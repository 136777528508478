import styled from 'styled-components';

const Wrapper = styled.div`
  height: inherit;
  margin: auto;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: #000000;
`;

const IconContainer = styled.div`
  margin-right: 16px;
`;

const ResendEmailContainer = styled.div`
  margin: 16px 0;
`;

export { Wrapper, Container, Text, IconContainer, ResendEmailContainer };
