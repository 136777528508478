import styled, { css } from 'styled-components';

const Svg = styled.svg`
  ${props =>
    props.minWidth &&
    props.minHeight &&
    css`
      min-width: ${props => props.minWidth}px;
      min-height: ${props => props.minHeight}px;
    `}
`;

export { Svg };
