import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { titleCase } from '../../../../utils';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: '0 12px 12px 0'
  },
  pos: {
    marginBottom: 12
  }
});

export default function AccountCard({ method, status, keyLastFour, currency }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {titleCase(method)}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {status}
        </Typography>
        <Typography variant="body2" component="p">
          Key: Ending with ****{keyLastFour}
          <br />
          Currency: {currency?.toUpperCase()}
        </Typography>
      </CardContent>
    </Card>
  );
}
