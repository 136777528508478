import React from 'react';
import Icons from '../../atoms/icons';
import history from '../../../history';
import { withRouter } from 'react-router-dom';
import {
  getApiResponseObject,
  getAuthToken,
  postWithResponseObject,
  successStatusCodes
} from '../../../utils';
import { connect } from 'react-redux';
import { API_BASE_URL } from '../../../config/env';
import * as actions from '../../../redux/actions/uiActions';
import PropTypes from 'prop-types';
import {
  Container,
  IconBorder,
  Next,
  SubContainer,
  IconContainer,
  RadioButtonContainer,
  RadioButtonSubContainer,
  UserSelectionContainer,
  Text,
  LoginAs
} from './styles';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';

class LoginType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginSelection: ['coseller', 'vendor', 'Network Operator'],
      userSelection: '',
      disableButton: false
    };
  }

  componentDidMount() {
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
  }

  handleRadio = e => {
    this.setState({ userSelection: e.target.value });
  };

  handleUserSelection = async userSelected => {
    localStorage.setItem('USER_TYPE', userSelected.toUpperCase());
    this.userPlatformSelection(userSelected);
  };

  isNetworkOperator = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const requestBody = {
      userType: 'network'
    };

    this.props.showSpinnerBackdrop();
    this.setState({ disableButton: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/authenticate`,
      requestBody,
      headers
    );
    this.setState({ disableButton: false });
    this.props.hideSpinnerBackdrop();

    if (response.data.is_onboarded === true) {
      localStorage.setItem('token', JSON.stringify(response.data.token) || {});
      localStorage.setItem('USER_TYPE', 'NETWORK_OPERATOR');

      const networkResponse = await getApiResponseObject(
        `${API_BASE_URL}/networks`,
        { authorization: response.data.token }
      );

      if (successStatusCodes.includes(networkResponse.status)) {
        localStorage.setItem(
          'userProfileNetworks',
          JSON.stringify(networkResponse.data || null)
        );

        history.push('/networkoperator/dashboard');
      }
    } else {
      history.push('/users/network-operator/onboard/');
    }
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
  };

  isVendor = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const requestBody = {
      userType: 'vendor'
    };

    this.props.showSpinnerBackdrop();
    this.setState({ disableButton: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/authenticate`,
      requestBody,
      headers
    );
    this.setState({ disableButton: false });

    if (response.data.is_onboarded === true) {
      localStorage.setItem('token', JSON.stringify(response.data.token) || {});
      localStorage.setItem('USER_TYPE', 'VENDOR');
      history.push('/dashboard');
    } else {
      history.push('/users/vendor/onboard/');
    }
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
    this.props.hideSpinnerBackdrop();
  };

  isCoSeller = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const headers2 = {
      'Content-type': 'application/json',
      authorization: token
    };
    const requestBody = {
      userType: 'coseller'
    };
    const reqBody = {
      gender: '',
      interests: [],
      socialMediaAccounts: []
    };

    this.props.showSpinnerBackdrop();
    this.setState({ disableButton: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/authenticate`,
      requestBody,
      headers
    );
    this.setState({ disableButton: false });

    if (response.data.is_onboarded === true) {
      localStorage.setItem('token', JSON.stringify(response.data.token) || {});
      localStorage.setItem('USER_TYPE', 'COSELLER');
      history.push('/cosellerdashboard');
    } else {
      const newResponse = await postWithResponseObject(
        `${API_BASE_URL}/cosellers`,
        reqBody,
        headers2
      );
      localStorage.setItem(
        'token',
        JSON.stringify(newResponse.data.token) || {}
      );
      localStorage.setItem('USER_TYPE', 'COSELLER');
      history.push('/cosellerdashboard');
    }
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
    this.props.hideSpinnerBackdrop();
  };

  userPlatformSelection = async userSelected => {
    switch (userSelected) {
      case 'Network Operator':
        this.isNetworkOperator();
        break;
      case 'vendor':
        this.isVendor();
        break;
      case 'coseller':
        this.isCoSeller();
        break;
      default:
        console.log('nothing');
    }
  };

  render() {
    const { loginSelection, userSelection, disableButton } = this.state;
    return (
      <Container>
        <LoginAs>Login as</LoginAs>
        <SubContainer>
          {loginSelection.map((type, key) => {
            const isSelected = userSelection === type;
            return (
              <IconContainer key={key}>
                <RadioButtonContainer>
                  <RadioButtonSubContainer>
                    <input
                      type="radio"
                      name="loginSelection"
                      id={type}
                      value={type}
                      onChange={e => this.handleRadio(e)}
                    />
                    <label
                      style={{ textTransform: 'capitalize' }}
                      htmlFor={type}
                    >
                      <IconBorder isSelected={isSelected}>
                        <div>
                          {type === 'Network Operator' ? (
                            <Icons
                              name={
                                isSelected
                                  ? 'networkoperator-selected'
                                  : 'networkoperator'
                              }
                              width={50}
                              height={50}
                            />
                          ) : (
                            <Icons
                              name={
                                isSelected
                                  ? `${type.toLowerCase()}-selected`
                                  : type.toLowerCase()
                              }
                              width={50}
                              height={50}
                            />
                          )}
                        </div>
                      </IconBorder>
                      <Text isSelected={isSelected}>{type}</Text>
                    </label>
                  </RadioButtonSubContainer>
                </RadioButtonContainer>
              </IconContainer>
            );
          })}
        </SubContainer>
        <UserSelectionContainer>
          {userSelection ? (
            <Next
              text="Next"
              onClick={() => this.handleUserSelection(userSelection)}
              disabled={disableButton}
            />
          ) : (
            <Next text="Next" disabled />
          )}
        </UserSelectionContainer>
      </Container>
    );
  }
}

LoginType.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default React.memo(
  withRouter(connect(null, mapDispatchToProps)(LoginType))
);
