import { isEmpty } from 'lodash';
import { currencySymbol } from '../../utils';

export default function orderConfirmationTemplate(checkoutDetails) {
  let productDetailsHTML = '';
  let customerDetailsHeadingString = checkoutDetails?.requires_shipping
    ? 'Customer Information (Shipping Address):'
    : 'Customer Information (Billing Address):';
  let paymentDetailsString = !isEmpty(checkoutDetails?.payment?.method_details)
    ? `Paid online via ${checkoutDetails.payment.name}`
    : 'NA';
  let customerDetailsHTML = '';

  customerDetailsHTML = `<td style="padding-right: 10px; word-break: break-word; vertical-align: top;">
    <div style="font-size: 14px; font-weight: 300;">
     ${
       checkoutDetails.requires_shipping
         ? checkoutDetails.shipping_address.name
         : checkoutDetails.billing_address.name
     },
    </div>
    <div style="font-size: 14px; font-weight: 300;">
    ${
      checkoutDetails.requires_shipping
        ? checkoutDetails.shipping_address.street1
        : checkoutDetails.billing_address.street1
    },
    </div>
    <div style="font-size: 14px; font-weight: 300;">
    ${
      checkoutDetails.requires_shipping
        ? checkoutDetails.shipping_address.city
        : checkoutDetails.billing_address.city
    } ${
    checkoutDetails.requires_shipping
      ? checkoutDetails.shipping_address.postalCode
      : checkoutDetails.billing_address.postalCode
  },
    </div>
    <div style="font-size: 14px; font-weight: 300;">
    ${
      checkoutDetails.requires_shipping
        ? checkoutDetails.shipping_address.state
        : checkoutDetails.billing_address.state
    }, ${
    checkoutDetails.requires_shipping
      ? checkoutDetails.shipping_address.country
      : checkoutDetails.billing_address.country
  }
    </div>
   </td>`;

  // iife to set productDetailsHTML
  (() => {
    const generateProducts = vendorId => {
      let productsList = '';

      checkoutDetails.order_details_per_vendor[vendorId].cart_lines.forEach(
        (product, productIndex, productsArray) => {
          productsList += `<tr>
          <td style="padding-right: 6px; ${
            productIndex === 0 ? '' : 'padding-top: 4px;'
          } ${
            productIndex === productsArray.length - 1
              ? ''
              : 'padding-bottom: 4px;'
          }">
           <div style="display: inline-block; min-width: 40px; min-height: 40px; width: 40px; height: 40px;">
            <img style="width: 100%; height: 100%; object-fit: cover; border: 0.5px solid #cbcbcb; border-radius: 5px;" src="${
              product.image_src
            }" alt="${product.name}" />
           </div>
          </td>
          <td style="padding-right: 12px; ${
            productIndex === 0 ? '' : 'padding-top: 4px;'
          } ${
            productIndex === productsArray.length - 1
              ? ''
              : 'padding-bottom: 4px;'
          } word-break: break-word;">
           ${product.name}
          </td>
          <td style="${productIndex === 0 ? '' : 'padding-top: 4px;'} ${
            productIndex === productsArray.length - 1
              ? ''
              : 'padding-bottom: 4px;'
          } word-break: break-word; font-size: 14px; font-weight: 300; text-align: right;">
           x[${product.quantity}] ${currencySymbol(product.price.currency)}${
            product.price.amount
          }
          </td>
         </tr>`;
        }
      );

      return productsList;
    };

    if (!isEmpty(checkoutDetails?.order_details_per_vendor)) {
      Object.keys(checkoutDetails.order_details_per_vendor).forEach(
        vendorId => {
          productDetailsHTML += `<tr>
          <td style="padding-right: 12px; padding-top: 10px; padding-bottom: 10px; word-break: break-word; border-bottom: 1px solid #e6e6e6; border-collapse: collapse; vertical-align: top;">
           <div style="font-size: 14px; font-weight: 300;">Order #${
             checkoutDetails.order_details_per_vendor[vendorId]
               .external_order_id
           }</div>
           <div style="font-size: 14px; font-weight: 300;">
            By ${
              checkoutDetails.order_details_per_vendor[vendorId].cart_lines[0]
                .vendor_name
            }
           </div>
          </td>
          <td style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #e6e6e6; border-collapse: collapse;">
           <table style="width: 100%;">
            ${generateProducts(vendorId)}
           </table>
          </td>
         </tr>
         `;
        }
      );
    }
  })();

  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="https://www.w3.org/1999/xhtml">
 <head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0 " />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap" rel="stylesheet" />
 </head>

 <body style="margin: 0px; padding: 12px; font-size: 16px; font-weight: 300; line-height: 1.5; font-family: 'Roboto', sans-serif; background: #f5f5f5;">
  <div style="margin: 0 auto; padding: 20px; max-width: 655px; min-height: 210px; background: #ffffff;">
   <div style="margin: 0 auto 10px; width: fit-content; font-size: 30px; font-weight: 700;">
    A new order has been placed&#x21;
   </div>
   <div style="margin: 0 auto 20px; width: fit-content; font-size: 18px; font-weight: 300;">
    Here are the details for the order
   </div>
   <div style="margin: 0 auto 20px; width: fit-content; font-size: 18px; font-weight: 300;">
    Order Id: ${checkoutDetails?.order_id}
   </div>
   <div style="margin-bottom: 32px;">
    <table style="width: 100%;">
     <!-- product details -->
     ${productDetailsHTML}
     <!-- amount details -->
     <tr>
      <td colspan="2" style="padding-top: 10px; padding-bottom: 10px; border-bottom: 1px solid #e6e6e6; border-collapse: collapse;">
       <table style="width: 100%;">
        <tr>
         <td style="padding-right: 40px; font-size: 14px; font-weight: 300; text-align: right;">
          Subtotal:
         </td>
         <td style="width: 1%; font-size: 14px; font-weight: 300; text-align: right;">
          ${currencySymbol(checkoutDetails.sub_total.currency)}${
    checkoutDetails.sub_total.amount
  }
         </td>
        </tr>
        <tr>
         <td style="padding-right: 40px; font-size: 14px; font-weight: 300; text-align: right;">
          Taxes:
         </td>
         <td style="width: 1%; font-size: 14px; font-weight: 300; text-align: right;">
          ${currencySymbol(checkoutDetails.taxes.currency)}${
    checkoutDetails.taxes.amount
  }
         </td>
        </tr>
        <tr>
         <td style="padding-right: 40px; font-size: 14px; font-weight: 300; text-align: right;">
          Shipping:
         </td>
         <td style="width: 1%; font-size: 14px; font-weight: 300; text-align: right;">
          ${currencySymbol(checkoutDetails.shipping.currency)}${
    checkoutDetails.shipping.amount
  }
         </td>
        </tr>
        <tr>
         <td style="padding-right: 40px; font-size: 14px; font-weight: 400; text-align: right;">
          Total:
         </td>
         <td style="width: 1%; font-size: 14px; font-weight: 400; text-align: right;">
          ${currencySymbol(checkoutDetails.total.currency)}${
    checkoutDetails.total.amount
  }
         </td>
        </tr>
       </table>
      </td>
     </tr>
     <!-- customer and payment details -->
     <tr>
      <td colspan="2" style="padding-top: 10px; padding-bottom: 10px;">
       <table style="width: 100%;">
        <tr>
         <td style="padding-right: 10px; font-size: 14px; font-weight: 400;">
          ${customerDetailsHeadingString}
         </td>
         <td style="padding-left: 10px; font-size: 14px; font-weight: 400;">
          Payment Information:
         </td>
        </tr>
        <tr>
         ${customerDetailsHTML}
         <td style="padding-left: 10px; word-break: break-word; vertical-align: top;">
          <div style="font-size: 14px; font-weight: 300;">
           ${paymentDetailsString}
          </div>
         </td>
        </tr>
       </table>
      </td>
     </tr>
    </table>
   </div>
  </div>
 </body>
</html>
`;
}
