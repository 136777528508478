import React from 'react';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

export default function DeleteKeyDialog({
  open,
  handleCloseDialog,
  deleteApiKey
}) {
  const handleDeleteApiKey = () => {
    handleCloseDialog();
    deleteApiKey();
  };
  return (
    <Dialog
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleCloseDialog();
        }
      }}
      open={open}
    >
      <DialogTitle onClose={handleCloseDialog}>Delete Key?</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          Once you delete the key, there is no way going back as we cannot
          retrieve or restore the same key again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteApiKey} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
