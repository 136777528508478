import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TableFooter
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';
import Currency from '../../../molecules/Currency';
import { isEmpty } from 'lodash';
import theme from '../../../../theme';
import Modal from '../../../templates/Modal';
import MaterialTablePagination from '../../../atoms/materialTablePagination';
import { VARIANT_COMBO_DELIMITER } from '../../../../constants';
import {
  PlatformImage,
  FlexRowEnd,
  SuccessContent,
  WarningContent,
  PrimaryButton,
  SecondaryButton
} from './styles';
import { sortAlphaNumeric } from '../../../../utils';

const styles = theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableBody: {
    opacity: '1',
    transition: 'all .1s linear'
  },
  tableBodyDisabled: {
    opacity: '0.4',
    pointerEvents: 'none'
  },
  imageCell: {
    display: 'flex',
    alignItems: 'center'
  },
  adjustmentsRow: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  adjustmentsData: {
    margin: '0 8px'
  }
});

const { INVOLVED, NOT_INVOLVED } = theme.default;

const lightBorder = '1px solid #ebebeb';
const dontIncludeLevels = ['lMarket', 'networkCommission', 'shoptypeCharge'];
let lowestLevel = '';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: '#f1d64d',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#ffffff'
      }
    }
  }
}))(MenuItem);

class ordersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrder: -1,
      openProduct: -1,
      rows: [],
      anchorEls: [],
      modalContent: null
    };
  }

  componentDidMount() {
    if (this.props.orderDetails.length > 0) {
      this.restructureOrderDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.orderDetails.length <= 0 &&
        this.props.orderDetails.length > 0) ||
      (prevProps.fetchingOrders && !this.props.fetchingOrders)
    ) {
      this.restructureOrderDetails();
    }
  }

  restructureOrderDetails = () => {
    const { orderDetails } = this.props;
    this.setState({ rows: orderDetails });
  };

  reIterateLevelName = level => {
    switch (level) {
      case 'lIntro':
        return 'Introduction Click';

      case 'lX':
        return 'Close Click';

      default:
        // Extract the numeric part from the commission string
        const levelNumber = parseInt(level.match(/\d+/)[0]);

        // Extract the numeric part from the lowest level
        const lowestLevelNumber = parseInt(lowestLevel.match(/\d+/)[0]);

        // Subtract the lowestLevelNumber from the current level number
        // to get the correct click number
        const clickNumber = levelNumber - lowestLevelNumber + 1;
        return `Click ${clickNumber}`;
    }
  };

  arrangeData = arr => {
    const sortedArray = sortAlphaNumeric(arr, 'ASC');
    // Need lowestLevel for Click calculation ( reIterateLevelName() )
    lowestLevel = sortedArray[0];

    // Order should be : Introduction Click -> Click 1 to Click 8 -> Close Click
    const index = sortedArray.indexOf('lIntro');
    const lIntro = sortedArray.splice(index, 1).toString();
    sortedArray.unshift(lIntro);
    return sortedArray;
  };

  adjustmentName = shortName => {
    switch (shortName) {
      case 'lShare':
        return 'Share Earnings';

      case 'lInvite':
        return 'Invite Earnings';

      case 'lReferrer':
        return 'Referrer Earnings';

      case 'lPartner':
        return 'Partner Earnings';

      case 'cosellerSplit':
        return 'Coseller Split';

      default:
        return shortName;
    }
  };

  openOrder = index => {
    this.setState({ openProduct: -1, openOrder: index });
  };

  closeOrder = () => {
    this.setState({ openOrder: -1 });
  };

  openProduct = productIndex => {
    this.setState({ openProduct: productIndex });
  };

  closeProduct = () => {
    this.setState({ openProduct: -1 });
  };

  openMenu = (index, event) => {
    let { anchorEls } = this.state;
    anchorEls[index] = event.target;
    this.setState({ anchorEls });
  };

  closeMenu = index => {
    let { anchorEls } = this.state;
    anchorEls[index] = null;
    this.setState({ anchorEls });
  };

  closeModal = () => {
    this.setState({
      modalContent: null
    });
  };

  handleSettlePayment = (index, externalId, orderId) => {
    this.closeMenu(index);
    const modalContent = (
      <Modal close={this.closeModal} width={500} height={125} model="custom">
        <div style={{ textAlign: 'left' }}>
          Once confirmed, payment will be settled for the order id &nbsp;
          <strong>{externalId}</strong>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <PrimaryButton
            style={{ marginRight: 8 }}
            text="Confirm"
            onClick={() => this.confirmSettlePayment(orderId)}
          />
          <SecondaryButton text="Cancel" onClick={() => this.closeModal()} />
        </div>
      </Modal>
    );

    this.setState({
      modalContent: modalContent
    });
  };

  confirmSettlePayment = orderId => {
    if (this.props.settlePayment) {
      this.closeModal();
      this.props.settlePayment(orderId);
    }
  };

  render() {
    const {
      openOrder,
      openProduct,
      rows,
      anchorEls,
      modalContent
    } = this.state;
    const {
      classes,
      totalOrdersCount,
      currentPage,
      fetchingOrders,
      handleChangePage
    } = this.props;

    return rows.length > 0 ? (
      <>
        {modalContent && <>{modalContent}</>}
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Order No.</TableCell>
                <TableCell>No. of items</TableCell>
                <TableCell align="left">Date&nbsp;&amp;&nbsp;Time</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Final Revenue</TableCell>
                <TableCell>Network</TableCell>
                <TableCell>Platform</TableCell>
                <TableCell>Transaction Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody
              className={`${classes.tableBody} ${
                fetchingOrders ? classes.tableBodyDisabled : null
              }`}
            >
              {rows.map((row, indexA) => (
                <React.Fragment key={indexA}>
                  <TableRow className={classes.root}>
                    <TableCell align="left" component="th" scope="row">
                      {row.externalId || '—'}
                    </TableCell>
                    <TableCell align="left">{row.quantity || '—'}</TableCell>
                    <TableCell align="left">
                      {row.createdAt
                        ? moment(row.createdAt).format(
                            'DD[/]MMMM[/]YYYY h:mm A'
                          )
                        : '—'}
                    </TableCell>
                    <TableCell align="left">
                      <Currency currencyName={row.price.currency || ''} />
                      {row.price.amount || '—'}
                    </TableCell>
                    <TableCell align="left">
                      <Currency
                        currencyName={row.vendorRevenue.currency || ''}
                      />
                      {row.vendorRevenue.amount || '—'}
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip title="Introduction Payout">
                        <FiberManualRecordIcon
                          fontSize="medium"
                          htmlColor={
                            !isEmpty(row.attributions) &&
                            row.attributions.lIntro
                              ? INVOLVED
                              : NOT_INVOLVED
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Middle Stages">
                        <FiberManualRecordIcon
                          fontSize="medium"
                          htmlColor={
                            !isEmpty(row.attributions) &&
                            row.attributions.lMiddle
                              ? INVOLVED
                              : NOT_INVOLVED
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Close">
                        <FiberManualRecordIcon
                          fontSize="medium"
                          htmlColor={
                            !isEmpty(row.attributions) && row.attributions.lX
                              ? INVOLVED
                              : NOT_INVOLVED
                          }
                        />
                      </Tooltip>
                      &emsp;&emsp;&emsp;&emsp;&emsp;
                      <Tooltip title="Invite Earning">
                        <FiberManualRecordIcon
                          fontSize="medium"
                          htmlColor={
                            !isEmpty(row.attributions) &&
                            row.attributions.lInvite
                              ? INVOLVED
                              : NOT_INVOLVED
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Coseller Earning">
                        <FiberManualRecordIcon
                          fontSize="medium"
                          htmlColor={
                            !isEmpty(row.attributions) &&
                            row.attributions.lReferrer
                              ? INVOLVED
                              : NOT_INVOLVED
                          }
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip title={row.platformUrl || 'shoptype.com'}>
                        <PlatformImage
                          src={`https://www.google.com/s2/favicons?sz=64&domain_url=${row.platformUrl ||
                            'shoptype.com'}`}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">
                      {row?.transactionStatus ? (
                        row.transactionStatus.toLowerCase() === 'success' ? (
                          <SuccessContent>
                            <div style={{ marginRight: 4 }}>
                              <CheckCircleOutlineIcon />
                            </div>
                            <div>Success</div>
                          </SuccessContent>
                        ) : (
                          row.transactionStatus.toLowerCase() === 'pending' && (
                            <WarningContent>
                              <div style={{ marginRight: 4 }}>
                                <ScheduleIcon />
                              </div>
                              <div>Pending</div>
                            </WarningContent>
                          )
                        )
                      ) : (
                        '—'
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <FlexRowEnd>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() =>
                            openOrder === indexA
                              ? this.closeOrder()
                              : this.openOrder(indexA)
                          }
                        >
                          {openOrder === indexA ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </FlexRowEnd>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={9}
                    >
                      <Collapse
                        style={{ backgroundColor: '#F5F5F5' }}
                        in={openOrder === indexA}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Table size="small" aria-label="products">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Product</TableCell>
                                <TableCell align="left">Quantity</TableCell>
                                <TableCell align="left">Variant</TableCell>
                                <TableCell align="left">Price</TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.productDetails.map((product, indexB) => (
                                <React.Fragment key={indexB}>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        border: 'none'
                                      }}
                                      align="left"
                                      component="th"
                                      scope="row"
                                    >
                                      <div className={classes.imageCell}>
                                        <div>
                                          <img
                                            src={
                                              product.primaryImageSrc.imageSrc
                                            }
                                            width="50px"
                                            height="50px"
                                            alt="product media"
                                          />
                                        </div>
                                        <div style={{ marginLeft: 4 }}>
                                          {product.title || '—'}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell align="left">
                                      {product.quantity || '—'}
                                    </TableCell>
                                    <TableCell align="left">
                                      {(!isEmpty(product.variantNameValue) &&
                                        Object.values(
                                          product.variantNameValue
                                        ).join(VARIANT_COMBO_DELIMITER)) ||
                                        '—'}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Currency
                                        currencyName={
                                          product.price.currency || ''
                                        }
                                      />
                                      {product.price.amount || '—'}
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() =>
                                          openProduct === indexB
                                            ? this.closeProduct()
                                            : this.openProduct(indexB)
                                        }
                                      >
                                        {openProduct === indexB ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        paddingBottom: 0,
                                        paddingTop: 0
                                      }}
                                      colSpan={9}
                                    >
                                      <Collapse
                                        style={{ backgroundColor: '#E5E5E5' }}
                                        in={openProduct === indexB}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box margin={1}>
                                          <Table
                                            size="small"
                                            aria-label="products"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    borderBottom: lightBorder
                                                  }}
                                                >
                                                  Stage
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    borderBottom: lightBorder
                                                  }}
                                                >
                                                  Coseller
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    borderBottom: lightBorder
                                                  }}
                                                >
                                                  Earning
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    borderBottom: lightBorder
                                                  }}
                                                >
                                                  Date&nbsp;&amp;&nbsp;Time
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    borderBottom: lightBorder
                                                  }}
                                                >
                                                  Platform
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {!isEmpty(
                                                product.commission_details
                                              ) ? (
                                                this.arrangeData(
                                                  Object.keys(
                                                    product.commission_details
                                                  )
                                                )
                                                  .filter(
                                                    item =>
                                                      !dontIncludeLevels.includes(
                                                        item
                                                      )
                                                  )
                                                  .map((commission, indexC) => (
                                                    <TableRow key={indexC}>
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        style={{
                                                          borderBottom: lightBorder
                                                        }}
                                                      >
                                                        {this.reIterateLevelName(
                                                          commission
                                                        )}
                                                      </TableCell>
                                                      <TableCell
                                                        align="left"
                                                        style={{
                                                          borderBottom: lightBorder
                                                        }}
                                                      >
                                                        {product
                                                          .commission_details[
                                                          commission
                                                        ].userName || '—'}
                                                      </TableCell>
                                                      <TableCell
                                                        align="left"
                                                        style={{
                                                          borderBottom: lightBorder
                                                        }}
                                                      >
                                                        <Currency
                                                          currencyName={
                                                            product
                                                              ?.commission_details[
                                                              commission
                                                            ]?.amount
                                                              ?.currency || ''
                                                          }
                                                        />
                                                        {product
                                                          ?.commission_details[
                                                          commission
                                                        ]?.amount?.amount ||
                                                          '—'}
                                                      </TableCell>
                                                      <TableCell
                                                        align="left"
                                                        style={{
                                                          borderBottom: lightBorder
                                                        }}
                                                      >
                                                        {product
                                                          ?.commission_details[
                                                          commission
                                                        ]?.createdAt
                                                          ? moment(
                                                              product
                                                                .commission_details[
                                                                commission
                                                              ].createdAt
                                                            ).format(
                                                              'DD[/]MMMM[/]YYYY h:mm A'
                                                            )
                                                          : '—'}
                                                      </TableCell>
                                                      <TableCell
                                                        align="left"
                                                        style={{
                                                          borderBottom: lightBorder
                                                        }}
                                                      >
                                                        <Tooltip
                                                          title={
                                                            product
                                                              ?.commission_details[
                                                              commission
                                                            ]?.platformUrl ||
                                                            'shoptype.com'
                                                          }
                                                        >
                                                          <PlatformImage
                                                            src={`https://www.google.com/s2/favicons?sz=64&domain_url=${product
                                                              ?.commission_details[
                                                              commission
                                                            ]?.platformUrl ||
                                                              'shoptype.com'}`}
                                                          />
                                                        </Tooltip>
                                                      </TableCell>
                                                    </TableRow>
                                                  ))
                                              ) : (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    colSpan={9}
                                                  >
                                                    Earnings details unavailable
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                              {!isEmpty(
                                                product.total_adjustments
                                              ) ? (
                                                <TableRow>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="left"
                                                    colSpan={9}
                                                    style={{
                                                      borderBottom: lightBorder
                                                    }}
                                                  >
                                                    <div
                                                      className={
                                                        classes.adjustmentsRow
                                                      }
                                                    >
                                                      {Object.keys(
                                                        product.total_adjustments
                                                      ).map(
                                                        (
                                                          adjustment,
                                                          indexD
                                                        ) => (
                                                          <div
                                                            key={indexD}
                                                            className={
                                                              classes.adjustmentsData
                                                            }
                                                          >
                                                            {`${this.adjustmentName(
                                                              adjustment
                                                            )} : `}
                                                            <Currency
                                                              currencyName={
                                                                product
                                                                  ?.total_adjustments[
                                                                  adjustment
                                                                ]?.currency ||
                                                                ''
                                                              }
                                                            />
                                                            {`${product?.total_adjustments[adjustment]?.amount}`}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    colSpan={9}
                                                  >
                                                    Adjustment details
                                                    unavailable
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <MaterialTablePagination
                  colSpan={9}
                  count={totalOrdersCount}
                  page={currentPage}
                  handleChangePage={handleChangePage}
                  rowsPerPage={6}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    ) : (
      <div>No order details available...</div>
    );
  }
}

export default withStyles(styles)(ordersTable);
