import React from 'react';
import PropTypes from 'prop-types';
import { Container, Textarea } from './styles';

const TextArea = ({
  name,
  value,
  onChange,
  inputType,
  required,
  size,
  id,
  maxLength,
  placeholder,
  rows,
  previewMode
}) => {
  return (
    <Container>
      <Textarea
        maxLength={maxLength}
        placeholder={placeholder || ''}
        id={id}
        value={value || ''}
        name={name}
        onChange={onChange}
        inputType={inputType}
        size={size}
        required={required}
        rows={rows || 2}
        readOnly={previewMode}
        spellCheck={'false'}
      />
    </Container>
  );
};

TextArea.defaultProps = {
  type: 'text',
  inputType: 'primary',
  required: false,
  size: 'default-size',
  id: ''
};

TextArea.propTypes = {
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  inputType: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.string
};

export default TextArea;
