import React from 'react';
import {
  isLoggedIn,
  isShoptypeAdmin,
  isNoShoptypeCheckout,
  addLogoutListener,
  removeLogoutListener
} from '../../../utils';
import { Route, Redirect } from 'react-router';

class STRoute extends React.Component {
  render() {
    const {
      component: Component,
      altRender,
      requiresLogin,
      requiresAdmin,
      requiresNoShoptypeCheckout,
      redirectIfLoggedIn,
      hideHelpChat,
      ...props
    } = this.props;

    let renderBody;

    if (redirectIfLoggedIn && isLoggedIn()) {
      renderBody = <Redirect to="/" />;
    } else {
      if (altRender) {
        renderBody = altRender();
      } else {
        if (requiresLogin && requiresAdmin) {
          renderBody =
            isLoggedIn() && isShoptypeAdmin() ? (
              <Component {...props} />
            ) : (
              <Redirect to="/page-not-found" />
            );
        } else if (requiresLogin && requiresNoShoptypeCheckout) {
          renderBody =
            isLoggedIn() && isNoShoptypeCheckout() ? (
              <Component {...props} />
            ) : (
              <Redirect to="/page-not-found" />
            );
        } else if (requiresLogin) {
          renderBody = isLoggedIn() ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          );
        } else {
          renderBody = <Component {...props} />;
        }
      }
    }

    //Hide/show help chat
    const helpChatRenderInterval = setInterval(() => {
      const freshworksContainer = document.getElementById(
        'freshworks-container'
      );
      if (freshworksContainer) {
        if (hideHelpChat) {
          freshworksContainer.style.display = 'none';
        } else {
          freshworksContainer.style.display = 'block';
        }
        clearInterval(helpChatRenderInterval);
      }
    }, 200);

    return (
      <Route
        {...props}
        path={props.path}
        render={() => {
          if (requiresLogin) {
            addLogoutListener();
          } else {
            removeLogoutListener();
          }
          return renderBody;
        }}
      />
    );
  }
}

export default React.memo(STRoute);
