import styled, { css } from 'styled-components';

const InputContainer = styled.div`
  position: relative;

  ${p => p.css}

  ${p => p.theme.TABLET`
    width: 100%;
  `};
`;

const InputSuggestions = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 200px;
  background: ${p => p.theme.WHITE};
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 30px 0 rgba(41, 49, 73, 0.1);
  overflow-y: auto;
`;

const InputSuggestionsItem = styled.div`
  padding: 10px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Input = styled.input`
  border-radius: unset;
  min-height: 60px;
  width: 100%;
  max-width: 100%;
  transition: all 200ms ease;

  :disabled {
    background: ${p => p.theme.GALLERY};
    border-color: transparent;
  }

  :disabled ~ label {
    color: ${p => p.theme.SILVER_CHALICE};
    background: ${p => p.theme.GALLERY};
  }

  :required:invalid ~ span:nth-of-type(1) {
    position: absolute;
    top: 17px;
    right: 11px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${p => p.theme.PINK_SWAN};
    vertical-align: middle;
    text-align: center;
  }

  ${p =>
    p.filled
      ? css`
          border: 1px solid ${p.theme.PINK_SWAN};

          ~ span:nth-child(1) {
            display: none;
          }
        `
      : ''};

  ${p =>
    p.inputType === 'primary'
      ? css`
          font-size: ${p.theme.MEDIUM};
          border: 1px solid ${p.theme.PINK_SWAN};
          padding: 11px 15px;
          font-weight: ${p.theme.SEMI_BOLD_FONT};
          :focus {
            border-left: 1px solid ${p.theme.PINK_SWAN};
            outline: none;
            font-weight: ${p.theme.MEDIUM_FONT};
            border-left-width: 4px;
            border-left-color: ${props => props.theme.BUDDHA_GOLD};
          }

          :focus ~ label {
            top: 2px;
            left: 17px;
            font-size: ${p.theme.X_SMALL};
            font-weight: ${p.theme.MEDIUM_FONT};
            color: ${p.theme.BUDDHA_GOLD};
          }

          ${p.touched
            ? css`
                :invalid {
                  border: 1px solid ${p.theme.PINK_SWAN};
                  box-shadow: none;
                }

                :invalid ~ label {
                  color: ${p.theme.PINK_SWAN};
                }
              `
            : ''};

          :optional {
            ${p.touched
              ? css`
                  :invalid ~ span:nth-of-type(2) {
                    display: block;
                  }
                `
              : ''};
          }
        `
      : ''};

  @media (min-width: 768px) {
    ${p =>
      ({
        'default-size': css`
          width: 300px;
          height: 40px;
        `,
        'small-size': css`
          width: 240px;
          height: 40px;

          :required:invalid ~ span:nth-of-type(1) {
            right: 22px;
          }
        `,
        'large-size': css`
          width: 500px;
          height: 100px;
        `,
        'contact-card-size': css`
          width: 200px;
          height: 40px;

          :required:invalid ~ span:nth-of-type(1) {
            right: 12px;
          }
        `
      }[p.size] ||
      css`
        width: 100%;
        height: 40px;
      `)};
  }
`;

const InputLabel = styled.label`
  position: absolute;
  top: 20px;
  left: 10px;
  padding: 2px 5px 2px 2px;
  font-size: ${p => p.theme.SMALL};
  transition: all 200ms ease;
  color: ${p => p.theme.PINK_SWAN};

  ${p =>
    p.filled
      ? css`
          top: 2px;
          left: 17px;
          font-size: ${p => p.theme.X_SMALL};
          font-weight: ${p => p.theme.MEDIUM};
          color: ${p => p.theme.PINK_SWAN};
        `
      : ''};
`;

const ErrorSymbol = styled.span`
  display: none;
  position: absolute;
  top: 13px;
  right: 12px;
  width: 14px;
  height: 14px;
  line-height: 11px;
  border-radius: 75px;
  background-color: ${p => p.theme.RONCHI};
  vertical-align: middle;
  text-align: center;
`;

const ErrorExclamation = styled.p`
  display: inline;
  font-size: ${p => p.theme.X_SMALL};
  font-weight: ${p => p.theme.MEDIUM_FONT};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${p => p.theme.WHITE};
`;

const MandatoryDot = styled.span``;

export {
  InputContainer,
  InputSuggestions,
  InputSuggestionsItem,
  Input,
  InputLabel,
  ErrorSymbol,
  ErrorExclamation,
  MandatoryDot
};
