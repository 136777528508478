import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import GooglePlacesAutocomplete from '../../molecules/GooglePlacesAutocompleteV2';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid grey',
    borderRadius: '5px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: '350px'
  },
  submitButton: {
    marginTop: '10px'
  }
}));

const GoogleLocationSearchPopover = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'location-widget-popover' : undefined;

  const handleLocationChange = locationData => {
    if (locationData.country_code) {
      props.getLocationDetails(locationData);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        size="small"
        color="primary"
        onClick={handleClick}
      >
        Set Location
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ zIndex: 100 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className={classes.container}>
          <GooglePlacesAutocomplete
            label="Search"
            type={'location-widget'}
            callback={handleLocationChange}
          />
        </div>
      </Popover>
    </div>
  );
};

export default GoogleLocationSearchPopover;
