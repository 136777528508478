import React from 'react';
import { withRouter } from 'react-router-dom';
import DefaultProductImage from '../../../assets/images/photo.svg';
import {
  getAuthToken,
  getApiResponseObject,
  getAuthId,
  successStatusCodes,
  showApiErrorToast
} from '../../../utils';
import { Secondary as SecondaryButton } from '../../atoms/button';
import Modal from '../../templates/Modal';
import { toaster } from '../../atoms/toaster';
import PublishModal from '../../organisms/ShareOnSocial';
import { API_BASE_URL, APP_BASE_URL } from '../../../config/env';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Currency from '../../molecules/Currency';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../atoms/loader';
import {
  ProductSection,
  ProductContainer,
  ProductListView,
  ProductTitle,
  ProductTitleItem,
  ProductListItems,
  ProductItem,
  Message,
  Image,
  Headline,
  Cost,
  Quantity,
  ViewProduct,
  ViewButton,
  TopLevelContainer
} from './styles';
import PaginationControls from '../../atoms/paginationControls';

const PRODUCT_LIST_TITLE = [
  '',
  'product name',
  'price',
  'quantity',
  'status',
  '',
  '',
  ''
];

const CustomSwitch = withStyles({
  switchBase: {
    color: '#E6C100',
    '&$checked': {
      color: '#E6C100'
    },
    '&$checked + $track': {
      backgroundColor: '#E6C100'
    }
  },
  checked: {},
  track: {}
})(Switch);

const styles = theme => ({
  paginationBtn: {
    border: 'none',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none'
    }
  }
});

class Catalog extends React.Component {
  state = {
    fetchProducts: [],
    isOpen: false,
    modalContent: null,
    productShareLink: ''
  };
  text = React.createRef();

  fetchProductLinkData = async (
    id,
    title,
    description,
    imageSrc,
    catalogId
  ) => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/track/publish-slug?productId=${id}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    }
    this.setState(
      {
        productShareLink: `${APP_BASE_URL}${response.data.slug}`,
        isOpen: true
      },
      () => {
        this.handlePublish(id, title, description, imageSrc, catalogId);
      }
    );
  };

  closeModal = () => {
    this.setState({ modalContent: null, productShareLink: '' });
  };

  handleViewButtonClick = id => {
    const productUrl = `${APP_BASE_URL}/view-product/${id}`;
    window.open(productUrl.replace(APP_BASE_URL, ''), '_blank');
  };

  handlePublish = (id, title, description, imageSrc, catalogId) => {
    const token = getAuthToken();
    const authId = getAuthId();
    const { productShareLink } = this.state;

    const modalContent = (
      <Modal close={this.closeModal}>
        <TopLevelContainer>
          <PublishModal
            authId={authId}
            productId={id}
            vendorId={catalogId} // Vendor Id is considered as Catalog id
            title={title}
            token={token}
            description={description}
            imageSrc={imageSrc}
            productShareLink={productShareLink}
            embedOption={true}
          />
        </TopLevelContainer>
      </Modal>
    );
    this.setState({
      shopifyProducts: true,
      modalContent: modalContent
    });
  };

  clickPublish = (id, title, description, imageSrc, catalogId) => {
    this.fetchProductLinkData(id, title, description, imageSrc, catalogId);
  };

  handleProductAttribution = (id, title, description, imageSrc) => {
    window.open(
      `/attribution?product=yes&id=${id}&title=${title}&imageSrc=${imageSrc}`,
      '_blank'
    );
  };

  render() {
    const { classes } = this.props;
    const { isOpen, modalContent } = this.state;
    const {
      isLoading,
      fetchProducts,
      handleToggleStatus,
      fetchParams,
      totalItemCount,
      updateFetchParams
    } = this.props;

    return (
      <ProductSection>
        <ProductTitle>
          {PRODUCT_LIST_TITLE.map((productTite, index) => (
            <ProductTitleItem key={index}>{productTite}</ProductTitleItem>
          ))}
        </ProductTitle>
        <ProductContainer>
          <ProductListItems>
            {!isLoading ? (
              <div>
                {isOpen ? <>{modalContent}</> : null}
                {fetchProducts && fetchProducts.length > 0 ? (
                  fetchProducts.map((product, index) => (
                    <ProductItem href={'#'} key={index}>
                      <Image
                        alt={product.title}
                        src={
                          product.primaryImageSrc.imageSrc ||
                          DefaultProductImage
                        }
                      />
                      <Headline>{product.title}</Headline>
                      <Cost>
                        {product.variants ? (
                          <React.Fragment>
                            {product.variants.length ? (
                              product.variants[0].discountedPrice ? (
                                <React.Fragment>
                                  <Currency currencyName={product.currency} />
                                  {product.variants[0].discountedPrice}
                                </React.Fragment>
                              ) : (
                                'Not Available'
                              )
                            ) : (
                              'Not Available'
                            )}
                          </React.Fragment>
                        ) : (
                          'Not Available'
                        )}
                      </Cost>
                      <Quantity>
                        {product.variants
                          ? product.variants[0].quantity
                          : 'Not Available'}
                      </Quantity>
                      <div>
                        <CustomSwitch
                          size="small"
                          checked={product.status === 'enabled'}
                          onChange={e => handleToggleStatus(e, product.id)}
                        />
                      </div>
                      <ViewProduct>
                        <ViewButton
                          text="View"
                          onClick={() => this.handleViewButtonClick(product.id)}
                          disabled={product.status === 'disabled'}
                        />
                        <SecondaryButton
                          text="Share"
                          disabled={product.status === 'disabled'}
                          onClick={() =>
                            this.clickPublish(
                              product.id,
                              product.title,
                              product.description,
                              product.primaryImageSrc.imageSrc
                                ? product.primaryImageSrc.imageSrc
                                : DefaultProductImage,
                              product.catalogId
                            )
                          }
                        />
                        <SecondaryButton
                          text="Attribution"
                          disabled={product.status === 'disabled'}
                          onClick={() =>
                            this.handleProductAttribution(
                              product.id,
                              product.title,
                              product.description,
                              product.primaryImageSrc.imageSrc
                                ? product.primaryImageSrc.imageSrc
                                : DefaultProductImage
                            )
                          }
                        />
                      </ViewProduct>
                    </ProductItem>
                  ))
                ) : (
                  <Message>
                    <i className="fas fa-times-circle danger" />
                    &nbsp;No products found
                  </Message>
                )}
              </div>
            ) : (
              <Loader
                message="Loading..."
                isFlex={true}
                w={'100%'}
                isCenter={true}
              />
            )}
          </ProductListItems>
          <PaginationControls
            paginationBtn={classes.paginationBtn}
            fetchParams={fetchParams}
            totalItemCount={totalItemCount}
            updateFetchParams={updateFetchParams}
          />
        </ProductContainer>
      </ProductSection>
    );
  }
}

Catalog.propTypes = {
  fetchProducts: PropTypes.array,
  handleToggleStatus: PropTypes.func
};

export default withStyles(styles)(withRouter(Catalog));
