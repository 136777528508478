import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: ${p => p.w && p.w};
  height: ${p => p.h};
  min-height: ${p => p.min_h && p.min_h};
  display: ${p => (p.isFlex ? 'flex' : 'inline-block')};
  align-items: center;
  flex-direction: column;
  padding: auto;
  justify-content: ${p => (p.isCenter ? 'space-around' : 'unset')};
`;

const LoadingAnimation = styled.span`
  border: 4px solid #eee;
  border-radius: 50%;
  border: 4px solid ${p => p.bgColor};
  border-top: 4px solid ${p => p.color && p.color};
  display: block;
  width: 24px;
  height: 24px;
  animation: spin 700ms linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { LoadingAnimation, LoadingContainer };
