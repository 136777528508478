import React from 'react';
import { withRouter } from 'react-router-dom';
import { toaster } from '../../atoms/toaster';
import TextInput from '../../atoms/textInput';
import update from 'immutability-helper';
import history from '../../../history';
import qs from 'qs';

import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  postWithResponseObject,
  putWithResponseObject,
  deleteWithResponseObject,
  resourceNotFoundStatusCode,
  fetchAttributionLevels,
  showApiErrorToast,
  clearBrowserStoredData
} from '../../../utils';
import { API_BASE_URL } from '../../../config/env';

import EffectiveNetworkCommission from './effectiveNetworkCommission';
import {
  Container,
  InnerContainer,
  AttributionContainer,
  AttributionConfigContainer,
  Heading,
  HeadingSection,
  SubHeading,
  SetAttributionContainer,
  SetDataContainer,
  AttributionData,
  SaveButtonContainer,
  Row,
  Image,
  ProductHeading,
  ClearAttributionButtonContainer,
  UpdateButtonContainer,
  ErrorText,
  ChartContainer,
  TopContainer,
  FloatingContainer
} from './styles';
import DefaultProductImage from '../../../assets/images/photo.svg';
import { GLOBAL_EVENT_KEYS, VENDOR } from '../../../constants';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Button, Tooltip, Zoom } from '@material-ui/core';
import { ATTRIBUTION, VENDOR_INFO } from '../../../constants/tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../redux/actions/uiActions';
import STProgressBar from '../../common/STProgressBar';
import EmptyState from '../../atoms/EmptyState';
class Attribution extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAttributionSet: false,
      attributionId: '',
      vendorId: '',
      vendorDetails: localStorage.getItem('userProfile'),
      previewMode: true,
      isDisabled: true,
      disableUpdateButton: false,
      disableClearAttributionButton: false,
      resetAttribution: false,
      productLevelAttribution: false,
      productDetails: {
        id: '',
        title: '',
        imageSrc: ''
      },
      attributionData: {
        attributionConfig: {
          configs: {
            networkCommission: {
              percentage: ''
            },
            lIntro: {
              percentage: '',
              timeLimit: '',
              timeLimitUnit: 'infinite'
            },
            lX: {
              percentage: '',
              timeLimit: '',
              timeLimitUnit: 'infinite'
            }
          }
        }
      },
      showError: false,
      showExpireTimeError: false,
      attributionSum: 0,
      invalidData: false
    };
  }

  componentDidMount() {
    this.fetchAllData();
  }

  fetchAllData = async () => {
    // Tokenized Login to get the Vendor's Attribution directly from Redirection (st-go --> st-dashboard)
    let params = new URLSearchParams(document.location.search);
    const token = params.get('access_token');
    const userType = params.get('user_type');

    if (token && userType) {
      clearBrowserStoredData();
      const profileResponse = await this.getProfileDetails(token);
      this.setProfileDetailsToLocalStorage(profileResponse, token);
    }

    if (this.isProductAttribution()) {
      this.setProductAttribution();
    } else {
      const userProfileData = JSON.parse(localStorage.getItem('userProfile'));
      if (userProfileData) {
        const vendorId = userProfileData.vendors[0].id;
        this.setState({ vendorId: vendorId });
        let { attributionData } = this.state;
        //creating 10 levels in attribution
        attributionData = fetchAttributionLevels(attributionData, 10);

        const newAttributionData = update(attributionData, {
          vendorId: { $set: vendorId }
        });
        this.setState({ attributionData: newAttributionData });

        this.fetchAttributionData('vendor');
      }
    }
  };

  getProfileDetails = async token => {
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      return profileRes.data;
    } else {
      showApiErrorToast(profileRes.data);
    }
  };

  setProfileDetailsToLocalStorage = (response, token) => {
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('USER_TYPE', VENDOR);
    localStorage.setItem('userProfile', JSON.stringify(response));
    localStorage.setItem('_id', JSON.stringify(response?._id));
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
  };

  getProfileDetails = async token => {
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      return profileRes.data;
    } else {
      showApiErrorToast(profileRes.data);
    }
  };

  setProfileDetailsToLocalStorage = (response, token) => {
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('USER_TYPE', VENDOR);
    localStorage.setItem('userProfile', JSON.stringify(response));
    localStorage.setItem('_id', JSON.stringify(response?._id));
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
  };

  getQueryParam = item => {
    try {
      return qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      })[item];
    } catch (err) {
      console.error(err);
    }
  };

  isProductAttribution = () => {
    const product = this.getQueryParam('product');
    const id = this.getQueryParam('id');
    return product && product === 'yes' && id && id.length > 0;
  };

  setProductAttribution = async () => {
    let newProductDetails = this.state.productDetails;

    try {
      const product = this.getQueryParam('product');
      const id = this.getQueryParam('id');
      const title = this.getQueryParam('title');
      const imageSrc = this.getQueryParam('imageSrc');

      newProductDetails = update(newProductDetails, {
        id: { $set: id ? id : null },
        title: { $set: title ? title : null },
        imageSrc: { $set: imageSrc ? imageSrc : null }
      });

      const productLevelAttribution =
        product && product === 'yes' && id && id.length > 0;

      this.setState(
        {
          productLevelAttribution,
          productDetails: newProductDetails
        },
        () => {
          let { attributionData } = this.state;
          const newAttributionData = update(attributionData, {
            productId: { $set: id }
          });
          this.setState({ attributionData: newAttributionData });

          this.fetchAttributionData('product');
        }
      );
    } catch (err) {
      console.error(err);
    }
  };
  checkValidAttribution = data => {
    let keys = Object.keys(data.attributionConfig.configs);
    if (keys.includes('l1') && !keys.includes('l10')) {
      return false;
    } else {
      return true;
    }
  };

  fetchAttributionData = async attributionLevel => {
    const { productLevelAttribution, productDetails } = this.state;
    const userProfileData = JSON.parse(localStorage.getItem('userProfile'));
    let token = getAuthToken();
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();

    let URL = '';
    if (productLevelAttribution && productDetails.id.length > 0) {
      URL = `${API_BASE_URL}/product-attribution/${productDetails.id}`;
    } else {
      URL = `${API_BASE_URL}/vendor-attribution/${userProfileData.vendors[0].id}`;
    }

    const response = await getApiResponseObject(URL, headers);

    if (successStatusCodes.includes(response.status)) {
      if (this.checkValidAttribution(response.data.data)) {
        response.data.data.id
          ? this.setState({
              isAttributionSet: true,
              disableClearAttributionButton: false,
              attributionId: response.data.data.id,
              invalidData: false,
              attributionData: this.generateAttributionData(
                response.data.data,
                'set'
              )
            })
          : this.setState({
              isAttributionSet: false,
              disableClearAttributionButton: true
            });
      } else {
        this.setState({ invalidData: true });
      }
    } else if (response.status !== resourceNotFoundStatusCode) {
      this.setState({
        isAttributionSet: false,
        disableClearAttributionButton: true
      });
    }
    this.props.hideSpinnerBackdrop();
  };

  updateContentHandler = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { attributionData } = this.state;
    let newAttributionData = attributionData;

    if (name === 'networkCommission' && value > 100) {
      return toaster('Network fee cannot be greater than 100');
    }
    // if (attributionData.attributionConfig.configs.hasOwnProperty(name)) {
    //   newAttributionData = update(newAttributionData, {
    //     attributionConfig: {
    //       configs: {
    //         [name]: { percentage: { $set: value ? Number(value) : '' } }
    //       }
    //     }
    //   });
    // }
    if (name in attributionData.attributionConfig.configs) {
      newAttributionData = update(newAttributionData, {
        attributionConfig: {
          configs: {
            [name]: { percentage: { $set: value ? Number(value) : '' } }
          }
        }
      });
    }
    this.setState({ attributionData: newAttributionData });
  };

  updateAttributionSum = sum => {
    this.setState({ attributionSum: sum });
  };

  clearAttribution = async () => {
    const { productDetails } = this.state;

    this.props.showSpinnerBackdrop();

    const token = getAuthToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token
    };

    this.setState({
      disableUpdateButton: true,
      disableClearAttributionButton: true
    });

    let response = await deleteWithResponseObject(
      `${API_BASE_URL}/product-attribution/${productDetails.id}`,
      headers,
      {}
    );

    this.setState({ disableUpdateButton: false });

    if (successStatusCodes.includes(response.status)) {
      this.resetAttribution();
      this.setState({ isAttributionSet: false, resetAttribution: true });
      this.setState({ resetAttribution: false });
      this.props.hideSpinnerBackdrop();
      return toaster('Attribution details cleared');
    } else {
      this.setState({ disableClearAttributionButton: false });
      this.props.hideSpinnerBackdrop();
      return toaster(response.data.message);
    }
  };

  resetAttribution = () => {
    let attributionData = {
      attributionConfig: {
        configs: {
          networkCommission: {
            percentage: ''
          },
          lIntro: {
            percentage: '',
            timeLimit: '',
            timeLimitUnit: 'infinite'
          },
          lX: {
            percentage: '',
            timeLimit: '',
            timeLimitUnit: 'infinite'
          }
        }
      }
    };
    attributionData = fetchAttributionLevels(attributionData, 10);
    if (this.state.productLevelAttribution) {
      attributionData.productId = this.state.productDetails.id;
    } else {
      attributionData.vendorId = this.state.vendorId;
    }

    this.setState({ attributionData });
  };

  saveAttributionConfig = async () => {
    if (!this.attributionDataComplete()) {
      return toaster(
        'Network Fee and at least one of the Effective Network Fee Division level is required'
      );
    }

    if (!this.minAttributionPercentageSet()) {
      return toaster(
        'Summation of all the Effective Network Fee Divisions must be equal to 100%'
      );
    }

    const {
      attributionId,
      isAttributionSet,
      attributionData,
      productLevelAttribution,
      productDetails
    } = this.state;

    let requestBody = this.generateAttributionData(attributionData, 'get');
    const token = getAuthToken();

    this.props.showSpinnerBackdrop();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: token
    };

    let URL = '';
    if (productLevelAttribution && productDetails.id.length > 0) {
      URL = `${API_BASE_URL}/product-attribution`;
    } else {
      URL = `${API_BASE_URL}/vendor-attribution`;
    }

    let response = '';
    if (isAttributionSet) {
      this.setState({
        disableUpdateButton: true,
        disableClearAttributionButton: true
      });
      response = await putWithResponseObject(
        `${URL}/${attributionId}`,
        requestBody,
        headers
      );
      this.setState({
        disableUpdateButton: false,
        disableClearAttributionButton: false
      });
    } else {
      this.setState({
        disableUpdateButton: true,
        disableClearAttributionButton: true
      });
      if (productLevelAttribution && productDetails.id.length > 0) {
        requestBody = { ...requestBody, productId: productDetails.id };
      }
      response = await postWithResponseObject(URL, requestBody, headers);
      this.setState({
        disableUpdateButton: false,
        disableClearAttributionButton: false
      });
    }

    if (successStatusCodes.includes(response.status)) {
      if (!isAttributionSet && response.data.data.id) {
        let { attributionData } = this.state;
        const newAttributionData = update(attributionData, {
          id: { $set: response.data.data.id }
        });
        this.setState({
          isAttributionSet: true,
          attributionId: response.data.data.id,
          attributionData: newAttributionData
        });
      }
      this.props.hideSpinnerBackdrop();
      return toaster('Attribution details updated successfully');
    } else {
      this.props.hideSpinnerBackdrop();
      return toaster(response.data.message);
    }
  };

  generateAttributionData = (attributionData, type) => {
    let newAttributionData = attributionData;

    Object.keys(attributionData.attributionConfig.configs).forEach(item => {
      if (
        attributionData.attributionConfig.configs[item].percentage === '' ||
        attributionData.attributionConfig.configs[item].percentage === 0
      ) {
        newAttributionData = update(newAttributionData, {
          attributionConfig: {
            configs: {
              [item]: {
                percentage: {
                  $set: type === 'set' ? '' : 0
                }
              }
            }
          }
        });
      }
      if (
        attributionData.attributionConfig.configs[item].timeLimit === '' ||
        attributionData.attributionConfig.configs[item].timeLimit === null
      ) {
        newAttributionData = update(newAttributionData, {
          attributionConfig: {
            configs: {
              [item]: {
                timeLimit: {
                  $set: type === 'set' ? '' : null
                }
              }
            }
          }
        });
      }
    });

    return newAttributionData;
  };

  setAttributionData = data => {
    this.restructureAttributionData(data);
  };

  restructureAttributionData = data => {
    let { attributionData } = this.state;
    let newAttributionData = attributionData;
    let numberOfLevels = data.levels.length;
    newAttributionData = fetchAttributionLevels(
      newAttributionData,
      numberOfLevels
    );
    Object.keys(data).forEach(item => {
      if (item === 'levels') {
        data[item].forEach(
          detail =>
            (newAttributionData = update(newAttributionData, {
              attributionConfig: {
                configs: {
                  [detail.shortName]: {
                    percentage: {
                      $set: detail.active
                        ? detail.splitValue !== ''
                          ? Number(detail.splitValue)
                          : ''
                        : ''
                    },
                    timeLimit: {
                      $set: detail.active ? detail.timeLimit : null
                    },
                    timeLimitUnit: { $set: detail.option.toLowerCase() }
                  }
                }
              }
            }))
        );
      } else {
        data[item].forEach(
          detail =>
            (newAttributionData = update(newAttributionData, {
              attributionConfig: {
                configs: {
                  [detail.shortName]: {
                    percentage: {
                      $set:
                        detail.splitValue !== ''
                          ? Number(detail.splitValue)
                          : ''
                    },
                    timeLimit: { $set: detail.timeLimit },
                    timeLimitUnit: { $set: detail.option.toLowerCase() }
                  }
                }
              }
            }))
        );
      }
    });

    this.setState({ attributionData: newAttributionData });
  };

  attributionDataComplete = () => {
    const { attributionData } = this.state;
    const configs = Object.keys(attributionData.attributionConfig.configs);
    const networkCommissionSet =
      attributionData.attributionConfig.configs.networkCommission.percentage !==
      '';

    if (networkCommissionSet) {
      const filteredConfigs = configs.filter(
        config => config !== 'networkCommission'
      );

      for (const element of filteredConfigs) {
        if (
          attributionData.attributionConfig.configs[element].percentage !== ''
        ) {
          return true;
        }
      }
    }

    return false;
  };

  minAttributionPercentageSet = () => {
    const { attributionData } = this.state;
    const configs = Object.keys(attributionData.attributionConfig.configs);
    let percentagesSum = 0;

    configs
      .filter(config => config !== 'networkCommission')
      .forEach(config => {
        percentagesSum +=
          attributionData.attributionConfig.configs[config].percentage !== ''
            ? attributionData.attributionConfig.configs[config].percentage
            : 0;
      });

    return percentagesSum === 100;
  };

  updateErrorFromEffectiveNetwork = value => {
    this.setState({
      showError: value
    });
  };

  updateExpireTimeErrorFromEffectiveNetwork = value => {
    this.setState({
      showExpireTimeError: value
    });
  };

  render() {
    const {
      attributionData,
      isAttributionSet,
      disableUpdateButton,
      disableClearAttributionButton,
      productLevelAttribution,
      productDetails,
      resetAttribution,
      showError,
      showExpireTimeError,
      attributionSum,
      invalidData
    } = this.state;
    return (
      <Container>
        <InnerContainer>
          <AttributionContainer>
            <Row>
              <div
                style={{
                  width: productLevelAttribution ? '60%' : '100%',
                  paddingRight: '16px'
                }}
              >
                <HeadingSection>
                  <Heading>Configure Attribution</Heading>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={VENDOR_INFO.pageDescription.Attribution}
                    arrow
                  >
                    <InfoRoundedIcon />
                  </Tooltip>
                </HeadingSection>
              </div>
              {productLevelAttribution && (
                <div style={{ width: '40%', paddingLeft: '16px' }}>
                  <Row justifyContent="flex-start">
                    <Image
                      alt={productDetails.title}
                      src={productDetails.imageSrc || DefaultProductImage}
                    />
                    <ProductHeading>{productDetails.title}</ProductHeading>
                  </Row>
                </div>
              )}
            </Row>
            {!invalidData ? (
              <AttributionConfigContainer>
                <SetAttributionContainer min_width={this.props.min_width}>
                  <HeadingSection>
                    <SubHeading>Attribution Percentage</SubHeading>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={ATTRIBUTION.Attribution_Percentage}
                      arrow
                    >
                      <InfoRoundedIcon />
                    </Tooltip>
                  </HeadingSection>
                  <TopContainer>
                    <SetDataContainer>
                      <AttributionData style={{ paddingLeft: 0 }}>
                        {/* <MiniHeading>Network Fee</MiniHeading> */}
                        <TextInput
                          type="number"
                          inputContainerSize="attribution-data"
                          placeHolder="0%"
                          size="attribution-data"
                          name="networkCommission"
                          value={
                            attributionData.attributionConfig.configs
                              .networkCommission.percentage
                          }
                          onChange={this.updateContentHandler}
                        />
                      </AttributionData>
                    </SetDataContainer>
                  </TopContainer>
                </SetAttributionContainer>
                <FloatingContainer>
                  <HeadingSection>
                    <SubHeading>Attribution Fee Breakup</SubHeading>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={ATTRIBUTION.Network_Fee_Division}
                      arrow
                    >
                      <InfoRoundedIcon />
                    </Tooltip>
                    {showError && (
                      <ErrorText>
                        (Effective network fee division cannot exceed 100%)
                      </ErrorText>
                    )}
                    {showExpireTimeError && (
                      <ErrorText>(Expire time cannot be blank)</ErrorText>
                    )}
                    <ChartContainer>
                      <STProgressBar
                        bgcolor={'#F0D64E'}
                        completed={attributionSum}
                      />
                    </ChartContainer>
                  </HeadingSection>
                </FloatingContainer>

                <EffectiveNetworkCommission
                  attributionData={attributionData}
                  networkCommission={
                    attributionData.attributionConfig &&
                    attributionData.attributionConfig.configs.networkCommission
                      .percentage
                  }
                  isAttributionSet={isAttributionSet}
                  sendData={this.setAttributionData}
                  resetAttribution={resetAttribution}
                  updateErrorFromEffectiveNetwork={
                    this.updateErrorFromEffectiveNetwork
                  }
                  updateAttributionSum={this.updateAttributionSum}
                  updateExpireTimeErrorFromEffectiveNetwork={
                    this.updateExpireTimeErrorFromEffectiveNetwork
                  }
                />
                <SaveButtonContainer>
                  {productLevelAttribution && (
                    <ClearAttributionButtonContainer>
                      <Button
                        onClick={this.clearAttribution}
                        disabled={disableClearAttributionButton}
                      >
                        RESET
                      </Button>
                    </ClearAttributionButtonContainer>
                  )}
                  <UpdateButtonContainer>
                    <Button
                      onClick={this.saveAttributionConfig}
                      disabled={
                        disableUpdateButton || showError || showExpireTimeError
                      }
                    >
                      UPDATE
                    </Button>
                  </UpdateButtonContainer>
                </SaveButtonContainer>
              </AttributionConfigContainer>
            ) : (
              <EmptyState errorMessage="Incorrect Attribution Data" />
            )}
          </AttributionContainer>
        </InnerContainer>
      </Container>
    );
  }
}

Attribution.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Attribution));
