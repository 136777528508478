import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Chart from 'react-apexcharts';

const WeeklySalesTrend = props => {
  const { salesTrend, height } = props;

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    representGraph(salesTrend);
  }, [salesTrend]);

  const representGraph = data => {
    setSeries([
      {
        name: 'Sales',
        data: [...data]
      }
    ]);
    setOptions({
      chart: {
        type: 'line',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      colors: ['#F0D64E'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        curve: 'straight',
        width: 3
      },
      xaxis: {
        categories: [],
        enabled: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        categories: [],
        enabled: false,
        labels: {
          show: false
        }
      },
      tooltip: {
        enabled: false
      },
      grid: {
        show: false
      }
    });
  };
  return (
    <Chart
      options={options}
      series={series}
      type="line"
      height={height ? height : '40%'}
      width={'100%'}
    />
  );
};

export default withRouter(WeeklySalesTrend);
