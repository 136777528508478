import styled from 'styled-components';
import { Secondary } from '../../../atoms/button';

const PanelContent = styled.div`
  width: 100%;
  padding: 24px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const VendorsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 12px;
  margin-top: 20px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  padding-bottom: 12px;
`;

// INVITE MODAL
const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 24px;
`;

const ModalHeading = styled.div`
  margin: 14px;
  text-align: center;
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const ModalSubHeading = styled.div`
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin: 12px;
`;

const SmallLabel = styled.div`
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.LIGHT_FONT};
  margin: 10px;
`;

const Actions = styled.div`
  margin: 14px;
`;

const InviteButton = styled(Secondary)`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const ModalBody = styled.div`
  width: 100%;
  height: 100%;
`;

const VendorImage = styled.img`
  margin: auto;
  max-width: 300px;
  width: 100%;
`;

const ProductCategories = styled.div`
  margin: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

// UNLINK VENDOR MODAL
const RemoveVendorModal = styled.div`
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 200px;
  padding: 40px;
  font-size: ${props => props.theme.X_LARGE};
`;

const ConfirmOption = styled(Secondary)`
  width: 100px;
  height: 40px;
  margin: 25px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.SILVER_CHALICE};
  background-color: ${props => props.theme.SPRING_WOOD};
  :hover {
    background-color: ${props => props.theme.RONCHI};
  }
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const Location = styled.div`
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 110px;
`;

export {
  PanelContent,
  Wrapper,
  VendorsContainer,
  PaginationWrapper,
  ModalContainer,
  ModalHeading,
  ModalSubHeading,
  SmallLabel,
  Actions,
  InviteButton,
  ModalBody,
  VendorImage,
  ProductCategories,
  RemoveVendorModal,
  ConfirmOption,
  Location
};
