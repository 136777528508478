import React from 'react';
import CalendarComponent from '../../../atoms/calendar';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  showApiErrorToast,
  getUserProfileNetworks
} from '../../../../utils';
import moment from 'moment';
import { API_BASE_URL } from '../../../../config/env';
import OrdersTable from './Components/ordersTable';
import SortByParams from './Components/SortByParams';
import { toaster } from '../../../atoms/toaster';
import { withStyles } from '@material-ui/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom } from '@material-ui/core';
import {
  Container,
  HeadingSection,
  Heading,
  DetailsContainer,
  Calendar,
  FiltersContainer,
  CalendarContainer
} from './styles';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import MultiSelectAutoComplete from '../../../atoms/MultiSelectAutoComplete';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import EmptyState from '../../../atoms/EmptyState';

const styles = () => ({
  calendarPicker: {
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: '#fff'
  }
});

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchProducts: [],
      ordersAvailable: false,
      totalOrdersCount: 0,
      currentPage: 0,
      currentOffset: 0,
      fetchingOrders: false,
      vendorList: [],
      selectedVendors: [],
      vendorListDataRawFormat: [],
      platformId: '',
      paramsObj: {},
      platformDetails: [],
      newPlatformDetails: [],
      fromDate: '01/02/2021',
      toDate: new Date()
    };
  }

  componentDidMount() {
    this.fetchPlatformId();
  }

  fetchVendorInvites = async () => {
    const { platformId } = this.state;
    const token = getAuthToken();
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/platforms/${platformId}/vendors`,
      headers
    );
    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      return;
    } else {
      const vendorListDataRawFormat = response.data;
      if (!Array.isArray(vendorListDataRawFormat)) {
        return toaster('Unable to fetch products');
      } else {
        let newVendorsList = vendorListDataRawFormat.map(vendor => vendor.name);
        this.setState({
          vendorList: newVendorsList,
          vendorListDataRawFormat: vendorListDataRawFormat
        });
      }
    }
    this.props.hideSpinnerBackdrop();
  };

  fetchPlatformDetails = async (offset, count) => {
    const { platformId, toDate, fromDate, paramsObj } = this.state;
    const vendorId =
      this.state.selectedVendors.length > 0
        ? this.state.selectedVendors.join(',')
        : '';
    const headers = { 'X-Shoptype-VendorIds': vendorId };

    let sortByParam;
    let order;

    if (
      paramsObj?.sortByParam !== undefined &&
      paramsObj?.order !== undefined
    ) {
      sortByParam = paramsObj.sortByParam;
      order = paramsObj.order;
    } else {
      sortByParam = 'createdAt';
      order = 'desc';
    }

    let finalToDate = moment(toDate).format('YYYY-MM-DD');
    let finalFromDate = moment(fromDate).format('YYYY-MM-DD');

    let finalUrl;
    this.props.showSpinnerBackdrop();

    finalUrl = `${API_BASE_URL}/platforms/${platformId}/orders?sortBy=${sortByParam}&orderBy=${order}&startDate=${finalFromDate}&endDate=${finalToDate}&count=${count}&offset=${offset}`;

    const response = await getApiResponseObject(finalUrl, headers);
    if (response.data) {
      const finalPlatform = this.updatePlatformDetails(response.data.data);
      this.setState({
        platformDetails: response.data.data,
        ordersAvailable: true,
        totalOrdersCount: response?.data?.count || 0,
        isLoading: false
      });
      this.props.hideSpinnerBackdrop();
      return finalPlatform;
    } else {
      this.setState({
        ordersAvailable: false,
        isLoading: false
      });
      this.props.hideSpinnerBackdrop();
      return response.data;
    }
  };

  updatePlatformDetails = detailer => {
    const { vendorListDataRawFormat, newPlatformDetails } = this.state;
    let newDetails = [];
    let i;
    let j;
    let o = {};
    let obj = {};
    let finalDetails = [];
    if (detailer) {
      if (vendorListDataRawFormat.length == 0) {
        finalDetails = detailer;
      } else {
        for (i = 0; i <= detailer.length; i++) {
          let count = 0;
          if (detailer[i] == undefined) {
            continue;
          } else {
            for (j = 0; j <= vendorListDataRawFormat.length; j++) {
              if (vendorListDataRawFormat[j] == undefined) {
                continue;
              } else {
                if (detailer[i].vendorId == vendorListDataRawFormat[j].id) {
                  o = Object.assign({}, detailer[i]);
                  o.vendorName = vendorListDataRawFormat[j].name;
                  newDetails.push(o);
                } else {
                  count = count + 1;
                }
              }
            }
          }
          if (count == vendorListDataRawFormat.length) {
            obj = Object.assign({}, detailer[i]);
            finalDetails.push(obj);
          }
        }
      }

      finalDetails = finalDetails.concat(newDetails);

      this.setState({
        newPlatformDetails: finalDetails
      });
      return finalDetails;
    }
  };

  fetchPlatformId = () => {
    const networkData = getUserProfileNetworks();
    const platformId = networkData?.platforms[0]?.id;
    this.setState({ platformId }, () => {
      this.fetchPlatformDetails(this.state.currentOffset, 6);
      this.fetchVendorInvites();
    });
  };

  fetchParamsObj = childData => {
    this.setState({ paramsObj: childData }, () => {
      this.fetchPlatformDetails(this.state.currentOffset, 6);
    });
  };

  updateSelectedVendors = selectedVendorsNames => {
    const { vendorListDataRawFormat } = this.state;
    const resArr = vendorListDataRawFormat.filter(vendor =>
      selectedVendorsNames.includes(vendor.name)
    );
    const selectedVendors = resArr.map(item => item.id);
    this.setState({ selectedVendors: selectedVendors }, () => {
      this.fetchPlatformDetails(this.state.currentOffset, 6);
    });
  };

  handleChangePage = async (event, newPage) => {
    if (this.state.fetchingOrders) return;
    const { currentPage, currentOffset } = this.state;
    const fetchOffset =
      newPage > currentPage ? currentOffset + 6 : currentOffset - 6;

    this.setState({ fetchingOrders: true });

    const responsePlatformdetails = await this.fetchPlatformDetails(
      fetchOffset,
      6
    );

    if (responsePlatformdetails) {
      const fetchPlatformDetails = responsePlatformdetails;
      if (!Array.isArray(fetchPlatformDetails)) {
        return toaster('Unable to fetch orders');
      }
      this.setState({
        newPlatformDetails: fetchPlatformDetails,
        currentPage: newPage,
        currentOffset: fetchOffset,
        fetchingOrders: false
      });
    } else {
      this.setState({ fetchingOrders: false });
      return toaster('Unable to fetch orders');
    }
  };

  handleFromDateChange = date => {
    this.setState({ fromDate: date }, () => {
      this.fetchPlatformDetails(this.state.currentOffset, 6);
    });
  };

  handleToDateChange = date => {
    this.setState({ toDate: date }, () => {
      this.fetchPlatformDetails(this.state.currentOffset, 6);
    });
  };

  render() {
    const {
      isLoading,
      fetchProducts,
      totalOrdersCount,
      currentPage,
      fetchingOrders,
      ordersAvailable,
      vendorList,
      newPlatformDetails,
      fromDate,
      toDate,
      platformDetails
    } = this.state;

    const { classes } = this.props;
    return (
      <Container>
        <HeadingSection>
          <Heading>Order details</Heading>
          <Tooltip
            TransitionComponent={Zoom}
            title={NETWORK_OPERATOR_INFO.pageDescription.Orders}
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </HeadingSection>
        <FiltersContainer>
          <MultiSelectAutoComplete
            vendorsList={vendorList}
            updateSelectedVendors={this.updateSelectedVendors}
            width="300px"
            label="Select Vendors"
            size="small"
          />
          <CalendarContainer>
            <CalendarComponent
              className={classes.calendarPicker}
              size="small"
              date={fromDate}
              handleDateChange={this.handleFromDateChange}
              label="Orders From "
            />
            <CalendarComponent
              className={classes.calendarPicker}
              size="small"
              date={toDate}
              handleDateChange={this.handleToDateChange}
              label="Orders To "
            />
          </CalendarContainer>
          <SortByParams size="small" fetchParamsObj={this.fetchParamsObj} />
        </FiltersContainer>
        <DetailsContainer>
          {!isLoading && ordersAvailable && !isEmpty(platformDetails) ? (
            <OrdersTable
              orderDetails={fetchProducts}
              newOrderDetails={newPlatformDetails}
              totalOrdersCount={totalOrdersCount}
              currentPage={currentPage}
              fetchingOrders={fetchingOrders}
              handleChangePage={this.handleChangePage}
            />
          ) : (
            !isLoading &&
            isEmpty(platformDetails) && (
              <EmptyState errorMessage="No order details available" />
            )
          )}
        </DetailsContainer>
      </Container>
    );
  }
}

OrderDetails.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withStyles(styles)(
  withRouter(connect(null, mapDispatchToProps)(OrderDetails))
);
