import styled from 'styled-components';

const Container = styled.div`
  display: block;
  max-width: 320px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const SubHeading = styled.span`
  font-size: ${props => props.theme.SMALL};
  font-weight: 400;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  margin-top: 20px;
`;

export { Container, Heading, SubHeading, FormContainer };
