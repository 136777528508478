import React from 'react';
import { makeStyles } from '@material-ui/styles';
import shoptypeThemes from '../../../theme';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  message: {
    fontWeight: shoptypeThemes.default.LIGHT_FONT
  }
});

const EmptyState = props => {
  const classes = useStyles();
  const { errorMessage, message, height } = props;

  return (
    <Box height={height ? height : '50vh'} className={classes.emptyState}>
      <Typography variant="h6" className={classes.message}>
        <i className="fas fa-times-circle danger" />
        &nbsp;{errorMessage}
      </Typography>
      {message && (
        <Typography variant="h6" className={classes.message}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default EmptyState;
