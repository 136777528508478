import styled, { css } from 'styled-components';

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;

  ${props => props.css};

  ${props => props.theme.TABLET`
    width: 100%;
  `};
  ${props => {
    if (
      props.inputContainerSize === 'variant-details' ||
      props.inputContainerSize === 'attribution-data'
    ) {
      return css``;
    } else {
      return css`
        border-bottom: 1px solid black;
      `;
    }
  }}
`;

const Input = styled.input`
  width: 100%;
  max-width: 100%;
  transition: all 200ms ease;
  text-align: center;

  ${props => props.theme.PHONE`
    text-align: left;
  `};

  ${props => props.theme.TABLET`
    text-align: left;
  `};

  :disabled {
    background: ${props => props.theme.BLACK};
    border-color: transparent;
  }

  ::placeholder {
    text-align: center;
    color: #aaa;
  }

  :disabled ~ label {
    color: ${props => props.theme.BLACK};
    background: ${props => props.theme.BLACK};
  }

  :required:invalid ~ span:nth-of-type(1) {
    position: absolute;
    top: 16px;
    right: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${props => props.theme.RONCHI};
    vertical-align: middle;
    text-align: center;
  }

  ${props =>
    props.filled
      ? css`
          ~ span:nth-child(1) {
            display: none;
          }
        `
      : ''};

  ${props =>
    props.inputType === 'primary'
      ? css`
          font-size: ${props.theme.MEDIUM};

          padding: 11px 15px;
          font-weight: ${props.theme.MEDIUM_FONT};
          border: 0;
          outline: 0;
          background: transparent;

          :focus {
            outline: none;
            font-weight: ${props.theme.MEDIUM_FONT};
          }

          :focus ~ label {
            display: none;
          }

          ${props.touched
            ? css`
                :invalid {
                  box-shadow: none;
                }

                :invalid ~ label {
                  color: ${props.theme.BLACK};
                }
              `
            : ''};

          :optional {
            ${props.touched
              ? css`
                  :invalid ~ span:nth-of-type(2) {
                    display: block;
                  }
                `
              : ''};
          }
        `
      : ''};

  @media (min-width: 768px) {
    ${p =>
      ({
        'default-size': css`
          width: 300px;
          height: 40px;
        `,
        'small-size': css`
          width: 240px;
          height: 40px;

          :required:invalid ~ span:nth-of-type(1) {
            right: 22px;
          }
        `,
        'large-size': css`
          width: 500px;
          height: 100px;
        `,
        'import-products-modal': css`
          width: 100%;
        `,
        'contact-card-size': css`
          width: 200px;
          height: 40px;

          :required:invalid ~ span:nth-of-type(1) {
            right: 12px;
          }
        `,
        'edit-detail-size': css`
          width: 100%;
          height: 40px;
          }`,
        'variant-details': css`
          width: 120px;
          height: 40px;
          background-color: white;
          }`,
        'attribution-data': css`
          width: 100%;
          min-width: 230px;
          height: 40px;
          font-size:  ${props => props.theme.SMALL};
          font-weight: ${props => props.theme.MEDIUM_FONT};
          background-color: ${props => (props.isDisabled ? '#B2B2B2' : '#FFF')};
          border: 1px solid ${props => props.theme.PINK_SWAN};
          border-radius: 5px;
          text-align: left;
          }`,
        'dropdown-searchbar': css`
          width: 100%;
          text-align: left;
          font-size: ${props => props.theme.LARGE};
          ::placeholder {
            text-align: left;
          }
        `
      }[p.size] ||
      css`
        width: 100%;
        height: 40px;
      `)};
  }
`;

const ErrorSymbol = styled.span`
  display: none;
  position: absolute;
  top: 13px;
  right: 12px;
  width: 14px;
  height: 14px;
  line-height: 11px;
  border-radius: 75px;
  background-color: ${props => props.theme.RONCHI};
  vertical-align: middle;
  text-align: center;
`;

const ErrorExclamation = styled.p`
  display: inline;
  font-size: ${props => props.theme.X_SMALL};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${props => props.theme.WHITE};
`;

const MandatoryDot = styled.span``;

export { InputContainer, Input, ErrorSymbol, ErrorExclamation, MandatoryDot };
