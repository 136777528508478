import styled, { css } from 'styled-components';

const Card = styled.div`
  width: 100%;
  /* height: 100%; */

  ${props =>
    props.isDark
      ? css`
          background-color: ${props.theme.GALLERY};
          color: ${props.theme.WHITE};
        `
      : css`
          background-color: ${props.theme.WHITE};
          box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          object-fit: contain;
        `};

  ${props => props.css};
`;

export default Card;
