/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Container,
  Accordion,
  withStyles,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  ButtonGroup,
  Chip,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Editor from '../../../molecules/TinyMCEEditor';
import { API_BASE_URL } from '../../../../config/env';
import { useDispatch } from 'react-redux';
import { toaster } from '../../../atoms/toaster';
import * as actions from '../../../../redux/actions/uiActions';
import {
  getAuthToken,
  getApiResponseObject,
  postWithResponseObject,
  deleteWithResponseObject,
  putWithResponseObject,
  successStatusCodes,
  uploadImageVideoHandler
} from '../../../../utils';
import Loader from '../../../atoms/loader';

const useStyles = () => ({
  templatesMenu: {
    display: 'grid',
    width: '100%'
  },
  templatesItem: {
    'margin-left': '10px'
  },
  templatesList: {
    'margin-bottom': '10px'
  },
  templateActionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  templateActionsBtn: {
    'margin-left': '10px'
  },
  templateNameField: {
    'margin-bottom': '10px'
  }
});

const ManageTemplates = props => {
  const [expanded, setExpanded] = useState(false);
  const { classes } = props;
  const editorRef = useRef(null);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [templateTypes, setTemplateTypes] = useState(null);
  const [templateSettings, setTemplateSettings] = useState(null);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateTypeId, setSelectedTemplateTypeId] = useState(null);

  const getNotificationTemplateTypesSettings = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    dispatch(actions.showSpinnerBackdropAction());

    const typesResponse = await getApiResponseObject(
      `${API_BASE_URL}/notifications/templates/types`,
      headers
    );
    const settingsResponse = await getApiResponseObject(
      `${API_BASE_URL}/notifications/templates/settings`,
      headers
    );

    if (
      successStatusCodes.includes(typesResponse.status) &&
      typesResponse.data
    ) {
      setTemplateTypes(typesResponse.data);
      // Setting the first template as expanded by default
      setSelectedTemplateTypeId(typesResponse.data[0].id);
      setExpanded(typesResponse.data[0].id);
    } else {
      toaster('Unable to fetch data');
    }

    if (settingsResponse.status == 204) {
      setTemplateSettings({ settings: {} });
    } else {
      if (
        successStatusCodes.includes(settingsResponse.status) &&
        settingsResponse.data
      ) {
        setTemplateSettings(settingsResponse.data);
      } else {
        toaster('Unable to fetch data');
      }
    }

    dispatch(actions.hideSpinnerBackdropAction());
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedTemplateTypeId && selectedTemplate) {
      getNotificationTemplatesByType(selectedTemplateTypeId, false);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    // Getting the notification template of 1st Accordion (Referral Invite) by default on mount after setting templateTypes
    if (templateTypes) {
      getNotificationTemplatesByType(templateTypes[0].id, true);
    }
  }, [templateTypes]);

  const setSelectedTemplateAsActive = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    dispatch(actions.showSpinnerBackdropAction());

    const res = await putWithResponseObject(
      `${API_BASE_URL}/notifications/templates/settings`,
      {
        notification_type_name: selectedTemplate.templateType,
        notification_template_title: selectedTemplate.title,
        notification_template_id: selectedTemplate.id
      },
      headers
    );

    if (!successStatusCodes.includes(res.status)) {
      toaster('Unable to update Template settings');
    }

    toaster('Template Settings updated successfully');
    dispatch(actions.hideSpinnerBackdropAction());
    await getNotificationTemplateTypesSettings();
  };

  const createNewTemplate = () => {
    const templateType = templateTypes.find(
      t => t.id == selectedTemplateTypeId
    );
    const defaultTemplate = templateType.templates.find(t =>
      isTemplateDefault(t.id, templateType.title)
    );
    const newTemplate = {
      title: 'New Template',
      content: defaultTemplate.content,
      templateTypeId: templateType.id,
      templateType: templateType.title,
      isDraft: true
    };

    setSelectedTemplate(newTemplate);
  };

  const escapeHtml = code => {
    // return code.replaceAll('/', '\/').replaceAll('"', '\"');
    return code;
  };

  const unescapeHtml = code => {
    // return code.replaceAll('\/', '/').replaceAll('\"', '"');
    return code;
  };

  const getNotificationTemplatesByType = async (
    templateTypeId,
    selectFirst = false
  ) => {
    let templateType = templateTypes.find(t => t.id == templateTypeId);
    if (templateType) {
      if (!templateType.templates) {
        const token = getAuthToken();
        const headers = { authorization: token };
        dispatch(actions.showSpinnerBackdropAction());

        const templatesResponse = await getApiResponseObject(
          `${API_BASE_URL}/notifications/templates/type/${templateTypeId}`,
          headers
        );
        if (
          successStatusCodes.includes(templatesResponse.status) &&
          templatesResponse.data
        ) {
          const templates = templatesResponse.data.map(t => {
            t.content = unescapeHtml(t.content);
            return t;
          });
          templateType = templateTypes.find(t => t.id == templateTypeId);
          templateType.templates = templates;
          setTemplateTypes(Object.assign([], templateTypes));
          if (selectFirst) {
            setSelectedTemplate(templateType.templates[0]);
          }
          // if(selectedTemplateId) {
          //     const template = templateType.templates.find(t => t == selectedTemplateId);
          //     setSelectedTemplate(Object.assign({}, template));
          // }
        } else {
          toaster('Unable to fetch data');
        }

        dispatch(actions.hideSpinnerBackdropAction());
      } else {
        if (selectFirst) {
          setSelectedTemplate(templateType.templates[0]);
        }
      }
    }
  };

  useEffect(() => {
    getNotificationTemplateTypesSettings();
  }, []);

  const handleAccordionChange = id => (event, newExpanded) => {
    setSelectedTemplateTypeId(id);
    getNotificationTemplatesByType(id, true);
    setExpanded(newExpanded ? id : false);
  };

  const isTemplateActive = (templateId, type) => {
    if (templateSettings.settings[type]) {
      return (
        templateSettings.settings[type].notification_template_id == templateId
      );
    } else {
      let templateType = templateTypes.find(t => type == t.title);
      return templateType.default_template_id == templateId;
    }
  };

  const isTemplateDefault = (templateId, type) => {
    let templateType = templateTypes.find(t => type == t.title);
    return templateType.default_template_id == templateId;
  };

  const saveSelectedTemplate = async saveAsDraft => {
    const token = getAuthToken();
    const headers = { authorization: token };
    dispatch(actions.showSpinnerBackdropAction());

    const template = Object.assign({}, selectedTemplate);
    template.isDraft = saveAsDraft;
    template.content = escapeHtml(template.content);

    if (!selectedTemplate.id) {
      const res = await postWithResponseObject(
        `${API_BASE_URL}/notifications/templates`,
        template,
        headers
      );

      if (successStatusCodes.includes(res.status)) {
        toaster('Template saved successfully');
        setSelectedTemplate(res.data);
        await getNotificationTemplateTypesSettings();
      } else {
        toaster('Unable to save template');
      }
    } else {
      const res = await putWithResponseObject(
        `${API_BASE_URL}/notifications/templates/${template.id}`,
        {
          templateTypeId: template.templateTypeId,
          content: template.content,
          title: template.title,
          isDraft: template.isDraft
        },
        headers
      );

      if (successStatusCodes.includes(res.status)) {
        toaster('Template updated successfully');
        getNotificationTemplateTypesSettings();
      } else {
        toaster('Unable to update template');
      }
    }

    dispatch(actions.hideSpinnerBackdropAction());
  };

  const deleteSelectedTemplate = async (templateId, templateType) => {
    if (isTemplateActive(templateId, templateType)) {
      toaster('Unable to delete an active template');
    }

    if (templateId) {
      const token = getAuthToken();
      const headers = { authorization: token };
      dispatch(actions.showSpinnerBackdropAction());

      const res = await deleteWithResponseObject(
        `${API_BASE_URL}/notifications/templates/type/${templateType}/id/${templateId}`,
        headers
      );

      if (successStatusCodes.includes(res.status)) {
        toaster('Template deleted successfully');
        await getNotificationTemplateTypesSettings();
        setSelectedTemplate(null);
      } else {
        toaster('Unable to delete template');
      }

      dispatch(actions.hideSpinnerBackdropAction());
    } else {
      setSelectedTemplate(null);
    }
  };

  const onEditorChange = content => {
    setSelectedTemplate({ ...selectedTemplate, content: content });
  };

  const onTitleChange = content => {
    setSelectedTemplate({ ...selectedTemplate, title: content });
  };

  return isLoading || !templateTypes ? (
    ''
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h6">Notifications</Typography>
        {templateTypes.map(templateType => (
          <Accordion
            key={templateType.id}
            expanded={expanded === templateType.id}
            onChange={handleAccordionChange(templateType.id)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{templateType.description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.templatesMenu}>
                <div className={classes.templatesList}>
                  {templateType.templates ? (
                    <ButtonGroup
                      fullWidth
                      orientation="vertical"
                      aria-label="vertical contained button group"
                      variant="text"
                    >
                      {templateType.templates.map(template => {
                        let isSelected = selectedTemplate
                          ? selectedTemplate.id == template.id
                          : false;
                        return (
                          <Button
                            key={template.id}
                            color={isSelected ? 'primary' : 'default'}
                            variant={isSelected ? 'contained' : 'text'}
                            onClick={() => setSelectedTemplate(template)}
                            fullWidth
                          >
                            {isTemplateDefault(
                              template.id,
                              template.templateType
                            ) ? (
                              <Chip
                                color="default"
                                label="Default"
                                size="small"
                              />
                            ) : (
                              ''
                            )}
                            {isTemplateActive(
                              template.id,
                              template.templateType
                            ) ? (
                              <Chip
                                color="primary"
                                label="Active"
                                size="small"
                              />
                            ) : (
                              ''
                            )}
                            {template.isDraft ? (
                              <>
                                <Chip
                                  color="secondary"
                                  label="Draft"
                                  size="small"
                                />{' '}
                                {template.isDraft}
                              </>
                            ) : (
                              ''
                            )}
                            <span className={classes.templatesItem}>
                              {template.title}
                            </span>
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  ) : (
                    ''
                  )}
                </div>
                <Button variant="contained" onClick={() => createNewTemplate()}>
                  + Add Template
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      <Grid item xs={8}>
        {selectedTemplate == null ? (
          <Loader isFlex={true} w={'100%'} isCenter={true} />
        ) : (
          <Card>
            <CardHeader
              action={
                selectedTemplate.id ? (
                  <>
                    {isTemplateActive(
                      selectedTemplate.id,
                      selectedTemplate.templateType
                    ) ? (
                      <Chip
                        variant="outlined"
                        label="Active Template"
                        color="primary"
                      />
                    ) : !selectedTemplate.isDraft ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedTemplateAsActive()}
                      >
                        Set as Active
                      </Button>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )
              }
              title={selectedTemplate.title}
            />
            <CardContent>
              <TextField
                disabled={isTemplateDefault(
                  selectedTemplate.id,
                  selectedTemplate.templateType
                )}
                className={classes.templateNameField}
                value={selectedTemplate.title}
                onChange={e => onTitleChange(e.target.value)}
                fullWidth
                label="Title"
                variant="standard"
              />
              <Editor
                onInit={(_evt, editor) => (editorRef.current = editor)}
                value={selectedTemplate.content}
                onEditorChange={content => onEditorChange(content)}
                disabled={isTemplateDefault(
                  selectedTemplate.id,
                  selectedTemplate.templateType
                )}
                init={{
                  height: 375,
                  branding: false,
                  plugins: 'emoticons hr image link lists charmap table',
                  toolbar1:
                    'bold italic underline strikethrough | forecolor backcolor | superscript subscript | removeformat | paragraphgroup insertgroup | h1 h2 h3',
                  toolbar2:
                    'bullist numlist | alignleft aligncenter alignright | indent outdent | link image emoticons charmap hr',
                  skin: 'outside',
                  menubar: false,
                  images_upload_url: 'command?type=addMedia',
                  images_upload_handler: blobInfo =>
                    new Promise(resolve => {
                      const blob = blobInfo.blob();
                      uploadImageVideoHandler(
                        {
                          target: {
                            files: [
                              new File([blob], blobInfo.filename(), {
                                type: blob.type
                              })
                            ]
                          }
                        },
                        url => {
                          resolve(url);
                        },
                        dispatch
                      );
                    })
                }}
              />
            </CardContent>
            <CardActions>
              <div className={classes.templateActionsContainer}>
                <div />
                <div>
                  {!isTemplateActive(
                    selectedTemplate.id,
                    selectedTemplate.templateType
                  ) &&
                  !isTemplateDefault(
                    selectedTemplate.id,
                    selectedTemplate.templateType
                  ) ? (
                    <Button
                      className={classes.templateActionsBtn}
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        deleteSelectedTemplate(
                          selectedTemplate.id,
                          selectedTemplate.templateType
                        )
                      }
                    >
                      Delete
                    </Button>
                  ) : (
                    ''
                  )}
                  {(selectedTemplate.isDraft &&
                    !isTemplateDefault(
                      selectedTemplate.id,
                      selectedTemplate.templateType
                    )) ||
                  !selectedTemplate.id ? (
                    <Button
                      className={classes.templateActionsBtn}
                      variant="outlined"
                      color="default"
                      onClick={() => saveSelectedTemplate(true)}
                    >
                      Save as Draft
                    </Button>
                  ) : (
                    ''
                  )}
                  {!isTemplateDefault(
                    selectedTemplate.id,
                    selectedTemplate.templateType
                  ) ? (
                    <Button
                      className={classes.templateActionsBtn}
                      variant="contained"
                      color="primary"
                      onClick={() => saveSelectedTemplate(false)}
                    >
                      Save
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </CardActions>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(ManageTemplates);
