import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ResetPasswordTab from '../../molecules/ResetPasswordTab';

import { Container, Heading, FormContainer } from './styles';

const ResetPassword = () => {
  return (
    <Container>
      <Heading>Reset Password</Heading>
      <FormContainer>
        <ResetPasswordTab />
      </FormContainer>
    </Container>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(ResetPassword);
