import styled from 'styled-components';

const Separator = styled.div`
  width: 100%;
  padding: 0 30px;
  margin: 18px 0;
  display: flex;
  align-items: center;
  text-align: center;

  &:before,
  :after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }

  &:before {
    margin-right: 0.25em;
  }

  &:after {
    margin-left: 0.25em;
  }
`;

export { Separator };
