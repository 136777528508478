import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ApiKey, Row, CopyButtonContainer } from './styles';
import { toaster } from '../../../atoms/toaster';

export default function ViewKeyDialog({
  open,
  getUpdatedApiKeys,
  handleCloseDialog,
  secretKey
}) {
  const closeDialog = () => {
    getUpdatedApiKeys();
    handleCloseDialog();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(secretKey);
    toaster('Copied to clipboard!');
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}
    >
      <DialogTitle onClose={closeDialog}>Copy API Key</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          API Key generated successfully. Make sure to copy your new API Key now
          as the key will be hidden once you close this window.
        </DialogContentText>
        <Row>
          <ApiKey>{secretKey}</ApiKey>
          <CopyButtonContainer>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FileCopyIcon />}
              onClick={handleCopy}
            >
              Copy
            </Button>
          </CopyButtonContainer>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
