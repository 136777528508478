import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ResendVerificationEmailTab from '../../molecules/ResendVerificationEmailTab';

import { Container, Heading, SubHeading, FormContainer } from './styles';

const ResendVerificationEmail = () => {
  return (
    <Container>
      <Heading>Resend Verification Email</Heading>
      <SubHeading>
        Please enter your email address. A confirmation email will be sent to
        your email address.
      </SubHeading>
      <FormContainer>
        <ResendVerificationEmailTab />
      </FormContainer>
    </Container>
  );
};

ResendVerificationEmail.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(ResendVerificationEmail);
