import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player';
import Icons from '../../atoms/icons';
import { isEmpty } from 'lodash';

import {
  CarouselContainer,
  CarouselElementContainer,
  DownArrow,
  UpArrow
} from './styles';
import { withStyles } from '@material-ui/core';

const useStyles = theme => ({
  dots: {
    bottom: '-110px',
    '& li.slick-active button::before': {
      height: '11px',
      width: '12px',
      background: 'gray',
      borderRadius: '50%',
      color: 'transparent',
      content: ''
    },
    '& li': {
      '& button::before': {
        color: '#fff',
        opacity: 0.5,
        background: 'transparent'
      }
    }
  }
});
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <DownArrow className={className} onClick={onClick}>
      <Icons name="down-arrow" width={20} height={20} />
    </DownArrow>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <UpArrow className={className} onClick={onClick}>
      <Icons name="up-arrow" width={20} height={20} />
    </UpArrow>
  );
}

class Carousel extends React.Component {
  render() {
    const {
      orientation,
      width,
      height,
      margin,
      imagesArray,
      videosArray,
      elementClick,
      classes,
      showDots,
      slidesToShow,
      className
    } = this.props;

    var settings = {
      dots: showDots ? true : false,
      arrows: true,
      speed: 500,
      infinite: false,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      draggable: false,
      dotsClass: `slick-dots ${classes.dots}`,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrow: false,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            draggable: false
          }
        }
      ]
    };

    if (isEmpty(imagesArray) && isEmpty(videosArray)) {
      return null;
    } else {
      return (
        <CarouselContainer
          orientation={orientation}
          width={width}
          height={height}
          margin={margin}
          className={className}
        >
          <Slider {...settings}>
            {imagesArray.length > 0
              ? imagesArray.map((imageElementSrc, index) => {
                  return (
                    <CarouselElementContainer
                      key={index}
                      onClick={() => {
                        elementClick(imageElementSrc, 'image');
                      }}
                    >
                      <img src={imageElementSrc} alt="secondary-images" />
                    </CarouselElementContainer>
                  );
                })
              : null}
            {!isEmpty(videosArray)
              ? videosArray.map(videoElementSrc => {
                  return (
                    <CarouselElementContainer
                      key="1"
                      onClick={() => {
                        elementClick(videoElementSrc, 'video');
                      }}
                    >
                      <ReactPlayer
                        url={videoElementSrc}
                        width="120px"
                        height="130px"
                      />
                    </CarouselElementContainer>
                  );
                })
              : null}
          </Slider>
        </CarouselContainer>
      );
    }
  }
}

Carousel.defaultProps = {
  imagesArray: [],
  videosArray: []
};

Carousel.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  imagesArray: PropTypes.array,
  videosArray: PropTypes.array,
  onClick: PropTypes.func,
  margin: PropTypes.number,
  orientation: PropTypes.string,
  elementClick: PropTypes.func,
  showDots: PropTypes.bool,
  slidesToShow: PropTypes.number,
  className: PropTypes.string
};

SampleNextArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

SamplePrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default withStyles(useStyles)(withRouter(Carousel));
