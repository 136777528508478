import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 48px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const KeysContainer = styled.div`
  margin: 24px 0;
`;

const ApiKeyRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 14px 0;
`;

const ApiKeyItem = styled.div`
  min-width: 190px;
  max-width: 200px;
  margin: 8px;
`;

const ApiKeyActions = styled.div`
  margin: 8px;
`;

const Row = styled.div`
  margin: 24px 8px;
`;

const ErrorMessageContainer = styled.div`
  margin: 24px 8px;
`;

export {
  Container,
  Heading,
  KeysContainer,
  ApiKeyRow,
  ApiKeyItem,
  ApiKeyActions,
  Row,
  ErrorMessageContainer
};
