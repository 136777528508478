import { CURRENCY_SYMBOL } from '../../../constants';

const Currency = ({ currencyName }) => {
  const currency = currencyName;
  if (CURRENCY_SYMBOL[currency]) {
    return CURRENCY_SYMBOL[currency];
  } else {
    return CURRENCY_SYMBOL.USD;
  }
};

Currency.propTypes = {};

export default Currency;
