import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const MainSection = styled.div``;

const InnerContainer = styled.div`
  color: ${props => props.theme.MINE_SHAFT};
  display: block;
`;

const Content = styled.div`
  padding: 24px 48px;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

export {
  Container,
  MainSection,
  Content,
  HeadingSection,
  Heading,
  InnerContainer
};
