import React from 'react';

const STProgressBar = props => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 5,
    margin: 50
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed <= 100 ? completed : 100}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'center',
    paddingLeft: '10px'
  };

  const labelStyles = {
    padding: 5,
    color: 'black',
    fontSize: '12px'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default STProgressBar;
