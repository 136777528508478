import React from 'react';
import PaginationControls from '../../../../atoms/paginationControls';
import Currency from '../../../../molecules/Currency';
import DefaultProductImage from '../../../../../assets/images/photo.svg';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  ProductLevelAnalyticsContainer,
  ProductListItems,
  ProductLevelAnalyticsContent,
  ProductDataPointTitle,
  ProductDataPoints
} from '../styles';

const ProductLevelAnalytics = props => {
  const PRODUCT_LEVEL_ANALYTICS_TITLE = [
    '',
    'VENDOR',
    'PRODUCT',
    'PRICE',
    'EARNING',
    'CLICKS',
    'PUBLISHES'
  ];

  const useStyles = makeStyles({
    paginationBtn: {
      border: 'none',
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent !important',
        boxShadow: 'none'
      }
    }
  });

  const classes = useStyles();
  const {
    analyticsData,
    fetchParams,
    totalResultsCount,
    updateFetchParams
  } = props;

  return (
    <div>
      <ProductLevelAnalyticsContainer>
        <ProductDataPointTitle>
          {PRODUCT_LEVEL_ANALYTICS_TITLE.map((title, i) => (
            <div key={i}>{title}</div>
          ))}
        </ProductDataPointTitle>
        <ProductListItems>
          <ProductLevelAnalyticsContent>
            {analyticsData.map((dataPoints, i) => {
              return (
                <ProductDataPoints key={i}>
                  <img
                    src={dataPoints.image_url || DefaultProductImage}
                    alt="product"
                  />
                  <div>{dataPoints.vendorName || 'NA'}</div>
                  <div>{dataPoints.title || 'NA'}</div>
                  <div>
                    <Currency currencyName={dataPoints.currency} />
                    {dataPoints.price >= 0 ? dataPoints.price : 'NA'}
                  </div>
                  <div>
                    <Currency currencyName={dataPoints.currency} />
                    {dataPoints.total_commissions >= 0
                      ? dataPoints.total_commissions
                      : 'NA'}
                  </div>
                  <div>
                    {dataPoints.total_clicks >= 0
                      ? dataPoints.total_clicks
                      : 'NA'}
                  </div>
                  <div>
                    {dataPoints.total_publishes >= 0
                      ? dataPoints.total_publishes
                      : 'NA'}
                  </div>
                </ProductDataPoints>
              );
            })}
          </ProductLevelAnalyticsContent>
        </ProductListItems>
        <PaginationControls
          paginationBtn={classes.paginationBtn}
          fetchParams={fetchParams}
          totalItemCount={totalResultsCount}
          updateFetchParams={updateFetchParams}
        />
      </ProductLevelAnalyticsContainer>
    </div>
  );
};

ProductLevelAnalytics.propTypes = {
  analyticsData: PropTypes.array,
  isLoading: PropTypes.bool
};

export default ProductLevelAnalytics;
