export const PAGE_ORDERS_COUNT = 6;

export const ORDER_STATUS = {
  pending: 'pending',
  pickup_initiated: 'pickup_initiated',
  items_received: 'items_received',
  refund_initiated: 'refund_initiated',
  declined: 'declined',
  complete: 'complete'
};

export const ORDER_STATUS_LABEL = {
  pending: 'Pending',
  declined: 'Declined',
  complete: 'Complete',
  refund_initiated: 'Refund Initiated'
};

export const ORDER_ACTION = {
  approve: 'approve',
  decline: 'decline'
};

export const ORDER_ACTION_VALUE = {
  approve: 'pickup_initiated',
  decline: 'declined',
  items_received: 'items_received',
  refund_initiated: 'refund_initiated'
};

export const REQUEST_APPROVED_SUB_STATUSES = [
  'pickup_initiated',
  'items_received',
  'refund_initiated'
];

export const ORDER_ACTION_DIALOG_INFORMATION = {
  approve: {
    title: 'Approve Request?',
    description:
      'If you Approve this request, then reverse pickup (if any) & refund process for the listed items shall be initiated & you won\'t be able to decline it later. Continue?'
  },
  decline: {
    title: 'Decline Request?',
    description:
      'If you Decline this request, then the refund process shall not be initiated, but the buyer still gets to place a new request within the return period. Continue?'
  },
  items_received: {
    title: 'Change Status?',
    description:
      'The buyer shall be notified about the same and you won\'t be able to undo the chosen actions. Continue?'
  },
  refund_initiated: {
    title: 'Change Status?',
    description:
      'If you initiate the refund, the order amount goes back to the buyer. You\'ll be charged a small fee to cover up for the extra charges involved. Continue?'
  }
};
