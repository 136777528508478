export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';

export const MAKE_PAYMENT_REQUEST = 'MAKE_PAYMENT_REQUEST';
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS';
export const MAKE_PAYMENT_ERROR = 'MAKE_PAYMENT_ERROR';

export const MAKE_SALES_ORDER_REQUEST = 'MAKE_SALES_ORDER_REQUEST';
export const MAKE_SALES_ORDER_SUCCESS = 'MAKE_SALES_ORDER_SUCCESS';
export const MAKE_SALES_ORDER_ERROR = 'MAKE_SALES_ORDER_ERROR';

export const SET_CHECKOUT_FORM_FIELDS = 'SET_CHECKOUT_FORM_FIELDS';

export const SET_INITIAL_STATUS_REQUEST = 'SET_INITIAL_STATUS_REQUEST';

export const SET_PRODUCT_SOURCENAME = 'SET_PRODUCT_SOURCENAME';
export const SET_PRODUCT_ID = 'SET_PRODUCT_ID';
export const SET_VENDOR_ID = 'SET_VENDOR_ID';
export const BUY_PRODUCT_REQUEST = 'BUY_PRODUCT_REQUEST';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART_DATA = 'CLEAR_CART_DATA';

export const CLEAR_PLACED_ORDER_DATA = 'CLEAR_PLACED_ORDER_DATA';
export const CHANGE_ITEM_CART_QTY = 'CHANGE_ITEM_CART_QTY';
export const CLEAR_BILLING_FROM_FORM = 'CLEAR_BILLING_FROM_FORM';
export const STATE_COUNTRY_RESPONSE = 'STATE_COUNTRY_RESPONSE';

export const SHOW_SPINNER_BACKDROP = 'SHOW_SPINNER_BACKDROP';
export const HIDE_SPINNER_BACKDROP = 'HIDE_SPINNER_BACKDROP';

export const UPDATE_PRODUCT_SHARE_LINK = 'UPDATE_PRODUCT_SHARE_LINK';
