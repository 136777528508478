import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from './styles';
import TotalAmount from './TotalAmount';
import * as actions from '../../../redux/actions/createFinalAmountActions';

import { withRouter } from 'react-router-dom';
function TotalAmountContainer(props) {
  useEffect(() => {
    const { items } = props.finalAmount.salesOrderResponse;
    if (!items || (items && items.length <= 0)) {
      props.history.push('/checkout');
    }
  });
  return (
    <Container>
      <TotalAmount
        makePayment={props.makePayment}
        finalAmount={props.finalAmount}
        history={props.history}
        setInitalStatus={props.setInitalStatus}
      />
    </Container>
  );
}

TotalAmountContainer.propTypes = {
  makePayment: PropTypes.func,
  finalAmount: PropTypes.object,
  setInitalStatus: PropTypes.bool,
  history: PropTypes.array
};

const mapStateToProps = state => {
  return {
    finalAmount: state.totalAmount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    makePayment: data => dispatch(actions.makePayment(data)),
    setInitalStatus: () => dispatch(actions.setInitalStatus())
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TotalAmountContainer)
);
