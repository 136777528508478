import React, { useEffect, Fragment } from 'react';
import qs from 'qs';
import history from '../../history';
import { toaster } from '../atoms/toaster';
import { withRouter } from 'react-router-dom';
import { API_BASE_URL } from '../../config/env';
import {
  getAuthToken,
  successStatusCodes,
  asciiToBinary,
  postWithResponseObject,
  isStateBase64Valid
} from '../../utils';
import { NETWORK_OPERATOR, VENDOR } from '../../constants';

function WalletStripeConnect() {
  // effects
  useEffect(() => {
    // checks if the decoded state has all the required properties (user_id, account_id, tab, code)

    (async () => {
      try {
        const state = qs.parse(history.location.search, {
          ignoreQueryPrefix: true
        }).state;

        const code = qs.parse(history.location.search, {
          ignoreQueryPrefix: true
        }).code;

        if (state && code) {
          const { user_id, account_id, currency, tab } = asciiToBinary(state);
          localStorage.setItem('WALLET_SELECTED_CURRENCY', currency);

          const openWallet = () => {
            if (tab === VENDOR) {
              history.push('/wallet');
            } else if (tab === NETWORK_OPERATOR) {
              history.push('/networkoperator/wallet');
            } else {
              history.push('/cosellerwallet');
            }
          };

          if (!isStateBase64Valid(state)) {
            openWallet();
            return toaster(
              'Failed to confirm stripe connect as "state" is invalid',
              'error'
            );
          }

          let confirmUrl = `${API_BASE_URL}/awake-money/linked-accounts/stripe-connect/confirm`;

          const token = getAuthToken();
          const headers = { authorization: token };
          const requestBody = {
            user_id,
            code,
            account_id: parseInt(account_id)
          };

          const response = await postWithResponseObject(
            confirmUrl,
            requestBody,
            headers
          );

          if (successStatusCodes.includes(response.status)) {
            openWallet();
            toaster('Confirmed stripe connect', 'success');
          } else {
            openWallet();
            toaster('Failed to confirm stripe connect', 'error');
          }
        } else {
          history.push('/');
          if (!state && !code)
            toaster(
              'Failed to confirm stripe connect as "state" and "code" is not present in the url',
              'error'
            );
          else if (!state)
            toaster(
              'Failed to confirm stripe connect as "state" is not present in the url',
              'error'
            );
          else if (!code)
            toaster(
              'Failed to confirm stripe connect as "code" is not present in the url',
              'error'
            );
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return <Fragment />;
}

export default withRouter(WalletStripeConnect);
