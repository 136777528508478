import { TextField } from '@material-ui/core';
import React from 'react';

class SearchBar extends React.Component {
  debounceQueue;

  constructor() {
    super();
    this.state = {
      searchText: ''
    };
  }

  queueSearch = e => {
    const { fetchParams, updateFetchParams } = this.props;
    let newFetchparams = { ...fetchParams };
    this.setState({
      searchText: this.state.searchText.length
        ? e.target?.value
        : e?.target?.value?.trim()
    });
    clearTimeout(this.debounceQueue);
    this.debounceQueue = setTimeout(() => {
      if (newFetchparams.text != this.state.searchText) {
        newFetchparams.text = this.state.searchText;
        newFetchparams.offset = 0;
        updateFetchParams(newFetchparams);
      }
    }, 1000);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.fetchParams.text != this.props.fetchParams.text) {
      this.setState({ searchText: this.props.fetchParams.text });
    }
  }

  render() {
    const { placeholder, className } = this.props;
    return (
      <TextField
        style={{ backgroundColor: '#fff' }}
        className={className}
        size="small"
        value={this.state.searchText}
        onChange={this.queueSearch}
        type="text"
        id="outlined-search"
        label={placeholder}
        variant="outlined"
        fullWidth
      />
    );
  }
}

export default SearchBar;
