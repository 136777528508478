import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';

const useStyles = makeStyles(theme => ({
  button: {
    color: '#000',
    backgroundColor: '#fff',

    '&:hover': {
      backgroundColor: '#000',
      color: '#fff'
    }
  }
}));

const PaginationControls = props => {
  const classes = useStyles();
  const {
    fetchParams,
    totalItemCount,
    updateFetchParams,
    paginationBtn
  } = props;
  const totalPages = Math.ceil(totalItemCount / fetchParams.count);

  const prevPage = () => {
    let newfetchParam = { ...fetchParams };
    newfetchParam.offset -= newfetchParam.count;
    updateFetchParams(newfetchParam);
  };

  const nextPage = () => {
    let newfetchParam = { ...fetchParams };
    newfetchParam.offset += newfetchParam.count;
    updateFetchParams(newfetchParam);
  };

  const currentPage =
    fetchParams.offset > 0
      ? Math.ceil(fetchParams.offset / fetchParams.count) + 1
      : 1;
  const nextBtnActive = !(
    fetchParams.offset <
    (totalPages - 1) * fetchParams.count
  );
  const prevBtnActive = !(fetchParams.offset >= fetchParams.count);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p="10px 0px"
      borderTop={paginationBtn ? '1px solid #ccc' : ''}
    >
      <Button
        variant="contained"
        className={paginationBtn ? paginationBtn : classes.button}
        disableRipple={paginationBtn ? true : false}
        color="default"
        size="large"
        onClick={() => prevPage()}
        disabled={prevBtnActive}
        startIcon={<NavigateBeforeIcon />}
      >
        Prev
      </Button>
      <span>
        {totalPages > 0 ? `Page ${currentPage} of ${totalPages}` : ''}
      </span>
      <Button
        variant="contained"
        className={paginationBtn ? paginationBtn : classes.button}
        disableRipple={paginationBtn ? true : false}
        color="default"
        size="large"
        onClick={() => nextPage()}
        disabled={nextBtnActive}
        endIcon={<NavigateNextIcon />}
      >
        Next
      </Button>
    </Box>
  );
};

export default PaginationControls;
