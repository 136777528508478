import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.WILD_SAND};
  padding: 50px;
  align-items: center;
`;

const Container = styled.div`
  max-width: 655px;
  width: 100%;
  min-height: 210px;
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.WHITE};
  ${props => props.theme.TABLET`
    padding: 20px;
  `}
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};
  margin-bottom: 10px;
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.XX_LARGE};
  `}
`;

const SubHeading = styled.div`
  margin-bottom: 20px;
  font-size: ${props => props.theme.X_LARGE};
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.LARGE};
  `}
`;

const Content = styled.div`
  margin-top: 10px;
  font-size: ${props => props.theme.MEDIUM};
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.MEDIUM};
  `}
`;

const OrdersContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const OrderDetails = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
`;

const ProductsContainer = styled.div`
  & > div:first-child {
    margin-top: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  text-align: left;
  margin: 8px 0;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: 0.5px solid #cbcbcb;
    border-radius: 5px;
  }
`;

const ImageContainer = styled.div`
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0.5px solid #cbcbcb;
    border-radius: 5px;
  }
`;

const OtherDetails = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
  margin-bottom: 32px;
`;

const ErrorLabel = styled.div`
  margin-bottom: 10px;
  font-size: ${props => props.theme.MEDIUM};
  color: #dc3545;
`;

export {
  Wrapper,
  Container,
  Heading,
  SubHeading,
  Content,
  OrdersContainer,
  OrderDetails,
  ProductsContainer,
  ProductDetails,
  ImageContainer,
  OtherDetails,
  ErrorLabel
};
