import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toaster } from '../../../atoms/toaster';
import qs from 'qs';
import { Wrapper } from './styles';
import { isEmpty, cloneDeep, get } from 'lodash';
import getDeviceId from '../../../templates/ProductDetail/generateBuyerSignature';
import CheckoutCart from '../../../organisms/CheckoutCart';
import history from '../../../../history';
import { API_BASE_URL } from '../../../../config/env';
import { fetchShopifyClientData } from '../../../../config/clientConfig/shopify';
import { createNewCart, addToCart, getCart } from '../../../../apis/cart';
import { SHOPIFY, DOMAIN, BLOKPARTY, PLAYTABLE } from '../../../../constants';
import { getApiResponseObject, getCartId } from '../../../../utils';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: '',
      variantId: '',
      quantity: null,
      fetchedProductDetails: {},
      isCartOpen: true,
      hideCloseButton: true,
      productSource: DOMAIN,
      hasVariants: false,
      productReferrer: '',
      checkoutDisable: true,
      checkout: null,
      variantData: [],
      cart: {}
    };
  }

  componentDidMount() {
    this.setCartData();
    if (
      this.getQueryParam('productid') &&
      this.getQueryParam('variantid') &&
      this.getQueryParam('quantity')
    ) {
      if (!/^[1-9][0-9]*$/.test(this.getQueryParam('quantity'))) {
        return toaster('Invalid quantity provided');
      } else {
        this.fetchProductData();
      }
    }
  }

  setCartData = () => {
    const cartId = getCartId();

    if (cartId) {
      this.updateCartDetails();
    }
  };

  fetchProductData = async () => {
    const productId = this.getQueryParam('productid');
    const variantId = this.getQueryParam('variantid');
    const quantity = this.getQueryParam('quantity');

    const response = await getApiResponseObject(
      `${API_BASE_URL}/products/${productId}`
    );

    if (response.status !== 200) {
      return toaster('Failed to fetch product details');
    } else {
      const fetchedProductDetails = response.data;

      if (isEmpty(fetchedProductDetails)) {
        return toaster('Failed to fetch product details');
      }

      this.setState(
        {
          productId,
          variantId,
          quantity: parseInt(quantity),
          fetchedProductDetails
        },
        () => {
          let {
            productSource,
            fetchedProductDetails,
            hasVariants
          } = this.state;
          const sourceName = fetchedProductDetails.sourceName || '';
          productSource = sourceName === BLOKPARTY ? PLAYTABLE : sourceName;
          hasVariants =
            get(fetchedProductDetails, 'options[0].values[0]') ===
            'Default Title'
              ? false
              : true;
          this.setState({
            productSource,
            hasVariants
          });
          this.checkVariantAvailability(variantId);
        }
      );
    }
  };

  addProductToCheckoutCart = async () => {
    const { variantData, productId, quantity } = this.state;
    const rawProductData = variantData;

    if (rawProductData) {
      let cartId = getCartId();

      if (!cartId) {
        cartId = await createNewCart();
      }

      if (cartId) {
        this.toggleCartLoader('show');
        const cartRes = await getCart();
        this.toggleCartLoader('hide');

        const productAlreadyExists =
          cartRes?.cart_lines?.length > 0 &&
          cartRes.cart_lines.some(
            item =>
              item.product_id === productId &&
              item.product_variant_id === rawProductData.id
          );

        if (productAlreadyExists) {
          this.setState({ cart: cartRes });
        } else {
          const reqBody = {
            product_id: productId,
            product_variant_id: rawProductData.id,
            quantity
          };

          this.toggleCartLoader('show');
          const addCartRes = await addToCart(reqBody);
          if (addCartRes?.data?.id) {
            this.toggleCartLoader('hide');
            this.setState({
              loadingCart: false,
              cart: addCartRes?.data
            });
          } else {
            toaster(addCartRes?.data?.message || 'Cannot add product to cart');
          }
        }
      } else {
        toaster('Cannot generate cart id');
      }
    } else {
      return toaster('Product not in stock');
    }
    const { productSource } = this.state;
  };

  getQueryParam = item => {
    try {
      return qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      })[item];
    } catch (err) {
      console.log(err);
    }
  };

  checkVariantAvailability = variantId => {
    const { fetchedProductDetails } = this.state;

    if (
      fetchedProductDetails.variants &&
      fetchedProductDetails.variants.length > 0
    ) {
      const variantData = fetchedProductDetails.variants.filter(variant => {
        return variant.id === variantId;
      });

      if (variantData.length <= 0) {
        return toaster('Invalid variant id provided or variant unavailable');
      } else {
        this.setState({ variantData: variantData[0] }, () => {
          const productReferrer = document.referrer || '';
          this.setState({ productReferrer });
          getDeviceId();
          return this.addProductToCheckoutCart();
        });
      }
    }
  };

  runOnCartDataChange = () => {
    const { cart } = this.state;
    let result = { totalQuantity: cart?.total_quantity || 0 };
    window.parent.postMessage(JSON.stringify(result), '*');
  };

  toggleCartLoader = status => {
    this.setState({ loadingCart: status === 'show' ? true : false });
  };

  updateCartDetails = async () => {
    this.toggleCartLoader('show');
    const cartRes = await getCart();
    this.toggleCartLoader('hide');
    this.setState({ cart: cartRes });
  };

  render() {
    const {
      fetchedProductDetails,
      isCartOpen,
      hideCloseButton,
      productSource,
      productId,
      checkout,
      hasVariants,
      checkoutDisable,
      productReferrer,
      cart,
      loadingCart
    } = this.state;
    return (
      <Wrapper>
        {fetchedProductDetails && (
          <CheckoutCart
            cart={cart}
            loadingCart={loadingCart}
            toggleCartLoader={this.toggleCartLoader}
            updateCartDetails={this.updateCartDetails}
            isCartOpen={isCartOpen}
            hideCloseButton={hideCloseButton}
            client={productSource}
            sourceId={fetchedProductDetails.sourceId || ''}
            productId={productId}
            fetchedProductDetails={fetchedProductDetails}
            hasVariants={hasVariants}
            productReferrer={productReferrer}
            onCartDataChange={this.runOnCartDataChange}
          />
        )}
      </Wrapper>
    );
  }
}

Cart.propTypes = {
  client: PropTypes.object
};

const mapStateToProps = state => {
  const { buyProduct } = state;
  return {
    buyProductData: buyProduct
  };
};

export default withRouter(connect(mapStateToProps)(Cart));
