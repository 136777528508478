import { Chip, Divider } from '@material-ui/core';
import styled from 'styled-components';

const StyledChip = styled(Chip)`
  font-size: ${props => props.theme.X_SMALL}!important;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  margin: 3px;
  text-transform: capitalize;
`;

const StyledDivider = styled(Divider)`
  margin: 15px 0px !important;
`;

export { StyledChip, StyledDivider };
