import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  getAuthToken,
  getAuthId,
  getApiResponseObject,
  successStatusCodes
} from '../../../../utils';
import { isEmpty } from 'lodash';
import { API_BASE_URL } from '../../../../config/env';
import { toaster } from '../../../atoms/toaster';
import OrdersTable from './OrdersTable';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../../constants';
import { Tooltip, Zoom } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { COSELLER_INFO } from '../../../../constants/tooltip';
import { Container, HeadingSection, Heading, DetailsContainer } from './styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import EmptyState from '../../../atoms/EmptyState';

class CosellerAttribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchAttribution: [],
      ordersAvailable: false,
      totalOrdersCount: 0,
      currentPage: 0,
      currentOffset: 0,
      fetchingOrders: false,
      selectedCurrency: null
    };
  }

  componentDidMount() {
    EventsBaseClient.on(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );

    const selectedCurrency = localStorage.getItem('selectedCurrency');

    if (selectedCurrency) {
      this.setState({ selectedCurrency: selectedCurrency }, () =>
        this.getAttributionDetails()
      );
    } else {
      this.getAttributionDetails();
    }
  }

  componentWillUnmount() {
    EventsBaseClient.removeEventListener(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );
  }

  resetState = () => {
    this.setState({
      isLoading: true,
      fetchAttribution: [],
      ordersAvailable: false,
      totalOrdersCount: 0,
      currentPage: 0,
      currentOffset: 0,
      fetchingOrders: false
    });
  };

  handleCurrencyChange = currency => {
    const { selectedCurrency } = this.state;

    if (currency !== selectedCurrency) {
      this.setState(
        {
          selectedCurrency: currency
        },
        () => {
          this.resetState();
          this.getAttributionDetails();
        }
      );
    }
  };

  fetchOrders = async (offset, count) => {
    this.props.showSpinnerBackdrop();
    const { selectedCurrency } = this.state;

    const token = getAuthToken();
    const cosellerId = getAuthId();
    const headers = { Authorization: token };
    let url = `${API_BASE_URL}/cosellers/${cosellerId}/orders?offset=${offset}&count=${count}`;

    if (selectedCurrency?.length > 0) {
      url += `&currency=${selectedCurrency}`;
    }

    const response = await getApiResponseObject(url, headers);
    this.props.hideSpinnerBackdrop();
    return response;
  };

  getAttributionDetails = async () => {
    const response = await this.fetchOrders(0, 6);
    if (!successStatusCodes.includes(response.status)) {
      this.setState({
        ordersAvailable: false,
        isLoading: false
      });
    } else {
      const fetchAttribution = response.data?.data;
      if (isEmpty(fetchAttribution)) {
        return toaster('Unable to fetch attribution data');
      }
      this.setState({
        fetchAttribution,
        ordersAvailable: true,
        totalOrdersCount: response?.data?.count || 0,
        isLoading: false
      });
    }
  };

  handleChangePage = async (event, newPage) => {
    if (this.state.fetchingOrders) return;
    const { currentPage, currentOffset } = this.state;
    const fetchOffset =
      newPage > currentPage ? currentOffset + 6 : currentOffset - 6;
    this.setState({ fetchingOrders: true });

    const response = await this.fetchOrders(fetchOffset, 6);

    if (successStatusCodes.includes(response.status)) {
      const fetchAttribution = response?.data?.data;
      if (!Array.isArray(fetchAttribution)) {
        return toaster('Unable to fetch orders');
      }
      this.setState({
        fetchAttribution,
        currentPage: newPage,
        currentOffset: fetchOffset,
        fetchingOrders: false
      });
    } else {
      this.setState({ fetchingOrders: false });
      return toaster('Unable to fetch orders');
    }
  };

  render() {
    const {
      isLoading,
      fetchAttribution,
      totalOrdersCount,
      currentPage,
      fetchingOrders,
      ordersAvailable
    } = this.state;
    return (
      <Container>
        <HeadingSection>
          <Heading>Earnings</Heading>
          <Tooltip
            TransitionComponent={Zoom}
            title={COSELLER_INFO.pageDescription.Earnings}
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </HeadingSection>
        <DetailsContainer>
          {!isLoading && ordersAvailable && !isEmpty(fetchAttribution) ? (
            <OrdersTable
              orderDetails={fetchAttribution}
              totalOrdersCount={totalOrdersCount}
              currentPage={currentPage}
              fetchingOrders={fetchingOrders}
              handleChangePage={this.handleChangePage}
            />
          ) : (
            !isLoading &&
            !ordersAvailable && (
              <EmptyState errorMessage="No order details available" />
            )
          )}
        </DetailsContainer>
      </Container>
    );
  }
}

CosellerAttribution.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(CosellerAttribution)
);
