import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Loader from '../../../atoms/loader';
import { toaster } from '../../../atoms/toaster';
import Catalog from '../../../organisms/Coseller/Catalog';
import { API_BASE_URL } from '../../../../config/env';
import InviteCoseller from '../../../templates/InviteCoseller';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  showApiErrorToast,
  getUserType
} from '../../../../utils';
import { COSELLER, GLOBAL_EVENT_KEYS } from '../../../../constants';
import {
  Container,
  InnerContainer,
  ProductsContainer,
  ReferWithOutInvite,
  Refer,
  HeadingSection,
  Heading
} from './styles';
import { Box, Button, Tooltip, Typography, Zoom } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import { withStyles } from '@material-ui/styles';
import shoptypeThemes from '../../../../theme';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import history from '../../../../history';
import { EmptyState } from '../../AwakeMoney/Dashboard/styles';

const styles = theme => ({
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  message: {
    fontWeight: shoptypeThemes.default.LIGHT_FONT
  },
  hereButton: {
    padding: 0,
    fontWeight: 'inherit',
    fontSize: '1.5rem',
    lineHeight: 0,
    textTransform: 'none',
    minWidth: 0
  }
});
class NetworkProducts extends React.Component {
  state = {
    isLoadingProductsPage: true,
    vendorListDataRawFormat: [],
    vendorListData: {},
    invites: {},
    vendorsList: [],
    selectedVendors: [],
    vendorFetchParam: {
      count: 10, // set this for pagesize
      offset: 0,
      text: ''
    },
    vendorTotalCount: 0
  };

  componentDidMount() {
    this.fetchVendorInvites();
  }

  updateVendorFetchParams = () => {
    let newfetchParam = { ...this.state.vendorFetchParam };
    let productsLeft =
      this.state.vendorTotalCount - this.state.vendorListDataRawFormat.length;

    if (productsLeft > 0) {
      if (productsLeft >= 10) {
        newfetchParam.offset += 10;
      } else {
        newfetchParam.offset += 10;
        newfetchParam.count = productsLeft;
      }
      this.setState({ vendorFetchParam: newfetchParam }, () => {
        this.fetchVendorInvites();
      });
    }
  };

  updateVendorFetchParamsBasedOnInput = value => {
    let newfetchParam = { ...this.state.vendorFetchParam };
    newfetchParam.text = value;
    newfetchParam.offset = 0;
    newfetchParam.count = 10;

    this.setState({ vendorFetchParam: newfetchParam }, () => {
      this.fetchVendorInvites();
    });
  };

  fetchVendorInvites = async () => {
    this.setState({ selectedVendors: [] });
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/networks/manage-vendors`,
      headers,
      this.state.vendorFetchParam
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      return;
    } else {
      const vendorListDataRawFormat =
        this.state.vendorFetchParam.offset === 0
          ? response.data?.vendors
          : [...this.state.vendorListDataRawFormat, ...response.data?.vendors];

      if (!Array.isArray(vendorListDataRawFormat)) {
        return toaster('Unable to fetch vendors');
      }

      this.setState(
        {
          vendorListDataRawFormat,
          isLoadingProductsPage: false,
          vendorTotalCount: response.data?.count
        },
        () => {
          let { vendorListDataRawFormat, vendorListData } = this.state;
          vendorListDataRawFormat.forEach(vendor => {
            vendorListData[vendor.vendor.name] = {
              vendorId: vendor.vendor.id
            };
          });

          let newVendorsList = vendorListDataRawFormat.map(
            vendor => vendor.vendor.name
          );

          this.setState({
            vendorListData,
            vendorsList: newVendorsList
          });
        }
      );
    }
  };

  updateSelectedVendors = selectedVendorsNames => {
    const selectedVendors = [];
    selectedVendorsNames.forEach(vendorName =>
      selectedVendors.push(this.state.vendorListData[vendorName].vendorId)
    );
    this.setState({ selectedVendors: selectedVendors });
  };

  toggleActiveState = () => {
    history.push('/networkoperator/vendors');
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.UPDATE_SIDEBAR_CATEGORIES);
  };

  render() {
    const { classes } = this.props;
    let {
      isLoadingProductsPage,
      vendorListData,
      selectedVendors,
      vendorsList
    } = this.state;

    const allVendors = [];
    Object.keys(vendorListData).forEach(vendorName => {
      allVendors.push(vendorListData[vendorName].vendorId);
    });

    return (
      <Container>
        <InnerContainer>
          <ProductsContainer>
            <HeadingSection>
              <Heading>Products</Heading>
              <Tooltip
                TransitionComponent={Zoom}
                title={NETWORK_OPERATOR_INFO.pageDescription.Products}
                arrow
              >
                <InfoRoundedIcon />
              </Tooltip>
            </HeadingSection>
            <>
              {!isLoadingProductsPage && !isEmpty(vendorListData) ? (
                selectedVendors.length !== 0 ? (
                  <Catalog
                    vendorsList={vendorsList}
                    updateSelectedVendors={this.updateSelectedVendors}
                    selectedVendors={selectedVendors}
                    updateVendorFetchParams={this.updateVendorFetchParams}
                    updateVendorFetchParamsBasedOnInput={
                      this.updateVendorFetchParamsBasedOnInput
                    }
                  />
                ) : (
                  <Catalog
                    vendorsList={vendorsList}
                    updateSelectedVendors={this.updateSelectedVendors}
                    selectedVendors={allVendors}
                    updateVendorFetchParams={this.updateVendorFetchParams}
                    updateVendorFetchParamsBasedOnInput={
                      this.updateVendorFetchParamsBasedOnInput
                    }
                  />
                )
              ) : (
                !isLoadingProductsPage &&
                isEmpty(vendorListData) && (
                  <Box height={'50vh'} className={classes.emptyState}>
                    <Typography variant="h5" className={classes.message}>
                      <i className="fas fa-times-circle danger" />
                      &nbsp;No Vendors Linked
                    </Typography>
                    <Typography variant="h5" className={classes.message}>
                      Discover Vendors{' '}
                        <Button className={classes.hereButton} color="primary" disableRipple={true} onClick={() => this.toggleActiveState()}>Here</Button>
                    </Typography>
                  </Box>
                )
              )}
            </>
          </ProductsContainer>
        </InnerContainer>
      </Container>
    );
  }
}

NetworkProducts.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withStyles(styles)(
  withRouter(connect(null, mapDispatchToProps)(NetworkProducts))
);
