import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import history from '../../../history';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  logout,
  fetchPlatformDetails
} from '../../../utils';
import Dashboard from './index';
import { API_BASE_URL } from '../../../config/env';
import Loader from '../../atoms/loader';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';

class DashboardWrapper extends React.Component {
  state = {
    fetchDashboard: false
  };

  componentDidMount() {
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const token = getAuthToken();
    if (token) {
      const userType = localStorage.getItem('USER_TYPE');
      if (token && userType) {
        fetchPlatformDetails(userType);
        if (!userProfile) {
          this.updateUserProfileToLocalStorage();
        } else {
          this.fetchDashboardData();
        }
      } else {
        history.push('/users/platform');
      }
    } else {
      history.push('/');
    }
  }

  updateUserProfileToLocalStorage = async () => {
    const token = getAuthToken();
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      localStorage.setItem('userProfile', JSON.stringify(profileRes.data));
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
      this.fetchDashboardData();
    } else {
      logout();
    }
    return profileRes.data || {};
  };

  fetchDashboardData = () => {
    const userDetails = JSON.parse(localStorage.getItem('userProfile')) || null;
    const vendorId = userDetails
      ? !isEmpty(userDetails.vendors)
        ? userDetails.vendors[0].id || null
        : null
      : null;
    if (vendorId) {
      this.setState({ fetchDashboard: true });
    } else {
      history.push('/users/platform');
    }
  };

  render() {
    const { fetchDashboard } = this.state;
    return (
      <>
        {fetchDashboard ? (
          <Dashboard />
        ) : (
          <div style={{ width: '100%', height: '100vh' }}>
            <Loader
              message="Loading..."
              isFlex={true}
              w={'100%'}
              h={'100%'}
              isCenter={true}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(DashboardWrapper);
