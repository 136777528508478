import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 6px;
`;

const Icon = styled.i`
  font-size: 48px;
`;

const MessageContainer = styled.div`
  margin-left: 6px;
`;

const Message = styled.div`
  font-size: ${props => props.theme.X_LARGE};
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
`;

export { Wrapper, IconContainer, Icon, MessageContainer, Message };
