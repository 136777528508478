import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Loader from '../../atoms/loader';

import { CloseButton, Filled } from '../../atoms/button';
import { toaster } from '../../atoms/toaster';
import UploadMedia from '../../atoms/UploadMediaInput';
import { API_BASE_URL } from '../../../config/env';
import { post, getAuthToken } from '../../../utils';

import {
  ModalHeading,
  EditVariantMediaContainer,
  VariantMediaContainer,
  ImagesContainer,
  ProductImageWrapper
} from './mediaContainerModalStyles';

class MediaContainerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingMediaStorageMedia: false,
      mediaStorage: {
        images: {},
        videos: {}
      },
      primaryImage: {
        mediaId: '',
        mediaSrc: '',
        variantIds: []
      },
      selectedImageIndex: null
    };
  }

  fetchUploadedData = e => {
    const selectedFile = e.target.files ? e.target.files[0] : '';
    const selectedFileName = e.target.files ? e.target.files[0].name : '';
    const fileType = e.target.files[0].type || '';
    const fileId = e.target.id || '';

    this.setState({ isLoadingMediaStorageMedia: true }, () => {
      toaster('Media Uploading...');
      var uploadMediaRequestBody = new FormData();
      uploadMediaRequestBody.append(selectedFileName, selectedFile);
      uploadMediaRequestBody.append('fileNames', `["${selectedFileName}"]`);
      const token = getAuthToken();
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token
      };

      post(
        `${API_BASE_URL}/command?type=addMedia`,
        uploadMediaRequestBody,
        headers
      ).then(response => {
        const result = response[Object.keys(response)[0]];

        this.setState({ fetchedUploadedMediaUrl: result }, () => {
          let { fetchedUploadedMediaUrl } = this.state;
          this.props.pushNewMediaElementToMediaStorage(
            fetchedUploadedMediaUrl,
            selectedFileName,
            fileType,
            fileId
          );
          this.props.closeMediaContainerModal();
        });
      });
    });
  };

  fetchAllMediaType = mediaType => {
    const { mediaStorage } = this.props;

    let collatedImagesArray = [];

    if (!isEmpty(mediaStorage[mediaType])) {
      Object.keys(mediaStorage[mediaType]).forEach(mediaId => {
        collatedImagesArray.push([
          mediaId,
          mediaStorage[mediaType][mediaId].mediaSrc,
          mediaStorage[mediaType][mediaId].primary
        ]);
      });
    }

    return collatedImagesArray;
  };

  selectImage = (imageElement, index) => e => {
    this.props.setImageToVariant(imageElement, this.props.variantName);
    this.setState({ selectedImageIndex: index });
  };

  render() {
    const { isLoadingMediaStorageMedia, selectedImageIndex } = this.state;
    const {
      variantName,
      uploadVariantMedia,
      closeMediaContainerModal
    } = this.props;
    return (
      <EditVariantMediaContainer>
        <ModalHeading>Select Variant Image</ModalHeading>
        <VariantMediaContainer>
          {!isLoadingMediaStorageMedia ? (
            <UploadMedia
              size="variant-media"
              id={`variant-${variantName}`}
              onChange={e => uploadVariantMedia(e)}
            />
          ) : (
            <Loader
              message="Loading Media.."
              isFlex={true}
              w={'100%'}
              isCenter={true}
              h={'240px'}
            />
          )}
          <ImagesContainer>
            {this.fetchAllMediaType('images').map((imageElement, index) => {
              return (
                <ProductImageWrapper
                  key={index}
                  selectedStatus={selectedImageIndex === index}
                >
                  <img
                    key={index}
                    src={imageElement[1]}
                    alt="media-storage-media"
                    onClick={this.selectImage(imageElement, index)}
                  />
                </ProductImageWrapper>
              );
            })}
          </ImagesContainer>
        </VariantMediaContainer>
        <CloseButton
          width="20px"
          height="20px"
          size="custom"
          textColor="white"
          backgroundColor="black"
          onClick={closeMediaContainerModal}
        />
        <Filled text="Done" onClick={closeMediaContainerModal} />
      </EditVariantMediaContainer>
    );
  }
}

MediaContainerModal.propTypes = {
  match: PropTypes.object,
  mediaStorage: PropTypes.object,
  variantName: PropTypes.string,
  uploadVariantMedia: PropTypes.func,
  pushNewMediaElementToMediaStorage: PropTypes.func,
  setImageToVariant: PropTypes.func,
  closeMediaContainerModal: PropTypes.func
};

export default withRouter(MediaContainerModal);
