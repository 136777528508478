import React, { Component, Fragment } from 'react';
import qs from 'qs';
import history from '../../history';
import { withRouter } from 'react-router-dom';
import { postWithResponseObject, successStatusCodes } from '../../utils';
import { API_BASE_URL } from '../../config/env';
import { EventsBaseClient } from './eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../constants';

class ValidateEmail extends Component {
  componentDidMount() {
    this.validateEmail();
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_SIDEBAR);
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR);
  }

  validateEmail = async () => {
    try {
      const token = qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      }).token;

      if (token) {
        let requestBody = { token };
        let response = await postWithResponseObject(
          `${API_BASE_URL}/validate-email`,
          requestBody
        );

        if (successStatusCodes.includes(response.status)) {
          sessionStorage.removeItem('socialAccounts');
          localStorage.removeItem('_email');
          localStorage.setItem('token', JSON.stringify(response.data.token));
          localStorage.setItem('_id', JSON.stringify(response.data.id));
          history.push('/users/platform');
        } else {
          let message = (response.data && response.data.message) || '';
          if (response.data.message === 'Invalid Token') {
            message = 'Your account is expired, Please register again';
          }
          history.push({
            pathname: '/users/message',
            search: '?type=email_validation_failed&queryMsg=' + message
          });
        }
      } else {
        history.push('/');
      }
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    return <Fragment />;
  }
}

export default withRouter(ValidateEmail);
