import React from 'react';
import TextInput from '../../atoms/textInputV1';
import Icons from '../../atoms/icons';
import TextField from '@material-ui/core/TextField';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import {
  VendorOnBoardForm,
  SocialAccount,
  SelectDropDown,
  Label,
  AddMoreLinks,
  AddIcon,
  CloseIcon,
  Svgfix,
  SocialMediaHandles,
  SocialMediaIcon,
  SocialMediaUrl
} from './styles';

const options = [
  {
    value: 'TWITTER',
    label: (
      <Label>
        <Icons name="twitter-dropdown" height={15} width={15} />
      </Label>
    )
  },
  {
    value: 'FACEBOOK',
    label: (
      <Label>
        <Icons name="facebook-dropdown" height={15} width={15} />
      </Label>
    )
  },
  {
    value: 'INSTAGRAM',
    label: (
      <Label>
        <Icons name="instagram-dropdown" height={15} width={15} />
      </Label>
    )
  }
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#ACCEF7' : '#FFFFFF'
  })
};

class SocialLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editedRows: new Set(),
      isDisabled: true,
      selectedOption: {
        value: ''
      },
      socialAccounts: [
        {
          socialMediaName: 'FACEBOOK',
          socialMediaLink: ' '
        },
        {
          socialMediaName: 'TWITTER',
          socialMediaLink: ' '
        },
        {
          socialMediaName: 'INSTAGRAM',
          socialMediaLink: ' '
        }
      ],
      detailsUpdated: false,
      dataIncomplete: false
    };
  }

  componentDidMount() {
    this.setState({
      detailsUpdated: true
    });
  }

  componentDidUpdate(prevState) {
    if (this.props.socialAccounts) {
      if (
        this.props.socialAccounts != prevState.socialAccounts &&
        this.props.socialAccounts.length > 0
      ) {
        this.setState({
          socialAccounts: this.props.socialAccounts
        });
      }
    }
  }

  changeHandler0 = e => {
    let { socialAccounts } = this.state;
    const ac = e.target.value;
    if (ac !== '') {
      socialAccounts[0].socialMediaLink = e.target.value;
    } else {
      socialAccounts[0].socialMediaLink = ' ';
    }
    this.setState(
      {
        socialAccounts: socialAccounts
      },
      () => {
        this.props.sendData(socialAccounts);
      }
    );
  };

  changeHandler1 = e => {
    let { socialAccounts } = this.state;
    const ac = e.target.value;
    if (ac !== '') {
      socialAccounts[1].socialMediaLink = e.target.value;
    } else {
      socialAccounts[1].socialMediaLink = ' ';
    }
    this.setState(
      {
        socialAccounts: socialAccounts
      },
      () => {
        this.props.sendData(socialAccounts);
      }
    );
  };

  changeHandler2 = e => {
    let { socialAccounts } = this.state;
    const ac = e.target.value;
    if (ac !== '') {
      socialAccounts[2].socialMediaLink = e.target.value;
    } else {
      socialAccounts[2].socialMediaLink = ' ';
    }
    this.setState(
      {
        socialAccounts: socialAccounts
      },
      () => {
        this.props.sendData(socialAccounts);
      }
    );
  };

  emptyEntry = () => {
    return {
      socialMediaOption: {
        value: '',
        label: 'Select'
      },
      socialMediaLink: ''
    };
  };

  addEmptySocialAccountEntry = () => {
    const emptyEntry = this.emptyEntry();

    this.setState(
      prevState => ({
        socialAccounts: [...prevState.socialAccounts, emptyEntry]
      }),
      () => {
        this.restructureSocialMediaAccounts();
      }
    );
  };

  render() {
    const { socialAccounts, isDisabled, editedRows } = this.state;
    const _this = this;
    return (
      <>
        <SocialMediaHandles>
          <SocialMediaIcon>
            <FacebookIcon style={{ width: '40px', height: '50px' }} />
          </SocialMediaIcon>
          <SocialMediaUrl
            type="text"
            placeholder="Facebook"
            value={!socialAccounts[0] ? '' : socialAccounts[0].socialMediaLink}
            onChange={e => this.changeHandler0(e)}
          />
        </SocialMediaHandles>
        <SocialMediaHandles>
          <SocialMediaIcon>
            <TwitterIcon style={{ width: '40px', height: '50px' }} />
          </SocialMediaIcon>
          <SocialMediaUrl
            type="text"
            placeholder="Twitter"
            value={!socialAccounts[1] ? '' : socialAccounts[1].socialMediaLink}
            onChange={e => this.changeHandler1(e)}
          />
        </SocialMediaHandles>
        <SocialMediaHandles>
          <SocialMediaIcon>
            <InstagramIcon style={{ width: '40px', height: '50px' }} />
          </SocialMediaIcon>
          <SocialMediaUrl
            type="text"
            placeholder="Instagram"
            value={!socialAccounts[2] ? ' ' : socialAccounts[2].socialMediaLink}
            onChange={e => this.changeHandler2(e)}
          />
        </SocialMediaHandles>
        {editedRows.size < socialAccounts.length ? (
          <AddMoreLinks isDisabled={isDisabled}>
            <AddIcon>
              <Icons name="add-more" width={22} height={22} />
            </AddIcon>
            Add More Links
          </AddMoreLinks>
        ) : (
          <AddMoreLinks onClick={() => this.addEmptySocialAccountEntry()}>
            <AddIcon>
              <Icons name="add-more" width={22} height={22} />
            </AddIcon>
            Add More Links
          </AddMoreLinks>
        )}
      </>
    );
  }
}

export default SocialLinks;
