const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_AWAKE_BASE_URL,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_BASE_URL,
  REACT_APP_AWAKE_BASE_URL,
  REACT_APP_SHOPTYPE_ADMIN_EMAIL,
  REACT_APP_STRIPE_PUBLISH_KEY,
  REACT_APP_SHOPTYPE_API_KEY,
  REACT_APP_SHOPTYPE_ADMIN_USER_ID,
  REACT_APP_ENV,
  REACT_APP_SHOPIFY_SHOPTYPE_CHECKOUT_ENFORCE,
  REACT_APP_WOOCOMMERCE_SHOPTYPE_CHECKOUT_ENFORCE
} = process.env;

export const API_BASE_URL = REACT_APP_API_BASE_URL;

export const AWAKE_API_BASE_URL = REACT_APP_API_AWAKE_BASE_URL;

export const APP_BASE_URL = REACT_APP_BASE_URL;

export const SHOPTYPE_API_KEY = REACT_APP_SHOPTYPE_API_KEY;

export const SHOPTYPE_ADMIN_USER_ID = REACT_APP_SHOPTYPE_ADMIN_USER_ID;

export const AWAKE_BASE_URL = REACT_APP_AWAKE_BASE_URL;

export const SHOPTYPE_ADMIN_EMAIL = REACT_APP_SHOPTYPE_ADMIN_EMAIL;

export const CURRENT_DOMAIN = REACT_APP_COOKIE_DOMAIN || '.shoptype.com';

export const APP_ENV = REACT_APP_ENV;

export const SHOPIFY_SHOPTYPE_CHECKOUT_ENFORCE = Boolean(
  REACT_APP_SHOPIFY_SHOPTYPE_CHECKOUT_ENFORCE
);
export const WOOCOMMERCE_SHOPTYPE_CHECKOUT_ENFORCE = Boolean(
  REACT_APP_WOOCOMMERCE_SHOPTYPE_CHECKOUT_ENFORCE
);

export const STRIPE_PUBLISH_KEY_LOCAL =
  REACT_APP_STRIPE_PUBLISH_KEY || 'publishable-key';

export const APP_ENV_DEV = 'Dev';
export const APP_ENV_BETA = 'Beta';
export const APP_ENV_PRODUCTION = 'Production';
