import styled from 'styled-components';
import { Primary } from '../../../atoms/button';
import { Secondary } from '../../../atoms/button';

const PageWrapper = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  background-color: ${props => props.theme.SPRING_WOOD};
  color: ${props => props.theme.MINE_SHAFT};
  display: block;
  width: 99%;
  margin: 0 auto;
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 200px;
  overflow-y: auto;
  text-align: center;
`;

const PlatformImage = styled.img`
  width: 16px;
  height: 16px;
`;

const FlexRowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SuccessContent = styled.div`
  display: flex;
  align-items: center;
  color: #00e62a;
`;

const WarningContent = styled.div`
  display: flex;
  align-items: center;
  color: #e6c100;
`;

const PrimaryButton = styled(Primary)`
  height: 40px !important;
  border: 1px solid black;
`;

const SecondaryButton = styled(Secondary)`
  height: 40px !important;
`;

export {
  PageWrapper,
  ContentWrapper,
  ErrorMessageContainer,
  PlatformImage,
  FlexRowEnd,
  SuccessContent,
  WarningContent,
  PrimaryButton,
  SecondaryButton
};
