import React from 'react';
import DottedOutline from '../../molecules/dottedOutline';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Icons from '../../atoms/icons';
import CSVReader from 'react-csv-reader';
import { toaster } from '../../atoms/toaster';
import { Secondary as SecondaryButton } from '../../atoms/button';
import {
  getAuthToken,
  postWithResponseObject,
  post,
  successStatusCodes,
  isEmailValid,
  showApiErrorToast,
  getApiResponseObject
} from '../../../utils';
import { API_BASE_URL, APP_BASE_URL } from '../../../config/env';
import { isEmpty } from 'lodash';
import { COSELLER, INVITE_TYPE_REFERRER } from '../../../constants';
import {
  Container,
  Heading,
  EmailForm,
  ToInput,
  CSVWrapper,
  CSVContainer,
  NewClose,
  Content,
  SendEmailContainer,
  SendEmail,
  HelpSpan,
  InputContainer,
  ProductLink,
  ReferrerWrapper,
  ReferrerHeading,
  ActionsContainer,
  RemoveEmailsWrapper
} from './styles';
import Loader from '../../atoms/loader';

const papaparseOptions = {
  dynamicTyping: true,
  skipEmptyLines: true
};

class InviteCoseller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: localStorage.getItem('USER_TYPE') || '',
      vendorProfile: JSON.parse(localStorage.getItem('userProfile')) || {
        vendors: [{ name: '' }]
      },
      vendorId: '',
      vendorName: '',
      isOpen: false,
      modalContent: null,
      toName: '',
      toEmail: '',
      subject: '',
      tagline: '',
      description: '',
      mediaStorage: {
        images: {},
        videos: {}
      },
      isLoadingMediaStorageImages: false,
      emailInviteLink: '',
      csvData: [],
      disableTo: false,
      activeTemplateContent: '',
      isLoading: true
    };
  }

  componentDidMount() {
    const { vendorProfile } = this.state;
    const setUserType = this.props.isCoseller;
    const vendorId = this.props.inviteData
      ? this.props.inviteData[0].vendorId
      : '';
    const vendorName = this.props.inviteData
      ? this.props.inviteData[0].vendorName
      : '';
    if (setUserType) {
      this.setState({
        userType: 'COSELLER',
        vendorId: vendorId,
        vendorName: vendorName
      });
    } else {
      this.setState({
        vendorName:
          vendorProfile &&
          vendorProfile.vendors &&
          vendorProfile.vendors[0] &&
          vendorProfile.vendors[0].name
            ? vendorProfile.vendors[0].name
            : ''
      });
    }
  }

  inputChangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendMail = async () => {
    const {
      vendorProfile,
      toEmail,
      toName,
      emailInviteLink,
      userType,
      vendorName,
      csvData
    } = this.state;

    const token = getAuthToken();
    const headers = {
      authorization: token,
      'Content-Type': 'application/json'
    };

    const emailList =
      csvData.length > 0 ? csvData : this.generateEmailList(toEmail, toName);
    const valid = this.validateEmailList(emailList);

    if (!valid) {
      return toaster('One or more of the emails is invalid');
    }

    const reqBody = {
      to: emailList,
      vendorProfileName:
        userType === COSELLER ? vendorName : vendorProfile?.name || '',
      emailInviteLink
    };

    if (toEmail && toName) {
      const res = await postWithResponseObject(
        `${API_BASE_URL}/send-invitation`,
        reqBody,
        headers
      );
      if (successStatusCodes.includes(res.status)) {
        this.closeModal();
        toaster('Email successfully sent');
      } else {
        toaster('Email Service is down. Kindly try again later');
      }
    } else {
      toaster('Please fill To input field');
    }
  };

  validateEmailList = emailList => {
    let valid = true;

    for (let index = 0; index < emailList.length; index++) {
      if (!isEmailValid(emailList[index].email)) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  generateEmailList = (emailString, nameString) => {
    const emails = Array.from(
      emailString
        .split(',')
        .map(email => email.trim())
        .filter(email => email.length > 0)
    );

    const names = Array.from(
      nameString
        .split(',')
        .map(name => name.trim())
        .filter(name => name.length > 0)
    );

    return emails.map(email => {
      const nameValue = names.find(
        name => names.indexOf(name) === emails.indexOf(email)
      );
      return { name: nameValue, email: email };
    });
  };

  handleCSVUploadError = () => {
    toaster('CSV Upload Failed, Please try again later !!');
  };

  handleCSVData = (data, fileInfo) => {
    if (data && fileInfo.size < 200) {
      let csvData = [];
      const emailData = data.map(item => {
        csvData.push({ name: item[0], email: item[2] });
        return item[2];
      });
      let testData = emailData.toString();
      const randomString = Math.random().toString(36);
      this.setState({
        to: testData,
        theInputKey: randomString,
        csvData,
        disableTo: true
      });
    } else {
      toaster('Upload file size less than 2KB');
    }
  };

  handleCSVUploadError = () => {
    toaster('CSV Upload Failed, Please try again later !!');
  };

  pushNewMediaElementToMediaStorage = (
    mediaUrl,
    fileName,
    fileType,
    fileId
  ) => {
    let { mediaStorage } = this.state;

    if (fileType.includes('image')) {
      if (isEmpty(mediaStorage.images[fileName])) {
        mediaStorage.images[fileName] = {
          imageSrc: mediaUrl,
          primary: [],
          sourceId: fileId
        };
      } else {
        toaster('Image name already exists. Please use a different image name');
      }
    } else if (fileType.includes('video')) {
      if (isEmpty(mediaStorage.videos[fileName])) {
        mediaStorage.videos[fileName] = {
          variantId: null,
          videoSrc: mediaUrl,
          primary: []
        };
      } else {
        toaster('Image name already exists. Please use a different image name');
      }
    } else {
      toaster('Invalid file type');
    }
    this.setState(
      { mediaStorage: mediaStorage, isLoadingMediaStorageImages: false },
      () => {
        toaster('Image Uploaded');
      }
    );
  };

  uploadImageVideoHandler = e => {
    const selectedFile = e.target.files ? e.target.files[0] : '';
    const selectedFileName = e.target.files ? e.target.files[0].name : '';
    const fileType = e.target.files[0].type || '';
    const fileId = e.target.id || '';
    const { mediaStorage } = this.state;

    if (Object.keys(mediaStorage.images).length >= 5) {
      toaster('You can only upload a maximum of 5 images');
    } else {
      if (!isEmpty(mediaStorage.images[selectedFileName])) {
        toaster('Image name already exists. Please use a different image name');
      } else {
        this.setState(
          {
            isLoadingMediaStorageImages: true
          },
          () => {
            toaster('Image Uploading...');
            var uploadMediaRequestBody = new FormData();
            uploadMediaRequestBody.append(selectedFileName, selectedFile);
            uploadMediaRequestBody.append(
              'fileNames',
              `["${selectedFileName}"]`
            );
            const token = getAuthToken();
            const headers = {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: token
            };
            post(
              `${API_BASE_URL}/command?type=addMedia`,
              uploadMediaRequestBody,
              headers
            ).then(response => {
              const result = response[Object.keys(response)[0]];
              this.setState({ fetchedUploadedMediaUrl: result }, () => {
                let { fetchedUploadedMediaUrl } = this.state;
                this.pushNewMediaElementToMediaStorage(
                  fetchedUploadedMediaUrl,
                  selectedFileName,
                  fileType,
                  fileId
                );
              });
            });
          }
        );
      }
    }
  };

  deleteMedia = (mediaId, mediaType) => {
    let { mediaStorage } = this.state;
    if (mediaType === 'image') {
      delete mediaStorage.images[mediaId];
    } else if (mediaType === 'video') {
      delete mediaStorage.videos[mediaId];
    }
    this.setState(
      {
        mediaStorage: mediaStorage
      },
      () => {}
    );
  };

  inviteCoseller = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen }, () => {
      const { isOpen } = this.state;
      if (isOpen) {
        this.fetchEmailInviteLink();
        this.fetchActiveTemplate();
      }
    });
  };

  fetchActiveTemplate = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/notifications/templates/referral-invite`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      this.setState({ isLoading: false });
      showApiErrorToast(response.data);
    } else {
      this.setState({isLoading: false, activeTemplateContent: response?.data?.content });
    }
  };

  fetchEmailInviteLink = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const requestBody = {
      type: INVITE_TYPE_REFERRER
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/invites`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      this.setState({ isLoading: false });
      toaster('Not able to fetch email link');
    } else {
      this.setState({
        isLoading: false,
        emailInviteLink: `${APP_BASE_URL}/users/signup${response.data.data.slug}`
      });
    }

  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      modalContent: null,
      to: '',
      subject: '',
      tagline: '',
      description: '',
      mediaStorage: {
        images: {},
        videos: {}
      },
      isLoadingMediaStorageImages: false,
      link: '',
      csvData: [],
      disableTo: false
    });
  };

  text = React.createRef();

  copyLink = () => {
    this.text.current.select();
    this.text.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  removeCsv = () => {
    this.setState({ toName: '', toEmail: '', csvData: [], disableTo: false });
  };

  render() {
    const {
      isOpen,
      toEmail,
      toName,
      emailInviteLink,
      csvData,
      disableTo,
      isLoading
    } = this.state;
    const { width, height, name, text, hideButton } = this.props;

    return (
      <div>
        {!hideButton ? (
          <DottedOutline
            width={width}
            height={height}
            name={name}
            onClick={this.inviteCoseller}
            text={text}
          />
        ) : (
          ''
        )}
        {isOpen === true ? (
          <>
            <Modal>
              <Container>
                <NewClose onClick={() => this.closeModal()}> X </NewClose>
                <ReferrerWrapper>
                  <ReferrerHeading>
                    {name === 'refer-vendor' ? 'Referrer link' : 'Refer Link'}
                  </ReferrerHeading>
                  <InputContainer onKeyDown={this.handleKeyDown}>
                    <ProductLink
                      type="text"
                      ref={this.text}
                      readOnly
                      defaultValue={emailInviteLink}
                      onClick={this.keyPress}
                    />
                    <SecondaryButton
                      text="copy"
                      disabled={emailInviteLink === ''}
                      onClick={this.copyLink}
                    />
                  </InputContainer>
                </ReferrerWrapper>
                <Heading>Send an e-mail</Heading>
                <EmailForm>
                  <div
                    style={{
                      display: 'flex',
                      borderBottom: '1px solid black',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        minWidth: '300px',
                        width: '100%'
                      }}
                    >
                      <ToInput
                        disabled={disableTo}
                        name="toName"
                        required={true}
                        value={toName}
                        placeHolder={'Name'}
                        onChange={this.inputChangeHandler}
                      />
                      <ToInput
                        disabled={disableTo}
                        name="toEmail"
                        required={true}
                        value={toEmail}
                        placeHolder={'Email'}
                        onChange={this.inputChangeHandler}
                      />
                    </div>
                    {csvData.length === 0 ? (
                      <CSVWrapper>
                        <CSVContainer key={this.state.theInputKey || ''}>
                          <CSVReader
                            inputId="ObiWan"
                            onFileLoaded={(data, fileInfo) =>
                              this.handleCSVData(data, fileInfo)
                            }
                            parserOptions={papaparseOptions}
                            onError={this.handleCSVUploadError}
                            label="+ Add CSV File"
                            cssClass="csv-reader-input"
                          />
                        </CSVContainer>
                        <HelpSpan>
                          <Icons name="info-icon" width={20} height={20} />
                          Upload CSV in First name, Last name, Email Address
                          format.
                        </HelpSpan>
                      </CSVWrapper>
                    ) : (
                      <RemoveEmailsWrapper onClick={this.removeCsv}>
                        <i className="far fa-times-circle" />
                        &nbsp;Remove CSV File
                      </RemoveEmailsWrapper>
                    )}
                  </div>
                  <Content>
                    {isLoading ? (
                      <Loader w={'100%'} isCenter={true} />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.activeTemplateContent
                        }}
                      />
                    )}
                    <ActionsContainer>
                      <SendEmailContainer>
                        <SendEmail
                          text="Send"
                          onClick={this.sendMail}
                          isDisabled={!toEmail || !toName}
                        />
                      </SendEmailContainer>
                    </ActionsContainer>
                  </Content>
                </EmailForm>
              </Container>
            </Modal>
          </>
        ) : null}
      </div>
    );
  }
}

InviteCoseller.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string,
  text: PropTypes.string,
  isCoseller: PropTypes.bool
};

export default InviteCoseller;
