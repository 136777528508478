import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import DefaultImage from '../../../../../assets/images/no-image.svg';
import shoptypeThemes from '../../../../../theme';
import WeeklySalesTrend from '../../../../common/Graph/weeklySalesTrend';
import { isPositiveInteger } from '../../../../../utils';
import { Location } from '../styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    '@media only screen and (max-width: 768px)': {
      padding: '10px'
    }
  },
  avatar: {
    height: '100px',
    width: '100px',
    padding: '5px',
    backgroundColor: shoptypeThemes.default.LIGHT_SILVER,
    borderRadius: '50%',

    '& .MuiAvatar-img': {
      borderRadius: '50%',
      objectFit: styles => styles.objectFit
    },

    '@media only screen and (max-width: 1420px)': {
      height: '95px',
      width: '95px'
    },

    '@media only screen and (max-width: 768px)': {
      height: '80px',
      width: '80px'
    }
  },
  cardContent: {
    padding: '0px 12px',
    marginTop: 'auto'
  },
  idInfo: {
    color: shoptypeThemes.default.BLUE_GEM,
    fontSize: '10px',
    lineHeight: '8px'
  },
  vendorData: {
    display: 'grid',
    gridTemplateColumns: styles => styles.gridTemplateColumns,
    gridGap: styles => styles.gridGap,
    paddingBottom: '10px'
  },
  vendorItem: {
    alignSelf: styles => styles.alignSelf,
    justifySelf: 'center',
    textAlign: 'center'
  },
  dataLabel: {
    fontSize: '10px',
    color: shoptypeThemes.default.BLUE_GEM,
    fontWeight: 500
  },
  description: {
    minHeight: '50px',
    padding: '4px 12px',
    fontSize: '12px',
    lineHeight: '1.2'
  },
  buttonsContainer: {
    display: 'flex',
    padding: '0px 20px 25px 20px'
  },
  button: {
    flex: '1',
    textTransform: 'none',
    backgroundColor: shoptypeThemes.default.RONCHI
  },
  vendorInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: shoptypeThemes.default.BLACK,
    height: '20px',
    padding: '10px',
    borderRadius: '15px',
    backgroundColor: 'rgba(255, 216, 0, 0.35)'
  },
  icons: {
    width: '15px',
    height: '15px',
    marginRight: '3px'
  },
  title: {
    lineHeight: '1',
    marginBottom: '5px',
    wordBreak: 'break-word'
  }
});

const DiscoverVendorCard = props => {
  const { vendor, mode = 'unlinked', vendorCardActionMethods, store } = props;

  // GRAPH
  const { weeklySalesTrend } = vendor;

  const salesTrend = weeklySalesTrend?.map(sale => {
    return sale.total_sales;
  });
  // END GRAPH

  // CARD HEADER
  const avatar = vendor?.logo || DefaultImage;
  const vendorName = vendor?.name ? vendor?.name : '--No name--';
  const vendorId = vendor?.id ? `XXXXXX${vendor?.id.slice(-4)}` : 'XXXXXXXXXX';
  const location = vendor?.store?.city ? vendor?.store.city : '--';
  const currency = vendor?.store?.currency ? vendor?.store.currency : '--';

  // CARD CONTENT
  const description = store?.settings?.description
    ? store.settings.description
    : '--No description--';
  const commissionOffered = isPositiveInteger(vendor?.commission_offered)
    ? `${vendor?.commission_offered}%`
    : '0%';
  const totalProducts = vendor?.productCount || 0;
  const ordersLastMonth = vendor?.total_orders_last_month || 0;

  const VENDOR_DATA = [
    {
      label: 'Commission Offered',
      value: commissionOffered
    },
    {
      label: 'Total Products',
      value: totalProducts
    },
    {
      label: 'Orders Last Month',
      value: ordersLastMonth
    }
  ];

  !isEmpty(weeklySalesTrend) &&
    !isEmpty(salesTrend) &&
    VENDOR_DATA.push({
      label: 'Sales Trends Last Month',
      value: <WeeklySalesTrend salesTrend={salesTrend} />
    });

  // Conditional stylings
  const gridTemplateColumns = weeklySalesTrend
    ? 'repeat(3, 1fr) 1.4fr'
    : 'repeat(3, 1fr)';
  const gridGap = weeklySalesTrend ? '8px' : '2px';
  const alignSelf = weeklySalesTrend ? 'end' : 'flex-start';
  const imageObjectFit = avatar === vendor?.logo ? 'cover' : 'none';

  const styles = {
    gridTemplateColumns: gridTemplateColumns,
    gridGap: gridGap,
    alignSelf: alignSelf,
    objectFit: imageObjectFit
  };

  const classes = useStyles(styles);

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Avatar
            className={classes.avatar}
            aria-label="network-image"
            src={avatar}
          />
        }
        title={vendorName}
        classes={{ title: classes.title }}
        subheader={
          <>
            <Box display="flex" flexDirection="column" mb={1.5}>
              <Typography variant="body2">{vendorId}</Typography>
              <Typography className={classes.idInfo}>
                Shoptype vendor Id
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: '3px' }}
            >
              <div className={classes.vendorInfo}>
                <LocationOnIcon className={classes.icons} />
                <Tooltip title={location} arrow>
                  <Location>{location}</Location>
                </Tooltip>
              </div>
              <div className={classes.vendorInfo}>
                <MoneyOutlinedIcon className={classes.icons} />
                <Box fontSize={10} fontWeight={500}>
                  {currency}
                </Box>
              </div>
            </Box>
          </>
        }
        titleTypographyProps={{
          variant: 'h6'
        }}
        subheaderTypographyProps={{
          variant: 'subtitle2'
        }}
      />
      <Typography className={classes.description}>{description}</Typography>
      <CardContent className={classes.cardContent}>
        <div className={classes.vendorData}>
          {VENDOR_DATA.map((data, index) => (
            <div key={index} className={classes.vendorItem}>
              <Box fontSize={24} fontWeight={500}>
                {data.value}
              </Box>
              <Typography className={classes.dataLabel}>
                {data.label}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
      {mode === 'linked' ? (
        <CardActions className={classes.buttonsContainer}>
          <Button
            variant="contained"
            className={`${classes.button}`}
            onClick={() =>
              vendorCardActionMethods.unlinkVendor(vendor?.vendorId)
            }
            startIcon={<LinkIcon />}
          >
            Remove from Network
          </Button>
        </CardActions>
      ) : (
        <CardActions className={classes.buttonsContainer}>
          <Button
            variant="contained"
            className={`${classes.button}`}
            onClick={() => vendorCardActionMethods.handleInvite(vendor)}
            startIcon={<LinkIcon />}
          >
            Add to Network
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

DiscoverVendorCard.propTypes = {
  mode: PropTypes.string,
  vendor: PropTypes.object,
  vendorCardActionMethods: PropTypes.object,
  store: PropTypes.object
};

export default DiscoverVendorCard;
