import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { toaster } from '../../../atoms/toaster';
import { withStyles } from '@material-ui/core/styles';
import { API_BASE_URL } from '../../../../config/env';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  FormHelperText,
  Tooltip,
  InputAdornment,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions
} from '@material-ui/core';
import {
  getAuthToken,
  putWithResponseObject,
  successStatusCodes
} from '../../../../utils';
import { InputFieldContainer } from './styles.js';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const CustomizedTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}))(Tooltip);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function EditKeyDialog({
  open,
  editKeyData,
  handleCloseDialog,
  scopeOptions,
  showSpinnerBackdrop,
  hideSpinnerBackdrop,
  getUpdatedApiKeys
}) {
  const domainTooltip = 'Example: *.shoptype.com';

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    domain: '',
    scope: []
  });
  const [errors, setErrors] = useState({
    name: false,
    domain: false,
    scope: false
  });
  const [errorMessage, setErrorMessage] = useState({
    name: '',
    domain: '',
    scope: ''
  });

  useEffect(() => {
    setFormData(editKeyData);
  }, [editKeyData]);

  const handleFormDataChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const closeDialog = () => {
    handleCloseDialog();
    setFormData({ name: '', domain: '', scope: [] });
    setErrors({ name: false, domain: false, scope: false });
    setErrorMessage({ name: false, domain: false, scope: false });
  };

  const validateForm = () => {
    let copiedErrors = { ...errors };
    let copiedErrorMessage = { ...errorMessage };

    if (formData.name.length < 1) {
      copiedErrors.name = true;
      copiedErrorMessage.name = 'Name is required';
    } else {
      copiedErrors.name = false;
      copiedErrorMessage.name = '';
    }

    if (formData.domain.length < 1) {
      copiedErrors.domain = true;
      copiedErrorMessage.domain = 'Domain is required';
    } else {
      copiedErrors.domain = false;
      copiedErrorMessage.domain = '';
    }

    if (isEmpty(formData.scope)) {
      copiedErrors.scope = true;
      copiedErrorMessage.scope = 'At least one scope is required';
    } else {
      copiedErrors.scope = false;
      copiedErrorMessage.scope = '';
    }

    setErrors(copiedErrors);
    setErrorMessage(copiedErrorMessage);

    return Object.values(copiedErrors).every(error => error === false);
  };

  const handleUpdateKey = async () => {
    const valid = validateForm();

    if (valid) {
      const token = getAuthToken();
      const headers = {
        'Content-type': 'application/json',
        authorization: token
      };
      const reqBody = {
        id: formData.id,
        name: formData.name,
        scopes: formData.scope,
        allowed_domain: formData.domain
      };

      showSpinnerBackdrop();
      const res = await putWithResponseObject(
        `${API_BASE_URL}/api-keys`,
        reqBody,
        headers
      );
      hideSpinnerBackdrop();

      if (successStatusCodes.includes(res.status)) {
        closeDialog();
        getUpdatedApiKeys();
      } else {
        toaster(res.data.message || 'Cannot add key');
      }
    }
  };

  return (
    <Dialog onClose={closeDialog} open={open} fullWidth={true} maxWidth="xs">
      <DialogTitle onClose={closeDialog}>Edit Key</DialogTitle>
      <DialogContent dividers>
        <InputFieldContainer>
          <TextField
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleFormDataChange}
            error={errors.name}
            helperText={errorMessage.name}
          />
        </InputFieldContainer>
        <InputFieldContainer>
          <TextField
            name="domain"
            label="Domain"
            value={formData.domain}
            onChange={handleFormDataChange}
            error={errors.domain}
            helperText={errorMessage.domain}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CustomizedTooltip title={domainTooltip}>
                    <HelpOutlineIcon fontSize="small" />
                  </CustomizedTooltip>
                </InputAdornment>
              )
            }}
          />
        </InputFieldContainer>
        <InputFieldContainer>
          <FormControl>
            <InputLabel>Scope</InputLabel>
            <Select
              name="scope"
              multiple
              value={formData.scope}
              onChange={handleFormDataChange}
              input={<Input />}
              renderValue={selected => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {scopeOptions.map(item => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={formData.scope.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errorMessage.scope}</FormHelperText>
          </FormControl>
        </InputFieldContainer>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleUpdateKey} color="primary">
          Save Key
        </Button>
      </DialogActions>
    </Dialog>
  );
}
