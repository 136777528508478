import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import history from '../../../history';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/uiActions';
import { DropDownSearchBar as ProductCategories } from '../SearchBar';
import {
  VendorOnBoardForm,
  Next,
  Container,
  Heading,
  SubHeading,
  VendorOnBoardContainer,
  VendorName,
  WebsiteUrl
} from './styles';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  postWithResponseObject,
  isInvalidVendorDetails,
  showApiErrorToast
} from '../../../utils';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';

class VendorOnBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorName: '',
      productCategories: [],
      websiteUrl: ''
    };
  }

  queryOnboardData = async () => {
    const { vendorName, productCategories, websiteUrl } = this.state;
    const token = getAuthToken();
    const timestamp = new Date().getTime();

    let error = isInvalidVendorDetails(vendorName, productCategories);
    if (error) {
      toaster(error);
      return;
    }

    const headers = { authorization: token };
    const requestBody = {
      name: vendorName.trim(),
      productCategories: productCategories,
      url: websiteUrl.trim(),
      timestamp: timestamp
    };
    this.setState({ loading: true }, async () => {
      this.props.showSpinnerBackdrop();
      this.setState({ disableButton: true });
      let res = await postWithResponseObject(
        `${API_BASE_URL}/vendors`,
        requestBody,
        headers
      );
      this.setState({ disableButton: false });
      this.props.hideSpinnerBackdrop();

      if (successStatusCodes.includes(res.status)) {
        this.setState({
          loading: false
        });
        localStorage.setItem('token', JSON.stringify(res.data.token));
        const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
          authorization: token
        });

        if (successStatusCodes.includes(profileRes.status)) {
          localStorage.setItem(
            'userProfile',
            JSON.stringify(profileRes.data || {})
          );
        } else {
          localStorage.setItem('userProfile', JSON.stringify({}));
        }
        history.push('/dashboard');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
      } else {
        showApiErrorToast(res.data);
      }
    });
  };

  inputChangeHandler = e => {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleSubmit = () => {
    this.queryOnboardData();
  };

  fetchSelectedCategories = categories => {
    let { productCategories } = this.state;
    productCategories = categories.filter(
      category => !isEmpty(category.replace(/ /g, ''))
    );
    this.setState({ productCategories });
  };

  render() {
    const { vendorName, websiteUrl, disableButton } = this.state;
    return (
      <Container>
        <Heading>Create a profile</Heading>
        <SubHeading>Add your details</SubHeading>
        <VendorOnBoardContainer>
          <VendorOnBoardForm>
            <VendorName
              name="vendorName"
              type="text"
              placeHolder="What is your vendor name ?"
              required={true}
              id="vendorName"
              value={vendorName}
              onChange={this.inputChangeHandler}
            />
            <ProductCategories
              fetchValues={this.fetchSelectedCategories}
              placeHolder="What will you be selling ?"
            />
            <WebsiteUrl
              name="websiteUrl"
              type="text"
              placeHolder="Company website URL"
              id="website"
              value={websiteUrl}
              onChange={this.inputChangeHandler}
              autocomplete="off"
            />
          </VendorOnBoardForm>
        </VendorOnBoardContainer>
        <div style={{ marginTop: '70px' }}>
          <Next
            text="Next"
            disabled={disableButton}
            onClick={this.handleSubmit}
          />
        </div>
      </Container>
    );
  }
}

VendorOnBoard.propTypes = {
  history: PropTypes.object.isRequired,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(VendorOnBoard));
