import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  PathContainer,
  PathItem,
  PathItemNumberContainer,
  PathItemNumber,
  PathItemContent
} from './styles';

const Path = ({ content }) => {
  if (!isEmpty(content)) {
    return (
      <PathContainer>
        {content.map((item, index) => (
          <PathItem key={index}>
            <PathItemNumberContainer>
              <PathItemNumber>{index + 1}</PathItemNumber>
            </PathItemNumberContainer>
            <PathItemContent>{item}</PathItemContent>
          </PathItem>
        ))}
      </PathContainer>
    );
  } else {
    return null;
  }
};

Path.propTypes = {
  content: PropTypes.array
};

export default Path;
