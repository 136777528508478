import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepIconActive: {
    color: '#F0D64E !important'
  },
  completed: {
    color: '#47B881 !important'
  },
  stepIconText: {
    fontWeight: '500'
  }
}));

export default function HorizontalLabelPositionBelowStepper({
  steps,
  activeStep
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(step => (
          <Step key={step.id}>
            <StepLabel
              StepIconProps={{
                classes: {
                  text: classes.stepIconText,
                  completed: classes.completed,
                  active: classes.stepIconActive
                }
              }}
            >
              {step.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
