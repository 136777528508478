import React from 'react';
import { func, object } from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Layout from './Layout';
import CheckoutForm from './CheckoutForm';
import isEqual from 'lodash/isEqual';
import { STRIPE_PUBLISH_KEY_LOCAL } from '../../../config/env';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as createFinalAmountActions from '../../../redux/actions/createFinalAmountActions';
import { isNil } from 'lodash';
import { getCart } from '../../../apis/cart';
import Loader from '../../atoms/loader';
import { Message } from './styles';
import { getCartId } from '../../../utils';

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY_LOCAL);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      isSalesOrderError: false,
      salesOrderResponse: {},
      checkoutFormData: { address: {} },
      cartData: {},
      loading: true,
      hasError: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.setCheckoutId();
    this.getCartData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.totalAmount && this.props.totalAmount) {
      if (
        prevProps.totalAmount.salesOrderProcessing !==
        this.props.totalAmount.salesOrderProcessing
      ) {
        this.setState({
          isProcessing: this.props.totalAmount.salesOrderProcessing
        });
      }
      if (
        prevProps.totalAmount.salesOrderError !==
        this.props.totalAmount.salesOrderError
      ) {
        this.setState({
          isSalesOrderError: this.props.totalAmount.salesOrderError
        });
      }
      if (
        !isEqual(
          prevProps.totalAmount.salesOrderResponse,
          this.props.totalAmount.salesOrderResponse
        )
      ) {
        this.setState({
          salesOrderResponse: this.props.totalAmount.salesOrderResponse
        });
      }
    }
  }

  setCheckoutId = () => {
    const checkoutId = this.props.match.params.checkoutId;
    sessionStorage.setItem('checkout_id', checkoutId);
  };

  getCartData = async () => {
    const cartId = getCartId();

    if (cartId) {
      const cartRes = await getCart();

      if (cartRes?.cart_lines?.length > 0) {
        this.setState({ cartData: cartRes, loading: false, hasError: false });
      } else {
        this.setState({
          loading: false,
          hasError: true,
          errorMessage: 'Either cart is empty or failed to load products.'
        });
      }
    } else {
      this.setState({
        loading: false,
        hasError: true,
        errorMessage: 'Cart id does not exist.'
      });
    }
  };

  getFomattedPayload = async (paymentMethod, fields) => {
    const { billing, shipping } = fields;
    const cartLines = this.state.cartData.cart_lines;
    let productsArray = [];

    for (let i in cartLines) {
      for (let j = 0; j < cartLines[i].quantity; j++) {
        productsArray.push(parseInt(cartLines[i].product_variant_id, 10));
      }
    }

    const shippingName = isNil(shipping.firstname)
      ? isNil(shipping.lastname)
        ? ''
        : shipping.lastname
      : isNil(shipping.lastname)
      ? shipping.firstname
      : shipping.firstname + ' ' + shipping.lastname;

    const billingName =
      isNil(billing.firstname) || billing.firstname === ''
        ? isNil(billing.lastname) || billing.lastname === ''
          ? shippingName
          : billing.lastname
        : isNil(billing.lastname) || billing.lastname === ''
        ? billing.firstname
        : billing.firstname + ' ' + billing.lastname;

    const shipTo = {
      name: shippingName,
      street1: isNil(shipping.address.street1) ? '' : shipping.address.street1,
      street2: isNil(shipping.address.street1) ? '' : shipping.address.street1,
      city: isNil(shipping.address.city) ? '' : shipping.address.city,
      state: isNil(shipping.address.state) ? '' : shipping.address.state,
      postal_code: isNil(shipping.address.postal_code)
        ? ''
        : shipping.address.postal_code,
      country: isNil(shipping.address.countryCode)
        ? ''
        : shipping.address.countryCode,
      phone: isNil(shipping.address.phone) ? '' : shipping.address.phone
    };

    const billTo = {
      name: billingName,
      street1:
        isNil(billing.address.street1) || billing.address.street1 === ''
          ? shipping.address.street1
          : billing.address.street1,
      street2:
        isNil(billing.address.street2) || billing.address.street1 === ''
          ? shipping.address.street1
          : billing.address.street1,
      city:
        isNil(billing.address.city) || billing.address.city === ''
          ? shipping.address.city
          : billing.address.city,
      state:
        isNil(billing.address.state) || billing.address.state === ''
          ? shipping.address.state
          : billing.address.state,
      postal_code:
        isNil(billing.address.postal_code) || billing.address.postal_code === ''
          ? shipping.address.postal_code
          : billing.address.postal_code,
      country:
        isNil(billing.address.countryCode) || billing.address.countryCode === ''
          ? shipping.address.countryCode
          : billing.address.countryCode,
      phone:
        isNil(billing.address.phone) || billing.address.phone === ''
          ? shipping.address.phone
          : billing.address.phone
    };

    const payload = {
      customer_email: isNil(shipping.email) ? '' : shipping.email,
      products: productsArray,
      ship_to: shipTo,
      bill_to: billTo,
      payment_method: isNil(paymentMethod.id) ? '' : paymentMethod.id
    };
    return payload;
  };

  render() {
    const {
      isProcessing,
      isSalesOrderError,
      salesOrderResponse,
      cartData,
      loading,
      hasError,
      errorMessage
    } = this.state;
    const {
      fetchStatesFromCountryCode,
      createSalesOrder,
      setCheckoutFormFields,
      totalAmount,
      clearBillingFromCheckoutData
    } = this.props;

    return (
      <Layout title="Checkout" fullScreen={loading || hasError}>
        {!loading && !hasError && (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              createSalesOrder={createSalesOrder}
              isProcessing={isProcessing}
              isSalesOrderError={isSalesOrderError}
              salesOrderResponse={salesOrderResponse}
              setCheckoutFormFields={setCheckoutFormFields}
              cartData={cartData.cart_lines}
              productSourceName={cartData.source}
              checkoutFormData={totalAmount.checkoutFormData}
              clearBillingFromCheckoutData={clearBillingFromCheckoutData}
              fetchStatesFromCountryCode={fetchStatesFromCountryCode}
              states={totalAmount.states}
              getFomattedPayload={this.getFomattedPayload}
            />
          </Elements>
        )}
        {loading && (
          <Loader isFlex={true} w={'100%'} isCenter={true} min_h={'100%'} />
        )}
        {hasError && <Message>{errorMessage}</Message>}
      </Layout>
    );
  }
}

Checkout.propTypes = {
  fetchStatesFromCountryCode: func,
  createSalesOrder: func,
  setCheckoutFormFields: func,
  totalAmount: object,
  clearBillingFromCheckoutData: func
};

const mapStateToProps = state => {
  let { totalAmount } = state;

  return {
    totalAmount
  };
};

const mapDispatchToProps = dispatch => ({
  createSalesOrder(payload) {
    dispatch(createFinalAmountActions.createSalesOrder(payload));
  },
  setCheckoutFormFields(formFields) {
    dispatch(createFinalAmountActions.setCheckoutFormFields(formFields));
  },
  clearBillingFromCheckoutData() {
    dispatch(createFinalAmountActions.clearBillingFromCheckoutData());
  },
  fetchStatesFromCountryCode(countryCode, type) {
    dispatch(
      createFinalAmountActions.fetchStatesFromCountryCode(countryCode, type)
    );
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
