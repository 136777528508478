import { Button, Container, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from '../../../../config/env';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import * as actions from '../../../../redux/actions/uiActions';
import shoptypeThemes from '../../../../theme';
import {
  getApiResponseObject,
  getAuthToken,
  postWithResponseObject,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import EmptyState from '../../../atoms/EmptyState';
import PageTitle from '../../../common/PageTitle';
import { MESSAGE_SEVERITY, withSnackbar } from '../../../hoc/withSnackbar';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: '24px 48px',
    margin: 0
  },
  subContainer: {
    margin: 0,
    padding: '24px',
    borderRadius: '10px',
    backgroundColor: shoptypeThemes.default.WHITE,
    boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.1)'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '300px',
    justifyContent: 'space-between',
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  button: {
    width: 100,
    margin: theme.spacing(1),
    backgroundColor: shoptypeThemes.default.RONCHI,
    color: shoptypeThemes.default.BLACK,
    fontSize: '15px',

    '&:hover': {
      backgroundColor: shoptypeThemes.default.RONCHI
    }
  }
}));

const NetworkOperatorNetworks = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showSnackbar } = props;

  useEffect(() => {
    fetchNetworkData();
  }, []);

  const [loading, setLoading] = useState(true);
  const [networkDetails, setNetworkDetails] = useState({
    id: '',
    name: '',
    networkCharge: ''
  });

  const fetchNetworkData = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    dispatch(actions.showSpinnerBackdropAction());

    const response = await getApiResponseObject(
      `${API_BASE_URL}/networks`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      setLoading(false);
      setNetworkDetails({});
      dispatch(actions.hideSpinnerBackdropAction());
      showApiErrorToast(response.data);
    } else {
      setLoading(false);
      setNetworkDetails({
        id: response.data.network.id || '',
        name: response.data.network.name || '',
        networkCharge:
          response.data.network.network_setting?.networkFee?.percentage || ''
      });
    }

    dispatch(actions.hideSpinnerBackdropAction());
  };

  const handleNetworkChargeChange = useCallback(event => {
    const { name, value } = event.target;
    if (canUpdatePercentage(value)) {
      setNetworkDetails(prevState => {
        return {
          ...prevState,
          [name]: value
        };
      });
    }
  }, []);

  const canUpdatePercentage = value => {
    const num = Number(value);
    return (
      (value.length > 0 &&
        num <= 100 &&
        !['+', '-'].includes(value.charAt(0)) &&
        !(num === 100 && value.charAt(3) === '.')) ||
      value.length === 0
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const token = getAuthToken();
    const headers = { authorization: token };

    dispatch(actions.showSpinnerBackdropAction());

    const requestBody = {
      id: networkDetails.id,
      name: networkDetails.name,
      networkCharge: parseInt(networkDetails.networkCharge)
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/network-settings`,
      requestBody,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      setLoading(false);
      dispatch(actions.hideSpinnerBackdropAction());
      showSnackbar({
        message: 'Commision Updated successfully',
        severity: MESSAGE_SEVERITY.success
      });
    } else {
      dispatch(actions.hideSpinnerBackdropAction());
      setLoading(false);
      showSnackbar({
        error: response.data.code,
        message: 'Unable to update data',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  return (
    <Container className={classes.mainContainer}>
      <PageTitle
        title={'Network Settings'}
        tooltip={NETWORK_OPERATOR_INFO.pageDescription.Network}
      />
      {!loading && !isEmpty(networkDetails) ? (
        <Container component="main" className={classes.subContainer}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom={true}>
              NETWORK COMMISION (%)
            </Typography>
            <TextField
              id="outlined-required"
              name="networkCharge"
              placeholder="0%"
              label="Percentage"
              variant="outlined"
              type="text"
              style={{ width: 300 }}
              value={networkDetails.networkCharge}
              onChange={handleNetworkChargeChange}
              helperText="Value must be less than or equal to 100"
            />
            <Button
              className={classes.button}
              style={{ margin: 0, height: 40 }}
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </form>
        </Container>
      ) : (
        !loading &&
        isEmpty(networkDetails) && (
          <EmptyState
            errorMessage="Something went wrong"
            message="Please check your internet connection and try again."
          />
        )
      )}
    </Container>
  );
};

export default withSnackbar(NetworkOperatorNetworks);
