import styled, { css } from 'styled-components';

const UploadImageContainer = styled.div`

  border: 1px dotted black;
  text-align: center;

  @media (min-width: 768px) {
    ${props =>
      ({
        'empty-media-storage': css`
          width: 100%;
          height: 240px;
        `,
        'filled-media-storage': css`
          width: 240px;
          height: 240px;
        `,
        'variant-media': css`
          width: 120px;
          height: 120px;
          margin: 15px;
        `
      }[props.size] ||
      css`
        width: 240px;
        height: 240px;
      `)};
`;

const UploadImageLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => {
    return props.size === 'large' ? '100%' : '240px';
  }};
  height: ${props => {
    return props.size === 'large' ? '240px' : '240px';
  }};
  cursor: pointer;

  @media (min-width: 768px) {
    ${props =>
      ({
        'empty-media-storage': css`
          width: 100%;
          height: 240px;
        `,
        'filled-media-storage': css`
          width: 240px;
          height: 240px;
        `,
        'how-it-works': css`
          width: 240px;
          height: 240px;
        `,
        'variant-media': css`
          width: 120px;
          height: 120px;
        `
      }[props.size] ||
      css`
        width: 240px;
        height: 240px;
      `)};
`;

const UploadImageText = styled.div`
  font-size: ${props => props.theme.LARGE};
`;

const UploadImageFile = styled.input`
  display: none;
`;

export {
  UploadImageContainer,
  UploadImageFile,
  UploadImageText,
  UploadImageLabel
};
