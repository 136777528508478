import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { GOOGLE_MAPS_API_KEY } from '../../../constants';

import Theme from '../../../theme';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  root: {
    width: '100%',
    maxWidth: 568
  },
  addressRoot: {
    width: '100%',
    maxWidth: 568
  },
  inputRoot: {
    fontSize: 14,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    }
  },
  labelRoot: {
    fontSize: 14,
    color: Theme.default.CORDUROY,
    '&$labelFocused': {
      color: Theme.default.BUDDHA_GOLD
    }
  },
  labelFocused: {}
}));

export default function GooglePlacesAutoCompleteInput({
  fillAddressFetchedFromGooglePlaces,
  addressType,
  addressValue,
  allowCustomValues = true,
  inputLabel = 'Address'
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(addressValue);
  const [inputValue, setInputValue] = React.useState(addressValue);
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const initAutocomplete = () => {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(`${addressType}-address-google`)
    );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(['address_component']);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', () =>
      fillInAddress(autocomplete)
    );
  };

  const addressField = type => {
    if (type.includes('locality') && type.includes('political')) {
      return 'city';
    }
    if (
      type.includes('administrative_area_level_1') &&
      type.includes('political')
    ) {
      return 'state';
    }
    if (type.includes('postal_code')) {
      return 'postal_code';
    }
    if (type.includes('country')) {
      return 'country';
    }
  };

  function fillInAddress(autocomplete) {
    // Get the place details from the autocomplete object.
    const addressObject = autocomplete.getPlace();
    try {
      if (addressObject) {
        let addressDetails = {
          street1: '',
          city: '',
          state: '',
          stateCode: '',
          postal_code: '',
          country: '',
          countryCode: ''
        };

        const commaRegex = /,/g;
        const spaceRegex = /\s/g;
        let name = addressObject.name || '';
        let prependName = true;
        let longAddress = '';
        let shortAddress = '';
        let address = '';

        let filtered_address_components = addressObject.address_components.filter(
          item => {
            return (
              item.types.includes('street_number') ||
              item.types.includes('route') ||
              item.types.includes('sublocality_level_2') ||
              item.types.includes('sublocality_level_1')
            );
          }
        );

        addressObject.address_components.forEach(item => {
          longAddress += item.long_name.toLowerCase();
          shortAddress += item.short_name.toLowerCase();

          switch (addressField(item.types)) {
            case 'city':
              addressDetails.city = item.long_name || '';
              break;
            case 'state':
              addressDetails.state = item.long_name || '';
              addressDetails.stateCode = item.short_name || '';
              break;
            case 'postal_code':
              addressDetails.postal_code = item.long_name || '';
              break;
            case 'country':
              addressDetails.country = item.long_name || '';
              addressDetails.countryCode = item.short_name || '';
              addressDetails.countryCodePhone = item.short_name || '';
              break;
            default:
              break;
          }
        });

        filtered_address_components.forEach(item => {
          address += ` ${item.long_name}`;
        });

        if (
          shortAddress
            .replace(spaceRegex, '')
            .replace(commaRegex, '')
            .indexOf(
              name
                .replace(spaceRegex, '')
                .replace(commaRegex, '')
                .toLowerCase()
            ) !== -1 ||
          longAddress
            .replace(spaceRegex, '')
            .replace(commaRegex, '')
            .indexOf(
              name
                .replace(spaceRegex, '')
                .replace(commaRegex, '')
                .toLowerCase()
            ) !== -1
        ) {
          prependName = false;
        }

        addressDetails.street1 = prependName
          ? `${name} ${address.trim()}`
          : address.trim();

        fillAddressFetchedFromGooglePlaces(addressDetails);
      }
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          initAutocomplete();
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id={`${addressType}-address-google`}
      style={{ width: '100%' }}
      noOptionsText={'Please enter your address'}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={x => x}
      options={[addressValue]}
      value={addressValue}
      open={false}
      classes={classes}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        fillAddressFetchedFromGooglePlaces(autocompleteService);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onBlur={() => {
        if (allowCustomValues == true) {
          fillAddressFetchedFromGooglePlaces(
            { street1: inputValue },
            'addressOnly'
          );
        }
      }}
      renderInput={params => {
        params.inputProps.autoComplete = 'new-password';
        return (
          <TextField
            {...params}
            id={`${addressType}-address`}
            label={inputLabel}
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            fullWidth
          />
        );
      }}
      renderOption={option => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map(match => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
