import styled from 'styled-components';

const ProfileIcon = styled.img`
  height: 31px;
  width: 29px;
  border-radius: 50%;
  margin: 0px 0px 0px 50px;
  cursor: pointer;
`;

const ProfileDisplayImage = styled.img`
  max-height: 100px;
  min-width: 100px;
  border-radius: 50%;
  margin: 0px 0px 0px 0px;
`;

const ProfileName = styled.span`
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  padding: 0px 10px;
  cursor: pointer;
`;

const UserDetails = styled.div`
  border-bottom: solid 1px ${props => props.theme.GALLERY};
  min-height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const VendorName = styled.span`
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  background-color: ${props => props.theme.RONCHI};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  width: auto;
  border-radius: 5px 5px 5px 5px;
  height: 20px;
  padding: 0px 25px;
  color: ${props => props.theme.BLACK};
`;
const UserName = styled.div`
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  padding-left: 20px;
`;

const UserEmail = styled.div`
  font-size: ${props => props.theme.LARGE};
`;

const MenuOptions = styled.div``;

const ProfileDropDown = styled.div`
  min-width: 350px;
  justify-content: center;
  grid-column: 1 / span 2;
  position: absolute;
  top: 0;
  left: -160px;
  z-index: 2;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.WHITE};
`;

const LocationWidgetContainer = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
`;

const ProfileMenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 90px auto;
  grid-template-rows: auto;
  position: relative;
  align-items: center;
`;

const MenuItems = styled.div`
  :hover {
    background-color: ${props => props.theme.RONCHI};
    font-weight: ${props => props.theme.BOLD_FONT};
  }
  display: flex;
  min-height: 50px;
  cursor: pointer;
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  padding: 0 45px;
  align-items: center;
`;

const UserProfileImageContainer = styled.div`
  border-radius: 5px;
  width: 100px;
  height: 100px;
  background-color: ${props => props.theme.RONCHI};
`;

const VendorUserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Platform = styled(VendorName)`
  width: fit-content;
  padding: 0 15px;
`;

const AccountSwitch = styled.button`
  margin-top: 10px;
  cursor: pointer;
  background: black;
  border-radius: 5px;
  color: white;
  border: unset;
  padding: 5px;
`;

const AccountSwitchCoseller = styled.div`
  display: flex;
  justify-content: left;
  margin-left: 20px;
`;

export {
  ProfileIcon,
  ProfileName,
  ProfileDropDown,
  ProfileContainer,
  ProfileDisplayImage,
  MenuItems,
  UserDetails,
  MenuOptions,
  VendorName,
  UserName,
  UserEmail,
  UserProfileImageContainer,
  VendorUserDetails,
  Platform,
  AccountSwitch,
  AccountSwitchCoseller,
  ProfileMenuContainer,
  LocationWidgetContainer
};
