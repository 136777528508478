import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['totalAmount', 'buyProduct', 'productsShare']
};
const middleware = [];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
const persistedStore = createStore(
  persistedReducer,
  applyMiddleware(thunk, ...middleware)
);
const persistor = persistStore(persistedStore);

export { persistedStore, persistor };
