import React from 'react';
import PropTypes from 'prop-types';

import {
  UploadImageContainer,
  UploadImageFile,
  UploadImageText,
  UploadImageLabel
} from './styles';

const UploadMedia = ({ id, onChange, size }) => {
  return (
    <UploadImageContainer size={size}>
      <UploadImageLabel htmlFor={id} size={size}>
        <UploadImageText>Upload Media</UploadImageText>
      </UploadImageLabel>
      <UploadImageFile
        size={size}
        type="file"
        accept="image/gif, image/jpeg, image/png, video/mp4"
        id={id}
        onChange={onChange}
      />
    </UploadImageContainer>
  );
};

UploadMedia.defaultProps = {
  size: 'medium'
};

UploadMedia.propTypes = {
  accept: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  id: PropTypes.string,
  uploadTrigger: PropTypes.bool
};

export default UploadMedia;
