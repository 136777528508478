import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialLoader from '../../../../atoms/materialLoader';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Link,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import moment from 'moment';
import ProductDetails from '../ProductDetails';
import { makeStyles } from '@material-ui/styles';
import ConfirmationDialog from '../../../../pages/NetworkOperator/Approvals/dialogs/confirmationDialog';
import { DELETE_RETURN_REQUEST, CONFIRMATION_DIALOG } from './constants';
import {
  deleteWithResponseObject,
  getAuthToken,
  successStatusCodes
} from '../../../../../utils';
import { API_BASE_URL } from '../../../../../config/env';
import { withSnackbar, MESSAGE_SEVERITY } from '../../../../hoc/withSnackbar';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

// dialog styles
const useStyles = makeStyles({
  dialogContent: {
    width: '100%',
    minHeight: 380
  },
  centerContentBox: {
    boxSizing: 'border-box',
    display: 'flex',
    height: '340px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableContainer: {
    maxHeight: 400
  }
});

function RequestDetailsDialog({
  open,
  onClose,
  loading,
  dataAvailable,
  requests,
  hasDelete,
  onActionComplete,
  showSnackbar
}) {
  const classes = useStyles();
  const [popoverAnchorEls, setPopoverAnchorEls] = useState([]);
  const [currentActionDetails, setCurrentActionDetails] = useState({
    action: '',
    requestId: ''
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    open: false,
    title: '',
    description: ''
  });

  // handling confirmation dialog on currentActionDetails changes
  useEffect(() => {
    const handleConfirmationDialog = () => {
      switch (currentActionDetails.action) {
        case DELETE_RETURN_REQUEST:
          openConfirmationDialog(
            CONFIRMATION_DIALOG.delete.title,
            CONFIRMATION_DIALOG.delete.description
          );
      }
    };

    handleConfirmationDialog();
  }, [currentActionDetails]);

  const handleClose = () => {
    onClose();
  };

  const getProductsCount = lineItems => {
    const productIdList = Object.keys(lineItems);
    return productIdList.length;
  };

  const getProducts = lineItems => {
    const productIdList = Object.keys(lineItems);
    const productsList = [];

    productIdList.forEach(productId => {
      productsList.push(lineItems[productId]);
    });

    return productsList;
  };

  const openPopover = (index, event) => {
    let newPopoverAnchorEls = { ...popoverAnchorEls };
    newPopoverAnchorEls[index] = event.target;

    setPopoverAnchorEls(newPopoverAnchorEls);
  };

  const closePopover = index => {
    let newPopoverAnchorEls = { ...popoverAnchorEls };
    newPopoverAnchorEls[index] = null;

    setPopoverAnchorEls(newPopoverAnchorEls);
  };

  const onDeleteRequest = id => {
    setCurrentActionDetails({
      ...currentActionDetails,
      requestId: id,
      action: DELETE_RETURN_REQUEST
    });
  };

  const openConfirmationDialog = (title, description) => {
    setConfirmationDialogData({ open: true, title, description });
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogData({ ...confirmationDialogData, open: false });
  };

  const closeConfirmationDialogAndResetData = () => {
    setConfirmationDialogData({ ...confirmationDialogData, open: false });
    resetCurrentActionDetails();

    setTimeout(() => {
      resetConfirmationDialogData();
    }, 500);
  };

  const resetCurrentActionDetails = () => {
    setCurrentActionDetails({ action: '', requestId: '' });
  };

  const resetConfirmationDialogData = () => {
    setConfirmationDialogData({ open: false, title: '', description: '' });
  };

  const handleConfirmAction = () => {
    closeConfirmationDialog();

    if (currentActionDetails.action === DELETE_RETURN_REQUEST) {
      deleteRequest();
    }
  };

  const deleteRequest = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const res = await deleteWithResponseObject(
      `${API_BASE_URL}/coseller-returns/${currentActionDetails.requestId}`,
      headers
    );

    if (successStatusCodes.includes(res.status)) {
      showSnackbar({
        message: 'Successfully deleted request',
        severity: MESSAGE_SEVERITY.success
      });

      if (onActionComplete && typeof onActionComplete === 'function') {
        onActionComplete();
      }
      window.location.reload(false);
    } else {
      showSnackbar({
        message: 'Failed to delete request',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  return (
    <>
      {!loading && dataAvailable && (
        <ConfirmationDialog
          open={confirmationDialogData.open}
          title={confirmationDialogData.title}
          description={confirmationDialogData.description}
          onConfirm={handleConfirmAction}
          onClose={closeConfirmationDialogAndResetData}
          onCancel={closeConfirmationDialogAndResetData}
        />
      )}

      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleClose}>Request Details</DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          {loading ? (
            <div className={classes.centerContentBox}>
              <MaterialLoader />
            </div>
          ) : !loading && !dataAvailable ? (
            <div className={classes.centerContentBox}>
              <Typography
                variant="body2"
                gutterBottom
                color="error"
                align="center"
              >
                Failed to load return requests details for the current order.
              </Typography>
            </div>
          ) : (
            <>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                align="center"
              >
                Order No:&nbsp;{requests[0]?.external_order_id || '—'}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Note: Click on &quot;Details&quot; to know the list of items and
                current status of the request. You may choose to cancel a
                request if it&apos;s not yet processed at the Seller&apos;s end.
              </Typography>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Request No.</TableCell>
                      <TableCell>Date &amp; Time</TableCell>
                      <TableCell>Products Count</TableCell>
                      {hasDelete && <TableCell />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requests.map((row, rowIndex) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                        >
                          <TableCell>{row.id}</TableCell>
                          <TableCell>
                            {moment(row.created_at).format(
                              'DD MMM YYYY h:mm A'
                            )}
                          </TableCell>
                          <TableCell>
                            {getProductsCount(row.line_items)}&nbsp;
                            <Link
                              component="button"
                              variant="body2"
                              onClick={e => openPopover(rowIndex, e)}
                            >
                              (Details)
                            </Link>
                            <Popover
                              id={rowIndex}
                              anchorEl={popoverAnchorEls[rowIndex]}
                              open={Boolean(popoverAnchorEls[rowIndex])}
                              onClose={() => closePopover(rowIndex)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                              }}
                              PaperProps={{
                                style: { minWidth: '400px', maxWidth: '480px' }
                              }}
                            >
                              <ProductDetails
                                lineItems={getProducts(row.line_items)}
                                status={row.status}
                                returnReason={row.return_reason}
                              />
                            </Popover>
                          </TableCell>
                          {hasDelete && (
                            <TableCell>
                              <IconButton
                                onClick={() => onDeleteRequest(row.id)}
                              >
                                <DeleteIcon fontSize="small" color="error" />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

RequestDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  dataAvailable: PropTypes.bool,
  requests: PropTypes.array,
  hasDelete: PropTypes.bool,
  onActionComplete: PropTypes.func,
  showSnackbar: PropTypes.func
};

export default withSnackbar(RequestDetailsDialog);
