import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import ShoptypeApp from './App';
import { Helmet } from 'react-helmet';
import Provider from './redux/provider';
import './index.css';

class ThemedApp extends Component {
  state = {
    hasError: false,
    isAppOnline: true
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  componentDidMount() {
    window.addEventListener('online', this.handleNetworkChange, false);
    window.addEventListener('offline', this.handleNetworkChange, false);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleNetworkChange, false);
    window.removeEventListener('offline', this.handleNetworkChange, false);
  }

  handleNetworkChange = () => {
    const isAppOnline =
      typeof window.navigator.onLine === 'undefined' || window.navigator.onLine;
    this.setState({ isAppOnline });
  };
  render() {
    return (
      <Provider>
        <ThemeProvider theme={theme.default}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Faishare</title>
          </Helmet>
          <ShoptypeApp />
        </ThemeProvider>
      </Provider>
    );
  }
}

console.log(`${process.env.REACT_APP_VERSION}:${process.env.REACT_APP_ENV}`);

ReactDOM.render(<ThemedApp />, document.getElementById('root'));
