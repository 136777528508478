import React from 'react';
import Logo from '../../../assets/images/logo.png';
import history from '../../../history';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  TermsOfService,
  AcceptableUsablePolicy,
  PrivacyPolicy,
  DataProcessingAddendum,
  COVID19,
  ReturnPolicy,
  DCMA,
  PrivacyCustomers,
  PrivacyMerchants,
  PrivacyPartners,
  PrivacyVisitors,
  Security,
  GuidelinesForLegalRequests,
  Subprocessors,
  AwakeMembershipAgreement,
  AwakeMembershipAgreementExhibitA
} from './content';

import {
  Container,
  LogoContainer,
  LogoImage,
  LogoContent,
  Wrapper,
  Pane
} from './styles';

class Policies extends React.Component {
  componentDidMount() {}

  goHome = () => {
    history.push('/');
  };

  render() {
    const basePath = this.props.match.url;
    return (
      <Wrapper>
        <Container>
          <Pane>
            <LogoContainer>
              <LogoContent onClick={() => this.goHome()}>
                <LogoImage src={Logo} />
              </LogoContent>
            </LogoContainer>
            <div>
              <Switch>
                <Route path={`${basePath}/`} exact>
                  <Redirect to={`${basePath}/terms-of-service`} />
                </Route>
                <Route
                  path={`${basePath}/terms-of-service`}
                  component={TermsOfService}
                />
                <Route
                  path={`${basePath}/acceptable-use-policy`}
                  component={AcceptableUsablePolicy}
                />
                <Route
                  path={`${basePath}/privacy-policy`}
                  component={PrivacyPolicy}
                />
                <Route
                  path={`${basePath}/privacy-policy-merchants`}
                  component={PrivacyMerchants}
                />
                <Route
                  path={`${basePath}/privacy-policy-customers`}
                  component={PrivacyCustomers}
                />
                <Route
                  path={`${basePath}/privacy-policy-partners`}
                  component={PrivacyPartners}
                />
                <Route
                  path={`${basePath}/privacy-policy-visitors`}
                  component={PrivacyVisitors}
                />
                <Route path={`${basePath}/security`} component={Security} />
                <Route
                  path={`${basePath}/subprocessors`}
                  component={Subprocessors}
                />
                <Route
                  path={`${basePath}/gvt-access-policy`}
                  component={GuidelinesForLegalRequests}
                />
                <Route
                  path={`${basePath}/dpa`}
                  component={DataProcessingAddendum}
                />
                <Route path={`${basePath}/covid19`} component={COVID19} />
                <Route
                  path={`${basePath}/return-policy`}
                  component={ReturnPolicy}
                />
                <Route path={`${basePath}/dcma`} component={DCMA} />
                <Route
                  path={`${basePath}/awake-membership-agreement`}
                  component={AwakeMembershipAgreement}
                />
                <Route
                  path={`${basePath}/awake-membership-agreement-exhibit-a`}
                  component={AwakeMembershipAgreementExhibitA}
                />
                <Route path="*">
                  <Redirect to={`${basePath}/terms-of-service`} />
                </Route>
              </Switch>
            </div>
          </Pane>
        </Container>
      </Wrapper>
    );
  }
}

Policies.propTypes = {};

export default withRouter(Policies);
