import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../../molecules/Currency';
import { VARIANT_COMBO_DELIMITER } from '../../../../../constants';
import { titleCase } from '../../../../../utils';
import {
  Wrapper,
  Table,
  TableRow,
  TableHeading,
  ProductDetailsFlex,
  TableFooter,
  ProductImage,
  ProductsListContainer,
  MessageContainer
} from './styles';

const ProductDetails = ({ lineItems, status, returnReason }) => {
  return (
    <Wrapper>
      {lineItems.length > 0 ? (
        <Table>
          <TableRow>
            <TableHeading>Product</TableHeading>
            <TableHeading>Quantity</TableHeading>
            <TableHeading>Price</TableHeading>
          </TableRow>
          <ProductsListContainer>
            {lineItems.map((product, index) => (
              <TableRow key={index}>
                <ProductDetailsFlex>
                  <ProductImage>
                    <img src={product.image_src || ''} />
                  </ProductImage>
                  <div>
                    <div>{product.name}</div>
                    <div>
                      {product.variant_name_value
                        ? Object.values(product.variant_name_value).join(
                            VARIANT_COMBO_DELIMITER
                          )
                        : ''}
                    </div>
                  </div>
                </ProductDetailsFlex>
                <div>{product.quantity}</div>
                <div>
                  <Currency currencyName={product.price.currency} />
                  {product.price.amount}
                </div>
              </TableRow>
            ))}
          </ProductsListContainer>
          <TableFooter>
            <div>
              <TableHeading>Status:</TableHeading>
              &nbsp;{titleCase(status.replace('_', ' '))}
            </div>
            <div>
              <TableHeading>Reason:</TableHeading>
              &nbsp;{titleCase(returnReason)}
            </div>
          </TableFooter>
        </Table>
      ) : (
        <MessageContainer>
          No products available for this order
        </MessageContainer>
      )}
    </Wrapper>
  );
};

ProductDetails.propTypes = {
  lineItems: PropTypes.array,
  status: PropTypes.string,
  returnReason: PropTypes.string
};

export default ProductDetails;
