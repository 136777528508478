import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, LeftPanel, RightPanel, Row } from '../../Dashboard/styles';
import { Button } from '@material-ui/core';
import CollectionFiltersPanel from './CollectionFiltersPanel';
import CreateCollectionForm from './CreateCollectionForm';
import { isEmpty } from 'lodash';
import {
  getApiResponseObject,
  getAuthToken,
  postWithResponseObject,
  putWithResponseObject,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import { API_BASE_URL } from '../../../../config/env';
import { toaster } from '../../../atoms/toaster';
import history from '../../../../history';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../redux/actions/uiActions';
import { useParams, withRouter } from 'react-router-dom';
import { Heading, HeadingSection } from '../Dashboard/styles';

const useStyles = makeStyles({
  paddingContainer: {
    boxShadow: '0px -4px 3px rgb(64 60 67 / 16%)',
    borderTop: '1px solid #ccc'
  },
  draftBtn: {
    width: 185,
    backgroundColor: '#F0D64E',
    fontSize: '12px',
    margin: '10px 8px 10px 0px !important',
    color: '#000',
    height: '35px',
    '&:hover': {
      backgroundColor: '#F0D64E'
    }
  },
  publishBtn: {
    width: 185,
    backgroundColor: '#3E7000',
    fontSize: '12px',
    margin: '10px 8px 10px 0px !important',
    color: '#fff',
    height: '35px',
    '&:hover': {
      backgroundColor: '#3E7000'
    }
  },
  justifyEnd: {
    justifyContent: 'flex-end'
  }
});

function CreateCollection() {
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [deletedProduct, setDeletedProduct] = useState({});
  const [deletedTag, setDeletedTag] = useState('');
  const [payload, setPayload] = useState({});
  const [collectionID, setCollectionId] = useState('');
  const [
    selectedCollectionForUpdate,
    setSelectedCollectionForUpdate
  ] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  const [disableOnDateError, setDisableOnDateError] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isDynamicCollection, setIsDynamicCollection] = useState(false);

  useEffect(() => {
    let collectionIDFromParam = params.collectionId || '';
    if (collectionIDFromParam !== '') {
      fetchCollectionDataById(collectionIDFromParam);
      setCollectionId(collectionIDFromParam);
    }
  }, []);

  const fetchCollectionDataById = async collectionIDData => {
    const token = getAuthToken();
    const headers = { authorization: token };

    dispatch(actions.showSpinnerBackdropAction());
    const response = await getApiResponseObject(
      `${API_BASE_URL}/networks/collections/${collectionIDData}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      setSelectedCollectionForUpdate(response.data);
    }

    dispatch(actions.hideSpinnerBackdropAction());
  };

  const updateSelectedProducts = data => {
    setSelectedProducts(data);
  };

  const updateSelectedTags = data => {
    setSelectedTags(data);
  };

  const onDeleteProduct = product => {
    setDeletedProduct(product);
  };
  const onDeleteTag = tag => {
    setDeletedTag(tag);
  };
  useEffect(() => {
    if (!isEmpty(deletedProduct)) {
      setDeletedProduct({});
    }
  }, [deletedProduct]);

  useEffect(() => {
    if (deletedTag !== '') {
      setDeletedTag('');
    }
  }, [deletedTag]);

  const fetchPayload = data => {
    setPayload(data);
  };

  const fetchDynamicCollectionData = value => {
    setIsDynamicCollection(value);
  };
  const postCollection = async type => {
    let finalPayload = modifyPayload(type);
    const token = getAuthToken();
    const headers = {
      Authorization: token
    };
    const response = await postWithResponseObject(
      `${API_BASE_URL}/networks/collections`,
      finalPayload,
      headers
    );
    if (successStatusCodes.includes(response.status)) {
      toaster('Collection Created Successfully');
      history.push('/networkoperator/collections');
    } else {
      showApiErrorToast(response.data);
    }
  };
  const modifyPayload = type => {
    let newPayload = { ...payload };
    newPayload.status = type;
    let product_metas = [];
    let collection_ids = [];
    if (isProduct()) {
      product_metas = selectedProducts.map((ele, index) => {
        return {
          product_id: ele.id,
          sequence_id: index + 1
        };
      });
    } else {
      collection_ids = selectedProducts.map(ele => ele.id);
    }

    newPayload.product_metas = product_metas;
    newPayload.collection_ids = collection_ids;
    if (isDynamicCollection) {
      newPayload.product_query = {
        tags: selectedTags
      };
    }

    return newPayload;
  };
  const updateCollectionStatus = async type => {
    const token = getAuthToken();
    const headers = { authorization: token };
    let response = '';

    let finalPayload = modifyPayload(type);

    response = await putWithResponseObject(
      `${API_BASE_URL}/networks/collections/${collectionID}`,
      finalPayload,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      isEmpty(selectedCollectionForUpdate)
        ? toaster('Collection Created Successfully')
        : toaster('Collection Updated Successfully');
      history.push('/networkoperator/collections');
    } else {
      showApiErrorToast(response.data);
    }
  };
  const isProduct = () => {
    return payload.type === 'product';
  };
  const formValidationForCollection = () => {
    if (isEmpty(selectedCollectionForUpdate)) {
      if (
        selectedProducts?.length > 0 &&
        payload.name !== '' &&
        !disableOnDateError
      ) {
        return false;
      }
    }
    if (
      (selectedProducts?.length > 0 || selectedTags?.length > 0) &&
      payload.name !== '' &&
      !disableOnDateError
    ) {
      return false;
    }

    return true;
  };

  const onDateError = value => {
    setDisableOnDateError(value);
  };
  return (
    <>
      <Container>
        <div>
          <HeadingSection>
            <Heading>
              {!isEmpty(selectedCollectionForUpdate)
                ? 'Update Collection'
                : 'Create Collection'}
            </Heading>
          </HeadingSection>
          <div className={classes.paddingContainer}>
            <LeftPanel>
              <CreateCollectionForm
                onDateError={onDateError}
                updateSelectedProducts={updateSelectedProducts}
                selectedCollectionForUpdate={selectedCollectionForUpdate}
                selectedProducts={selectedProducts}
                onDeleteProduct={onDeleteProduct}
                fetchPayload={fetchPayload}
                fetchDynamicCollectionData={fetchDynamicCollectionData}
                selectedTags={selectedTags}
                updateSelectedTags={updateSelectedTags}
                onDeleteTag={onDeleteTag}
              />
              <Row className={classes.justifyEnd}>
                <Button
                  className={classes.draftBtn}
                  variant="contained"
                  onClick={() =>
                    !isEmpty(selectedCollectionForUpdate)
                      ? updateCollectionStatus('draft')
                      : postCollection('draft')
                  }
                  disabled={formValidationForCollection()}
                  color="secondary"
                >
                  Save as Draft
                </Button>

                <Button
                  className={classes.publishBtn}
                  variant="contained"
                  onClick={() =>
                    !isEmpty(selectedCollectionForUpdate)
                      ? updateCollectionStatus('published')
                      : postCollection('published')
                  }
                  disabled={formValidationForCollection()}
                  color="primary"
                >
                  Publish
                </Button>
              </Row>
            </LeftPanel>
            <RightPanel>
              <CollectionFiltersPanel
                collectionID={collectionID}
                payload={payload}
                deletedProduct={deletedProduct}
                updateSelectedProducts={updateSelectedProducts}
                selectedCollectionForUpdate={selectedCollectionForUpdate}
                updateSelectedTags={updateSelectedTags}
                isDynamicCollection={isDynamicCollection}
                deletedTag={deletedTag}
              />
            </RightPanel>
          </div>
        </div>
      </Container>
    </>
  );
}

export default withRouter(CreateCollection);
