import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import history from '../../../history';
import { getCheckoutDetails } from '../../../apis/cart';
import { successStatusCodes } from '../../../utils';
import { isEmpty } from 'lodash';
import Loader from '../../atoms/loader';
import PageMessage from '../../atoms/pageMessage';
import Currency from '../../molecules/Currency';
import {
  Wrapper,
  Container,
  Heading,
  SubHeading,
  OrdersContainer,
  OrderDetails,
  ProductsContainer,
  ProductDetails,
  ImageContainer,
  OtherDetails,
  ErrorLabel,
  WarningLabel,
  AmountContainer,
  AmountDetails
} from './styles';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';

const OrderConfirmation = () => {
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(false);

  useEffect(() => {
    sessionStorage.clear();
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
    fetchCheckoutDetails();
  }, []);

  const fetchCheckoutDetails = async () => {
    const checkoutId = getCheckoutId();

    if (checkoutId?.length > 0) {
      const res = await getCheckoutDetails(checkoutId);

      if (successStatusCodes.includes(res.status)) {
        setCheckoutDetails(res.data);
        setIsLoading(false);
      } else {
        setPageError(true);
        setIsLoading(false);
      }
    }
  };

  const getCheckoutId = () => {
    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    }).checkoutId;
  };

  return isLoading && !pageError ? (
    <Loader isFlex={true} w={'100%'} min_h={'100vh'} isCenter={true} />
  ) : !isLoading && pageError ? (
    <PageMessage
      type="danger"
      message="There was a problem while fetching the order details."
    />
  ) : (
    !isEmpty(checkoutDetails) && (
      <>
        <Wrapper>
          <Container>
            <>
              <Heading>Your order has been processed!</Heading>
              <SubHeading>
                We will send a notification mail to your email id&nbsp;
                <i className="fas fa-check-circle success" />
              </SubHeading>
              <SubHeading>
                Order Id:&nbsp;
                {checkoutDetails?.order_id}
              </SubHeading>
            </>
            <OrdersContainer>
              {!isEmpty(checkoutDetails?.order_details_per_vendor) &&
                Object.keys(checkoutDetails.order_details_per_vendor).map(
                  (vendorId, index) => (
                    <OrderDetails key={index}>
                      <div>
                        <div>
                          Order&nbsp;&#35;
                          {
                            checkoutDetails.order_details_per_vendor[vendorId]
                              .external_order_id
                          }
                        </div>
                        <div style={{ wordBreak: 'break-all' }}>
                          By&nbsp;
                          {
                            checkoutDetails.order_details_per_vendor[vendorId]
                              .cart_lines[0].vendor_name
                          }
                        </div>
                      </div>
                      <ProductsContainer>
                        {checkoutDetails.order_details_per_vendor[
                          vendorId
                        ].cart_lines.map((product, index) => (
                          <ProductDetails key={index}>
                            <ImageContainer>
                              <img src={product.image_src} alt={product.name} />
                            </ImageContainer>
                            <div>{product.name}</div>
                            <div>
                              x[{product.quantity}]&nbsp;
                              <Currency currencyName={product.price.currency} />
                              {product.price.amount}
                            </div>
                          </ProductDetails>
                        ))}
                      </ProductsContainer>
                    </OrderDetails>
                  )
                )}
              <AmountContainer>
                <div>
                  <AmountDetails>
                    <div>Subtotal:</div>
                    <div>
                      <Currency
                        currencyName={checkoutDetails.sub_total.currency}
                      />
                      {checkoutDetails.sub_total.amount}
                    </div>
                  </AmountDetails>
                  <AmountDetails>
                    <div>Taxes:</div>
                    <div>
                      <Currency currencyName={checkoutDetails.taxes.currency} />
                      {checkoutDetails.taxes.amount}
                    </div>
                  </AmountDetails>
                  <AmountDetails>
                    <div>Shipping:</div>
                    <div>
                      <Currency
                        currencyName={checkoutDetails.shipping.currency}
                      />
                      {checkoutDetails.shipping.amount}
                    </div>
                  </AmountDetails>
                  <AmountDetails>
                    <div>
                      <b>Total:</b>
                    </div>
                    <div>
                      <b>
                        <Currency
                          currencyName={checkoutDetails.total.currency}
                        />
                        {checkoutDetails.total.amount}
                      </b>
                    </div>
                  </AmountDetails>
                </div>
              </AmountContainer>
            </OrdersContainer>
            <OtherDetails>
              {checkoutDetails?.requires_shipping ? (
                <div>
                  Customer Information:
                  <br />
                  {checkoutDetails?.shipping_address?.name}&#44;
                  <br />
                  {checkoutDetails?.shipping_address?.street1}&#44;
                  <br />
                  {checkoutDetails?.shipping_address?.city}&nbsp;
                  {checkoutDetails?.shipping_address?.postalCode}&#44;
                  <br />
                  {checkoutDetails?.shipping_address?.state}&#44;&nbsp;
                  {checkoutDetails?.shipping_address?.country}
                </div>
              ) : (
                <div>
                  Customer Information (Billing Address):
                  <br />
                  {checkoutDetails?.billing_address?.name}&#44;
                  <br />
                  {checkoutDetails?.billing_address?.street1}&#44;
                  <br />
                  {checkoutDetails?.billing_address?.city}&nbsp;
                  {checkoutDetails?.billing_address?.postalCode}&#44;
                  <br />
                  {checkoutDetails?.billing_address?.state}&#44;&nbsp;
                  {checkoutDetails?.billing_address?.country}
                </div>
              )}

              <div>
                Payment Information:
                <br />
                {!isEmpty(checkoutDetails?.payment?.method_details)
                  ? `Paid online via ${checkoutDetails.payment.name}`
                  : 'NA'}
              </div>
            </OtherDetails>
            {checkoutDetails?.payment?.status === 'success' && (
              <SubHeading>
                Confirmation sent to:&nbsp;
                <strong>{checkoutDetails?.shipping_address?.email}</strong>
              </SubHeading>
            )}
          </Container>
        </Wrapper>
      </>
    )
  );
};

export default withRouter(OrderConfirmation);
