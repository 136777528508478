import styled from 'styled-components';
import { Secondary } from '../../atoms/button';
import TextInput from '../../atoms/textInputV1';

const Container = styled.div`
  display: block;
`;

const Heading = styled.div`
  font-size: 25px;
  font-weight: 700;
`;

const SubHeading = styled.div`
  font-size: 12px;
  margin-bottom: 20px;
`;

const VendorOnBoardContainer = styled.div`
  margin-top: 20px;
  border-bottom: unset;
`;

const VendorOnBoardForm = styled.div`
  > div {
    border-bottom: unset;
    input {
      width: 500px;
      height: 60px;
      border: solid 1px ${props => props.theme.PINK_SWAN};
      text-align: left;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const VendorName = styled(TextInput)`
  input {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    ::placeholder {
      color: ${props => props.theme.PINK_SWAN};
    }
  }
`;

const WebsiteUrl = styled(TextInput)`
  input {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
  }
`;

const Next = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const SampleURL = styled.span`
  font-size: ${props => props.theme.SMALL};
  opacity: 0.5;
`;

export {
  VendorOnBoardForm,
  Next,
  Container,
  Heading,
  SubHeading,
  VendorOnBoardContainer,
  VendorName,
  WebsiteUrl,
  SampleURL
};
