import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  getAuthToken,
  getUserProfile,
  getUserType,
  getApiResponseObject,
  successStatusCodes,
  postWithResponseObject,
  putWithResponseObject,
  showApiErrorToast
} from '../../../utils';
import Catalog from '../../organisms/Catalog';
import AddProduct from '../AddProducts';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import { SyncHoverInfo } from './syncHoverInfo';
import SearchBar from '../../atoms/searchBar';
import { VENDOR } from '../../../constants';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/uiActions';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom } from '@material-ui/core';

import {
  CatalogBar,
  Container,
  HeadingSection,
  Heading,
  SyncContainer,
  SyncInfo,
  SearchCount,
  InfoBar
} from './styles';
import { VENDOR_INFO } from '../../../constants/tooltip';

const styles = theme => ({
  searchProducts: {
    width: '100%',
    backgroundColor: '#fff'
  },
  syncButton: {
    width: '12vw',
    minWidth: '80px',
    backgroundColor: '#fff'
  },
  paginationBtn: {
    border: 'none',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none'
    }
  }
});

class ImportProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shopifyProducts: false,
      domain: '',
      apiKey: '',
      password: '',
      isLoading: true,
      disableSync: false,
      displaySyncInfo: false,
      SyncedData: {
        LastSynced: '',
        StoreName: '',
        SyncStatus: '',
        ProductCount: ''
      },
      userType: '',
      fetchProducts: [],
      // Below required for search and pagination implementation
      totalCount: null,
      totalResultsCount: 0,
      fetchParams: {
        count: 10, // set this for pagesize
        offset: 0,
        text: ''
      }
    };
  }

  componentDidMount() {
    this.fetchDashboardData(true);
  }

  setUserType = () => {
    const userType = getUserType();
    this.setState({ userType });
  };

  // required for search and pagination implementation
  updateFetchParams = fetchParams => {
    this.setState({ fetchParams, isLoading: true }, () => {
      this.fetchDashboardData();
    });
  };

  fetchDashboardData = async (updateTotalCount = false) => {
    const userProfile = getUserProfile() || {};
    const token = getAuthToken();
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();

    if (isEmpty(userProfile)) return toaster('User detail not available');
    const vendorId = userProfile.vendors[0].id;
    const { fetchParams } = this.state;
    const userType = getUserType();

    // Uniform elastic search API for VENDOR
    let URL = `${API_BASE_URL}/query-products?vendorId=${vendorId}`;
    if (userType === VENDOR) {
      URL = `${API_BASE_URL}/query-products?vendorId=${vendorId}&showHidden=true`;
    }

    const response = await getApiResponseObject(URL, headers, fetchParams);

    if (response.status !== 200) {
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
    }

    const results = response.data?.product;
    if (!Array.isArray(results) && results !== null) {
      this.props.hideSpinnerBackdrop();
      return toaster('Unable to fetch products');
    }

    this.setState(
      {
        fetchProducts: results,
        totalCount: updateTotalCount
          ? response.data.count
          : this.state.totalCount,
        totalResultsCount: response.data.count,
        isLoading: false
      },
      () => {
        this.setUserType();
      }
    );
    this.props.hideSpinnerBackdrop();
  };

  syncStoreData = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    this.setState({ disableSync: true });
    let response = await postWithResponseObject(
      `${API_BASE_URL}/command?type=syncProducts`,
      { override_on_sync: true },
      headers
    );
    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      toaster('Sync initiated. Please refresh page in sometime.');
    }
    this.setState({ disableSync: false });
  };

  handleToggleStatus = async (e, productId) => {
    const enabled = e.target.checked;
    const token = getAuthToken();

    this.props.showSpinnerBackdrop();
    const response = await putWithResponseObject(
      `${API_BASE_URL}/products/${productId}`,
      { status: enabled ? 'enabled' : 'disabled' },
      { authorization: token }
    );
    this.props.hideSpinnerBackdrop();

    if (!successStatusCodes.includes(response.status)) {
      toaster('Failed to change status');
    } else {
      this.fetchDashboardData(true);
      toaster(response?.data?.message || 'Status changed');
    }
  };

  getSyncedData = data => {
    this.setState({
      SyncedData: data,
      displaySyncInfo: true
    });
  };

  compare = (totalCount, totalResultsCount) => {
    if (totalCount !== totalResultsCount) {
      if (totalResultsCount === 1)
        return `About ${totalResultsCount} product found`;
      else return `About ${totalResultsCount} products found`;
    } else {
      return 'Showing all the products';
    }
  };

  render() {
    const { classes } = this.props;
    const {
      fetchProducts,
      isLoading,
      disableSync,
      displaySyncInfo,
      totalCount,
      totalResultsCount
    } = this.state;

    return (
      <>
        <Container>
          <HeadingSection>
            <Heading>Products</Heading>
            <Tooltip
              TransitionComponent={Zoom}
              title={VENDOR_INFO.pageDescription.Products}
              arrow
            >
              <InfoRoundedIcon />
            </Tooltip>
          </HeadingSection>
          {totalCount > 0 ? (
            <>
              <CatalogBar>
                <SearchBar
                  placeholder="Search.."
                  fetchParams={this.state.fetchParams}
                  updateFetchParams={this.updateFetchParams}
                  className={classes.searchProducts}
                />
                <SyncContainer>
                  <Button
                    className={classes.syncButton}
                    text="Sync"
                    size="large"
                    variant="outlined"
                    onClick={this.syncStoreData}
                    disabled={disableSync}
                    endIcon={
                      <InfoRoundedIcon
                        onMouseEnter={() => {
                          this.setState({ displaySyncInfo: true });
                        }}
                        onMouseLeave={() => {
                          this.setState({ displaySyncInfo: false });
                        }}
                        alt="sync-info"
                      />
                    }
                  >
                    {disableSync ? 'Syncing' : 'Sync'}
                  </Button>
                  {displaySyncInfo ? (
                    <SyncHoverInfo getSyncedData={this.getSyncedData} />
                  ) : null}
                </SyncContainer>
              </CatalogBar>
              <InfoBar>
                <SearchCount>
                  {this.compare(totalCount, totalResultsCount)}
                </SearchCount>
                <SyncInfo>{this.state.SyncedData.LastSynced}</SyncInfo>
              </InfoBar>
              <Catalog
                isLoading={isLoading}
                fetchProducts={fetchProducts}
                handleToggleStatus={this.handleToggleStatus}
                // for pagination :
                fetchParams={this.state.fetchParams}
                totalItemCount={this.state.totalResultsCount}
                updateFetchParams={this.updateFetchParams}
              />
            </>
          ) : (
            !isLoading && (
              <AddProduct
                fetchDashboardData={() => {
                  this.fetchDashboardData(true);
                }}
              />
            )
          )}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withStyles(styles)(
  withRouter(connect(null, mapDispatchToProps)(ImportProducts))
);
