import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import HorizontalSeparator from '../../../atoms/horizontalSeparator';
import { toaster } from '../../../atoms/toaster';
import { API_BASE_URL, APP_BASE_URL } from '../../../../config/env';
import * as actions from '../../../../redux/actions/uiActions';
import {
  getApiResponseObject,
  postWithResponseObject,
  successStatusCodes,
  checkBrowserStoredData,
  isUserNameValid,
  isEmailValid,
  isPhoneValid,
  isPasswordValid,
  PASSWORD_PATTERN,
  cookieEnabled,
  getBrowserStoredData,
  showApiErrorToast
} from '../../../../utils';
import {
  GOOGLEPROVIDER,
  FACEBOOKPROVIDER,
  COSELLER,
  DOMAIN,
  FACEBOOKAPPID,
  GOOGLECLIENTID
} from '../../../../constants';
import {
  Wrapper,
  Container,
  Heading,
  AuthContainer,
  AuthButton,
  AwakeMeButton,
  FormContainer,
  FormFields,
  FormFieldRow,
  TextInputField,
  FormActions,
  ActionButton,
  ActionLinkButton,
  ActionLink,
  Col
} from './styles';

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      otp: '',
      signUpStep: 1,
      buttonDisabled: false,
      data: {
        id: '',
        token: '',
        userType: '',
        userProfile: ''
      }
    };
  }

  componentDidMount() {
    if (cookieEnabled()) {
      this.ifAlreadyLoggedIn();
    }
  }

  ifAlreadyLoggedIn = () => {
    if (checkBrowserStoredData()) {
      this.sendDataToParent();
    }
  };

  inputChangeHandler = e => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value.trim();

    this.setState({ [fieldName]: fieldValue });
  };

  keyPressHandler = e => {
    const { signUpStep } = this.state;
    const error = this.inputsAreInvalid();

    if (!error && e.key === 'Enter') {
      signUpStep === 1 ? this.signUp() : this.verifyOtp();
    } else if (e.key === 'Enter') {
      toaster(error);
    }
  };

  clickAwakeMe = () => {
    window.location.href =
      'http://beta-awake.me.s3-website-us-east-1.amazonaws.com/?SSO=true&APP_ID=123456789';
  };

  handleGoogleResponse = response => {
    let gmailToken = response.tokenId;
    if (gmailToken) {
      this.socialSignIn(gmailToken, GOOGLEPROVIDER);
    } else if (response.error && response.error !== 'popup_closed_by_user') {
      return toaster('Error Connecting to Gmail');
    }
  };

  handleFacebookResponse = response => {
    let facebookToken = response.accessToken;
    if (facebookToken) {
      this.socialSignIn(facebookToken, FACEBOOKPROVIDER);
    } else {
      return toaster('Error Connecting to Facebook');
    }
  };

  socialSignIn = async (token, provider) => {
    const requestBody = {
      token: token,
      provider: provider,
      domain: DOMAIN
    };

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/social-login`,
      requestBody
    );

    if (cookieEnabled()) {
      localStorage.setItem('_id', JSON.stringify(response?.data?.data?._id));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          id: response?.data?.data?._id
        }
      }));
    }

    if (successStatusCodes.includes(response.status)) {
      this.userOnboard(response.data.data.token);
    } else {
      this.props.hideSpinnerBackdrop();
      this.setState({ buttonDisabled: false });
      showApiErrorToast(response.data);
    }
  };

  userOnboard = async token => {
    const headers = { authorization: token };
    const requestBody = {
      userType: COSELLER.toLowerCase()
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/authenticate`,
      requestBody,
      headers
    );

    if (cookieEnabled()) {
      localStorage.setItem('token', JSON.stringify(response?.data?.token));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          token: response?.data?.token
        }
      }));
    }

    if (response.data.is_onboarded === false) {
      this.quickSignUp(token);
    } else {
      const cosellerToken = response.data.token;

      if (cookieEnabled()) {
        localStorage.setItem('USER_TYPE', COSELLER);
      } else {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            userType: COSELLER
          }
        }));
      }

      this.updateUserProfileToLocalStorage(cosellerToken);
    }
  };

  handleSignUp = () => {
    const { signUpStep } = this.state;
    const inputValidationError = this.inputsAreInvalid();

    if (!inputValidationError) {
      signUpStep === 1 ? this.signUp() : this.verifyOtp();
    } else {
      return toaster(inputValidationError);
    }
  };

  inputsAreInvalid = () => {
    const { signUpStep, otp } = this.state;
    if (signUpStep === 2 && otp.length <= 0) {
      return 'Please enter the OTP';
    } else if (!isUserNameValid(this.state.firstName)) {
      return 'Please enter a valid first name';
    } else if (!isUserNameValid(this.state.lastName)) {
      return 'Please enter a valid last name';
    } else if (!isPhoneValid(this.state.phone)) {
      return 'Please enter a valid phone number';
    } else if (!isEmailValid(this.state.email)) {
      return 'Please enter a valid email id';
    } else if (this.state.password.length <= 0) {
      return 'Please enter password';
    } else if (!isPasswordValid(this.state.password)) {
      return 'Password must contain at least a lowercase letter, uppercase letter, numeric digit, special character & should be longer than 7 characters';
    }

    return null;
  };

  signUp = async () => {
    const { firstName, lastName, phone, email, password } = this.state;
    const requestBody = {
      name: firstName.trim() + ' ' + lastName.trim(),
      phone: phone,
      email: email,
      password: password
    };

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/register?verificationMethod=OTP`,
      requestBody
    );
    this.setState({ buttonDisabled: false });
    this.props.hideSpinnerBackdrop();

    if (successStatusCodes.includes(response.status)) {
      if (cookieEnabled()) {
        localStorage.setItem('_id', JSON.stringify(response?.data?.data?.id));
      }
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          id: response?.data?.data?.id
        }
      }));
      this.setState({ signUpStep: 2 });
    } else {
      showApiErrorToast(response.data);
    }
  };

  verifyOtp = async () => {
    const { otp, data } = this.state;
    const requestBody = {
      id: data.id,
      verificationCode: otp
    };

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/validate-email-otp`,
      requestBody
    );

    if (successStatusCodes.includes(response.status)) {
      this.quickSignUp(response.data.token);
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
    }
  };

  quickSignUp = async token => {
    const headers = { authorization: token };
    const reqBody = {};

    const response = await postWithResponseObject(
      `${API_BASE_URL}/cosellers`,
      reqBody,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      if (cookieEnabled()) {
        localStorage.setItem('token', JSON.stringify(response?.data?.token));
        localStorage.setItem('USER_TYPE', COSELLER);
      } else {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            token: response?.data?.token,
            userType: COSELLER
          }
        }));
      }

      this.updateUserProfileToLocalStorage(response.data.token);
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
    }
  };

  updateUserProfileToLocalStorage = async authToken => {
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: authToken
    });

    if (successStatusCodes.includes(profileRes.status)) {
      if (cookieEnabled()) {
        localStorage.setItem('userProfile', JSON.stringify(profileRes.data));
      } else {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            userProfile: profileRes.data
          }
        }));
      }

      this.sendDataToParent();
      this.resetPage();
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
      toaster('Error fetching user profile details');
    }
  };

  sendDataToParent = () => {
    let data = {};

    if (cookieEnabled()) {
      data = getBrowserStoredData();
    } else {
      data = this.state.data;
    }

    window.parent.postMessage(JSON.stringify(data), '*');
  };

  resetPage = () => {
    this.setState({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      otp: '',
      signUpStep: 1,
      buttonDisabled: false
    });
  };

  render() {
    const {
      firstName,
      lastName,
      phone,
      email,
      password,
      otp,
      buttonDisabled,
      signUpStep
    } = this.state;
    return (
      <Wrapper>
        <Container>
          <Heading>Sign Up</Heading>
          <AuthContainer>
            <GoogleLogin
              clientId={GOOGLECLIENTID}
              render={renderProps => (
                <AuthButton
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    width="56px"
                    src={require('../../../../assets/images/google.png')}
                    alt="google"
                  />
                </AuthButton>
              )}
              onSuccess={this.handleGoogleResponse}
              onFailure={this.handleGoogleResponse}
              cookiePolicy={'single_host_origin'}
            />
            <FacebookLogin
              appId={FACEBOOKAPPID}
              callback={this.handleFacebookResponse}
              render={renderProps => (
                <AuthButton onClick={renderProps.onClick}>
                  <img
                    width="56px"
                    src={require('../../../../assets/images/facebook.png')}
                    alt="google"
                  />
                </AuthButton>
              )}
            />
            <AwakeMeButton
              text="Awake.me"
              backgroundColor="white"
              textColor="black"
              borderColor="black"
              onClick={this.clickAwakeMe}
            />
          </AuthContainer>
          <HorizontalSeparator label="OR" />
          <FormContainer>
            <FormFields>
              {signUpStep === 1 && (
                <>
                  <FormFieldRow>
                    <TextInputField
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeHolder="First Name"
                      autocomplete="off"
                      value={firstName}
                      onChange={e => this.inputChangeHandler(e)}
                      onKeyPress={e => {
                        this.keyPressHandler(e);
                      }}
                    />
                    <TextInputField
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeHolder="Last Name"
                      autocomplete="off"
                      value={lastName}
                      onChange={e => this.inputChangeHandler(e)}
                      onKeyPress={e => {
                        this.keyPressHandler(e);
                      }}
                    />
                  </FormFieldRow>
                  <TextInputField
                    id="phone"
                    name="phone"
                    type="phone"
                    placeHolder="Phone Number"
                    autocomplete="off"
                    value={phone}
                    onChange={e => this.inputChangeHandler(e)}
                    onKeyPress={e => {
                      this.keyPressHandler(e);
                    }}
                  />
                  <TextInputField
                    id="email"
                    type="email"
                    name="email"
                    autocomplete="off"
                    placeHolder="Email"
                    value={email}
                    onChange={e => this.inputChangeHandler(e)}
                    onKeyPress={e => {
                      this.keyPressHandler(e);
                    }}
                  />
                  <TextInputField
                    id="password"
                    type="password"
                    name="password"
                    autocomplete="off"
                    placeHolder="Password"
                    pattern={PASSWORD_PATTERN}
                    value={password}
                    onChange={e => this.inputChangeHandler(e)}
                    onKeyPress={e => {
                      this.keyPressHandler(e);
                    }}
                  />
                </>
              )}
              {signUpStep === 2 && (
                <>
                  <TextInputField
                    id="otp"
                    type="password"
                    name="otp"
                    autocomplete="off"
                    placeHolder="OTP"
                    value={otp}
                    onChange={e => this.inputChangeHandler(e)}
                    onKeyPress={e => {
                      this.keyPressHandler(e);
                    }}
                  />
                </>
              )}
            </FormFields>
            <FormActions>
              <Col>
                <ActionButton
                  text={signUpStep === 1 ? 'Sign Up' : 'Submit OTP'}
                  borderColor="black"
                  backgroundColor="black"
                  textColor="white"
                  onClick={() => this.handleSignUp()}
                  disabled={buttonDisabled}
                />
                <span
                  style={{ display: signUpStep === 1 ? 'initial' : 'none' }}
                >
                  <ActionLinkButton>
                    <ActionLink href={APP_BASE_URL + '/signin'}>
                      Already have an account? Click here to Sign In
                    </ActionLink>
                  </ActionLinkButton>
                </span>
              </Col>
            </FormActions>
          </FormContainer>
        </Container>
      </Wrapper>
    );
  }
}

SignUpPage.propTypes = {
  showSpinnerBackdrop: func,
  hideSpinnerBackdrop: func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(SignUpPage));
