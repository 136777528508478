import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Row from './prebuilt/Row';
import HeadingRow from './prebuilt/HeadingRow';
import BillingDetailsFields from './prebuilt/BillingDetailsFields';
import SubmitButton from './prebuilt/SubmitButton';
import CheckoutError from './prebuilt/CheckoutError';
import withStripeHook from './withStripeHook';
import { CardElementContainer, cardElementOpts } from './styles';
import { isNil, isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import history from '../../../history';
import { toaster } from '../../atoms/toaster';
import cloneDeep from 'lodash/cloneDeep';
import { WOOCOMMERCE, BLOKPARTY } from '../../../constants';

const addressFields = [
  'city',
  'street1',
  'state',
  'postal_code',
  'country',
  'phone'
];

const Checkbox = styled.input`
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid ${props => props.theme.PINK_SWAN};
`;

const BillingAddressContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
  input[type='checkbox']:checked + label::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 6px;
    top: 4px;
    left: 2px;
    border: 3px solid ${props => props.theme.BUDDHA_GOLD};
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    @media (max-width: 768px) {
      left: 6px;
    }
  }

  input[type='checkbox'] {
    line-height: 1;
  }

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    left: -999em;
  }

  input[type='checkbox'] + label {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  input[type='checkbox'] + label::before {
    content: '';
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: white;
    border: 1px solid rgb(166, 166, 166);
    margin-right: 10px;
  }
`;

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      isSalesOrderError: false,
      salesOrderResponse: {},
      checkoutError: '',
      formFields: {
        shipping: { address: {} },
        billing: { address: {} }
      },
      isChecked: false
    };
  }

  componentDidMount() {
    this.setState({ formFields: cloneDeep(this.props.checkoutFormData) });
    if (
      (!isNil(this.props.checkoutFormData.billing.firstname) &&
        this.props.checkoutFormData.billing.firstname !== '') ||
      (!isNil(this.props.checkoutFormData.billing.email) &&
        this.props.checkoutFormData.billing.email !== '') ||
      !isEmpty(this.props.checkoutFormData.billing.address)
    ) {
      this.setState({ isChecked: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isProcessing !== this.props.isProcessing) {
      this.setState({
        isProcessing: this.props.isProcessing
      });
    }
    if (
      this.props.isProcessing === false &&
      !isNil(this.props.salesOrderResponse.order_number)
    ) {
      history.push('/confirm');
    }
    if (
      prevProps.isSalesOrderError !== this.props.isSalesOrderError &&
      this.props.isSalesOrderError !== false
    ) {
      this.procesSalesOrderError();
    }
    if (!isEqual(prevProps.checkoutFormData, this.props.checkoutFormData)) {
      this.setState({ formFields: cloneDeep(this.props.checkoutFormData) });
    }
  }

  handleChecked = () => {
    if (this.state.isChecked === true) {
      this.props.clearBillingFromCheckoutData();
    }
    this.setState({ isChecked: !this.state.isChecked });
  };

  procesSalesOrderError() {
    toaster('Something went wrong, Please try again');
    this.setState({ isProcessing: false });
  }

  handleCardDetailsChange = ev => {
    ev.error
      ? this.setState({ checkoutError: ev.error.message })
      : this.setState({ checkoutError: '' });
  };

  callCreatePaymentMethod = async () => {
    const { formFields } = this.state;
    const { shipping, billing } = this.state.formFields;
    const shippingName = isNil(shipping.firstname)
      ? isNil(shipping.lastname)
        ? ''
        : shipping.lastname
      : isNil(shipping.lastname)
      ? shipping.firstname
      : shipping.firstname + ' ' + shipping.lastname;

    const billingName = isNil(billing.firstname)
      ? isNil(billing.lastname)
        ? shippingName
        : billing.lastname
      : isNil(billing.lastname)
      ? billing.firstname
      : billing.firstname + ' ' + billing.lastname;
    let updatedBillingDetails = {
      name: billingName,
      email:
        isNil(billing.email) || billing.email === ''
          ? shipping.email
          : billing.email,
      address: {
        city:
          isNil(billing.address.city) || billing.address.city === ''
            ? shipping.address.city
            : billing.address.city,
        line1:
          isNil(billing.address.street1) || billing.address.street1 === ''
            ? shipping.address.street1
            : billing.address.street1,
        state:
          isNil(billing.address.state) || billing.address.state === ''
            ? shipping.address.state
            : billing.address.state,
        postal_code:
          isNil(billing.address.postal_code) ||
          billing.address.postal_code === ''
            ? shipping.address.postal_code
            : billing.address.postal_code
      }
    };

    const { productSourceName, elements, stripe } = this.props;
    const card = elements.getElement(CardElement);
    const payload = {
      type: 'card',
      card: card,
      billing_details: updatedBillingDetails
    };

    const result = await stripe.createPaymentMethod(payload);
    if (result.error) {
      toaster(result.error.message);
      this.setState({ isProcessing: false });
    } else {
      this.proceedToCreateSalesOrder(result.paymentMethod, formFields);
    }
  };

  proceedToCreateSalesOrder = async (paymentMethod, formFields) => {
    const { getFomattedPayload, createSalesOrder } = this.props;

    const payload = await getFomattedPayload(paymentMethod, formFields);

    createSalesOrder(payload);
  };

  createOrderViaShoptype = () => {
    history.push('/order-confirm');
  };

  handleBlur = (e, type) => {
    let { formFields } = this.state;
    if (addressFields.indexOf(e.target.name) !== -1) {
      formFields[type].address[e.target.name] = e.target.value;
    } else {
      formFields[type][e.target.name] = e.target.value;
    }
    this.props.setCheckoutFormFields(formFields);
  };

  handleSelectChange = (name, value, addressType) => {
    let { formFields } = this.state;
    formFields[addressType].address[name] = value;
    this.props.setCheckoutFormFields(formFields);
  };

  setAddress = (addressDetails, type) => {
    let { formFields } = this.state;
    Object.entries(addressDetails).forEach(detail => {
      formFields[type].address[detail[0]] = detail[1];
    });
    this.props.fetchStatesFromCountryCode(addressDetails.countryCode, type);
    this.props.setCheckoutFormFields(formFields);
  };

  handleFormSubmit = ev => {
    ev.preventDefault();
    const { productSourceName, cartData } = this.props;
    if (cartData.length > 0) {
      this.callCreatePaymentMethod();
    } else {
      toaster('Please add items in Cart');
    }
  };

  render() {
    const { checkoutFormData, fetchStatesFromCountryCode, states } = this.props;
    return (
      <form onSubmit={this.handleFormSubmit} autoComplete="new-password">
        <HeadingRow>SHIPPING & BILLING INFORMATION</HeadingRow>
        <div>
          <p>SHIPPING ADDRESS</p>
          <BillingDetailsFields
            handleBlur={this.handleBlur}
            handleSelectChange={this.handleSelectChange}
            setAddress={this.setAddress}
            type="shipping"
            checkoutFormData={checkoutFormData}
            fetchStatesFromCountryCode={fetchStatesFromCountryCode}
            states={states}
          />
        </div>
        <BillingAddressContainer>
          <Checkbox
            type="checkbox"
            name="address_checkbox"
            value="address"
            id="address_id"
            onChange={() => this.handleChecked()}
          />
          <label htmlFor="address_id">
            Check this box if Billing Address is not same as Shipping Address
          </label>
        </BillingAddressContainer>
        <div>
          {this.state.isChecked ? (
            <>
              <p>BILLING ADDRESS</p>
              <div>
                <BillingDetailsFields
                  type="billing"
                  checkoutFormData={checkoutFormData}
                  handleBlur={this.handleBlur}
                  handleSelectChange={this.handleSelectChange}
                  setAddress={this.setAddress}
                  fetchStatesFromCountryCode={fetchStatesFromCountryCode}
                  states={states}
                />
              </div>
            </>
          ) : null}
        </div>
        <HeadingRow>PAYMENT INFORMATION</HeadingRow>
        <Row>
          <CardElementContainer>
            <CardElement
              options={cardElementOpts}
              onChange={this.handleCardDetailsChange}
            />
          </CardElementContainer>
        </Row>
        {this.state.checkoutError && (
          <CheckoutError>{this.state.checkoutError}</CheckoutError>
        )}
        <Row>
          <SubmitButton
            disabled={this.state.isProcessing || !this.props.stripe}
          >
            {this.state.isProcessing ? 'Processing...' : 'Submit'}
          </SubmitButton>
        </Row>
      </form>
    );
  }
}

export default withStripeHook(CheckoutForm);
