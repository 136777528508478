import { API_BASE_URL } from '../config/env';
import {
  postWithResponseObject,
  getApiResponseObject,
  putWithResponseObject,
  deleteWithResponseObject,
  successStatusCodes,
  setCartId,
  getCartId,
  removeCartId,
  getAuthHeaders
} from '../utils';

export const createNewCart = async () => {
  const res = await postWithResponseObject(
    `${API_BASE_URL}/cart`,
    {},
    getAuthHeaders()
  );

  if (successStatusCodes.includes(res.status)) {
    setCartId(res?.data?.id);
    return res?.data?.id;
  } else {
    return undefined;
  }
};

export const addToCart = async reqBody => {
  const cartId = getCartId();

  const res = await postWithResponseObject(
    `${API_BASE_URL}/cart/${cartId}/add`,
    reqBody,
    getAuthHeaders()
  );

  return res;
};

export const getCart = async () => {
  const cartId = getCartId();

  const res = await getApiResponseObject(
    `${API_BASE_URL}/cart/${cartId}`,
    getAuthHeaders()
  );

  if (successStatusCodes.includes(res.status)) {
    return res?.data;
  } else {
    return res;
  }
};

export const updateCartItemQuantity = async reqBody => {
  const cartId = getCartId();

  const res = await putWithResponseObject(
    `${API_BASE_URL}/cart/${cartId}`,
    reqBody,
    getAuthHeaders()
  );

  if (successStatusCodes.includes(res.status)) {
    return res?.data;
  } else {
    return res;
  }
};

export const deleteCartItem = async reqBody => {
  const cartId = getCartId();

  const res = await putWithResponseObject(
    `${API_BASE_URL}/cart/${cartId}`,
    { ...reqBody, quantity: 0 },
    getAuthHeaders()
  );

  if (successStatusCodes.includes(res.status)) {
    return res?.data;
  } else {
    return res;
  }
};

export const deleteCart = async () => {
  const cartId = getCartId();

  const res = await deleteWithResponseObject(
    `${API_BASE_URL}/cart/${cartId}`,
    getAuthHeaders()
  );

  if (successStatusCodes.includes(res.status)) {
    removeCartId();
    return res?.data;
  } else {
    return res;
  }
};

export const getCheckoutDetails = async checkoutId => {
  const res = await getApiResponseObject(
    `${API_BASE_URL}/checkout/${checkoutId}`,
    getAuthHeaders()
  );

  if (res) {
    return res;
  } else {
    return undefined;
  }
};

export const updateCheckoutAddresses = async (checkoutId, reqBody) => {
  const res = await putWithResponseObject(
    `${API_BASE_URL}/checkout/${checkoutId}/address`,
    reqBody,
    getAuthHeaders()
  );

  if (res) {
    return res;
  } else {
    return undefined;
  }
};

export const updateShippingOption = async (checkoutId, reqBody) => {
  const res = await putWithResponseObject(
    `${API_BASE_URL}/checkout/${checkoutId}/shipping-method`,
    reqBody,
    getAuthHeaders()
  );

  if (res) {
    return res;
  } else {
    return undefined;
  }
};
