import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  TableFooter
} from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { CURRENCY_SYMBOL } from '../../../../constants';
import moment from 'moment';
import EmptyState from '../../../atoms/EmptyState';
import MaterialTablePagination from '../../../atoms/materialTablePagination';

const styles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableBody: {
    opacity: '1',
    transition: 'all .1s linear'
  },
  connectedColor: {
    color: '#43C009'
  },
  pendingColor: {
    color: '#EF1002'
  },
  zeroState: {
    textAlign: 'center',
    fontSize: '16px',
    marginTop: 10,
    height: '200px',
    paddingTop: '15px'
  },
  textAlign: {
    textAlign: 'center'
  },
  tableContainer: {
    borderBottom: 'none'
  }
});
function TransactionsTable(props) {
  const classes = styles();

  return (
    <>
      {props?.transactionsData?.length > 0 ? (
        <>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: 450, minHeight: 100 }}
          >
            <Table aria-label="collapsible table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Transaction Data</TableCell>
                  <TableCell>Transaction Id </TableCell>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Debit/Credit</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {props.transactionsData &&
                  props.transactionsData.map((ele, index) => {
                    return (
                      <TableRow key={index} className={classes.root}>
                        <TableCell align="left">
                          {moment(ele.created_at).format(
                            'DD[/]MMMM[/]YYYY h:mm A'
                          )}
                        </TableCell>
                        <TableCell align="left">{ele.transaction_id}</TableCell>
                        <TableCell align="left">
                          {ele.external_id ? ele.external_id : '-'}
                        </TableCell>
                        <TableCell align="left" style={{ width: '20%' }}>
                          {ele.ref}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={
                            ele.status === 'success'
                              ? classes.connectedColor
                              : classes.pendingColor
                          }
                        >
                          {ele.status === 'success' ? 'Successful' : 'On Hold'}
                        </TableCell>
                        <TableCell>
                          {ele.entry_type === 'D' ? 'Debit' : 'Credit'}
                        </TableCell>
                        <TableCell align="left">
                          {CURRENCY_SYMBOL[ele.asset_type_unit.toUpperCase()]}
                          {ele.amount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <MaterialTablePagination
                    colSpan={9}
                    count={props.transactionsTotalCount}
                    page={props.currentTransactionsPage}
                    handleChangePage={props.handleChangePage}
                    rowsPerPage={10}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <EmptyState errorMessage="No transactions available" height={'30vh'} />
      )}
    </>
  );
}

export default withRouter(TransactionsTable);
