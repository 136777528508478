import React from 'react';
import Currency from '../../../../molecules/Currency';
import { CosellerDataPoint, CosellerLevelAnalyticsContainer } from '../styles';
import PropTypes from 'prop-types';

const CosellerLevelAnalytics = props => {
  const { isLoading, dataPoints } = props;

  return (
    <CosellerLevelAnalyticsContainer>
      {!isLoading &&
        Object.keys(dataPoints).map((dataPoint, i) => (
          <CosellerDataPoint key={i} isDark={true}>
            <h4>{dataPoint}</h4>
            <div>
              {dataPoint === 'Total Earnings' ? (
                <Currency currencyName={dataPoints.currency} />
              ) : null}
              {dataPoints[dataPoint]}
            </div>
          </CosellerDataPoint>
        ))}
    </CosellerLevelAnalyticsContainer>
  );
};

CosellerLevelAnalytics.propTypes = {
  dataPoints: PropTypes.object,
  isLoading: PropTypes.bool
};

export default CosellerLevelAnalytics;
