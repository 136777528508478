import styled from 'styled-components';

const Row = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 30px auto;
  position: relative;
  ${props => props.theme.PHONE`
    max-width: 330px;
    width: 100%;
  `}
`;

export default Row;
