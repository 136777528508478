import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MaterialButton from '../../../atoms/materialButton';
import MaterialStepper from '../../../atoms/materialStepper';
import CountryCurrencies from '../../../../assets/icons/country-currencies.svg';
import { withStyles } from '@material-ui/core/styles';
import { API_BASE_URL } from '../../../../config/env';
import { FORM_ID } from '../../../../constants';
import { isEmpty } from 'lodash';
import update from 'immutability-helper';
import { MESSAGE_SEVERITY } from '../../../hoc/withSnackbar';
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Radio,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Checkbox,
  FormControlLabel,
  FormLabel,
  RadioGroup
} from '@material-ui/core';
import {
  getAuthToken,
  successStatusCodes,
  getApiResponseObject,
  postWithResponseObject,
  capitalize
} from '../../../../utils';
import {
  InputFieldContainer,
  CheckboxContainer,
  MainContentWrapper,
  CurrenciesImage,
  MainContentFlex,
  CurrencySelectContainer
} from './styles.js';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  formControl: {
    margin: theme.spacing(1),
    width: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    textTransform: 'none'
  },
  textField: {
    minWidth: 240,
    maxWidth: 380,
    width: '100%'
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: '#E6C100'
    }
  },
  checked: {}
})(Radio);

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#E6C100'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const STEPS = [
  { id: 1, name: 'Select a currency' },
  { id: 2, name: 'Select a payout method' },
  { id: 3, name: 'Fill account details' }
];

const FIELD_TYPE_TEXT = 'text';
const FIELD_TYPE_CHECKBOX = 'checkbox';

class ConfigurePayoutsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      activeStep: 0,
      currencies: [],
      payoutMethodsList: [],
      currency: { value: '', error: false, errorMessage: '' },
      payoutMethod: { value: '', error: false, errorMessage: '' },
      formFieldsList: [],
      formFields: {}
    };
  }

  componentDidMount() {
    this.fetchCurrencies();
  }

  fetchCurrencies = async () => {
    const response = await getApiResponseObject(`${API_BASE_URL}/currencies`);

    if (!successStatusCodes.includes(response.status)) {
      this.setState({
        currency: {
          ...this.state.currency,
          error: true,
          errorMessage: 'Could not fetch currencies or currencies unavailable'
        }
      });
    } else {
      this.setState({ currencies: response.data });
    }
  };

  validateForm = (fieldsArray = this.state.formFieldsList) => {
    let { activeStep, currency, formFields, payoutMethod } = this.state;

    if (activeStep === 0) {
      if (currency.value.length === 0) {
        currency.error = true;
        currency.errorMessage = 'Select a currency';
      } else {
        currency.error = false;
        currency.errorMessage = '';
      }

      this.setState({ currency });
    } else if (activeStep === 1) {
      if (payoutMethod.value.length === 0) {
        payoutMethod.error = true;
        payoutMethod.errorMessage = 'Select a payout method';
      } else {
        payoutMethod.error = false;
        payoutMethod.errorMessage = '';
      }

      this.setState({ payoutMethod });
    } else if (activeStep === 2) {
      fieldsArray.forEach(field => {
        if (formFields[field].visible) {
          if (formFields[field].type === FIELD_TYPE_TEXT) {
            formFields[field].error = formFields[field].value.trim().length < 1;
            formFields[field].errorMessage =
              formFields[field].value.trim().length < 1
                ? `${formFields[field].label} is required`
                : '';
          } else {
            formFields[field].error = false;
            formFields[field].errorMessage = '';
          }
        } else {
          formFields[field].error = false;
          formFields[field].errorMessage = '';
        }
      });

      this.setState({ formFields });
    }

    if (activeStep === 0) {
      return currency.error === false;
    } else if (activeStep === 1) {
      return payoutMethod.error === false;
    } else if (activeStep === 2) {
      return fieldsArray.every(field => formFields[field].error === false);
    }
  };

  fetchPayoutMethods = async (cb = null) => {
    const token = getAuthToken();
    const headers = {
      'Content-type': 'application/json',
      authorization: token
    };

    this.setState({
      loading: true
    });

    const response = await getApiResponseObject(
      `${API_BASE_URL}/payout-method?currency=${this.state.currency.value.toUpperCase()}`,
      headers
    );

    this.setState({
      loading: false
    });

    if (!successStatusCodes.includes(response.status)) {
      this.setState({
        currency: {
          ...this.state.currency,
          error: true,
          errorMessage:
            response?.data?.message ||
            'No payout method support available for the selected currency'
        }
      });
    } else {
      this.setState({ payoutMethodsList: response.data }, () => {
        if (cb) {
          cb();
        }
      });
    }
  };

  fetchPayoutsForm = async (cb = null) => {
    const { currency, payoutMethod } = this.state;
    const token = getAuthToken();
    const headers = {
      'Content-type': 'application/json',
      authorization: token
    };

    this.setState({
      loading: true
    });

    const response = await getApiResponseObject(
      `${API_BASE_URL}/forms/${FORM_ID.payouts}`,
      headers
    );

    this.setState({
      loading: false
    });

    if (!successStatusCodes.includes(response.status)) {
      this.setState({
        payoutMethod: {
          ...payoutMethod,
          error: true,
          errorMessage:
            response?.data?.message ||
            'Failed to get form fields for the selected payout method'
        }
      });
    } else {
      if (
        !isEmpty(response.data) &&
        response.data?.data[currency.value.toLowerCase()][payoutMethod.value]
      ) {
        try {
          const selectedCurrency = currency.value.toLowerCase();
          const selectedPaymentMethod = payoutMethod.value;
          const fields =
            response.data.data[selectedCurrency][selectedPaymentMethod].fields;
          const fieldVisibility =
            response.data.data[selectedCurrency][selectedPaymentMethod]
              .field_visibility;
          const formFieldsList = [];
          let formFields = {};

          Object.keys(fields).forEach(key => {
            formFieldsList[fields[key].order_id] = key;
          });

          formFieldsList.forEach(field => {
            const formFieldObj = {
              label: fields[field].label,
              type: fields[field].type,
              visible: fields[field].visible,
              error: false,
              errorMessage: ''
            };

            if (fields[field].type === FIELD_TYPE_TEXT) {
              formFieldObj.value = '';
            } else if (fields[field].type === FIELD_TYPE_CHECKBOX) {
              formFieldObj.checked = false;
            }

            if (fieldVisibility[field]) {
              formFieldObj.fieldVisibility = fieldVisibility[field];
            }

            formFields[field] = formFieldObj;
          });

          this.setState({ formFieldsList, formFields }, () => {
            if (cb) {
              cb();
            }
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        this.setState({
          payoutMethod: {
            ...this.state.payoutMethod,
            error: true,
            errorMessage:
              response?.data?.message ||
              'Form fields unavailable for the selected currency and payout method'
          }
        });
      }
    }
  };

  linkAccount = async () => {
    const { payoutMethod, currency, formFieldsList, formFields } = this.state;
    const token = getAuthToken();
    const headers = {
      'Content-type': 'application/json',
      authorization: token
    };
    const reqBody = {
      payout_method: payoutMethod.value,
      currency: currency.value.toUpperCase()
    };

    formFieldsList.forEach(field => {
      if (
        formFields[field].type === FIELD_TYPE_TEXT &&
        formFields[field].visible
      ) {
        reqBody[field] = formFields[field].value.trim();
      } else if (formFields[field].type === FIELD_TYPE_CHECKBOX) {
        reqBody[field] = formFields[field].checked;
      }
    });

    this.setState({
      loading: true
    });

    const response = await postWithResponseObject(
      `${API_BASE_URL}/payout-config`,
      reqBody,
      headers
    );

    this.setState({
      loading: false
    });

    if (!successStatusCodes.includes(response.status)) {
      this.props.showSnackbar({
        error: response.data,
        message: 'Failed to link account',
        severity: MESSAGE_SEVERITY.error
      });
    } else {
      this.props.showSnackbar({
        error: response.data,
        message: 'Successfully linked account',
        severity: MESSAGE_SEVERITY.success
      });
      this.closeDialog();
      this.props.fetchPayoutConfig();
    }
  };

  handleNext = () => {
    let valid = this.validateForm();

    if (valid) {
      if (this.state.activeStep === 0) {
        this.fetchPayoutMethods(this.incrementActiveStep);
      } else if (this.state.activeStep === 1) {
        this.fetchPayoutsForm(this.incrementActiveStep);
      } else if (this.state.activeStep === 2) {
        this.linkAccount();
      }
    }
  };

  incrementActiveStep = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    if (this.state.activeStep === 1) {
      this.setState({
        payoutMethod: { value: '', error: false, errorMessage: '' }
      });
    } else if (this.state.activeStep === 2) {
      this.setState({
        formFieldsList: [],
        formFields: {}
      });
    }

    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  closeDialog = () => {
    this.props.handleDialog('close');
    this.resetDialog();
  };

  changeCurrency = e => {
    this.setState(
      {
        currency: { ...this.state.currency, value: e.target.value }
      },
      () => {
        this.validateForm();
      }
    );
  };

  changePayoutMethod = e => {
    this.setState(
      {
        payoutMethod: { ...this.state.payoutMethod, value: e.target.value }
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleFieldVisibility = field => {
    let formFields = { ...this.state.formFields };

    if (formFields[field].type === FIELD_TYPE_CHECKBOX) {
      // checked state
      if (formFields[field].checked) {
        // making fields visible
        formFields[field].fieldVisibility.checked.visible.forEach(
          field => (formFields[field].visible = true)
        );
        // making fields hidden
        formFields[field].fieldVisibility.checked.hidden.forEach(field => {
          formFields[field].visible = false;
          this.resetFormField([field]);
        });
      }

      // unchecked state
      if (!formFields[field].checked) {
        // making fields visible
        formFields[field].fieldVisibility.unchecked.visible.forEach(
          field => (formFields[field].visible = true)
        );
        // making fields hidden
        formFields[field].fieldVisibility.unchecked.hidden.forEach(field => {
          formFields[field].visible = false;
          this.resetFormField([field]);
        });
      }
    }

    this.setState({ formFields }, () => {});
  };

  resetFormField = fieldList => {
    const formFields = { ...this.state.formFields };

    fieldList.forEach(field => {
      if (formFields[field].type === FIELD_TYPE_TEXT) {
        formFields[field].value = '';
        formFields[field].error = false;
        formFields[field].errorMessage = '';
      }
    });

    this.setState({ formFields });
  };

  handleFormDataChange = e => {
    const { formFields } = this.state;
    try {
      const name = e.target.name;
      const type = e.target.type;
      let value = '';
      // field name whose value will be changed
      let field = '';

      if (type === FIELD_TYPE_TEXT) {
        value = e.target.value;
        field = 'value';
      } else if (type === FIELD_TYPE_CHECKBOX) {
        value = e.target.checked;
        field = 'checked';
      }

      let newFormFields = update(this.state.formFields, {
        [name]: {
          [field]: { $set: value }
        }
      });

      this.setState({ formFields: newFormFields }, () => {
        if (type === FIELD_TYPE_TEXT) {
          this.validateForm([name]);
        }

        if (type === FIELD_TYPE_CHECKBOX && formFields[name].fieldVisibility) {
          this.handleFieldVisibility(name);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  resetDialog = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
        activeStep: 0,
        payoutMethodsList: [],
        currency: { value: '', error: false, errorMessage: '' },
        formFieldsList: [],
        formFields: {}
      });
    }, 500);
  };

  render() {
    const {
      loading,
      activeStep,
      currencies,
      payoutMethodsList,
      currency,
      payoutMethod,
      formFieldsList,
      formFields
    } = this.state;
    const { classes, open } = this.props;

    return (
      <Dialog
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            this.closeDialog();
          }
        }}
        open={open}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle onClose={this.closeDialog}>Configure Payouts</DialogTitle>
        <DialogContent dividers>
          <MaterialStepper steps={STEPS} activeStep={activeStep} />
          <MainContentWrapper>
            {activeStep === 0 && (
              <MainContentFlex>
                <CurrenciesImage
                  src={CountryCurrencies}
                  alt="country currencies"
                />
                <CurrencySelectContainer>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    error={currency.error}
                  >
                    <InputLabel>Currency</InputLabel>
                    <Select
                      value={currency.value}
                      onChange={this.changeCurrency}
                      label="Currency"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {currencies &&
                        currencies?.map((key, index) => (
                          <MenuItem key={index} value={key}>
                            {key.toUpperCase()}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormHelperText error={currency.error}>
                    {currency.errorMessage || ' '}
                  </FormHelperText>
                </CurrencySelectContainer>
              </MainContentFlex>
            )}
            {activeStep === 1 && (
              <MainContentFlex>
                <FormControl component="fieldset" error={payoutMethod.error}>
                  <FormLabel component="legend">Payout Methods</FormLabel>
                  <RadioGroup
                    name="payout-methods"
                    value={payoutMethod.value}
                    onChange={this.changePayoutMethod}
                  >
                    {payoutMethodsList.map((method, index) => (
                      <FormControlLabel
                        key={index}
                        value={method}
                        control={<CustomRadio />}
                        label={capitalize(method)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormHelperText error={payoutMethod.error}>
                  {payoutMethod.errorMessage || ' '}
                </FormHelperText>
              </MainContentFlex>
            )}
            {activeStep === 2 && (
              <MainContentFlex>
                {formFieldsList.map(field =>
                  formFields[field].visible ? (
                    formFields[field].type === 'text' ? (
                      <InputFieldContainer key={field}>
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          size="small"
                          name={field}
                          label={formFields[field].label}
                          value={formFields[field].value}
                          onChange={this.handleFormDataChange}
                          error={formFields[field].error}
                          helperText={formFields[field].errorMessage || ' '}
                        />
                      </InputFieldContainer>
                    ) : formFields[field].type === 'checkbox' ? (
                      <CheckboxContainer key={field}>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              checked={formFields[field].checked}
                              onChange={this.handleFormDataChange}
                              name={field}
                            />
                          }
                          label={formFields[field].label}
                        />
                      </CheckboxContainer>
                    ) : null
                  ) : null
                )}
              </MainContentFlex>
            )}
          </MainContentWrapper>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            disabled={activeStep === 0}
            onClick={this.handleBack}
          >
            Back
          </Button>
          <MaterialButton
            loading={loading}
            label={activeStep === STEPS.length - 1 ? 'Link' : 'Next'}
            disabled={loading}
            onClick={this.handleNext}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ConfigurePayoutsDialog);
