/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Shopify from '../../../../assets/images/shopify.svg';
import WooCommerce from '../../../../assets/images/woocommerce.svg';
import {
  deleteWithResponseObject,
  getAuthToken,
  isUrlValid,
  putWithResponseObject,
  successStatusCodes,
  toBoolean
} from '../../../../utils';
import { toaster } from '../../../atoms/toaster';
import { API_BASE_URL } from '../../../../config/env';
import { isEmpty } from 'lodash';
import shoptypeThemes from '../../../../theme';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../redux/actions/uiActions';

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: shoptypeThemes.default.RONCHI
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: 0,
    padding: 0
  },
  linkedStore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  paper: {
    flexGrow: 1,
    maxWidth: 550,
    boxShadow: 'none'
  },
  saveButton: {
    width: '110px',
    marginTop: '50px',
    height: '50px'
  },
  viewButton: {
    width: '120px',
    marginLeft: '10px',
    height: '30px'
  },
  storeButton: {
    margin: '5px'
  },
  heading: {
    fontSize: '18px',
    marginBottom: '10px'
  },
  submit: {
    margin: theme.spacing(3, 0, 0, 0),
    height: '45px',
    width: '100px'
  }
}));

const StoreInfo = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { storeInfo } = props;

  const [storeData, setStoreData] = useState({
    store: {
      host_url: '',
      id: '',
      settings: {
        description: '',
        shipping_delivery_policy: '',
        returns_refunds_policy: '',
        privacy_policy: ''
      },
      source: ''
    },
    vendor: {
      returns_days_limit: '',
      logo: '',
      name: '',
      store: {
        addressLine1: '',
        city: '',
        postalCode: '',
        countryState: '',
        currency: ''
      }
    }
  });

  const { store, vendor } = storeData;
  const { settings } = storeData.store;

  const [restrictions, setRestrictions] = useState({
    isAdult: false,
    isAgeRestricted: false
  });
  const [storeUpdate, setStoreUpdate] = useState(true);

  useEffect(() => {
    fetchStoreInfo();
  }, []);

  const fetchStoreInfo = () => {
    if (isEmpty(storeInfo.store?.settings)) {
      storeInfo.store.settings = {
        description: '',
        shipping_delivery_policy: '',
        returns_refunds_policy: '',
        privacy_policy: ''
      };
    }
    setStoreData({
      store: storeInfo.store,
      vendor: storeInfo?.vendor
    });

    // Converting the JSON string values into boolean ('false' => false)
    const getRestrictions = storeInfo.vendor?.restrictions;
    if (!isEmpty(getRestrictions)) {
      const ageRestricted = toBoolean(getRestrictions?.isAgeRestricted);
      const adult = toBoolean(getRestrictions?.isAdult);
      setRestrictions({
        isAdult: adult,
        isAgeRestricted: ageRestricted
      });
    }
  };

  const handleVendorChange = (e, type) => {
    const { name, value } = e.target;

    switch (type) {
      case 'store':
        store[name] = value;
        break;
      case 'settings':
        settings[name] = value;
        break;
      case 'vendor':
        vendor[name] = value;
        break;
      case 'vendorStore':
        vendor.store[name] = value;
        break;
      default:
        break;
    }

    setStoreData(prevState => {
      return {
        ...prevState,
        storeData: storeData
      };
    });
  };

  const handleSave = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    // Stringify the boolean values
    const isAgeRestricted = restrictions.isAgeRestricted.toString();
    const isAdult = restrictions.isAdult.toString();

    const newRestrictions = {
      isAdult: isAdult,
      isAgeRestricted: isAgeRestricted
    };

    const returnsPeriod = parseInt(vendor?.returns_days_limit);
    vendor.returns_days_limit = returnsPeriod;
    vendor.restrictions = newRestrictions;

    let response;
    const hostURL = isUrlValid(store.host_url);

    if (hostURL) {
      if (storeUpdate) {
        const reqBody = {
          store: store,
          vendor: vendor
        };

        response = await putWithResponseObject(
          `${API_BASE_URL}/vendors?updateStore=true`,
          reqBody,
          headers
        );
      } else {
        const reqBody = {
          vendor: vendor
        };

        response = await putWithResponseObject(
          `${API_BASE_URL}/vendors?updateStore=false`,
          reqBody,
          headers
        );
      }

      setStoreUpdate(false);

      if (!successStatusCodes.includes(response.status)) {
        toaster('Failed to update Store details');
      } else {
        toaster('Store details updated successfully');
      }
    } else {
      toaster('Please enter valid Store URL');
      setStoreUpdate(false);
    }
  };

  const deLink = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    let { id } = storeData.store;

    dispatch(actions.showSpinnerBackdropAction());

    const response = await deleteWithResponseObject(
      `${API_BASE_URL}/store/${id}`,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      dispatch(actions.hideSpinnerBackdropAction());
      toaster('Store de-linked successfully');
      window.location.reload();
    } else {
      dispatch(actions.hideSpinnerBackdropAction());
      toaster('Failed to de-link store');
    }
  };

  const handleCheckbox = event => {
    setRestrictions({
      ...restrictions,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <Container component="main" maxWidth="sm" className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.linkedStore}>
              <Typography className={classes.typography}>
                Linked Store :
              </Typography>
              <Box
                component="img"
                sx={{
                  width: '88px',
                  height: '140px',
                  padding: '10px',
                  objectFit: 'contain'
                }}
                alt={
                  storeData.store.source === 'shopify'
                    ? 'Shopify-store-logo'
                    : 'WooCommerce-store-logo'
                }
                src={
                  storeData.store.source === 'shopify' ? Shopify : WooCommerce
                }
                flex={2}
              />

              <Box display="flex" flexDirection="column" flex={1}>
                <Button variant="outlined" className={classes.storeButton}>
                  View
                </Button>
                <Button
                  className={classes.storeButton}
                  variant="outlined"
                  onClick={deLink}
                >
                  Delink
                </Button>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Complete Address"
              variant="outlined"
              fullWidth
              value={vendor?.store?.addressLine1 || ''}
              name="addressLine1"
              multiline
              rows={3}
              onChange={e => handleVendorChange(e, 'vendorStore')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="City/Town"
              variant="outlined"
              fullWidth
              value={vendor?.store?.city || ''}
              name="city"
              onChange={e => handleVendorChange(e, 'vendorStore')}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Zip"
              variant="outlined"
              fullWidth
              value={vendor?.store?.postalCode || ''}
              name="postalCode"
              onChange={e => handleVendorChange(e, 'vendorStore')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Country/State"
              variant="outlined"
              fullWidth
              value={vendor?.store?.countryState || ''}
              name="countryState"
              onChange={e => handleVendorChange(e, 'vendorStore')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="Store Url"
              variant="outlined"
              fullWidth
              value={store?.host_url || ''}
              name="host_url"
              onChange={e => handleVendorChange(e, 'store')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="Vendor Name"
              variant="outlined"
              fullWidth
              value={vendor?.name || ''}
              name="name"
              onChange={e => handleVendorChange(e, 'vendor')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              fullWidth
              value={store?.settings?.description || ''}
              name="description"
              multiline
              rows={6}
              variant="outlined"
              onChange={e => handleVendorChange(e, 'settings')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Shipping & Delivery Policy"
              fullWidth
              value={store?.settings?.shipping_delivery_policy || ''}
              name="shipping_delivery_policy"
              multiline
              rows={6}
              variant="outlined"
              onChange={e => handleVendorChange(e, 'settings')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Returns & Refunds Policy"
              fullWidth
              value={store?.settings?.returns_refunds_policy || ''}
              name="returns_refunds_policy"
              multiline
              rows={6}
              variant="outlined"
              onChange={e => handleVendorChange(e, 'settings')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="No. of days valid for placing return requests"
              fullWidth
              variant="outlined"
              value={vendor.returns_days_limit}
              name="returns_days_limit"
              type="number"
              onChange={e => handleVendorChange(e, 'vendor')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="Store currency (Picked up from Shopify/Woocommerce)"
              fullWidth
              variant="outlined"
              name="currency"
              value={vendor?.store?.currency || ''}
              onChange={e => handleVendorChange(e, 'vendorStore')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Privacy Policy"
              fullWidth
              value={store?.settings?.privacy_policy || ''}
              name="privacy_policy"
              multiline
              rows={6}
              variant="outlined"
              onChange={e => handleVendorChange(e, 'settings')}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className={classes.heading}>Preferences</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    name="isAdult"
                    checked={restrictions.isAdult}
                    onChange={handleCheckbox}
                  />
                }
                label="Show Adult Content"
              />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    name="isAgeRestricted"
                    checked={restrictions.isAgeRestricted}
                    onChange={handleCheckbox}
                  />
                }
                label="Show Age-restricted Content"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              className={classes.submit}
              type="submit"
              variant="outlined"
              size="large"
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default StoreInfo;
