import styled, { css } from 'styled-components';
import TextInput from '../../atoms/textInputV1';
import Select from 'react-select';

const VendorOnBoardForm = styled.div`
  max-height: 200px;
  overflow: auto;
  > div {
    border-bottom: unset;
    input {
      padding: 20px 35px 20px 20px;
      outline: none;
      width: 500px;
      height: 60px;
      border: solid 1px ${props => props.theme.PINK_SWAN};
      text-align: left;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const Svgfix = styled.div`
  height: 100% !important;
  width: 100%;
  cursor: pointer;
`;

const SocialMediaHandles = styled.div`
  margin-bottom: 2.5%;
  width: 500px;
  display: flex;
  flex-direction: row;
`;

const SocialMediaIcon = styled.div`
  width: 10%;
`;

const SocialMediaUrl = styled.input`
  border: none;
  margin-left: 10px;
  appearance: none;
  background: #f2f2f2;
  border-radius: 3px;
  padding: 12px;
  outline: none;
  max-width: 310px !important;
`;

const SocialAccount = styled.div`
  display: flex;
  max-width: 603px;
  :last-child {
    border-bottom: 1px solid ${props => props.theme.PINK_SWAN};
  }
  > div {
    border-bottom: unset;
    > div {
      max-height: 60px;
      border-radius: 0;
      border-bottom: 0;
      width: 90px;
      align-content: center;
      > div {
        padding: unset;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 0 20px 0;
        > div {
          display: flex;
          justify-content: center;
          border: none;
          color: transparent;
          text-shadow: 0 0 0 gray;
          text-align: center;
          :focus {
            outline: none;
          }
        }
        > span {
          width: 0;
          background-color: unset;
        }
      }
    }
    input {
      width: 400px;
      height: 60px;
      border: solid 1px;
      border-left: ${props => (props.error ? '1' : '0')}
      border-bottom: ${props => (props.error ? '1' : '0')}
      text-align: left;
      ::placeholder {
        text-align: left;
      }
      border-color:${props =>
        props.error ? props.theme.RED : props.theme.PINK_SWAN}
    }
  }
`;

const Label = styled.div`
  display: flex;
`;

const SelectDropDown = styled(Select)``;

const AddMoreLinks = styled.button`
  cursor: pointer;
  background-color: ${props => props.theme.WHITE};
  width: 490px;
  outline: none;
  height: 60px;
  border-radius: 5px;
  border: dashed 1px ${props => props.theme.CORDUROY};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: ${props => props.theme.SMALL};

  ${props =>
    props.isDisabled &&
    css`
      background-color: ${props => props.theme.PINK_SWAN};
    `}
`;

const AddIcon = styled.div`
  margin-right: 15px;
`;

const CloseIcon = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.WHITE};
  color: ${props => props.theme.WHITE};
  cursor: pointer;
  top: 20px;
  left: -30px;
`;

export {
  VendorOnBoardForm,
  SocialAccount,
  Label,
  SelectDropDown,
  AddMoreLinks,
  AddIcon,
  CloseIcon,
  Svgfix,
  SocialMediaHandles,
  SocialMediaIcon,
  SocialMediaUrl
};
