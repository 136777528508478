import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import { CURRENCY_SYMBOL } from '../../../../constants';
import { toaster } from '../../../../components/atoms/toaster';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Fade from '@material-ui/core/Fade';
import shoptypeThemes from '../../../../theme/index';

const MuiSlider = styled(Slider)({
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: 'translateX(0%)'
  },
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: 'translateX(-100%)'
  },
  '& .MuiSlider-thumb': {
    color: shoptypeThemes.default.RONCHI
  },
  '& .MuiSlider-rail': {
    color: shoptypeThemes.default.RONCHI
  },
  '& .MuiSlider-track': {
    color: shoptypeThemes.default.RONCHI
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-root': {
      height: '40px'
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  popoverContainer: {
    padding: '15px',
    width: '300px'
  },
  inputsContainer: {
    marginTop: '15px',
    display: 'flex'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '15px',
    alignItems: 'center'
  },
  rangeInput: {
    width: '130px'
  },
  mL10: {
    marginLeft: '10px'
  },
  setBtn: {
    background: '#ffffff',
    border: '1px solid #F0D64E'
  },
  clearBtn: {
    background: '#ffed93',
    border: 'none',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: shoptypeThemes.default.RONCHI
    }
  }
}));

const PriceRangeFilter = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [priceRangeToSelect, setPriceRangeToSelect] = React.useState([0, 1000]);
  const [priceRangeInputs, setPriceRangeInputs] = React.useState([0, 1000]);
  const [priceRangeAvailable, setpriceRangeAvailable] = React.useState([
    0,
    1000
  ]);
  const [timeoutHandle, setTimeoutHandle] = React.useState(null);

  const handleClick = event => {
    //set init values
    setPriceRangeToSelect(props.priceRangeSelected || [0, 1000]);
    setPriceRangeInputs(props.priceRangeSelected || [0, 1000]);
    setpriceRangeAvailable(props.priceRangeAvailable || [0, 1000]);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    props.priceRangeUpdateCallback(priceRangeToSelect);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'price-range-popover' : undefined;

  const validateAndSetInput = input => {
    if (timeoutHandle !== null) {
      clearTimeout(timeoutHandle); // clear the previous one
      setTimeoutHandle(null);
    }

    const prevValues = priceRangeToSelect;
    setPriceRangeInputs(input);
    let newHandle = setTimeout(() => {
      if (
        input[0] < priceRangeAvailable[0] ||
        input[0] > priceRangeAvailable[1] ||
        input[1] > priceRangeAvailable[1] ||
        input[1] < priceRangeAvailable[0] ||
        Number(input[1]) < Number(input[0]) ||
        isNaN(input[0]) ||
        isNaN(input[1])
      ) {
        toaster('Please enter valid values');
        setPriceRangeInputs(prevValues);
      } else {
        setPriceRangeInputs(input);
        setPriceRangeToSelect(input);
      }
    }, 800);

    setTimeoutHandle(newHandle);
  };

  const updateInputs = inputs => {
    setPriceRangeToSelect(inputs);
    setPriceRangeInputs(inputs);
  };

  const clearFilter = () => {
    props.priceRangeUpdateCallback(priceRangeAvailable);
    handleClose();
  };

  return (
    <div>
      <TextField
        className={classes.root}
        id="price-filter-text"
        label="Price Range"
        variant="outlined"
        value={
          CURRENCY_SYMBOL[props.selectedCurrency] +
          props.priceRangeSelected[0] +
          ' - ' +
          CURRENCY_SYMBOL[props.selectedCurrency] +
          props.priceRangeSelected[1]
        }
        onClick={handleClick}
      />
      {priceRangeAvailable ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          TransitionComponent={Fade}
        >
          <div className={classes.popoverContainer}>
            <span id="price-filter-slider" gutterBottom>
              Set Price Range
            </span>
            <MuiSlider
              style={{ width: '95%', margin: '8px' }}
              value={priceRangeToSelect}
              valueLabelDisplay="auto"
              aria-labelledby="price-filter-slider"
              min={priceRangeAvailable[0]}
              max={priceRangeAvailable[1]}
              ValueLabelComponent={p => {
                const { children, open, value } = p;
                return (
                  <Tooltip
                    open={open}
                    enterTouchDelay={0}
                    placement="top"
                    title={value}
                  >
                    {children}
                  </Tooltip>
                );
              }}
              onChange={(e, newValues) => updateInputs(newValues)}
              marks={[
                {
                  value: priceRangeAvailable[0],
                  label:
                    CURRENCY_SYMBOL[props.selectedCurrency] +
                    priceRangeAvailable[0]
                },
                {
                  value: priceRangeAvailable[1],
                  label:
                    CURRENCY_SYMBOL[props.selectedCurrency] +
                    priceRangeAvailable[1]
                }
              ]}
            />
            <div className={classes.inputsContainer}>
              <TextField
                id="price-filter-min"
                label="Minimum"
                size="small"
                variant="outlined"
                value={priceRangeInputs[0]}
                onChange={e =>
                  validateAndSetInput([e.target.value, priceRangeToSelect[1]])
                }
              />
              <TextField
                id="price-filter-max"
                className={classes.mL10}
                label="Maximum"
                size="small"
                variant="outlined"
                value={priceRangeInputs[1]}
                onChange={e =>
                  validateAndSetInput([priceRangeToSelect[0], e.target.value])
                }
              />
            </div>
            <div className={classes.buttonsContainer}>
              <Button
                className={`${classes.setBtn}`}
                onClick={handleSubmit}
                variant="outlined"
              >
                Set
              </Button>
              <Button
                className={`${classes.mL10} ${classes.clearBtn}`}
                aria-describedby={id}
                variant="outlined"
                onClick={clearFilter}
              >
                Clear
              </Button>
            </div>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export default PriceRangeFilter;
