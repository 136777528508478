import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';
import shoptypeThemes from '../../theme';

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    height: 24,
    position: 'relative',
    zIndex: 0,
    border: '1px solid',
    minWidth: '20px',
    '& .MuiChip-label': {
      color: '#000'
    },
    '& .MuiChip-deleteIcon': {
      color: 'rgba(0, 0, 0, 0.7) '
    },
    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      zIndex: -1
    }
  },
  option: {
    textTransform: 'capitalize'
  }
})(Autocomplete);

function AutoCompleteCustomChips(props) {
  return (
    <div>
      <CustomAutocomplete
        multiple
        defaultValue={props.selectedTags}
        id="tags-standard"
        limitTags={2}
        options={props.options}
        onChange={(event, value) => props.handleChange(value)}
        onInputChange={(event, value, reason) => {
          if (reason === 'input') {
            props.changeOptionBaseOnValue(value);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            className={props.className}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}

export default AutoCompleteCustomChips;
