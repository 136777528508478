import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink, Button } from './styles';

const BaseButton = ({
  text,
  onClick,
  size,
  variant = 'primary',
  disabled = false,
  backgroundColor,
  textColor,
  borderColor,
  ...rest
}) => (
  <Button
    data-testid="button"
    variant={variant}
    size={size}
    className={rest.className}
    onClick={onClick}
    disabled={disabled}
    backgroundColor={backgroundColor}
    textColor={textColor}
    borderColor={borderColor}
    {...rest}
  >
    {text}
  </Button>
);

BaseButton.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool
};

const Primary = ({
  text,
  onClick,
  size = 'medium',
  disabled = false,
  borderColor,
  ...rest
}) => {
  return (
    <BaseButton
      text={text}
      onClick={onClick}
      variant="primary"
      size={size}
      disabled={disabled}
      borderColor={borderColor}
      {...rest}
    />
  );
};

Primary.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  borderColor: PropTypes.string,
  disabled: PropTypes.bool
};

const Secondary = ({
  text,
  onClick,
  size = 'medium',
  borderColor,
  ...rest
}) => (
  <BaseButton
    text={text}
    onClick={onClick}
    variant="secondary"
    size={size}
    borderColor={borderColor}
    {...rest}
  />
);

Secondary.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  borderColor: PropTypes.string
};

const Embed = ({ text, onClick, size = 'medium' }) => (
  <BaseButton text={text} onClick={onClick} variant="secondary" size={size} />
);

Embed.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string
};

const Ternary = ({ text, onClick, size = 'semi-large', ...rest }) => (
  <BaseButton
    text={text}
    onClick={onClick}
    variant="ternary"
    size={size}
    {...rest}
  />
);

Ternary.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string
};

const Dashed = ({
  text,
  onClick,
  size = 'semi-large',
  color,
  backgroundColor,
  ...rest
}) => (
  <BaseButton
    text={text}
    onClick={onClick}
    variant="dashed"
    size={size}
    color={color}
    backgroundColor={backgroundColor}
    {...rest}
  />
);

Dashed.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string
};

const Filled = ({
  text,
  onClick,
  size = 'medium',
  backgroundColor,
  textColor,
  ...rest
}) => (
  <BaseButton
    text={text}
    onClick={onClick}
    variant="filled"
    size={size}
    backgroundColor={backgroundColor}
    textColor={textColor}
    {...rest}
  />
);

Filled.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string
};

const TransparentButton = ({ onClick, size = 'medium', text, ...rest }) => (
  <BaseButton
    variant="transparent"
    onClick={onClick}
    text={text}
    size={size}
    {...rest}
  />
);

TransparentButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string
};

const CloseButton = ({
  text,
  onClick,
  size = 'medium',
  backgroundColor,
  textColor,
  borderColor,
  ...rest
}) => (
  <BaseButton
    text={text}
    onClick={onClick}
    variant="close"
    size={size}
    backgroundColor={backgroundColor}
    textColor={textColor}
    borderColor={borderColor}
    {...rest}
  />
);

CloseButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string
};

const ProceedButton = props => {
  return (
    <StyledLink {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 50">
        <defs>
          <path
            id="a"
            d="M25 0c13.807 0 25 11.193 25 25S38.807 50 25 50 0 38.807 0 25 11.193 0 25 0z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#BE3144" xlinkHref="#a" />
          <g stroke="#FFF" strokeLinecap="round" strokeWidth="2" mask="url(#b)">
            <path d="M15 24.897h20" />
            <path
              strokeLinejoin="round"
              d="M31.394 20.757L35.637 25l-4.243 4.243"
            />
          </g>
        </g>
      </svg>
    </StyledLink>
  );
};

export {
  BaseButton,
  Primary,
  Secondary,
  Ternary,
  Filled,
  ProceedButton,
  CloseButton,
  TransparentButton,
  Dashed,
  Embed
};
