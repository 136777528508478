import styled from 'styled-components';

const Container = styled.div`
  max-width: 655px;
  width: 100%;
  max-height: 210px;
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.WHITE};
  ${props => props.theme.TABLET`
    padding: 20px;
  `}
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};
  margin-bottom: 20px;
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.XX_LARGE};
  `}
`;

const SubHeading = styled.div`
  font-size: ${props => props.theme.X_LARGE};
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.LARGE};
  `}
`;

const Content = styled.div`
  margin-top: 10px;
  font-size: ${props => props.theme.MEDIUM};
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.MEDIUM};
  `}
`;

export { Container, Heading, SubHeading, Content };
