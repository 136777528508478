import { css, keyframes } from 'styled-components';

export const BREAKPOINTS = {
  SMALL_PHONE: 320,
  MEDIUM_PHONE: 480,
  PHONE: 544,
  TABLET: 768,
  DESKTOP: 1012,
  WIDE_DESKTOP: 1280
};

const media = Object.keys(BREAKPOINTS).reduce(
  (acc, label) => ({
    ...acc,
    [label]: (...args) => css`
      @media (max-width: ${BREAKPOINTS[label]}px) {
        ${css(...args)}
      }
    `
  }),
  {}
);

const mappedKeyFrames = {
  smoothBounce: keyframes`
    0% { transform: translateY(0); } 
    20% { transform: translateY(0); } 
    40% { transform: translateY(0); } 
    50% { transform: translateY(5px); }
    80% { transform: translateY(0); } 
    100% { transform: translateY(0); } 
  `
};

export default {
  default: {
    SPRING_WOOD: '#FBFAF8',
    WHITE: '#FFFFFF',
    SNOW: '#FFFAFA',
    BLACK: '#000',
    MERCURY: '#E7E7E7',
    GALLERY: '#EFEFEF',
    MINE_SHAFT: '#333333',
    DARK_BLACK: '#303030',
    SOLID_BLACK: '#000000',
    SILVER_CHALICE: '#A1A1A1',
    RONCHI: '#F0D64E',
    GAINSBORO: '#D8D8D8',
    FLORAL_WHITE: '#FBFAF8',
    PINK_SWAN: '#B2B2B2',
    BUDDHA_GOLD: '#B69903',
    CORDUROY: '#6B6C6B',
    TACHA: '#D2C26A',
    OLIVE: '#756200',
    HAVELOCK_BLUE: '#4A90E2',
    RED: '#FF0000',
    WILD_SAND: '#F5F5F5',
    CHINESE_SILVER: '#CBCBCB',
    TANA: '#DFDABF',
    MATTERHORN: '#535353',
    DIM_GRAY: '#717171',
    SONIC_SILVER: '#767676',
    TAB_BLUE_COLOR: '#0061BB',
    INVOLVED: '#E6C100',
    NOT_INVOLVED: '#EDE5C0',
    DARK_GRAY: '#A9A9A9',
    LIGHT_SILVER: '#D9D9D9',
    BLUE_GEM: '#3C16A8',
    DARK_RED: '#EE3333',
    LIME_GREEN: '#22BB33',
    VERDUN_GREEN: '#3E7000',
    SUCCESS: '#00B512',
    SUCCESS_HOVER: '#00C314',
    FAILURE: '#EA4C68',
    FAILURE_HOVER: '#F01840',

    LIGHT_FONT: 300,
    SEMI_MEDIUM_FONT: 400,
    MEDIUM_FONT: 500,
    SEMI_BOLD_FONT: 600,
    BOLD_FONT: 700,

    X_SMALL: '10px',
    SMALL: '12px',
    MEDIUM: '14px',
    LARGE: '16px',
    X_LARGE: '18px',
    XX_LARGE: '20px',
    XXX_LARGE: '24px',
    HEADING: '30px',
    XXXX_LARGE: '32px',
    PAGE_HEADING: '40px',

    FONT_FAMILY: '"Shoptype",sans-serif',

    ...media,

    ...mappedKeyFrames
  }
};
