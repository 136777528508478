import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getApiResponseObject,
  getAuthToken,
  postWithResponseObject,
  putWithResponseObject,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import * as actions from '../../../../redux/actions/uiActions';
import { API_BASE_URL } from '../../../../config/env';
import { isEmpty } from 'lodash';
import { ConnectionCardWrapper } from '../styles';
import EmptyState from '../../../atoms/EmptyState';
import ConnectionRequestCard from './connectionRequestCard';
import { withSnackbar, MESSAGE_SEVERITY } from '../../../hoc/withSnackbar';
import {
  ACCEPT,
  CONFIRMATION_DIALOG,
  DECLINE
} from '../ConfirmationDialog/constants';
import ConfirmationDialog from '../ConfirmationDialog';

const ConnectionRequests = ({ showSnackbar }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [connectionRequests, setConnectionRequests] = useState([]);
  const [networkAnalytics, setNetworkAnalytics] = useState({});
  const [dataAvailable, setDataAvailable] = useState(false);

  // Dialog
  const [currentActionDetails, setCurrentActionDetails] = useState({
    action: '',
    currentConnectionObj: {}
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    open: false,
    title: '',
    description: ''
  });
  // Dialog Close

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    const networksData = await fetchConnectionRequests();
    if (!isEmpty(networksData)) setConnectionRequests(networksData?.data);
    else return;

    if (!isEmpty(networksData?.platformIds)) {
      const analyticsResponse = await fetchNetworkAnalytics(
        networksData?.platformIds
      );
      setNetworkAnalytics(analyticsResponse);
    }
  };

  const fetchConnectionRequests = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    dispatch(actions.showSpinnerBackdropAction());

    const response = await getApiResponseObject(
      `${API_BASE_URL}/vendor-network-connections/requests`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      setLoading(false);
      dispatch(actions.hideSpinnerBackdropAction());
      showApiErrorToast(response.data);
    } else {
      setLoading(false);
      dispatch(actions.hideSpinnerBackdropAction());
      return response.data;
    }
  };

  const fetchNetworkAnalytics = async platformIds => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const requestBody = {
      platformIds: platformIds
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/discover-networks/analytics`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      setLoading(false);
      showApiErrorToast(response.data);
    } else {
      setLoading(false);
      return response.data.data;
    }
  };

  // ****************************************** CONFIRMATION DIALOG **********************************************

  // handling confirmation dialog on currentActionDetails changes
  useEffect(() => {
    const handleConfirmationDialog = () => {
      switch (currentActionDetails.action) {
        case ACCEPT:
          openConfirmationDialog(
            CONFIRMATION_DIALOG.accept.title,
            CONFIRMATION_DIALOG.accept.description
          );
          break;
        case DECLINE:
          openConfirmationDialog(
            CONFIRMATION_DIALOG.decline.title,
            CONFIRMATION_DIALOG.decline.description
          );
          break;

        default:
          //
          break;
      }
    };

    handleConfirmationDialog();
  }, [currentActionDetails]);

  const openConfirmationDialog = (title, description) => {
    setConfirmationDialogData({ open: true, title, description });
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogData(prevState => {
      return {
        ...prevState,
        open: false
      };
    });
  };

  const closeConfirmationDialogAndResetData = () => {
    closeConfirmationDialog();
    resetCurrentActionDetails();

    setTimeout(() => {
      resetConfirmationDialogData();
    }, 500);
  };

  const resetCurrentActionDetails = () => {
    setCurrentActionDetails({ action: '', currentConnectionObj: {} });
  };

  const resetConfirmationDialogData = () => {
    setConfirmationDialogData({ open: false, title: '', description: '' });
  };

  const handleConfirmAction = () => {
    closeConfirmationDialog();

    if (currentActionDetails.action === ACCEPT) {
      acceptRequest();
    } else if (currentActionDetails.action === DECLINE) {
      declineRequest();
    }
  };

  const acceptRequest = async () => {
    const networkId = currentActionDetails.currentConnectionObj?.network_id;
    const token = getAuthToken();
    const headers = { authorization: token };
    const reqBody = {
      network_id: networkId
    };

    resetCurrentActionDetails();

    const response = await putWithResponseObject(
      `${API_BASE_URL}/vendor-network-connections/accept`,
      reqBody,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      showSnackbar({
        message: 'Connection accepted successfully',
        severity: MESSAGE_SEVERITY.success
      });
      fetchAllData();
    } else {
      showSnackbar({
        error: response.data,
        message: 'Failed to accept connection',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  const declineRequest = async () => {
    const connectionNetworkId =
      currentActionDetails.currentConnectionObj?.network_id;
    const token = getAuthToken();
    const headers = { authorization: token };
    const reqBody = {
      network_id: connectionNetworkId
    };

    resetCurrentActionDetails();

    const response = await putWithResponseObject(
      `${API_BASE_URL}/vendor-network-connections/decline`,
      reqBody,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      showSnackbar({
        message: 'Connection request declined',
        severity: MESSAGE_SEVERITY.success
      });
      fetchAllData();
    } else {
      showSnackbar({
        error: response.data,
        message: 'Failed to decline request',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  const onAccept = connectionObj => {
    setCurrentActionDetails(prevState => {
      return {
        ...prevState,
        action: 'accept',
        currentConnectionObj: connectionObj
      };
    });
  };

  const onDecline = connectionObj => {
    setCurrentActionDetails(prevState => {
      return {
        ...prevState,
        action: 'decline',
        currentConnectionObj: connectionObj
      };
    });
  };

  return (
    <>
      {!loading && (
        <ConfirmationDialog
          open={confirmationDialogData.open}
          title={confirmationDialogData.title}
          description={confirmationDialogData.description}
          onConfirm={handleConfirmAction}
          onClose={closeConfirmationDialogAndResetData}
          onCancel={closeConfirmationDialogAndResetData}
        />
      )}
      {!loading && !isEmpty(connectionRequests) ? (
        <ConnectionCardWrapper>
          {connectionRequests.map((network, index) => (
            <ConnectionRequestCard
              key={index}
              network={network.Network}
              platform={network.Platform}
              analytics={networkAnalytics[network.Platform.id]}
              onAccept={() => onAccept(network)}
              onDecline={() => onDecline(network)}
            />
          ))}
        </ConnectionCardWrapper>
      ) : (
        !loading &&
        isEmpty(connectionRequests) && (
          <EmptyState errorMessage="No new connections are available" />
        )
      )}
    </>
  );
};

export default withSnackbar(ConnectionRequests);
