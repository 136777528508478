import PropTypes from 'prop-types';
import React from 'react';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { Router, Switch } from 'react-router';
import styled from 'styled-components';
import { COSELLER } from '../../../constants';
import history from '../../../history';
import { getUserType, isLoggedIn } from '../../../utils';
import STRoute from '../../atoms/STRoute';
import StripeConnectConfirm from '../../helpers/stripeConnectConfirm';
import WalletStripeConnect from '../../helpers/WalletStripeConnect';
import GoogleMaps from '../../organisms/AddressInput/googlePlacesAutoComplete';
import CheckoutCart from '../../organisms/CheckoutCart';
import EmbedCode from '../../organisms/EmbedCode';
import PageNotFound from '../../pages/404';
import AddProduct from '../../pages/AddProducts';
import ApiKeys from '../../pages/ApiKeys';
import AwakeMoneyDashboard from '../../pages/AwakeMoney/Dashboard';
import ChangePassword from '../../pages/ChangePassword';
import CosellerAttribution from '../../pages/Coseller/Attribution';
import CosellerDashboardWrapper from '../../pages/Coseller/Dashboard/dashboardWrapper';
import CosellerProducts from '../../pages/Coseller/ProductList';
import Purchases from '../../pages/Coseller/Purchases';
import DashboardWrapper from '../../pages/Dashboard/DasboardWrapper';
import ImportProducts from '../../pages/ImportProducts';
import NetworkOperatorApprovals from '../../pages/NetworkOperator/Approvals';
import NetworkOperatorAttribution from '../../pages/NetworkOperator/Attribution';
import CollectionsWrapper from '../../pages/NetworkOperator/Collections/CollectionsWrapper';
import CreateCollection from '../../pages/NetworkOperator/Collections/CreateCollection';
import NetworkOperatorDashboardWrapper from '../../pages/NetworkOperator/Dashboard/dashboardWrapper';
import NetworkOperatorNetworks from '../../pages/NetworkOperator/Networks';
import NetworkOperatorOrders from '../../pages/NetworkOperator/Orders';
import NetworkOperatorPayments from '../../pages/NetworkOperator/Payments';
import NetworkOperatorPayouts from '../../pages/NetworkOperator/Payouts';
import NetworkProducts from '../../pages/NetworkOperator/ProductList';
import NetworkOperatorVendors from '../../pages/NetworkOperator/Vendors';
import OrderConfirm from '../../pages/OrderConfirm';
import OrderConfirmation from '../../pages/OrderConfirmation';
import Referrals from '../../pages/Referrals';
import ShoptypeCart from '../../pages/ShoptypeExtras/Cart';
import ShoptypeSignIn from '../../pages/ShoptypeExtras/SignInPage';
import ShoptypeSignUp from '../../pages/ShoptypeExtras/SignUpPage';
import SignIn from '../../pages/SignIn';
import SocialPlatform from '../../pages/SocialPlatform';
import TotalAmount from '../../pages/TotalAmount';
import VendorNetworks from '../../pages/VendorNetworks';
import VendorPayouts from '../../pages/Vendors/Payouts';
import Attribution from '../../templates/Attribution';
import Checkout from '../../templates/Checkout';
import CheckoutV2 from '../../templates/Checkout_v2.0';
import OrderFailed from '../../templates/OrderFailed';
import ProductDetail from '../../templates/ProductDetail';
import ProductMasterTemplate from '../../templates/ProductMasterTemplate';
import ThankYou from '../../templates/ThankYou';
import ThankYouV1 from '../../templates/ThankYou_v1.0';
import CreateAccount from '../CreateAccount';
import OrderDetails from '../OrderDetails';
import Policies from '../Policies';
import ProfileDetails from '../../pages/ProfileDetails';

const PrimaryViewContainer = styled.div`
  height: fit-content;
  ${p => p.theme.TABLET`
    background-color: ${props => props.theme.SPRING_WOOD};
  `};
`;

class PrimaryView extends React.Component {
  render() {
    return (
      <Router history={history}>
        <PrimaryViewContainer>
          <Switch>
            <STRoute
              exact
              path="/"
              altRender={() =>
                window.location.pathname !== '/signin' &&
                window.location.pathname !== '/signup' &&
                isLoggedIn() ? (
                  window.location.pathname !== '/signin' &&
                  window.location.pathname !== '/signup' &&
                  getUserType() === COSELLER ? (
                    <CosellerDashboardWrapper />
                  ) : (
                    <DashboardWrapper />
                  )
                ) : (
                  <CreateAccount />
                )
              }
              requiresLogin
            />
            <STRoute path="/users" component={CreateAccount} />
            <STRoute path="/policies" component={Policies} />
            <STRoute path="/linkedin" component={LinkedInPopUp} />
            <STRoute path="/sign-in" component={SignIn} hideHelpChat />
            <STRoute
              path="/networkoperator/dashboard"
              component={NetworkOperatorDashboardWrapper}
              requiresLogin
            />
            <STRoute
              exact
              path="/networkoperator/network"
              component={NetworkOperatorNetworks}
              requiresLogin
            />
            <STRoute
              exact
              path="/networkoperator/vendors"
              component={NetworkOperatorVendors}
              requiresLogin
            />
            <STRoute
              path="/dashboard"
              component={DashboardWrapper}
              requiresLogin
            />
            <STRoute
              path="/cosellerdashboard"
              component={CosellerDashboardWrapper}
              requiresLogin
            />
            <STRoute
              path="/cosellerproducts"
              component={CosellerProducts}
              requiresLogin
            />
            <STRoute
              path="/cosellerearnings"
              component={CosellerAttribution}
              requiresLogin
            />
            <STRoute
              path="/cosellerpurchases"
              component={Purchases}
              requiresLogin
            />
            <STRoute
              path="/products"
              component={ImportProducts}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/products"
              component={NetworkProducts}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/collections"
              component={CollectionsWrapper}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/create-collection/:collectionId?"
              component={CreateCollection}
              requiresLogin
            />
            <STRoute
              path="/create-product"
              component={ProductMasterTemplate}
              mode="Create"
              requiresLogin
            />
            <STRoute
              path="/add-products"
              component={AddProduct}
              hasProducts={true}
              requiresLogin
            />
            <STRoute
              exact
              path="/product/:productId"
              viewProduct="no"
              component={ProductDetail}
            />
            <STRoute
              exact
              path="/view-product/:productId"
              viewProduct="yes"
              component={ProductDetail}
              requiresLogin
            />
            <STRoute
              exact
              path="/edit-product/:productId"
              mode="Edit"
              component={ProductMasterTemplate}
              requiresLogin
            />
            <STRoute
              path="/attribution"
              component={Attribution}
              requiresLogin
            />
            <STRoute
              path="/social-platforms"
              component={SocialPlatform}
              requiresLogin
            />
            <STRoute
              path="/:authId/embed-code/:productId"
              component={EmbedCode}
            />
            <STRoute
              path="/profile"
              component={ProfileDetails}
              requiresLogin
              hideHelpChat
            />
            <STRoute
              path="/edit-profile"
              component={ProfileDetails}
              requiresLogin
              hideHelpChat
            />
            <STRoute
              path="/network-profile"
              component={ProfileDetails}
              requiresLogin
              hideHelpChat
            />
            <STRoute
              path="/change-password"
              component={ChangePassword}
              requiresLogin
            />
            <STRoute
              path="/coseller/change-password"
              component={ChangePassword}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/change-password"
              component={ChangePassword}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/attribution"
              component={NetworkOperatorAttribution}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/orders"
              component={NetworkOperatorOrders}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/payments"
              component={NetworkOperatorPayments}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/payouts"
              component={NetworkOperatorPayouts}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/approvals"
              component={NetworkOperatorApprovals}
              requiresLogin
              requiresAdmin
            />
            <STRoute path="/orders" component={OrderDetails} requiresLogin />
            <STRoute
              exact
              path="/networks"
              component={VendorNetworks}
              requiresLogin
            />
            <STRoute path="/checkout/:checkoutId" component={Checkout} />
            <STRoute path="/checkout_v2/:checkoutId" component={CheckoutV2} />
            <STRoute path="/google" component={GoogleMaps} />
            <STRoute path="/order-confirm" component={OrderConfirm} />
            <STRoute path="/order-confirmation" component={OrderConfirmation} />
            <STRoute path="/confirm" component={TotalAmount} />
            <STRoute exact path="/thank-you-v1" component={ThankYouV1} />
            <STRoute exact path="/thank-you" component={ThankYou} />
            <STRoute path="/order-failed" component={OrderFailed} />
            <STRoute path="/checkout-cart" component={CheckoutCart} />
            <STRoute exact path="/cart" component={ShoptypeCart} />
            <STRoute exact path="/signin" component={ShoptypeSignIn} />
            <STRoute exact path="/signup" component={ShoptypeSignUp} />
            <STRoute
              exact
              path="/wallet"
              component={AwakeMoneyDashboard}
              requiresLogin
            />
            <STRoute
              path="/cosellerwallet"
              component={AwakeMoneyDashboard}
              requiresLogin
            />
            <STRoute
              path="/networkoperator/wallet"
              component={AwakeMoneyDashboard}
              requiresLogin
            />
            <STRoute path="/referrals" component={Referrals} requiresLogin />
            <STRoute exact path="/api-keys" component={ApiKeys} requiresLogin />
            <STRoute
              path="/payouts"
              component={VendorPayouts}
              requiresLogin
              requiresNoShoptypeCheckout
            />
            <STRoute
              exact
              path="/stripe-connect/confirm"
              component={StripeConnectConfirm}
              requiresLogin
            />
            <STRoute
              exact
              path="/wallet/stripe-connect/confirm"
              component={WalletStripeConnect}
              requiresLogin
            />
            <STRoute exact path="/page-not-found" component={PageNotFound} />
            <STRoute path="*" component={PageNotFound} />
          </Switch>
        </PrimaryViewContainer>
      </Router>
    );
  }
}

PrimaryView.propTypes = {
  activeCategory: PropTypes.string
};

export default PrimaryView;
