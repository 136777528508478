import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const options = [
  'Date: Latest First',
  'Date: Earliest First',
  'Price: Lowest First',
  'Price: Highest First',
  'Quantity: Least',
  'Quantity: Max'
];

function SortByParams(props) {
  const { fetchParamsObj } = props;

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');

  const SORTBYPARAMS_DATA = [
    {
      selectedSortOption: 'Date: Latest First',
      sortByParam: 'createdAt',
      order: 'desc'
    },
    {
      selectedSortOption: 'Date: Earliest First',
      sortByParam: 'createdAt',
      order: 'asc'
    },
    {
      selectedSortOption: 'Price: Lowest First',
      sortByParam: 'totalPrice',
      order: 'asc'
    },
    {
      selectedSortOption: 'Price: Highest First',
      sortByParam: 'totalPrice',
      order: 'desc'
    },
    {
      selectedSortOption: 'Quantity: Least',
      sortByParam: 'quantity',
      order: 'asc'
    },
    {
      selectedSortOption: 'Quantity: Max',
      sortByParam: 'quantity',
      order: 'desc'
    }
  ];

  const setParamsObj = inputValue => {
    let selectedSortOption = SORTBYPARAMS_DATA.find(
      item => item.selectedSortOption === inputValue
    );
    fetchParamsObj(selectedSortOption);
  };

  return (
    <div>
      <Autocomplete
        id="sorting-autocomplete"
        size={props.size}
        value={value}
        onChange={(event, newValue) => {
          setParamsObj(newValue);
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        style={{ width: 250 }}
        renderInput={params => (
          <TextField
            {...params}
            label="Sort By"
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
          />
        )}
      />
    </div>
  );
}

export default SortByParams;
