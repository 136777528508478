import styled, { css } from 'styled-components';

const EditVariantMediaContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: ${props => props.theme.WHITE};
  max-height: 1000px;
  & > button {
    top: 15px;
    right: 15px;
  }
`;

const ModalHeading = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  font-family: Montserrat;
  padding-left: 10px;
  text-align: left;
`;

const VariantMediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
  & > img {
  }
`;

const ProductImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  background-color: #f4f6f8;
  margin: 10px;
  & > img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    border: 1px solid rgb(186, 186, 186);
    ${props =>
      props.selectedStatus
        ? css`
            border: 2px solid ${props => props.theme.BLACK};
          `
        : css``}
  }
`;

export {
  ModalHeading,
  EditVariantMediaContainer,
  VariantMediaContainer,
  ImagesContainer,
  ProductImageWrapper
};
