import EventEmitter from 'eventemitter3';
import _ from 'lodash';
import { GLOBAL_EVENT_KEYS } from '../../constants';

const BaseEmitter = new EventEmitter();

/**
 * Validate event key
 *
 * @param {string} eventKey
 */
const validateEventKey = eventKey => {
  return GLOBAL_EVENT_KEYS[eventKey] ? true : false;
};

export const EventsBaseClient = {
  /**
   * Adds the @listener function to the end of the listeners array
   * for the event key named @eventKey
   * Will ensure that only one time the listener added for the event
   *
   * @param {string} eventKey
   * @param {function} listener
   */
  on: (eventKey, listener) => {
    if (validateEventKey(eventKey)) {
      BaseEmitter.on(eventKey, listener);
    }
  },

  /**
   * Will remove the specified @listener from @eventKey list
   *
   * @param {string} eventKey
   * @param {function} listener
   */
  removeEventListener: (eventKey, listener) => {
    if (validateEventKey(eventKey)) {
      BaseEmitter.removeListener(eventKey, listener);
    }
  },

  /**
   * Will emit the event on the event key with the @payload
   * and if its an error set the @error value
   *
   * @param {string} eventKey
   * @param {object} payload
   * @param {boolean} error
   */
  emit: (eventKey, payload, error = false) => {
    if (validateEventKey(eventKey)) {
      BaseEmitter.emit(eventKey, payload, error);
    }
  }
};
