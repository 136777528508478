import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  min-width: fit-content;
  height: 100vh;
  min-height: inherit;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 630px;
  margin: 60px;
  background-color: ${props => props.theme.WHITE};
  height: 600px;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
`;

const Pane = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 60px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const LogoImage = styled.img`
  height: 30px;
`;

const LogoContent = styled.div`
  cursor: pointer;
`;

const RouteContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const RouteContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export {
  Container,
  LogoContainer,
  SelectionContainer,
  LogoImage,
  LogoContent,
  RouteContainer,
  RouteContent,
  Wrapper,
  Pane
};
