export const ACCEPT = 'accept';
export const DECLINE = 'decline';
export const REMOVE = 'remove';

export const CONFIRMATION_DIALOG = {
  accept: {
    title: 'Accept Network request?',
    description:
      'On clicking Confirm, the selected connection request will be accepted and you will become a part of that Network. Do you want to continue?'
  },
  decline: {
    title: 'Decline the connection request?',
    description:
      'On clicking Confirm, the selected Network request will be declined, the Network won\'t be notified about that. Do you want to continue?'
  },
  remove: {
    title: 'Remove Network from your connections?',
    description:
      'On clicking Confirm, the selected Network will be removed from your list of existing connectons. Do you want to continue?'
  }
};
