import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import HorizontalSeparator from '../../../atoms/horizontalSeparator';
import { toaster } from '../../../atoms/toaster';
import { API_BASE_URL, APP_BASE_URL } from '../../../../config/env';
import * as actions from '../../../../redux/actions/uiActions';
import {
  getApiResponseObject,
  postWithResponseObject,
  successStatusCodes,
  checkBrowserStoredData,
  isEmailValid,
  isPasswordValid,
  PASSWORD_PATTERN,
  cookieEnabled,
  getBrowserStoredData,
  showApiErrorToast
} from '../../../../utils';
import {
  DOMAIN,
  GOOGLEPROVIDER,
  FACEBOOKPROVIDER,
  COSELLER,
  FACEBOOKAPPID,
  GOOGLECLIENTID
} from '../../../../constants';
import {
  Wrapper,
  Container,
  Heading,
  AuthContainer,
  AuthButton,
  AwakeMeButton,
  FormContainer,
  FormFields,
  TextInputField,
  FormActions,
  ActionButton,
  ActionLinkButton,
  ActionLink,
  Col
} from './styles';

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      buttonDisabled: false,
      data: {
        id: '',
        token: '',
        userType: '',
        userProfile: ''
      }
    };
  }

  componentDidMount() {
    if (cookieEnabled()) {
      this.ifAlreadyLoggedIn();
    }
  }

  ifAlreadyLoggedIn = () => {
    if (checkBrowserStoredData()) {
      this.sendDataToParent();
    }
  };

  inputChangeHandler = e => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value.trim();

    this.setState({ [fieldName]: fieldValue });
  };

  keyPressHandler = e => {
    const error = this.inputsAreInvalid();

    if (!error && e.key === 'Enter') {
      this.signIn();
    } else if (e.key === 'Enter') {
      toaster(error);
    }
  };

  clickAwakeMe = () => {
    window.location.href =
      'http://beta-awake.me.s3-website-us-east-1.amazonaws.com/?SSO=true&APP_ID=123456789';
  };

  handleGoogleResponse = response => {
    let gmailToken = response.tokenId;
    if (gmailToken) {
      this.socialSignIn(gmailToken, GOOGLEPROVIDER);
    } else if (response.error && response.error !== 'popup_closed_by_user') {
      return toaster('Error Connecting to Gmail');
    }
  };

  handleFacebookResponse = response => {
    let facebookToken = response.accessToken;
    if (facebookToken) {
      this.socialSignIn(facebookToken, FACEBOOKPROVIDER);
    } else {
      return toaster('Error Connecting to Facebook');
    }
  };

  handleSignIn = () => {
    const inputValidationError = this.inputsAreInvalid();

    if (!inputValidationError) {
      this.signIn();
    } else {
      return toaster(inputValidationError);
    }
  };

  inputsAreInvalid = () => {
    if (!isEmailValid(this.state.email)) {
      return 'Please enter a valid email id';
    } else if (this.state.password.length <= 0) {
      return 'Please enter password';
    } else if (!isPasswordValid(this.state.password)) {
      return 'Password must contain at least a lowercase letter, uppercase letter, numeric digit, special character & should be longer than 7 characters';
    }
    return null;
  };

  socialSignIn = async (token, provider) => {
    const requestBody = {
      token: token,
      provider: provider,
      domain: DOMAIN
    };

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/social-login`,
      requestBody
    );

    if (cookieEnabled()) {
      localStorage.setItem('_id', JSON.stringify(response?.data?.data?._id));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          id: response?.data?.data?._id
        }
      }));
    }

    if (successStatusCodes.includes(response.status)) {
      this.userOnboard(response.data.data.token);
    } else {
      this.props.hideSpinnerBackdrop();
      this.setState({ buttonDisabled: false });
      showApiErrorToast(response.data);
    }
  };

  signIn = async () => {
    const { email, password } = this.state;

    const requestBody = {
      email: email,
      password: password
    };

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/login`,
      requestBody
    );

    if (cookieEnabled()) {
      localStorage.setItem('_id', JSON.stringify(response?.data?.data?._id));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          id: response?.data?.data?._id
        }
      }));
    }

    if (successStatusCodes.includes(response.status)) {
      this.userOnboard(response.data.data.token);
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
    }
  };

  userOnboard = async token => {
    const headers = { authorization: token };
    const requestBody = {
      userType: COSELLER.toLowerCase()
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/authenticate`,
      requestBody,
      headers
    );

    if (cookieEnabled()) {
      localStorage.setItem('token', JSON.stringify(response?.data?.token));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          token: response?.data?.token
        }
      }));
    }

    if (response.data.is_onboarded === false) {
      const reqBody = {};

      const response = await postWithResponseObject(
        `${API_BASE_URL}/cosellers`,
        reqBody,
        headers
      );

      if (successStatusCodes.includes(response.status)) {
        const cosellerToken = response.data.token;

        if (cookieEnabled()) {
          localStorage.setItem('USER_TYPE', COSELLER);
        } else {
          this.setState(prevState => ({
            data: {
              ...prevState.data,
              userType: COSELLER
            }
          }));
        }

        this.updateUserProfileToLocalStorage(cosellerToken);
      }
    } else {
      const cosellerToken = response.data.token;

      if (cookieEnabled()) {
        localStorage.setItem('USER_TYPE', COSELLER);
      } else {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            userType: COSELLER
          }
        }));
      }

      this.updateUserProfileToLocalStorage(cosellerToken);
    }
  };

  updateUserProfileToLocalStorage = async authToken => {
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: authToken
    });

    if (successStatusCodes.includes(profileRes.status)) {
      if (cookieEnabled()) {
        localStorage.setItem('userProfile', JSON.stringify(profileRes.data));
      } else {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            userProfile: profileRes.data
          }
        }));
      }

      this.sendDataToParent();
      this.resetPage();
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
      toaster('Error fetching user profile details');
    }
  };

  sendDataToParent = () => {
    let data = {};

    if (cookieEnabled()) {
      data = getBrowserStoredData();
    } else {
      data = this.state.data;
    }

    window.parent.postMessage(JSON.stringify(data), '*');
  };

  resetPage = () => {
    this.setState({
      email: '',
      password: '',
      buttonDisabled: false
    });
  };

  render() {
    const { email, password, buttonDisabled } = this.state;
    return (
      <Wrapper>
        <Container>
          <Heading>Sign In</Heading>
          <AuthContainer>
            <GoogleLogin
              clientId={GOOGLECLIENTID}
              render={renderProps => (
                <AuthButton
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    width="56px"
                    src={require('../../../../assets/images/google.png')}
                    alt="google"
                  />
                </AuthButton>
              )}
              onSuccess={this.handleGoogleResponse}
              onFailure={this.handleGoogleResponse}
              cookiePolicy={'single_host_origin'}
            />
            <FacebookLogin
              appId={FACEBOOKAPPID}
              callback={this.handleFacebookResponse}
              render={renderProps => (
                <AuthButton onClick={renderProps.onClick}>
                  <img
                    width="56px"
                    src={require('../../../../assets/images/facebook.png')}
                    alt="google"
                  />
                </AuthButton>
              )}
            />
            <AwakeMeButton
              text="Awake.me"
              backgroundColor="white"
              textColor="black"
              borderColor="black"
              onClick={this.clickAwakeMe}
            />
          </AuthContainer>
          <HorizontalSeparator label="OR" />
          <FormContainer>
            <FormFields>
              <TextInputField
                id="email"
                type="email"
                name="email"
                autocomplete="off"
                placeHolder="Email"
                value={email}
                onChange={e => this.inputChangeHandler(e)}
                onKeyPress={e => {
                  this.keyPressHandler(e);
                }}
              />
              <TextInputField
                id="password"
                type="password"
                name="password"
                autocomplete="off"
                placeHolder="Password"
                pattern={PASSWORD_PATTERN}
                value={password}
                onChange={e => this.inputChangeHandler(e)}
                onKeyPress={e => {
                  this.keyPressHandler(e);
                }}
              />
            </FormFields>
            <FormActions>
              <Col>
                <ActionButton
                  text="Sign In"
                  borderColor="black"
                  backgroundColor="black"
                  textColor="white"
                  onClick={() => this.handleSignIn()}
                  disabled={buttonDisabled}
                />
                <ActionLinkButton>
                  <ActionLink href={APP_BASE_URL + '/signup'}>
                    Don&apos;t have an account? Click here to Sign Up
                  </ActionLink>
                </ActionLinkButton>
              </Col>
            </FormActions>
          </FormContainer>
        </Container>
      </Wrapper>
    );
  }
}

SignInPage.propTypes = { showSpinnerBackdrop: func, hideSpinnerBackdrop: func };

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(SignInPage));
