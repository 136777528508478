import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../../atoms/loader';
import { toaster } from '../../atoms/toaster';
import { Filled } from '../../atoms/button';
import TextInput from '../../atoms/textInput';
import history from '../../../history';
import {
  getApiResponseObject,
  post,
  getAuthToken,
  getAuthId,
  showApiErrorToast
} from '../../../utils';
import { API_BASE_URL, APP_BASE_URL } from '../../../config/env';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaContainer from './mediaContainer';
import VariantsContainer from './variantsContainer';
import ProductCards from './cards';

import {
  Container,
  InnerContainer,
  ProductContainer,
  Heading,
  SubHeading,
  EditPrimaryDetails,
  AddTitleContainer,
  MiniHeading,
  AddDescriptionContainer,
  AddCategoryContainer,
  EditPricingContainer,
  EditInventoryContainer,
  EditAddTagsContainer,
  TagsHeadingInfoContainer,
  AddTagsInfo,
  SaveDetails
} from './styles';
import * as createProductActions from '../../../redux/actions/createProductActions';
import { DOMAIN, VARIANT_COMBO_DELIMITER } from '../../../constants';

class ProductMasterTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      isLoading: !(this.props.mode === 'Create'),
      productId: this.props.match.params.productId,
      productSource: 'SHOPTYPE',
      fetchedProductDetails: {},
      currency: '',
      isLoadingMediaStorageMedia: false,
      mediaStorage: {
        images: {},
        videos: {}
      },
      primaryImage: {
        id: '',
        mediaSrc: '',
        variantIds: []
      },
      defaultVariant: { variantNameValue: { title: 'Default Title' } },
      variantOptionStatus: false,
      generatedVariantsObject: {},
      variantStorage: {},
      variantOptionTypeOptionValuesMapping: {},
      selectedVariantOptionTypes: [],
      structuredMediaData: {},
      structureMediaDataStatus: false,
      howitworks: {
        mediaUrl: '',
        text: [],
        type: 'image'
      },
      descriptionData: [],
      testimony: { text: '' },
      features: { points: [''], text: '' },
      technicalspecifications: { points: [''] },
      details: { points: [''] },
      faq: [],
      faqQuestion: '',
      faqAnswer: '',
      tagsString: '',
      disableSaveButton: false
    };
    this.mediaStorage = React.createRef();
  }

  componentDidMount = () => {
    if (this.state.mode === 'Edit') {
      this.fetchExistingProductData();
    }
    this.props.addProduct();
  };

  fetchExistingProductData = async () => {
    let { productId } = this.state;

    const userId = getAuthId();
    if (!userId) return toaster('Something went wrong!');

    let token = getAuthToken();
    const headers = { authorization: token };
    const response = await getApiResponseObject(
      `${API_BASE_URL}/products/${productId}`,
      headers
    );

    if (response.status !== 200) {
      showApiErrorToast(response.data);
    } else {
      const fetchedProductDetails = response.data;
      if (isEmpty(fetchedProductDetails)) {
        return toaster(
          'Failed to fetch product details, please reload the page'
        );
      }
      this.setState(
        {
          fetchedProductDetails,
          userId
        },
        () => {
          let { fetchedProductDetails } = this.state;
          this.pushFetchedProductDataToPrimaryDetails(
            fetchedProductDetails.title,
            fetchedProductDetails.description,
            fetchedProductDetails.currency
          );

          this.pushFetchedMediaDataToMediaStorage(
            fetchedProductDetails.primaryImageSrc || {},
            fetchedProductDetails.secondaryImageSrc || [],
            fetchedProductDetails.primaryVideoSrc || {}
          );

          this.pushFetchedVariantDataToVariantStorage(
            fetchedProductDetails.variants || [],
            fetchedProductDetails.options || []
          );

          this.pushFetchedCardsDataToCardsStorage(
            fetchedProductDetails.cards || []
          );

          this.setState({
            isLoading: false,
            productSource: fetchedProductDetails.sourceName || 'NA',
            tagsString: fetchedProductDetails.tags
              ? fetchedProductDetails.tags.join(',')
              : '',
            category: fetchedProductDetails.productType
              ? fetchedProductDetails.productType
              : ''
          });
        }
      );
    }
  };

  updateContentHandler = e => {
    let state = {};
    state.technicalspecifications = this.state.technicalspecifications;
    state.details = this.state.details;
    state.features = this.state.features;
    state.testimony = this.state.testimony;
    state.defaultVariant = this.state.defaultVariant;
    /*Storing for data for techspecs and details card*/
    let input = { id: e.target.id, name: e.target.name, value: e.target.value };

    if (input.id.includes('default')) {
      state.defaultVariant[input.name] = input.value;
    }
    if (
      input.name === 'features' ||
      input.name === 'technicalspecifications' ||
      input.name === 'details'
    ) {
      state[input.name] = this.state[input.name];
      let cardTextIndex = parseInt(input.id.replace(`${input.name}-`, ''));
      state[input.name].points[cardTextIndex] = input.value;
    } else if (input.name === 'testimony') {
      state[input.name].text = input.value;
    } else {
      state[input.name] = input.value;
    }

    this.setState(state);
  };

  toggleVariantsOptionStatus = () => {
    this.setState({ variantOptionStatus: !this.state.variantOptionStatus });
  };

  pushFetchedProductDataToPrimaryDetails = (title, description, currency) => {
    this.setState({ title, description, currency });
  };

  pushFetchedMediaDataToMediaStorage = (
    primaryImageElement,
    secondaryImageElements
  ) => {
    let { mediaStorage, primaryImage } = this.state;

    if (!isEmpty(primaryImageElement)) {
      mediaStorage.images[primaryImageElement.id] = {
        id: primaryImageElement.id,
        mediaSrc: primaryImageElement.imageSrc,
        variantIds: primaryImageElement.variantIds || [],
        primary: true
      };

      primaryImage = {
        id: primaryImageElement.id,
        mediaSrc: primaryImageElement.imageSrc,
        variantIds: primaryImageElement.variantIds || []
      };
    }
    if (!isEmpty(secondaryImageElements)) {
      secondaryImageElements.forEach(imageElement => {
        mediaStorage.images[imageElement.id] = {
          id: imageElement.id,
          mediaSrc: imageElement.imageSrc,
          variantIds: imageElement.variantIds || []
        };
      });
    }
    this.setState({ mediaStorage, primaryImage });
  };

  pushFetchedVariantDataToVariantStorage = (variants, options) => {
    let {
      defaultVariant,
      variantOptionStatus,
      variantOptionTypeOptionValuesMapping,
      generatedVariantsObject,
      selectedVariantOptionTypes,
      variantStorage
    } = this.state;

    if (
      variants.length === 1 &&
      variants[0].variantNameValue.title === 'Default Title'
    ) {
      defaultVariant = variants[0];
      variantOptionStatus = false;
    } else {
      variantOptionStatus = true;

      options.forEach(option => {
        variantOptionTypeOptionValuesMapping[option.name] = option.values.join(
          ','
        );
        selectedVariantOptionTypes.push(option.name);
      });

      variants.forEach(variant => {
        let variantNameArray = [];
        let variantName = '';
        selectedVariantOptionTypes.forEach(optionType => {
          if (variant.variantNameValue[optionType]) {
            variantNameArray.push(variant.variantNameValue[optionType]);
          }
        });
        variantName = variantNameArray.join(VARIANT_COMBO_DELIMITER);
        delete variant.variantNameValue;
        generatedVariantsObject[variantName] = variant;
        //Temporary fix for missing variant image
        if (!generatedVariantsObject[variantName].imageIds) {
          generatedVariantsObject[variantName].imageIds = [];
        }
      });
    }

    variantStorage = generatedVariantsObject;

    this.setState({
      defaultVariant,
      variantOptionStatus,
      variantOptionTypeOptionValuesMapping,
      generatedVariantsObject,
      variantStorage
    });
  };

  pushFetchedCardsDataToCardsStorage = cardElements => {
    let state = {};
    state.features = this.state.features;
    state.howitworks = this.state.howitworks;
    cardElements.forEach(card => {
      card.heading = card.heading.toLowerCase().replace(/\s/g, '');

      state[card.heading] = card.content;
    });

    state.descriptionData = state.howitworks.text;
    this.setState(state);
  };

  pushNewMediaElementToMediaStorage = (
    mediaUrl,
    sourceName,
    fileType,
    fileId,
    receivedMediaId
  ) => {
    let { mediaStorage, primaryImage, variantStorage } = this.state;
    let mediaId = Date.now().toString();

    if (fileType.includes('image')) {
      let primaryImageStatus = false;

      primaryImageStatus = isEmpty(Object.keys(mediaStorage.images));

      /* Considering first uploaded image as primary image */
      if (fileId.includes('variant')) {
        mediaStorage.images[receivedMediaId] = {
          variantIds: [variantStorage[sourceName].id],
          mediaSrc: mediaUrl,
          primary: primaryImageStatus
        };

        if (primaryImageStatus) {
          primaryImage.id = mediaId;
          primaryImage.mediaSrc = mediaUrl;
          primaryImage.variantIds = [variantStorage[sourceName].id];
        }
      } else {
        mediaStorage.images[mediaId] = {
          variantIds: [],
          mediaSrc: mediaUrl,
          primary: primaryImageStatus
        };

        if (primaryImageStatus) {
          primaryImage.id = mediaId;
          primaryImage.mediaSrc = mediaUrl;
          primaryImage.variantIds = [];
        }
      }
    } else if (fileType.includes('video')) {
      let primaryVideoStatus = false;

      if (fileId === 'variant') {
        mediaStorage.videos[receivedMediaId] = {
          variantIds: [variantStorage[sourceName].id],
          mediaSrc: mediaUrl,
          primary: false
        };
      } else {
        primaryVideoStatus = isEmpty(Object.keys(mediaStorage.images));
        mediaStorage.videos[mediaId] = {
          variantIds: [],
          mediaSrc: mediaUrl,
          primary: primaryVideoStatus
        };
      }
    } else {
      toaster('Invalid file type');
    }

    this.setState(
      { mediaStorage, primaryImage, isLoadingMediaStorageImages: false },
      () => {
        toaster('Media Uploaded');
      }
    );
  };

  deleteMedia = (mediaId, mediaType) => {
    let { mediaStorage, primaryImage, variantStorage } = this.state;
    if (!isEmpty(mediaStorage[mediaType][mediaId].variantIds)) {
      variantStorage[
        this.fetchVariantNameFromVariantId(
          mediaStorage[mediaType][mediaId].variantIds[0]
        )
      ].imageIds.splice(0, 1);
    }

    if (mediaStorage[mediaType][mediaId].primary) {
      delete mediaStorage[mediaType][mediaId];
      if (!isEmpty(mediaStorage[mediaType])) {
        mediaStorage[mediaType][
          Object.keys(mediaStorage[mediaType])[0]
        ].primary = true;
        primaryImage.id = Object.keys(mediaStorage[mediaType])[0];

        primaryImage.mediaSrc =
          mediaStorage[mediaType][
            Object.keys(mediaStorage[mediaType])[0]
          ].mediaSrc;
        primaryImage.variantIds =
          mediaStorage[mediaType][
            Object.keys(mediaStorage[mediaType])[0]
          ].variantIds;
      } else {
        primaryImage = {};
      }
    } else {
      delete mediaStorage[mediaType][mediaId];
    }

    this.setState({
      mediaStorage,
      primaryImage,
      variantStorage
    });
  };

  fetchVariantNameFromVariantId = variantId => {
    const { variantStorage } = this.state;

    for (let i = 0; i < Object.keys(variantStorage).length; i++) {
      if (variantStorage[Object.keys(variantStorage)[i]].id === variantId)
        return Object.keys(variantStorage)[i];
    }
    return null;
  };

  changePrimaryImage = mediaId => {
    let { mediaStorage, primaryImage } = this.state;

    mediaStorage.images[primaryImage.id].primary = false;

    primaryImage.id = mediaId;
    primaryImage.mediaSrc = mediaStorage.images[mediaId].mediaSrc;
    primaryImage.variantIds = mediaStorage.images[mediaId].variantIds;

    mediaStorage.images[mediaId].primary = true;

    this.setState({ mediaStorage, primaryImage });
  };

  returnMediaExcludingPrimary = mediaType => {
    let { mediaStorage } = this.state;
    let imagesArray = Object.keys(mediaStorage[mediaType]).filter(mediaId => {
      return !mediaStorage[mediaType][mediaId].primary;
    });

    return imagesArray;
  };

  addDescription = () => {
    this.setState({ descriptionData: [...this.state.descriptionData, ''] });
  };

  storeVariantsToVariantStorage = (
    updatedVariants,
    selectedVariantOptionTypes,
    variantOptionTypeOptionValuesMapping
  ) => {
    this.setState({
      variantStorage: updatedVariants,
      selectedVariantOptionTypes,
      variantOptionTypeOptionValuesMapping
    });
  };

  structureProductMedia = () => {
    let { mediaStorage, primaryImage, primaryVideo } = this.state;

    let structuredMediaData = {
      primaryImageSrc: {},
      secondaryImageSrc: [],
      primaryVideoSrc: {}
    };

    structuredMediaData.primaryImageSrc.id = primaryImage.id;
    structuredMediaData.primaryImageSrc.imageSrc = primaryImage.mediaSrc;
    structuredMediaData.primaryImageSrc.variantIds =
      primaryImage.variantIds || [];

    structuredMediaData.secondaryImageSrc = [];
    this.returnMediaExcludingPrimary('images').forEach(mediaId => {
      structuredMediaData.secondaryImageSrc.push({
        id: mediaId,
        imageSrc: mediaStorage.images[mediaId].mediaSrc,
        variantIds: mediaStorage.images[mediaId].variantIds
      });
    });

    if (!isEmpty(primaryVideo)) {
      structuredMediaData.primaryVideoSrc.id = primaryVideo.mediaId;
      structuredMediaData.primaryVideoSrc.videoSrc = primaryVideo.mediaSrc;
      structuredMediaData.primaryVideoSrc.variantIds =
        primaryVideo.variantIds || [];
    } else {
      structuredMediaData.primaryVideoSrc = {};
    }

    return structuredMediaData;
  };

  handleKeyUp = e => {
    let state = this.state;
    let textInputRenderFormatCardsList = [
      'features',
      'technicalspecifications',
      'details'
    ];
    if (
      textInputRenderFormatCardsList.includes(e.target.name) &&
      e.key === 'Enter'
    ) {
      e.preventDefault();

      let cardsTextIndex = parseInt(
        e.target.id.replace(`${e.target.name}-`, '')
      );

      if (cardsTextIndex === state[e.target.name].points.length - 1) {
        state[e.target.name].points.push('');
      }
    }

    this.setState(state);
  };

  structureCardField = () => {
    const {
      testimony,
      features,
      howitworks,
      faq,
      details,
      technicalspecifications
    } = this.state;

    let cardDetails = [];
    if (testimony.text) {
      cardDetails.push({
        heading: 'Testimony',
        content: { text: testimony.text },
        id: '13',
        type: 'text'
      });
    }

    if (!isEmpty(features.points[0])) {
      cardDetails.push({
        heading: 'Features',
        content: { points: features.points },
        id: '16',
        type: 'text'
      });
    }

    if (!isEmpty(this.state.descriptionData) || howitworks.mediaUrl) {
      howitworks.text = this.state.descriptionData;
      cardDetails.push({
        content: howitworks,
        heading: 'How It Works',
        id: '14',
        type: 'text'
      });
    }

    if (!isEmpty(details.points[0])) {
      cardDetails.push({
        heading: 'Details',
        content: { points: details.points },
        id: '16',
        type: 'text'
      });
    }

    if (!isEmpty(technicalspecifications.points[0])) {
      cardDetails.push({
        heading: 'Technical Specifications',
        content: { points: technicalspecifications.points },
        id: '',
        type: 'text'
      });
    }

    if (!isEmpty(faq)) {
      cardDetails.push({ heading: 'FAQ', content: faq, id: '', type: 'faq' });
    }

    return cardDetails;
  };

  handleInputDescriptionChange = (e, index) => {
    let { descriptionData } = this.state;
    descriptionData[index] = e.target.value;
    this.setState({ descriptionData: descriptionData });
  };

  handleRemove = index => {
    let { descriptionData } = this.state;
    descriptionData.splice(index, 1);
    this.setState({ descriptionData: descriptionData });
  };

  storeHowItWorksMedia = (mediaUrl, mediaType) => {
    let { howitworks } = this.state;

    howitworks.mediaUrl = mediaUrl;
    howitworks.type = mediaType;

    this.setState({ howitworks });
  };

  deleteHowItWorksMedia = () => {
    let { howitworks } = this.state;

    howitworks.mediaUrl = '';
    howitworks.type = '';
    this.setState({ howitworks });
  };
  saveFAQElement = mode => {
    var { faq, faqQuestion, faqAnswer } = this.state;
    if (faqQuestion === '' || faqAnswer === '') {
      if (!(mode === 'saveInputFAQ')) {
        toaster('FAQ text cannot be empty');
      }
    } else {
      faq.push({ question: faqQuestion, answer: faqAnswer });
      this.setState({
        faq: faq,
        faqQuestion: '',
        faqAnswer: ''
      });
    }
  };

  deleteFAQElement = index => {
    let { faq } = this.state;
    faq.splice(index, 1);
    this.setState({ faq: faq });
  };

  structureVariantStorageForRequestBody = () => {
    const { variantStorage, selectedVariantOptionTypes } = this.state;

    let variantRequestBodyField = [];

    Object.keys(variantStorage).forEach(variantName => {
      let variantNameValue = {};

      selectedVariantOptionTypes.forEach((optionType, index) => {
        variantNameValue[optionType.toLowerCase()] = variantName.split(
          VARIANT_COMBO_DELIMITER
        )[index];
      });

      variantStorage[variantName].variantNameValue = variantNameValue;
      variantStorage[variantName].quantity = variantStorage[variantName]
        .quantity
        ? parseInt(variantStorage[variantName].quantity)
        : 0;
      variantRequestBodyField.push(variantStorage[variantName]);
    });

    return variantRequestBodyField;
  };

  returnStructuredRequestBodyForNewProduct = () => {
    let {
      title,
      description,
      category,
      defaultVariant,
      variantOptionStatus,
      variantOptionTypeOptionValuesMapping,
      tagsString
    } = this.state;

    let structuredMediaData = this.structureProductMedia();
    let postRequestBody = {};

    postRequestBody.id = uuidv4();
    postRequestBody.publishedAt = new Date().getTime();
    postRequestBody.currency = 'USD';
    const { vendors } = JSON.parse(localStorage.getItem('userProfile')) || {
      vendor: []
    };
    postRequestBody.catalogId = !isEmpty(vendors) ? vendors[0].id : '';

    postRequestBody.sourceName = DOMAIN;

    if (!title) {
      toaster('Please add title for the product');
      return null;
    }
    postRequestBody.title = title;

    postRequestBody.description = description || '';
    if (!isEmpty(category)) {
      postRequestBody.productType = category;
    }
    postRequestBody.primaryImageSrc = structuredMediaData.primaryImageSrc;
    postRequestBody.secondaryImageSrc = structuredMediaData.secondaryImageSrc;
    postRequestBody.primaryVideoSrc = structuredMediaData.primaryVideoSrc;

    if (variantOptionStatus) {
      postRequestBody.variants = this.structureVariantStorageForRequestBody();
      postRequestBody.options = [];

      Object.keys(variantOptionTypeOptionValuesMapping).forEach(optionType => {
        let optionsFieldObjectElement = {};

        optionsFieldObjectElement.name = optionType;
        optionsFieldObjectElement.values = variantOptionTypeOptionValuesMapping[
          optionType
        ].split(',');

        optionsFieldObjectElement.values = optionsFieldObjectElement.values.filter(
          value => {
            return !isEmpty(value) || value !== ',';
          }
        );

        postRequestBody.options.push(optionsFieldObjectElement);
      });
    } else {
      defaultVariant.quantity = parseInt(defaultVariant.quantity) || 0;
      postRequestBody.variants = [defaultVariant];
      postRequestBody.options = [{ name: 'title', values: ['Default Title'] }];
    }

    let structuredCardField = this.structureCardField() || [];
    if (!isEmpty(structuredCardField)) {
      postRequestBody.cards = structuredCardField;
    }

    postRequestBody.tags = tagsString.replace(/#/g, '').split(',');

    return postRequestBody;
  };

  returnStructuredRequestBodyForProductEdits = () => {
    const {
      productId,
      fetchedProductDetails,
      title,
      currency,
      description,
      category,
      defaultVariant,
      variantOptionStatus,
      variantOptionTypeOptionValuesMapping,
      tagsString
    } = this.state;

    let structuredMediaData = this.structureProductMedia();
    let postRequestBody = {};

    postRequestBody.id = productId;
    if (fetchedProductDetails.sourceId) {
      postRequestBody.sourceId = fetchedProductDetails.sourceId;
    }
    postRequestBody.publishedAt = fetchedProductDetails.publishedAt;
    postRequestBody.handle = fetchedProductDetails.handle
      ? fetchedProductDetails.handle
      : '';
    postRequestBody.currency = currency;
    postRequestBody.catalogId = fetchedProductDetails.catalogId
      ? fetchedProductDetails.catalogId
      : '';
    postRequestBody.store_name = fetchedProductDetails.store_name
      ? fetchedProductDetails.store_name
      : '';
    postRequestBody.sourceName = fetchedProductDetails.sourceName
      ? fetchedProductDetails.sourceName
      : '';
    postRequestBody.title = title ? title : toaster('Please enter a Title');
    postRequestBody.description = description || '';
    if (!isEmpty(category)) {
      postRequestBody.productType = category;
    }
    postRequestBody.primaryImageSrc = structuredMediaData.primaryImageSrc;
    postRequestBody.secondaryImageSrc = structuredMediaData.secondaryImageSrc;
    postRequestBody.primaryVideoSrc = structuredMediaData.primaryVideoSrc;

    if (variantOptionStatus) {
      postRequestBody.variants = this.structureVariantStorageForRequestBody();
      postRequestBody.options = [];

      Object.keys(variantOptionTypeOptionValuesMapping).forEach(optionType => {
        let optionsFieldObjectElement = {};

        optionsFieldObjectElement.name = optionType;
        optionsFieldObjectElement.values = variantOptionTypeOptionValuesMapping[
          optionType
        ].split(',');

        optionsFieldObjectElement.values = optionsFieldObjectElement.values.filter(
          value => {
            return !isEmpty(value) || value !== ',';
          }
        );

        postRequestBody.options.push(optionsFieldObjectElement);
      });
    } else {
      defaultVariant.quantity = parseInt(defaultVariant.quantity) || 0;
      postRequestBody.variants = [defaultVariant];
      postRequestBody.options = [{ name: 'title', values: ['Default Title'] }];
    }

    let structuredCardField = this.structureCardField() || [];
    if (!isEmpty(structuredCardField)) {
      postRequestBody.cards = structuredCardField;
    }

    postRequestBody.tags = tagsString.replace(/#/g, '').split(',');

    return postRequestBody;
  };

  saveProductData = () => {
    this.saveFAQElement('saveInputFAQ');
    const { mode, productId, userId } = this.state;

    let requestBody = {};

    requestBody =
      mode === 'Create'
        ? this.returnStructuredRequestBodyForNewProduct()
        : this.returnStructuredRequestBodyForProductEdits();

    if (!requestBody) {
      toaster('Product could not be saved');
      return;
    }

    const token = getAuthToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token
    };

    const saveProductDataPostUrl =
      mode === 'Create'
        ? `${API_BASE_URL}/command?type=addProducts&source=manual`
        : `${API_BASE_URL}/update-product`;

    this.setState({ disableSaveButton: true });
    post(saveProductDataPostUrl, requestBody, headers).then(result =>
      this.setState(
        {
          loading: false,
          response: result.data
        },
        () => {
          toaster('Details Saved');
          this.setState({ disableSaveButton: false });
          if (mode === 'Create') {
            history.push('/dashboard');
          } else {
            const productUrl = `${APP_BASE_URL}/view-product/${productId}`;
            history.push(productUrl.replace(APP_BASE_URL, ''));
          }
        }
      )
    );
  };

  render() {
    const {
      mode,
      isLoading,
      productSource,
      mediaStorage,
      variantStorage,
      variantOptionStatus,
      generatedVariantsObject,
      variantOptionTypeOptionValuesMapping,
      defaultVariant,
      primaryImage,
      testimony,
      howitworks,
      descriptionData,
      features,
      technicalspecifications,
      details,
      title,
      description,
      category,
      tagsString,
      faqQuestion,
      faqAnswer,
      faq,
      disableSaveButton
    } = this.state;

    return (
      <Container>
        {!isLoading ? (
          <InnerContainer>
            <ProductContainer>
              <Heading>{`${mode} Product`}</Heading>

              <MediaContainer
                mediaStorage={mediaStorage}
                primaryImage={primaryImage}
                pushNewMediaElementToMediaStorage={
                  this.pushNewMediaElementToMediaStorage
                }
                deleteMedia={this.deleteMedia}
                changePrimaryImage={this.changePrimaryImage}
              />

              <EditPrimaryDetails>
                <SubHeading>Add Details</SubHeading>

                <AddTitleContainer>
                  <MiniHeading>Add Title</MiniHeading>
                  <TextInput
                    name="title"
                    id="title"
                    value={title}
                    size="edit-detail-size"
                    placeHolder="Title"
                    onChange={this.updateContentHandler}
                  />
                </AddTitleContainer>
                <AddDescriptionContainer>
                  <MiniHeading>Add Description</MiniHeading>
                  <TextInput
                    name="description"
                    id="description"
                    value={description}
                    size="edit-detail-size"
                    placeHolder="Description"
                    onChange={this.updateContentHandler}
                  />
                </AddDescriptionContainer>
                <AddCategoryContainer>
                  <TextInput
                    name="category"
                    id="category"
                    onChange={this.updateContentHandler}
                    value={category}
                    placeHolder="Add Category"
                    size="edit-detail-size"
                  />
                </AddCategoryContainer>
              </EditPrimaryDetails>

              <EditPricingContainer>
                <SubHeading>Add Pricing</SubHeading>
                <TextInput
                  value={defaultVariant.discountedPrice}
                  placeHolder="Price"
                  name="discountedPrice"
                  id="defaultDiscountedPrice"
                  size="edit-detail-size"
                  onChange={e => {
                    if (productSource === 'SHOPTYPE') {
                      this.updateContentHandler(e);
                    }
                  }}
                />
                <TextInput
                  value={defaultVariant.price}
                  placeHolder="Add compare at price"
                  size="edit-detail-size"
                  name="price"
                  id="defaultPrice"
                  onChange={e => {
                    if (productSource === 'SHOPTYPE') {
                      this.updateContentHandler(e);
                    }
                  }}
                />
              </EditPricingContainer>

              <EditInventoryContainer>
                <SubHeading>Add Inventory</SubHeading>
                <TextInput
                  value={defaultVariant.sku}
                  placeHolder="SKU (Stock Keeping Unit)"
                  size="edit-detail-size"
                  id="defaultSku"
                  name="sku"
                  onChange={e => {
                    if (productSource === 'SHOPTYPE') {
                      this.updateContentHandler(e);
                    }
                  }}
                />
                <TextInput
                  value={defaultVariant.quantity}
                  placeHolder="Total Quantity"
                  size="edit-detail-size"
                  id="defaultQuantity"
                  name="quantity"
                  onChange={e => {
                    if (productSource === 'SHOPTYPE') {
                      this.updateContentHandler(e);
                    }
                  }}
                />
              </EditInventoryContainer>

              <VariantsContainer
                mode={mode}
                productSource={productSource}
                variantOptionStatus={variantOptionStatus}
                toggleVariantsOptionStatus={this.toggleVariantsOptionStatus}
                variantOptionTypeOptionValuesMapping={
                  variantOptionTypeOptionValuesMapping
                }
                generatedVariantsObject={generatedVariantsObject}
                variantStorage={variantStorage}
                storeVariantsToVariantStorage={
                  this.storeVariantsToVariantStorage
                }
                mediaStorage={mediaStorage}
                pushNewMediaElementToMediaStorage={
                  this.pushNewMediaElementToMediaStorage
                }
                primaryImage={primaryImage}
                deleteMedia={this.deleteMedia}
                changePrimaryImage={this.changePrimaryImage}
              />

              <EditAddTagsContainer>
                <TagsHeadingInfoContainer>
                  <SubHeading>Add Tags</SubHeading>
                  <AddTagsInfo>(You can add upto 30 filters)</AddTagsInfo>
                </TagsHeadingInfoContainer>
                <TextInput
                  size="edit-detail-size"
                  value={tagsString}
                  placeHolder="#makeup     #skincare"
                  name="tagsString"
                  id="tagsString"
                  onChange={this.updateContentHandler}
                />
              </EditAddTagsContainer>

              <ProductCards
                updateContentHandler={this.updateContentHandler}
                setCardDetails={this.setCardDetails}
                deleteFAQElement={this.deleteFAQElement}
                handleKeyUp={this.handleKeyUp}
                testimony={testimony}
                howitworks={howitworks}
                handleInputDescriptionChange={this.handleInputDescriptionChange}
                addDescription={this.addDescription}
                handleRemove={this.handleRemove}
                deleteHowItWorksMedia={this.deleteHowItWorksMedia}
                descriptionData={descriptionData}
                storeHowItWorksMedia={this.storeHowItWorksMedia}
                features={features}
                technicalspecifications={technicalspecifications}
                details={details}
                faqQuestion={faqQuestion}
                faqAnswer={faqAnswer}
                faq={faq}
                saveFAQElement={this.saveFAQElement}
              />

              <SaveDetails>
                <Filled
                  text="Save"
                  backgroundColor="black"
                  textColor="white"
                  onClick={this.saveProductData}
                  disabled={disableSaveButton}
                />
              </SaveDetails>
            </ProductContainer>
          </InnerContainer>
        ) : (
          <Loader
            message="Loading Product Details..."
            isFlex={true}
            w={'100%'}
            isCenter={true}
            min_h={'100vh'}
          />
        )}
      </Container>
    );
  }
}

ProductMasterTemplate.propTypes = {
  match: PropTypes.object,
  mode: PropTypes.string
};

const mapStateToProps = state => {
  return {
    data: state
  };
};
const mapDispatchToProps = dispatch => {
  return { ...bindActionCreators({ ...createProductActions }, dispatch) };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductMasterTemplate)
);
