import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const InnerContainer = styled.div`
  color: ${props => props.theme.MINE_SHAFT};
  display: block;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const ProductsContainer = styled.div`
  padding: 24px 48px;
`;

const FilterContainer = styled.div``;

const SelectVendor = styled.div`
  width: 100%;
  max-width: 300px;
`;

const CatalogContainer = styled.div`
  margin-top: 50px;
`;

const AcceptInvite = styled.div`
  min-height: 100vh;
  text-align: center;
`;

const NoInvites = styled.div`
  min-height: 100vh;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  text-align: center;
`;

const ReferWithOutInvite = styled(NoInvites)`
  min-height: unset;
`;

const SelectVendorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Refer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export {
  Container,
  InnerContainer,
  HeadingSection,
  Heading,
  ProductsContainer,
  FilterContainer,
  SelectVendor,
  CatalogContainer,
  AcceptInvite,
  NoInvites,
  SelectVendorContainer
};
