import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const TabContent = styled.div`
  padding-top: 20px;
`;

export { Container, HeadingSection, Heading, TabContent };
