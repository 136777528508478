import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import NewKeyDialog from './dialogs/newKeyDialog';
import EditKeyDialog from './dialogs/editKeyDialog';
import DeleteKeyDialog from './dialogs/deleteKeyDialog';
import ViewKeyDialog from './dialogs/viewKeyDialog';
import { withRouter } from 'react-router-dom';
import Loader from '../../atoms/loader';
import { IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import MaterialButton from '../../atoms/materialButton';
import { API_BASE_URL } from '../../../config/env';
import { toaster } from '../../atoms/toaster';
import * as actions from '../../../redux/actions/uiActions';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  deleteWithResponseObject
} from '../../../utils';
import {
  Container,
  Heading,
  KeysContainer,
  ApiKeyRow,
  ApiKeyItem,
  ApiKeyActions,
  Row,
  ErrorMessageContainer
} from './styles';

const TextInput = ({ label, value }) => {
  return (
    <TextField
      label={label}
      defaultValue={value}
      variant="outlined"
      size="small"
      InputProps={{
        readOnly: true
      }}
    />
  );
};

const ApiKeys = ({ showSpinnerBackdrop, hideSpinnerBackdrop }) => {
  const [apiKeysData, setApiKeysData] = useState(null);
  const [scopeOptions, setScopeOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [openDialog, setOpenDialog] = useState('');
  const [deleteKeyId, setDeleteKeyId] = useState('');
  const [editKeyData, setEditKeyData] = useState({
    id: '',
    name: '',
    domain: '',
    scope: []
  });

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = () => {
    fetchScopeOptions();
    fetchApiKeys();
  };

  const fetchScopeOptions = async () => {
    const token = getAuthToken();
    const headers = {
      'Content-type': 'application/json',
      authorization: token
    };

    const res = await getApiResponseObject(
      `${API_BASE_URL}/api-keys/scopes`,
      headers
    );

    if (successStatusCodes.includes(res.status) && !isEmpty(res.data)) {
      setScopeOptions(res.data);
    } else {
      setDisableAdd(true);
      toaster('Cannot fetch scope options');
    }
  };

  const fetchApiKeys = async () => {
    const token = getAuthToken();
    const headers = {
      'Content-type': 'application/json',
      authorization: token
    };

    const res = await getApiResponseObject(`${API_BASE_URL}/api-keys`, headers);

    if (successStatusCodes.includes(res.status) && !isEmpty(res.data)) {
      setApiKeysData(res.data);
      setIsLoading(false);
      setDataAvailable(true);
    } else {
      setIsLoading(false);
      setDataAvailable(false);
    }
  };

  const handleOpenDialog = (dialog, data = null) => {
    setOpenDialog(dialog);

    if (dialog === 'edit-key') {
      setEditKeyData({
        id: data.id,
        name: data.name,
        domain: data.allowed_domain,
        scope: data.scopes
      });
    }

    if (dialog === 'delete-key') {
      setDeleteKeyId(data);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog('');
  };

  const getUpdatedApiKeys = () => {
    setApiKeysData(null);
    setIsLoading(true);
    setDataAvailable(false);
    fetchApiKeys();
  };

  const deleteApiKey = async () => {
    const token = getAuthToken();
    const headers = {
      'Content-type': 'application/json',
      authorization: token
    };

    showSpinnerBackdrop();
    const res = await deleteWithResponseObject(
      `${API_BASE_URL}/api-keys/${deleteKeyId}`,
      headers
    );
    hideSpinnerBackdrop();

    if (successStatusCodes.includes(res.status)) {
      toaster(res.data.message || 'Api Key Deleted');
      getUpdatedApiKeys();
    } else {
      toaster(res.data.message || 'Cannot delete Api Key');
    }
  };

  return (
    <>
      {!isLoading && !isEmpty(scopeOptions) && (
        <NewKeyDialog
          open={openDialog === 'new-key'}
          handleCloseDialog={handleCloseDialog}
          scopeOptions={scopeOptions}
          setSecretKey={setSecretKey}
          handleOpenDialog={handleOpenDialog}
          showSpinnerBackdrop={showSpinnerBackdrop}
          hideSpinnerBackdrop={hideSpinnerBackdrop}
        />
      )}
      <ViewKeyDialog
        open={openDialog === 'view-key'}
        handleCloseDialog={handleCloseDialog}
        secretKey={secretKey}
        getUpdatedApiKeys={getUpdatedApiKeys}
      />
      {!isLoading &&
      dataAvailable &&
      !isEmpty(scopeOptions) &&
      !isEmpty(apiKeysData) ? (
        <>
          <EditKeyDialog
            open={openDialog === 'edit-key'}
            handleCloseDialog={handleCloseDialog}
            scopeOptions={scopeOptions}
            editKeyData={editKeyData}
            handleOpenDialog={handleOpenDialog}
            showSpinnerBackdrop={showSpinnerBackdrop}
            hideSpinnerBackdrop={hideSpinnerBackdrop}
            getUpdatedApiKeys={getUpdatedApiKeys}
          />
          <DeleteKeyDialog
            open={openDialog === 'delete-key'}
            handleCloseDialog={handleCloseDialog}
            deleteApiKey={deleteApiKey}
          />
          <Container>
            <Heading>API Keys</Heading>
            <KeysContainer>
              {apiKeysData.map((data, index) => (
                <ApiKeyRow key={index}>
                  <ApiKeyItem>
                    <TextInput label="Name" value={data.name || ''} />
                  </ApiKeyItem>
                  <ApiKeyItem>
                    <TextInput
                      label="API Key (Last Four Digits)"
                      value={data.last_4 ? `******${data.last_4}` : ''}
                    />
                  </ApiKeyItem>
                  <ApiKeyItem>
                    <TextInput
                      label="Domain"
                      value={data.allowed_domain || ''}
                    />
                  </ApiKeyItem>
                  <ApiKeyItem>
                    <TextInput
                      label="Scope"
                      value={
                        !isEmpty(data.scopes) ? data.scopes.join(', ') : ''
                      }
                    />
                  </ApiKeyItem>
                  <ApiKeyItem>
                    <TextInput
                      label="Created At"
                      value={moment(data.createdAt).format(
                        'DD[-]MMMM[-]YYYY h:mm A'
                      )}
                    />
                  </ApiKeyItem>
                  <ApiKeyActions>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleOpenDialog('edit-key', data)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenDialog('delete-key', data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ApiKeyActions>
                </ApiKeyRow>
              ))}
            </KeysContainer>
            <Row>
              <MaterialButton
                label="Add Key"
                disabled={disableAdd}
                onClick={() => handleOpenDialog('new-key')}
              />
            </Row>
          </Container>
        </>
      ) : !isLoading && !dataAvailable ? (
        <Container>
          <Heading>API Keys</Heading>
          <ErrorMessageContainer>API keys unavailable.</ErrorMessageContainer>
          <Row>
            <MaterialButton
              label="Add Key"
              disabled={disableAdd}
              onClick={() => handleOpenDialog('new-key')}
            />
          </Row>
        </Container>
      ) : (
        isLoading && (
          <Loader
            message="Loading..."
            isFlex={true}
            w={'100%'}
            isCenter={true}
            min_h={'100vh'}
          />
        )
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ApiKeys));
