import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckBoxContainer,
  CheckBoxInput,
  CheckMark,
  CheckBoxLabel,
  LabelText
} from './styles';

const CheckBox = (
  {
    id,
    name,
    value,
    onChange,
    label,
    backgroundColor,
    color,
    marginTop,
    height,
    fontSize,
    fontFamily,
    innerJSX,
    disabled
  } = this.props
) => (
  <CheckBoxContainer color={color} marginTop={marginTop} height={height}>
    <CheckBoxLabel id={id}>
      <CheckBoxInput
        id={id}
        name={name}
        onChange={onChange}
        checked={value}
        disabled={disabled}
      />
      <CheckMark backgroundColor={backgroundColor} />
      <LabelText fontSize={fontSize} fontFamily={fontFamily}>
        {innerJSX ? innerJSX() : label}
      </LabelText>
    </CheckBoxLabel>
  </CheckBoxContainer>
);

CheckBox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string
};

export default CheckBox;
