import styled from 'styled-components';
import CollapsibleWithIcon from '../../../organisms/collapsibleWithIcon';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;
  text-align: center;
`;

const OrderDetailsContainer = styled(CollapsibleWithIcon)`
  margin-top: 40px;
  border-bottom: 1px solid black;
`;

const PlatformImage = styled.img`
  width: 16px;
  height: 16px;
`;

const Message = styled.div`
  margin: 8px 0;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
`;

const Section = styled.div`
  margin: 12px 0;
`;

const AccountCardWrapper = styled.div`
  width: 100%;
  margin: 24px 0;
  display: flex;
  flex-wrap: wrap;
`;

export {
  Container,
  HeadingSection,
  Heading,
  OrderDetailsContainer,
  ErrorMessageContainer,
  PlatformImage,
  Message,
  Section,
  AccountCardWrapper
};
