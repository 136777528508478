import React from 'react';
import { Link } from 'react-router-dom';

export const TermsOfService = () => {
  return (
    <React.Fragment>
      <h1>
        <strong>TERMS OF SERVICE</strong>
      </h1>
      <p>
        By signing up for a Shoptype Account (as defined in Section 1) or by
        using any Shoptype Services (as defined below), you are agreeing to be
        bound by the following terms and conditions (the &ldquo;Terms of
        Service&rdquo;).
      </p>
      <p>
        As used in these Terms of Service, &ldquo;we&rdquo;, &ldquo;us&rdquo;
        and &ldquo;Shoptype&rdquo; means the applicable Shoptype Contracting
        Party (as defined in Section 4 below).
      </p>
      <p>
        The services offered by Shoptype under the Terms of Service include
        various products and services to help you sell goods and services to
        buyers, whether online (&ldquo;Online Services&rdquo;), in person
        (&ldquo;POS Services&rdquo;), or both. Any such services offered by
        Shoptype are referred to in these Terms of Services as the
        &ldquo;Services&rdquo;. Any new features or tools which are added to the
        current Services shall be also subject to the Terms of Service. You can
        review the current version of the Terms of Service{' '}
        <Link target="_blank" to={'/policies/terms-of-service'}>
          here
        </Link>
        . Shoptype reserves the right to update and change the Terms of Service
        by posting updates and changes to the Shoptype website. You are advised
        to check the Terms of Service from time to time for any updates or
        changes that may impact you. and if you do not accept such amendments,
        you must cease using the Services.
      </p>
      <p>
        You must read, agree with, and accept all of the terms and conditions
        contained or expressly referenced in these Terms of Service, including
        Shoptype&rsquo;s{' '}
        <Link target="_blank" to={'/policies/acceptable-use-policy'}>
          <u>Acceptable Use Policy (&ldquo;AUP&rdquo;)</u>
        </Link>
        &nbsp;and{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>Privacy Policy</u>
        </Link>
        , and, if applicable, the Shoptype{' '}
        <u>API License and Terms of Use (&ldquo;API Terms&rdquo;)</u>&nbsp;and
        the Shoptype{' '}
        <Link target="_blank" to={'/policies/dpa'}>
          <u>Data Processing Addendum (&ldquo;DPA&rdquo;)</u>
        </Link>
        &nbsp;before you may sign up for a Shoptype Account or use any Shoptype
        Service. Additionally, if you offer goods or services in relation to
        COVID-19, you must read, acknowledge and agree to the{' '}
        <Link target="_blank" to={'/policies/covid19'}>
          <u>Rules of Engagement for Sale of COVID-19 Related Products</u>
        </Link>
        .
      </p>
      <h2>
        <strong>1. Account Terms</strong>
      </h2>
      <ol>
        <li>
          To access and use the Services, you must register for a Shoptype
          account (&ldquo;Account&rdquo;) by providing your full legal name,
          current address, phone number, a valid email address, and any other
          required information. Shoptype may reject your application for an
          Account, or cancel an existing Account, for any reason, in our sole
          discretion.
        </li>
        <li>
          You must be the older of: (i) 18 years, or (ii) at least the age of
          majority in the jurisdiction where you reside and from which you use
          the Services to open an Account.
        </li>
        <li>
          You confirm that you are receiving any Services provided by Shoptype
          for the purposes of carrying on a business activity and not for any
          personal, household, or family purpose.
        </li>
        <li>
          You acknowledge that Shoptype will use the email address you provide
          on opening an Account or as updated by you from time to time as the
          primary method for communication with you.
        </li>
        <li>
          You are responsible for keeping your password secure. Shoptype cannot
          and will not be liable for any loss or damage from your failure to
          maintain the security of your Account and password.
        </li>
        <li>
          You are responsible for all activity and content such as photos,
          images, videos, graphics, written content, audio files, code,
          information, or data uploaded, collected, generated, stored,
          displayed, distributed, transmitted, or exhibited on or in connection
          with your Account (&ldquo;Materials&rdquo;).
        </li>
        <li>
          A breach or violation of any term in the Terms of Service, including
          the AUP, as determined in the sole discretion of Shoptype may result
          in an immediate termination of your Services.
        </li>
      </ol>
      <h2>
        <strong>2. Account Activation</strong>
      </h2>
      <p>
        <strong>2.1 Store Owner</strong>
      </p>
      <ol>
        <li>
          Subject to section 2.1(2), the person signing up for the Service by
          opening an Account will be the contracting party (&ldquo;Store
          Owner&rdquo;) for the purposes of our Terms of Service and will be the
          person who is authorized to use any corresponding Account we may
          provide to the Store Owner in connection with the Service.
        </li>
        <li>
          If you are signing up for the Services on behalf of your employer,
          your employer shall be the Store Owner. If you are signing up for the
          Services on behalf of your employer, then you must use your
          employer-issued email address and you represent and warrant that you
          have the authority to bind your employer to our Terms of Service.
        </li>
        <li>
          Your Shoptype Store can only be associated with one Store Owner. A
          Store Owner may have multiple Shoptype Stores. &ldquo;Store&rdquo;
          means the online store or physical retail location(s) associated with
          the Account.
        </li>
      </ol>
      <p>
        <strong>2.2 Staff Accounts</strong>
      </p>
      <ol>
        <li>
          You can create one or more staff accounts (&ldquo;Staff
          Accounts&rdquo;) allowing other people to access the Account. With
          Staff Accounts, the Store Owner can set permissions and let other
          people work in their Account while determining the level of access by
          Staff Accounts to specific business information (for example, you can
          limit Staff Account access to sales information on the Reports page or
          prevent Staff Accounts from changing general store settings).
        </li>
        <li>
          The Store Owner is responsible and liable for the acts, omissions, and
          defaults arising from use of Staff Accounts in the performance of
          obligations under these Terms of Service as if they were the Store
          Owner&rsquo;s own acts, omissions, or defaults.
        </li>
        <li>
          The Store Owner and the users under Staff Accounts are each referred
          to as a &ldquo;Shoptype User&rdquo;.
        </li>
      </ol>
      <p>
        <strong>2.3 Payment Systems</strong>
      </p>
      <ol>
        <li>
          Upon completion of sign up for the Service, Shoptype will create a
          payment checkout account on your behalf, using your email address.
          Depending on your location, Shoptype may also create a Shoptype
          Payments account on your behalf.
        </li>
        <li>
          You acknowledge that the payment checkout method will be your default
          payment gateway(s) and that it is your sole responsibility as the
          Store Owner to activate and maintain these accounts. If you do not
          wish to keep either of the payment accounts active, as the Store Owner
          it is your responsibility to deactivate them. For the avoidance of
          doubt, the payment checkout method may be a Third Party Service, as
          defined in Section 17 of these Terms of Service.
        </li>
      </ol>
      <h2>
        <strong>3. General Conditions</strong>
      </h2>
      <p>
        You must read, agree with, and accept all of the terms and conditions
        contained in these Terms of Service, including the{' '}
        <Link target="_blank" to={'/policies/acceptable-use-policy'}>
          <u>AUP</u>
        </Link>
        &nbsp;and the{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>Privacy Policy</u>
        </Link>
        &nbsp;before you may become a Shoptype User. Additionally, you
        acknowledge and agree to the{' '}
        <Link target="_blank" to={'/policies/covid19'}>
          <u>Rules of Engagement for the Sale of COVID-19 Related Products</u>
        </Link>
        &nbsp;if you sell such products.
      </p>
      <ol>
        <li>
          Technical support in respect of the Services is only provided to
          Shoptype Users.
        </li>
        <li>
          The Terms of Service shall be governed by and interpreted in
          accordance with the laws of the State of California and the laws of
          the United States of America, without regard to principles of
          conflicts of laws. The United Nations Convention on Contracts for the
          International Sale of Goods will not apply to these Terms of Service
          and is hereby expressly excluded.
        </li>
        <li>
          You acknowledge and agree that Shoptype may amend these Terms of
          Service at any time by posting the relevant amended and restated Terms
          of Service on Shoptype&rsquo;s website, available{' '}
          <Link target="_blank" to={'/policies/terms-of-service'}>
            <u>here</u>
          </Link>
          and such amendments to the Terms of Service are effective as of the
          date of posting. Your continued use of the Services after the amended
          Terms of Service are posted to Shoptype&rsquo;s website constitutes
          your agreement to, and acceptance of, the amended Terms of Service. If
          you do not agree to any changes to the Terms of Service, do not
          continue to use the Service.
        </li>
        <li>
          You may not use the Shoptype Services for any illegal or unauthorized
          purpose nor may you, in the use of the Service, violate any laws in
          your jurisdiction (including but not limited to copyright laws), the
          laws applicable to you in your customer&rsquo;s jurisdiction, or the
          laws of the United States of America and the State of California. You
          will comply with all applicable laws, rules and regulations in your
          use of the Service and your performance of obligations under the Terms
          of Service.
        </li>
        <li>
          The Shoptype API License and Terms of Use at <u>API Terms</u> govern
          your access to and use of the Shoptype API (as defined therein). You
          are solely responsible for the activity that occurs using your API
          Credentials (as defined in the API Terms) and for keeping your API
          Credentials secure.
        </li>
        <li>
          You agree not to reproduce, duplicate, copy, sell, resell, or exploit
          any portion of the Service, use of the Services, or access to the
          Services without the express written permission by Shoptype.
        </li>
        <li>
          You shall not purchase search engine or other pay per click keywords
          (such as Google Ads), or domain names that use Shoptype or Shoptype
          trademarks and/or variations and misspellings thereof.
        </li>
        <li>
          Questions about the Terms of Service should be sent to{' '}
          <a href="mailto:support@shoptype.com">
            <u>Shoptype Support</u>
          </a>
          .
        </li>
        <li>
          You understand that your Materials (not including credit card
          information), may be transferred unencrypted and involve (a)
          transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
          Credit card information is always encrypted during transfer over
          networks.
        </li>
        <li>
          In the near future, the Services will allow you to send certain
          communications to your customers by short message service (SMS)
          messaging (for example, sending order confirmation notifications via
          SMS) (the "SMS Services"). You will only use the SMS Services in
          compliance with these Terms of Service, the SMS provider&rsquo;s
          Acceptable Use Policy, and the laws of the jurisdiction from which you
          send messages, and in which your messages are received.
        </li>
        <li>
          You acknowledge and agree that your use of the Services, including
          information transmitted to or stored by Shoptype, is governed by the
          SMS provider&rsquo;s privacy policy.
        </li>
        <li>
          The Terms of Service may be available in languages other than English.
          To the extent there are any inconsistencies or conflicts between these
          English Terms of Service and Shoptype&rsquo;s Terms of Service
          available in another language, the current English version of the
          Terms of Service{' '}
          <Link target="_blank" to={'/policies/terms-of-service'}>
            <u>here</u>
          </Link>
          will prevail.
        </li>
        <li>
          All the terms and provisions of the Terms of Service shall be binding
          upon and inure to the benefit of the parties to the Terms of Service
          and to their respective heirs, successors, permitted assigns, and
          legal representatives. Shoptype shall be permitted to assign these
          Terms of Service without notice to you or consent from you. You shall
          have no right to assign or otherwise transfer the Terms of Service, or
          any of your rights or obligations hereunder, to any third party
          without Shoptype&rsquo;s prior written consent, to be given or
          withheld in Shoptype&rsquo;s sole discretion.
        </li>
        <li>
          If any provision, or portion of the provision, in these Terms of
          Service is, for any reason, held to be invalid, illegal, or
          unenforceable in any respect, then such invalidity, illegality, or
          unenforceability will not affect any other provision (or the
          unaffected portion of the provision) of the Terms of Service, and the
          Terms of Service will be construed as if such invalid, illegal, or
          unenforceable provision, or portion of the provision, had never been
          contained within the Terms of Service.
        </li>
        <li>
          Sections 1, 3(2)-(5), 4, 6-8, 14-15, 17(9)-(11), 19, 21 and 22 will
          survive the termination or expiration of these Terms of Service.
        </li>
      </ol>
      <h2>
        <strong>4. Shoptype Contracting Party</strong>
      </h2>
      <ol>
        <li>
          If the billing address of your Store is located in the United States
          or Canada, this Section 4(1) applies to you:
          <br />
          &ldquo;Shoptype Contracting Party&rdquo; means Awake Market, Inc., a
          Delaware corporation, with offices located at 655 Oak Grove Ave, #329
          Menlo Park, California 94026.
          <br />
          b. The parties irrevocably and unconditionally submit to the exclusive
          jurisdiction of the courts of the State of California with respect to
          any dispute or claim arising out of or in connection with the Terms of
          Service. The United Nations Convention on Contracts for the
          International Sale of Goods will not apply to these Terms of Service
          and is hereby expressly excluded.
        </li>
        <li>
          If the billing address of your Store is located in{' '}
          <u>the Asia Pacific region</u> (including Australia, New Zealand,
          China, Japan, Singapore), this Section 4(2) applies to you:
          <br />
          &ldquo;Shoptype Contracting Party&rdquo; means Awake Market Singapore
          Pte. Ltd, a corporation formed under the laws of Singapore, with
          offices located at Singapore address TBD.
          <br />
          b. The parties irrevocably agree that the courts of Singapore are to
          have exclusive jurisdiction to settle any dispute which may arise out
          of or in connection with the Terms of Service and that accordingly any
          proceedings arising out of or in connection with the Terms of Service
          shall be brought in such courts. The United Nations Convention on
          Contracts for the International Sale of Goods will not apply to these
          Terms of Service and is hereby expressly excluded.
        </li>
        <li>
          If the billing address of your Store is located in <u>EMEA region</u>{' '}
          or other jurisdictions not listed in Section 4(1) or 4(2), including
          Europe and Russia, the Middle East, Africa, South America, Caribbean,
          or Mexico this Section 4(3) applies to you:
          <br />
          &ldquo;Shoptype Contracting Party&rdquo; means Awake Market Limited, a
          private company limited by shares, incorporated in Ireland under
          registration number TBD, with its registered offices located at
          Dublin, Ireland Address TBD&nbsp;and its website is at{' '}
          <a href="https://www.shoptype.com/">
            <u>https://www.Shoptype.com/</u>
          </a>
          .<br />
          b. You irrevocably and unconditionally agree to submit to the
          jurisdiction of Irish courts, which have jurisdiction to settle any
          disputes which may arise out of or in connection with the validity,
          effect, interpretation or performance of these Terms of Service and
          you similarly irrevocably and unconditionally waive any objection to
          any claim that any suit, action, or proceeding has been brought by
          Shoptype in any other jurisdiction. The United Nations Convention on
          Contracts for the International Sale of Goods will not apply to these
          Terms of Service and is hereby expressly excluded.
        </li>
      </ol>
      <h2>
        <strong>5. Shoptype Rights</strong>
      </h2>
      <ol>
        <li>
          We reserve the right to modify or terminate the Services for any
          reason, without notice at any time. Not all Services and features are
          available in every jurisdiction and we are under no obligation to make
          any Services or features available in any jurisdiction.
        </li>
        <li>
          We reserve the right to refuse service to anyone for any reason at any
          time.
        </li>
        <li>
          We may, but have no obligation to, remove Materials and suspend or
          terminate Accounts if we determine in our sole discretion that the
          goods or services offered via a Store, or the Materials uploaded or
          posted to a Store, violate our{' '}
          <Link target="_blank" to={'/policies/acceptable-use-policy'}>
            <u>Acceptable Use Policy (&ldquo;AUP&rdquo;)</u>
          </Link>
          or these Terms of Service.
        </li>
        <li>
          Verbal or written abuse of any kind (including threats of abuse or
          retribution) of any Shoptype customer, Shoptype employee, member, or
          officer will result in immediate Account termination.
        </li>
        <li>
          Shoptype does not pre-screen Materials and it is in our sole
          discretion to refuse or remove any Materials from the Service,
          including your Store.
        </li>
        <li>
          We reserve the right to provide our services to your competitors and
          make no promise of exclusivity in any particular market segment. You
          further acknowledge and agree that Shoptype employees and contractors
          may also be Shoptype customers/merchants and that they may compete
          with you, although they may not use your Confidential Information (as
          defined in Section 6) in doing so.
        </li>
        <li>
          In the event of a dispute regarding Account ownership, we reserve the
          right to request documentation to determine or confirm Account
          ownership. Documentation may include, but is not limited to, a scanned
          copy of your business license, government issued photo ID, the last
          four digits of the credit card on file, your status as an employee of
          an entity, etc.
        </li>
        <li>
          Shoptype retains the right to determine, in our sole judgment,
          rightful Account ownership and transfer an Account to the rightful
          Store Owner. If we are unable to reasonably determine the rightful
          Store Owner, without prejudice to our other rights and remedies,
          Shoptype reserves the right to temporarily disable an Account until
          resolution has been determined between the disputing parties.
        </li>
      </ol>
      <h2>
        <strong>6. Confidentiality</strong>
      </h2>
      <ol>
        <li>
          &ldquo;Confidential Information&rdquo; shall include, but shall not be
          limited to, any and all information associated with a party&rsquo;s
          business and not publicly known, including specific business
          information, technical processes and formulas, software, customer
          lists, prospective customer lists, names, addresses and other
          information regarding customers and prospective customers, product
          designs, sales, costs (including any relevant processing fees), price
          lists, and other unpublished financial information, business plans and
          marketing data, and any other confidential and proprietary
          information, whether or not marked as confidential or proprietary.
          Shoptype&rsquo;s Confidential Information includes all information
          that you receive relating to us, or to the Services, that is not known
          to the general public including information related to our security
          program and practices.
        </li>
        <li>
          Each party agrees to use the other party&rsquo;s Confidential
          Information solely as necessary for performing its obligations under
          these Terms of Service and in accordance with any other obligations in
          these Terms of Service including this Section 6. Each party agrees
          that it shall take all reasonable steps, at least substantially
          equivalent to the steps it takes to protect its own proprietary
          information, to prevent the duplication, disclosure or use of any such
          Confidential Information, other than (i) by or to its employees,
          agents and subcontractors who must have access to such Confidential
          Information to perform such party&rsquo;s obligations hereunder, who
          each shall treat such Confidential Information as provided herein, and
          who are each subject to obligations of confidentiality to such party
          that are at least as stringent as those contained herein; or (ii) as
          required by any law, regulation, or order of any court of proper
          jurisdiction over the parties and the subject matter contained in
          these Terms of Service, provided that, if legally permitted, the
          receiving party shall give the disclosing party prompt written notice
          and use commercially reasonable efforts to ensure that such disclosure
          is accorded confidential treatment. Confidential Information shall not
          include any information that the receiving party can prove: (A) was
          already in the public domain, or was already known by or in the
          possession of the receiving party, at the time of disclosure of such
          information; (B) is independently developed by the receiving party
          without use of or reference to the other party&rsquo;s Confidential
          Information, and without breaching any provisions of these Terms of
          Service; or (C) is thereafter rightly obtained by the receiving party
          from a source other than the disclosing party without breaching any
          provision of these Terms of Service.
        </li>
      </ol>
      <h2>
        <strong>7. Limitation of Liability</strong>
      </h2>
      <ol>
        <li>
          You expressly understand and agree that, to the extent permitted by
          applicable laws, Shoptype shall not be liable for any direct,
          indirect, incidental, special, consequential, or exemplary damages,
          including but not limited to, damages for loss of profits, goodwill,
          use, data, or other intangible losses resulting from the use of or
          inability to use the Service.
        </li>
        <li>
          To the extent permitted by applicable laws, in no event shall Shoptype
          or our suppliers be liable for lost profits or any special,
          incidental, or consequential damages arising out of or in connection
          with our site, our Services or these Terms of Service (however arising
          including negligence). You agree to indemnify and hold us and, as
          applicable, our parent, subsidiaries, affiliates, Shoptype partners,
          officers, directors, agents, employees, and suppliers harmless from
          any claim or demand, including reasonable attorneys&rsquo; fees, made
          by any third party due to or arising out of your breach of these Terms
          of Service or the documents it incorporates by reference (including
          the AUP), or your violation of any law or the rights of a third party.
        </li>
        <li>
          Shoptype does not manufacture, distribute, or provide any warranty or
          sell any of the products, services, information, or other materials
          purchased or obtained by you through the Services. &nbsp;
        </li>
        <li>
          Your use of the Services is at your sole risk. The Services are
          provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo;
          basis without any warranty or condition, express, implied, or
          statutory.
        </li>
        <li>
          Shoptype does not warrant that the Services will be uninterrupted,
          timely, secure, or error-free.
        </li>
        <li>
          Shoptype does not warrant that the results that may be obtained from
          the use of the Services will be accurate or reliable.
        </li>
        <li>
          Shoptype does not warrant that the quality of any products, services,
          information, or other materials purchased or obtained by you through
          the Services will meet your expectations, or that any errors in the
          Services will be corrected.
        </li>
      </ol>
      <h2>
        <strong>8. Waiver and Complete Agreement</strong>
      </h2>
      <p>
        The failure of Shoptype to exercise or enforce any right or provision of
        the Terms of Service shall not constitute a waiver of such right or
        provision. The Terms of Service, including the documents it incorporates
        by reference, constitute the entire agreement between you and Shoptype
        and govern your use of the Services and your Account, superseding any
        prior agreements between you and Shoptype (including, but not limited
        to, any prior versions of the Terms of Service).
      </p>
      <h2>
        <strong>9. Intellectual Property and Customer Content</strong>
      </h2>
      <ol>
        <li>
          We do not claim any intellectual property rights over the Materials
          you provide to the Shoptype Service. All Materials you upload remain
          yours. You can remove your Shoptype Store at any time by deleting your
          Account.
        </li>
        <li>
          By uploading Materials, you agree: (a) to allow other internet users
          to view the Materials you post publicly to your Store; (b) to allow
          Shoptype to store, and in the case of Materials you post publicly,
          display, and use your Materials; and (c) that Shoptype can, at any
          time, review and delete all the Materials submitted to its Service,
          although Shoptype is not obligated to do so.
        </li>
        <li>
          You retain ownership over all Materials that you upload to the Store;
          however, by making your Store public, you agree to allow others to
          view Materials that you post publicly to your Store. You are
          responsible for compliance of the Materials with any applicable laws
          or regulations.
        </li>
        <li>
          Shoptype shall have the non-exclusive right and license to use the
          names, trademarks, service marks, and logos associated with your Store
          to promote the Service.
        </li>
      </ol>
      <h2>
        <strong>10. POS Services</strong>
      </h2>
      <p>
        In addition to the terms applicable to Services generally, the following
        terms apply to your access and use of the POS Services. The POS Services
        include the Shoptype POS software (&ldquo;POS Software&rdquo;), the
        Shoptype POS website, located at /pos, programs, documentation, apps,
        tools, internet-based services and components, Shoptype&rsquo;s POS
        hardware (&ldquo;POS Equipment&rdquo;) and any updates thereto provided
        to you by Shoptype.
      </p>
      <ol>
        <li>
          Access to and use of the POS Services requires that you have an active
          and valid Account.
        </li>
        <li>
          If your POS Services are enabled with Shoptype Payments, you cannot
          concurrently use any other payment processing service. The payment
          gateway used for your POS Services must be the same as that used for
          your Online Services, if applicable.
        </li>
        <li>
          You can terminate your POS Services without terminating your Account
          or any other Services you subscribe to, and in such case, you will
          continue to be billed, and will pay for, the Services (other than the
          terminated POS Services).
        </li>
        <li>
          You agree to use the POS Services in accordance with all procedures
          that may be provided by Shoptype from time to time.
        </li>
        <li>
          While the POS Services use encryption technology, and the law
          generally prohibits third parties from monitoring transmission, we
          cannot guarantee security with respect to the connection to the POS
          Services, and we will not be liable for any unauthorized access to or
          use of data transmitted via the POS Services.
        </li>
        <li>
          POS Equipment is purchased, and not leased. Purchase of the POS
          Equipment is subject to the POS Equipment Agreement, located at{' '}
          <u>https://www.Shoptype.com/legal/notice</u>. Upon payment by you for
          the POS Equipment, and confirmation to you of shipment of the POS
          equipment, you will acquire ownership of and title to the hardware
          components of the POS Equipment, and you will be licensed to access
          and use all POS Software installed in the POS Equipment (and any
          updates thereto), on a limited, non-exclusive, revocable,
          non-sublicensable, non-transferable basis, solely for the purposes of
          using the POS Services. POS Equipment is subject to our Return Policy,
          which can be found at{' '}
          <Link target="_blank" to={'/policies/return-policy'}>
            <u>https://Shoptype.com/policies/return-policy</u>
          </Link>
          . Returns and refunds are not available for the Chip &amp; Swipe
          Reader, Swipe (Audio Jack) and physical gift cards purchased from the
          Shoptype Hardware Store.
        </li>
        <li>
          The Fees for POS Services shall be determined based on the number of
          locations at which you are using the POS Services. Locations are
          defined by business address and are used for associating orders with a
          specific business address. Locations are added through the Shoptype
          web administrative console associated with your Account. The number of
          locations using POS Services will also be detected by us and billed
          accordingly as part of your regular billing cycle. In the event of any
          discrepancy between the number of locations added by you through the
          Shoptype web administrative console and the number of locations
          detected by Shoptype, the number of locations detected by Shoptype
          shall be deemed to be correct.
        </li>
      </ol>
      <h2>
        <strong>11. Shoptype Shipping</strong>
      </h2>
      <p>
        Fulfillment, shipping, and liability for any and all purchases is
        provided by and the responsibility of the Shoptype Store and Store
        Owner, not Shoptype.
      </p>
      <h2>
        <strong>12. Theme Store</strong>
      </h2>
      <ol>
        <li>
          You may establish the appearance of your Shoptype Store with a design
          template from Shoptype&rsquo;s Theme Store (&ldquo;a Theme&rdquo;). If
          you download a Theme, you are licensed to use it for a single Store
          only. You are free to transfer a Theme to a second one of your own
          Stores if you close your first Store. To initiate a transfer of a
          Theme to a second one of your Stores, please contact{' '}
          <a href="https://shoptype.com/support">
            <u>Shoptype Support</u>
          </a>
          . You are not permitted to transfer or sell a Theme to any other
          person&rsquo;s Store on Shoptype or elsewhere. Multiple Stores require
          multiple downloads and each download is subject to the applicable fee.
          Shoptype gives no assurance that a particular Theme will remain
          available for additional downloads.
        </li>
        <li>
          You may modify the Theme to suit your Store. Shoptype may add or
          modify the footer in a Theme that refers to Shoptype at its
          discretion. Shoptype may modify the Theme where it contains, in our
          sole discretion, an element that violates the Shoptype AUP or other
          provisions of the Terms of Service, even if you received the Theme in
          that condition. Shoptype may modify the Theme to reflect technical
          changes and updates as required.
        </li>
        <li>
          The intellectual property rights of the Theme remain the property of
          the designer. If you exceed the rights granted by your purchase of a
          Theme, the designer may take legal action against you, and, without
          prejudice to our other rights or remedies, Shoptype may take
          administrative action such as modifying your Store or closing your
          Store.
        </li>
        <li>
          Technical support for a Theme is the responsibility of the designer,
          and Shoptype accepts no responsibility to provide such support.
          Shoptype may be able to help you contact the designer.
        </li>
        <li>
          It is the responsibility of the user, and not Shoptype, to ensure that
          the installation of a new theme does not overwrite or damage the
          current or preexisting theme, or UI, of the user.
        </li>
      </ol>
      <h2>
        <strong>14. Payment of Fees</strong>
      </h2>
      <ol>
        <li>
          You will pay the Fees applicable to your subscription to Online
          Service and/or POS Services (&ldquo;Subscription Fees&rdquo;) and any
          other applicable fees, including but not limited to applicable fees
          relating to the value of sales made through your Store when using all
          payment providers (&ldquo;Transaction Fees&rdquo;), and any fees
          relating to your purchase or use of any products or services such as
          Shoptype Payments, POS Equipment, shipping, apps, Themes, domain
          names, Experts Marketplace, or Third Party Services (&ldquo;Additional
          Fees&rdquo;). Together, the Subscription Fees, Transaction Fees and
          the Additional Fees are referred to as the &ldquo;Fees&rdquo;.
        </li>
        <li>
          You must keep a valid payment method on file with us to pay for all
          incurred and recurring Fees. Shoptype will charge applicable Fees to
          any valid payment method that you authorize (&ldquo;Authorized Payment
          Method&rdquo;), and Shoptype will continue to charge the Authorized
          Payment Method for applicable Fees until the Services are terminated,
          and any and all outstanding Fees have been paid in full. Unless
          otherwise indicated, all Fees and other charges are in U.S. dollars,
          and all payments shall be in U.S. currency.
        </li>
        <li>
          Subscription Fees are paid in advance and will be billed in 30 day
          intervals (each such date, a &ldquo;Billing Date&rdquo;). Transaction
          Fees and Additional Fees will be charged from time to time at
          Shoptype&rsquo;s discretion. You will be charged on each Billing Date
          for all outstanding Fees that have not previously been charged. Fees
          will appear on an invoice, which will be sent to the Store Owner via
          the email provided. As well, an invoice will appear on the Account
          page of your Store&rsquo;s administration console. Users have
          approximately two weeks to bring up and settle any issues with the
          billing of Subscription Fees.
        </li>
        <li>
          If we are not able to process payment of Fees using an Authorized
          Payment Method, we will make a second attempt to process payment using
          any Authorized Payment Method 3 days later. If the second attempt is
          not successful, we will make a final attempt 3 days following the
          second attempt. If our final attempt is not successful, we may suspend
          and revoke access to your Account and the Services. Your Account will
          be reactivated upon your payment of any outstanding Fees, plus the
          Fees applicable to your next billing cycle. You may not be able to
          access your Account or your storefront during any period of
          suspension. If the outstanding Fees remain unpaid for 60 days
          following the date of suspension, Shoptype reserves the right to
          terminate your Account.
        </li>
        <li>
          All Fees are exclusive of applicable federal, provincial, state,
          local, or other governmental sales, goods and services (including
          Goods and Sales Tax under the Goods and Services Tax Act, Chapter 117A
          of Singapore), harmonized or other taxes, fees, or charges now in
          force or enacted in the future (&ldquo;Taxes&rdquo;).
        </li>
        <li>
          You are responsible for all applicable Taxes that arise from or as a
          result of your subscription to or purchase of products and services
          through Shoptype. To the extent that Shoptype charges applicable Taxes
          that arise from or as a result of your subscription to or purchase of
          products and services through Shoptype, the Taxes are calculated using
          the tax rates that apply based on the billing address you provide to
          us. Such amounts are in addition to the Fees for such products and
          services and will be billed to your Authorized Payment Method. If you
          are exempt from payment of such Taxes, you must provide us with
          evidence of your exemption, which in some jurisdictions includes an
          original certificate that satisfies applicable legal requirements
          attesting to tax-exempt status. Tax exemption will only apply from and
          after the date we receive evidence satisfactory to Shoptype of your
          exemption.
        </li>
        <li>
          We pass the money that we collect for Taxes along to the Store and
          Store Owner. The Store and Store Owner are responsible for paying
          those Taxes to the applicable tax authority, and all tax liability is
          the responsibility of the Store and Store Owner. The Store and Store
          Owner indemnify and hold harmless Shoptype for all liability related
          to Taxes and the payment thereof after Shoptype passes the collected
          Taxes to either one of them.
        </li>
        <li>
          The Store and Store Owner are responsible for fulfilling all sales,
          shipping the product(s), providing any associated warranty, and
          assuming and all liability and responsibility related to the
          product(s) or service(s) sold through their Store. The Store and Store
          Owner shall determine if shipping costs will be paid by the buyer or
          Store. Shoptype will calculate, deduct, and retain Network Fees from
          each sales transaction based upon specific percentage criteria
          determined by the Store, as recorded in the Shoptype Ledger.
        </li>
        <li>
          Shoptype will calculate, deduct, and retain Profit Shares from each
          sales transaction based upon specific percentage criteria as recorded
          in the Shoptype Ledger. The Store determines some of the Profit
          Shares, and others are fixed.
        </li>
        <li>
          For the avoidance of doubt, all sums payable by you to Shoptype under
          these Terms of Service shall be paid free and clear of any deductions
          or withholdings whatsoever. Other than Taxes charged by Shoptype to
          you and remitted to the appropriate tax authorities on your behalf,
          any deductions or withholdings that are required by law shall be borne
          and paid by you separately to the relevant taxation authority.
          Shoptype shall be entitled to charge the full amount of Fees
          stipulated under these Terms of Service to your Authorized Payment
          Method ignoring any such deduction or withholding that may be
          required.
        </li>
        <li>
          You must maintain an accurate location in the administration menu of
          your Shoptype Store. If you change jurisdictions you must promptly
          update your location in the administration menu.
        </li>
        <li>
          With the exception of Network Fees and Profit Shares, Shoptype does
          not provide refunds. Network Fees and Profit Shares are only
          refundable if a transaction is reversed within 30 days. After those 30
          days, Network Fees and Profit Shares are no longer refundable.
        </li>
      </ol>
      <h2>
        <strong>15. Cancellation and Termination</strong>
      </h2>
      <ol>
        <li>
          You may cancel your Account and terminate the Terms of Service at any
          time by contacting{' '}
          <a href="https://www.shoptype.com/support">
            <u>Shoptype Support</u>
          </a>
          and then following the specific instructions indicated to you in
          Shoptype&rsquo;s response.
        </li>
        <li>
          Upon termination of the Services by either party for any reason:
          <ol>
            <li>
              Shoptype will cease providing you with the Services and you will
              no longer be able to access your Account;
            </li>
            <li>
              unless otherwise provided in the Terms of Service, you will not be
              entitled to any refunds of any Fees, pro rata or otherwise;
            </li>
            <li>
              any outstanding balance owed to Shoptype for your use of the
              Services through the effective date of such termination will
              immediately become due and payable in full; and
            </li>
            <li>your Store website will be taken offline.</li>
          </ol>
        </li>
        <li>
          If at the date of termination of the Service, there are any
          outstanding Fees owing by you, you will receive one final invoice via
          email. Once that invoice has been paid in full, you will not be
          charged again.
        </li>
        <li>
          We reserve the right to modify or terminate the Shoptype Service, the
          Terms of Service and/or your Account for any reason, without notice at
          any time. Termination of the Terms of Service shall be without
          prejudice to any rights or obligations which arose prior to the date
          of termination.
        </li>
        <li>
          Fraud: Without limiting any other remedies, Shoptype may suspend or
          terminate your Account if we suspect that you (by conviction,
          settlement, insurance or escrow investigation, or otherwise) have
          engaged in fraudulent activity in connection with the use of the
          Services.
        </li>
      </ol>
      <h2>
        <strong>16. Modifications to the Service and Prices</strong>
      </h2>
      <ol>
        <li>
          Prices for using the Services are subject to change upon 30
          days&rsquo; notice from Shoptype. Such notice may be provided at any
          time by posting the changes to the Shoptype Site (Shoptype.com) or the
          administration menu of your Shoptype Store via an announcement.
        </li>
        <li>
          Shoptype reserves the right at any time, and from time to time, to
          modify or discontinue, the Services (or any part thereof) with or
          without notice.
        </li>
        <li>
          Shoptype shall not be liable to you or to any third party for any
          modification, price change, suspension, or discontinuation of the
          Service.
        </li>
      </ol>
      <h2>
        <strong>
          17. Third Party Services, Shoptype Experts, and Shoptype Experts
          Marketplace
        </strong>
      </h2>
      <ol>
        <li>
          Shoptype may from time to time recommend, provide you with access to,
          or enable third party software, applications (&ldquo;Apps&rdquo;),
          products, services, or website links (collectively, &ldquo;Third Party
          Services&rdquo;) for your consideration or use, including via the
          Shoptype App Store, or Shoptype Experts Marketplace. Such Third Party
          Services are made available only as a convenience, and your purchase,
          access, or use of any such Third Party Services is solely between you
          and the applicable third party services provider (&ldquo;Third Party
          Provider&rdquo;). In addition to these Terms of Service, you also
          agree to be bound by the terms applicable to services you purchase
          from, or that are provided by, Third Party Providers.
        </li>
        <li>
          Shoptype Experts Marketplace is an online directory of independent
          third parties (&ldquo;Experts&rdquo;) that can help you build and
          operate your Shoptype Store.
        </li>
        <li>
          You can engage and work with an Expert directly or through Shoptype
          Experts Marketplace. Shoptype Experts Marketplace provides you with
          recommendations on Experts that can assist you with different aspects
          of your Store. Using Shoptype Experts Marketplace, you can find, hire,
          and communicate with Experts directly from your Account.
        </li>
        <li>
          Any use by you of Third Party Services offered through the Services,
          Shoptype Experts, Shoptype Experts Marketplace, or Shoptype&rsquo;s
          website is entirely at your own risk and discretion, and it is your
          responsibility to read the terms and conditions and/or privacy
          policies applicable to such Third Party Services before using them. In
          some instances, Shoptype may receive a revenue share from Third Party
          Providers that Shoptype recommends to you or that you otherwise engage
          through your use of the Services, Shoptype Experts, Shoptype Experts
          Marketplace, or Shoptype&rsquo;s website.
        </li>
        <li>
          We do not provide any warranties or make representations to you with
          respect to Third Party Services. You acknowledge that Shoptype has no
          control over Third Party Services and shall not be responsible or
          liable to you or anyone else for Third Party Services. The
          availability of Third Party Services on Shoptype&rsquo;s websites,
          including the Shoptype App Store or Shoptype Experts Marketplace, or
          the integration or enabling of such Third Party Services with the
          Services does not constitute or imply an endorsement, authorization,
          sponsorship, or affiliation by or with Shoptype. Shoptype does not
          guarantee the availability of Third Party Services and you acknowledge
          that Shoptype may disable access to any Third Party Services at any
          time in its sole discretion and without giving notice to you. Shoptype
          is not responsible or liable to anyone for discontinuation or
          suspension of access to, or disablement of, any Third Party Service.
          Shoptype strongly recommends that you seek specialist advice before
          using or relying on Third Party Services, to ensure they will meet
          your needs. In particular, tax calculators should be used for
          reference only and not as a substitute for independent tax advice,
          when determining the correct tax rates you should charge to your
          customers.
        </li>
        <li>
          If you install or enable a Third Party Service for use with the
          Services, you grant us permission to allow the applicable Third Party
          Provider to access your data and other Materials and to take any other
          actions as required for the interoperation of the Third Party Service
          with the Services.Any exchange of data or other Materials or other
          interaction between you and the Third Party Provider is solely between
          you and such Third Party Provider. Shoptype is not responsible for any
          disclosure, modification, or deletion of your data or other Materials,
          or for any corresponding losses or damages you may suffer, as a result
          of access by a Third Party Service or a Third Party Provider to your
          data or other Materials. Google Maps is a Third Party Service that is
          used within the Services. Your use of the Services is subject to your
          acceptance of the Google Maps and Earth Enterprise Universal{' '}
          <a href="https://enterprise.google.com/maps/terms/universal_aup.html">
            <u>Acceptable Use Policy</u>
          </a>
          , Google Maps and Google Earth{' '}
          <a href="https://maps.google.com/help/terms_maps.html">
            <u>Additional Terms of Service</u>
          </a>
          and Google{' '}
          <a href="https://www.google.com/intl/ALL/policies/privacy/index.html">
            <u>Privacy Policy</u>
          </a>
          &nbsp;as it may be amended by Google from time to time.
        </li>
        <li>
          You acknowledge and agree that: (i) by submitting a request for
          assistance or other information through Shoptype Experts Marketplace,
          you consent to being contacted by one or more Experts at the Store
          Owner&rsquo;s registered email address (or such other email address
          provided by you) as well as the applicable user email address; and
          (ii) Shoptype will receive all email communications exchanged via
          Shoptype Experts Marketplace or in any reply emails (each a
          &ldquo;Reply&rdquo;) that originate from Shoptype Experts Marketplace
          (directly or indirectly) between yourself and Experts. You further
          agree that Shoptype may share your contact details and the background
          information that you submit via the Shoptype Experts Marketplace with
          Experts. Experts may require access to certain admin pages on your
          Shoptype Store. You choose the pages that the Experts can access.
        </li>
        <li>
          The relationship between you and any Third Party Provider is strictly
          between you and such Third Party Provider, and Shoptype is not
          obligated to intervene in any dispute arising between you and a Third
          Party Provider.
        </li>
        <li>
          Under no circumstances shall Shoptype be liable for any direct,
          indirect, incidental, special, consequential, punitive, extraordinary,
          exemplary, or other damages whatsoever, that result from any Third
          Party Services or your contractual relationship with any Third Party
          Provider, including any Expert. These limitations shall apply even if
          Shoptype has been advised of the possibility of such damages. The
          foregoing limitations shall apply to the fullest extent permitted by
          applicable law.
        </li>
        <li>
          You agree to indemnify and hold us and (as applicable) our parent,
          subsidiaries, affiliates, Shoptype partners, officers, directors,
          agents, employees, and suppliers harmless from any claim or demand,
          including but not limited to reasonable attorneys&rsquo; fees, arising
          out of your use of a Third Party Service or your relationship with a
          Third Party Provider.
        </li>
      </ol>
      <h2>
        <strong>18. Beta Services</strong>
      </h2>
      <p>
        From time to time, Shoptype may, in its sole discretion, invite you to
        use, on a trial basis, pre-release or beta features that are in
        development and not yet available to all merchants (&ldquo;Beta
        Services&rdquo;). Beta Services may be subject to additional terms and
        conditions, which Shoptype will provide to you prior to your use of the
        Beta Services. Such Beta Services and all associated conversations and
        materials relating thereto will be considered Shoptype Confidential
        Information and subject to the confidentiality provisions in this
        agreement. Without limiting the generality of the foregoing, you agree
        that you will not make any public statements or otherwise disclose your
        participation in the Beta Services without Shoptype&rsquo;s prior
        written consent. Shoptype makes no representations or warranties that
        the Beta Services will function. Shoptype may discontinue the Beta
        Services at any time in its sole discretion. Shoptype will have no
        liability for any harm or damage arising out of or in connection with a
        Beta Service. The Beta Services may not work in the same way as a final
        version. Shoptype may change or not release a final or commercial
        version of a Beta Service in our sole discretion.
      </p>
      <h2>
        <strong>19. Feedback and Reviews</strong>
      </h2>
      <p>
        Shoptype welcomes any ideas and/or suggestions regarding improvements or
        additions to the Services. Under no circumstances shall any disclosure
        of any idea, suggestion or related material or any review of the
        Services, Third Party Services or any Third Party Provider
        (collectively, &ldquo;Feedback") to Shoptype be subject to any
        obligation of confidentiality or expectation of compensation. By
        submitting Feedback to Shoptype (whether submitted directly to Shoptype
        or posted on any Shoptype hosted forum or page), you waive any and all
        rights in the Feedback and that Shoptype is free to implement and use
        the Feedback if desired, as provided by you or as modified by Shoptype,
        without obtaining permission or license from you or from any third
        party. Any reviews of a Third Party Service or Third Party Provider that
        you submit to Shoptype must be accurate to the best of your knowledge,
        and must not be illegal, obscene, threatening, defamatory, invasive of
        privacy, infringing of intellectual property rights, or otherwise
        injurious to third parties or objectionable. Shoptype reserves the right
        (but not the obligation) to remove or edit Feedback of Third Party
        Services or Third Party Providers, but does not regularly inspect posted
        Feedback.
      </p>
      <h2>
        <strong>20. DMCA Notice and Takedown Procedure</strong>
      </h2>
      <p>
        Shoptype supports the protection of intellectual property and asks
        Shoptype merchants to do the same. It&rsquo;s our policy to respond to
        all notices of alleged copyright infringement. If someone believes that
        one of our merchants is infringing their intellectual property rights,
        they can send a DMCA Notice to Shoptype&rsquo;s designated agent using
        our form. Upon receiving a DMCA Notice, we may remove or disable access
        to the Materials claimed to be a copyright infringement. Once provided
        with a notice of takedown, the merchant can reply with a counter
        notification using our form if they object to the complaint. The
        original complainant has 14 business days after we receive a counter
        notification to seek a court order restraining the merchant from
        engaging in the infringing activity, and if the original complainant
        does not file the request for this court order within this time frame
        &nbsp;we may restore the material. For more information, see our{' '}
        <Link target="_blank" to={'/policies/dcma'}>
          <u>DMCA Notice and Takedown Procedure</u>
        </Link>
        .
      </p>
      <h2>
        <strong>21. Rights of Third Parties</strong>
      </h2>
      <p>
        Save for Shoptype and its affiliates, Shoptype Users or anyone accessing
        Shoptype Services pursuant to these Terms of Service, unless otherwise
        provided in these Terms of Service, no person or entity who is not a
        party to these Terms of Service shall have any right to enforce any term
        of these Terms of Service, regardless of whether such person or entity
        has been identified by name, as a member of a class or as answering a
        particular description. For the avoidance of doubt, this shall not
        affect the rights of any permitted assignee or transferee of these
        Terms.
      </p>
      <h2>
        <strong>22. Privacy &amp; Data Protection</strong>
      </h2>
      <p>
        Shoptype is firmly committed to protecting the privacy of your personal
        information and the personal information of your customers. By using the
        Service, you acknowledge and agree that Shoptype&rsquo;s collection,
        usage and disclosure of this personal information is governed by our{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>Privacy Policy</u>
        </Link>
        .
      </p>
      <p>
        Additionally, if: (a) you are established in the European Economic Area
        (EEA); (b) you provide goods or services to customers in the EEA; or (c)
        you are otherwise subject to the requirements of the EU General Data
        Protection Regulation, Shoptype&rsquo;s collection and use of personal
        information of any European residents is also subject to our{' '}
        <Link target="_blank" to={'/policies/dpa'}>
          <u>Data Processing Addendum</u>
        </Link>
        .
      </p>
      <h2>
        <strong>Awake Market Membership Agreement</strong>
      </h2>
      <p>
        By signing up for a Shoptype Account, you are agreeing to be bound by
        the following terms and conditions (the &ldquo;
        <Link target="_blank" to={'/policies/awake-membership-agreement'}>
          <u>Awake Market Membership Agreement</u>
        </Link>
        &rdquo;).
      </p>
    </React.Fragment>
  );
};

export const AcceptableUsablePolicy = () => {
  return (
    <React.Fragment>
      <h1>Shoptype Acceptable Use Policy</h1>
      <p>
        Awake Market, Inc. (dba &ldquo;Shoptype&rdquo;) is a media technology
        platform that provides the tools and technology for publishers, brands,
        retailers, influencers, and others to set up an online presence and
        promote their advice, expertise, opinions, products and services, and to
        drive sales to their own licensed retail operations across digital and
        offline locations. While we believe the free and open exchange of ideas
        and products is a key tenet of commerce, there are some activities that
        are incompatible with Shoptype&rsquo;s mission to make commerce better
        for everyone. This Acceptable Use Policy (&ldquo;AUP&rdquo;) describes
        activities that are prohibited in connection with your use of the
        Services.
      </p>
      <p>
        Any capitalized terms used in this AUP are defined on the Shoptype AUP
        definitions page, located in Exhibit A of this Agreement.
      </p>
      <h4>The following activities are prohibited:</h4>
      <ol>
        <li>
          <strong>Child exploitation</strong>: You may not offer goods or
          services, or post or upload Materials that exploit or abuse children,
          including but not limited to images or depictions of child abuse or
          sexual abuse, or that present children in a sexual manner.
        </li>
        <li>
          <strong>COVID-19</strong>: You may only offer goods or services in
          relation to COVID-19 that comply with the{' '}
          <Link target="_blank" to={'/policies/covid19'}>
            <u>Rules of Engagement for the Sale of COVID-19 Related Products</u>
          </Link>
          .
        </li>
        <li>
          <strong>Harassment, bullying, defamation and threats</strong>: You may
          not offer goods or services, or post or upload Materials, that harass,
          bully, defame or threaten a specific individual.
        </li>
        <li>
          <strong>Hateful content</strong>: You may not use the Services to
          promote or condone hate or violence against people based on race,
          ethnicity, color, national origin, religion, age, gender, sexual
          orientation, disability, medical condition, veteran status or other
          forms of discriminatory intolerance. You may not use the Services to
          promote or support organizations, platforms or people that: (i)
          promote or condone such hate; or (ii) threaten or condone violence to
          further a cause.
        </li>
        <li>
          <strong>Illegal activities</strong>: You may not offer goods or
          services, or post or upload Materials, that contravene or that
          facilitate or promote activities that contravene the laws of the
          jurisdictions in which you operate or do business.
        </li>
        <li>
          <strong>Intellectual property</strong>: You may not offer goods or
          services, or post or upload Materials, that infringe on the copyright
          or trademarks of others.
        </li>
        <li>
          <strong>Malicious and deceptive practices</strong>: You may not use
          the Services to transmit malware or host phishing pages. You may not
          perform activities or upload or distribute Materials that harm or
          disrupt the operation of the Services or other infrastructure of
          Shoptype or others, including Shoptype&rsquo;s third party providers.
          You may not use the Services for deceptive commercial practices or any
          other illegal or deceptive activities.
        </li>
        <li>
          <strong>
            Personal, confidential, and protected health information
          </strong>
          : You may not post or upload any Materials that contain personally
          identifiable information, sensitive personal information, or
          confidential information, such as credit card numbers, confidential
          national ID numbers, or account passwords unless you have consent from
          the person to whom the information belongs or who is otherwise
          authorized to provide such consent. You may not use the Services to
          collect, store, or process any protected health information subject to
          the Health Insurance Portability and Accountability Act
          (&ldquo;HIPAA&rdquo;), any applicable health privacy regulation or any
          other applicable law governing the processing, use, or disclosure of
          protected health information.
        </li>
        <li>
          <strong>Restricted Items</strong>: You may not offer goods or services
          that are, or appear to be, Restricted Items.
        </li>
        <li>
          <strong>Self-harm</strong>: You may not offer goods or services, or
          post or upload Materials, that promote self-harm.
        </li>
        <li>
          <strong>Spam</strong>: You may not use the Services to transmit
          unsolicited commercial electronic messages.
        </li>
        <li>
          <strong>Terrorist organizations</strong>: You may not offer goods or
          services, or post or upload Materials, that imply or promote support
          or funding of, or membership in, a terrorist organization.
        </li>
      </ol>
      <p>
        We may, at any time and without notice, remove any Materials, and
        suspend or terminate your Account or your access to the Services if you
        engage in activities that violate the letter or spirit of this AUP,
        including activities outside of your use of the Services.
      </p>
      <p>
        Shoptype has the right, but not the obligation, to monitor or
        investigate any Materials and your use of the Services at any time for
        compliance with this AUP and the Shoptype Terms of Service, or any other
        agreement between you and Shoptype governing your use of the Services
        (collectively, the <strong>&ldquo;Terms&rdquo;</strong>). Our
        determination of whether a violation of this AUP has occurred will be
        final and binding, and any action taken with respect to enforcing this
        AUP, including taking no action at all, will be at our sole discretion.
      </p>
      <p>
        Shoptype may modify this AUP, including the list of Restricted Items, at
        any time by posting a revised version at{' '}
        <Link target="_blank" to={'/policies/acceptable-use-policy'}>
          <u>https://www.Shoptype.com/policies/aup</u>
        </Link>
        . By continuing to use the Services or access your Account after a
        revised version of the AUP has been posted, you agree to comply with the
        latest version of the AUP. In the event of a conflict between the AUP
        and the Terms, this AUP will take precedence, but only to the extent
        required to resolve such conflict. Capitalized terms used but not
        defined in this AUP shall have the meanings set forth in the Terms.
      </p>
      <p>
        If you feel that a user of the Services has violated this AUP, please
        contact us at{' '}
        <a href="mailto:support@shoptype.com">
          <u>Shoptype Support</u>
        </a>
        .
      </p>
      <p>Last updated: July 16, 2020</p>
      <p>&copy; 2020 Awake Market, Inc.</p>
      <p>&nbsp;</p>
      <h1>Exhibit A</h1>
      <h2>Shoptype AUP Definitions</h2>
      <p>
        The definitions listed below (
        <strong>&ldquo;AUP Definitions&rdquo;</strong>) apply to Shoptype's
        Acceptable Use Policy (<strong>&ldquo;AUP&rdquo;</strong>). Shoptype
        reserves the right to update and change the AUP Definitions at any time.
      </p>
      <p>
        <strong>&ldquo;Materials&rdquo;</strong>&nbsp;means any photo, image,
        video, graphic, written content, audio file, code, information, data or
        other content uploaded, collected, generated, stored, displayed,
        distributed, transmitted or exhibited on or in connection with your
        Account.
      </p>
      <p>
        <strong>&ldquo;Restricted Items&rdquo;</strong>&nbsp;means:
      </p>
      <p>
        <strong>Certain Firearms</strong>
      </p>
      <ul>
        <li>an automatic firearm that has not been rendered inoperable</li>
        <li>
          a semi-automatic firearm that has the capacity to accept a detachable
          magazine, with one or more of the following items:
          <ul>
            <li>magazine capable of accepting more than 10 rounds</li>
            <li>bump stock</li>
            <li>rapid fire trigger activator or trigger crank</li>
            <li>barrel shroud</li>
            <li>thumbhole stock</li>
            <li>
              threaded barrel capable of accepting a flash suppressor, sound
              suppressor or silencer
            </li>
            <li>grenade or rocket launcher</li>
            <li>flash suppressor, sound suppressor or silencer</li>
            <li>
              pistol grip (or in the case of a pistol, a second pistol grip)
            </li>
            <li>forward pistol grip</li>
          </ul>
        </li>
        <li>
          a semi-automatic firearm that has a fixed magazine with the capacity
          to accept more than 10 rounds
        </li>
        <li>firearms without serial numbers</li>
        <li>
          ghost guns and 3D printed guns, including blueprints for such guns
        </li>
        <li>any part, component or kit for any firearm or gun listed above</li>
      </ul>
      <p>
        <strong>Certain Firearm Parts</strong>
      </p>
      <ul>
        <li>80% or unfinished lower receivers</li>
        <li>magazine capable of accepting more than 10 rounds</li>
        <li>bump stock</li>
        <li>grenade or rocket launcher</li>
        <li>pistol grip (or in the case of a pistol, a second pistol grip)</li>
        <li>forward pistol grip</li>
        <li>barrel shroud*</li>
        <li>thumbhole stock*</li>
        <li>
          threaded barrel capable of accepting a flash suppressor, sound
          suppressor or silencer*
        </li>
        <li>flash suppressor, sound suppressor or silencer*</li>
        <li>rapid fire trigger activator or trigger crank</li>
        <li>
          any part, component or kit for a firearm part or including a firearm
          part listed above
        </li>
      </ul>
      <p>*only if for use with a semi-automatic firearm</p>
      <p>
        &ldquo;Services&rdquo; has the meaning set out in the{' '}
        <Link target="_blank" to={'/policies/terms-of-service'}>
          <u>Shoptype Terms of Service</u>
        </Link>
        .
      </p>
    </React.Fragment>
  );
};

export const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <h1>Privacy Policy</h1>
      <h2>How Shoptype handles your data</h2>
      <h2>Introduction</h2>
      <p>
        In our mission to make commerce better for everyone at Shoptype, we
        collect and use information about you, our
      </p>
      <ul>
        <li>
          <Link target="_blank" to={'/policies/privacy-policy-merchants'}>
            <u>Merchants using Shoptype</u>
          </Link>{' '}
          to power your business
        </li>
        <li>
          <Link target="_blank" to={'/policies/privacy-policy-customers'}>
            <u>Customers</u>
          </Link>{' '}
          who shop at a Shoptype-powered business
        </li>
        <li>
          <Link target="_blank" to={'/policies/privacy-policy-partners'}>
            <u>Partners</u>
          </Link>{' '}
          who develop apps for merchants to use, build stores on behalf of
          merchants, refer potential entrepreneurs to Shoptype, or otherwise
          help merchants operate or improve their Shoptype-powered business
        </li>
        <li>
          Users of Shoptype apps and services like{' '}
          <a href="https://shoptype.app/">
            <u>Shoptype App</u>
          </a>
          &nbsp;or{' '}
          <a href="https://pay.shoptype.com/">
            <u>Shoptype Pay</u>
          </a>
        </li>
        <li>
          <Link target="_blank" to={'/policies/privacy-policy-visitors'}>
            <u>
              Visitors to Shoptype&rsquo;s websites, or anyone contacting
              Shoptype support
            </u>
          </Link>
        </li>
      </ul>
      <p>
        This Privacy Policy will help you better understand how we collect, use,
        and share your personal information. If we change our privacy practices,
        we may update this privacy policy. If any changes are significant, we
        will let you know (for example, through the Shoptype admin or by email).
      </p>
      <h2>Our values</h2>
      <p>
        Trust is the foundation of the Shoptype platform and includes trusting
        us to do the right thing with your information. Three main values guide
        us as we develop our products and services. These values should help you
        better understand how we think about your information and privacy.
      </p>
      <ul>
        <li>
          Your information belongs to you
          <br />
          We carefully analyze what types of information we need to provide our
          services, and we try to limit the information we collect to only what
          we really need. Where possible, we delete or anonymize this
          information when we no longer need it. When building and improving our
          products, our engineers work closely with our privacy and security
          teams to build with privacy in mind. In all of this work our guiding
          principle is that your information belongs to you, and we aim to only
          use your information to your benefit.
        </li>
        <li>
          We protect your information from others
          <br />
          If a third party requests your personal information, we will refuse to
          share it unless you give us permission or we are legally required.
          When we are legally required to share your personal information, we
          will tell you in advance, unless we are legally forbidden.
        </li>
        <li>
          We help merchants and partners meet their privacy obligations
          <br />
          Many of the merchants and partners using Shoptype do not have the
          benefit of a dedicated privacy team, and it is important to us to help
          them meet their privacy obligations. To do this, we try to build our
          products and services so they can easily be used in a privacy-friendly
          way. We also provide detailed FAQs, documentation and whitepapers
          covering the most important privacy topics, and respond to
          privacy-related questions we receive.
        </li>
      </ul>
      <h2>Why we process your information</h2>
      <p>
        We generally process your information when we need to do so to fulfill a
        contractual obligation (for example, to process your subscription
        payments to use the Shoptype platform), or where we or someone we work
        with needs to use your personal information for a reason related to
        their business (for example, to provide you with a service). European
        law calls these reasons &ldquo;legitimate interests.&rdquo; These
        &ldquo;legitimate interests&rdquo; include:
      </p>
      <ul>
        <li>preventing risk and fraud</li>
        <li>answering questions or providing other types of support</li>
        <li>helping merchants find and use apps through our app store</li>
        <li>providing and improving our products and services</li>
        <li>providing reporting and analytics</li>
        <li>testing out features or additional services</li>
        <li>assisting with marketing, advertising, or other communications</li>
      </ul>
      <p>
        We only process personal information for these &ldquo;legitimate
        interests&rdquo; after considering the potential risks to your
        privacy&mdash;for example, by providing clear transparency into our
        privacy practices, offering you control over your personal information
        where appropriate, limiting the information we keep, limiting what we do
        with your information, who we send your information to, how long we keep
        your information, or the technical measures we use to protect your
        information.
      </p>
      <p>
        One of the ways in which we are able to help merchants using Shoptype is
        by using techniques like &ldquo;
        <a href="https://en.wikipedia.org/wiki/Machine_learning">
          <u>machine learning</u>
        </a>
        &rdquo; (European law refers to this as &ldquo;automated
        decision-making&rdquo;) to help us improve our services. When we use
        machine learning, we either: (1) still have a human being involved in
        the process (and so are not fully automated); or (2) use machine
        learning in ways that don&rsquo;t have significant privacy implications
        (for example, reordering how apps might appear when you visit the app
        store).
      </p>
      <h2>Your rights over your information</h2>
      <p>
        We believe you should be able to access and control your personal
        information no matter where you live. Depending on how you use Shoptype,
        you may have the right to request access to, correct, amend, delete,
        port to another service provider, restrict, or object to certain uses of
        your personal information (for example, direct marketing). We will not
        charge you more or provide you with a different level of service if you
        exercise any of these rights.
      </p>
      <p>
        If you buy something from a Shoptype-powered store and wish to exercise
        these rights over information about your purchase, you need to directly
        contact the merchant you interacted with. We are only a processor on
        their behalf, and cannot decide how to process their information. As
        such, we can only forward your request to them to allow them to respond.
        We will of course help our merchants to fulfill these requests by giving
        them the tools to do so and by answering their questions.
      </p>
      <p>
        Please note that if you send us a request relating to your personal
        information, we have to make sure that it is you before we can respond.
        In order to do so, we may ask to see documentation verifying your
        identity, which we will discard after verification.
      </p>
      <p>
        If you would like to designate an authorized agent to exercise your
        rights for you, please email us from the email address we have on file
        for you. If you email us from a different email address, we cannot
        determine if the request is coming from you and will not be able to
        accommodate your request. In your email, please include the name and
        email address of your authorized agent.
      </p>
      <p>
        If you are not happy with our response to a request, you can contact us
        to resolve the issue. You also have the right to contact your local data
        protection or privacy authority at any time.
      </p>
      <p>
        Finally, because there is no common understanding about what a{' '}
        <a href="https://en.wikipedia.org/wiki/Do_Not_Track">
          <u>&ldquo;Do Not Track&rdquo;</u>
        </a>
        &nbsp;signal is supposed to mean, we don&rsquo;t respond to those
        signals in any particular way.
      </p>
      <h2>Where we send your information</h2>
      <p>
        We are a USA company, but we work with and process data about
        individuals across the world. To operate our business, we may send your
        personal information outside of your state, province, or country. This
        data may be subject to the laws of the countries where we send it. When
        we send your information across borders, we take steps to protect your
        information, and we try to only send your information to countries that
        have strong data protection laws. If you would like more information
        about where your information might be sent, please contact us.
      </p>
      <h4>Transfers outside of Europe and Switzerland</h4>
      <p>
        If you are in Europe or Switzerland, your personal information is
        controlled by our Irish affiliate, Awake Market Ltd. Your information is
        then sent to other Shoptype locations and to service providers who may
        be located in other regions, including Canada and the United States
        (where we are based). When we send your personal information outside of
        Europe, we do so in accordance with European law.
      </p>
      <p>
        If you are in Europe or Switzerland, when we send your personal
        information to the USA it is protected under USA law, which the European
        Commission has found will adequately protect your information.
      </p>
      <p>
        Finally, while we do what we can to protect your information, we may at
        times be legally required to disclose your personal information (for
        example, if we receive a valid court order). For information about how
        we respond to such orders, please review our{' '}
        <Link target="_blank" to={'/policies/gvt-access-policy'}>
          <u>Guidelines for Legal Requests</u>
        </Link>
        .
      </p>
      <h2>How we protect your information</h2>
      <p>
        Our teams work tirelessly to protect your information, and to ensure the
        security and integrity of our platform. We also have independent
        auditors assess the security of our data storage and systems that
        process financial information. However, we all know that no method of
        transmission over the Internet, and method of electronic storage, can be
        100% secure. This means we cannot guarantee the absolute security of
        your personal information. You can find more information about our
        security measures{' '}
        <Link target="_blank" to={'/policies/security'}>
          <u>here</u>
        </Link>
        .
      </p>
      <h2>How we use &ldquo;cookies&rdquo; and other tracking technologies</h2>
      <p>
        We use{' '}
        <a href="https://www.allaboutcookies.org/">
          <u>cookies</u>
        </a>
        &nbsp;and similar tracking technologies on our website and when
        providing our services. For more information about how we use these
        technologies, including a list of other companies that place cookies on
        our sites, a list of cookies that we place when we power a
        merchant&rsquo;s store, and an explanation of how you can opt out of
        certain types of cookies, please see our Cookie Policy.
      </p>
      <h2>How you can reach us</h2>
      <p>
        If you would like to ask about, make a request relating to, or complain
        about how we process your personal information, you can contact us by
        email at privacy@Shoptype.com, or at one of the addresses below. If you
        would like to submit a legally binding request to demand someone
        else&rsquo;s personal information (for example, if you have a subpoena
        or court order), please review our{' '}
        <Link target="_blank" to={'/policies/gvt-access-policy'}>
          <u>Guidelines for Legal Requests</u>
        </Link>
        .
      </p>
      <p>
        <strong>Awake Market, Inc.</strong>
      </p>
      <p>ATTN: Chief Privacy Officer</p>
      <p>
        655 Oak Grove Ave., #329
        <br />
        Menlo Park, CA 94026
        <br />
        USA
      </p>
      <p>&nbsp;&nbsp;&nbsp;</p>
      <p>
        <strong>
          If you are located in Europe, the Middle East, South America, or
          Africa:
        </strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Awake Market, Ltd.</strong>
      </p>
      <p>Attn: Data Protection Officer</p>
      <p>Dublin, Ireland Address TBD</p>
      <p>&nbsp;</p>
      <p>
        <strong>If you are located in Asia, Australia, or New Zealand:</strong>
      </p>
      <p>
        <strong>Awake Market Singapore PTE. LTD.</strong>
      </p>
      <p>Attn: Data Protection Officer</p>
      <p>Singapore Address TBD</p>
    </React.Fragment>
  );
};

export const DataProcessingAddendum = () => {
  return (
    <React.Fragment>
      <h1>Shoptype Data Processing Addendum</h1>
      <p>
        This Shoptype Data Processing Addendum (&ldquo;<strong>Addendum</strong>
        &rdquo;) amends the Shoptype Terms of Service (the &ldquo;
        <strong>Agreement</strong>&rdquo;) by and between you and Awake Market,
        Inc., a Delaware corporation with offices at 655 Oak Grove Ave., #329
        Menlo Park, CA 94026
      </p>
      <p>
        , on behalf of itself, its Singaporean affiliate Awake Market Singapore
        PTE. LTD., and its Irish affiliate Awake Market Ltd. (collectively
        &ldquo;<strong>Shoptype</strong>&rdquo;).
      </p>
      <ol>
        <li>
          <strong>Definitions</strong>
          <ol>
            <li>
              &ldquo;<strong>Data Protection Legislation</strong>&rdquo; means
              European Union Regulation 2016/679 (the &ldquo;General Data
              Protection Regulation&rdquo;) or California Civil Code Section
              1798.100-1798.199 (the &ldquo;California Consumer Privacy Act of
              2018&rdquo;), as applicable, and any legislation and/or regulation
              implementing or made pursuant to it, or which amends or replaces
              any of it;
            </li>
            <li>
              &ldquo;<strong>Data Processor</strong>&rdquo;, &ldquo;
              <strong>Data Subject</strong>&rdquo;, &ldquo;
              <strong>Processor</strong>&rdquo;, &ldquo;
              <strong>Processing</strong>&rdquo;, &ldquo;
              <strong>Subprocessor</strong>&rdquo;, and &ldquo;
              <strong>Supervisory Authority</strong>&rdquo; shall be interpreted
              in accordance with the General Data Protection Regulation;
            </li>
            <li>
              &ldquo;<strong>Service Provider</strong>&rdquo; shall be
              interpreted in accordance with the California Consumer Privacy Act
              of 2018;
            </li>
            <li>
              &ldquo;<strong>Personal Data</strong>&rdquo; as used in this
              Addendum means information that relates to, or could reasonably be
              linked with, to an identifiable or identified Data Subject who
              visits or engages in transactions through your store (a &ldquo;
              <strong>Customer</strong>&rdquo;), which Shoptype Processes as a
              Data Processor or Service Provider in the course of providing you
              with the Services. Notwithstanding the foregoing sentence,
              Personal Data does not include information that Shoptype Processes
              in the context of services that it provides directly to a
              consumer, such as through its consumer-facing applications like
              Frenzy, Arrive, or its consumer-facing services like Shoptype Pay;
            </li>
            <li>
              &ldquo;<strong>Data Subject Request</strong>&rdquo; as used in
              this Addendum means a request for access, erasure, rectification,
              or portability of your Customer&rsquo;s Personal Data; and
            </li>
            <li>
              All other capitalized terms in this Addendum shall have the same
              definition as in the Agreement.
            </li>
          </ol>
        </li>
        <li>
          <strong>Data Protection</strong>
          <ol>
            <li>
              Where a Data Subject is located in the European Economic Area,
              that Data Subject&rsquo;s Personal Data will be processed by
              Shoptype&rsquo;s Irish affiliate, Shoptype International Ltd. As
              part of providing the Services, this Personal Data may be
              transferred to other regions, including to Canada and the United
              States. Such transfers will be completed in compliance with
              relevant Data Protection Legislation.
            </li>
            <li>
              When Shoptype Processes Personal Data in the course of providing
              the Services, Shoptype will:
              <ol>
                <li>
                  Process the Personal Data as a Data Processor and/or Service
                  Provider, only for the purpose of providing the Services in
                  accordance with documented instructions from you (provided
                  that such instructions are commensurate with the
                  functionalities of the Services), and as may subsequently be
                  agreed to by you. If Shoptype is required by law to Process
                  the Personal Data for any other purpose, Shoptype will provide
                  you with prior notice of this requirement, unless Shoptype is
                  prohibited by law from providing such notice;
                </li>
                <li>
                  notify you if, in Shoptype&rsquo;s opinion, your instruction
                  for the processing of Personal Data infringes applicable Data
                  Protection Legislation;
                </li>
                <li>
                  notify you promptly, to the extent permitted by law, upon
                  receiving an inquiry or complaint from a Supervisory Authority
                  relating to Shoptype&rsquo;s Processing of the Personal Data;
                </li>
                <li>
                  implement reasonable technical and organizational measures
                  enabling you to execute Data Subject Requests that you are
                  obligated to fulfil;
                </li>
                <li>
                  implement and maintain appropriate technical and
                  organizational measures to protect the Personal Data against
                  unauthorized or unlawful processing and against accidental
                  loss, destruction, damage, theft, alteration or disclosure.
                  These measures shall be appropriate to the harm which might
                  result from any unauthorized or unlawful processing,
                  accidental loss, destruction, damage or theft of Personal Data
                  and appropriate to the nature of the Personal Data which is to
                  be protected;
                </li>
                <li>
                  upon request, provide reasonable information to help the
                  Customer complete the Customer&rsquo;s data protection impact
                  assessments.
                </li>
                <li>
                  provide you, upon request, with up-to-date attestations,
                  reports or extracts thereof where available from a source
                  charged with auditing Shoptype&rsquo;s data protection
                  practices (e.g. external auditors, internal audit, data
                  protection auditors), or suitable certifications, to enable
                  you to assess compliance with the terms of this Addendum;
                </li>
                <li>
                  notify you without undue delay upon becoming aware of and
                  confirming any accidental, unauthorized, or unlawful
                  processing of, disclosure of, or access to the Personal Data;
                </li>
                <li>
                  ensure that its personnel who access the Personal Data are
                  subject to confidentiality obligations that restrict their
                  ability to disclose the Customer Personal Data; and
                </li>
                <li>
                  upon termination of the Agreement, Shoptype will promptly
                  initiate its purge process to delete or anonymize the Personal
                  Data. If you request a copy of such Personal Data within 60
                  days of termination, Shoptype will provide you with a copy of
                  such Personal Data.
                </li>
              </ol>
            </li>
            <li>
              In the course of providing the Services, you acknowledge and agree
              that Shoptype may use Subprocessors to Process the Personal Data.
              Shoptype&rsquo;s use of any specific Subprocessor to process the
              Personal Data must be in compliance with Data Protection
              Legislation and must be governed by a contract between Shoptype
              and Subprocessor that requires comparable protections to this Data
              Processing Addendum. A current list of Subprocessors may be found
              online{' '}
              <Link target="_blank" to={'/policies/subprocessors'}>
                here
              </Link>
              . If you object to the appointment of a Subprocessor you may
              terminate this agreement in accordance with our Terms of Service
              and your Shoptype Plus Agreement, if applicable.
            </li>
          </ol>
        </li>
        <li>
          <strong>Miscellaneous</strong>
          <ol>
            <li>
              In the event of any conflict or inconsistency between the
              provisions of the Agreement and this Addendum, the provisions of
              this Addendum shall prevail. For avoidance of doubt and to the
              extent allowed by applicable law, any and all liability under this
              Addendum, including limitations thereof, will be governed by the
              relevant provisions of the Agreement. You acknowledge and agree
              that Shoptype may amend this Addendum from time to time by posting
              the relevant amended and restated Addendum on Shoptype&rsquo;s
              website, available at{' '}
              <Link target="_blank" to={'/policies/dpa'}>
                <u>https://www.Shoptype.com/policies/dpa</u>
              </Link>
              and such amendments to the Addendum are effective as of the date
              of posting. Your continued use of the Services after the amended
              Addendum is posted to Shoptype&rsquo;s website constitutes your
              agreement to, and acceptance of, the amended Addendum. If you do
              not agree to any changes to the Addendum, do not continue to use
              the Service.
            </li>
            <li>
              Save as specifically modified and amended in this Addendum, all of
              the terms, provisions and requirements contained in the Agreement
              shall remain in full force and effect and govern this Addendum. If
              any provision of the Addendum is held illegal or unenforceable in
              a judicial proceeding, such provision shall be severed and shall
              be inoperative, and the remainder of this Addendum shall remain
              operative and binding on the parties.
            </li>
            <li>
              The terms of this Addendum shall be governed by and interpreted in
              accordance with the laws of the State of California and the laws
              of the USA applicable therein, without regard to principles of
              conflicts of laws. The parties irrevocably and unconditionally
              submit to the exclusive jurisdiction of the courts of the State of
              California with respect to any dispute or claim arising out of or
              in connection with this Addendum.
            </li>
          </ol>
        </li>
      </ol>
    </React.Fragment>
  );
};

export const COVID19 = () => {
  return (
    <React.Fragment>
      <h1>Rules of Engagement for the Sale of COVID‑19 Related Products</h1>
      <p>
        Shoptype supports the sale of products to help combat the spread of
        COVID‑19 during this unprecedented global health crisis.
      </p>
      <p>
        While we are committed to helping communities get what they need at this
        critical time, any COVID‑19 related products sold through
        Shoptype&rsquo;s platform must comply with our{' '}
        <Link target="_blank" to={'/policies/terms-of-service'}>
          <u>Terms of Service</u>
        </Link>
        , including our{' '}
        <Link target="_blank" to={'/policies/acceptable-use-policy'}>
          <u>Acceptable Use Policy</u>
        </Link>
        &nbsp;and these Rules of Engagement. Violation of these Rules of
        Engagement may result in the removal of products from your store or the
        suspension or termination of your account.
      </p>
      <p>
        This page may change as the pandemic situation evolves, so please check
        back from time to time to ensure your products remain in compliance.
      </p>
      <h2>Rules of Engagement</h2>
      <p>
        <strong>Price Gouging</strong>: You must not charge excessive prices or
        engage in deceptive pricing practices for necessary COVID‑19 related
        products (for example, for the resale of medical grade masks, toilet
        paper, hand sanitizer).
      </p>
      <p>
        <strong>Claims</strong>: Any medical, scientific or other claims must be
        true and supported by documented evidence. Products claiming to prevent,
        treat or cure COVID‑19 will be removed from the Shoptype platform.
      </p>
      <p>
        <strong>Compliance with laws</strong>: You must comply with all
        applicable laws and other regulatory requirements relating to the sale
        of COVID‑19 related products, both in your jurisdiction and in the
        jurisdiction of your customers.
      </p>
      <p>
        <strong>Appropriate licensing or permissions</strong>: You must obtain
        all applicable licenses or permissions for the products you sell. This
        includes all applicable licenses, approvals, or authorizations for the
        sale of medical or health-related products.
      </p>
      <p>
        <strong>Selling regulated products</strong>: Prior notice and/or
        approval by Shoptype is required for the sale of regulated products on
        our platform. In some cases (for example, selling government-approved
        test kits), we may require that you provide information or documentation
        in order to verify that you have complied with these Rules of Engagement
        and sign an additional agreement. Please contact us at{' '}
        <a href="mailto:legal@Shoptype.com">
          <u>legal@Shoptype.com</u>
        </a>
        .
      </p>
      <p>Last updated: July 31, 2020</p>
      <p>&copy; 2020 Awake Market, Inc.</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
};

export const ReturnPolicy = () => {
  return (
    <React.Fragment>
      <h2>Return Policy</h2>
      <h3>Return Policy for the US</h3>
      <p>
        At Shoptype, we do everything we can to ensure you are happy with your
        purchase, but we know that sometimes a product is just not right.
        We&rsquo;ve made our return policy as easy as possible.
      </p>
      <p>
        <strong>Please note:</strong>&nbsp;If you did not receive the items that
        you ordered or are missing items that were supposed to come with your
        hardware, please reach out to the Shoptype support team at
        retail-support@shoptype.com&nbsp;or via the{' '}
        <a href="https://help.shoptype.com/en/questions#/contact">
          <u>contact page here</u>
        </a>
        . We can only process these requests within 30 days after delivery.
      </p>
      <h3>
        <strong>Requirements</strong>
      </h3>
      <p>
        <strong>Within 30 Days</strong>&nbsp;- You must request a return within
        30 days of the product being shipped to you. Returns must be shipped
        back to our warehouse within 7 days of receiving your return label.
      </p>
      <p>
        <strong>
          Includes original packaging and product in new condition
        </strong>
        &nbsp;- Any returned product(s) must be returned in all of their
        original packaging, and in like-new condition. Shoptype may reject the
        returned product if there is damage to the product and/or product
        packaging.
      </p>
      <p>
        If a product is deemed unfit for return, Shoptype will notify the
        customer that the return has been rejected and no refund will be issued
        to the customer. The customer can choose one of the following options:
      </p>
      <ol>
        <li>
          The product will be shipped back to the customer at a shipping cost of
          $30 ; or
        </li>
        <li>The product will be recycled.</li>
      </ol>
      <p>
        <strong>Free Return Shipping</strong>&nbsp;- If you received free
        shipping for the original purchase of your product, you are eligible for
        free return shipping using a carrier that we&rsquo;ve selected. Please
        follow the instructions below. We are not responsible for risk of loss
        or damage for returning a product during shipment. We only offer free
        return shipping in Canada and the Continental US. International
        shipments must purchase return postage to process a return.
      </p>
      <p>
        Due to the nature of physical gift cards we are unable to offer any
        returns/refunds on gift card orders once they have been printed. If a
        card order has moved to the proofing stage but not printed, a $15 charge
        will apply, which will be taken from the refund provided.
      </p>
      <h3>
        <strong>Instructions</strong>
      </h3>
      <p>
        As long as your return adheres to the requirements described above,
        follow these steps to return your product(s):
      </p>
      <ol>
        <li>
          Please fill out the return form at{' '}
          <a href="https://hardware.shoptype.com/returns">
            <u>https://hardware.Shoptype.com/returns</u>
          </a>
          .
        </li>
        <li>
          After reviewing your return form, we will email you a return label.
          Print out the return label and securely attach it to a suitable
          shipping carton.
        </li>
        <li>
          Drop the package off at the shipping carrier&rsquo;s closest location.
        </li>
      </ol>
      <p>
        We will receive and review the products you have returned. If the
        returned products have met all of our return policy requirements, we
        will process the refund and send you a confirmation email. We are only
        able to credit the original credit card used to make the purchase.
        Please allow up to 8 business days for this process.
      </p>
      <p>
        If you have any questions or concerns about making a return, feel free
        to contact the Shoptype support team at
        retail-support@shoptype.com&nbsp;or via the{' '}
        <a href="https://help.shoptype.com/en/questions#/contact">
          <u>contact page here</u>
        </a>
        .
      </p>
    </React.Fragment>
  );
};

export const DCMA = () => {
  return (
    <React.Fragment>
      <h2>Shoptype DMCA Notice and Takedown Procedure</h2>
      <p>
        Shoptype supports the protection of intellectual property and asks their
        users to do the same. It is our policy to expeditiously respond to clear
        notices of alleged copyright infringement that comply with the United
        States Digital Millennium Copyright Act (&ldquo;DMCA&rdquo;), the text
        of which can be found at the U.S. Copyright Office website,{' '}
        <a href="https://www.copyright.gov/">
          <u>https://www.copyright.gov</u>
        </a>
        .
      </p>
      <p>
        It is expected that all users of any part of Shoptype will comply with
        applicable copyright laws. If, however, we receive proper notification
        of claimed copyright infringement, our response to such notices will
        include removing or disabling access to material claimed to be the
        subject of infringing activity and/or terminating the user&rsquo;s
        account.
      </p>
      <p>
        Please use the following form to report content on Shoptype that you
        believe violates or infringes your copyright. If you are not sure if
        copyright laws protect the material, we suggest that you first contact a
        lawyer.
      </p>
      <h3>Who owns the copyright?</h3>
      <p>
        Please select I am the copyright owner I am an authorized representative
        of the copyright owner None of the above
      </p>
      <h2>Your contact information</h2>
      <p>
        Please provide your contact information below. Note that your contact
        information may be provided to the person that posted the content you
        are reporting.
      </p>
      <p>First name</p>
      <p>Last name</p>
      <p>Company (optional)</p>
      <p>Address</p>
      <p>Telephone number</p>
      <p>Email</p>
      <h2>Identify your work</h2>
      <p>
        If available, please provide direct link(s) to the original work (NOT to
        the content you are reporting on Shoptype). This can be a representative
        list. If not available, please ensure you provide a detailed description
        of your work below.
      </p>
      <p>
        <strong>Links to the original work</strong>
      </p>
      <p>Link to original work</p>
      <p>Link to original work</p>
      <p>Link to original work</p>
      <p>Link to original work</p>
      <p>Link to original work</p>
      <p>
        <strong>Add another link</strong>
      </p>
      <p>
        <strong>Description of the original work</strong>
      </p>
      <p>
        Please provide a description of the original work (e.g. photograph I
        took, product description or blog post I wrote, video I made, etc.)
      </p>
      <h2>Content you want to report</h2>
      <p>
        Please note, you can only submit notices for one Shoptype hosted store
        at a time. Up to 100 links for the same store may be included in the
        same notice.
      </p>
      <p>
        <strong>Links to the allegedly infringing work</strong>
      </p>
      <p>
        Please provide link(s) to the content you believe violates or infringes
        your copyright on Shoptype. Ensure you have provided a comprehensive
        list to each individual page with the content you would like removed and
        not just the main shop URL.
      </p>
      <p>Link to allegedly infringing work</p>
      <p>Link to allegedly infringing work</p>
      <p>Link to allegedly infringing work</p>
      <p>Link to allegedly infringing work</p>
      <p>Link to allegedly infringing work</p>
      <p>
        <strong>Add another link</strong>
      </p>
      <p>
        <strong>
          Description of the specific material (or portion thereof) that is
          claimed to be infringing or be the subject of infringing activity and
          that is to be removed or access to which is to be disabled:
        </strong>
      </p>
      <h2>Declarations</h2>
      <p>
        I have a good faith belief that use of the material in the manner
        complained of is not authorized by the copyright owner, its agent, or
        the law.
      </p>
      <p>
        I swear, under penalty of perjury, that the information in the
        notification is accurate, and that I am the copyright owner or am
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed.
      </p>
      <p>
        I acknowledge that a copy of this infringement notice, including any
        contact information provided above, may be provided to the person that
        posted the content being reported.
      </p>
      <h2>Electronic Signature</h2>
      <p>
        Typing your name in the box below will act as your electronic signature.
      </p>
      <p>Requester signature</p>
      <p>
        <strong>Submit</strong>
      </p>
      <p>
        Should you wish not to use our online form, please provide all of the
        information requested in the form to our Designated Agent at the address
        below.
      </p>
      <p>
        In order to be effective, any such notification must include a physical
        or electronic signature of a person authorized to act on behalf of the
        owner of the exclusive copyright right that is allegedly infringed and
        must include a statement that the information in the notification is
        accurate, and under penalty of perjury, that the complaining party is
        authorized to act on behalf of the owner of the exclusive right that is
        allegedly infringed. Please note that you will be liable for damages
        (including costs and attorney's fees) if you materially misrepresent
        that material is infringing your copyright(s). Accordingly, if you are
        not sure if you are the proper copyright holder or if copyright laws
        protect the material, we suggest that you first contact a lawyer.
      </p>
      <h2>How to file a Counter Notice</h2>
      <p>
        A merchant who receives a DMCA Takedown Notice may make a counter
        notification pursuant to sections 512(g)(2) and (3) of the{' '}
        <a href="https://www.copyright.gov/">
          <u>US Copyright Act</u>
        </a>
        .
      </p>
      <p>
        To file a counter notification with us, you must provide a written
        communication that sets out the information specified in the list below
        by either following the instructions in the DMCA Takedown Notice email
        we sent you (preferred) or by sending the written communication to the
        Designated Agent details set out below (preferably via email).
      </p>
      <p>
        Please include the following information in your DMCA counter
        notification:
      </p>
      <ol>
        <li>
          Identification of the material that has been removed or to which
          access has been disabled and the location at which the material
          appeared before it was removed or access to it was disabled;
        </li>
        <li>Your name, address, and telephone number;</li>
        <li>
          The following statement: "I consent to the jurisdiction of Federal
          District Court for the judicial district in which my address is
          located or, if my address is located outside of the United States, I
          consent to the jurisdiction of the Federal District Court for the
          District of Delaware";
        </li>
        <li>
          The following statement: "I will accept service of process from the
          party who submitted the DMCA Infringement Notice or its agent";
        </li>
        <li>
          The following statement: "I swear, under penalty of perjury, that I
          have a good faith belief that the affected material was removed or
          disabled as a result of a mistake or misidentification of the material
          to be removed or disabled and that I am the merchant subject to the
          DMCA Takedown Notice or I am authorised to act on behalf of that
          merchant.";
        </li>
        <li>
          The following statement: "I acknowledge that a copy of this counter
          notification, including my full name and contact information, will be
          provided to the party that submitted the DMCA Takedown Notice."; and
        </li>
        <li>A physical or electronic signature.</li>
      </ol>
      <p>
        Please note: You will be liable for damages (including costs and
        attorneys&rsquo; fees) if you materially misrepresent that a product or
        activity is not infringing the copyrights of others or that the material
        or activity was removed or disabled by mistake or misidentification.
        Accordingly, if you are not sure whether certain material infringes the
        copyrights of others or that the material or activity was removed or
        disabled by mistake or misidentification, we suggest that you first
        contact a lawyer.
      </p>
      <h2>Designated Agent</h2>
      <p>Shoptype Trust &amp; Safety</p>
      <p>Awake Market, Inc.</p>
      <p>
        655 Oak Grove Ave., #329
        <br />
        Menlo Park, CA 94026
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>Email: legal@shoptype.com</p>
      <h2>Repeat Infringers</h2>
      <p>
        In accordance with Section 512(i)(1)(a) of the DMCA, Shoptype will, in
        appropriate circumstances, disable and/or terminate the accounts of
        users who are repeat infringers.
      </p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
};

export const PrivacyMerchants = () => {
  return (
    <React.Fragment>
      <h1>Privacy for Merchants</h1>
      <p>For merchants using Shoptype to power their stores</p>
      <p>&nbsp;</p>
      <h2>Introduction</h2>
      <p>
        If you are a merchant using Shoptype to power your business, we collect
        and use your personal information to provide you with the use of our
        platform and its services, and generally to help you better manage your
        business and your relationship with your customers. Additionally, please
        review our{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>overall privacy policy</u>
        </Link>
        &nbsp;that applies to everyone whose information we process.
      </p>
      <h2>What information we collect about you and why</h2>
      <p>
        We collect personal information when you sign up for Shoptype, when you
        use our platform, or when you otherwise provide us information. We may
        also use third party service providers to help us review accounts for
        fraud or other concerns. In general we need this information for you to
        be able to use our platform.
      </p>
      <table>
        <tbody>
          <tr>
            <td width="234">
              <p>
                <strong>What we collect</strong>
              </p>
            </td>
            <td width="234">
              <p>
                <strong>How we use it</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information you provide us about you and your business, like
                your name, company name, address, email address, and phone
                number.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide you with the use of our platform and other
                related services (e.g., to confirm your identity, to contact you
                about issues with the platform, to invoice you)
              </p>
              <p>●&nbsp;To advertise and market products or features to you</p>
              <p>●&nbsp;To comply with legal requirements</p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Payment information you provide us, such as your credit or debit
                card number or your bank account number.
              </p>
            </td>
            <td width="234">
              <p>●&nbsp;To charge for our services</p>
              <p>
                ●&nbsp;To provide{' '}
                <a href="https://help.shoptype.com/en//manual/payments/shoptype-payments">
                  Shoptype Payments
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information about how you access Shoptype websites, your
                account, and our platform, including information about the
                device and browser you use, your network connection, your IP
                address, and details about how you browse our websites and
                platform. We collect some of this information by using
                &ldquo;cookies&rdquo; or other similar technologies directly
                from your device. For more information about how we use these
                technologies, see our Cookie Policy.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide you use of, and to improve, our platform and
                other related services (e.g., identifying ways to make our
                platform easier to use or navigate)
              </p>
              <p>
                ●&nbsp;To personalize the platform for you (e.g., by showing you
                apps in our app store that we believe may be useful to you)
              </p>
              <p>●&nbsp;To advertise and market products or features to you</p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                If you use{' '}
                <a href="https://help.shoptype.com/en//manual/payments/shoptype-payments">
                  Shoptype Payments
                </a>
                , we collect more specific information you provide us about your
                business, including your business address, business type,
                business ID number, bank account information, date of birth (if
                you are an individual business owner), and in some
                circumstances, government-issued identification (such as your
                Social Security Number, Social Information Number, Employer
                Identification Number, or Tax Identification Number).
              </p>
            </td>
            <td width="234">
              <p>●&nbsp;To create and manage your Shoptype Payments account</p>
              <p>●&nbsp;To provide you with the Shoptype Payments services</p>
              <p>●&nbsp;To conduct fraud and risk monitoring</p>
              <p>
                ●&nbsp;To comply with legal requirements (such as &ldquo;know
                your customer&rdquo;, anti-money laundering, or anti-terrorism
                laws)
              </p>
              <p>●&nbsp;To provide tax documentation</p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>Copies of government-issued identification you provide us.</p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;If we need to verify your identity (e.g., to protect you
                against identity theft or fraud)
              </p>
              <p>
                ●&nbsp;To verify that we are speaking with you if you contact us
              </p>
              <p>●&nbsp;To comply with legal requirements</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        We also work with companies that provide us with information about
        merchants or prospective merchants (for example, to protect against
        fraud or if we&rsquo;re sponsoring an event).
      </p>
      <h2>When and why we share your information with others</h2>
      <p>
        Running a business is hard, and we work with a variety of companies to
        help us provide you with a range of services to help you support your
        business. We sometimes share your personal information with these
        companies to help us provide you with Shoptype services.
      </p>
      <p>We also share your personal information with others:</p>
      <ul>
        <li>
          to prevent or investigate suspected fraud, threats to physical safety,
          illegal activity, or violations of a contract (like our{' '}
          <Link target="_blank" to={'/policies/terms-of-service'}>
            <u>Terms of Service</u>
          </Link>
          ) or our policies (like our{' '}
          <Link target="_blank" to={'/policies/acceptable-use-policy'}>
            <u>Acceptable Use Policy</u>
          </Link>
          )
        </li>
        <li>to help us conduct marketing and advertising</li>
        <li>
          to comply with legal requirements, or to respond to court orders, or
          other similar government demands (for more information, please read
          our{' '}
          <Link target="_blank" to={'/policies/gvt-access-policy'}>
            <u>Government Access Policy</u>
          </Link>
          )
        </li>
        <li>if we merge with or are acquired by another company</li>
      </ul>
      <p>
        Additionally, almost every merchant using the Shoptype platform also
        uses non-Shoptype services to support their business (for example, apps
        from our app store, sales channels, payment gateways, or shipping
        providers).
      </p>
      <p>
        Shoptype doesn&rsquo;t control how these services use your personal
        information, and you should review any other service you use to make
        sure it meets your privacy expectations. When you choose to use
        non-Shoptype services, we tell you what information we will share with
        that service at your direction, so you can decide whether you want to
        use the service. You can review what access any app has to your
        information or your store&rsquo;s information on the &ldquo;App
        Details&rdquo; page in the Shoptype admin.
      </p>
      <h2>Your rights over your information</h2>
      <p>
        We believe that you should be able to <u>access and control</u>
        &nbsp;your own personal information no matter where you live. You can
        access and correct a lot of your personal information directly through
        the Shoptype admin. For information you are not able to access or
        correct directly within the Shoptype admin, please email us at
        privacy@Shoptype.com.
      </p>
      <p>
        Because we need your personal information to provide Shoptype services,
        we generally keep your personal information while you use Shoptype
        products or services. We then retain your information before we begin
        our process to anonymize your personal information. We don&rsquo;t do
        this immediately in case you reactivate your account, or if there is a
        legal complaint or audit relating to your business. Your information
        will be automatically erased a short time after closing your store. If
        you have questions about this process, please{' '}
        <a href="https://www.shoptype.com/contact">
          <u>contact</u>
        </a>
        &nbsp;our Support team. Please keep in mind that after we anonymize your
        personal information, we may continue to use non-identifiable
        information to improve our services.
      </p>
      <p>
        Finally, we do not and will not &ldquo;sell&rdquo; your personal
        information, as that term is used in California law.
      </p>
      <h2>Your customers&rsquo; information</h2>
      <p>
        In order to power your business, we collect and use personal information
        about your customers. In general, we only collect and use this personal
        information as directed by you, and as further described in our{' '}
        <Link target="_blank" to={'/policies/dpa'}>
          <u>Data Processing Addendum</u>
        </Link>
        . Legally speaking, we are a &ldquo;data processor&rdquo; and a
        &ldquo;service provider&rdquo; as these terms are used in European and
        California privacy law. We will never use your customers&rsquo;
        information to independently market or advertise to your customers
        unless they are also using one of <u>our applications or services</u>
        &nbsp;directly. We also do not and will not &ldquo;sell&rdquo; your
        customers&rsquo; information, as that term is used in California law.
      </p>
      <p>
        Because you decide how the personal information of your customers will
        be used, you need to make sure your customers understand how you (and
        how we on your behalf) collect and process their personal information.
        You should do this by, at a minimum, posting a privacy policy on your
        store that describes the information you collect, how you use it, and
        who you share it with.
      </p>
    </React.Fragment>
  );
};

export const PrivacyCustomers = () => {
  return (
    <React.Fragment>
      <h1>Privacy for Customers</h1>
      <h2>For customers that shop at Shoptype-powered stores</h2>
      <h2>Introduction</h2>
      <p>
        Shoptype provides software used by our merchants to run their
        businesses. If you have visited or made a purchase from a
        merchant&rsquo;s Shoptype-powered store, we collect and use information
        about you on behalf of that particular merchant. We only use this
        information at the direction of our merchants&mdash;they ultimately
        choose how and why to use your information. If you have questions about
        how a specific merchant or store uses your information, please visit
        their privacy policy.
      </p>
      <p>
        Additionally, please review our{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>overall privacy policy</u>
        </Link>
        &nbsp;that applies to everyone whose information we process.
      </p>
      <h2>What information we collect about you and why</h2>
      <p>
        Please note that Shoptype will <strong>never</strong>&nbsp;use your
        personal information to independently market or advertise to you, unless
        you are using one of our <u>apps or services</u>&nbsp;directly.
      </p>
      <table>
        <tbody>
          <tr>
            <td width="234">
              <p>
                <strong>What we collect</strong>
              </p>
            </td>
            <td width="234">
              <p>
                <strong>How we use it</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information you provide about yourself like your name, billing
                address, shipping address, email address, phone number, and
                payment information.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide merchants with our services, and enable them
                to operate their business&mdash;e.g., to allow them to complete
                a transaction, screen orders for fraud and risk, fulfill orders,
                and contact you with new products or offers
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information about how you access our websites, your account, and
                our platform, including information about the device and browser
                you use, your network connection, your IP address, and details
                about how you browse through our websites and platform. We
                collect some of this information by using &ldquo;cookies&rdquo;
                or other similar technologies directly from your device. For
                more information about how we use these technologies, see our
                Cookie Policy.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide merchants with our services, and enable them
                to operate their business (e.g., to provide them with analytics
                about how many people visit their store, and to help them
                troubleshoot and improve their store)
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>When and why we share your information with others</h2>
      <p>
        We{' '}
        <Link target="_blank" to={'/policies/subprocessors'}>
          <u>use service providers</u>
        </Link>
        &nbsp;to help us provide services to our merchants. Outside of these
        service providers, we will only share your information if we are legally
        required to do so (for example, if we receive a legally binding court
        order or subpoena), or where a Shoptype merchant instructs us to share
        information on their behalf. If you have questions about how a merchant
        or store shares your personal information, you should contact the
        merchant or visit their privacy policy.
      </p>
      <h2>Your rights over your information</h2>
      <p>
        When you visit or make a purchase from a merchant&rsquo;s
        Shoptype-powered store, the merchant you're visiting or buying from
        legally controls your information. As a result, Shoptype doesn&rsquo;t,
        for example, decide how long your information is retained because that
        decision is made by the merchant. This means Shoptype can't help you
        access, correct, erase, or port your information without being directed
        to by the merchant. To make a request about your personal information,
        contact the specific merchant directly. If you make a request to us, we
        will forward your request to the relevant merchant and help them fulfil
        your request. If the merchant no longer exists, however, we may be able
        to assist you directly. We also do not and will not &ldquo;sell&rdquo;
        your customers&rsquo; information, as that term is used in California
        law.
      </p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
};

export const PrivacyPartners = () => {
  return (
    <React.Fragment>
      <p>Privacy for Shoptype Partners</p>
      <h2>
        For Shoptype Partners who develop apps, build stores, refer merchants,
        or help merchants operate their stores
      </h2>
      <h2>Introduction</h2>
      <p>
        If you participate in Shoptype&rsquo;s partner program (for example, by
        developing apps or themes for use by Shoptype merchants, by serving as
        an affiliate that refers potential merchants to us, or by helping
        merchants build or manage their stores), we collect and use your
        personal information to provide you with the use of our platform and its
        services, and generally to help you work with our merchants.
        Additionally, please review our{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>overall privacy policy</u>
        </Link>
        &nbsp;that applies to everyone whose information we process.
      </p>
      <h2>What information we collect about you and why</h2>
      <p>
        We collect the following personal information about you when you sign up
        as a Shoptype partner or affiliate, or when you otherwise provide us
        with this information.
      </p>
      <table>
        <tbody>
          <tr>
            <td width="234">
              <p>
                <strong>What we collect</strong>
              </p>
            </td>
            <td width="234">
              <p>
                <strong>How we use it</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information you provide about yourself and your business, like
                your name, company name, address, email address, and phone
                number.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide you with the use of our platform and other
                related services (e.g., to confirm your identity, to contact you
                about issues with the platform, to invoice you)
              </p>
              <p>●&nbsp;To advertise and market products or features to you</p>
              <p>●&nbsp;To comply with legal requirements</p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Payment information you provide, such as your credit or debit
                card number or your bank account number.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To process payments from you, or to remit payments to you
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information about how you access Shoptype websites, your
                account, and our platform, including information about the
                device and browser you use, your network connection, your IP
                address, and details about how you browse our websites and
                platform. We collect some of this information by using
                &ldquo;cookies&rdquo; or other similar technologies directly
                from your device. For more information about how we use these
                technologies, see our Cookie Policy.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide you use of, and to improve, our platform and
                other related services (e.g., identifying ways to make our
                platform easier to use or navigate)
              </p>
              <p>
                ●&nbsp;To personalize the platform for you (e.g., by showing you
                apps in our app store that we believe may be useful to you)
              </p>
              <p>●&nbsp;To advertise and market products or features to you</p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Copies you provide of government-issued identification you
                provide us.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;If we need to verify your identity (e.g., to protect you
                against identity theft or fraud)
              </p>
              <p>
                ●&nbsp;To verify that we are speaking with you if you contact us
              </p>
              <p>●&nbsp;To comply with legal requirements</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>When and why we share your information with others</h2>
      <p>
        We need to share your personal information with companies who help us
        provide you with our services, and to connect you with merchants that we
        believe may be interested in your services. We also share your personal
        information with others:
      </p>
      <ul>
        <li>
          to prevent or investigate suspected fraud, threats to physical safety,
          illegal activity, or violations of a contract (like our{' '}
          <Link target="_blank" to={'/policies/terms-of-services'}>
            <u>Terms of Service</u>
          </Link>
          ) or our policies (like our{' '}
          <Link target="_blank" to={'/policies/acceptable-use-police'}>
            <u>Acceptable Use Policy</u>
          </Link>
          )
        </li>
        <li>to help us conduct marketing and advertising</li>
        <li>
          to comply with legal requirements, or to respond to court orders,
          subpoenas, warrants, or other similar government demands (for more
          information, please read our{' '}
          <Link target="_blank" to={'/policies/gvt-access-policy'}>
            <u>Government Access Policy</u>
          </Link>
          )
        </li>
        <li>if we merge with or are acquired by another company</li>
      </ul>
      <h2>Your rights over your information</h2>
      <p>
        We believe that you should be able to <u>access and control</u>
        &nbsp;your own personal information no matter where you live. You can
        access and correct a lot of your personal information directly through
        the Shoptype admin. For information you are not able to access or
        correct directly within the Shoptype admin, please email us at
        privacy@Shoptype.com.
      </p>
      <p>
        When you sign up for the partner program you remain in the program until
        you tell us otherwise. Please email us at privacy@Shoptype.com&nbsp;if
        you would like us to delete your personal information. Please keep in
        mind that after we anonymize your personal information, we will continue
        to use non-identifiable information to improve our services.
      </p>
      <p>
        Finally, we do not and will not &ldquo;sell&rdquo; your personal
        information, as that term is used in California law.
      </p>
      <h2>Merchants that use your services</h2>
      <p>
        Shoptype Partners provide a range of critical services to our merchants.
        When merchants sign up to use your services (for example, when they
        install an app you have developed), we will provide you with their
        personal information on the merchant&rsquo;s instruction. When we do
        this, we are acting on behalf of our merchants. It is your
        responsibility to protect our merchant&rsquo;s personal information and
        process it safely and securely in accordance with applicable law, our{' '}
        <u>Partner Program Agreement</u>, <u>API License &amp; Terms of Use</u>,
        any{' '}
        <Link target="_blank" to={'/policies/terms-of-service'}>
          <u>other terms</u>
        </Link>
        &nbsp;governing other Shoptype services you may use. Shoptype also
        requires you to provide merchants with a clear and transparent privacy
        policy to help them understand your privacy practices, and how you use
        the personal information they provide you.
      </p>
    </React.Fragment>
  );
};

export const PrivacyVisitors = () => {
  return (
    <React.Fragment>
      <h1>Privacy for Shoptype Visitors</h1>
      <h2>
        For people who visit Shoptype&rsquo;s websites or contact Shoptype
        support
      </h2>
      <h2>&nbsp;</h2>
      <h2>Introduction</h2>
      <p>
        If you visit one of Shoptype&rsquo;s websites or contact Shoptype
        support, we generally use your personal information to provide you with
        the specific service you are using. Additionally, please review our{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>overall privacy policy</u>
        </Link>
        &nbsp;that applies to everyone whose information we process.
      </p>
      <h2>What information we collect about you and why</h2>
      <p>
        We collect the following personal information about you when you visit
        one of our websites or contact Shoptype support:
      </p>
      <table>
        <tbody>
          <tr>
            <td width="234">
              <p>
                <strong>What we collect</strong>
              </p>
            </td>
            <td width="234">
              <p>
                <strong>How we use it</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information about you that you provide us about yourself, like
                your name, address, email address, and phone number.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide you use of, and to improve, the app or service
                you are trying to use (e.g., to confirm your identity, to
                contact you about issues with the platform, to invoice you)
              </p>
              <p>●&nbsp;To verify that we are speaking with you</p>
              <p>●&nbsp;To advertise and market products or features to you</p>
              <p>●&nbsp;To comply with legal requirements</p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Payment information you provide us, such as your payment card
                number or bank account number.
              </p>
            </td>
            <td width="234">
              <p>●&nbsp;To enable you to make a payment</p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>
                Information about how you access and use Shoptype apps,
                websites, and other services, including information about the
                device and browser you use, your network connection, your IP
                address, and details about how you browse through our apps and
                sites, including search terms you may enter. We collect some of
                this information by using &ldquo;cookies&rdquo; or other similar
                technologies directly from your device. For more information
                about how we use these technologies, see our Cookie Policy.
              </p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To provide you use of, and to improve, our websites and
                related services (e.g., identifying ways to make our platform
                easier to use or navigate)
              </p>
              <p>
                ●&nbsp;To personalize your experience using our websites (e.g.,
                by changing the layout of our webpages)
              </p>
              <p>●&nbsp;To advertise and market products or features to you</p>
              <p>
                ●&nbsp;To provide app developers aggregated analytics relating
                to their app listing on Shoptype&rsquo;s App Store
              </p>
            </td>
          </tr>
          <tr>
            <td width="234">
              <p>Copies of your government-issued identification.</p>
            </td>
            <td width="234">
              <p>
                ●&nbsp;To verify that we are speaking with you if you contact us
              </p>
              <p>●&nbsp;To comply with other legal requirements</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>When and why we share your information with others</h2>
      <p>
        We share your personal information with companies who help us provide
        you with our services. We also share your personal information with
        others:
      </p>
      <ul>
        <li>
          to prevent or investigate suspected fraud, threats to physical safety,
          illegal activity, or violations of a contract (like our{' '}
          <Link target="_blank" to={'/policies/terms-of-service'}>
            <u>Terms of Service</u>
          </Link>
          ) or our policies (like our{' '}
          <Link target="_blank" to={'/policies/acceptable-use-policy'}>
            <u>Acceptable Use Policy</u>
          </Link>
          )
        </li>
        <li>to help us conduct marketing and advertising</li>
        <li>
          to comply with legal requirements, or to respond to court orders or
          other similar government demands (for more information, please read
          our{' '}
          <Link target="_blank" to={'/policies/gvt-access-policy'}>
            <u>Government Access Policy</u>
          </Link>
          )
        </li>
        <li>if we merge with or are acquired by another company</li>
      </ul>
      <h2>Your rights over your information</h2>
      <p>
        We believe that you should have the right to access and control your own
        personal information, no matter where you live. We retain your personal
        information until you tell us to delete it. Please email us at
        privacy@Shoptype.com&nbsp;if you would like to access or request that we
        delete your personal information. Please keep in mind that after we
        anonymize your information, we will continue to use non-identifiable
        information to improve our services. Finally, we do not and will not
        &ldquo;sell&rdquo; your personal information, as that term is used in
        California law.
      </p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
};

export const GuidelinesForLegalRequests = () => {
  return (
    <React.Fragment>
      <h1>Guidelines for Legal Requests for Information</h1>
      <h3>
        Note: Due to office closures, we cannot accept service at any of our
        physical locations until further notice.
      </h3>
      <p>
        Legal requests should be directed to legal-orders@Shoptype.com until
        further notice.
      </p>
      <p>
        <strong>
          Note: This guide does not cover individuals seeking access to their
          own information.
        </strong>
      </p>
      <p>
        Please consult our{' '}
        <Link target="_blank" to={'/policies/privacy-policy'}>
          <u>Privacy Policy</u>
        </Link>
        &nbsp;for more information about your right of access.
      </p>
      <h3>Introduction</h3>
      <p>
        Shoptype supports more than a million merchants, who in turn interact
        with millions of customers across the globe. Some of these merchants (or
        customers) occasionally find themselves in legal disputes which might
        lead to a legal request for information about a particular merchant,
        store, or transaction. Shoptype also receives requests from law
        enforcement or other government institutions for personal information in
        the context of an investigation or enforcement of applicable laws. These
        guidelines explain how we balance our legal obligations, the requesting
        party&rsquo;s needs, and the privacy rights of our merchants and their
        customers.
      </p>
      <p>
        These are the principles that guide us when we respond to legal
        requests:
      </p>
      <ul>
        <li>
          When a third party demands identifiable non-public information (such
          as personal or financial information about a merchant or customer), we
          will not share this information unless we are legally required to do
          so;
        </li>
        <li>
          We will notify affected individuals before we produce information
          about them, unless we are legally prohibited from doing so;
        </li>
        <li>
          When a third party requests information about a merchant, we will not
          also provide information about the individual&rsquo;s activity as a
          partner, customer, or a user of Shoptype&rsquo;s consumer-facing apps
          or services unless the legal request or court order specifically
          identifies and requests this additional information; and
        </li>
        <li>
          When a third party requests information about a merchant, we will not
          provide information about that merchant&rsquo;s customers. This
          information is in the merchant&rsquo;s custody and control. If you are
          requesting information about a merchant&rsquo;s customers, please send
          your request directly to the relevant merchant.
        </li>
      </ul>
      <h3>Shoptype&rsquo;s Terminology</h3>
      <p>
        To better understand Shoptype records, it is helpful to be familiar with
        a few terms that we commonly use when talking about our business:
      </p>
      <ul>
        <li>
          &ldquo;Merchants&rdquo; are individuals or businesses who use
          Shoptype&rsquo;s platform to power their stores in any capacity. We
          consider all individuals who access Shoptype on behalf of a store (or
          multiple stores) to be a merchant&mdash;not just the listed account
          owners.
        </li>
        <li>
          &ldquo;Customers&rdquo; are individuals who visit or make a purchase
          from a Merchant.
        </li>
        <li>
          &ldquo;
          <a href="https://www.shoptype.com/partners/">
            <u>Partners</u>
          </a>
          &rdquo; are third parties who provide services to Merchants, such as:
          developing apps or themes that can be used in a Merchant&rsquo;s
          store; helping Merchants build or set up their stores; or referring
          potential entrepreneurs looking to become a Merchant.
        </li>
      </ul>
      <h3>What Do I Need to Submit?</h3>
      <p>
        Each request should include the following information to assist with
        processing your request and reduce the chance of a rejection:
      </p>
      <ol>
        <li>
          The name of the requesting body
          <ul>
            <li>
              For example, the court that issued the subpoena, or government
              agency that authorized the request
            </li>
          </ul>
        </li>
        <li>The law or other authority that compels production</li>
        <li>
          The Shoptype entity from which you are requesting records
          <ul>
            <li>
              Please see below for which entity controls the information you are
              seeking
            </li>
            <li>
              We cannot respond to requests directed to &ldquo;Shoptype&rdquo;
              or &ldquo;Shoptype.com&rdquo; - you must specify one of our
              corporate entities
            </li>
          </ul>
        </li>
        <li>
          The subject of the request
          <ul>
            <li>
              For example, an individual&rsquo;s name, email, business name, or
              website address
            </li>
            <li>
              Shoptype cannot identify individuals with a common name (eg: Jane
              Smith) without additional information
            </li>
            <li>
              <strong>
                Please do not provide us with an individual&rsquo;s SSN/SIN,
                other government identification number, or a bank account
                number, or payment card number
              </strong>
            </li>
          </ul>
        </li>
        <li>
          The specific information requested
          <ul>
            <li>
              See &lsquo;What Types of Information does Shoptype Control?&rsquo;
              section below
            </li>
          </ul>
        </li>
        <li>
          If the request must be kept secret, please: (1) enclose a
          non-disclosure or restraining order, or (2) identify the legal or
          regulatory basis for the requirement
          <ul>
            <li>
              We will not notify the subject upon receipt of a Grand Jury
              Subpoena
            </li>
          </ul>
        </li>
        <li>
          If you require a Custodian of Records Affidavit or Certificate of True
          Copy, please let us know.
        </li>
        <li>Deadlines for production, where relevant</li>
        <li>
          Where Shoptype should send the production
          <ul>
            <li>For example, email address of the requester</li>
            <li>
              We strongly prefer to provide information electronically where
              feasible. Requiring physical production will delay our ability to
              respond.
            </li>
          </ul>
        </li>
      </ol>
      <h3>What Types of Information Does Shoptype Control?</h3>
      <p>
        In order for us to properly respond to your request, you must identify
        the type of information you require -- please use the specific defined
        categories below. This enables us to make sure that the proper Shoptype
        entity is responding to your request, and that we respond in line with
        relevant laws. This also allows us to adopt different approaches
        depending on the sensitivity of the information requested. We break our
        information into four categories, as follows:
      </p>
      <ul>
        <li>
          <strong>Store Information</strong>
          <ul>
            <li>
              This includes non-public information relating to a specific store,
              such as store revenues, countries into which a store has sold,
              invoices for Shoptype services rendered, method of payment for
              Shoptype services, connected bank account(s), product lists, store
              transactions (without customer information), payouts (if using
              Shoptype Payments), tax documents, contracts between the store and
              Shoptype, and apps installed on a store.
            </li>
            <li>
              This information is generally protected under our contracts with
              our Merchants.
            </li>
            <li>
              To request this information, please send a request to the Shoptype
              entity which processes information in the merchant&rsquo;s
              location. You can find the specific contracting entity in our
              Contracting Party Chart. We cannot respond to a request unless you
              make the request to the proper legal entity. Please ensure that
              the requesting body has jurisdiction to compel the Shoptype entity
              to produce documentation (see &lsquo;Jurisdiction&rsquo; section
              below for any questions).
            </li>
          </ul>
        </li>
        <li>
          <strong>Merchant or Partner Information</strong>
          <ul>
            <li>
              This includes information about the identity of a specific
              Merchant or Partner as an individual, such as their name, listed
              address, phone number, email address, IP addresses, and any staff
              members on their account.
              <ul>
                <li>
                  Please note that Shoptype{' '}
                  <strong>
                    does not control the flow of funds to a merchant
                  </strong>
                  &mdash;this is done independently by the third party payment
                  processor used by the merchant. Merchants are not entitled to
                  any revenues from transactions until their funds clear the
                  credit card settlement process and their payment processor
                  deposits them into a merchants&rsquo; bank account (with no
                  involvement from Shoptype). As such,{' '}
                  <strong>
                    we cannot garnish receivables or apply liens or levies, even
                    on money generated through Shoptype Payments.
                  </strong>
                </li>
              </ul>
            </li>
            <li>
              This information is generally protected under applicable privacy
              and data protection laws.
            </li>
            <li>
              To request this information, please send a request to the Shoptype
              entity which processes information in the merchant&rsquo;s
              location. You can find the specific contracting entity in our
              Contracting Party Chart. We cannot respond to a request unless you
              make the request to the proper legal entity. Please ensure that
              the requesting body has jurisdiction to compel the Shoptype entity
              to produce documentation (see &lsquo;Jurisdiction&rsquo; section
              below for any questions).
            </li>
          </ul>
        </li>
        <li>
          <strong>App Information</strong>
          <ul>
            <li>
              This includes information about users of Shoptype&rsquo;s
              consumer-facing apps and services, like Shoptype Payments. For
              these services, we hold information about how the individual uses
              the service, their account registration information, and when
              someone uses Shop Pay, their stored payment information.
              <ul>
                <li>
                  We will not produce this information unless the request
                  specifically identifies one of these services.
                </li>
              </ul>
            </li>
            <li>
              This information is generally protected under applicable privacy
              and data protection laws.
            </li>
            <li>
              To request this information, please send a request to Shoptype
              Inc. We cannot respond to a request unless you make the request to
              the proper legal entity. Please ensure that the requesting body
              has jurisdiction to compel the Shoptype entity to produce
              documentation (see &lsquo;Jurisdiction&rsquo; section below for
              any questions).
            </li>
          </ul>
        </li>
        <li>
          <strong>Other Information</strong>
          <ul>
            <li>
              We also store other information associated with specific Shoptype
              services, or our corporate websites. Please contact us for more
              information if you require information relating to one of these
              services. We will not produce information about these additional
              services unless your request specifically identifies the service.
            </li>
          </ul>
        </li>
      </ul>
      <h3>How do I serve my request on Shoptype?</h3>
      <p>
        Each Shoptype entity has its own service address (See the &lsquo;Types
        of Information&rsquo; section for more details about which entity
        controls the information you seek). As discussed above, the proper
        entity might depend on the location of the specific merchant subject to
        your request. If you are unsure of the proper entity, or if you name the
        wrong entity, we can direct you to the appropriate Shoptype entity that
        maintains the relationship with a particular merchant.
      </p>
      <p>
        Additionally, in many cases we may be willing to accept service by
        email. To inquire about email service, please reach out to us via
        legal-orders@Shoptype.com. Service by email is for Shoptype&rsquo;s
        convenience only and does not waive any objections, including lack of
        jurisdiction, subpoena power, or improper service.
      </p>
      <p>For service by mail, please see our contact information below:</p>
      <p>
        <strong>Awake Market, Inc.</strong>&nbsp;ATTN: Legal/Privacy Team
      </p>
      <p>
        655 Oak Grove Ave., #329
        <br />
        Menlo Park, CA 94026
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Awake Market, Ltd.</strong>&nbsp;ATTN: Legal/Privacy Team
      </p>
      <p>Address in Dublin, Ireland TBD</p>
      <p>
        <strong>Awake Market Singapore PTE. LTD.</strong>&nbsp;ATTN:
        Legal/Privacy Team
      </p>
      <p>Address in Singapore TBD</p>
      <p>
        Please note that any of our offices other than those listed above, are
        not authorized to receive service on their behalf. We may object to
        requests that are improperly served, which may delay your receipt of
        information you are demanding.
      </p>
      <h3>Jurisdiction</h3>
      <p>
        We will produce information when the requesting body has legal authority
        to issue - and jurisdiction to enforce - the relevant request, subpoena,
        or court order. As States may only exercise their legal authority within
        their territorial borders, except when they have obtained legal
        authority through mutual legal assistance processes in another State,
        this may occur in two types of cases
      </p>
      <ol>
        <li>
          <strong>
            The requesting body is in the same jurisdiction as the Shoptype
            entity that holds the data
          </strong>
          <ul>
            <li>
              A Shoptype entity may respond to requests issued by courts or
              authorities in the country in which the entity is located. Since
              Awake Market, Inc. is a USA entity, it may provide any information
              it holds in response to an order issued by a USA court or a
              warrant issued by a USA authority.
            </li>
            <li>
              If you are making a request from another jurisdiction, you will
              generally need to go through the Mutual Legal Assistance Treaty
              (&ldquo;MLAT&rdquo;) or Letters Rogatory process to have your
              request issued by an authority from the country in which the
              relevant entity is located, unless you fall into the category
              below.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            The requesting body is in the same jurisdiction as the data subject.
          </strong>
          <ul>
            <li>
              A Shoptype entity may also respond to a court order from an
              authority in the same jurisdiction as the merchant, to the extent
              that it is legally permitted to do so. For example, even though
              Awake Market Limited is incorporated in Ireland, it may provide
              information about merchants with registered addresses in France in
              response to a valid request from a French authority.
              <ul>
                <li>
                  Specific jurisdiction in the United States is determined on a
                  state-by-state basis (see &lsquo;Requests from the United
                  States&rsquo; below).
                </li>
              </ul>
            </li>
            <li>
              If the merchant or partner is located outside of your
              jurisdiction, you will also have to go through the MLAT or Letters
              Rogatory process.
            </li>
          </ul>
        </li>
      </ol>
      <h3>Requests from the United States</h3>
      <p>
        If you would like to make a request from the United States directed to a
        non-US entity, we treat jurisdiction on a state-by-state basis in
        accordance with American law. Accordingly, you will need to issue or
        domesticate the request in the particular state in which the relevant
        merchant(s) or partner(s) are located - unless the requesting body has
        statutory federal authority. For example, Awake Market, Inc. may only
        provide information about merchants who have registered for our services
        in New York in response to a request from a New York authority.
      </p>
      <h3>Frequently Asked Questions</h3>
      <h4>
        Will Shoptype Notify Affected Merchants Before Disclosing Information?
      </h4>
      <p>
        <strong>Yes.</strong>&nbsp;Unless we are legally prohibited from doing
        so, we will notify the affected Merchant or Partner. You must notify us
        if there is a legal prohibition on notification, or if you have obtained
        or intend to obtain a court order preventing notification. If you do not
        notify us of any such order or prohibition, we will default to notifying
        the affected Merchant. If a Merchant informs us that they intend to seek
        a protective order, we will inform you of this.
      </p>
      <p>
        Additionally, if in the course of responding to a request we conclude
        that a particular store may be violating Shoptype&rsquo;s policies, we
        may decide to terminate the store. Please inform us if doing so would
        jeopardize an ongoing matter or we should not do so for some other legal
        reason.
      </p>
      <h4>Does Shoptype Charge Anything to Respond to a Legal Request?</h4>
      <p>
        <strong>Not usually.</strong>&nbsp;However, we reserve the right to seek
        reimbursement for the costs associated with responding to legal requests
        (where permitted by law).
      </p>
      <h4>How Does Shoptype Handle Data Preservation Orders?</h4>
      <p>
        We generally keep shop data for the lifetime of a store, and do not
        require a preservation order to maintain this data while a store remains
        active. We purge personal information within 90 days of a store&rsquo;s
        deactivation, after which the identifiable information is no longer
        capable of being recovered. If you require specific information to be
        preserved, please send us a preservation order with a discrete list of
        data to preserve. We are not able to archive an entire store or recreate
        how that store appeared to visitors on a given date.
      </p>
      <h3>Transparency Report</h3>
      <p>
        For data on how many requests for information we receive, please see our{' '}
        <u>Transparency Report</u>&nbsp;when one is available
      </p>
      <p>Last updated: July 31, 2020</p>
      <p>&copy; 2020 Awake Market, Inc.</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
};

export const Security = () => {
  return (
    <React.Fragment>
      <h1>Security</h1>
      <p>
        You put a lot of trust in Shoptype, and we take that seriously. Our
        certifications and the standards we choose to meet are one way we
        demonstrate our commitment to maintaining your trust.
      </p>
      <h3>Privacy</h3>
      <p>
        Shoptype has designed its platform to enable you to offer your customers
        transparency into and control over their personal information. Shoptype
        believes in making it easy for you to use its platform in a manner that
        complies with privacy and data protection laws around the world.
      </p>
      <p>
        Shoptype does what it can to set you up for success, but there are also
        steps you will need to take on your own. Refer to our help center pages
        on <u>General Data Protection Regulation (GDPR)</u>&nbsp;and{' '}
        <u>California Consumer Privacy Act (CCPA)</u>&nbsp;to start thinking
        about privacy laws that are applicable to your store. If you have
        specific legal questions about which laws apply to you, or questions
        specific to your business, you should consult with a local lawyer who is
        familiar with data protection laws.
      </p>
      <h3>Transparency Report</h3>
      <p>
        Shoptype&rsquo;s Transparency Report provides data about legal requests
        for information about our merchants, customers, and partners for 2020.
        It describes how requests are evaluated, and how often they are filled.
      </p>
      <p>
        Read our <u>Transparency Report</u>&nbsp;when we have one.
      </p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
};

export const Subprocessors = () => {
  return (
    <React.Fragment>
      <h2>Shoptype's subprocessors</h2>
      <p>Shoptype uses the following subprocessors for customer data:</p>
      <table>
        <tbody>
          <tr>
            <td width="156">
              <p>
                <strong>Subprocessor</strong>
              </p>
            </td>
            <td width="156">
              <p>
                <strong>Service Provided</strong>
              </p>
            </td>
            <td width="156">
              <p>
                <strong>Corporate Headquarters</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Awake Market Inc.</p>
            </td>
            <td width="156">
              <p>Providing and improving the Shoptype Payments service</p>
            </td>
            <td width="156">
              <p>USA, Canada, Latin America</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Awake Market Limited</p>
            </td>
            <td width="156">
              <p>Providing and improving the Shoptype Payments service</p>
            </td>
            <td width="156">
              <p>Ireland, UK, EMEA</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Awake Market &nbsp;Singapore Pte. Ltd.</p>
            </td>
            <td width="156">
              <p>
                Providing and improving the Shoptype platform and other related
                services
              </p>
            </td>
            <td width="156">
              <p>Singapore, Asia Pacific</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Akamai</p>
            </td>
            <td width="156">
              <p>Content delivery</p>
            </td>
            <td width="156">
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Amazon AWS</p>
            </td>
            <td width="156">
              <p>Cloud hosting</p>
            </td>
            <td width="156">
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Google Maps</p>
            </td>
            <td width="156">
              <p>Mapping delivery</p>
            </td>
            <td width="156">
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Quintype</p>
            </td>
            <td width="156">
              <p>Content delivery</p>
            </td>
            <td width="156">
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>ShipStation, LLC</p>
            </td>
            <td width="156">
              <p>Shipping services</p>
            </td>
            <td width="156">
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Slack Technologies, Inc.</p>
            </td>
            <td width="156">
              <p>Internal communications</p>
            </td>
            <td width="156">
              <p>USA</p>
            </td>
          </tr>
          <tr>
            <td width="156">
              <p>Zendesk Inc.</p>
            </td>
            <td width="156">
              <p>Customer support</p>
            </td>
            <td width="156">
              <p>USA</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
    </React.Fragment>
  );
};

export const AwakeMembershipAgreement = () => {
  return (
    <React.Fragment>
      <h2>
        <strong>
          <u>AWAKE MARKET MEMBERSHIP AGREEMENT</u>
        </strong>
      </h2>
      <p>
        Effective 1st of July, 2020 (&ldquo;the Awake Market Member&rdquo;) and
        Awake Market Inc. (&ldquo;Company&rdquo;) agree as follows:
      </p>
      <ol>
        <li>
          <strong>
            <u>
              <strong>
                Services; Payment; No Violation of Rights or Obligations
              </strong>
            </u>
          </strong>
          . &nbsp;The Awake Market Member agrees to undertake and complete the
          Services (as defined in <u>Exhibit A</u>) in accordance with and on
          the schedule specified in Exhibit A. &nbsp;As the only consideration
          due the Awake Market Member regarding the subject matter of this
          Agreement, Company will pay the Awake Market Member in accordance with{' '}
          <u>Exhibit A</u>. Unless otherwise specifically agreed upon by Company
          in writing (and notwithstanding any other provision of this
          Agreement), all activity relating to Services will be performed by and
          only by the Awake Market Member or by employees of the Awake Market
          Member and only those such employees who have been approved in writing
          in advance by Company. &nbsp;The Awake Market Member agrees that it
          will not (and will not permit others to) violate any agreement with or
          rights of any third party or, except as expressly authorized by
          Company in writing hereafter, use or disclose at any time the Awake
          Market Member&rsquo;s own or any third party&rsquo;s confidential
          information or intellectual property in connection with the Services
          or otherwise for or on behalf of Company.
        </li>
        <li>
          <strong>
            <u>
              <strong>Warranties and Other Obligations</strong>
            </u>
          </strong>
          . &nbsp;the Awake Market Member represents, warrants, and covenants
          that: &nbsp;(i) the Services will be performed in a professional and
          workmanlike manner and that none of such Services nor any part of this
          Agreement is or will be inconsistent with any obligation the Awake
          Market Member may have to others; (ii) all work under this Agreement
          shall be the Awake Market Member&rsquo;s original work and none of the
          Services or Inventions nor any development, use, production,
          distribution, or exploitation thereof will infringe, misappropriate,
          or violate any intellectual property or other right of any person or
          entity (including, without limitation, the Awake Market Member); (iii)
          the Awake Market Member has the full right to allow it to provide
          Company with the assignments and rights provided for herein (and has
          written enforceable agreements with all persons necessary to give it
          the rights to do the foregoing and otherwise fully perform this
          Agreement); (iv) the Awake Market Member shall comply with all
          applicable laws and Company safety rules in the course of performing
          the Services; and (v) if the Awake Market Member&rsquo;s work requires
          a license, the Awake Market Member has obtained that license and the
          license is in full force and effect.
        </li>
        <li>
          <strong>
            <u>
              <strong>Termination</strong>
            </u>
          </strong>
          . &nbsp;The term of this Agreement will begin on the effective date
          and will last for the duration specified in Exhibit A; if no duration
          is specified, the term will last until the Services are completed or
          the Agreement is terminated, whichever comes first. If either party
          breaches a material provision of this Agreement, the other party may
          terminate this Agreement upon ten (10) days&rsquo; notice, unless the
          breach is cured within the notice period. &nbsp;Company also may
          terminate this Agreement at any time, with or without cause, upon
          thirty (30) days&rsquo; notice, but, if (and only if) such termination
          is without cause, Company shall upon such termination pay the Awake
          Market Member all unpaid, undisputed amounts due for the Services
          completed prior to notice of such termination. &nbsp;Sections 2
          through 7 and Sections 9 and 10 of this Agreement and any remedies for
          breach of this Agreement shall survive any termination or expiration.
          &nbsp;Company may communicate the obligations contained in this
          Agreement to any other (or potential) client or employer of the Awake
          Market Member.
        </li>
        <li>
          <strong>
            <u>
              <strong>
                Relationship of the Parties; Independent Contractor; No Employee
                Benefits
              </strong>
            </u>
          </strong>
          . &nbsp;Notwithstanding any provision hereof, the Awake Market Member
          is an independent contractor and is not an employee, agent, partner,
          or joint venturer of Company and shall not bind nor attempt to bind
          Company to any contract. the Awake Market Member shall accept any
          directions issued by Company pertaining to the goals to be attained
          and the results to be achieved by the Awake Market Member, but the
          Awake Market Member shall be solely responsible for the manner and
          hours in which the Services are performed under this Agreement.
          &nbsp;the Awake Market Member shall not be eligible to participate in
          any of Company&rsquo;s employee benefit plans, fringe benefit
          programs, group insurance arrangements, or similar programs.
          &nbsp;Company shall not provide workers&rsquo; compensation,
          disability insurance, Social Security or unemployment compensation
          coverage, or any other statutory benefit to the Awake Market Member.
          the Awake Market Member shall comply at the Awake Market
          Member&rsquo;s expense with all applicable provisions of
          workers&rsquo; compensation laws, unemployment compensation laws,
          federal Social Security law, the Fair Labor Standards Act, federal,
          state and local income tax laws, and all other applicable federal,
          state and local laws, regulations and codes relating to terms and
          conditions of employment required to be fulfilled by employers or
          independent contractors. the Awake Market Member will ensure that its
          employees, contractors, and others involved in the Services, if any,
          are bound in writing to the foregoing, and to all of the Awake Market
          Member&rsquo;s obligations under any provision of this Agreement, for
          Company&rsquo;s benefit and the Awake Market Member will be
          responsible for any noncompliance by them. The Awake Market Member
          agrees to indemnify Company from any and all claims, damages,
          liability, settlement, attorneys&rsquo; fees and expenses, as
          incurred, on account of the foregoing or any breach of this Agreement
          or any other action or inaction by or for or on behalf of the Awake
          Market Member. &nbsp;&nbsp;
        </li>
        <li>
          <strong>
            <u>
              <strong>Assignment</strong>
            </u>
          </strong>
          . &nbsp;This Agreement and the services contemplated hereunder are
          personal to the Awake Market Member and the Awake Market Member shall
          not have the right or ability to assign, transfer, or subcontract any
          rights or obligations under this Agreement without the written consent
          of Company. Any attempt to do so shall be void. Company may fully
          assign and transfer this Agreement in whole or part. &nbsp;
        </li>
        <li>
          <strong>
            <u>
              <strong>Amendment</strong>
            </u>
          </strong>
          . Company may amend or replace this Agreement in part or or in its
          entirety at its sole discretion without providing prior notice to the
          Awake Market Member. Any amendment or replacement in part of this
          Agreement or in its entirety will supersede any previous provisions
          regarding the same topic or topics. An amendment or replacement will
          not affect previous transactions and will apply only to future
          transactions.
        </li>
        <li>
          <strong>
            <u>
              <strong>Notice</strong>
            </u>
          </strong>
          . &nbsp;All notices under this Agreement shall be in writing and shall
          be deemed given when (a) sent by electronic means, including, but not
          limited to, email or a messaging service (e.g. WhatsApp); (b)
          personally delivered, or (c) three days after being sent by prepaid
          certified or registered U.S. mail to the address of the party to be
          noticed as set forth herein or to such other address as such party
          last provided to the other by written notice.
        </li>
        <li>
          <strong>
            <u>
              <strong>Review of Reassignment Agreement</strong>
            </u>
          </strong>
          . By signing below, you affirm that you have had the opportunity to
          consult legal counsel concerning this Agreement, whether or not you
          availed yourself of such opportunity.
        </li>
        <li>
          <strong>
            <u>
              <strong>Terms and Conditions</strong>
            </u>
          </strong>
          . By signing this agreement, you agree to be bound by the Awake
          Market, Inc. (&ldquo;Shoptype&rdquo;){' '}
          <u>
            <Link target="_blank" to={'/policies/terms-of-service'}>
              Terms of Service
            </Link>
          </u>{' '}
          and all related agreements linked to or related to the Terms of
          Service.
        </li>
      </ol>
      <p>
        A breach or violation of any term in the Shoptype Terms of Service,
        including the{' '}
        <u>
          <Link target="_blank" to={'/policies/acceptable-use-policy'}>
            Acceptable Use Policy
          </Link>
        </u>
        , as determined in the sole discretion of Shoptype may result in the
        immediate termination of this Agreement.
      </p>
      <ol start="10">
        <li>
          <strong>
            <u>
              <strong>Arbitration</strong>
            </u>
          </strong>
          . Except for any claim for injunctive relief arising out of a breach
          of a party&rsquo;s obligations to protect the other&rsquo;s
          proprietary information or for violating the non-disparagement clause,
          the parties agree to arbitrate, in Santa Clara County, California,
          through Judicial Arbitration and Mediation Services (JAMS), any and
          all disputes or claims arising out of or related to the validity,
          enforceability, interpretation, performance, or breach of this
          Agreement, whether sounding in tort, contract, statutory violation or
          otherwise, or involving the construction or application or any of the
          terms, provisions, or conditions of this Agreement. Any arbitration
          may be initiated by a written demand to the other party. The
          arbitrator's decision shall be final, binding, and conclusive. The
          parties further agree that this Agreement is intended to be strictly
          construed to provide for arbitration as the sole and exclusive means
          for resolution of all disputes hereunder to the fullest extent
          permitted by law. The parties expressly waive any entitlement to have
          such controversies decided by a court or a jury.
        </li>
        <li>
          <strong>
            <u>
              <strong>Governing Law</strong>
            </u>
          </strong>
          . This Agreement shall be governed by and construed in accordance with
          the laws of the State of California, without giving effect to
          principles of conflicts of law.
        </li>
        <li>
          <strong>
            <u>
              <strong>Miscellaneous</strong>
            </u>
          </strong>
          . Any breach of Section 3 will cause irreparable harm to Company for
          which damages would not be an adequate remedy, and therefore, Company
          will be entitled to injunctive relief with respect thereto in addition
          to any other remedies. &nbsp;The failure of either party to enforce
          its rights under this Agreement at any time for any period shall not
          be construed as a waiver of such rights. &nbsp;No changes or
          modifications or waivers to this Agreement will be effective unless in
          writing and signed by both parties. &nbsp;In the event that any
          provision of this Agreement shall be determined to be illegal or
          unenforceable, that provision will be limited or eliminated to the
          minimum extent necessary so that this Agreement shall otherwise remain
          in full force and effect and enforceable. &nbsp;In any action or
          proceeding to enforce rights under this Agreement, the prevailing
          party will be entitled to recover costs and attorneys&rsquo; fees.
          Headings herein are for convenience of reference only and shall in no
          way affect interpretation of the Agreement.
        </li>
      </ol>
      <h3>
        <strong>
          <u>EXHIBIT A</u>
        </strong>
      </h3>
      <p>
        The Awake Market Membership Agreement Exhibit A can be found online :{' '}
        <u>
          <Link
            target="_blank"
            to={'/policies/awake-membership-agreement-exhibit-a'}
          >
            Awake Market Membership Agreement Exhibit A
          </Link>
        </u>
        .
      </p>
    </React.Fragment>
  );
};

export const AwakeMembershipAgreementExhibitA = () => {
  return (
    <React.Fragment>
      <h2>
        <strong>AWAKE MARKET MEMBERSHIP AGREEMENT</strong>
      </h2>
      <h3>
        <strong>
          <u>EXHIBIT A</u>
        </strong>
      </h3>
      <p>
        <strong>
          <u>
            <strong>Awake Market Member</strong>
          </u>
        </strong>
        : Any person or entity that has a current Awake Market Membership
        contract with the Company.
      </p>
      <p>
        <strong>
          <u>
            <strong>Awake Market Seller</strong>
          </u>
        </strong>
        : Any person or entity that has a current contract (via Shoptype Terms
        of Service) with the Company to sell products on the Shoptype Platform.
      </p>
      <p>
        <strong>
          <u>
            <strong>Services</strong>
          </u>
        </strong>
        : The Awake Market Member will provide the Company with:
      </p>
      <ul>
        <li>
          Introductions to potential Co-Sellers (Influencers, Ambassadors), and
          Company Customers
        </li>
        <li>
          Setting up meetings with potential Co-Sellers (Influencers,
          Ambassadors), and Company Customers
        </li>
        <li>Evangelism of the Company and Company Products</li>
        <li>
          Evangelism of any products and services in the Shoptype Platform
          catalog
        </li>
        <li />
      </ul>
      <p>
        <strong>
          <u>
            <strong>TERM</strong>
          </u>
        </strong>
        : Twelve (12) months for the Awake Market Member that starts operating
        on Shoptype Platform (the Company&rsquo;s Shoptype Platform), with the
        term starting from date their membership is accepted or renewed as
        recorded by the Shoptype Ledger (the Company&rsquo;s official record of
        transactions on the Shoptype Platform) after the Awake Market Member has
        been accepted by the Company.
      </p>
      <p>
        <strong>
          <u>
            <strong>Awake Market Member Application Process</strong>
          </u>
        </strong>
        <strong>
          <strong>:</strong>
        </strong>
        &nbsp;The process by which the Company receives, reviews, and either
        accepts or rejects an application to become an Awake Market Member for
        the TERM, renewable upon mutual agreement of the Awake Market Member and
        the Company. The acceptance or rejection of the Awake Market Member
        application is at the Company&rsquo;s sole discretion. An application to
        become an Awake Market Member may be rejected for many reasons, such as
        the applicant provided fraudulent information or failed Company
        Compliance.
      </p>
      <p>
        During the application process, the applicant may specify the referring
        Awake Market Member who invited them to become an Awake Market Member,
        establishing an Awake Market Member Connection which implicitly includes
        the referred Awake Market Member&rsquo;s agreement to pay 5% of their
        Member Earnings to the referring Awake Market Member for the TERM.
      </p>
      <p>
        <strong>
          <u>
            <strong>Awake Market Member Connection</strong>
          </u>
        </strong>
        : The referral relationship between a referring Awake Market Member and
        a referred Awake Market Member exists where the referral relationship:
      </p>
      <ul>
        <li>
          Has been established through the Awake Market Member Application
          Process
        </li>
        <li>AND mutually agreed upon by the referring and referred parties</li>
        <li>AND approved by the Company for a TERM</li>
        <li>AND registered in the Shoptype Ledger</li>
      </ul>
      <p>
        The Awake Market Member Connection is renewable and approval or
        rejection is at the Company&rsquo;s sole discretion.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <u>
            <strong>Networking Fee</strong>
          </u>
        </strong>
        : Compensation earned from Gross Sales (sales on the Shoptype Platform
        where the sale and attribution for the Awake Market Member&rsquo;s
        influence on the sale are recorded in the Shoptype Ledger). The
        Networking Fee is calculated for each sale based upon specific
        percentage criteria determined by each Awake Market Seller as recorded
        in the Shoptype Ledger.
      </p>
      <p>
        Circumstances such as Networking Fee overpayments or returns of product
        where sale was attributed to the Awake Market Member may result in an
        adjustment to the Awake Market Member&rsquo;s Networking Fees.
      </p>
      <p>
        <strong>
          <u>
            <strong>PAYMENT SCHEDULE</strong>
          </u>
        </strong>
      </p>
      <p>
        <strong>
          <u>
            <strong>Member Payment</strong>
          </u>
        </strong>
        : The Awake Market Member will be paid Member Payment according to the
        following payment schedule, and this schedule overrides all other
        previous schedules for all Member &nbsp;payments:
      </p>
      <ul>
        <li>
          If a current and valid referring Awake Market Member Connection{' '}
          <strong>
            <u>
              <strong>exists</strong>
            </u>
          </strong>
          <ul>
            <li>
              Five percent (5%) of the Member Earnings will be paid to the
              referring Awake Market Member
            </li>
            <li>
              The remaining ninety five percent (95%) of Member Earnings will be
              paid to the Awake Market Member
            </li>
          </ul>
        </li>
        <li>
          If a current and valid referring Awake Market Member Connection{' '}
          <strong>
            <u>
              <strong>does not exist</strong>
            </u>
          </strong>
          <ul>
            <li>
              Five percent (5%) of the Member Earnings will be paid to Company
              as the referring Awake Market Member
            </li>
            <li>
              The remaining ninety five percent (95%) of Member Earnings will be
              paid to the Awake Market Member
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <strong>
          <u>
            <strong>Member Earnings</strong>
          </u>
        </strong>
        : The Awake Market Member will accrue member earnings as follows:
      </p>
      <ul>
        <li>One hundred percent (100%) of their Networking Fees</li>
        <li>
          AND Five percent (5%) of their Awake Market Member Connections&rsquo;s
          Member Earnings
        </li>
      </ul>
      <p>
        For sales on the Shoptype Platform as recorded in the Shoptype Ledger
        during the TERM.
      </p>
      <p>
        Circumstances such as Networking Fee overpayments or returns of product
        where a sale was attributed to the Awake Market Member or their Awake
        Market Member Connections may result in an adjustment to the Awake
        Market Member&rsquo;s Networking Fees or Member Earnings.
      </p>
      <p>All payments will be paid monthly.</p>
      <p>
        Payments will be made for the&nbsp;TERM&nbsp;for each applicable Company
        customer.
      </p>
      <p>
        <strong>
          <strong>
            For the purpose of clarity regarding referrals - the Awake Market
            Member may share payments with others. For instance, the Awake
            Market Member may use payments to incentivize third parties to
            collaborate.{' '}
          </strong>
        </strong>
      </p>
    </React.Fragment>
  );
};
