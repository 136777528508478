import * as actions from './index';
import { post, getApi, removeCartId } from '../../utils';
import { API_BASE_URL } from '../../config/env';

const headers = {
  'Content-Type': 'application/json',
  Authorization: 'Token 2aefc0dd2b1b321a32a4c893caa1a8817cbeb5fe'
};

export const makePayment = paymentData => {
  return dispatch => {
    dispatch(actions.makePaymentRequest());
    post(`${API_BASE_URL}/order/confirm`, paymentData, headers)
      .then(data => {
        dispatch(actions.makePaymentSuccess(data));
        removeCartId();
      })
      .catch(error => {
        dispatch(actions.makePaymentError(error));
      });
  };
};

export const createSalesOrder = payload => {
  return dispatch => {
    dispatch(actions.makeSalesOrderRequest());
    post(`${API_BASE_URL}/order`, payload, headers)
      .then(result => {
        dispatch(actions.makeSalesOrderSuccess(result));
      })
      .catch(error => {
        dispatch(actions.makeSalesOrderError(error.message));
      });
  };
};

export const setCheckoutFormFields = formFields => {
  return dispatch => {
    dispatch(actions.setCheckoutFormFields(formFields));
  };
};

export const setInitalStatus = payload => {
  return dispatch => {
    dispatch(actions.setInitalStatusRequest());
  };
};

export const clearBillingFromCheckoutData = payload => {
  return dispatch => {
    dispatch(actions.clearBillingFromCheckoutData());
  };
};

export const fetchStatesFromCountryCode = (countryCode, type) => {
  return dispatch => {
    getApi(`${API_BASE_URL}/states/${countryCode}`)
      .then(result => {
        if (result.data) {
          let states = [];
          for (let i in result.data) {
            states.push({
              value: result.data[i]?.name,
              label: result.data[i]?.name
            });
          }

          dispatch(actions.fetchStatesFromCountryCode({ states, type }));
        } else {
          dispatch(actions.fetchStatesFromCountryCode({ states: [], type }));
        }
      })
      .catch(error => {
        dispatch(actions.fetchStatesFromCountryCode({ states: [], type }));
      });
  };
};
