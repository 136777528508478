import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const variant = {
  primary: css`
    color: ${props => props.theme.BLACK};
    background-color: ${props => props.theme.WHITE};
    border: 1px solid ${props => props.borderColor};
  `,
  secondary: css`
    color: ${props => props.theme.WHITE};
    background-color: ${props => props.theme.BLACK};
    border: 1px solid
      ${props => (props.borderColor ? props.borderColor : props.theme.WHITE)};
  `,
  embed: css`
    color: ${props => props.theme.WHITE};
    background-color: ${props => props.theme.BLACK};
    border: 1px solid ${props => props.theme.WHITE};
  `,
  ternary: css`
    color: ${props => props.theme.BLACK};
    background-color: transparent;
    border: 1px solid ${props => props.theme.BLACK};
  `,
  filled: css`
    border: none;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    border: 1px solid ${props => props.borderColor};
  `,
  dashed: css`
    border-style: dotted;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
  `,
  uploadMediaButton: css`
    border: 1px dashed #000;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
  `,

  transparent: css`
    background-color: transparent;
    border: 0;
    color: ${props => props.theme.BLACK};
    :hover {
      box-shadow: none;
    }
  `,
  close: css`
    height: 20px;
    width: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    border: 2px solid
      ${props => (props.borderColor ? props.borderColor : 'grey')};
    background: white;
    color: ${props => props.textColor};
    cursor: pointer;
    display: inline-block;
    &:after {
      width: 18px;
      height: 2px;
      background-color: grey;
      content: '';
      left: 50%;
      top: 50%;
      margin-left: -9px;
      margin-top: -1px;
      position: absolute;
      transform: rotate(-45deg);
    }
    &:before {
      left: 50%;
      top: 50%;
      margin-left: -9px;
      margin-top: -1px;
      width: 18px;
      height: 2px;
      background-color: grey;
      content: '';
      position: absolute;
      transform: rotate(45deg);
    }
    & + p {
      text-transform: lowercase;
      font-size: 14px;
      margin-bottom: 10px;
    }
  `,
  default: css`
    color: ${props => props.theme.BLACK};
    background-color: transparent;
    border: 1px solid ${props => props.theme.BLACK};
  `
};

const StyledLink = styled(Link)`
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}

  ${props => props.css};
`;

const Button = styled.button`
  width: 100%;
  max-width: 100%;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  outline: none;
  cursor: pointer;
  :disabled {
    opacity: 0.3;
  }

  :hover {
  }

  ${p => getVariant(p)};

  @media (min-width: 320px) {
    ${props => getSize(props)};
  }
`;

const getSize = props =>
  ({
    medium: css`
      width: 117px;
      height: 40px;
    `,
    'semi-large': css`
      width: 100%;
      height: 40px;
    `,

    small: css`
      width: 100%;
      max-width: 90px;
      height: 40px;
    `,
    'medium-large': css`
      width: 140px;
      height: 40px;
    `,
    'extra-large': css`
      width: 460px;
      height: 300px;
    `,
    'product-checkout-button': css`
      width: 100%;
      max-width: 300px;
      height: 40px;
    `,
    'media-container-modal-open': css`
      width: 90px;
      height: 90px;
    `,
    custom: css`
      width: ${props => props.width};
      height: ${props => props.height};
    `,
    default: css``
  }[props.size] ||
  css`
    width: 300px;
    height: 40px;
  `);

const getVariant = props => variant[props.variant] || variant.default;

export { StyledLink, Button };
