import React, { useEffect, useState } from 'react';
import TextInput from '../../atoms/textInput';
import { toaster } from '../../atoms/toaster';
import Dropdown from '../../atoms/dropDownInput';
import update from 'immutability-helper';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {
  isPositiveInteger,
  LEVEL_INTRO,
  LEVELX,
  isPositiveFloat
} from '../../../utils';

import {
  MiniHeading,
  SetNetworkCommission,
  SetDataContainer,
  AttributionData,
  LevelRowContainer,
  TimeLimitConatainer,
  DeleteButton
} from './styles';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
  inputError: {
    border: '1px solid red'
  },
  timeUnitBorder: {
    border: '1px solid #B2B2B2',
    borderRadius: '5px'
  },
  addLevelBtn: {
    border: '2px solid #B2B2B2',
    background: 'white',
    width: '175px',
    height: '40px',
    borderRadius: '5px',
    color: '#333333',
    cursor: 'pointer',
    marginLeft: '0px !important'
  }
});

export default function EffectiveNetworkCommission(props) {
  const classes = useStyles();

  const [attributionLevels, updateAttrLevels] = useState({
    levelIntro: LEVEL_INTRO,
    levels: [],
    levelX: LEVELX,
    networkCommission: ''
  });
  const dropDownOptions = [
    'Minutes',
    'Hours',
    'Days',
    'Weeks',
    'Months',
    'Infinite'
  ];
  const nonInfiniteExpiryOptions = [
    'Minutes',
    'Hours',
    'Days',
    'Weeks',
    'Months'
  ];
  const expiryOptions = {
    minutes: 60000000000,
    hours: 3600000000000,
    days: 86400000000000,
    weeks: 604800000000000,
    months: 2628000000000000
  };

  const effectiveNetworkCommissionPercentage = 100;

  const [showError, updateError] = useState(false);
  const [showExpireTimeError, updateExpireTimeError] = useState(false);

  useEffect(() => {
    populateAttributionData();
  }, [props.isAttributionSet]);

  useEffect(() => {
    if (canUpdateSplit()) {
      updateError(false);

      sendDetails();
    } else {
      updateError(true);
    }
    if (checkExpireTime()) {
      updateExpireTimeError(true);
    } else {
      updateExpireTimeError(false);
    }
  }, [attributionLevels]);

  useEffect(() => {
    props.updateErrorFromEffectiveNetwork(showError);
  }, [showError]);

  useEffect(() => {
    props.updateExpireTimeErrorFromEffectiveNetwork(showExpireTimeError);
  }, [showExpireTimeError]);

  useEffect(() => {
    resetStateDetails();
  }, [props.resetAttribution]);

  const resetStateDetails = () => {
    updateAttrLevels({
      levelIntro: LEVEL_INTRO,
      levels: [],
      levelX: LEVELX,
      networkCommission: ''
    });
  };

  const getLevelName = shortName => {
    switch (shortName) {
      case 'lIntro':
        return 'INTRODUCTION';
      case 'lX':
        return 'Level X';
      default:
        return `Level ${shortName.substring(1)}`;
    }
  };

  const pushTo = shortName => {
    switch (shortName) {
      case 'lIntro':
        return 'levelIntro';
      case 'lX':
        return 'levelX';
      default:
        return 'levels';
    }
  };

  const calculateExpiry = (level, timeLimit, timeLimitUnit) => {
    if (timeLimitUnit.toLowerCase() === 'infinite') {
      return 'Infinite';
    } else if (timeLimit === '' || timeLimit === null) {
      return '0';
    } else if (Number(timeLimit) > 0 && timeLimitUnit) {
      return Number(timeLimit) / expiryOptions[timeLimitUnit.toLowerCase()];
    } else {
      switch (level) {
        case 'lIntro':
          return 'Infinite';
        case 'lX':
          return '1';
        default:
          return '1';
      }
    }
  };

  const setOption = (level, timeLimitUnit) => {
    if (timeLimitUnit === '' || timeLimitUnit === null) {
      switch (level) {
        case 'lIntro':
          return 'Infinite';
        case 'lX':
          return 'Hours';
        default:
          return 'Hours';
      }
    } else {
      return timeLimitUnit.charAt(0).toUpperCase() + timeLimitUnit.slice(1);
    }
  };

  const populateAttributionData = () => {
    if (props.attributionData && props.isAttributionSet) {
      const { lIntro, lX } = props.attributionData.attributionConfig.configs;
      let obj = { lIntro, lX };

      for (let i = 10; i > 0; i--) {
        if (
          !isEmpty(props.attributionData.attributionConfig.configs[`l${i}`])
        ) {
          obj[`l${i}`] =
            props.attributionData.attributionConfig.configs[`l${i}`];
        }
      }
      let levelObj = { levelIntro: [], levels: [], levelX: [] };
      Object.keys(obj).forEach(key => {
        if (obj[key] === 'lIntro' || obj[key] === 'lX') {
          levelObj[pushTo(key)].push({
            name: getLevelName(key),
            shortName: key,
            splitValue:
              obj[key].percentage > 0 ? obj[key].percentage.toString() : '',
            expiry: calculateExpiry(
              key,
              obj[key].timeLimit,
              obj[key].timeLimitUnit
            ),
            option: setOption(key, obj[key].timeLimitUnit),
            timeLimit: obj[key].timeLimit
          });
        } else if (obj[key].percentage > 0) {
          levelObj[pushTo(key)].push({
            name: getLevelName(key),
            shortName: key,
            splitValue:
              obj[key].percentage > 0 ? obj[key].percentage.toString() : '',
            expiry: calculateExpiry(
              key,
              obj[key].timeLimit,
              obj[key].timeLimitUnit
            ),
            option: setOption(key, obj[key].timeLimitUnit),
            timeLimit: obj[key].timeLimit,
            active: obj[key].percentage > 0
          });
        }
      });

      if (levelObj.levelIntro.length > 0) {
        updateAttrLevels(prevState => ({
          ...prevState,
          levelIntro: levelObj.levelIntro
        }));
      }
      if (levelObj.levelX.length > 0) {
        updateAttrLevels(prevState => ({
          ...prevState,
          levelX: levelObj.levelX
        }));
      }
      updateAttrLevels(prevState => ({
        ...prevState,
        levels: levelObj.levels
      }));
    }
  };

  const canUpdateSplit = () => {
    let splitSum = 0;

    attributionLevels.levelIntro.forEach(item => {
      splitSum +=
        item.splitValue.toString().trim() === '' ? 0 : Number(item.splitValue);
    });
    attributionLevels.levelX.forEach(item => {
      splitSum +=
        item.splitValue.toString().trim() === '' ? 0 : Number(item.splitValue);
    });
    attributionLevels.levels.forEach(item => {
      splitSum +=
        item.splitValue.toString().trim() === '' ? 0 : Number(item.splitValue);
    });
    props.updateAttributionSum(splitSum);

    return splitSum <= effectiveNetworkCommissionPercentage;
  };

  const checkExpireTime = () => {
    let showError = false;
    attributionLevels.levelIntro.forEach(item => {
      if (item.expiry === '') {
        showError = true;
      }
    });
    attributionLevels.levelX.forEach(item => {
      if (item.expiry === '') {
        showError = true;
      }
    });
    attributionLevels.levels.forEach(item => {
      if (item.expiry === '') {
        showError = true;
      }
    });
    return showError;
  };

  const addSetDataContainer = () => {
    let newLevels = attributionLevels.levels;
    if (newLevels.length <= 9) {
      if (
        newLevels.length > 0 &&
        newLevels[newLevels.length - 1].splitValue === ''
      ) {
        return toaster('Fill current level details first');
      }
      newLevels.push({
        name: `Level ${10 - attributionLevels.levels.length}`,
        shortName: `l${10 - attributionLevels.levels.length}`,
        splitValue: '1',
        expiry: '1',
        option: 'Hours',
        timeLimit: calculateTimeLimit('1', 'Hours'),
        active: true
      });
      updateAttrLevels(prevState => ({
        ...prevState,
        levels: newLevels
      }));
    } else {
      return toaster('Maximum of ten levels can be added');
    }
  };

  const calculateTimeLimit = (multiplicand, option) => {
    const multiplicandValue = multiplicand.toString().trim();
    if (multiplicandValue === '0' || multiplicand === '') return null;
    switch (option) {
      case 'Infinite':
        return null;
      default:
        return (
          Number(multiplicand) * Number(expiryOptions[option.toLowerCase()])
        );
    }
  };

  const deleteRow = (index, type) => {
    let newData = attributionLevels[type];
    newData = update(newData, { [index]: { active: { $set: false } } });
    updateAttrLevels(prevState => ({
      ...prevState,
      [type]: newData
    }));
    newData = update(newData, { $splice: [[index, 1]] });
    updateAttrLevels(prevState => ({
      ...prevState,
      [type]: newData
    }));
  };
  useEffect(() => {
    sendDetails();
  }, [attributionLevels]);

  const handleSplitValueInput = (e, i, type) => {
    let value = e.target.value;
    if (
      (!isPositiveFloat(value.trim()) && value.trim().length > 0) ||
      value.trim() === '0'
    ) {
      return;
    }
    let newData = attributionLevels[type];
    const index = i;

    newData = update(newData, {
      [index]: { splitValue: { $set: value } }
    });
    updateAttrLevels(prevState => ({
      ...prevState,
      [type]: newData
    }));
  };

  const handleExpiryChange = (e, i, type) => {
    let value = e.target.value;
    if (
      (!isPositiveInteger(value.trim()) && value.trim().length > 0) ||
      value.trim() === '0'
    ) {
      return;
    }
    const index = i;
    value = value.trim();
    let newData = attributionLevels[type];

    newData = update(newData, {
      [index]: {
        expiry: { $set: value },
        timeLimit: {
          $set: calculateTimeLimit(value, newData[index].option)
        }
      }
    });
    updateAttrLevels(prevState => ({
      ...prevState,
      [type]: newData
    }));
  };

  const handleExpiryOptionChange = (e, selected, index, type) => {
    let newData = attributionLevels[type];
    const lastOption = newData[index].option;
    newData = update(newData, {
      [index]: {
        option: { $set: selected },
        timeLimit: {
          $set: calculateTimeLimit(newData[index].expiry, selected)
        }
      }
    });
    if (selected === 'Infinite') {
      newData = update(newData, {
        [index]: {
          expiry: { $set: 'Infinite' },
          timeLimit: {
            $set: null
          }
        }
      });
    } else if (
      nonInfiniteExpiryOptions.includes(selected) &&
      (lastOption === 'Infinite' ||
        attributionLevels[type][index].expiry === '')
    ) {
      newData = update(newData, {
        [index]: {
          expiry: { $set: '1' },
          timeLimit: {
            $set: calculateTimeLimit('1', selected)
          }
        }
      });
    }
    updateAttrLevels(prevState => ({
      ...prevState,
      [type]: newData
    }));
  };

  const sendDetails = () => {
    if (props.sendData) {
      props.sendData({
        levelIntro: attributionLevels.levelIntro,
        levels: attributionLevels.levels,
        levelX: attributionLevels.levelX
      });
    }
  };

  const viewLevelName = name => {
    if (name === 'Level X') {
      return 'CLOSE';
    } else if (name.includes('Level')) {
      let regEx = /\d+/;
      let levelNumber = name.match(regEx);
      return `THIS IS LEVEL X - ${11 - levelNumber}`;
    }
    return name;
  };
  const MenuContent = ({ index, type }) => (
    <DeleteButton
      onClick={() => deleteRow(index, type)}
      disabled={index !== attributionLevels[type].length - 1}
      style={
        index !== attributionLevels[type].length - 1
          ? {
              opacity: 0.5,
              cursor: 'default'
            }
          : null
      }
    >
      <DeleteOutlinedIcon />
    </DeleteButton>
  );
  const levelRow = (level, index, type, showMenu) => (
    <SetDataContainer key={index}>
      <AttributionData style={{ paddingLeft: 0 }}>
        <MiniHeading>Stage</MiniHeading>
        <TextInput
          type="text"
          inputContainerSize="attribution-data"
          size="attribution-data"
          name="starSellerShare"
          value={viewLevelName(level.name)}
          previewMode={true}
        />
      </AttributionData>
      <AttributionData>
        <MiniHeading>Network Fee Split(%)</MiniHeading>
        <TextInput
          type="text"
          inputContainerSize="attribution-data"
          size="attribution-data"
          name="starSellerShare"
          value={level.splitValue}
          inputStyleClass={showError ? classes.inputError : ''}
          onChange={e => handleSplitValueInput(e, index, type)}
        />
      </AttributionData>
      <AttributionData>
        <MiniHeading>Expire Time</MiniHeading>
        <TimeLimitConatainer>
          <TextInput
            type="text"
            inputContainerSize="attribution-data"
            size="attribution-data"
            name="starSellerShare"
            value={level.expiry}
            inputStyleClass={showExpireTimeError ? classes.inputError : ''}
            onChange={e => handleExpiryChange(e, index, type)}
          />
        </TimeLimitConatainer>
      </AttributionData>
      <AttributionData>
        <MiniHeading>Time Unit</MiniHeading>
        <Dropdown
          options={dropDownOptions}
          selected={dropDownOptions[dropDownOptions.indexOf(level.option)]}
          onOptionSelect={(e, option) =>
            handleExpiryOptionChange(e, option, index, type)
          }
          heroStyleClass={classes.timeUnitBorder}
          showArrowIcon={true}
        />
      </AttributionData>
      {showMenu && <MenuContent index={index} type={type} />}
    </SetDataContainer>
  );

  return (
    <SetNetworkCommission>
      <LevelRowContainer>
        {attributionLevels.levelX.map((row, index) =>
          levelRow(row, index, 'levelX', false)
        )}
      </LevelRowContainer>

      {attributionLevels.levels.length > 0 && (
        <LevelRowContainer>
          {attributionLevels.levels.map(
            (row, index) => row.active && levelRow(row, index, 'levels', true)
          )}
        </LevelRowContainer>
      )}
      {attributionLevels.levels.length < 10 && (
        <button className={classes.addLevelBtn} onClick={addSetDataContainer}>
          ADD ANOTHER LEVEL
        </button>
      )}
      <LevelRowContainer marginBottom="32">
        {attributionLevels.levelIntro.map((row, index) =>
          levelRow(row, index, 'levelIntro', false)
        )}
      </LevelRowContainer>
    </SetNetworkCommission>
  );
}
