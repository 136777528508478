import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  getAuthToken,
  postWithResponseObject,
  getApiResponseObject,
  successStatusCodes,
  showApiErrorToast
} from '../../../../utils';
import { API_BASE_URL } from '../../../../config/env';
import { toaster } from '../../../atoms/toaster';
import { ACCEPTED, PENDING } from '../../../../constants';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../../constants';
import { Tooltip, Typography, Zoom } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { COSELLER_INFO } from '../../../../constants/tooltip';
import { Container, HeadingSection, Heading } from './styles';
import ProductLevelAnalytics from './ProductLevelAnalytics';
import CosellerLevelAnalytics from './CosellerLevelAnalytics';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import EmptyState from '../../../atoms/EmptyState';

class CosellerDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingCosellerAnalytics: true,
      isLoadingProductAnalytics: true,
      cosellerLevelAnalyticsDataPoints: {},
      productLevelAnalyticsData: [],
      totalCount: 0,
      totalResultsCount: 0,
      fetchParams: {
        count: 10, // set this for pagesize
        offset: 0
      }
    };
  }

  componentDidMount() {
    EventsBaseClient.on(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );

    const selectedCurrency = localStorage.getItem('selectedCurrency');

    if (selectedCurrency) {
      this.setState({ selectedCurrency: selectedCurrency }, () =>
        this.getPageData()
      );
    } else {
      this.getPageData();
    }
  }

  updateFetchParams = fetchParams => {
    this.setState({ fetchParams }, () => {
      this.fetchDashboardData(false, false);
    });
  };

  componentWillUnmount() {
    EventsBaseClient.removeEventListener(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );
  }

  getPageData = () => {
    const inviteLinkStatus =
      localStorage.getItem('inviteLinkStatus') || PENDING;
    if (inviteLinkStatus !== ACCEPTED) {
      const inviteLinkSlugData = localStorage.getItem('inviteSlug') || '';
      if (inviteLinkSlugData)
        this.recordVendorEmailInviteNotification(inviteLinkSlugData);
    }
    this.fetchDashboardData(true);
  };

  handleCurrencyChange = currency => {
    const { selectedCurrency } = this.state;

    if (currency !== selectedCurrency) {
      this.setState(
        {
          selectedCurrency: currency
        },
        () => {
          this.resetState();
          this.fetchDashboardData(true);
        }
      );
    }
  };

  resetState = () => {
    this.setState({
      isLoadingCosellerAnalytics: true,
      isLoadingProductAnalytics: true,
      cosellerLevelAnalyticsDataPoints: {},
      productLevelAnalyticsData: []
    });
  };

  recordVendorEmailInviteNotification = async inviteLinkSlugData => {
    const token = getAuthToken();
    const headers = { Authorization: token };
    const profile = JSON.parse(localStorage.getItem('userProfile')) || {
      email: ''
    };
    let emailId = [];
    if (profile.email) {
      emailId.push(profile.email);
    }

    const invitorId = inviteLinkSlugData.split(',')[0];
    const invitorType = inviteLinkSlugData.split(',')[1];
    const requestBody = {
      inviteeEmailIds: emailId,
      invitorId: invitorId,
      invitorType: invitorType
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/command?type=sendInvite`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      return;
    }
    localStorage.setItem('inviteLinkStatus', ACCEPTED);
  };

  fetchDashboardData = (
    updateTotalCount = false,
    fetchCosellerAnalytics = true,
    fetchProductAnalytics = true
  ) => {
    const userDetails = JSON.parse(localStorage.getItem('userProfile')) || null;
    const token = getAuthToken();

    if (fetchCosellerAnalytics)
      this.fetchCosellerLevelAnalytics(userDetails, token);

    if (fetchProductAnalytics)
      this.fetchProductLevelAnalytics(userDetails, token, updateTotalCount);
  };

  fetchCosellerLevelAnalytics = async (userDetails, token) => {
    const { selectedCurrency } = this.state;
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/coseller-dashboard?viewType=cosellerView&currency=${selectedCurrency}`,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      const fetchedAnalyticsData = response.data || {};
      this.setState({
        isLoadingCosellerAnalytics: false,
        cosellerLevelAnalyticsDataPoints: {
          'Total Earnings': fetchedAnalyticsData.total_commissions || 0,
          Clicks: fetchedAnalyticsData.total_clicks || 0,
          Publishes: fetchedAnalyticsData.total_publishes || 0,
          currency: fetchedAnalyticsData.currency || this.state.selectedCurrency
        }
      });
      this.props.hideSpinnerBackdrop();
    } else {
      this.props.hideSpinnerBackdrop();
      toaster(
        'Failed to load Coseller level Analytics ! Please try again later'
      );
    }
  };

  fetchProductLevelAnalytics = async (
    userDetails,
    token,
    updateTotalCount = false
  ) => {
    const { selectedCurrency, fetchParams } = this.state;

    this.props.showSpinnerBackdrop();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/coseller-dashboard?viewType=cosellerProductView&currency=${selectedCurrency}`,
      headers,
      fetchParams
    );

    if (successStatusCodes.includes(response.status)) {
      this.setState({
        isLoadingProductAnalytics: false,
        totalCount: updateTotalCount
          ? response?.headers['total-count'] || 0
          : this.state.totalCount,
        productLevelAnalyticsData: response.data || [],
        totalResultsCount: response?.headers['total-count'] || 0
      });
      this.props.hideSpinnerBackdrop();
    } else {
      this.props.hideSpinnerBackdrop();
      toaster(
        'Failed to load Product level Analytics ! Please try again later'
      );
    }
  };

  render() {
    const {
      isLoadingCosellerAnalytics,
      isLoadingProductAnalytics,
      cosellerLevelAnalyticsDataPoints,
      productLevelAnalyticsData,
      fetchParams,
      totalResultsCount,
      totalCount
    } = this.state;
    return (
      <Container>
        <HeadingSection>
          <Heading>Dashboard</Heading>
          <Tooltip
            TransitionComponent={Zoom}
            title={COSELLER_INFO.pageDescription.Dashboard}
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </HeadingSection>
        <CosellerLevelAnalytics
          isLoading={isLoadingCosellerAnalytics}
          dataPoints={cosellerLevelAnalyticsDataPoints || {}}
        />
        {!isLoadingProductAnalytics && totalCount > 0 ? (
          <ProductLevelAnalytics
            analyticsData={productLevelAnalyticsData || []}
            fetchParams={fetchParams}
            totalResultsCount={totalResultsCount}
            updateFetchParams={this.updateFetchParams}
          />
        ) : (
          !isLoadingProductAnalytics && (
            <EmptyState errorMessage="No products to show for the selected currency" />
          )
        )}
      </Container>
    );
  }
}

CosellerDashboard.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CosellerDashboard));
