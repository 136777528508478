import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Container,
  MainSection,
  Content,
  HeadingSection,
  Heading,
  InnerContainer
} from './styles';
import { Tooltip, Zoom } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../../constants';

class NetworkOperatorDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.history.push('/networkoperator/products');
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.UPDATE_SIDEBAR_CATEGORIES);
  }

  render() {
    return (
      <Container>
        <InnerContainer>
          <MainSection>
            <Content>
              <HeadingSection>
                <Heading>Network Operator Dashboard</Heading>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={NETWORK_OPERATOR_INFO.pageDescription.Dashboard}
                  arrow
                >
                  <InfoRoundedIcon />
                </Tooltip>
              </HeadingSection>
            </Content>
          </MainSection>
        </InnerContainer>
      </Container>
    );
  }
}

export default withRouter(NetworkOperatorDashboard);
