import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div``;

const Header = styled.div`
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
`;

const Content = styled.div`
  font-size: 14px;
`;

const Seperator = styled.hr`
  border: solid 1px #bfbfbf;
`;

class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  togglePanel = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <Container>
        <Header onClick={e => this.togglePanel(e)}>
          <div>{this.props.title}</div>
        </Header>
        {this.state.open ? <Content>{this.props.children}</Content> : null}
        <Seperator />
      </Container>
    );
  }
}

Collapsible.propTypes = {
  children: PropTypes.array,
  title: PropTypes.string
};

export default Collapsible;
