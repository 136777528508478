import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const WalletDetailsContainer = styled.div`
  background: #fff;
  margin-top: 20px;
  width: 50%;
  margin-right: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 80px;
  height: 145px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const SubHeading = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-bottom: 10px;
`;
const ContentText = styled.div`
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  margin-top: 10px;
`;

const ContentHeading = styled.span`
  font-size: ${props => props.theme.XXX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-left: 5px;
`;

const AwakeMoneyContainer = styled.div`
  margin-top: 48px;
  max-width: 500px;
  width: 100%;
  border: 1.5px solid lightgray;
  border-radius: 5px;
  background-color: #ffffff;
`;

const AwakeMoneyContent = styled.div`
  padding: 24px;
`;

const DropDownContainer = styled.div`
  width: 315px;
  background: #fff;
  height: 55px;
  padding-left: 0px;
`;

const StyledCard = styled.div`
  margin-top: 12px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`;

const PanelContent = styled.div`
  width: 100%;
  height: 100%;
  margin: 24px 0px;
`;

export {
  Container,
  HeadingSection,
  Heading,
  SubHeading,
  ContentText,
  AwakeMoneyContainer,
  AwakeMoneyContent,
  DropDownContainer,
  StyledCard,
  WalletDetailsContainer,
  ContentHeading,
  PanelContent
};
