import React from 'react';
import Loader from '../../../atoms/loader';
import MaterialButton from '../../../atoms/materialButton';
import ConfigurePaymentsDialog from './dialogs/configurePaymentsDialog';
import AccountCard from './accountCard';
import { isEmpty } from 'lodash';
import { API_BASE_URL } from '../../../../config/env';
import { withSnackbar } from '../../../hoc/withSnackbar';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes
} from '../../../../utils';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom } from '@material-ui/core';
import {
  Container,
  HeadingSection,
  Heading,
  Message,
  Section,
  AccountCardWrapper,
  PaymentsContainer
} from './styles';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import { withRouter } from 'react-router-dom';

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchedDetails: [],
      paymentMethodConfigured: false,
      openDialog: false
    };
  }

  componentDidMount() {
    this.fetchPaymentConfig();
  }

  fetchPaymentConfig = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();
    this.setState({ isLoading: true });

    const response = await getApiResponseObject(
      `${API_BASE_URL}/payment-config`,
      headers
    );

    if (
      successStatusCodes.includes(response.status) &&
      response?.data?.length > 0
    ) {
      const fetchedDetails = response.data;
      this.setState({
        fetchedDetails,
        paymentMethodConfigured: true,
        isLoading: false
      });
    } else {
      this.setState({
        paymentMethodConfigured: false,
        isLoading: false
      });
    }
    this.props.hideSpinnerBackdrop();
  };

  handleDialog = status => {
    this.setState({ openDialog: status === 'open' ? true : false });
  };

  handleOpenSnackbar = (severity, message) => {
    this.setState({ openSnackbar: true, severity, snackbarMessage: message });
  };

  handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openSnackbar: false, snackbarMessage: '' });
  };

  render() {
    const {
      isLoading,
      fetchedDetails,
      paymentMethodConfigured,
      disableButton,
      openDialog
    } = this.state;
    const { showSnackbar } = this.props;
    return (
      <>
        {!isLoading && (
          <ConfigurePaymentsDialog
            open={openDialog}
            handleDialog={this.handleDialog}
            showSnackbar={showSnackbar}
            fetchPaymentConfig={this.fetchPaymentConfig}
          />
        )}
        <Container>
          <HeadingSection>
            <Heading>Payments</Heading>
            <Tooltip
              TransitionComponent={Zoom}
              title={NETWORK_OPERATOR_INFO.pageDescription.Payments}
              arrow
            >
              <InfoRoundedIcon />
            </Tooltip>
          </HeadingSection>
          <PaymentsContainer>
            <>
              {!isLoading &&
              paymentMethodConfigured &&
              !isEmpty(fetchedDetails) ? (
                <>
                  <Message>
                    <i className="fas fa-check-circle success" />
                    &nbsp;Payments configured
                  </Message>
                  <Section>
                    <MaterialButton
                      label="Add Payment"
                      disabled={disableButton}
                      onClick={() => {
                        this.handleDialog('open');
                      }}
                    />
                  </Section>
                  <AccountCardWrapper>
                    {fetchedDetails &&
                      fetchedDetails.map((account, index) => (
                        <AccountCard
                          key={index}
                          method={account?.method}
                          status="Account Connected"
                          keyLastFour={account?.last_4?.key}
                          currency={account?.currency}
                        />
                      ))}
                  </AccountCardWrapper>
                </>
              ) : (
                !isLoading &&
                !paymentMethodConfigured && (
                  <>
                    <Message>
                      <i className="fas fa-info-circle info" />
                      &nbsp;Payments are not configured yet
                    </Message>
                    <Section>
                      <MaterialButton
                        label="Add Payment"
                        disabled={disableButton}
                        onClick={() => {
                          this.handleDialog('open');
                        }}
                      />
                    </Section>
                  </>
                )
              )}
            </>
          </PaymentsContainer>
        </Container>
      </>
    );
  }
}

Payments.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withSnackbar(
  withRouter(connect(null, mapDispatchToProps)(Payments))
);
