import styled from 'styled-components';
import TextInput from '../../atoms/textInputV1';
import { Secondary } from '../../atoms/button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  overflow: hidden;
`;

const Container = styled.div`
  background-color: ${props => props.theme.WHITE};
  color: ${props => props.theme.MINE_SHAFT};
  display: block;
  width: 100%;
  height: inherit;
`;

const Content = styled.div`
  padding: 24px 48px;
`;

const HeadingSection = styled.div`
  margin-bottom: 28px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 300px 300px 300px max-content;
  grid-gap: 0;
  align-items: center;

  div:nth-of-type(1) input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
  }

  div:nth-of-type(3) input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
  }
`;

const Password = styled(TextInput)``;

const SaveButton = styled.div`
  margin-left: 20px;
`;

const Save = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

export {
  Wrapper,
  Container,
  Content,
  HeadingSection,
  Heading,
  Row,
  Password,
  SaveButton,
  Save
};
