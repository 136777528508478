import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const OrderDetailsContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.SPRING_WOOD};
  padding: 30px 50px;
`;

const ShippingCard = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: ${props => props.theme.WHITE};
  margin: 10px;
  padding: 30px;
`;

const ShippingCardHeading = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  margin-bottom: 20px;
`;

const ShippingCardInfo = styled.div`
  font-size: ${props => props.theme.MEDIUM};
  min-height: 40px;

  & > b {
    font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  }
`;

const OrderConfirm = props => {
  return (
    <>
      <OrderDetailsContainer>
        <ShippingDetails
          checkoutFormData={props.totalAmount.checkoutFormData}
        />
      </OrderDetailsContainer>
    </>
  );
};

const ShippingDetails = props => {
  let { shipping, billing } = props.checkoutFormData;

  if (!billing.firstname) billing = shipping;

  const shippingCardData = [
    {
      heading: 'Shipping',
      name: shipping.firstname,
      email: shipping.email,
      phone: shipping.address.phone,
      address:
        shipping.address.street1 +
        ', ' +
        shipping.address.city +
        ', ' +
        shipping.address.state +
        ', ' +
        shipping.address.postal_code +
        ', ' +
        shipping.address.country
    },
    {
      heading: 'Billing',
      name: billing.firstname,
      email: billing.email,
      phone: billing.address.phone,
      address:
        billing.address.street1 +
        ', ' +
        billing.address.city +
        ', ' +
        billing.address.state +
        ', ' +
        billing.address.postal_code +
        ', ' +
        billing.address.country
    }
  ];

  return (
    <div>
      {shippingCardData.map((data, i) => {
        return (
          <ShippingCard key={i}>
            <ShippingCardHeading>{data.heading} Details</ShippingCardHeading>
            <ShippingCardInfo>
              Name: <b>{data.name}</b>
            </ShippingCardInfo>
            <ShippingCardInfo>
              Email: <b>{data.email}</b>
            </ShippingCardInfo>
            <ShippingCardInfo>
              Mobile No: <b>{data.phone}</b>
            </ShippingCardInfo>
            <ShippingCardInfo>
              {data.heading} address: <b>{data.address}</b>
            </ShippingCardInfo>
          </ShippingCard>
        );
      })}
    </div>
  );
};

OrderConfirm.propTypes = {};

const mapStateToProps = state => {
  return {
    buyProduct: state.buyProduct,
    totalAmount: state.totalAmount
  };
};

export default withRouter(connect(mapStateToProps)(OrderConfirm));
