import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import * as actions from '../../../redux/actions/productsShareActions';
import { toaster } from '../../atoms/toaster';
import {
  postWithResponseObject,
  addQueryParamsToUrl,
  getApiResponseObject,
  successStatusCodes
} from '../../../utils.js';
import { Secondary as SecondaryButton, Embed } from '../../atoms/button';
import { API_BASE_URL } from '../../../config/env';
import { COSELLER } from '../../../constants';
import Share from '../Share';

import {
  Heading,
  SubHeading,
  InputContainer,
  ProductLink,
  Container,
  EmbedContainer,
  EmbedLink
} from './styles';

class PublishModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      embedOptionStatus: this.props.embedOption,
      modalContent: null,
      productShareLink: ''
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === 'c') {
      event.preventDefault();
    }
    if (event.metaKey && charCode === 'c') {
      event.preventDefault();
    }
  }

  text = React.createRef();

  manualCopyEvent = async () => {
    await this.publishShareEvent('copy');
  };

  publishShareEvent = async medium => {
    const { productId, vendorId, productShareLink } = this.props;
    const token = this.props.token;
    const timestamp = new Date().getTime();
    const headers = {
      authorization: token,
      'Content-Type': 'application/json'
    };
    const requestBody = {
      productId: productId,
      vendorId: vendorId,
      referrerLink: window.location.href,
      link: addQueryParamsToUrl(productShareLink, { utm_medium: medium }),
      medium: medium,
      timestamp: timestamp
    };

    const userType = localStorage.getItem('USER_TYPE') || null;

    if (userType === COSELLER) {
      const responseCoseller = await getApiResponseObject(
        `${API_BASE_URL}/cosellers`,
        headers
      );

      if (
        successStatusCodes.includes(responseCoseller.status) &&
        responseCoseller.data
      ) {
        if (isEmpty(responseCoseller.data.id))
          return toaster('Unable to fetch Coseller details');
        requestBody.cosellerId = responseCoseller.data.id;
      }
    }

    if (token) {
      await postWithResponseObject(
        `${API_BASE_URL}/publish-product`,
        requestBody,
        headers
      );
    }
  };

  copyLink = () => {
    this.text.current.select();
    this.text.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
    this.manualCopyEvent();
  };

  embedCodeGenerator = (productId, authId, token) => {
    sessionStorage.setItem(
      'product_share_url',
      addQueryParamsToUrl(this.props.productShareLink, { utm_medium: 'embed' })
    );
    sessionStorage.setItem('token', token);
    window.open(`/${authId}/embed-code/${productId}`, '_blank');
  };

  closeModal = () => {
    this.setState({ modalContent: null });
    this.props.closePublishModal();
  };

  render() {
    let {
      productShareLink,
      authId,
      token,
      productId,
      title,
      description,
      imageSrc,
      tags
    } = this.props;
    const { embedOptionStatus } = this.state;

    return (
      <Container>
        <Heading>Share your Product</Heading>
        <SubHeading>Share link</SubHeading>
        <InputContainer onKeyDown={this.handleKeyDown}>
          <ProductLink
            type="text"
            ref={this.text}
            readOnly
            defaultValue={addQueryParamsToUrl(productShareLink, {
              utm_medium: 'copy'
            })}
            onClick={this.keyPress}
          />
          <SecondaryButton text="copy" onClick={this.copyLink} />
        </InputContainer>

        {!isEmpty(productShareLink) && (
          <div style={{ margin: '24px 0' }}>
            <Share
              shareLabel="Share on social platform"
              title={title}
              description={description}
              imageSrc={imageSrc}
              productShareLink={productShareLink}
              tags={tags}
              disableButton={false}
              publishShareEvent={this.publishShareEvent}
            />
          </div>
        )}

        {embedOptionStatus ? (
          <EmbedContainer>
            <SubHeading>Share on blogs</SubHeading>
            <EmbedLink>
              <Embed
                text="Get an Embed"
                size="medium-large"
                onClick={() =>
                  this.embedCodeGenerator(productId, authId, token)
                }
              />
            </EmbedLink>
          </EmbedContainer>
        ) : null}
      </Container>
    );
  }
}

PublishModal.propTypes = {
  productId: PropTypes.string,
  vendorId: PropTypes.string,
  embedOption: PropTypes.bool,
  productShareLink: PropTypes.string,
  updateProductShareLink: PropTypes.func,
  authId: PropTypes.string,
  token: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.object,
  closePublishModal: PropTypes.func,
  tags: PropTypes.array
};

const mapDispatchToProps = dispatch => {
  return {
    updateProductShareLink: productShareLink => {
      dispatch(actions.updateProductShareLink(productShareLink));
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(PublishModal));
