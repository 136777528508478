import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  background-color: #fbfaf8;
`;

const ProductsListContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const Table = styled.div`
  mix-width: 400px;
  max-width: 480px;

  & > div:not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;

  & > div {
    padding: 4px 8px;
    ${p =>
      p.heading &&
      css`
        font-weight: ${p => p.theme.SEMI_BOLD_FONT};
      `}
  }

  & > :nth-child(3n) {
    text-align: right;
  }
`;

const TableHeading = styled.div`
  display: inline-block;
  font-weight: ${p => p.theme.MEDIUM_FONT};
`;

const ProductDetailsFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ProductImage = styled.div`
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  margin-right: 4px;
  background-color: rgba(224, 224, 224, 1);
  border-radius: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0.5px solid rgba(224, 224, 224, 1);
    border-radius: 5px;
  }
`;

const TableFooter = styled.div`
  text-align: left;
  padding: 8px;
`;

const MessageContainer = styled.div`
  padding: 8px;
`;

export {
  Wrapper,
  ProductsListContainer,
  Table,
  TableRow,
  TableHeading,
  ProductDetailsFlex,
  ProductImage,
  TableFooter,
  MessageContainer
};
