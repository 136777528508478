import React from 'react';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

const ConfirmationDialog = ({
  open,
  title,
  description,
  children,
  onConfirm,
  onClose,
  onCancel
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          Continue
        </Button>
        <Button onClick={onCancel} color="primary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
