import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Chip,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import shoptypeThemes from '../../../../theme';
import CalendarComponent from '../../../atoms/calendar';
import { dateWithoutTime } from '../../../../utils';

const useStyles = makeStyles({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    alignItems: 'center'
  },
  subContainer: {
    padding: 0,
    margin: 0
  },
  formControl: {
    width: 150
  },
  select: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      backgroundColor: shoptypeThemes.default.WHITE
    }
  },
  selected: {
    height: '36px',
    padding: '7px',
    backgroundColor: shoptypeThemes.default.LIME_GREEN,
    color: shoptypeThemes.default.WHITE,
    '&:hover, &:focus': {
      background: `${shoptypeThemes.default.LIME_GREEN} !important`
    }
  },
  notSelected: {
    height: '36px',
    padding: '7px',
    backgroundColor: shoptypeThemes.default.WHITE,
    border: `1px solid ${shoptypeThemes.default.LIME_GREEN}`
  },
  clearBtn: {
    cursor: 'pointer',
    border: `1px solid ${shoptypeThemes.default.DARK_RED}`,
    color: shoptypeThemes.default.DARK_RED,
    backgroundColor: shoptypeThemes.default.WHITE,
    '&:hover, &:focus': {
      background: `${shoptypeThemes.default.WHITE} !important`
    }
  }
});

function GetCollectionsFilters(props) {
  const classes = useStyles();
  const [debounceQueue, setDebounceQueue] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({
    count: 8, // set this for pagesize
    offset: 0,
    text: '',
    status: '',
    type: '',
    sortBy: '',
    orderBy: '',
    isMenu: null,
    isCampaign: null,
    campaignStartDate: dateWithoutTime(
      new Date(new Date().setDate(new Date().getDate() - 30))
    ),
    campaignEndDate: dateWithoutTime(new Date())
  });

  useEffect(() => {
    setSelectedFilters(props.fetchParams);
  }, []);

  useEffect(() => {
    setSelectedFilters(props.fetchParams);
  }, [props.fetchParams.text]);

  useEffect(() => {
    clearTimeout(debounceQueue);
    let debounceQueueId = setTimeout(() => {
      props.updateFetchParams(selectedFilters);
    }, 500);
    setDebounceQueue(debounceQueueId);
  }, [selectedFilters]);

  const clearFilters = () => {
    setSelectedFilters({
      count: 8, // set this for pagesize
      offset: 0,
      text: '',
      status: '',
      type: '',
      sortBy: '',
      orderBy: '',
      isMenu: null,
      isCampaign: null,
      campaignStartDate: dateWithoutTime(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ),
      campaignEndDate: dateWithoutTime(new Date())
    });
  };
  const updateFilters = (e, type) => {
    if (type !== 'orderBy') {
      if (type === 'type') {
        setSelectedFilters(prevState => {
          return {
            ...prevState,
            isMenu: prevState.isMenu,
            isCampaign: prevState.isCampaign,
            [type]: e.target.value
          };
        });
      } else {
        setSelectedFilters(prevState => {
          return {
            ...prevState,
            [type]: e.target.value
          };
        });
      }
    } else {
      setSelectedFilters(prevState => {
        return {
          ...prevState,
          sortBy: 'count',
          [type]: e.target.value
        };
      });
    }
  };

  const updateFiltersForRadio = type => {
    setSelectedFilters(prevState => {
      return {
        ...prevState,
        [type]: prevState[type] ? null : true
      };
    });
  };

  const handleFromDateChange = date => {
    setSelectedFilters(prevState => {
      return {
        ...prevState,
        campaignStartDate: dateWithoutTime(date)
      };
    });
  };

  const handleToDateChange = date => {
    setSelectedFilters(prevState => {
      return {
        ...prevState,
        campaignEndDate: dateWithoutTime(date)
      };
    });
  };

  return (
    <Container className={classes.subContainer}>
      <Box className={classes.filters}>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
        >
          <InputLabel className={classes.inputLabel}>Status</InputLabel>
          <Select
            className={classes.select}
            value={selectedFilters.status}
            onChange={e => updateFilters(e, 'status')}
            label="Status"
            placeholder="Status"
          >
            <MenuItem key={1} value={'published'}>
              Published
            </MenuItem>
            <MenuItem key={2} value={'draft'}>
              Draft
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
        >
          <InputLabel>Type</InputLabel>
          <Select
            className={classes.select}
            value={selectedFilters.type}
            onChange={e => updateFilters(e, 'type')}
            label="Type"
            placeholder="Type"
          >
            <MenuItem key={1} value={'self'}>
              Collections
            </MenuItem>
            <MenuItem key={2} value={'product'}>
              Products
            </MenuItem>
          </Select>
        </FormControl>
        {selectedFilters.type !== '' && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
          >
            <InputLabel>Sort</InputLabel>
            <Select
              className={classes.select}
              value={selectedFilters.orderBy}
              onChange={e => updateFilters(e, 'orderBy')}
              label="Sort"
              placeholder="Sort"
            >
              <MenuItem key={1} value={'asc'}>
                Items (Low to High)
              </MenuItem>
              <MenuItem key={2} value={'desc'}>
                Items (High to Low)
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {selectedFilters.type === 'self' && (
          <Chip
            label="Menu"
            onClick={() => updateFiltersForRadio('isMenu')}
            variant="outlined"
            className={
              selectedFilters.isMenu ? classes.selected : classes.notSelected
            }
          />
        )}
        {selectedFilters.type !== '' && (
          <>
            <Chip
              label="Campaign"
              onClick={() => updateFiltersForRadio('isCampaign')}
              variant="outlined"
              className={
                selectedFilters.isCampaign
                  ? classes.selected
                  : classes.notSelected
              }
            />
            {selectedFilters.isCampaign && (
              <>
                <CalendarComponent
                  date={selectedFilters.campaignStartDate}
                  handleDateChange={handleFromDateChange}
                  height={40}
                  label="Campaign Start Date"
                />
                <CalendarComponent
                  minDate={selectedFilters.campaignStartDate}
                  date={selectedFilters.campaignEndDate}
                  handleDateChange={handleToDateChange}
                  height={40}
                  label="Campaign End Date"
                />
              </>
            )}
          </>
        )}
        <Chip
          label="Clear All"
          variant="outlined"
          onClick={() => clearFilters()}
          className={classes.clearBtn}
        />
      </Box>
    </Container>
  );
}

export default withRouter(GetCollectionsFilters);
