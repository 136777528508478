import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Loader from '../../../atoms/loader';
import { toaster } from '../../../atoms/toaster';
import Catalog from '../../../organisms/Coseller/Catalog';
import { API_BASE_URL } from '../../../../config/env';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  showApiErrorToast
} from '../../../../utils';
import { COSELLER, GLOBAL_EVENT_KEYS } from '../../../../constants';
import { Container, CatalogContainer, HeadingSection, Heading } from './styles';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import { Tooltip, Typography, Zoom } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { COSELLER_INFO } from '../../../../constants/tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import EmptyState from '../../../atoms/EmptyState';

class CosellerProducts extends React.Component {
  state = {
    isLoadingProductsPage: true,
    vendorListDataRawFormat: [],
    vendorListData: {},
    invites: {},
    isCoseller: false,
    vendorsList: [],
    selectedVendors: [],
    restrictions: {},
    adult: false,
    ageRestricted: false,
    selectedCurrency: '',
    vendorFetchParam: {
      count: 10, // set this for pagesize
      offset: 0,
      text: ''
    },
    vendorTotalCount: 0
  };

  componentDidMount() {
    const selectedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    this.fetchExtras(selectedCurrency);
    const isCoseller = localStorage.getItem('USER_TYPE') || false;
    if (isCoseller === COSELLER) this.setState({ isCoseller: true });
    EventsBaseClient.on(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );
  }

  componentWillUnmount() {
    EventsBaseClient.removeEventListener(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );
  }

  handleCurrencyChange = () => {
    const { adult, ageRestricted } = this.state;
    const selectedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    this.fetchVendorInvites(selectedCurrency, ageRestricted, adult);
  };

  updateVendorFetchParams = () => {
    let newfetchParam = { ...this.state.vendorFetchParam };
    let productsLeft =
      this.state.vendorTotalCount - this.state.vendorListDataRawFormat.length;
    if (productsLeft > 0) {
      if (productsLeft >= 10) {
        newfetchParam.offset += 10;
      } else {
        newfetchParam.offset += 10;
        newfetchParam.count = productsLeft;
      }
      this.setState({ vendorFetchParam: newfetchParam }, () => {
        this.handleCurrencyChange();
      });
    }
  };

  updateVendorFetchParamsBasedOnInput = value => {
    let newfetchParam = { ...this.state.vendorFetchParam };
    newfetchParam.text = value;
    newfetchParam.offset = 0;
    newfetchParam.count = 10;

    this.setState({ vendorFetchParam: newfetchParam }, () => {
      this.handleCurrencyChange();
    });
  };

  fetchVendorInvites = async (selectedCurrency, ageRestricted, adult) => {
    this.setState({ selectedVendors: [] });
    const token = getAuthToken();
    const headers = { authorization: token };

    let extraParams = `restrictionsKey=isAdult,isAgeRestricted,showDefault&restrictionsValue=${adult},${ageRestricted},true`;

    const response = await getApiResponseObject(
      `${API_BASE_URL}/query-vendors?currency=${selectedCurrency}&${extraParams}`,
      headers,
      this.state.vendorFetchParam
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      return;
    } else {
      const vendorListDataRawFormat =
        this.state.vendorFetchParam.offset === 0
          ? response.data?.vendors
          : [...this.state.vendorListDataRawFormat, ...response.data?.vendors];

      if (!Array.isArray(vendorListDataRawFormat)) {
        return toaster('Unable to fetch vendors');
      }

      this.setState(
        {
          vendorListDataRawFormat,
          isLoadingProductsPage: false,
          vendorTotalCount: response.data?.count
        },
        () => {
          let { vendorListDataRawFormat, vendorListData } = this.state;
          vendorListDataRawFormat.forEach(vendor => {
            vendorListData[vendor.name] = { vendorId: vendor.id };
          });

          let newVendorsList = vendorListDataRawFormat.map(
            vendor => vendor.name
          );
          this.setState({
            vendorListData,
            vendorsList: newVendorsList
          });
        }
      );
    }
  };

  updateSelectedVendors = selectedVendorsNames => {
    const selectedVendors = [];
    selectedVendorsNames.forEach(vendorName =>
      selectedVendors.push(this.state.vendorListData[vendorName].vendorId)
    );
    this.setState({ selectedVendors: selectedVendors });
  };

  fetchExtras = async selectedCurrency => {
    const token = getAuthToken();
    const headers = { authorization: token };
    this.props.showSpinnerBackdrop();

    const cosellerResponse = await getApiResponseObject(
      `${API_BASE_URL}/cosellers`,
      headers
    );

    if (cosellerResponse.data) {
      const restrictions = cosellerResponse.data.restrictions;
      const adult = restrictions.isAdult ? restrictions.isAdult : false;
      const ageRestricted = restrictions.isAgeRestricted
        ? restrictions.isAgeRestricted
        : false;
      this.fetchVendorInvites(selectedCurrency, ageRestricted, adult);

      this.setState({
        restrictions: restrictions || {},
        adult: adult,
        ageRestricted: ageRestricted
      });
    }
    this.props.hideSpinnerBackdrop();
  };

  render() {
    let {
      isLoadingProductsPage,
      vendorListData,
      vendorsList,
      selectedVendors,
      restrictions
    } = this.state;

    return (
      <Container>
        <HeadingSection>
          <Heading>Products</Heading>
          <Tooltip
            TransitionComponent={Zoom}
            title={COSELLER_INFO.pageDescription.Products}
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </HeadingSection>
        <CatalogContainer>
          {!isLoadingProductsPage && !isEmpty(vendorListData) ? (
            <Catalog
              vendorsList={vendorsList}
              updateSelectedVendors={this.updateSelectedVendors}
              selectedVendors={selectedVendors}
              restrictions={restrictions}
              updateVendorFetchParams={this.updateVendorFetchParams}
              updateVendorFetchParamsBasedOnInput={
                this.updateVendorFetchParamsBasedOnInput
              }
            />
          ) : (
            !isLoadingProductsPage && (
              <EmptyState errorMessage="No Vendor Invites Received" />
            )
          )}
        </CatalogContainer>
      </Container>
    );
  }
}

CosellerProducts.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CosellerProducts));
