import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const CatalogBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SyncContainer = styled.div`
  margin-left: 10px;
  position: relative;
`;

const InfoBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const SearchCount = styled.div`
  width: 100%;
  font-size: ${props => props.theme.SMALL};
`;

const SyncInfo = styled.div`
  width: 13vw;
  min-width: 80px;
  font-size: 9px;
  font-weight: bold;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  CatalogBar,
  Container,
  HeadingSection,
  Heading,
  SyncContainer,
  SyncInfo,
  SearchCount,
  InfoBar
};
