import { combineReducers } from 'redux';
import createProduct from './createProductsActions';
import totalAmount from './finalAmountReducer';
import buyProduct from './productDetailReducer';
import productsShare from './productsShareReducer';
import uiReducer from './uiReducer';
export default combineReducers({
  createProduct,
  totalAmount,
  buyProduct,
  uiReducer,
  productsShare
});
