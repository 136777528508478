import React from 'react';
import Icons from '../../atoms/icons';
import Logo from '../../../assets/images/logo.png';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  isLoggedIn,
  disableHomeRedirect,
  getRouteUserType
} from '../../../utils';
import history from '../../../history';
import { withRouter } from 'react-router-dom';
import LoginSelection from '../../organisms/LoginSelection';
import SignIn from '../../organisms/SignIn';
import SignUp from '../../organisms/SignUp';
import VendorOnBoard from '../../organisms/VendorOnBoard';
import NetworkOperatorOnBoard from '../../organisms/NetworkOperatorOnBoard';
import CosellerOnBoardSocialLogin from '../../organisms/CosellerOnBoardSocialLogin';
import CosellerOnBoardInterests from '../../organisms/CosellerOnBoardInterests';
import Message from '../../organisms/Message';
import ValidateEmail from '../../helpers/validateEmail';
import ForgotPassword from '../../organisms/ForgotPassword';
import ResetPassword from '../../organisms/ResetPassword';
import ResendVerificationEmail from '../../organisms/ResendVerificationEmail';
import qs from 'qs';

import {
  Container,
  LogoContainer,
  SelectionContainer,
  LogoImage,
  LogoContent,
  RouteContainer,
  RouteContent,
  Wrapper,
  Pane
} from './styles';
import STRoute from '../../atoms/STRoute';
import { GLOBAL_EVENT_KEYS } from '../../../constants';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signInStatus: true
    };
  }

  componentDidMount() {
    this.fetchinviteLinkSlug();
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
  }

  fetchinviteLinkSlug = () => {
    const inviteId = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    }).rid;
    if (inviteId) {
      sessionStorage.setItem('inviteId', inviteId);
    }
  };

  goHome = () => {
    localStorage.removeItem('USER_TYPE');
    if (disableHomeRedirect(history.location.pathname)) {
      return;
    }
    history.push('/');
  };

  render() {
    return (
      <Wrapper>
        <Container>
          <Pane>
            <LogoContainer>
              <LogoContent onClick={() => this.goHome()}>
                <LogoImage src={Logo} />
              </LogoContent>
            </LogoContainer>
            <RouteContent>
              <Icons name="create-account" width={300} height={260} />
            </RouteContent>
          </Pane>
          <Pane>
            <SelectionContainer>
              <RouteContainer>
                <Switch>
                  <STRoute exact path="/" component={SignIn} hideHelpChat />
                  <STRoute
                    path="/users/signin"
                    component={SignIn}
                    redirectIfLoggedIn
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/signup"
                    component={SignUp}
                    redirectIfLoggedIn
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/forgot-password"
                    component={ForgotPassword}
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/platform"
                    component={LoginSelection}
                    requiresLogin
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/vendor/onboard"
                    component={VendorOnBoard}
                    requiresLogin
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/network-operator/onboard"
                    component={NetworkOperatorOnBoard}
                    requiresLogin
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/coseller/onboard/sociallogin"
                    component={CosellerOnBoardSocialLogin}
                    requiresLogin
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/coseller/onboard/interests"
                    component={CosellerOnBoardInterests}
                    requiresLogin
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/message"
                    component={Message}
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/onboard/email-verification"
                    component={ValidateEmail}
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/reset-password"
                    component={ResetPassword}
                    hideHelpChat
                  />
                  <STRoute
                    path="/users/resend-verification-email"
                    component={ResendVerificationEmail}
                    hideHelpChat
                  />
                </Switch>
              </RouteContainer>
            </SelectionContainer>
          </Pane>
        </Container>
      </Wrapper>
    );
  }
}

CreateAccount.propTypes = {};

export default withRouter(CreateAccount);
