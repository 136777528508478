import styled, { css, keyframes } from 'styled-components';

const show = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  z-index: 1005;
  opacity: 1;
  animation: ${show} 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${props =>
    props.model === 'custom'
      ? `max-width: ${props.width}px;max-height: ${props.height}px;`
      : 'max-width: 720px;max-height: 600px;'}
  background-color: #fff;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
  padding: 1rem;
  display: grid;
  text-align: center;
  overflow: auto;
  z-index: 1;
  ${p =>
    ({
      default: css`
        max-height: 600px;
      `,
      'woocommerce-import': css`
        max-height: auto;
      `
    }[p.size] || css``)}
`;

const ModalBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.SOLID_BLACK};
  opacity: 0.5;
`;

export { ModalContainer, ModalContent, ModalBackdrop };
