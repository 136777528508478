import styled from 'styled-components';
import { Secondary } from '../../atoms/button';

const MainContainer = styled.div`
  display: flex;
  background-color: ${props => props.theme.SPRING_WOOD};
`;

const Content = styled.div`
  padding: 24px 48px;
  max-width: 1150px;
`;

const Heading = styled.div`
  margin-top: 100px;
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};
  text-align: left;
`;

const SecondLevelHeading = styled.div`
  margin-top: 100px;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  text-align: left;
  text-transform: uppercase;
  text-transform: uppercase;
`;

const SubHeading = styled.div`
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  margin-bottom: 30px;
`;

const Headline = styled.div`
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  margin-bottom: 20px;
`;

const EmbedCodeContainer = styled.div`
  textarea {
    border: unset;
    padding: 20px;
    width: 100%;
    min-height: 172px;
    border-radius: 5px;
    border: solid 1px #b2b2b2;
    background-color: ${props => props.theme.WHITE};
    font-size: ${props => props.theme.X_LARGE};
    font-weight: ${props => props.theme.LIGHT_FONT};
  }
`;

const CopyLink = styled(Secondary)`
  max-width: 130px;
  min-height: 40px;
  border-radius: 3px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
`;

const RadioButton = styled.div`
  margin-right: 10px;
`;

const EmbedRadioButtonContainer = styled.div`
  display: flex;
`;

const DesktopView = styled.div`
  margin-right: 50px;
`;

const Options = styled.div`
  display: flex;
  min-width: 670px;
  max-height: 450px;
`;

const CopyLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  MainContainer,
  Content,
  Heading,
  SecondLevelHeading,
  SubHeading,
  Headline,
  EmbedCodeContainer,
  CopyLink,
  RadioButton,
  EmbedRadioButtonContainer,
  DesktopView,
  Options,
  CopyLinkContainer
};
