import React from 'react';
import {
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paper: { minWidth: '500px' },
  alignCenter: { textAlign: 'center' }
}));

export default function ConfirmationDialog({
  open,
  title,
  description,
  onConfirm,
  onClose,
  onCancel,
  hideCancel
}) {
  const classes = useStyles();
  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.alignCenter}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          {!hideCancel ? 'Confirm' : 'Ok'}
        </Button>
        {!hideCancel && (
          <Button onClick={onCancel} color="primary" autoFocus>
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
