/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  makeStyles,
  Grid,
  Container,
  CssBaseline
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  getCountriesList,
  getCountryLabelsAndDialCodes
} from '../../../../constants/dial_codes';
import ReactFlagsSelect from 'react-flags-select';
import {
  getAuthToken,
  putWithResponseObject,
  successStatusCodes,
  showApiErrorToast,
  isFullNameValid
} from '../../../../utils';
import { API_BASE_URL } from '../../../../config/env';
import { toaster } from '../../../atoms/toaster';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: 0,
    padding: 0
  },
  paper: {
    maxWidth: 550
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  phone: {
    width: '165px',
    height: '55px'
  },
  submit: {
    margin: theme.spacing(4, 0, 0, 0),
    height: '45px',
    width: '100px'
  }
}));

const PersonalInfo = props => {
  const classes = useStyles();
  const { personalInfo } = props;

  // Personal Info
  const [personalDetails, setPersonalDetails] = useState({
    fullName: '',
    email: '',
    phone: ''
  });
  const [selectedCountry, setSelectedCountry] = useState('US');

  const { fullName, email, phone } = personalDetails;

  useEffect(() => {
    fetchPersonalDetails();
  }, [props]);

  const fetchPersonalDetails = () => {
    if (!isEmpty(personalInfo)) {
      setPersonalDetails({
        fullName: personalInfo?.name || '',
        email: personalInfo?.email, //non-editable
        phone: personalInfo?.phone || ''
      });
    } else {
      toaster('Unable to fetch personal details');
    }
  };

  const handleChange = event => {
    let { name, value } = event.target;
    setPersonalDetails({
      ...personalDetails,
      [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const token = getAuthToken();
    const headers = { authorization: token };

    if (!isFullNameValid(fullName)) {
      toaster('Invalid Username');
      return;
    }

    const requestBody = {
      name: fullName
    };

    const response = await putWithResponseObject(
      `${API_BASE_URL}/me`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      return;
    } else {
      toaster('Profile Info updated');
    }
  };

  return (
    <Container component="main" maxWidth="sm" className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="fullName"
                name="fullName"
                label="Full Name"
                autoComplete="fullName"
                variant="outlined"
                fullWidth
                value={fullName}
                onChange={e => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ReactFlagsSelect
                selected={selectedCountry}
                countries={getCountriesList()}
                customLabels={getCountryLabelsAndDialCodes()}
                onSelect={code => setSelectedCountry(code)}
                showSelectedLabel={false}
                fullWidth={false}
                selectedSize={18}
                optionsSize={16}
                searchable
                selectButtonClassName={classes.phone}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Telephone"
                variant="outlined"
                value={phone}
                name="phone"
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            className={classes.submit}
            type="submit"
            variant="outlined"
            size="large"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default PersonalInfo;
