import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import { bool, string, object, func } from 'prop-types';
import qs from 'qs';
import {
  WOOCOMMERCE,
  PRODUCT_CHECKOUT,
  VARIANT_COMBO_DELIMITER,
  GLOBAL_EVENT_KEYS
} from '../../../constants';
import { Filled as CheckoutButton, CloseButton } from '../../atoms/button';
import { toaster } from '../../atoms/toaster';
import history from '../../../history';
import Currency from '../../molecules/Currency';
import {
  CartContainer,
  CartHeading,
  CartLineItems,
  LineItemDetails,
  LineItemPrimaryDetails,
  LineItemSecondaryDetails,
  LineItemQuantity,
  LineItemPrice,
  CartFooter,
  CartFooterItem,
  LineItemVendorDetails
} from './styles';
import {
  postWithResponseObject,
  successStatusCodes,
  getCartId,
  getAuthHeaders,
  showApiErrorToast
} from '../../../utils';
import { API_BASE_URL, APP_BASE_URL } from '../../../config/env';
import { deleteCartItem, updateCartItemQuantity } from '../../../apis/cart';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';

const styles = props => ({
  colorPrimary: {
    backgroundColor: '#E6C100'
  },
  barColorPrimary: {
    backgroundColor: '#EDE5C0'
  }
});

class CheckoutCart extends React.Component {
  sendUserProductCheckoutEvent = async (
    checkoutId,
    fetchedProductId,
    client,
    vendorId
  ) => {
    const productId = fetchedProductId;
    const id = localStorage.getItem('deviceId') || '';
    let decodedCheckoutId;
    if (client === WOOCOMMERCE) {
      decodedCheckoutId = checkoutId;
    } else if (client === 'SHOPIFY') {
      decodedCheckoutId = checkoutId
        ? new Buffer(checkoutId || '', 'base64')
            .toString('ascii')
            .split('/')[4]
            .split('?')[0]
        : '';
    }

    const url = history.location.search;
    const uid = qs.parse(url, {
      ignoreQueryPrefix: true
    }).uid;
    const nid = qs.parse(url, {
      ignoreQueryPrefix: true
    }).nid;
    const cid = qs.parse(url, {
      ignoreQueryPrefix: true
    }).cid;

    let requestBody = {
      deviceId: id,
      action: PRODUCT_CHECKOUT,
      vendorId: vendorId,
      userId: uid || '',
      networkId: nid || '',
      cosellerId: cid || '',
      productId: productId,
      checkoutId: decodedCheckoutId || '',
      url: window.location.href,
      referrer: this.props.productReferrer
    };

    if (sessionStorage.getItem('checkout_id') != null) {
      requestBody.checkoutId = sessionStorage.getItem('checkout_id');
    }
    const response = await postWithResponseObject(
      `${API_BASE_URL}/user-event`,
      requestBody
    );

    if (successStatusCodes.includes(response.status)) {
      sessionStorage.setItem('USER_EVENT_STATUS', 'user view event success');
      return 'success';
    } else {
      sessionStorage.setItem('USER_EVENT_STATUS', 'user view event failed');
      return 'fail';
    }
  };

  changeQuantity = (action, variant) => {
    const reqBody = {
      product_id: variant.product_id,
      product_variant_id: variant.product_variant_id,
      variant_name_value: variant.variant_name_value,
      quantity:
        action === 'increase' ? variant.quantity + 1 : variant.quantity - 1
    };

    this.props.toggleCartLoader && this.props.toggleCartLoader('show');

    updateCartItemQuantity(reqBody).then(res => {
      if (res?.status && !successStatusCodes.includes(res.status)) {
        showApiErrorToast(res.data);
      }
      this.props.updateCartDetails();
    });
  };

  removeVariant = async variant => {
    const reqBody = {
      product_id: variant.product_id,
      product_variant_id: variant.product_variant_id,
      variant_name_value: variant.variant_name_value
    };

    this.props.toggleCartLoader && this.props.toggleCartLoader('show');

    deleteCartItem(reqBody).then(() => {
      this.props.updateCartDetails();
    });
  };

  handleCheckoutClick = async () => {
    const { cart } = this.props;
    const cartId = getCartId();
    let lineItems = cart.cart_lines;

    if (isEmpty(lineItems)) {
      return toaster('Your Cart is empty!');
    }

    const reqBody = {
      deviceId: localStorage.getItem('deviceId') || '',
      cartId
    };

    const res = await postWithResponseObject(
      `${API_BASE_URL}/checkout`,
      reqBody,
      getAuthHeaders()
    );

    if (successStatusCodes.includes(res.status)) {
      const data = res?.data;
      const redirectUri = data?.redirect_uri;

      if (data?.external_url) {
        window.open(redirectUri); // earlier we used to send the redirectUri to the parent window but now we just open the URI in a new window
      } else {
        history.push(redirectUri);
      }

      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
      return;
    } else {
      return toaster(
        res.data.error
          ? res.data.error.message
          : res.data.message
          ? res.data.message
          : 'Cannot proceed with the checkout'
      );
    }
  };

  render() {
    const {
      cart,
      isCartOpen,
      hideCloseButton,
      handleCloseCart,
      onCartDataChange,
      loadingCart,
      classes
    } = this.props;

    if (onCartDataChange) {
      onCartDataChange();
    }

    return (
      <CartContainer display={isCartOpen ? 'show' : 'hide'}>
        {!hideCloseButton && (
          <CloseButton
            width="30px"
            height="30px"
            size="custom"
            borderColor="white"
            onClick={handleCloseCart || undefined}
          />
        )}
        <CartHeading>Your Cart</CartHeading>
        <div style={{ visibility: loadingCart ? 'visible' : 'hidden' }}>
          <LinearProgress
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary: classes.barColorPrimary
            }}
          />
        </div>
        <CartLineItems>
          {cart?.cart_lines?.length > 0
            ? cart.cart_lines.map((item, index) => {
                return (
                  <LineItemDetails key={index}>
                    <LineItemPrimaryDetails>
                      <img src={item.image_src || ''} alt="product-media" />
                      <div>
                        <div>{item.name || ''}</div>
                        <div>
                          {item.variant_name_value
                            ? Object.values(item.variant_name_value).join(
                                VARIANT_COMBO_DELIMITER
                              )
                            : ''}
                        </div>
                      </div>
                    </LineItemPrimaryDetails>
                    <LineItemSecondaryDetails>
                      <LineItemQuantity>
                        <button
                          disabled={loadingCart}
                          onClick={() => {
                            this.changeQuantity('decrease', item);
                          }}
                        >
                          -
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          disabled={loadingCart}
                          onClick={() => {
                            this.changeQuantity('increase', item);
                          }}
                        >
                          +
                        </button>
                      </LineItemQuantity>
                      <LineItemPrice>
                        <Currency currencyName={item?.price?.currency} />
                        {item?.price?.amount}
                      </LineItemPrice>
                      <CloseButton
                        width="20px"
                        height="20px"
                        size="custom"
                        borderColor="white"
                        disabled={loadingCart}
                        onClick={() => {
                          this.removeVariant(item);
                        }}
                      />
                    </LineItemSecondaryDetails>
                    <LineItemVendorDetails>
                      By&nbsp;{item.vendor_name}
                    </LineItemVendorDetails>
                  </LineItemDetails>
                );
              })
            : null}
        </CartLineItems>
        <CartFooter>
          <CartFooterItem>
            <div>TOTAL</div>
            <div>
              <Currency currencyName={cart?.sub_total?.currency} />
              {cart?.sub_total?.amount.toFixed(2) || 0}
            </div>
          </CartFooterItem>
          <CheckoutButton
            text="Checkout"
            textColor="white"
            backgroundColor="#2752ff"
            borderColor="#2752ff"
            size="product-checkout-button"
            onClick={() => this.handleCheckoutClick()}
          />
        </CartFooter>
      </CartContainer>
    );
  }
}

CheckoutCart.propTypes = {
  isCartOpen: bool,
  hideCloseButton: bool,
  client: string,
  fetchedProductDetails: object,
  productId: string,
  hasVariants: bool,
  sourceId: string,
  buyProductData: object,
  productReferrer: string,
  handleCloseCart: func,
  onCartDataChange: func,
  cart: object,
  loadingCart: bool,
  toggleCartLoader: func,
  updateCartDetails: func,
  classes: object
};

CheckoutCart.defaultProps = {
  onCartDataChange: undefined
};

const mapStateToProps = state => {
  const { buyProduct } = state;
  return { buyProductData: buyProduct };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckoutCart))
);
