import React from 'react';
import PropTypes from 'prop-types';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import { Link, withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Icons from '../../atoms/icons';
import {
  getApiResponseObject,
  postWithResponseObject,
  successStatusCodes,
  isUserNameValid,
  showApiErrorToast
} from '../../../utils';
import history from '../../../history';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/uiActions';
import PhoneNumberInput from '../../atoms/phoneNumberInput';

import {
  PASSWORD_PATTERN,
  isEmailValid,
  isPasswordValid,
  isPhoneValid,
  getAuthToken
} from '../../../utils';

import {
  GOOGLEPROVIDER,
  FACEBOOKPROVIDER,
  DOMAIN,
  FACEBOOKAPPID,
  GOOGLECLIENTID
} from '../../../constants';

import {
  SignUpForm,
  Next,
  Email,
  AuthButton,
  Container,
  Separator,
  AuthContainer,
  SignUpButton,
  LoginContainer,
  SeperatorContainer,
  OR,
  Password,
  Row,
  FirstName,
  LastName
} from './styles';

class SignUpTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      phone: '',
      email: '',
      password: '',
      response: '',
      isLoading: false,
      disableButton: false
    };
  }

  updateUserProfileToLocalStorage = async (id, token) => {
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      localStorage.setItem(
        'userProfile',
        JSON.stringify(profileRes.data || {})
      );
      localStorage.setItem(
        '_id',
        JSON.stringify(id || (profileRes.data && profileRes.data._id) || null)
      );
    } else {
      localStorage.setItem('userProfile', JSON.stringify({}));
    }

    return profileRes.data || {};
  };

  getAwakeMeRedirectToken = () => {
    let redirectUrlParams = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
      m,
      key,
      value
    ) {
      redirectUrlParams[key] = value;
    });
    return redirectUrlParams.token;
  };

  responseGoogle = response => {
    let gmailToken = response.tokenId;
    if (response && response.tokenId) {
      if (gmailToken) {
        this.signUp(gmailToken, GOOGLEPROVIDER);
      } else if (response.error) {
        return toaster('Error Connecting to Gmail');
      }
    } else if (response && response.error === 'popup_closed_by_user') {
      return toaster('Error Connecting to Gmail');
    }
  };

  responseFacebook = response => {
    let facebookToken = response.accessToken;
    if (facebookToken) {
      this.signUp(facebookToken, FACEBOOKPROVIDER);
    } else {
      return toaster('Error Connecting to Facebook');
    }
  };

  signUpSubmitHandler = () => {
    let { signInStatus } = this.state;
    if (!signInStatus) {
      let inputValidationError = this.inputsAreInvalid(!signInStatus);

      return !inputValidationError
        ? this.signUp()
        : toaster(inputValidationError);
    } else {
      this.setState({
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: '',
        signInStatus: false
      });
    }
  };

  inputChangeHandler = e => {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  keyPressHandler = e => {
    let { signInStatus } = this.state;
    let error = this.inputsAreInvalid(!signInStatus);
    if (!error && e.key === 'Enter') {
      if (signInStatus) {
        this.signIn();
      } else {
        this.signUp();
      }
    } else if (e.key === 'Enter') {
      toaster(error);
    }
  };

  signUp = async (token, provider) => {
    const { email } = this.state;
    const inviteId = sessionStorage.getItem('inviteId');
    let response = '';
    if (token) {
      let url = `${API_BASE_URL}/social-login`;
      const requestBody = {
        token: token,
        provider: provider,
        domain: DOMAIN
      };

      if (inviteId) {
        url = `${API_BASE_URL}/social-login?rid=${inviteId}`;
      }

      this.props.showSpinnerBackdrop();
      this.setState({ disableButton: true });
      response = await postWithResponseObject(url, requestBody);
      this.setState({ disableButton: false });
      this.props.hideSpinnerBackdrop();
    } else {
      let url = `${API_BASE_URL}/register`;
      const requestBody = {
        name: this.state.fname.trim() + ' ' + this.state.lname.trim(),
        email: this.state.email,
        password: this.state.password
      };

      if (inviteId) {
        url = `${API_BASE_URL}/register?rid=${inviteId}`;
      }

      this.props.showSpinnerBackdrop();
      this.setState({ disableButton: true });
      response = await postWithResponseObject(url, requestBody);
      this.setState({ disableButton: false });
      this.props.hideSpinnerBackdrop();
    }

    if (successStatusCodes.includes(response.status)) {
      if (
        provider &&
        (provider === FACEBOOKPROVIDER || provider === GOOGLEPROVIDER)
      ) {
        localStorage.setItem('token', JSON.stringify(response.data.data.token));
        localStorage.setItem('_id', JSON.stringify(response.data.data._id));

        localStorage.removeItem('inviteId');

        history.push('/users/platform');
      } else {
        sessionStorage.removeItem('socialAccounts');
        localStorage.setItem('_email', JSON.stringify(email));
        localStorage.setItem(
          '_id',
          JSON.stringify((response.data && response.data.id) || null)
        );
        history.push({
          pathname: '/users/message',
          search: '?type=verify_email'
        });
      }
    } else {
      showApiErrorToast(response.data);
    }
  };

  inputsAreInvalid = signUpStatus => {
    if (signUpStatus && !isUserNameValid(this.state.fname)) {
      return 'Please enter a valid first name.';
    }

    if (signUpStatus && !isUserNameValid(this.state.lname)) {
      return 'Please enter a valid last name.';
    }
    if (!isEmailValid(this.state.email)) {
      return 'Please enter a valid email id.';
    }

    if (signUpStatus && !isPasswordValid(this.state.password)) {
      return 'Password should contain at least 6 characters.';
    } else if (!this.state.password) {
      return 'Please enter password.';
    }

    return null;
  };

  toggleLogin = () => {
    this.setState({ status: true });
  };

  handleSignIn = () => {
    history.push('/users/signin');
  };

  render() {
    const { fname, lname, email, password, phone, disableButton } = this.state;
    const { isSignUp } = this.props;

    return (
      <>
        <Container>
          <div>
            <SignUpForm>
              <Row>
                <FirstName
                  name="fname"
                  type="fname"
                  placeHolder="First name"
                  id="fname"
                  value={fname}
                  onChange={this.inputChangeHandler}
                  autocomplete="off"
                  onKeyPress={event => {
                    this.keyPressHandler(event);
                  }}
                />
                <LastName
                  name="lname"
                  type="lname"
                  placeHolder="Last name"
                  id="lname"
                  value={lname}
                  onChange={this.inputChangeHandler}
                  autocomplete="off"
                  onKeyPress={event => {
                    this.keyPressHandler(event);
                  }}
                />
              </Row>
              <Email
                name="email"
                type="email"
                placeHolder="Email"
                id="email"
                value={email}
                onChange={this.inputChangeHandler}
                autocomplete="off"
                onKeyPress={event => {
                  this.keyPressHandler(event);
                }}
              />
              <Password
                id="password"
                name="password"
                type="password"
                placeHolder="Password"
                value={password}
                onChange={this.inputChangeHandler}
                pattern={PASSWORD_PATTERN}
                autocomplete="off"
                onKeyPress={event => {
                  this.keyPressHandler(event);
                }}
              />
            </SignUpForm>
          </div>
          <SeperatorContainer>
            <Separator />
            <OR>OR</OR>
          </SeperatorContainer>
          <AuthContainer>
            <FacebookLogin
              appId={FACEBOOKAPPID}
              callback={this.responseFacebook}
              render={renderProps => (
                <AuthButton onClick={renderProps.onClick}>
                  <Icons name="facebook-oauth" width={40} height={40} />
                </AuthButton>
              )}
            />
            <GoogleLogin
              clientId={GOOGLECLIENTID}
              render={renderProps => (
                <AuthButton
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <Icons name="google-oauth" width={40} height={40} />
                </AuthButton>
              )}
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </AuthContainer>
        </Container>
        <LoginContainer>
          <SignUpButton>
            <Next
              text="Sign Up"
              disabled={disableButton}
              onClick={this.signUpSubmitHandler}
            />
          </SignUpButton>
          <SignUpButton>
            <Next
              text="Sign In"
              disabled={disableButton}
              onClick={() => this.handleSignIn()}
              isSignUp={isSignUp}
            />
          </SignUpButton>
        </LoginContainer>
      </>
    );
  }
}

SignUpTab.propTypes = {
  history: PropTypes.object.isRequired,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(SignUpTab));
