import styled from 'styled-components';

const InputFieldContainer = styled.div`
  width: 100%;
  margin: 12px 0;

  & > div {
    width: 100%;
  }
`;

const ApiKey = styled.div`
  width: 100%;
  padding: 6px;
  border-radius: 4px;
  border: 1.8px solid #b8b8b8;
  overflow-wrap: break-word;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
`;

const CopyButtonContainer = styled.div`
  margin-left: 8px;
`;

export { InputFieldContainer, ApiKey, Row, CopyButtonContainer };
