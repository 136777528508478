/* eslint-disable react/prop-types */
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  Slide,
  Snackbar,
  TextField
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../../config/env';
import { getCountryLabels } from '../../../../constants/dial_codes';
import shoptypeThemes from '../../../../theme';
import {
  getApiResponseObject,
  getAuthToken,
  isUrlValid,
  putWithResponseObject,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import { toaster } from '../../../atoms/toaster';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: 0,
    padding: 0
  },
  paper: {
    maxWidth: 550
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(4, 0, 0, 0),
    height: '45px',
    width: '100px'
  },
  copyButton: {
    marginLeft: '-74px',
    marginTop: '12px',
    backgroundColor: shoptypeThemes.default.WILD_SAND
  }
}));

const NetworkInfo = props => {
  const classes = useStyles();
  const { networkInfo } = props;

  // Network Info
  const [networkDetails, setNetworkDetails] = useState({
    name: networkInfo?.network?.name || '',
    platformId: networkInfo?.platforms[0]?.id || '',
    url: networkInfo?.platforms[0]?.url || '',
    previewImage: networkInfo?.network?.preview_image_url || '',
    country: networkInfo?.platforms[0]?.country || '',
    zip: networkInfo?.platforms[0]?.zip || ''
  });

  const { name, platformId, url, previewImage, country, zip } = networkDetails;

  // Snackbar (copy to clipboard)
  const [open, setOpen] = useState(false);

  // Geolocation
  const [geolocation, setGeolocation] = useState('');

  useEffect(() => {
    getCoOrdinates();
  }, []);

  const handleNetworkDetails = event => {
    const { name, value } = event.target;
    setNetworkDetails({ ...networkDetails, [name]: value });
  };

  const getCoOrdinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          getLocationDetails(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        err => {
          console.log(err);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    }
  };

  const getLocationDetails = async (lat, long) => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const locationParams = `geoLat=${lat}&geoLng=${long}`;
    const response = await getApiResponseObject(
      `${API_BASE_URL}/address?${locationParams}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      const formattedAddress = response.data?.formatted_address;
      localStorage.setItem('locationInfo', formattedAddress);
      setGeolocation(formattedAddress);
    }
  };

  const copyPlatformId = () => {
    setOpen(true);
    navigator.clipboard.writeText(platformId);
  };

  const handleSave = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const requestBody = {
      name,
      platformId,
      url,
      previewImage,
      country,
      zip
    };

    const response = await putWithResponseObject(
      `${API_BASE_URL}/networks`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      toaster('Network details updated successfully');
    }
  };

  return (
    <Container component="main" maxWidth="md" className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="name"
                name="name"
                label="Network Name"
                fullWidth
                value={name}
                variant="outlined"
                onChange={handleNetworkDetails}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="url"
                name="url"
                label="Platform URL"
                fullWidth
                variant="outlined"
                value={url}
                onChange={handleNetworkDetails}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                style={{ width: '100%', position: 'relative' }}
                id="platformid"
                name="platformid"
                label="Platform ID (can't edit)"
                fullWidth
                variant="outlined"
                value={platformId}
              />
              <Button
                className={classes.copyButton}
                variant="outlined"
                size="small"
                onClick={copyPlatformId}
              >
                Copy
              </Button>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                TransitionComponent={Slide}
                transitionDuration={{ enter: 1000, exit: 2000 }}
                TransitionProps={{ enter: true, exit: false }}
                onClose={() => setOpen(false)}
                open={open}
              >
                <Alert severity="success">Copied to clipboard</Alert>
              </Snackbar>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="zip"
                name="zip"
                label="Zip"
                fullWidth
                variant="outlined"
                value={zip}
                onChange={handleNetworkDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="geolocation"
                name="geolocation"
                fullWidth
                label="Geolocation"
                variant="outlined"
                value={geolocation}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                id="country"
                name="country"
                fullWidth
                value={country}
                options={getCountryLabels()}
                getOptionSelected={option => option}
                onChange={(e, value) =>
                  setNetworkDetails({
                    ...networkDetails,
                    country: value
                  })
                }
                renderInput={params => (
                  <TextField {...params} variant="outlined" label="Country" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id="previewImage"
                name="previewImage"
                label="Image URL"
                fullWidth
                variant="outlined"
                value={previewImage}
                onChange={handleNetworkDetails}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.submit}
            variant="outlined"
            size="large"
            onClick={handleSave}
          >
            Save
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default NetworkInfo;
