import { API_BASE_URL } from '../config/env';
import { DIAL_CODES } from './dial_codes';

const FACEBOOKPROVIDER = 'FACEBOOK';
const GOOGLEPROVIDER = 'GOOGLE';
const DOMAIN = 'SHOPTYPE';
const SHOPIFY = 'SHOPIFY';
const PLAYTABLE = 'PLAYTABLE';
const WOOCOMMERCE = 'woo-commerce';
const BLOKPARTY = 'BLOK-PARTY';

const ORDER_STATUS_COMPLETED_WOOCOMMERCE = 'completed';
const ORDER_STATUS_COMPLETED_SHOPIFY = 'fulfilled';

const SHOPIFY_SOURCE = 'shopify';
const WOOCOMMERCE_SOURCE = 'woo-commerce';

const USER_TYPE = 'COSELLER';
const CATALOG = 'CATALOG';
let SHOPTYPE_WIDGET_URL = '';
const STAGE_URL = 'https://beta-backend.shoptype.com';
const DEV_URL = 'https://dev-backend.shoptype.com';
const STAGE_WIDGET_URL =
  'https://shoptype-scripts.s3.amazonaws.com/Widgets/ShoptypeStagingWidget_0.1.2.js';
const PROD_WIDGET_URL =
  'https://shoptype-scripts.s3.amazonaws.com/Widgets/ShoptypeProductionWidget_0.1.2.js';
const DEV_WIDGET_URL =
  'https://shoptype-scripts.s3.amazonaws.com/Widgets/ShoptypeDevWidget_0.1.2.js';

const AWAKE_REDIRECT =
  'http://beta-awake.me.s3-website-us-east-1.amazonaws.com/?SSO=true&APP_ID=123456789';

const DESKTOP = 'Desktop';
const MOBILE = 'Mobile';

const IMAGE = 'image';
const IMAGES = 'images';
const VIDEO = 'video';
const VIDEOS = 'videos';

const VARIANT_COMBO_DELIMITER = ' | ';

const COSELLER_ROUTES = {
  SOCIAL_LOGIN: '/users/coseller/onboard/sociallogin',
  SIGNIN: '/users/coseller/signin',
  SIGNUP: '/users/coseller/signup',
  INTERESTS: '/users/coseller/onboard/interests'
};

const VENDOR = 'VENDOR';
const COSELLER = 'COSELLER';
const NETWORK_OPERATOR = 'NETWORK_OPERATOR';

const INVITE_TYPE_COSELLER = 'coseller';
const INVITE_TYPE_REFERRER = 'referrer';

const ACCEPTED = 'accepted';
const PENDING = 'pending';

if (API_BASE_URL === STAGE_URL) {
  SHOPTYPE_WIDGET_URL = STAGE_WIDGET_URL;
} else if (API_BASE_URL === DEV_URL) {
  SHOPTYPE_WIDGET_URL = DEV_WIDGET_URL;
} else {
  SHOPTYPE_WIDGET_URL = PROD_WIDGET_URL;
}

const GLOBAL_EVENT_KEYS = {
  CURRENCY_CHANGE_EVENT: 'CURRENCY_CHANGE_EVENT',
  USER_PROFILE_DATA_CHANGE_EVENT: 'USER_PROFILE_DATA_CHANGE_EVENT',
  TOGGLE_NAVBAR_AND_SIDEBAR: 'TOGGLE_NAVBAR_AND_SIDEBAR',
  UPDATE_SIDEBAR_CATEGORIES: 'UPDATE_SIDEBAR_CATEGORIES'
};

const FACEBOOKAPPID = '908250310106079';
const GOOGLECLIENTID =
  '303271545753-dnnm7vmokdg7vhlgtmogk3oajrb0v0sg.apps.googleusercontent.com';
const SHOPTYPE_LOGO = 'https://shoptype-media-assets.s3.amazonaws.com/logo.png';
const MOBILE_MEARKETING_LOGO =
  'https://shoptype-media-assets.s3.amazonaws.com/undraw-mobile-marketing-iqbr%403x.png';
const FACEBOOK_LOGO =
  'https://shoptype-media-assets.s3.amazonaws.com/fb%403x.png';
const TWITTER_LOGO =
  'https://shoptype-media-assets.s3.amazonaws.com/twitter%403x.png';

const GOOGLE_MAPS_API_KEY = 'AIzaSyAnQ4vEirZ623md3yiqDT-hJiFCz87hvVs';

const RAZOR_PAY_KEY = 'rzp_test_sH7o7s7QM7L8vg';
const STRIPE_PUBLIC_KEY =
  'pk_test_51GvkFkFQPnig6EmyrxxbVmWEHOTi1jX9Xe6xkteaTvMJXgdhAVC9TNeN92kv9jGG9nurGqI3aRslyg9zCJ13I2cd00ZB2Cg3np';

const PRODUCT_VIEW = 'product_view';
const PRODUCT_CHECKOUT = 'product_checkout';

const PRODUCT_CATEGORIES = [
  'Apparel',
  'Automotive',
  'Books/Music/Video',
  'Computer/Electronics',
  'Cannabis',
  'Flower/Gifts',
  'Food/Drugs',
  'Hardware/HomeImp',
  'Health/Beauty',
  'Houseware',
  'Jewelry',
  'Office Supplies',
  'Petcare',
  'Speciality',
  'Sporting Goods',
  'Toys/Hobbies',
  'Others'
];

const FORM_ID = {
  payments: 'c6fm6eth5s5qcs6lsn60',
  payouts: 'c6fm6hdh5s5qd57giol0'
};

const CURRENCY_SYMBOL = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BRL: 'R$',
  BSD: '$',
  BTC: '฿',
  BTN: 'Nu.',
  BWP: 'P',
  BYR: 'Br',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: 'лв',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: '₲',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$'
};

const TRANSACTION_PDF_HEADERS = [
  'Transaction Date',
  'Transaction Id',
  'Order Id',
  'Description',
  'Status',
  'Debit/Credit',
  'Amount'
];
const TRANSACTION_PDF_HEADERS_CSV = [
  { label: 'Transaction Date', key: 'created_at' },
  { label: 'Transaction Id', key: 'transaction_id' },
  { label: 'Order Id', key: 'external_id' },
  { label: 'Description', key: 'ref' },
  { label: 'Status', key: 'status' },
  { label: 'Debit/Credit', key: 'entry_type' },
  { label: 'Amount', key: 'amount' }
];
const TRANSACTION_PDF_KEYS = [
  'created_at',
  'transaction_id',
  'external_id',
  'ref',
  'status',
  'entry_type',
  'amount'
];

export {
  FACEBOOKPROVIDER,
  GOOGLEPROVIDER,
  DOMAIN,
  SHOPIFY,
  PLAYTABLE,
  WOOCOMMERCE,
  ORDER_STATUS_COMPLETED_WOOCOMMERCE,
  ORDER_STATUS_COMPLETED_SHOPIFY,
  SHOPIFY_SOURCE,
  WOOCOMMERCE_SOURCE,
  BLOKPARTY,
  USER_TYPE,
  CATALOG,
  SHOPTYPE_WIDGET_URL,
  AWAKE_REDIRECT,
  DESKTOP,
  MOBILE,
  IMAGE,
  IMAGES,
  VIDEO,
  VIDEOS,
  COSELLER_ROUTES,
  FACEBOOKAPPID,
  GOOGLECLIENTID,
  VENDOR,
  COSELLER,
  INVITE_TYPE_COSELLER,
  INVITE_TYPE_REFERRER,
  ACCEPTED,
  PENDING,
  SHOPTYPE_LOGO,
  MOBILE_MEARKETING_LOGO,
  FACEBOOK_LOGO,
  TWITTER_LOGO,
  GOOGLE_MAPS_API_KEY,
  RAZOR_PAY_KEY,
  PRODUCT_VIEW,
  PRODUCT_CHECKOUT,
  PRODUCT_CATEGORIES,
  FORM_ID,
  CURRENCY_SYMBOL,
  NETWORK_OPERATOR,
  GLOBAL_EVENT_KEYS,
  VARIANT_COMBO_DELIMITER,
  STRIPE_PUBLIC_KEY,
  DIAL_CODES,
  TRANSACTION_PDF_HEADERS,
  TRANSACTION_PDF_KEYS,
  TRANSACTION_PDF_HEADERS_CSV
};
