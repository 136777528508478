import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const ContentSection = styled.div`
  background-color: ${props => props.theme.SPRING_WOOD};
  color: ${props => props.theme.MINE_SHAFT};
  display: block;
  width: 100%;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-bottom: 30px;
`;

const Content = styled.div`
  padding: 24px 48px;
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;
  text-align: center;
`;

const ChartContainer = styled.div`
  display: flex;
  height: calc(100vh - 70px);
  width: 100%;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);

  & > div:nth-of-type(3) {
    flex: 1;
  }

  ${props => props.theme.TABLET`
  display: unset;

  & > div:nth-of-type(3) {
    flex: unset;
  }

  ${UserNodeLabel} {
    text-align: left;
  }

  ${UserNodesContainer} {
    padding: 8px 8px 8px 0px;
    margin: 8px 8px 8px 0px;
    box-sizing: unset;
    border-left: unset;
    border-right: unset;
  }

  ${NotAvailableMessage} {
    padding: 12px 12px 12px 0px;
    margin: 12px 12px 12px 0px;
    text-align: left;
  }
  `};
`;

const NotAvailableMessage = styled.div`
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.LIGHT_FONT};
  text-align: center;
  padding: 12px;
  margin: 12px;
`;

const UserNodesContainer = styled.div`
  padding: 8px;
  margin: 8px;
  box-sizing: border-box;
  border-left: 1.8px solid #e8e8e8;
  border-right: 1.8px solid #e8e8e8;
`;

const UserNodeLabel = styled.div`
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.BOLD_FONT};
  margin: 12px 0;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export {
  Container,
  ContentSection,
  Content,
  Heading,
  ErrorMessageContainer,
  ChartContainer,
  NotAvailableMessage,
  UserNodesContainer,
  UserNodeLabel,
  Row
};
