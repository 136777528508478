import styled from 'styled-components';

const Calendar = styled.div`
  width: 200px;
`;

const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 10px;
`;

export { Calendar, CalendarContainer };
