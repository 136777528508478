import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { withSnackbar } from '../../../hoc/withSnackbar';
import {
  SubHeading,
  FieldContainer,
  UploadImageLabel,
  UploadImageFile,
  UploadImage,
  CheckboxContainer,
  DateHeading,
  DateContainer,
  SelectedProductsContainer,
  AlignItemInCenter,
  MediumHeading
} from '../../Dashboard/styles';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Card,
  CardContent,
  Typography,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { toaster } from '../../../atoms/toaster';
import CalendarComponent from '../../../atoms/calendar';
import { isEmpty } from 'lodash';
import {
  ProductContainer,
  ProductSectionCollections
} from '../../../organisms/Catalog/styles';
import { CURRENCY_SYMBOL } from '../../../../constants';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import { getAuthToken, post } from '../../../../utils';
import { API_BASE_URL } from '../../../../config/env';
import * as actions from '../../../../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import noBannerImage from '../../../../assets/images/no-preview-available.png';
import shoptypeThemes from '../../../../theme';
import { SecondaryButton } from '../../../templates/OrderDetails/AllOrders/styles';
import Modal from '../../../templates/Modal';
import { PanelContent } from '../../AwakeMoney/Dashboard/styles';
import { CalendarContainer } from '../Orders/styles';

const useStyles = makeStyles({
  input: {
    backgroundColor: 'white',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset'
    }
  },
  flexBox: {
    width: '100%',
    display: 'flex'
  },
  calendarPicker: {
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: '#fff',
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute !important',
      top: '53px !important'
    }
  },
  root: {
    width: '100%',
    minHeight: 90,
    maxHeight: 110,
    marginBottom: '8px',
    display: 'flex',
    backgroundColor: shoptypeThemes.default.WILD_SAND,
    boxShadow: 'none',
    border: '0.25px solid rgba(0, 0, 0, 0.7)',
    borderRadius: '5px'
  },
  w100: {
    width: '100%'
  },
  cancelButton: {
    position: 'absolute',
    right: '-5px',
    top: '-9px'
  },
  media: {
    height: 60,
    minWidth: 80,
    backgroundSize: 'contain',
    marginTop: '15px',
    marginLeft: '5px'
  },
  pos: {
    marginBottom: 12
  },
  cardContent: {
    'padding-bottom': 0,
    width: 'inherit'
  },
  cardTypography: {
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: '75%'
  },
  descTypography: {
    fontSize: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    width: '75%'
  },
  pRel: {
    position: 'relative',
    boxSizing: 'border-box',
    width: 'inherit'
  },
  checkboxContainer: {
    width: '100%'
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none'
  },
  overflowScroll: {
    maxHeight: '600px',
    overflow: 'auto',
    padding: '0px 4px',
    overflowX: 'hidden',
    backgroundColor: shoptypeThemes.default.FLORAL_WHITE,
    boxShadow: 'none'
  },
  overflowEllipses: {
    position: 'relative'
  },
  bannerImageContainer: {
    width: '250px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '2px',
    position: 'relative',
    top: '-18px',
    textAlign: 'center',
    backgroundColor: shoptypeThemes.default.WILD_SAND
  },
  pr8: {
    paddingRight: '8px'
  },
  menuContainer: {
    marginTop: '30px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start'
  },
  inlineBlock: {
    display: 'inline-block'
  },
  boxBorder: {
    border: '1px solid #ccc'
  },
  imgageContentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  viewImage: {
    marginLeft: '60px',
    marginTop: '-10px'
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'center'
  },
  imgDimension: {
    width: '300px',
    height: '200px'
  },
  imgIcon: {
    position: 'absolute',
    right: '10px'
  },
  tabs: {
    position: 'sticky',
    top: -24,
    zIndex: 999,
    marginTop: 20
  },
  tab: {
    color: shoptypeThemes.default.MINE_SHAFT,
    fontSize: shoptypeThemes.default.LARGE,
    textTransform: 'none'
  },
  selectedTab: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    height: '3px'
  },
  headerPadding: {
    paddingBottom: '0px'
  }

});

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#E6C100'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

function CreateCollectionForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mediaFileUploadLimit = { default: 10 };
  const [imageDisplayName, setImageDisplayName] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: 'product',
    status: '',
    preview_image_src: '',
    tags: [],
    collection_ids: [],
    attributes: {
      is_campaign: false,
      is_menu: false,
      campaign_start_date: new Date(),
      campaign_end_date: new Date()
    },
    product_metas: [],
    dynamic: false
  });
  const [isDynamicCollection, setIsDynamicCollection] = useState(false);

  const [modalContent, setModalContent] = useState(null);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let { selectedCollectionForUpdate } = props;
    if (!isEmpty(selectedCollectionForUpdate)) {
      let formDataFromProps = {
        name: selectedCollectionForUpdate.name,
        description: selectedCollectionForUpdate.description,
        type: selectedCollectionForUpdate.type,
        status: selectedCollectionForUpdate.status,
        preview_image_src: selectedCollectionForUpdate.preview_image_src,
        tags: [],
        collection_ids: !isProduct()
          ? selectedCollectionForUpdate.collections.map(ele => ele.id)
          : [],
        attributes: {
          is_campaign: selectedCollectionForUpdate?.attributes?.is_campaign
            ? selectedCollectionForUpdate?.attributes?.is_campaign
            : false,
          is_menu: selectedCollectionForUpdate?.attributes?.is_menu
            ? selectedCollectionForUpdate?.attributes?.is_menu
            : false,
          campaign_start_date: selectedCollectionForUpdate?.attributes
            ?.campaign_start_date
            ? selectedCollectionForUpdate?.attributes?.campaign_start_date
            : new Date(),
          campaign_end_date: selectedCollectionForUpdate?.attributes
            ?.campaign_end_date
            ? selectedCollectionForUpdate?.attributes?.campaign_end_date
            : new Date()
        },
        product_metas: isProduct()
          ? selectedCollectionForUpdate.product_details
          : [],
        dynamic: selectedCollectionForUpdate.dynamic
      };
      setImageDisplayName(selectedCollectionForUpdate.preview_image_src);
      setFormData(formDataFromProps);
      setIsDynamicCollection(
        selectedCollectionForUpdate?.product_query?.tags.length > 0
      );
    }
  }, [props.selectedCollectionForUpdate]);

  const handleFormDataChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const isProduct = () => {
    return formData.type === 'product';
  };
  const getEmptyStateMessage = () => {
    return isProduct() ? 'products' : 'collections';
  };
  const handleFormCheckboxChange = e => {
    if (e.target.name === 'is_menu' || e.target.name === 'is_campaign') {
      let value = e.target.value === 'true' ? true : false;
      if (e.target.name === 'is_menu') {
        // for menu
        setFormData(prevState => {
          return {
            ...prevState,
            attributes: {
              ...prevState.attributes,
              [e.target.name]: value,
              is_campaign: value ? false : prevState.attributes.is_campaign
            }
          };
        });
      } else {
        setFormData(prevState => {
          // for campaign
          return {
            ...prevState,
            attributes: {
              ...prevState.attributes,
              [e.target.name]: value
            }
          };
        });
      }
    } else {
      let value =
        e.target.value === 'product' ? false : formData.attributes.is_menu;
      props.updateSelectedProducts([]);
      setFormData(prevState => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
          attributes: {
            ...prevState.attributes,
            is_menu: value
          }
        };
      });
      if (e.target.value !== 'product') {
        setIsDynamicCollection(false);
        props.updateSelectedTags([]);
      }
    }
  };

  const handleDynamicCollectionChange = e => {
    e.persist();
    let value = e.target.value === 'true' ? true : false;
    setIsDynamicCollection(value);
    props.updateSelectedTags([]);
    setFormData(prevState => {
      return {
        ...prevState,
        [e.target.name] : value,
      };
    });
  };

  const openImageModel = () => {
    setShowModal(true);
  };

  const handleTabChange = (_, newSelectedTabIndex) => {
    setSelectedTabIndex(newSelectedTabIndex);
  };

  function TabPanel(propsParam) {
    const { children, value, index, ...other } = propsParam;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        {...other}
        style={{ height: '100%' }}
      >
        {value === index && (
          <PanelContent style={{ padding: 0 }}>{children}</PanelContent>
        )}
      </div>
    );
  }

  const uploadImageVideoHandler = e => {
    const selectedFile = e.target.files ? e.target.files[0] : '';
    const selectedFileName = e.target.files ? e.target.files[0].name : '';
    const fileSizeInBytes = e.target.files[0].size || '';
    const fileType = e.target.files[0].type || '';

    if (fileSizeInBytes / 1000000 > mediaFileUploadLimit.default) {
      toaster('Please upload files of size below 10 mb');
    } else {
      if (fileType.includes('image')) {
        let uploadMediaRequestBody = new FormData();
        uploadMediaRequestBody.append(selectedFileName, selectedFile);
        uploadMediaRequestBody.append('fileNames', `["${selectedFileName}"]`);

        const token = getAuthToken();
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: token
        };
        dispatch(actions.showSpinnerBackdropAction());

        post(
          `${API_BASE_URL}/command?type=addMedia`,
          uploadMediaRequestBody,
          headers
        ).then(response => {
          const fetchedUploadedMediaUrl = response[Object.keys(response)[0]];
          dispatch(actions.hideSpinnerBackdropAction());

          setImageDisplayName(selectedFileName);
          setFormData(prevState => {
            return {
              ...prevState,
              preview_image_src: fetchedUploadedMediaUrl
            };
          });
          const modalContentData = (
            <Modal close={closeModal} width={500} height={400} model="custom">
              <div className={classes.modalContent}>
                <img
                  className={classes.imgDimension}
                  src={fetchedUploadedMediaUrl}
                  alt="banner-img"
                />
                <SecondaryButton text="Cancel" onClick={closeModal} />
              </div>
            </Modal>
          );
          setModalContent(modalContentData);
        });
      } else {
        toaster('Please upload an image file');
      }
    }
  };

  const onDeleteProduct = product => {
    props.onDeleteProduct(product);
  };

  const onDeleteTag = tag => {
    props.onDeleteTag(tag);
  };

  useEffect(() => {
    props.fetchPayload(formData);
  }, [formData]);

  useEffect(() => {
    props.fetchDynamicCollectionData(isDynamicCollection);
    if (!isDynamicCollection) {
      setSelectedTabIndex(0);
    }
  }, [isDynamicCollection]);

  const handleFromDateChange = date => {
    setFormData(prevState => {
      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          campaign_start_date: date
        }
      };
    });
  };

  const handleToDateChange = date => {
    setFormData(prevState => {
      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          campaign_end_date: date
        }
      };
    });
  };

  const onError = error => {
    if (error !== '') {
      props.onDateError(true);
    } else {
      props.onDateError(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <FieldContainer>
        <TextField
          name="name"
          label="Collection Name"
          InputProps={{ classes: { input: classes.input } }}
          fullWidth
          value={formData.name}
          onChange={handleFormDataChange}
          id="outlined-basic"
          variant="outlined"
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          name="description"
          label="Collection Description"
          InputProps={{
            className: classes.input
          }}
          fullWidth
          value={formData.description}
          onChange={handleFormDataChange}
          id="outlined-multiline-static"
          variant="outlined"
          multiline
          rows={4}
        />
      </FieldContainer>
      <div className={classes.checkboxContainer}>
        <div>
          <SubHeading className={`${classes.pr8} ${classes.inlineBlock}`}>
            What will this collection contain?
          </SubHeading>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={formData.type === 'product'}
                  onChange={handleFormCheckboxChange}
                  name={'type'}
                  value={'product'}
                  disabled={!isEmpty(props.selectedCollectionForUpdate)}
                />
              }
              label={'Products'}
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={formData.type === 'self'}
                  onChange={handleFormCheckboxChange}
                  name={'type'}
                  value={'self'}
                  disabled={!isEmpty(props.selectedCollectionForUpdate)}
                />
              }
              label={'Collections'}
            />
          </CheckboxContainer>
        </div>
        {formData.type !== 'self' && (
          <>
            <SubHeading className={`${classes.pr8} ${classes.inlineBlock}`}>
              Is this a dynamic collection?
            </SubHeading>
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={formData.dynamic === true}
                    onChange={handleDynamicCollectionChange}
                    name={'dynamic'}
                    value={true}
                  />
                }
                label={'Yes'}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={formData.dynamic === false}
                    onChange={handleDynamicCollectionChange}
                    name={'dynamic'}
                    value={false}
                  />
                }
                label={'No'}
              />
            </CheckboxContainer>
          </>
        )}
      </div>
      {!formData.attributes.is_menu && (
        <>
          <SubHeading className={`${classes.pr8} ${classes.inlineBlock}`}>
            Will this collection be a campaign? i.e. applicable between a start
            and end date
          </SubHeading>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={formData.attributes.is_campaign}
                  onChange={handleFormCheckboxChange}
                  name={'is_campaign'}
                  value={true}
                />
              }
              label={'Yes'}
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={!formData.attributes.is_campaign}
                  onChange={handleFormCheckboxChange}
                  name={'is_campaign'}
                  value={false}
                />
              }
              label={'No'}
            />
          </CheckboxContainer>

          <CalendarContainer
            className={!formData.attributes.is_campaign ? classes.disabled : ''}
          >
            {isEmpty(props.selectedCollectionForUpdate) ? (
              <CalendarComponent
                label="Campaign start date"
                className={classes.calendarPicker}
                date={formData.attributes.campaign_start_date}
                disableToolbar
                handleDateChange={handleFromDateChange}
                minDate={new Date()}
              />
            ) : (
              <CalendarComponent
                label="Campaign start date"
                className={classes.calendarPicker}
                date={formData.attributes.campaign_start_date}
                disableToolbar
                handleDateChange={handleFromDateChange}
              />
            )}

            <CalendarComponent
              label="Campaign end date"
              className={classes.calendarPicker}
              minDate={formData.attributes.campaign_start_date}
              date={formData.attributes.campaign_end_date}
              handleDateChange={handleToDateChange}
              onError={onError}
            />
          </CalendarContainer>
        </>
      )}
      <div className={classes.menuContainer}>
        <div style={{ width: 'inherit' }}>
          <SubHeading className={`${classes.pr8} ${classes.inlineBlock}`}>
            Is this a menu?
          </SubHeading>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <CustomCheckbox
                  disabled={formData.type !== 'self'}
                  checked={formData.attributes.is_menu}
                  onChange={handleFormCheckboxChange}
                  name={'is_menu'}
                  value={true}
                />
              }
              label={'Yes'}
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <FormControlLabel
              control={
                <CustomCheckbox
                  disabled={formData.type !== 'self'}
                  checked={!formData.attributes.is_menu}
                  onChange={handleFormCheckboxChange}
                  name={'is_menu'}
                  value={false}
                />
              }
              label={'No'}
            />
          </CheckboxContainer>
        </div>
        {!formData.attributes.is_menu && (
          <FieldContainer className={classes.imgageContentBox}>
            <UploadImageLabel
              htmlFor="media-storage"
              className={classes.bannerImageContainer}
            >
              <UploadImage className={classes.overflowEllipses}>
                Upload Banner Image
                <InsertPhotoOutlinedIcon className={classes.imgIcon} />
              </UploadImage>
            </UploadImageLabel>
            <UploadImageFile
              size="medium-large"
              text="+ Add More"
              type="file"
              accept="image/gif, image/jpeg, image/png"
              id="media-storage"
              onChange={uploadImageVideoHandler}
            />
            {imageDisplayName !== '' && formData.preview_image_src !== '' && (
              <Button
                color="primary"
                className={classes.viewImage}
                onClick={openImageModel}
              >
                View the image
              </Button>
            )}
          </FieldContainer>
        )}
        {showModal && modalContent}
      </div>

      <SubHeading className={classes.headerPadding}>
        <Tabs
          className={classes.tabs}
          value={selectedTabIndex}
          TabIndicatorProps={{ className: classes.selectedTab }}
          onChange={handleTabChange}
        >
          <Tab
            className={classes.tab}
            label={isProduct() ? 'Selected Products' : 'Selected Collections'}
          />
          {isDynamicCollection && (
            <Tab className={classes.tab} label="Selected Tags" />
          )}
        </Tabs>
      </SubHeading>
      <TabPanel value={selectedTabIndex} index={0}>
        <MediumHeading>
          {props.selectedProducts?.length > 0
            ? `(${props.selectedProducts?.length} Selected)`
            : ''}
        </MediumHeading>

        <SelectedProductsContainer>
          <ProductSectionCollections
            className={`${classes.overflowScroll} ${props.selectedProducts?.length === 0 ? classes.boxBorder : ''
              }`}
          >
            <ProductContainer>
              <div className={classes.w100}>
                {!isEmpty(props.selectedProducts) ? (
                  props.selectedProducts.map((product, index) => {
                    return (
                      <div key={product.id} className={classes.pRel}>
                        <IconButton
                          aria-label="add"
                          className={classes.cancelButton}
                          onClick={() => onDeleteProduct(product)}
                        >
                          <DeleteOutlineIcon fontSize="small" color="error" />
                        </IconButton>

                        <Card className={classes.root} key={index}>
                          <img
                            className={classes.media}
                            src={
                              isProduct()
                                ? product?.primaryImageSrc?.imageSrc ||
                                noBannerImage
                                : product.preview_image_src || noBannerImage
                            }
                          />
                          <CardContent className={classes.cardContent}>
                            <Typography
                              className={classes.cardTypography}
                              gutterBottom
                              variant="h6"
                              component="h6"
                            >
                              {isProduct() &&
                                product.variants &&
                                product.variants.length > 0 && (
                                  <Fragment>
                                    Starting {CURRENCY_SYMBOL[product.currency]}{' '}
                                    {product.variants[0].discountedPrice} |
                                  </Fragment>
                                )}
                              {isProduct() ? product.title : product.name}
                            </Typography>

                            <Typography
                              className={classes.descTypography}
                              variant="body1"
                              color="textSecondary"
                              component="p"
                            >
                              {product.description || '-'}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <AlignItemInCenter>
                    No {getEmptyStateMessage()} added
                  </AlignItemInCenter>
                )}
              </div>
            </ProductContainer>
          </ProductSectionCollections>
        </SelectedProductsContainer>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <MediumHeading>
          {props.selectedTags.length > 0
            ? `(${props.selectedTags.length} Selected)`
            : ''}
        </MediumHeading>

        <SelectedProductsContainer>
          <ProductSectionCollections
            className={`${classes.overflowScroll} ${props.selectedTags.length === 0 ? classes.boxBorder : ''
              }`}
          >
            <ProductContainer>
              <div className={classes.w100}>
                {!isEmpty(props.selectedTags) ? (
                  props.selectedTags.map((tag, index) => {
                    return (
                      <div key={index} className={classes.pRel}>
                        <IconButton
                          aria-label="add"
                          className={classes.cancelButton}
                          onClick={() => onDeleteTag(tag)}
                        >
                          <DeleteOutlineIcon fontSize="small" color="error" />
                        </IconButton>

                        <Card className={classes.root} key={index}>
                          <CardContent className={classes.cardContent}>
                            <Typography
                              className={classes.cardTypography}
                              gutterBottom
                              variant="h6"
                              component="h6"
                            >
                              {tag}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <AlignItemInCenter>No Tags added</AlignItemInCenter>
                )}
              </div>
            </ProductContainer>
          </ProductSectionCollections>
        </SelectedProductsContainer>
      </TabPanel>
    </>
  );
}

CreateCollectionForm.propTypes = {};

export default withSnackbar(CreateCollectionForm);
