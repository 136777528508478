import styled from 'styled-components';
import TextInput from '../../atoms/textInputV1';
import { Secondary } from '../../atoms/button';

const Container = styled.div`
  display: flex;
`;

const SubContainer = styled.div`
  padding: 20px;
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  padding: 24px 48px;
  color: ${props => props.theme.MINE_SHAFT};
  width: 100%;
`;

const InputFieldContainer = styled.div`
  display: flex;
  padding: 50px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  max-width: 1230px;
  width: fit-content;
  background-color: ${props => props.theme.WHITE};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const Image = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 50%;
`;

const MainSection = styled.div`
  width: 100%;
`;

const IconsContainer = styled.div`
  padding: 25px;
`;

const ProfileForm = styled.div`
  padding: 25px;
  width: 100%;
  input {
    width: 100%;
    border-bottom: 0;
    font-size: ${props => props.theme.SMALL};
    font-weight: ${props => props.theme.LIGHT_FONT};
  }
`;

const Name = styled(TextInput)`
  input {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

const Occupation = styled(TextInput)``;

const EmailContainer = styled.div`
  display: flex;
`;

const Email = styled(TextInput)`
  input {
    width: 400px;
    background: #e7e7e7 !important;
    border-right: 0;
  }
`;

const Phone = styled(TextInput)`
  input {
    width: 400px;
    background: #e7e7e7 !important;
  }
`;

const HeadAndSubHead = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const GenderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  border: solid 1px ${props => props.theme.PINK_SWAN};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
`;

const UserSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
`;

const Gender = styled.input`
  max-width: 20px;
  margin-right: 10px;
`;

const List = styled.div`
  width: 40px;
  height: 14px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.BLACK};
  position: relative;
  top: -2px;
  margin-left: 10px;
`;

const Seperator = styled.hr`
  border: 1px solid ${props => props.theme.PINK_SWAN};
  :last-child {
    border: unset;
    height: 0;
  }
`;

const SocialInterestContainer = styled.div`
  display: flex;
  max-width: 800px;
  margin-top: 60px;
`;

const SocialMediaLinks = styled.div`
  max-width: 420px;
  margin-right: 40px;
  input {
    max-width: 280px;
  }
  button {
    max-width: 370px;
  }
`;

const Interests = styled.div`
  width: 250px;
  > div {
    min-height: 181px;
    div {
      width: 250px;
    }
  }
`;

const SubHeading = styled.span`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
`;

const NextButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 70px;
`;

const Next = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

export {
  Container,
  ImageContainer,
  InnerContainer,
  SubContainer,
  ProfileForm,
  MainSection,
  InputFieldContainer,
  Image,
  Heading,
  IconsContainer,
  Name,
  Occupation,
  EmailContainer,
  Email,
  Phone,
  GenderContainer,
  UserSelectionContainer,
  Gender,
  List,
  Seperator,
  SocialInterestContainer,
  SocialMediaLinks,
  Interests,
  HeadAndSubHead,
  SubHeading,
  NextButton,
  Next,
  ProfileContainer
};
