import styled from 'styled-components';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const iframeStyles = {
  base: {
    color: 'black',
    fontSize: '16px',
    iconColor: 'black',
    '::placeholder': {
      color: '#B2B2B2'
    }
  },
  invalid: {
    iconColor: 'black',
    color: 'black'
  },
  complete: {
    iconColor: 'black'
  }
};

const cardElementOpts = {
  iconStyle: 'solid',
  style: iframeStyles,
  hidePostalCode: true
};

const Message = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.MEDIUM};
`;

export { CardElementContainer, cardElementOpts, Message };
