import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import TextInput from '../../atoms/textInput';

import { PRODUCT_CATEGORIES } from '../../../constants';

class DropDownSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      textBar: '',
      renderedSuggestionsList: [],
      selectedSuggestionsList: [],
      unselectedSuggestionsList: PRODUCT_CATEGORIES
    };

    this.resultsDropDownRef = React.createRef();
    this.clickOutsideResultsDropDown = this.clickOutsideResultsDropDown.bind(
      this
    );
  }

  componentDidMount() {
    if (!isEmpty(this.props.defaultValue)) this.setDefaultValue();
    document.addEventListener('mousedown', this.clickOutsideResultsDropDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutsideResultsDropDown);
  }

  clickOutsideResultsDropDown(event) {
    if (
      this.resultsDropDownRef &&
      !this.resultsDropDownRef.current.contains(event.target)
    )
      this.setState({ renderedSuggestionsList: [] });
  }

  setDefaultValue = () => {
    let {
      selectedSuggestionsList,
      unselectedSuggestionsList,
      textBar
    } = this.state;

    const defaultValue = this.props.defaultValue.filter(
      value => !isEmpty(value.replace(/ /g, ''))
    );

    selectedSuggestionsList = defaultValue.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    unselectedSuggestionsList = unselectedSuggestionsList.filter(
      suggestion => !selectedSuggestionsList.includes(suggestion)
    );
    textBar = selectedSuggestionsList.join(',') + ',';
    this.setState({
      selectedSuggestionsList,
      unselectedSuggestionsList,
      textBar
    });
  };

  onChange = e => {
    let { searchQuery } = this.state;
    const inputItemsArray = e.target.value.split(',');
    searchQuery = inputItemsArray[inputItemsArray.length - 1];
    searchQuery = searchQuery.replace(/ /g, '');

    const renderedSuggestionsList = !isEmpty(searchQuery)
      ? this.state.unselectedSuggestionsList.filter(suggestion =>
          suggestion.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];

    this.setState(
      {
        [e.target.name]: e.target.value,
        renderedSuggestionsList,
        searchQuery
      },
      () => this.props.fetchValues(this.state.textBar.split(','))
    );
  };

  selectSuggestion = selectedSuggestion => {
    let {
      textBar,
      selectedSuggestionsList,
      unselectedSuggestionsList
    } = this.state;

    selectedSuggestionsList.push(selectedSuggestion);
    unselectedSuggestionsList.splice(
      unselectedSuggestionsList.indexOf(selectedSuggestion),
      1
    );
    //unselectedSuggestionsList = unselectedSuggestionsList.filter((suggestion)=>suggestion!=selectedSuggestion);
    textBar = selectedSuggestionsList.join(',') + ',';
    this.setState(
      {
        textBar,
        selectedSuggestionsList,
        unselectedSuggestionsList,
        renderedSuggestionsList: []
      },
      () => this.props.fetchValues(this.state.textBar.split(','))
    );
  };

  onKeyUp = e => {
    let {
      textBar,
      selectedSuggestionsList,
      unselectedSuggestionsList
    } = this.state;
    if (e.key === ',') {
      let inputItemsArray = textBar.split(',');
      let lastInputItem = inputItemsArray[inputItemsArray.length - 2];
      lastInputItem = lastInputItem.replace(/ /g, '');

      unselectedSuggestionsList = unselectedSuggestionsList.filter(
        suggestion => {
          const suggestionLower = suggestion.toLowerCase();
          const lastInputItemLower = lastInputItem.toLowerCase();

          if (suggestionLower === lastInputItemLower) {
            selectedSuggestionsList.push(suggestion);
            lastInputItem = suggestion;
            inputItemsArray[inputItemsArray.length - 2] = lastInputItem;
            textBar = inputItemsArray.join(',');
            this.setState({ selectedSuggestionsList, textBar });
          }
          return !inputItemsArray.includes(suggestion);
        }
      );
      this.setState({ searchQuery: '', unselectedSuggestionsList }, () => {});
    } else if (e.keyCode === 8 || e.keyCode === 46) {
      selectedSuggestionsList = selectedSuggestionsList.filter(suggestion => {
        if (!textBar.includes(suggestion))
          unselectedSuggestionsList.unshift(suggestion);
        return textBar.includes(suggestion);
      });
      unselectedSuggestionsList = unselectedSuggestionsList.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
      this.setState({ selectedSuggestionsList, unselectedSuggestionsList });
    }
  };

  render() {
    const { textBar, renderedSuggestionsList } = this.state;
    const { placeHolder } = this.props;

    return (
      <Container>
        <TextBar
          size="dropdown-searchbar"
          name="textBar"
          value={textBar}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          autocomplete="off"
          placeHolder={placeHolder}
        />
        <SearchBarBackdrop />
        <ResultsDropDown ref={this.resultsDropDownRef}>
          {renderedSuggestionsList.map((optionValue, i) => {
            return (
              <Option
                key={i}
                onClick={() => this.selectSuggestion(optionValue)}
              >
                {' '}
                {optionValue}{' '}
              </Option>
            );
          })}
        </ResultsDropDown>
      </Container>
    );
  }
}

DropDownSearchBar.defaultProps = {
  defaultValue: [],
  placeHolder: ''
};

DropDownSearchBar.propTypes = {
  fetchValues: PropTypes.func,
  defaultValue: PropTypes.array,
  placeHolder: PropTypes.string
};

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const TextBar = styled(TextInput)``;

const ResultsDropDown = styled.div`
  width: 100%;
  position: absolute;
  z-index: 10;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 5px 5px;
  background-color: ${props => props.theme.SPRING_WOOD};
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  min-height: 40px;
  font-weight: ${props => props.theme.MEDIUM_FONT};
  font-size: ${props => props.theme.LARGE};
  padding: 5px 10px;
  :hover {
    background-color: ${props => props.theme.RONCHI};
  }
  :last-child {
    border-radius: 0px 0px 5px 5px;
  }
`;

const SearchBarBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
`;

export { DropDownSearchBar };
