import React from 'react';
import qs from 'qs';
import {
  Wrapper,
  Container,
  Text,
  IconContainer,
  ResendEmailContainer
} from './styles';
import history from '../../../history';
import { withRouter } from 'react-router-dom';
import Icons from '../../atoms/icons';
import { APP_BASE_URL } from '../../../config/env';

const Message = () => {
  let message = '';
  let resendEmailUrl = `${APP_BASE_URL}/users/resend-verification-email`;
  let messageType = '';

  const getQueryParam = param => {
    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    })[param];
  };

  try {
    messageType = getQueryParam('type');
    if (messageType) {
      switch (messageType) {
        case 'verify_email':
          const email = JSON.parse(localStorage.getItem('_email'));
          if (email) {
            resendEmailUrl = `${APP_BASE_URL}/users/resend-verification-email?email=${email}`;
          }
          message = `A confirmation email has been sent to ${email ||
            'your mail id'}.
        Click on the link in the email to activate your account.
        This link will be active for the next 1 hour.`;
          break;
        case 'email_validation_failed':
          const queryMsg = getQueryParam('queryMsg');
          message = queryMsg || 'Something went wrong!';
          break;
        case 'forgot_password':
          const queryEmail = getQueryParam('queryEmail');
          message = `A reset password link has been sent to ${queryEmail ||
            'your mail id'}.
        Click on the link in the email to reset your password.
        This link will be active for the next 1 hour.`;
          break;
        case 'password_reset_success':
          message =
            'Your password has been reset. Please login again to continue.';
          break;
        default:
          message = 'something went wrong.';
      }
    }
  } catch (err) {
    console.log(err);
  }

  return (
    <Wrapper>
      <Container>
        <IconContainer>
          <Icons
            name="info-icon"
            width={16}
            height={16}
            minWidth={16}
            minHeight={16}
          />
        </IconContainer>
        <div>
          <Text>{message}</Text>
          {messageType === 'verify_email' && (
            <ResendEmailContainer>
              <a href={resendEmailUrl}>Click here</a>
              &nbsp;if you have not received the verification email.
            </ResendEmailContainer>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default withRouter(Message);
