import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  min-width: fit-content;
  height: inherit;
  min-height: inherit;
`;

const Container = styled.div`
  margin: 60px;
  background-color: ${props => props.theme.WHITE};
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
`;

const Pane = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 60px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LogoImage = styled.img`
  height: 65px;
  margin-bottom: 40px;
`;

const LogoContent = styled.div`
  cursor: pointer;
`;

export { Container, LogoContainer, LogoImage, LogoContent, Wrapper, Pane };
