import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from '../../atoms/card';
import Icons from '../../atoms/icons';

const Container = styled(Card)``;

const Header = styled.div`
  display: flex;
  padding: 15px;
  color: ${props => props.theme.BLACK};
  width: 100%;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  text-align: left;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 15px;
`;

const Toggle = styled.div`
  cursor: pointer;
  display: flex;
  align-self: flex-end;
`;

const Collapse = styled.div`
  align-self: unset;
`;

class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  togglePanel = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { open } = this.state;
    return (
      <Container>
        <Header>
          <div>{this.props.title}</div>
          <Toggle onClick={e => this.togglePanel(e)}>
            {open ? (
              <Icons name="collapse" width={20} height={16} />
            ) : (
              <Collapse>
                {' '}
                <Icons name="expand" width={20} height={16} />
              </Collapse>
            )}
          </Toggle>
        </Header>
        {this.state.open ? <Content>{this.props.children}</Content> : null}
      </Container>
    );
  }
}

Collapsible.propTypes = {
  children: PropTypes.array,
  title: PropTypes.string
};

export default Collapsible;
