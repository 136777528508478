import React, { useState } from 'react';
import { Switch, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  shippingContainer: {
    font: 'inherit',
    color: 'inherit'
  },

  refundShippingDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  refundShippingButtonDiv: {
    padding: '0 3rem'
  }
});

const CustomShipping = props => {
  const classes = useStyles();
  const [shippingChecked, setShippingChecked] = useState(false);

  const onShipping = () => {
    if (shippingChecked === false) {
      setShippingChecked(true);
      return;
    }
    setShippingChecked(false);
  };

  return (
    <Typography className={classes.shippingContainer} color="textSecondary">
      <div className={classes.refundShippingDiv}>
        Refund Shipping Fee?
        <div className={classes.refundShippingButtonDiv}>
          <p>
            No
            <Switch
              value={shippingChecked}
              onChange={onShipping}
              color="inherit"
            />
            Yes
          </p>
        </div>
      </div>
      <div className={classes.refundShippingDiv}>
        Enter Shipping Amount
        <TextField
          type="number"
          inputRef={props.customShippingAmount}
          label="Shipping Amount"
          rows={1}
          variant="outlined"
          disabled={!shippingChecked}
          defaultValue={0}
          InputProps={{ inputProps: { min: 0 } }}
        />
      </div>
    </Typography>
  );
};

export default CustomShipping;
