import styled from 'styled-components';
import Card from '../../atoms/card';

const PublishButtonModal = styled(Card)`
  color: ${props => props.theme.BLACK};
  display: flex;
  align-items: left;
  flex-direction: column;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-bottom: 40px;
  text-align: left;
`;

const SubHeading = styled.div`
  text-align: left;
  padding: 8px 0 20px 0;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 60px;
  width: 100%;
  > input {
    height: 40px;
    border-right: 0 !important;
    border: solid 1px ${props => props.theme.BLACK};
  }
`;

const ProductLink = styled.input`
  width: 100%;
  max-width: 400px;
`;

const Container = styled.div`
  padding: 50px;
  ${props => props.theme.TABLET`
    text-align: center;
  `}
`;

const EmbedContainer = styled.div``;

const EmbedLink = styled.div`
  display: flex;
  align-items: center;
  > button {
    height: 40px;
  }
`;

const MobileView = styled.div`
  display: none;
  ${props => props.theme.TABLET`
      display: unset;
    `}
`;

export {
  PublishButtonModal,
  Heading,
  SubHeading,
  IconContainer,
  InputContainer,
  ProductLink,
  Container,
  EmbedContainer,
  EmbedLink,
  MobileView
};
