import styled from 'styled-components';
import Card from '../../atoms/card';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const AlignItemInCenter = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const GridContainer = styled.div`
  display: grid;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  padding-bottom: 12px;
`;

const ProductContainer = styled.div`
  padding: 24px 48px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FieldContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 15px 0px;
`;

const GreyBackgroundContainer = styled.div`
  background-color: ${props => props.theme.WILD_SAND};
  padding: 10px;
  display: inline;
  margin-right: 15px;
`;

const TextAreaConatiner = styled(FieldContainer)`
  height: 100px;
  padding-left: 10px;
`;

const UploadImageLabel = styled.label`
  display: inline-block;
  height: 50px;
  cursor: pointer;
  padding: 12px 0px;
`;

const UploadImageFile = styled.input`
  display: none;
`;

const UploadImage = styled.div`
  font-size: ${props => props.theme.LARGE};
`;

const LeftPanel = styled.div`
  width: 65%;
  padding: 15px 30px 30px 0px;
  float: left;
  border-right: 0.5px solid rgba(0, 0, 0, 0.6);
`;
const RightPanel = styled.div`
  width: 35%;
  float: left;
  padding: 30px 0px 30px 30px;
`;

const CheckboxContainer = styled.div`
  min-width: 240px;
  max-width: 380px;
  display: inline;
`;

const DateContainer = styled.div`
  margin-right: 20px;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const PageHeading = styled.div`
  font-size: ${props => props.theme.XXXX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const SubHeading = styled.div`
  padding: 8px 0 20px 0;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const MediumHeading = styled.span`
  font-size: ${props => props.theme.SMALL};
`;
const SelectedProductsContainer = styled.div`
  padding: 8px 0 20px 0;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  min-height: 400px;
  max-height: 600px;
  width: 100%;
  overflow: hidden;
`;

const FilterHeading = styled.div`
  padding: 8px 0 20px 0;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  display: flex;
  justify-content: space-between;
`;
const DateHeading = styled(SubHeading)`
  padding: 0px;
  display: inline-block;
  margin-top: 10px;
`;

const VendorLevelAnalyticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VendorDataPoint = styled(Card)`
  width: 100%;
  max-width: 200px;
  height: auto;
  padding: 10px 20px;
  margin: 10px;
  color: black;
  background-color: ${props => props.theme.GALLERY};

  & > h4 {
    font-size: ${props => props.theme.XX_LARGE};
    font-weight: ${props => props.theme.MEDIUM_FONT};
    margin: 0;
  }
  & > div {
    font-size: ${props => props.theme.XXX_LARGE};
    font-weight: ${props => props.theme.BOLD_FONT};
    padding: 10px 0px;
  }
`;

const ProductLevelAnalyticsContainer = styled.div`
  margin-top: 50px;
  border-radius: 5px;
  background-color: ${props => props.theme.WHITE};
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
`;

const ProductListItems = styled.div`
  display: block;
  height: 525px;
  overflow-y: scroll;
`;

const ProductDataPointTitle = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr;
  place-items: center;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  min-height: 60px;
  padding-top: 35px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const ProductDataPoints = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  min-height: 105px;
  padding: 12px 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  & > img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: contain;
    background-color: ${props => props.theme.WHITE};
    border: 1px solid ${props => props.theme.GAINSBORO};
  }
  & > div:first-of-type {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: ${props => props.theme.GALLERY};
    transition: all 300ms ease;
  }
`;

const Message = styled.div`
  text-align: center;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
  height: 525px;
  line-height: 525px;
`;

const FilterContainer = styled.div`
  border: 0.25px solid #000000;
  padding: 10px 20px;
  position: relative;
`;

export {
  Container,
  HeadingSection,
  Heading,
  SubHeading,
  VendorLevelAnalyticsContainer,
  VendorDataPoint,
  ProductLevelAnalyticsContainer,
  ProductListItems,
  ProductDataPointTitle,
  ProductDataPoints,
  Message,
  ProductContainer,
  Row,
  PaginationWrapper,
  FieldContainer,
  TextAreaConatiner,
  UploadImageLabel,
  UploadImage,
  UploadImageFile,
  LeftPanel,
  RightPanel,
  CheckboxContainer,
  GreyBackgroundContainer,
  DateHeading,
  DateContainer,
  FilterHeading,
  GridContainer,
  SelectedProductsContainer,
  PageHeading,
  AlignItemInCenter,
  MediumHeading,
  FilterContainer
};
