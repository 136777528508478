import React from 'react';
import { isEmpty } from 'lodash';
import { API_BASE_URL } from '../../../../config/env';
import OrdersTable from './ordersTable';
import { PAGE_ORDERS_COUNT, ORDER_ACTION_VALUE } from './constants';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  putWithResponseObject,
  showApiSuccessToast,
  showApiErrorToast
} from '../../../../utils';
import { PageWrapper, ContentWrapper } from './styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import { withRouter } from 'react-router-dom';
import EmptyState from '../../../atoms/EmptyState';

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orders: [],
      ordersAvailable: false,
      totalOrdersCount: 0,
      currentPage: 0,
      currentOffset: 0,
      fetchingOrders: false
    };
  }

  componentDidMount() {
    this.fetchPageData();
  }

  fetchOrders = async offset => {
    this.props.showSpinnerBackdrop();
    const token = getAuthToken();
    const headers = { authorization: token };

    const res = await getApiResponseObject(
      `${API_BASE_URL}/vendor-returns?offset=${offset}&count=${PAGE_ORDERS_COUNT}`,
      headers
    );
    this.props.hideSpinnerBackdrop();
    return res;
  };

  fetchPageData = async () => {
    const res = await this.fetchOrders(0);

    if (successStatusCodes.includes(res.status)) {
      const orders = res?.data?.data;

      if (!isEmpty(orders)) {
        this.setState({
          orders,
          totalOrdersCount: res?.data?.count || 0,
          ordersAvailable: true,
          isLoading: false
        });
      } else {
        this.setState({
          ordersAvailable: false,
          isLoading: false
        });
      }
    } else {
      this.setState({
        ordersAvailable: false,
        isLoading: false
      });
    }
  };

  updateOrderRequest = async data => {
    const { currentOffset } = this.state;
    const token = getAuthToken();
    const headers = {
      authorization: token,
      'Content-Type': 'application/json'
    };
    const reqBody = {
      status: data.status,
      customShippingAmount: data.customShippingAmount
    };

    if (data.status === ORDER_ACTION_VALUE.decline) {
      reqBody.reason = data.reason;
    }

    this.setState({ fetchingOrders: true });

    const res = await putWithResponseObject(
      `${API_BASE_URL}/vendor-returns/${data.id}`,
      reqBody,
      headers
    );

    if (successStatusCodes.includes(res.status)) {
      showApiSuccessToast(res.data);

      const ordersRes = await this.fetchOrders(currentOffset);

      if (successStatusCodes.includes(ordersRes.status)) {
        const orders = ordersRes?.data?.data;

        if (orders) {
          this.setState({
            orders,
            fetchingOrders: false
          });
        } else {
          this.setState({
            fetchingOrders: false
          });
        }
      } else {
        this.setState({ fetchingOrders: false });
      }
    } else {
      this.setState({ fetchingOrders: false });
      showApiErrorToast(res.data);
    }
  };

  handleChangePage = async (event, newPage) => {
    if (this.state.fetchingOrders) return;
    const { currentPage, currentOffset } = this.state;
    const fetchOffset =
      newPage > currentPage
        ? currentOffset + PAGE_ORDERS_COUNT
        : currentOffset - PAGE_ORDERS_COUNT;

    this.setState({ fetchingOrders: true });

    const res = await this.fetchOrders(fetchOffset);

    if (successStatusCodes.includes(res.status)) {
      const orders = res?.data?.data;

      if (orders) {
        this.setState({
          orders,
          currentPage: newPage,
          currentOffset: fetchOffset,
          fetchingOrders: false
        });
      } else {
        this.setState({ fetchingOrders: false });
        showApiErrorToast(res.data);
      }
    } else {
      this.setState({ fetchingOrders: false });
      showApiErrorToast(res.data);
    }
  };

  render() {
    const {
      isLoading,
      orders,
      totalOrdersCount,
      currentPage,
      fetchingOrders,
      ordersAvailable
    } = this.state;

    return (
      <PageWrapper>
        <ContentWrapper>
          {!isLoading && ordersAvailable && !isEmpty(orders) ? (
            <OrdersTable
              orderDetails={orders}
              totalOrdersCount={totalOrdersCount}
              currentPage={currentPage}
              fetchingOrders={fetchingOrders}
              settlePayment={this.settlePayment}
              handleChangePage={this.handleChangePage}
              updateOrderRequest={this.updateOrderRequest}
            />
          ) : (
            !isLoading &&
            !ordersAvailable && (
              <EmptyState errorMessage="No order details available" />
            )
          )}
        </ContentWrapper>
      </PageWrapper>
    );
  }
}

Requests.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Requests));
