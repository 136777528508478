import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import DefaultProductImage from '../../../assets/images/photo.svg';
import Loader from '../../atoms/loader';
import logo from '../../../assets/images/simple_logo.png';

import {
  Container,
  PreviewContainer,
  Preview,
  Image,
  Title,
  Description,
  BuyNow,
  BuyNowContainer,
  Logo
} from './styles';

const PublishPreview = ({
  fetchedProductDetails,
  isMobilePreview,
  slicedDescription,
  isCurrent
}) => {
  const fetchDescription = () => {
    return get(fetchedProductDetails.description, 'length') < 10
      ? fetchedProductDetails.description
      : slicedDescription;
  };
  return (
    <Container isMobilePreview={isMobilePreview}>
      <Preview
        isCurrent={isCurrent}
        isDark={true}
        isMobilePreview={isMobilePreview}
      >
        {Object.keys(fetchedProductDetails).length > 0 ? (
          <>
            <PreviewContainer>
              <Logo src={logo} alt="logo" />
              <Image
                isMobilePreview={isMobilePreview}
                src={get(
                  fetchedProductDetails,
                  'primaryImageSrc.imageSrc',
                  DefaultProductImage
                )}
              />
            </PreviewContainer>
            <Title isMobilePreview={isMobilePreview}>
              {fetchedProductDetails.title}
            </Title>
            <Description isMobilePreview={isMobilePreview}>
              {fetchDescription()}
            </Description>
            <BuyNowContainer isMobilePreview={isMobilePreview}>
              <BuyNow size="large" text="Product Details" />
            </BuyNowContainer>
          </>
        ) : (
          <Loader
            message="Loading Preview ..."
            isFlex={true}
            w={'100%'}
            isCenter={true}
            min_h={'450px'}
          />
        )}
      </Preview>
    </Container>
  );
};

PublishPreview.propTypes = {
  fetchedProductDetails: PropTypes.array,
  isMobilePreview: PropTypes.bool,
  slicedDescription: PropTypes.string,
  isCurrent: PropTypes.bool
};

export default PublishPreview;
