import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

import { withStyles, makeStyles } from '@material-ui/core/styles';

const CustomButton = withStyles(theme => ({
  root: {
    color: '#303030',
    backgroundColor: '#F0D64E',
    '&:hover': {
      backgroundColor: '#ebc918'
    }
  }
}))(Button);

const useStyles = makeStyles({
  button: {
    textTransform: 'none'
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: '#F0D64E !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

const MaterialButton = ({
  label = 'Label',
  onClick,
  disabled,
  loading,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CustomButton
        className={classes.button}
        variant="contained"
        onClick={onClick}
        disabled={disabled || loading}
        {...props}
      >
        {label}
      </CustomButton>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default MaterialButton;
