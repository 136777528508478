import React, { useEffect, useState } from 'react';

import { ProfileMenuContainer, LocationWidgetContainer } from './styles';
import {
  COSELLER,
  CURRENCY_SYMBOL,
  GLOBAL_EVENT_KEYS,
  NETWORK_OPERATOR,
  VENDOR
} from '../../../constants';
import {
  logout,
  postWithResponseObject,
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  showApiErrorToast,
  getUserType
} from '../../../utils';
import { API_BASE_URL } from '../../../config/env';
import InviteCoseller from '../../templates/InviteCoseller';
import LocationWidget from '../../molecules/LocationWidget';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { toaster } from '../../atoms/toaster';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import UserProfileMenu from '../../organisms/UserProfileMenu';

const ProfileMenu = props => {
  // refs
  const inviteCosellerRef = React.createRef();

  // state
  const [currenciesList, setCurrenciesList] = useState(['USD']);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    userType: '',
    vendorName: '',
    networkOperatorName: ''
  });

  const updateUserDetails = () => {
    const params = new URLSearchParams(document.location.search);
    const userType = params.get('usertype') || getUserType();
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));

    if (userType === VENDOR) {
      setUserDetails(prevState => ({
        ...prevState,
        name: userProfile?.name || '',
        email: userProfile?.email || '',
        userType: userType || '',
        vendorName:
          (userProfile?.vendors?.length > 0 && userProfile.vendors[0].name) ||
          ''
      }));
    } else if (userType === COSELLER) {
      const savedCurrency = localStorage.getItem('selectedCurrency');
      if (!savedCurrency) {
        setCurrency('USD'); // hardcoding USD here as default, if nothing else is selected
      } else {
        setSelectedCurrency(savedCurrency);
      }
      fetchSupportedCurrencies();
      setUserDetails(prevState => ({
        ...prevState,
        name: userProfile?.name || '',
        email: userProfile?.email || '',
        userType: userType || ''
      }));
    } else {
      (async () => {
        const token = getAuthToken();
        const headers = { authorization: token };
        const res = await getApiResponseObject(
          `${API_BASE_URL}/networks`,
          headers
        );

        if (successStatusCodes.includes(res.status)) {
          setUserDetails(prevState => ({
            ...prevState,
            name: userProfile?.name || '',
            email: userProfile?.email || '',
            userType: userType || '',
            networkOperatorName: res.data.network.name || ''
          }));
        } else {
          showApiErrorToast(res.data);
        }
      })();
    }
  };

  // effects
  useEffect(() => {
    updateUserDetails();

    // Register an event listener to update the user details when the user profile data changes
    EventsBaseClient.on(
      GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT,
      updateUserDetails
    );

    // Clean up the event listener when the component unmounts
    return () => {
      EventsBaseClient.removeEventListener(
        GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT,
        updateUserDetails
      );
    };
  }, []);

  const fetchSupportedCurrencies = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const response = await getApiResponseObject(
      `${API_BASE_URL}/currencies`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      if (!Array.isArray(response.data)) {
        return toaster('Unable to fetch currencies');
      }

      setCurrenciesList(response.data);
    }
  };

  const handleInvite = () => {
    inviteCosellerRef.current.inviteCoseller();
  };

  const handleLogout = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const id = JSON.parse(localStorage.getItem('_id'));
    const requestBody = { userId: id };
    const headers = {
      'Content-type': 'application/json',
      Authorization: token
    };
    if (token && id) {
      postWithResponseObject(`${API_BASE_URL}/logout`, requestBody, headers);
    }
    logout();
  };

  const setCurrency = currency => {
    if (currenciesList.some(c => c == currency)) {
      setSelectedCurrency(currency);
      localStorage.setItem('selectedCurrency', currency);
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT, currency);
    }
  };

  return userDetails.userType === VENDOR ? (
    <ProfileMenuContainer>
      <LocationWidgetContainer />
      <InviteCoseller hideButton={true} ref={inviteCosellerRef} />
      <div style={{ margin: '0 8px' }}>
        <UserProfileMenu
          name={userDetails.name}
          userType={VENDOR}
          userName={userDetails.vendorName}
          email={userDetails.email}
          handleLogout={handleLogout}
          handleInvite={handleInvite}
        />
      </div>
    </ProfileMenuContainer>
  ) : userDetails.userType === COSELLER ? (
    <ProfileMenuContainer>
      <LocationWidgetContainer>
        <LocationWidget setCurrencyCallback={setCurrency} />
      </LocationWidgetContainer>
      <div>
        <Autocomplete
          id="currencies-autocomplete"
          options={currenciesList}
          size="small"
          getOptionLabel={option => option}
          style={{ width: 120, margin: '0 8px', backgroundColor: 'white' }}
          value={CURRENCY_SYMBOL[selectedCurrency] + ' ' + selectedCurrency}
          onChange={(event, value) => setCurrency(value)}
          disableClearable
          renderOption={option => (
            <React.Fragment>
              <span>{CURRENCY_SYMBOL[option] + ' ' + option}</span>
            </React.Fragment>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label="Currency"
              variant="outlined"
              value={CURRENCY_SYMBOL[selectedCurrency] + ' ' + selectedCurrency}
            />
          )}
        />
      </div>
      <InviteCoseller
        isCoseller={true}
        hideButton={true}
        ref={inviteCosellerRef}
      />
      <div style={{ margin: '0 8px' }}>
        <UserProfileMenu
          name={userDetails.name}
          userType={COSELLER}
          email={userDetails.email}
          handleLogout={handleLogout}
          handleInvite={handleInvite}
        />
      </div>
    </ProfileMenuContainer>
  ) : (
    <ProfileMenuContainer>
      <InviteCoseller hideButton={true} ref={inviteCosellerRef} />
      <div style={{ margin: '0 8px' }}>
        <UserProfileMenu
          name={userDetails.name}
          userType={NETWORK_OPERATOR}
          userName={userDetails.networkOperatorName}
          email={userDetails.email}
          handleLogout={handleLogout}
          handleInvite={handleInvite}
        />
      </div>
    </ProfileMenuContainer>
  );
};

export default ProfileMenu;
