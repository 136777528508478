import styled from 'styled-components';
import { Primary } from '../../../atoms/button';
import Card from '../../../atoms/card';

const MainContainer = styled.div``;

const ProductSection = styled.div`
  color: ${props => props.theme.MINE_SHAFT};
`;

const HeadAndSubHead = styled.div`
  margin-bottom: 24px;
`;

const CatalogBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const Message = styled.div`
  text-align: center;
  height: 525px;
  line-height: 525px;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
`;

const SearchCount = styled.div`
  width: 100%;
  font-size: ${props => props.theme.SMALL};
`;

const ProductTitle = styled.div`
  min-height: 60px;
  padding-top: 35px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr;
  place-items: center;
`;

const ProductTitleItem = styled.div`
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  text-transform: uppercase;
`;

const ProductListItems = styled.div`
  display: block;
  overflow: hidden;
`;

const ProductItem = styled.div`
  min-height: 105px;
  padding: 12px 0;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 2fr;
  place-items: center;
  transition: all 300ms ease;
  cursor: pointer;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: ${props => props.theme.GALLERY};
    transition: all 300ms ease;
  }
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: contain;
  background-color: ${props => props.theme.WHITE};
  border: 1px solid ${props => props.theme.GAINSBORO};
`;

const Headline = styled.div`
  padding: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const Cost = styled.div`
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const Quantity = styled(Cost)``;

const VendorName = styled(Cost)``;

const QtySold = styled.div`
  font-size: 32px;
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const Views = styled(QtySold)``;

const ViewProduct = styled.div`
  display: flex;
  justify-content: space-between;

  & > button:not(:last-child) {
    margin-right: 20px;
  }
`;

const ViewButton = styled(Primary)`
  border: 1px solid black;
`;

const PublishButtonModal = styled(Card)`
  color: ${props => props.theme.BLACK};
  display: flex;
  align-items: left;
  flex-direction: column;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-bottom: 40px;
  text-align: left;
`;

const SubHeading = styled.div`
  text-align: left;
  padding: 8px 0 20px 0;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 60px;
  width: 100%;
  > input {
    border-right: 0 !important;
    border: solid 1px ${props => props.theme.BLACK};
  }
`;

const ProductLink = styled.input`
  width: 100%;
  max-width: 400px;
`;

const EmbedLink = styled.div`
  background-color: ${props => props.theme.WHITE};
  display: flex;
  align-items: center;
`;

const TopLevelContainer = styled.div`
  width: 100%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
`;

export {
  MainContainer,
  ProductSection,
  HeadAndSubHead,
  CatalogBar,
  InfoBar,
  SearchCount,
  ProductTitle,
  ProductTitleItem,
  ProductListItems,
  ProductItem,
  Message,
  Image,
  Headline,
  Cost,
  Quantity,
  QtySold,
  Views,
  ViewProduct,
  ViewButton,
  PublishButtonModal,
  Heading,
  SubHeading,
  IconContainer,
  InputContainer,
  ProductLink,
  EmbedLink,
  TopLevelContainer,
  VendorName
};
