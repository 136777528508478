import styled from 'styled-components';
import { Filled } from '../../../atoms/button';
import TextInput from '../../../atoms/textInput';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
`;

const Heading = styled.h1`
  width: 100%;
  text-align: center;
  padding: 20px;
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};

  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.XX_LARGE};
    font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  `}
`;

const AuthContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  column-gap: 10px;
  row-gap: 15px;

  ${props => props.theme.TABLET`
    grid-template-columns: 1fr;
  `}
`;

const AuthButton = styled.button`
  width: 150px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid ${p => p.theme.BLACK};

  & > img {
    margin: auto;
  }
`;

const AwakeMeButton = styled(Filled)`
  width: 150px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid ${p => p.theme.BLACK};
`;

const FormContainer = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 12px auto;
  padding: 12px;
`;

const FormFields = styled.div`
  margin: 12px;
  padding: 12px;
`;

const TextInputField = styled(TextInput)`
  input {
    width: 100%;
  }
`;

const FormActions = styled.div`
  margin: 12px 0;
`;

const ActionButton = styled(Filled)`
  width: 150px;
  height: 40px;
  cursor: pointer;
  margin: 8px;
  border: 1px solid ${p => p.theme.BLACK};
`;

const ActionLinkButton = styled.div``;

const ActionLink = styled.a`
  color: ${props => props.theme.HAVELOCK_BLUE};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export {
  Wrapper,
  Container,
  Heading,
  AuthContainer,
  AuthButton,
  AwakeMeButton,
  FormContainer,
  FormFields,
  TextInputField,
  FormActions,
  ActionButton,
  ActionLinkButton,
  ActionLink,
  Col
};
