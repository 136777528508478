import { createAction } from 'redux-actions';
import * as constants from '../constants';

export const addProductRequest = createAction(constants.ADD_PRODUCT_REQUEST);
export const addProductSuccess = createAction(constants.ADD_PRODUCT_SUCCESS);
export const addProductError = createAction(constants.ADD_PRODUCT_ERROR);

export const makePaymentRequest = createAction(constants.MAKE_PAYMENT_REQUEST);
export const makePaymentSuccess = createAction(constants.MAKE_PAYMENT_SUCCESS);
export const makePaymentError = createAction(constants.MAKE_PAYMENT_ERROR);

export const makeSalesOrderRequest = createAction(
  constants.MAKE_SALES_ORDER_REQUEST
);
export const makeSalesOrderSuccess = createAction(
  constants.MAKE_SALES_ORDER_SUCCESS
);
export const makeSalesOrderError = createAction(
  constants.MAKE_SALES_ORDER_ERROR
);

export const setCheckoutFormFields = createAction(
  constants.SET_CHECKOUT_FORM_FIELDS
);

export const setInitalStatusRequest = createAction(
  constants.SET_INITIAL_STATUS_REQUEST
);

export const setProductSourceName = createAction(
  constants.SET_PRODUCT_SOURCENAME
);
export const setProductId = createAction(constants.SET_PRODUCT_ID);
export const setVendorId = createAction(constants.SET_VENDOR_ID);
export const buyProductRequest = createAction(constants.BUY_PRODUCT_REQUEST);
export const addToCart = createAction(constants.ADD_TO_CART);
export const removeFromCart = createAction(constants.REMOVE_FROM_CART);
export const changeProductDataInCart = createAction(
  constants.CHANGE_ITEM_CART_QTY
);
export const clearCartData = createAction(constants.CLEAR_CART_DATA);

export const clearPlacedOrderData = createAction(
  constants.CLEAR_PLACED_ORDER_DATA
);

export const clearBillingFromCheckoutData = createAction(
  constants.CLEAR_BILLING_FROM_FORM
);

export const fetchStatesFromCountryCode = createAction(
  constants.STATE_COUNTRY_RESPONSE
);

export const showSpinnerBackdrop = createAction(
  constants.SHOW_SPINNER_BACKDROP
);
export const hideSpinnerBackdrop = createAction(
  constants.HIDE_SPINNER_BACKDROP
);

export const updateProductShareLink = createAction(
  constants.UPDATE_PRODUCT_SHARE_LINK
);
