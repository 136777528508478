import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import history from '../../../history';
import { COSELLER_ROUTES } from '../../../constants';
import SocialLinks from '../../molecules/socialLinks';
import { toaster } from '../../atoms/toaster';
import {
  Next,
  Container,
  Heading,
  SubHeading,
  VendorContainer,
  NextButton
} from './styles';

class CosellerOnBoardSocialLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socialAccounts: [],
      socialMediaAccounts: [],
      dataIncomplete: false
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem('socialAccounts')) {
      let socialAccounts = JSON.parse(sessionStorage.getItem('socialAccounts'));

      this.setState({ socialAccounts: socialAccounts });
    }
  }

  handleSubmit = () => {
    const { socialMediaAccounts, dataIncomplete } = this.state;

    if (dataIncomplete) {
      return toaster('Please enter valid Media options and Media Links');
    } else {
      const socialAccountCopy = socialMediaAccounts.slice(
        0,
        socialMediaAccounts.length
      );
      let socialAccountPayload = [];
      socialAccountCopy.forEach(account => {
        if (account.socialMediaLink) {
          socialAccountPayload.push(account);
        }
      });
      sessionStorage.setItem(
        'socialAccounts',
        JSON.stringify(socialAccountPayload)
      );
      const socialLoginData = {
        pathname: COSELLER_ROUTES.INTERESTS,
        data: {
          socialMediaAccounts: socialAccountPayload
        }
      };
      history.push(socialLoginData);
    }
  };

  getData = (val, status) => {
    this.setState({ socialMediaAccounts: val, dataIncomplete: status });
  };

  render() {
    const { socialAccounts } = this.state;
    return (
      <Container>
        <Heading>Create a Coseller account</Heading>
        <SubHeading>Add your social media links</SubHeading>
        <VendorContainer>
          <SocialLinks
            sendData={(val, status) => this.getData(val, status)}
            socialAccounts={socialAccounts}
          />
          <NextButton>
            <Next text="Next" onClick={() => this.handleSubmit()} />
          </NextButton>
        </VendorContainer>
      </Container>
    );
  }
}

CosellerOnBoardSocialLogin.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(CosellerOnBoardSocialLogin);
