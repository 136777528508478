import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { API_BASE_URL } from '../../../config/env';
import { post, getAuthToken, cartesian } from '../../../utils';
import { Primary, CloseButton, Dashed } from '../../atoms/button';
import { toaster } from '../../atoms/toaster';
import TextInput from '../../atoms/textInput';
import CheckBox from '../../atoms/checkBox';
import Dropdown from '../../atoms/dropDownInput';
import Loader from '../../atoms/loader';
import Modal from '../../templates/Modal';
import { VARIANT_COMBO_DELIMITER } from '../../../constants';

import MediaContainerModal from './mediaContainerModal';

import {
  SubHeading,
  EditVariantsContainer,
  VariantOptionsContainer,
  VariantOptionsCheckBox,
  VariantOptionsHeading,
  VariantOptionElement,
  VariantOption,
  VariantOptionValue,
  PreviewContainer,
  PreviewHeading,
  VariantTableHeading,
  VariantName,
  VariantMedia,
  VariantImageWrapper,
  VariantPrimaryImage
} from './styles';

const VARIANTOPTIONTYPES = [
  'size',
  'color',
  'material',
  'style',
  'title',
  'storage'
];

class VariantsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      selectedVariantOptionTypes: [VARIANTOPTIONTYPES[0]],
      variantOptionTypeOptionValuesMapping:
        this.props.mode === 'Create'
          ? { [VARIANTOPTIONTYPES[0]]: '' }
          : this.props.variantOptionTypeOptionValuesMapping,
      generatedVariantsObject:
        this.props.mode === 'Create' ? {} : this.props.generatedVariantsObject,
      isLoadingVariantMedia: [],
      mediaContainerModal: false,
      modalContent: null
    };
  }

  getSelectedValue = (dropDownSelectedValue, optionIndex) => {
    let {
      selectedVariantOptionTypes,
      variantOptionTypeOptionValuesMapping
    } = this.state;

    let prevVariantOptionTypeOptionValuesMapping = variantOptionTypeOptionValuesMapping;
    variantOptionTypeOptionValuesMapping = {};
    selectedVariantOptionTypes[optionIndex] = dropDownSelectedValue;

    selectedVariantOptionTypes.forEach(optionType => {
      if (optionType) {
        variantOptionTypeOptionValuesMapping[optionType] =
          prevVariantOptionTypeOptionValuesMapping[optionType] || '';
      }
    });

    this.setState({
      selectedVariantOptionTypes,
      variantOptionTypeOptionValuesMapping
    });
  };

  addVariantOption = () => {
    let {
      selectedVariantOptionTypes,
      variantOptionTypeOptionValuesMapping
    } = this.state;
    selectedVariantOptionTypes.push('');
    selectedVariantOptionTypes.forEach(optionType => {
      variantOptionTypeOptionValuesMapping[optionType] =
        variantOptionTypeOptionValuesMapping[optionType] || '';
    });

    this.setState({
      selectedVariantOptionTypes,
      variantOptionTypeOptionValuesMapping
    });
  };

  updateVariantOptionContentHandler = (e, optionType) => {
    let { variantOptionTypeOptionValuesMapping } = this.state;

    variantOptionTypeOptionValuesMapping[optionType] = e.target.value;

    this.setState({ variantOptionTypeOptionValuesMapping });
  };

  handleKeyUp = (e, focusedOptionType) => {
    if (e.key === ',' || e.key === 'Enter') {
      let {
        variantOptionTypeOptionValuesMapping,
        generatedVariantsObject,
        isLoadingVariantMedia
      } = this.state;

      let cartesianInput = [],
        cartesianResult = [];
      Object.keys(variantOptionTypeOptionValuesMapping).forEach(optionType => {
        if (
          variantOptionTypeOptionValuesMapping[optionType].replace(' ', '') ===
          ''
        ) {
          generatedVariantsObject = {};
        } else {
          let optionTypeValues = variantOptionTypeOptionValuesMapping[
            optionType
          ].split(',');

          if (e.key === ',') {
            if (optionType === focusedOptionType) optionTypeValues.pop();
          }

          optionTypeValues = optionTypeValues.filter(optionTypeValue => {
            return !isEmpty(optionTypeValue);
          });

          cartesianInput.push(optionTypeValues);
        }
      });

      if (cartesianInput.length === 1) {
        cartesianResult = cartesianInput[0];
        cartesianResult.forEach(element => {
          if (!generatedVariantsObject[element]) {
            generatedVariantsObject[element] = {
              id: uuidv4(),
              imageIds: []
            };
          }
        });
      } else if (cartesianInput.length > 1) {
        cartesianResult = cartesian(...cartesianInput);
        generatedVariantsObject = {};
        cartesianResult.forEach(combinationElement => {
          if (Array.isArray(combinationElement)) {
            generatedVariantsObject[
              combinationElement.join(VARIANT_COMBO_DELIMITER)
            ] = {
              id: uuidv4(),
              imageIds: []
            };
          }
        });
      }

      Object.keys(generatedVariantsObject).forEach((variantName, index) => {
        isLoadingVariantMedia[index] = false;
      });

      this.setState({ generatedVariantsObject, isLoadingVariantMedia }, () => {
        const {
          generatedVariantsObject,
          selectedVariantOptionTypes
        } = this.state;
        const { storeVariantsToVariantStorage } = this.props;

        storeVariantsToVariantStorage(
          generatedVariantsObject,
          selectedVariantOptionTypes,
          variantOptionTypeOptionValuesMapping
        );
      });
    }
  };

  updateVariantDetailsHandler = e => {
    let { generatedVariantsObject } = this.state;

    let inputVariantName = e.target.id.replace(`-${e.target.name}`, '');

    generatedVariantsObject[inputVariantName][e.target.name] = e.target.value;

    this.setState({ generatedVariantsObject }, () => {
      const {
        generatedVariantsObject,
        selectedVariantOptionTypes,
        variantOptionTypeOptionValuesMapping
      } = this.state;
      const { storeVariantsToVariantStorage } = this.props;

      storeVariantsToVariantStorage(
        generatedVariantsObject,
        selectedVariantOptionTypes,
        variantOptionTypeOptionValuesMapping
      );
    });
  };

  openMediaContainerModal = variantName => () => {
    const { mediaStorage } = this.props;
    const modalContent = (
      <Modal close={this.closeMediaContainerModal}>
        <MediaContainerModal
          mediaStorage={mediaStorage}
          pushNewMediaElementToMediaStorage={
            this.pushNewMediaElementToMediaStorage
          }
          variantName={variantName}
          setImageToVariant={this.setImageToVariant}
          uploadVariantMedia={this.uploadVariantMedia}
          openMediaContainerModal={this.openMediaContainerModal}
          closeMediaContainerModal={this.closeMediaContainerModal}
        />
      </Modal>
    );

    this.setState({ modalContent, mediaContainerModal: true });
  };

  closeMediaContainerModal = () => {
    this.setState({
      mediaContainerModal: false,
      modalContent: null
    });
  };

  uploadVariantMedia = e => {
    const selectedFile = e.target.files ? e.target.files[0] : '';
    const selectedFileName = e.target.files ? e.target.files[0].name : '';
    const fileType = e.target.files[0].type || '';
    const fileId = e.target.id || '';

    let { isLoadingVariantMedia } = this.state;

    this.setState(
      {
        isLoadingVariantMedia: isLoadingVariantMedia
      },
      () => {
        toaster('Media Uploading...');
        var uploadMediaRequestBody = new FormData();
        uploadMediaRequestBody.append(selectedFileName, selectedFile);
        uploadMediaRequestBody.append('fileNames', `["${selectedFileName}"]`);
        const token = getAuthToken();
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: token
        };

        post(
          `${API_BASE_URL}/command?type=addMedia`,
          uploadMediaRequestBody,
          headers
        ).then(response => {
          const result = response[Object.keys(response)[0]];
          let { isLoadingVariantMedia } = this.state;
          this.setState(
            {
              fetchedUploadedMediaUrl: result,
              isLoadingVariantMedia: isLoadingVariantMedia
            },
            () => {
              let {
                fetchedUploadedMediaUrl,
                generatedVariantsObject
              } = this.state;
              const { pushNewMediaElementToMediaStorage } = this.props;

              let variantName = fileId.replace('variant-', '');

              let uploadedMediaId = Date.now().toString();

              pushNewMediaElementToMediaStorage(
                fetchedUploadedMediaUrl,
                variantName,
                fileType,
                'variant',
                uploadedMediaId
              );
              generatedVariantsObject[variantName].imageIds = [uploadedMediaId];
              this.closeMediaContainerModal();
            }
          );
        });
      }
    );
  };

  setImageToVariant = (image, variantName) => {
    let { generatedVariantsObject } = this.state;
    generatedVariantsObject[variantName].imageIds[0] = image[0];
    this.setState({ generatedVariantsObject });
  };

  deleteVariantImage = variantName => {
    let { generatedVariantsObject } = this.state;
    generatedVariantsObject[variantName].imageIds = [];
    this.setState({ generatedVariantsObject });
  };

  render() {
    const {
      variantOptionTypeOptionValuesMapping,
      generatedVariantsObject,
      isLoadingVariantMedia,
      mediaContainerModal,
      modalContent
    } = this.state;

    const { mediaStorage, productSource, variantOptionStatus } = this.props;
    return (
      <EditVariantsContainer>
        <SubHeading>Add Variants</SubHeading>
        <VariantOptionsCheckBox>
          <CheckBox
            label="This product has multiple options, like different sizes or colors"
            onChange={e => {
              if (productSource === 'SHOPTYPE') {
                this.props.toggleVariantsOptionStatus(e);
              }
            }}
            value={variantOptionStatus}
          />
        </VariantOptionsCheckBox>

        {variantOptionStatus ? (
          <VariantOptionsContainer>
            <VariantOptionsHeading>Options</VariantOptionsHeading>

            {Object.keys(variantOptionTypeOptionValuesMapping).map(
              (optionType, index) => {
                return (
                  <VariantOptionElement key={index}>
                    <VariantOption>
                      <Dropdown
                        isSelectedBold
                        selected={optionType}
                        name={`variantOptionName_${index}`}
                        options={VARIANTOPTIONTYPES}
                        optionIndex={index}
                        getSelectedValue={this.getSelectedValue}
                        isDisabled={productSource !== 'SHOPTYPE'}
                      />
                    </VariantOption>
                    <VariantOptionValue>
                      <TextInput
                        name={`variantOptionValue_${index}`}
                        size="edit-detail-size"
                        value={variantOptionTypeOptionValuesMapping[optionType]}
                        placeHolder="Type in your variant option and press Enter"
                        onChange={event => {
                          if (productSource === 'SHOPTYPE') {
                            this.updateVariantOptionContentHandler(
                              event,
                              optionType
                            );
                          }
                        }}
                        onKeyUp={event => {
                          if (productSource === 'SHOPTYPE') {
                            this.handleKeyUp(event, optionType);
                          }
                        }}
                      />
                    </VariantOptionValue>
                  </VariantOptionElement>
                );
              }
            )}
            <Primary
              text="+ Add another option"
              size="custom"
              width="260px"
              height="50px"
              onClick={() => {
                if (productSource === 'SHOPTYPE') {
                  this.addVariantOption();
                }
              }}
            />
            <PreviewContainer>
              <PreviewHeading>Preview</PreviewHeading>
              <table>
                <thead>
                  <tr>
                    <th>
                      <VariantTableHeading>VARIANT</VariantTableHeading>
                    </th>
                    <th>
                      <VariantTableHeading>AMOUNT</VariantTableHeading>
                    </th>
                    <th>
                      <VariantTableHeading>DISCOUNT AMOUNT</VariantTableHeading>
                    </th>
                    <th>
                      <VariantTableHeading>SKU</VariantTableHeading>
                    </th>
                    <th>
                      <VariantTableHeading>QUANTITY</VariantTableHeading>
                    </th>
                    <th>
                      <VariantTableHeading>VARIANT IMAGE</VariantTableHeading>
                    </th>
                  </tr>
                </thead>

                {!isEmpty(generatedVariantsObject)
                  ? Object.keys(generatedVariantsObject).map(
                      (variantName, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>
                                <VariantName>{variantName}</VariantName>
                              </td>
                              <td>
                                <TextInput
                                  value={
                                    generatedVariantsObject[variantName].price
                                  }
                                  placeHolder="Price"
                                  inputContainerSize="variant-details"
                                  size="variant-details"
                                  name="price"
                                  id={`${variantName}-price`}
                                  onChange={e => {
                                    if (productSource === 'SHOPTYPE') {
                                      this.updateVariantDetailsHandler(e);
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <TextInput
                                  value={
                                    generatedVariantsObject[variantName]
                                      .discountedPrice
                                  }
                                  placeHolder="Discounted Price"
                                  inputContainerSize="variant-details"
                                  size="variant-details"
                                  name="discountedPrice"
                                  id={`${variantName}-discountedPrice`}
                                  onChange={e => {
                                    if (productSource === 'SHOPTYPE') {
                                      this.updateVariantDetailsHandler(e);
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <TextInput
                                  value={
                                    generatedVariantsObject[variantName].sku
                                  }
                                  placeHolder="SKU"
                                  inputContainerSize="variant-details"
                                  size="variant-details"
                                  name="sku"
                                  id={`${variantName}-sku`}
                                  onChange={e => {
                                    if (productSource === 'SHOPTYPE') {
                                      this.updateVariantDetailsHandler(e);
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <TextInput
                                  value={
                                    generatedVariantsObject[variantName]
                                      .quantity
                                  }
                                  placeHolder="Quantity"
                                  inputContainerSize="variant-details"
                                  size="variant-details"
                                  name="quantity"
                                  id={`${variantName}-quantity`}
                                  onChange={e => {
                                    if (productSource === 'SHOPTYPE') {
                                      this.updateVariantDetailsHandler(e);
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                {!isLoadingVariantMedia[index] ? (
                                  <VariantMedia>
                                    {!isEmpty(
                                      mediaStorage.images[
                                        generatedVariantsObject[variantName]
                                          .imageIds[0]
                                      ]
                                    ) ? (
                                      <VariantImageWrapper>
                                        <CloseButton
                                          width="20px"
                                          height="20px"
                                          size="custom"
                                          textColor="white"
                                          backgroundColor="black"
                                          onClick={() => {
                                            this.deleteVariantImage(
                                              variantName
                                            );
                                          }}
                                        />
                                        <VariantPrimaryImage
                                          src={
                                            mediaStorage.images[
                                              generatedVariantsObject[
                                                variantName
                                              ].imageIds[0]
                                            ].mediaSrc
                                          }
                                          onClick={this.openMediaContainerModal(
                                            variantName
                                          )}
                                        />
                                      </VariantImageWrapper>
                                    ) : (
                                      <Dashed
                                        text="Upload Media"
                                        size="media-container-modal-open"
                                        onClick={this.openMediaContainerModal(
                                          variantName
                                        )}
                                      />
                                    )}
                                  </VariantMedia>
                                ) : (
                                  <Loader
                                    message="Loading Media.."
                                    isFlex={true}
                                    w={'100%'}
                                    isCenter={true}
                                    h={'100%'}
                                  />
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      }
                    )
                  : null}
              </table>
            </PreviewContainer>
          </VariantOptionsContainer>
        ) : null}
        {mediaContainerModal ? <>{modalContent}</> : null}
      </EditVariantsContainer>
    );
  }
}

VariantsContainer.propTypes = {
  match: PropTypes.object,
  mode: PropTypes.string,
  productSource: PropTypes.string,
  variantOptionStatus: PropTypes.bool,
  variantOptionTypeOptionValuesMapping: PropTypes.object,
  generatedVariantsObject: PropTypes.object,
  storeVariantsToVariantStorage: PropTypes.func,
  pushNewMediaElementToMediaStorage: PropTypes.func,
  toggleVariantsOptionStatus: PropTypes.func,
  mediaStorage: PropTypes.object,
  deleteMedia: PropTypes.func
};

export default withRouter(VariantsContainer);
