import styled from 'styled-components';

const PathContainer = styled.div`
  margin: auto;
  max-width: 600px;
  counter-reset: path-counter;
`;

const PathItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05rem;
  line-height: 1.75;
  color: #000;
  padding: 24px 0;
  text-align: justify;

  &:nth-child(even) {
    flex-direction: row-reverse;
    padding-left: 24px;
    text-align: end;

    &::before {
      content: '';
      position: absolute;
      top: -1.5px;
      right: 7.5%;
      margin-bottom: -4px;
      width: calc(100% - 57.5%);
      height: -webkit-fill-available;
      display: block;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      color: #333;
      border-bottom: 2px dashed;
      border-top: 2px dashed;
    }
  }
  &:nth-child(odd) {
    padding-right: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 0.5px;
      left: 7.5%;
      width: calc(100% - 57.5%);
      height: -webkit-fill-available;
      display: block;
      border-bottom-left-radius: 60px;
      border-top-left-radius: 60px;
      color: #333;
      border-bottom: 2px dashed;
      border-top: 2px dashed;
    }
  }

  &:nth-child(1)::before {
    border-top: unset;
  }

  &:last-child::before {
    border-bottom: unset;
  }
`;

const PathItemNumberContainer = styled.div`
  width: 15%;
`;

const PathItemNumber = styled.div`
  width: 48px;
  height: 48px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
`;

const PathItemContent = styled.div`
  width: 85%;
  padding: 0 10px;
`;

export {
  PathContainer,
  PathItem,
  PathItemNumberContainer,
  PathItemNumber,
  PathItemContent
};
