const VENDOR_INFO = {
  pageDescription: {
    Dashboard: `The Vendor Dashboard gives an overview of the Vendor activity and a visual representation of the analytics related to the same. This enables the Vendor to analyze overall performance and formulate a plan of action to scale up.
    `,
    Products: `The Vendor Products Page allows the Vendor to import all their merchandise from their respective ecommerce stores (we support Shopify & Woocommerce for now) and browse across all the imported products to promote their merchandise to any potential buyer. The link sharing part is exactly similar to that of a Coseller.
    `,
    Attribution: `Understand the journey of each product sale and the cosellers who helped it get there. Set attribution percentages and determine the fee you want to pay out to cosellers for successful product sales.
    `,
    Orders: `The Vendor Orders Page gives the details for the orders that have contributed to the earnings for the Vendor. Also, inside the requests tab, the Vendor gets to see and process all the return requests from the buyers.
    `,
    Networks: `On this page, you can see the connection requests sent by different Networks to you to become part of their network which you can either accept or decline. On accepting a new connection request, the Network will be listed under the "My Connections" section. If you decline a Network's request, they won't be notified about that.
    `,
    Wallet: `Shoptype allows you to accrue your funds directly into your Awake Money wallet. You can use the accrued money for bank transfers or other purchases. These funds are typically approved by the vendors once the purchase is marked successful. Let&apos;s say a buyer places an order with Shoptype for which you are eligible to get a coseller earning. Once the purchase is marked successful, i.e. there are no returns associated to the purchase, the Vendor settles the payment to all parties. These funds reach your Awake Wallet directly.
    `,
    Payouts: `Once you have configured Payouts, you will be able to transfer earnings to Shoptype users in a hassle free way. Shoptype routes the money directly to your merchants using the payouts method you have selected based on your region or currency.
    `
  }
};

const COSELLER_INFO = {
  pageDescription: {
    Dashboard: `The Coseller Dashboard gives an overview of the Coseller activity and a visual representation of the analytics related to the same. This enables the Coseller to analyze overall performance and formulate a plan of action to scale up.
    `,
    Products: `The Coseller Products Page allows the Coseller to browse across all the existing public Vendors to be able to promote their merchandise to any potential buyer, and earn a Coseller share per order. Everytime a Coseller wants to share a product with any potential buyer, they need to get their unique link for that specific product and send it across. We support three modes as of now, share via link, share via social media channels & share via an embed that can be latched on to any webpage directly.
    `,
    Earnings: `Below you can see the order details. Expanding each order will give you a holistic view of each order including the product name, variant & price. It gives you the entire breakup associated with a particular order. The order number is the reference point for any particular ticket you might want to raise with Shoptype. The number of items and the date & time specify details associated to the respective order number.
    
    The Coseller Earnings Page gives the details for the orders that have contributed to the earnings for the Coseller. Note. Some orders might be cancelled/returned/refunded, these orders are also present in the orders list, however the status reflects whether they were successful or not.
    `,
    Purchases: `All the orders that you have placed on any network are listed on this page. This gives you the order details like items in the order, shipping, billing addresses, delivery/return status and options to request for a return before the return window closes.
    `,
    Wallet: `Shoptype allows you to accrue your funds directly into your Awake Money wallet. You can use the accrued money for bank transfers or other purchases. These funds are typically approved by the vendors once the purchase is marked successful. Let&apos;s say a buyer places an order with Shoptype for which you are eligible to get a coseller earning. Once the purchase is marked successful, i.e. there are no returns associated to the purchase, the Vendor settles the payment to all parties. These funds reach your Awake Wallet directly.
    `
  }
};

const NETWORK_OPERATOR_INFO = {
  pageDescription: {
    Dashboard: `The Network Dashboard gives an overview of the Network activity and a visual representation of the analytics related to the same. This enables the Network to analyze overall performance and formulate a plan of action to scale up.
    `,
    Network: `Here you can see a list of Networks who wish to connect with you so that you can sell on their platform. You'll be able to see connection requests from these Networks inside "New Connections" section. If you have accepted any Network's connection request and have formed a connection, you'll be able to manage those connections inside "My Connections" section.
    `,
    Products: `The Network Operator Products Page allows the Network Operator to browse across all the existing connected Vendors to be able to promote their merchandise to any potential buyer, and earn a platform fee per order. The link sharing part is exactly similar to that of a Coseller.
    `,
    Vendors: `This is a place where you can search and add Vendors to your Network. Inside "Discover Vendors" section, we enable you to search for the Vendors you might want to add to your Network, you can simply browse across your searched Vendors and send them a connect request with just button click! When these Vendors accept your connection request, they become a part of your network and appear inside "Manage Vendors" section.
    `,
    Approvals: `Here you can see the list of new connection requests that you can either accept or decline. On clicking "Accept", you will be taken to a different page where you need to complete the KYC for that Network. The Accepted Network whose KYC is completed will be listed under the "My Connections" section. If you decline a Network's request, they won't be notified about that. Note that this page, "Approvals is only available to you as you are registered as Shoptype Admin and other Shoptype users can't access this page.
    `,
    Collections: `This is a place where a Network can create menus or mini stores in the form on collections. Any collection can be a campaign (i.e. it can have a start and end date). A collection can be a set of products across all the connected Vendors or it can be a collection of collections. The Network operator can create several collections and furnish them in the form of an item menu as well.
    `,
    Attribution: `Understand the journey of each product sale and the cosellers who helped it get there. Set attribution percentages and determine the fee you want to pay out to cosellers for successful product sales.
    `,
    Orders: `Below you can see the order details. Expanding each order will give you a holistic view of each order including the product name, variant & price. It gives you the entire breakup associated with a particular order. The order number is the reference point for any particular ticket you might want to raise with Shoptype. The number of items and the date & time specify details associated to the respective order number.
    
    The Network Operator Orders Page gives the details for the orders that have contributed to the earnings for the Network Operator. Note. Some orders might be cancelled/returned/refunded, these orders are also present in the orders list, however the status reflects whether they were successful or not.
    `,
    Payments: `The Payments Page allows you to configure your bank account for receiving payments. For all your Vendors, this would be the account where all the revenue shall be accrued across all their orders. It's simple, just choose your currency and add your desired payment provider and you're good to go!
    `,
    Payouts: `Once you have configured Payouts, you will be able to transfer earnings to Shoptype users in a hassle free way. Shoptype routes the money directly to your merchants using the payouts method you have selected based on your region or currency.
    `,
    Wallet: `Shoptype allows you to accrue your funds directly into your Awake Money wallet. You can use the accrued money for bank transfers or other purchases. These funds are typically approved by the vendors once the purchase is marked successful. Let&apos;s say a buyer places an order with Shoptype for which you are eligible to get a coseller earning. Once the purchase is marked successful, i.e. there are no returns associated to the purchase, the Vendor settles the payment to all parties. These funds reach your Awake Wallet directly.
    `
  }
};

const ATTRIBUTION = {
  Attribution_Percentage:
    'Attribution Percentage is the part of the order value which the vendor is willing to pay to the cosellers/influencers to make the sale happen. Consider this to be the salesperson’s remuneration. On a product worth $100, if the Vendor is willing to give away $30 to the salesperson or anyone who made the sale happen, then we can say that the attribution percentage is 30%',
  Network_Fee_Division:
    'This is the ratio in which the attribution money is to be broken down among different levels. We support 10+2 levels as of now, the subtotal for these levels should amount to 100%'
};

export { VENDOR_INFO, COSELLER_INFO, NETWORK_OPERATOR_INFO, ATTRIBUTION };
