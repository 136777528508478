import styled, { css } from 'styled-components';
import { Secondary } from '../../atoms/button';

const Container = styled.div`
  padding: 50px 0;
`;

const LoginAs = styled.p`
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  margin-top: 27px;
  margin-left: 10px;
`;

const IconBorder = styled.div`
  min-width: 100px;
  min-height: 100px;
  border-radius: 5px;
  border: solid 1px ${props => props.theme.PINK_SWAN};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.isSelected &&
    css`
      background-color: ${props => props.theme.BLACK};
    `}
`;

const Next = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.3;
      cursor: unset;
    `}
`;

const SubContainer = styled.div`
  display: flex;
  min-width: 500px;
  justify-content: space-evenly;
  margin-top: -10px;
  margin-left: -20px;
`;

const IconContainer = styled.div`
  margin-right: 20px;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;

const RadioButtonSubContainer = styled.div`
  margin-right: 10px;

  input {
    visibility: hidden;
  }
  label {
    cursor: pointer;
  }
  input:checked + label {
  }
`;

const UserSelectionContainer = styled.div`
  margin-top: 28px;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  ${props =>
    props.isSelected &&
    css`
      font-weight: ${props => props.theme.BOLD_FONT};
    `}
`;

export {
  Container,
  IconBorder,
  Next,
  SubContainer,
  LoginAs,
  IconContainer,
  RadioButtonContainer,
  RadioButtonSubContainer,
  UserSelectionContainer,
  Text
};
