import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'qs';

import history from '../../../history';
import { Container, Heading, SubHeading } from './styles';
import * as actions from '../../../redux/actions/checkoutActions';

const OrderConfirmation = ({ data, clearData }) => {
  useEffect(() => {
    sessionStorage.clear();
  });

  const { order_number, customer_email } = data.salesOrderResponse;
  return (
    <Container>
      <Heading>Thank You!</Heading>
      <SubHeading>
        Your order is confirmed with Order ID :{' '}
        {order_number ||
          qs.parse(history.location.search, {
            ignoreQueryPrefix: true
          }).orderId}
      </SubHeading>
      <SubHeading>
        Please check your email sent to your mail id{' '}
        <b>
          {customer_email ||
            qs.parse(history.location.search, {
              ignoreQueryPrefix: true
            }).email}
        </b>{' '}
        for Order Confirmation
      </SubHeading>
    </Container>
  );
};

OrderConfirmation.propTypes = {
  data: PropTypes.object
};

const mapStateToProps = state => {
  return {
    data: state.totalAmount
  };
};
const mapDispatchToProps = dispatch => {
  return {
    clearData: () => dispatch(actions.clearPlacedOrderData())
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation)
);
