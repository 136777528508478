import { handleActions } from 'redux-actions';
import * as constants from '../constants';
import update from 'immutability-helper';

const initalState = {
  isLoading: false,
  isError: false,
  isSuccess: false
};

const addProductRequest = (state, action) =>
  update(state, {
    isLoading: { $set: false },
    isError: { $set: false },
    isSuccess: { $set: true }
  });

export default handleActions(
  {
    [constants.ADD_PRODUCT_REQUEST]: addProductRequest
  },
  initalState
);
