import { handleActions } from 'redux-actions';
import * as constants from '../constants';
import update from 'immutability-helper';

const initalState = {
  productSourceName: '',
  cartData: {},
  productId: '',
  vendorId: ''
};

const setProductSourceName = (state, action) => {
  return update(state, { productSourceName: { $set: action.payload } });
};

const setProductId = (state, action) => {
  return update(state, { productId: { $set: action.payload } });
};

const setVendorId = (state, action) => {
  return update(state, { vendorId: { $set: action.payload } });
};

// this function must be called when the cart is empty
const buyProductRequest = (state, action) => {
  const { product, vendorId } = action.payload;
  return update(state, {
    cartData: { [vendorId]: { $set: [product] } }
  });
};

// this function must be called when there is existing vendorId in the cart and a new product needs
// to be pushed to the same vendorId
const addToCart = (state, action) => {
  const { product, vendorId } = action.payload;
  return update(state, {
    cartData: { [vendorId]: { $push: [product] } }
  });
};

const removeFromCart = (state, action) => {
  const { lineItemIndex, vendorId } = action.payload;
  return update(state, {
    cartData: { [vendorId]: { $splice: [[lineItemIndex, 1]] } }
  });
};

const changeProductDataInCart = (state, action) => {
  const { item, index, vendorId } = action.payload;
  return update(state, {
    cartData: { [vendorId]: { $splice: [[index, 1, item]] } }
  });
};

const clearPlacedOrderDataRequest = (state, action) => {
  return update(state, {
    cartData: { $set: {} }
  });
};

const clearCartData = (state, action) => {
  return update(state, {
    cartData: { $set: {} }
  });
};

export default handleActions(
  {
    [constants.SET_PRODUCT_SOURCENAME]: setProductSourceName,
    [constants.SET_PRODUCT_ID]: setProductId,
    [constants.SET_VENDOR_ID]: setVendorId,
    [constants.BUY_PRODUCT_REQUEST]: buyProductRequest,
    [constants.REMOVE_FROM_CART]: removeFromCart,
    [constants.ADD_TO_CART]: addToCart,
    [constants.CHANGE_ITEM_CART_QTY]: changeProductDataInCart,
    [constants.CLEAR_PLACED_ORDER_DATA]: clearPlacedOrderDataRequest,
    [constants.CLEAR_CART_DATA]: clearCartData
  },
  initalState
);
