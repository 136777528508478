import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownContainer,
  DropdownHero,
  DropdownPlaceholder,
  DropdownIndicator,
  DropdownOptions,
  DropdownPlaceholderOption,
  DropdownOption,
  SelectedOption,
  SelectedMulipleOption,
  SelectedMulipleOptionText,
  SelectedMulipleOptionRemove,
  DropdownSearchInput,
  DropDownIcon
} from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
class DropDown extends Component {
  constructor(props) {
    super(props);
    this.dropDown = createRef();
    const defaultSelectedValues = props.selected;
    let selected = props.isMultiSelect ? [] : null;
    if (defaultSelectedValues) {
      const selectedValuesArray = ['string', 'number'].includes(
        typeof defaultSelectedValues
      )
        ? [defaultSelectedValues]
        : [...defaultSelectedValues].map(x => x.value || x);
      selected = props.options[props.isMultiSelect ? 'filter' : 'find'](item =>
        selectedValuesArray.includes(item.value || item)
      );
    }

    this.state = {
      isOpen: false,
      selected,
      ...(props.isSearchable && { inputText: '', isSearchActive: false })
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutsideDropDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener(
      'click',
      this.handleClickOutsideDropDown,
      false
    );
  }

  componentDidUpdate({ selected: prevSelected }) {
    const { selected, isSearchable, options } = this.props;

    if (
      prevSelected !== this.props.selected &&
      options &&
      options.includes(this.props.selected)
    ) {
      this.setState({ selected });
    }

    if (!prevSelected && selected && isSearchable) {
      this.setState({
        selected: ['number', 'string'].includes(typeof selected)
          ? selected
          : prevSelected
      });
    }

    // * INFO: below IF block to force reset state depending on default selected value
    if (
      (prevSelected || prevSelected === 0) &&
      ['', null, undefined].includes(selected)
    ) {
      this.setState({ selected });
    }
  }

  handleClickOutsideDropDown = ({ target }) => {
    const dropDown = this.dropDown.current;
    const { isOpen } = this.state;

    if (isOpen && !dropDown.contains(target)) this.toggleDropDown();
  };

  toggleDropDown = () => {
    const { isSearchable } = this.props;

    this.setState(({ isOpen: wasOpen }) => {
      const isOpen = !wasOpen;

      return {
        isOpen,
        ...(isSearchable && {
          isSearchActive: isOpen,
          ...(!isOpen && { inputText: '' })
        })
      };
    });
  };

  onOptionSelect = selectedOption => event => {
    const { isMultiSelect, onOptionSelect } = this.props;

    this.toggleDropDown();
    this.setState(({ selected: prevSelected }) => ({
      selected: isMultiSelect
        ? [...prevSelected, selectedOption]
        : selectedOption
    }));

    if (this.props.getSelectedValue)
      this.props.getSelectedValue(selectedOption, this.props.optionIndex);

    if (onOptionSelect) onOptionSelect(event, selectedOption);
  };

  removeSelectedValue = option => () => {
    this.setState(({ selected: prevSelected }) => ({
      selected: prevSelected.filter(
        item => (item.value || item) !== (option.value || option)
      )
    }));
  };

  renderHero = () => {
    const { isOpen, selected, isSearchActive } = this.state;
    const {
      isSearchable,
      isMultiSelect,
      placeholder,
      backgroundColor,
      isDisabled,
      hideIndicator,
      heroStyleClass = ''
    } = this.props;

    const hasSelectedValue = isMultiSelect ? selected.length > 0 : selected;

    return (
      <DropdownHero
        role="presentation"
        style={{ backgroundColor }}
        hasSelectedValue={hasSelectedValue || false}
        className={heroStyleClass}
        {...(!isDisabled && {
          onClick: this.toggleDropDown
        })}
      >
        {placeholder && (
          <DropdownPlaceholder
            style={{ backgroundColor }}
            isOpenOrHasSelectedValue={isOpen || hasSelectedValue || false}
          >
            {placeholder}
          </DropdownPlaceholder>
        )}
        {hasSelectedValue && this.renderSelectedValues()}
        {isSearchable && isSearchActive && this.renderSearchInput()}

        {!hideIndicator && <DropdownIndicator isOpen={isOpen} />}
      </DropdownHero>
    );
  };

  renderOptions = () => {
    const { options, isSearchable } = this.props;

    if (isSearchable && !options.length) return null;

    let filteredOptions = options;

    if (isSearchable) {
      const { inputText } = this.state;

      filteredOptions = !inputText
        ? options
        : options.filter(item =>
            (item.label || item).toLowerCase().includes(inputText.toLowerCase())
          );

      if (!filteredOptions.length) {
        return (
          <DropdownOptions>
            <DropdownPlaceholderOption>No Options</DropdownPlaceholderOption>
          </DropdownOptions>
        );
      }
    }

    // * INFO: to get filtered options based on selected value uncomment below block
    // const { selected } = this.state;

    // if (selected) {
    //   if (isMultiSelect && selected.length > 0) {
    //     filteredOptions = filteredOptions.filter(x => !selected.includes(x));
    //   } else {
    //     filteredOptions = filteredOptions.filter(
    //       option => (option.value || option) !== (selected.value || selected)
    //     );
    //   }
    // }

    return (
      <DropdownOptions
        isFilteredOptionsLengthExceeds7={filteredOptions.length > 7}
      >
        {filteredOptions.map(item => (
          <DropdownOption
            key={item.value || item}
            role="presentation"
            onClick={this.onOptionSelect(item)}
          >
            {item.label || item}
          </DropdownOption>
        ))}
      </DropdownOptions>
    );
  };

  onSearchInputChange = event => {
    this.setState({ inputText: event.target.value });
    const { onSearchInputChange } = this.props;
    if (onSearchInputChange) onSearchInputChange(event);
  };

  renderSearchInput = () => {
    const { inputText } = this.state;

    return (
      <DropdownSearchInput
        autoFocus
        value={inputText || ''}
        onChange={this.onSearchInputChange}
      />
    );
  };

  renderSelectedValues = () => {
    const { selected } = this.state;
    const { isMultiSelect, isSelectedBold } = this.props;

    if (
      isMultiSelect &&
      !['string', 'number'].includes(typeof selected) &&
      selected.length
    ) {
      return selected.map(item => (
        <SelectedMulipleOption
          role="presentation"
          key={item.value || item}
          onClick={this.removeSelectedValue(item)}
        >
          <SelectedMulipleOptionText>
            {item.label || item}
          </SelectedMulipleOptionText>
          <SelectedMulipleOptionRemove>&#215;</SelectedMulipleOptionRemove>
        </SelectedMulipleOption>
      ));
    }

    return (
      <SelectedOption isSelectedBold={isSelectedBold}>
        {(selected || {}).label || selected}
      </SelectedOption>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { containerStyleClass = [], showArrowIcon } = this.props;

    return (
      <DropdownContainer ref={this.dropDown} className={containerStyleClass}>
        {showArrowIcon && (
          <DropDownIcon>
            <KeyboardArrowDownIcon />
          </DropDownIcon>
        )}
        {this.renderHero()}
        {isOpen && this.renderOptions()}
      </DropdownContainer>
    );
  }
}

DropDown.propTypes = {
  isSearchable: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  isSelectedBold: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  onOptionSelect: PropTypes.func,
  onSearchInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  backgroundColor: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number
  ]),
  heroStyleClass: PropTypes.string,
  containerStyleClass: PropTypes.array,
  returnSelected: PropTypes.func,
  showArrowIcon: PropTypes.bool
};

DropDown.defaultProps = {
  isSearchable: false,
  isMultiSelect: false,
  isDisabled: false,
  isSelectedBold: false,
  options: [],
  onOptionSelect: null,
  onSearchInputChange: null,
  placeholder: '',
  backgroundColor: '#FFFFFF',
  selected: null,
  hideIndicator: false,
  heroStyleClass: '',
  containerStyleClass: [],
  returnSelected: null,
  showArrowIcon: false
};

export default DropDown;
