import React from 'react';
import PropTypes from 'prop-types';
import { DottedOutlineButton } from './styles';
import { Link } from 'react-router-dom';

const DottedOutline = ({ link, text, onClick, width, height }) => {
  return (
    <div>
      <Link to={link}>
        <div>
          <DottedOutlineButton
            text={text}
            onClick={onClick}
            width={width}
            height={height}
          />
        </div>
      </Link>
    </div>
  );
};

DottedOutline.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func
};

export default DottedOutline;
