import styled, { css } from 'styled-components';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: ${props =>
    props.navBarLogo ? 'space-between' : 'flex-end'};
  padding: 20px 50px 20px 50px;
  height: 85px;
`;

const Logo = styled.img`
  max-width: 130px;
  cursor: ${props => (props.allowClick ? 'pointer' : 'auto')};
`;

const Search = styled.img`
  height: 31px;
  width: 29px;
  border-radius: 100%;
  margin: 0px 0px 0px 70%;
`;

const getSize = props =>
  ({
    small: css`
      padding: 20px;
    `
  }[props.size] ||
  css`
    width: 100%;
    padding: 30px 30px 30px 90px;
  `);

export { NavbarContainer, Logo, Search };
