import styled, { css } from 'styled-components';
import { Secondary } from '../../atoms/button';
import TextInput from '../../atoms/textInputV1';

const SignUpForm = styled.form`
  > div {
    border-bottom: unset;
    input {
      width: 320px;
      height: 60px;
      border: solid 1px ${props => props.theme.PINK_SWAN};
      text-align: left;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const SignUp = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const FirstName = styled(TextInput)`
  input {
    width: 160px !important;
    border-top-left-radius: 5px;
    :first-child {
      border-bottom: 0;
      border-right: none;
    }
  }
`;

const LastName = styled(TextInput)`
  input {
    width: 160px !important;
    border-top-right-radius: 5px;
    :first-child {
      border-bottom: 0;
    }
  }
`;

const PhoneNumber = styled(TextInput)`
  input {
    :first-child {
      border-bottom: 0;
    }
  }
`;

const Email = styled(TextInput)`
  input {
    :first-child {
      border-bottom: 0;
    }
  }
`;

const Password = styled(TextInput)`
  input {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const Next = styled(Secondary)`
  margin-right: 20px;
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
  ${props =>
    props.isSignUp &&
    css`
      background-color: ${props => props.theme.WHITE};
      border: 1px solid ${props => props.theme.BLACK};
    `}
`;

const AuthButton = styled.button`
  border: 0;
  max-width: 40px;
  max-height: 40px;
  cursor: pointer;
  outline: none;
  background-color: ${props => props.theme.WHITE};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 500px;
  min-height: 200px;
`;

const Separator = styled.hr`
  width: 1px solid ${props => props.theme.PINK_SWAN};
  height: 100%;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const SignUpButton = styled.div`
  margin: 20px 4px 0 4px;
`;

const Vendor = styled.div`
  width: 111px;
  height: 30px;
  border-radius: 5px;
  background-color: ${props => props.theme.BLACK};
  color: ${props => props.theme.RONCHI};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -185px;
`;

const Coseller = styled(Vendor)``;

const LoginContainer = styled.div`
  display: flex;
`;

const SeperatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OR = styled.div`
  position: relative;
  left: -16px;
  width: 30px;
  height: 30px;
  background-color: ${props => props.theme.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  SignUpForm,
  SignUp,
  PhoneNumber,
  Email,
  Next,
  AuthButton,
  Container,
  Separator,
  AuthContainer,
  SignUpButton,
  Vendor,
  Coseller,
  LoginContainer,
  SeperatorContainer,
  OR,
  Password,
  Row,
  FirstName,
  LastName
};
