import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  showApiErrorToast
} from '../../../utils';
import { API_BASE_URL } from '../../../config/env';
import { toaster } from '../../atoms/toaster';
import Loader from '../../atoms/loader';

const Container = styled.div`
  position: absolute;
  background-color: #fff;
  min-width: 230px;
  width: fit-content;
  min-height: 80px;
  height: fit-content;
  padding: 5px;
  margin-top: 5px;
  overflow: hidden;
  right: 0px;
  top: 60px;
  border: 1px dotted #ccc;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const InfoBlock = styled.div`
  display: flex;
`;

const ContentTitle = styled.div`
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  white-space: nowrap;
`;

const Content = styled(ContentTitle)`
  font-weight: ${props => props.theme.LIGHT_FONT};
  padding-left: 2px;
`;

class SyncHoverInfo extends React.Component {
  state = {
    isLoading: true,
    displayInfo: null
  };

  componentDidMount() {
    this.fetchSyncInfo();
  }

  fetchSyncInfo = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const userDetails = JSON.parse(localStorage.getItem('userProfile')) || {};
    const vendorId = !isEmpty(userDetails.vendors)
      ? userDetails.vendors[0].vendorId
      : '';
    const url = vendorId
      ? `${API_BASE_URL}/sync-status?vendorId=${vendorId}`
      : `${API_BASE_URL}/sync-status`;

    let response = await getApiResponseObject(url, headers);

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      const syncInfo = response.data;
      const convertedDate = new Date(syncInfo.lastSyncedAt);

      const displayInfo = (
        <InnerContainer>
          <InfoBlock>
            <ContentTitle>Last Synced At: </ContentTitle>
            <Content>
              {convertedDate
                ? convertedDate
                    .toString()
                    .replace(' GMT+0530 (India Standard Time)', '')
                : 'Not Available'}
            </Content>
          </InfoBlock>
          <InfoBlock>
            <ContentTitle>Store Name: </ContentTitle>
            <Content>{syncInfo.storeName || 'Not Available'}</Content>
          </InfoBlock>
          <InfoBlock>
            <ContentTitle>Sync Status: </ContentTitle>
            <Content>{syncInfo.status || 'Not Available'}</Content>
          </InfoBlock>
          <InfoBlock>
            <ContentTitle>Product Count: </ContentTitle>
            <Content>{syncInfo.productCount || 'Not Available'}</Content>
          </InfoBlock>
        </InnerContainer>
      );

      const LastSynced = `Last Synced : ${convertedDate
        .toString()
        .replace(' GMT+0530 (India Standard Time)', '')}`;
      const StoreName = `Store Name : ${syncInfo.storeName || 'Not Available'}`;
      const SyncStatus = `Sync Status : ${syncInfo.status || 'Not Available'}`;
      const ProductCount = `Product Count : ${syncInfo.productCount ||
        'Not Available'}`;

      this.setState({ isLoading: false, displayInfo });
      this.props.getSyncedData({
        LastSynced,
        StoreName,
        SyncStatus,
        ProductCount
      });
    }
  };

  render() {
    const { isLoading, displayInfo } = this.state;
    return (
      <Container>
        {!isLoading ? (
          displayInfo
        ) : (
          <Loader
            message="Loading..."
            isFlex={true}
            w={'100%'}
            h={'100%'}
            isCenter={true}
            bgColor="white"
          />
        )}
      </Container>
    );
  }
}

export { SyncHoverInfo };
