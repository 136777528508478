import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  InputContainer,
  Input,
  ErrorSymbol,
  ErrorExclamation,
  MandatoryDot
} from './styles';

class TextInput extends React.Component {
  state = {
    touched: false,
    isFocused: false,
    defaultValueExists: false
  };

  setFocus = () => {
    if (this.props.autoFocus) {
      setTimeout(() => {
        if (this.inputSearch) {
          this.inputSearch.focus();
        }
        return true;
      }, 0);
    } else {
      return false;
    }
  };

  onBlur = () => {
    this.setState({
      touched: true,
      isFocused: false
    });

    if (this.props.onBlur) this.props.onBlur();
  };

  onInputFocus = () => this.setState({ isFocused: true });

  onKeyDown = event => {
    const {
      submitOnEnter,
      isAutoSuggest,
      suggestions = [],
      onSuggestionSelect
    } = this.props;
    if (
      !submitOnEnter &&
      isAutoSuggest &&
      suggestions.length &&
      event.key === 'Enter'
    ) {
      event.preventDefault();
      if (onSuggestionSelect) onSuggestionSelect(event, suggestions[0]);
    }
  };

  render() {
    const {
      name,
      placeHolder,
      value,
      defaultValue,
      onChange,
      type,
      inputType,
      required,
      size,
      inputContainerSize,
      id,
      disabled,
      autocomplete,
      isAutoSuggest,
      containerStyleClass,
      inputStyleClass,
      inputStyle,
      title,
      step,
      min,
      max,
      pattern,
      customPlaceholder,
      previewMode,
      autoFocus,
      isDisabled,
      ...rest
    } = this.props;

    const otherProps = {
      value
    };
    if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
      otherProps.defaultValue = defaultValue;
      delete otherProps.value;
    }

    const { isFocused } = this.state;
    return previewMode === true ? (
      <InputContainer
        className={containerStyleClass}
        inputContainerSize={inputContainerSize}
        {...rest}
        inputContainer
      >
        <Input
          readOnly
          placeholder={customPlaceholder ? '' : placeHolder}
          style={inputStyle}
          disabled={disabled}
          data-testid="textInput"
          id={id}
          name={name}
          onFocus={this.onInputFocus}
          onChange={onChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          inputType={inputType}
          size={size}
          touched={this.state.touched || false}
          filled={value || false}
          className={inputStyleClass}
          type={type}
          required={required}
          autoComplete={autocomplete}
          ref={input => {
            this.inputSearch = input;
          }}
          title={title}
          step={step}
          min={min}
          max={max}
          pattern={pattern}
          autoFocus={autoFocus}
          isDisabled={isDisabled}
          {...otherProps}
        />
        <MandatoryDot />

        <ErrorSymbol>
          <ErrorExclamation>!</ErrorExclamation>
        </ErrorSymbol>

        {isAutoSuggest && isFocused && this.renderSuggestions()}
      </InputContainer>
    ) : (
      <InputContainer
        className={containerStyleClass}
        inputContainerSize={inputContainerSize}
        {...rest}
        inputContainer
      >
        <Input
          placeholder={customPlaceholder ? '' : placeHolder}
          style={inputStyle}
          disabled={disabled}
          data-testid="textInput"
          id={id}
          name={name}
          onFocus={this.onInputFocus}
          onChange={onChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          inputType={inputType}
          size={size}
          touched={this.state.touched || false}
          filled={value || false}
          className={inputStyleClass}
          type={type}
          required={required}
          autoComplete={autocomplete}
          ref={input => {
            this.inputSearch = input;
          }}
          title={title}
          step={step}
          min={min}
          max={max}
          pattern={pattern}
          autoFocus={autoFocus}
          {...otherProps}
        />
        <MandatoryDot />

        <ErrorSymbol>
          <ErrorExclamation>!</ErrorExclamation>
        </ErrorSymbol>

        {isAutoSuggest && isFocused && this.renderSuggestions()}
      </InputContainer>
    );
  }
}

TextInput.defaultProps = {
  type: 'text',
  inputType: 'primary',
  required: false,
  size: 'default-size',
  inputContainerSize: 'default-size',
  id: '',
  isAutoSuggest: false,
  containerStyleClass: [],
  inputStyleClass: '',
  backgroundColor: '#FFFFFF',
  title: '',
  customPlaceholder: false,
  autoFocus: false,
  value: '',
  defaultValue: null
};

TextInput.propTypes = {
  placeHolder: PropTypes.string,
  value: oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  inputType: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  inputContainerSize: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  isAutoSuggest: PropTypes.bool,
  containerStyleClass: PropTypes.array,
  inputStyleClass: PropTypes.string,
  inputStyle: PropTypes.object,
  title: PropTypes.string,
  pattern: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  suggestions: PropTypes.array,
  customPlaceholder: PropTypes.bool,
  autoFocus: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  onSuggestionSelect: PropTypes.func,
  onChange: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default TextInput;
