import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const SocialPlatformContainer = styled.div`
  padding: 24px 48px;
`;

const MainSection = styled.div`
  width: 100%;
`;

const Analytics = () => {
  return (
    <Container>
      <MainSection>
        <SocialPlatformContainer>
          <h1> Coming Soon..</h1>
        </SocialPlatformContainer>
      </MainSection>
    </Container>
  );
};

export default withRouter(Analytics);
