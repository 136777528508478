import styled from 'styled-components';

const CheckBoxContainer = styled.div`
  margin-top: ${props => props.marginTop ?? 0}px;
  height: ${props => (props.height ? props.height + 'px' : '100%')};
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
  color: ${props => props.color};
`;

const CheckBoxInput = styled.input.attrs(props => ({
  id: props.id,
  name: props.name,
  value: props.value,
  onChange: props.onChange,
  type: 'checkbox',
  disabled: props.disabled ?? false
}))`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const CheckMark = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 0;
  border: solid 1px ${props => props.theme.checkBoxUnselectedColor};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.WHITE};

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: -1px;
    width: 10px;
    height: 15px;
    border: solid ${props => props.theme.checkBoxBackground};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const CheckBoxLabel = styled.label.attrs(({ id }) => ({
  id
}))`
  display: block;
  width: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;

  > input:checked ~ span {
    background-color: ${props => props.theme.WHITE};
    border: solid 1px ${props => props.theme.BLACK};
  }

  > input:checked ~ span:after {
    display: block;
  }

  & pre {
    background: ${props => props.theme.codeBackground};
    border-radius: 0;
    margin: 0;
    padding: 1rem 1.25rem;
  }
`;

const LabelText = styled.div`
  position: absolute;
  padding-left: 24px;
  font-family: ${props =>
    props.fontFamily == 'normal' ? 'unset' : 'Montserrat'};
  font-size: ${props => {
    switch (props.fontSize) {
      case 'small':
        return props.theme.SMALL;
        break;
      case 'medium':
        return props.theme.MEDIUM;
        break;
      case 'large':
        return props.theme.SMALL;
        break;
      default:
        return props.theme.X_LARGE;
        break;
    }
  }};
`;

export {
  CheckBoxContainer,
  CheckBoxInput,
  CheckMark,
  CheckBoxLabel,
  LabelText
};
