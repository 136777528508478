import React, { useState, useRef, useEffect } from 'react';
import { number, func, bool } from 'prop-types';

export default function CountdownTimer({ timerDuration, start, onComplete }) {
  const [timer, setTimer] = useState(timerDuration);
  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
  };

  const resetTimer = () => {
    setTimer(timerDuration);
  };

  useEffect(() => {
    return () => clear();
  }, []);

  useEffect(() => {
    if (start) {
      id.current = window.setInterval(() => {
        setTimer(time => time - 1);
      }, 1000);
    } else {
      clear();
      resetTimer();
    }
  }, [start]);

  useEffect(() => {
    if (timer === 0) {
      clear();
      resetTimer();
      onComplete();
    }
  }, [timer]);

  return <>{timer}</>;
}

CountdownTimer.propTypes = {
  timerDuration: number,
  start: bool,
  onComplete: func
};
