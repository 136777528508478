import styled from 'styled-components';
import { Dashed } from '../../../atoms/button';

const Container = styled.div`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const InnerContainer = styled.div``;

const AttributionContainer = styled.div`
  padding: 24px 48px;
  background-color: ${props => props.theme.SPRING_WOOD};
`;

const AttributionConfigContainer = styled.div`
  min-width: fit-content;
  padding: 50px;
  border-radius: 10px;
  padding-left: 0px;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  position: relative;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const SubHeading = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const MiniHeading = styled(SubHeading)`
  font-size: ${props => props.theme.X_SMALL};
  margin: 10px;
`;

const SetAttributionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 40px;
  min-width: ${props => props.min_width}px;
`;

const SetNetworkCommission = styled(SetAttributionContainer)`
  & > button {
    margin: 30px 25px;
  }
`;

const SetDataContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 5px;
  margin: 0;
`;

const SelectOption = styled.div`
  width: 50px;
  position: absolute;
  right: 10px;
  top: 48px;

  & > div {
    height: 30px;
  }
  & > div > div {
    background-color: lightgrey !important;
    border: none;
  }
`;

const DataInput = styled.input`
  width: 50px;
  background-color: ${props => props.theme.SNOW};
  outline: none;
  margin-top: 5px;
`;

const AttributionData = styled.div`
  width: 100%;
  max-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-top: 8px;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
  position: sticky;
  top: 0px;
  z-index: 99;
  background: ${props => props.theme.SPRING_WOOD};
`;

const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: contain;
  background-color: ${props => props.theme.WHITE};
  border: 1px solid ${props => props.theme.GAINSBORO};
`;

const ProductHeading = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin: 16px;
`;

const LevelRowContainer = styled.div`
  width: 100%;
  margin-top: ${props => props.marginTop + 'px' || 'auto'};
  margin-bottom: ${props => props.marginBottom + 'px' || 'initial'};
`;

const MenuList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
`;

const ListItem = styled.button`
  width: 100%;
  padding: 12px 12px 12px 20px;
  background-color: #f0d64e;
  border: none;
  text-align: left;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
`;

const ProductsContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.WHITE};
  border-radius: 10px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 0px;
  margin-bottom: 200px;
  & > div:last-child {
    border: none;
  }
`;

const Product = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.GAINSBORO};
  padding: 0px 30px 0px 30px;
  & > div {
    margin: 10px;
  }
  & > img {
    margin: 10px;
  }
`;

const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  border: 1px solid black;
  border-radius: 5px;
`;

const ProductTitle = styled.div`
  width: 100%;
  max-width: 170px;
`;

const OrderDetails = styled.div``;

const OrderPlaced = styled.div``;

const OrderID = styled.div``;

const HopsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const HoperInnerContainer = styled.div`
  width: 170px;
  height: 50px;
  position: relative;
  & > svg {
    fill: #ffff00;
  }
  & > svg > polygon {
    stroke: ${props => props.theme.BUDDHA_GOLD};
    stroke-width: 2px;
    fill: white;
    stroke-linejoin: round;
  }

  & > div {
    width: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 25px;
    top: 4px;
  }

  & > div > span {
    font-size: ${props => props.theme.SMALL};
    color: ${props => props.theme.BUDDHA_GOLD};
  }

  & > div > div {
    font-size: ${props => props.theme.SMALL};
    color: white;
    font-weight: ${props => props.theme.MEDIUM_FONT};
    background-color: ${props => props.theme.BUDDHA_GOLD};
    padding: 2px;
    border-radius: 3px;
  }
`;

const SaveButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

const AttributionDataEffectiveCommision = styled(AttributionData)`
  > div {
    input {
      ::placeholder {
        color: ${props => props.theme.BLACK};
      }
    }
  }
`;

const SelectOptionTimeLimit = styled(SelectOption)`
  width: 100px;
  right: 10px;
`;

const DeleteRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;
`;

const DeleteRow = styled.div`
  width: 4px;
  margin-bottom: 2px;
  height: 4px;
  border-radius: 50%;
  background-color: ${props => props.theme.BLACK};
`;

const TimeLimitConatainer = styled.div`
  display: flex;
`;

const DottedOutlineButton = styled(Dashed)`
  width: 100%;
  max-width: 240px;
  max-height: 50px;
  border-radius: 5px;
  border: dashed 1px ${props => props.theme.CORDUROY};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.LIGHT_FONT};
  color: ${props => props.theme.CORDUROY};
`;

const ShowDeleteMenuContainer = styled.div`
  display: block;
  position: relative;
  top: -5px;
  right: -25px;
`;

const SelectOptionDeleteContainer = styled(SelectOption)`
  width: 150px;

  & > div > div {
    background-color: ${props => props.theme.RONCHI} !important;
    opacity: 0.6;
  }
`;

const UpdateButtonContainer = styled.div`
  color: ${props => props.theme.DARK_BLACK};
  padding: 2px 8px;
  min-width: 80px;
  text-transform: none;
  background-color: #f0d64e;
  border-radius: 5px;
  height: 40px;
`;

const ClearAttributionButtonContainer = styled.div`
  margin-right: 8px;

  > button {
    width: auto;
  }
`;

const DeleteButton = styled.button`
  border: none;
  background: none;
  margin-top: 43px;
  color: #b80f0f;
  cursor: pointer;
`;

const ErrorText = styled.p`
  color: red;
  display: inline;
  margin-left: 10px;
  font-size: 14px;
`;

export {
  Container,
  InnerContainer,
  AttributionContainer,
  AttributionConfigContainer,
  ProductsContainer,
  Product,
  ProductImage,
  ProductTitle,
  OrderDetails,
  OrderPlaced,
  OrderID,
  HopsContainer,
  HoperInnerContainer,
  HeadingSection,
  Heading,
  SubHeading,
  MiniHeading,
  SetAttributionContainer,
  SetNetworkCommission,
  SetDataContainer,
  DataInput,
  AttributionData,
  Row,
  Col,
  Image,
  ProductHeading,
  LevelRowContainer,
  MenuList,
  ListItem,
  SelectOption,
  SaveButtonContainer,
  AttributionDataEffectiveCommision,
  SelectOptionTimeLimit,
  DeleteRow,
  DeleteRowContainer,
  TimeLimitConatainer,
  DottedOutlineButton,
  ShowDeleteMenuContainer,
  SelectOptionDeleteContainer,
  UpdateButtonContainer,
  ClearAttributionButtonContainer,
  DeleteButton,
  ErrorText
};
