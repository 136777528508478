import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextArea from '../../atoms/textArea';
import { isEmpty } from 'lodash';
import PublishPreview from '../../molecules/PublishPreview';
import {
  getApiResponseObject,
  postWithResponseObject,
  getAuthToken,
  successStatusCodes,
  showApiErrorToast
} from '../../../utils';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import {
  SHOPTYPE_WIDGET_URL,
  DESKTOP,
  MOBILE,
  COSELLER
} from '../../../constants';

import {
  MainContainer,
  Content,
  Heading,
  SecondLevelHeading,
  SubHeading,
  Headline,
  EmbedCodeContainer,
  CopyLink,
  RadioButton,
  EmbedRadioButtonContainer,
  DesktopView,
  Options,
  CopyLinkContainer
} from './styles';
class EmbedCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: this.props.match.params.productId || '',
      authId: this.props.match.params.authId || '',
      userSelection: DESKTOP,
      fetchedProductDetails: [],
      vendorId: '',
      publishLink: sessionStorage.getItem('product_share_url') || ''
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.fetchProductData();
  }

  fetchEmbedProductCode = device => {
    const { match } = this.props;
    const { publishLink } = this.state;

    if (device === 'mobile') {
      return `
      <div id="shoptype-widget-${match.params.productId}"></div>
      <script>
        var loadScriptAsync = function(uri){
          return new Promise((resolve, reject) => {
            var tag = document.createElement('script');
            tag.src = uri;
            tag.async = true;
            tag.onload = () => {
              resolve();
            };
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
          });
        }
        var scriptLoaded = loadScriptAsync("${SHOPTYPE_WIDGET_URL}");
        </script>
        <script>
        scriptLoaded.then(function(){
          renderWidget("shoptype-widget-${match.params.productId}","${match.params.productId}","${publishLink}","portrait");
        });
        </script>`;
    }

    return `
    <div id="shoptype-widget-${match.params.productId}"></div>
    <script>
      var loadScriptAsync = function(uri){
        return new Promise((resolve, reject) => {
          var tag = document.createElement('script');
          tag.src = uri;
          tag.async = true;
          tag.onload = () => {
            resolve();
          };
          var firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        });
      }
      var scriptLoaded = loadScriptAsync("${SHOPTYPE_WIDGET_URL}");
      </script>
      <script>
      scriptLoaded.then(function(){
        renderWidget("shoptype-widget-${match.params.productId}","${match.params.productId}","${publishLink}","landscape");
      });
      </script>`;
  };

  fetchProductData = async () => {
    const { productId } = this.state;
    const response = await getApiResponseObject(
      `${API_BASE_URL}/products/${productId}`
    );
    if (response.status !== 200) {
      showApiErrorToast(response.data);
    }
    const fetchedProductDetails = response.data || [];
    this.setState({
      fetchedProductDetails,
      vendorId: fetchedProductDetails?.vendor?.id || '',
      isLoading: false
    });
  };

  copyEmbedLink = () => {
    const copyText = document.getElementById('myInput');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');

    if (getAuthToken()) {
      this.embedClickEvent();
    }
  };

  embedClickEvent = async () => {
    const { productId, vendorId, publishLink } = this.state;
    const token = getAuthToken();
    const headers = {
      authorization: token,
      'Content-Type': 'application/json'
    };
    const timestamp = new Date().getTime();
    const requestBody = {
      productId: productId,
      vendorId: vendorId,
      referrerLink: window.location.href,
      link: publishLink,
      medium: 'embed',
      timestamp: timestamp
    };

    const userType = localStorage.getItem('USER_TYPE') || null;

    if (userType === COSELLER) {
      const responseCoseller = await getApiResponseObject(
        `${API_BASE_URL}/cosellers`,
        headers
      );

      if (
        successStatusCodes.includes(responseCoseller.status) &&
        responseCoseller.data
      ) {
        if (isEmpty(responseCoseller.data.id))
          return toaster('Unable to fetch Coseller details');
        requestBody.cosellerId = responseCoseller.data.id;
      }
    }

    await postWithResponseObject(
      `${API_BASE_URL}/publish-product`,
      requestBody,
      headers
    );
  };

  handleKeyDown(event) {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === 'c') {
      event.preventDefault();
    }
    if (event.metaKey && charCode === 'c') {
      event.preventDefault();
    }
  }
  handleRadio = e => {
    this.setState({ userSelection: e.target.value });
  };

  render() {
    const { fetchedProductDetails, userSelection } = this.state;
    let sampleDescription = fetchedProductDetails.description;
    const slicedDescription = sampleDescription
      ? `${sampleDescription.split('.')[0]}.`
      : '';

    const options = [DESKTOP, MOBILE].map((type, key) => {
      const isCurrent = userSelection === type;
      return (
        <EmbedRadioButtonContainer key={key}>
          <RadioButton>
            <label>
              <input
                type="radio"
                name="previewType"
                id={type}
                value={type}
                checked={isCurrent ? 'checked' : null}
                onChange={e => this.handleRadio(e)}
              />
            </label>
          </RadioButton>
          <div>
            {type === DESKTOP ? (
              <DesktopView>
                <PublishPreview
                  isCurrent={isCurrent}
                  fetchedProductDetails={fetchedProductDetails}
                  slicedDescription={slicedDescription}
                />
              </DesktopView>
            ) : (
              <PublishPreview
                isCurrent={isCurrent}
                fetchedProductDetails={fetchedProductDetails}
                slicedDescription={slicedDescription}
                isMobilePreview={true}
              />
            )}
          </div>
        </EmbedRadioButtonContainer>
      );
    });
    return (
      <>
        <MainContainer>
          <Content>
            {fetchedProductDetails ? (
              <>
                <Heading>Publish Preview</Heading>
                <Headline>
                  Select the kind of preview you want the consumer to see
                </Headline>
                <Options>{options}</Options>
              </>
            ) : null}
            <SecondLevelHeading>Embed code generated</SecondLevelHeading>
            <SubHeading>
              Copy the below code and paste it onto the HTML editor of your blog
              or website platform of your choice, and generate a Shoptype
              product card for your audience to purchase from.
            </SubHeading>
            <EmbedCodeContainer onKeyDown={this.handleKeyDown}>
              <TextArea
                readOnly
                id="myInput"
                name={'embedProductCode'}
                size={'large-size'}
                value={
                  userSelection === MOBILE
                    ? this.fetchEmbedProductCode('mobile')
                    : this.fetchEmbedProductCode('desktop')
                }
              />
            </EmbedCodeContainer>
            <CopyLinkContainer>
              <CopyLink
                text="Copy"
                size={'medium-large'}
                onClick={this.copyEmbedLink}
              />
            </CopyLinkContainer>
          </Content>
        </MainContainer>
      </>
    );
  }
}

EmbedCode.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  authId: PropTypes.string,
  match: PropTypes.object,
  publishLink: PropTypes.string
};

const mapStateToProps = state => {
  const { productsShare } = state;
  return {
    publishLink: productsShare.productShareLink
  };
};

export default withRouter(connect(mapStateToProps, null)(EmbedCode));
