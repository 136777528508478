import styled from 'styled-components';

const DownArrow = styled.div`
  svg {
    transform: rotate(270deg);
  }
`;

const UpArrow = styled.div`
  svg {
    transform: rotate(270deg);
  }
`;
const CarouselContainer = styled.div`
  width: 530px;
  height: 120px;
  position: relative;

  ${props =>
    props.orientation === 'desktop' ? 'transform: rotate(90deg);' : ''}
  .slick-slider {
    position: absolute;
    position: absolute;
    width: 200px;
    height: 70px;
    margin-top: 20px;
  }
  .slick-next:before,
  .slick-prev:before {
    content: unset;
  }
  .slick-slide > div > div {
    outline: none;
  }
  .slick-slide img {
    object-fit: contain;
    background-color: #ffffff;
    width: 120px;
    height: 70px;
    margin: auto;
    outline: none;
    cursor: pointer;
    ${props =>
      props.orientation === 'desktop' ? 'transform: rotate(-90deg);' : ''}
    div {
      > img {
        border-radius: 10px;
        box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .slick-slide video {
    width: 120px;
    height: 130px;
    margin: auto;
    outline: none;
    ${props =>
      props.orientation === 'desktop' ? 'transform: rotate(-90deg);' : ''}
  }
  .slick-slider button {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
  }

  .slick-prev {
    left: -35px;
    top: 34px;
  }
  .slick-next {
    right: -50px;
    top: 34px;
  }

  ${p => p.theme.TABLET`
width: 100%;
margin-top: 50px;

transform: rotate(0deg);

.slick-prev {
  display: none;
}

.slick-next {
    display: none;
  }

.slick-slider{
    position: absolute;
    width: 100%; 
}
.slick-slide > div > div{
    outline: none;
    display: flex;
    justify-content: space-between;
   
    
}
.slick-slide img {
    object-fit: contain;
    background-color: #ffffff;
    width: 140px;
    height: 150px;
    outline: none;
    cursor: pointer;
    transform: rotate(0deg);
    border: 1px solid ${props => props.theme.MERCURY};

    ${props => props.theme.TABLET`
    height: auto;
    max-height: 118px
    `}
}
.slick-slide video{
    width: 140px;
    height: 150px;
    cursor: pointer;
    transform: rotate(0deg);
    border: 1px solid ${props => props.theme.MERCURY};
    outline: none;

}
.slick-slider button{
    background-color: black;
    border-radius: 50%;
}

.slick-list {
    background-color: ${props => props.theme.SPRING_WOOD};
   
}




`};
`;

const CarouselElementContainer = styled.div``;

export { CarouselContainer, CarouselElementContainer, DownArrow, UpArrow };
