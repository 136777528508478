import styled from 'styled-components';
import Card from '../../atoms/card';
import TextInput from '../../atoms/textInput';

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;
`;

const SubHeading = styled.div`
  padding-top: 20px;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const Paragraph = styled.div`
  padding-top: 10px;
  margin-bottom: 40px;
  font-size: ${props => props.theme.LARGE};
`;

const LinkAccountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
`;

const AddProductView = styled(Card)`
  color: ${props => props.theme.BLACK};
  padding: 30px;
  max-width: 440px;
  text-align: center;
  & > p {
    min-height: 45px;
  }

  & > button {
    word-break: break-word;
    min-height: 40px;
  }
`;

const ImportProductModal = styled.div`
  color: ${props => props.theme.BLACK};
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ModalHeading = styled.div`
  font-weight: ${props => props.theme.MEDIUM_FONT};
  font-size: ${props => props.theme.HEADING};
  margin-bottom: 20px;
`;

const ModalSubHeading = styled.div`
  font-weight: ${props => props.theme.MEDIUM_FONT};
  font-size: ${props => props.theme.X_LARGE};
  margin: 0px 0px 10px 0px;
`;

const ShippingServiceTitle = styled.div`
  padding: 10px 0px 15px 15px;
`;

const ShippingService = styled.div`
  width: 500px;
  padding: 10px 0px 15px 15px;
`;

const ShippingServiceSelect = styled.div`
  width: 100%;
  max-width: 350px;
  margin: auto;
`;

const ModalForm = styled.form`
  width: 100%;
  max-width: 400px;
  display: block;
`;

const FormField = styled(TextInput)`
  width: 100%;
  max-width: 350px;
  margin: 10px auto;
`;

const LinkButton = styled.div`
  margin-top: 30px;
`;

const ProductSection = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

const ProductSyncButton = styled.div`
  & > button {
    width: unset !important;
    max-width: unset !important;
    word-break: break-word !important;
    min-height: 40px !important;
  }
`;

export {
  ProductContainer,
  SubHeading,
  Paragraph,
  LinkAccountContainer,
  AddProductView,
  ImportProductModal,
  ModalHeading,
  ModalSubHeading,
  ShippingServiceTitle,
  ShippingServiceSelect,
  ShippingService,
  ModalForm,
  FormField,
  LinkButton,
  ProductSection,
  ProductSyncButton
};
