import styled, { css } from 'styled-components';
import { Secondary } from '../../atoms/button';
import TextInput from '../../atoms/textInput';

const Container = styled.div`
  padding: 50px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  text-align: left;
`;

const EmailForm = styled.div`
  max-width: 600px;
  border-radius: 5px;
  text-align: left;
  border: solid 1px ${props => props.theme.SILVER_CHALICE};
  > div {
    input {
      text-align: left;
      min-height: 60px;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const ToInput = styled(TextInput)`
  border-bottom: none;
  min-height: 60px;

  > input {
    width: 100% !important;

    &:disabled {
      background-color: unset;
    }
  }
`;

const CSVWrapper = styled.div`
  min-width: 298px;
  width: 100%;
  padding: 2px;
`;

const NewClose = styled.div`
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  border-radius: 100%;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 22px;
  height: 22px;
  font-weight: 600;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  padding: 0;
  z-index: 10;
`;

const CSVContainer = styled.div`
  > div {
    z-index: 1;
    display: flex;
    flex-direction: column;

    input {
      display: none;
    }

    label {
      cursor: pointer;
      padding: 10px;
      border-radius: 3px;
      border: dashed 1px #6b6c6b;
      text-align: center;

      &:hover {
        border-style: solid;
      }
    }
  }
`;

const Content = styled.div`
  min-height: 850px;
  padding: 24px;
`;

const SendEmailContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

const SendEmail = styled(Secondary)`
  margin-left: 20px;
  max-width: 130px;
  min-height: 40px;
  border-radius: 5px;
  font-size: 14px; 
  padding: 8px 5px; 
  border: none; 
  font-weight: 600;
  background-color: #fed713;
  color: ${props => props.theme.BLACK};
  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.3;
      cursor: unset;
    `}
`;

const HelpSpan = styled.span`
  font-size: ${props => props.theme.X_SMALL};
  color: #555;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  > input {
    border-right: 0 !important;
    border: solid 1px ${props => props.theme.BLACK};
  }
`;

const ProductLink = styled.input`
  width: 100%;
`;

const ReferrerWrapper = styled.div`
  margin-bottom: 18px;
`;

const ReferrerHeading = styled.div`
  font-size: ${props => props.theme.SMALL_FONT};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  text-align: left;
`;

const ActionsContainer = styled.div``;

const RemoveEmailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  color: red;
`;

export {
  Container,
  Heading,
  EmailForm,
  ToInput,
  CSVWrapper,
  CSVContainer,
  NewClose,
  Content,
  SendEmailContainer,
  SendEmail,
  HelpSpan,
  InputContainer,
  ProductLink,
  ReferrerWrapper,
  ReferrerHeading,
  ActionsContainer,
  RemoveEmailsWrapper
};