import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../atoms/textInputV1';
import Script from 'react-load-script';
import { GOOGLE_MAPS_API_KEY } from '../../../constants';

class GooglePlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addressField = type => {
    if (type.includes('locality') && type.includes('political')) {
      return 'city';
    }
    if (
      type.includes('administrative_area_level_1') &&
      type.includes('political')
    ) {
      return 'state';
    }
    if (type.includes('postal_code')) {
      return 'postal_code';
    }
    if (type.includes('country')) {
      return 'country';
    }
  };

  handleScriptLoad = () => {
    /*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.props.addressType)
    );

    this.autocomplete.setFields([
      'address_components',
      'formatted_address',
      'name'
    ]);
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  };

  handlePlaceSelect = () => {
    const addressObject = this.autocomplete.getPlace();

    try {
      if (addressObject) {
        let addressDetails = {
          street1: '',
          city: '',
          state: '',
          postal_code: '',
          country: '',
          countryCode: ''
        };

        const commaRegex = /,/g;
        const spaceRegex = /\s/g;
        let name = addressObject.name || '';
        let prependName = true;
        let longAddress = '';
        let shortAddress = '';
        let address = '';

        let filtered_address_components = addressObject.address_components.filter(
          item => {
            return (
              item.types.includes('street_number') ||
              item.types.includes('route') ||
              item.types.includes('sublocality_level_2') ||
              item.types.includes('sublocality_level_1')
            );
          }
        );

        addressObject.address_components.forEach(item => {
          longAddress += item.long_name.toLowerCase();
          shortAddress += item.short_name.toLowerCase();

          switch (this.addressField(item.types)) {
            case 'city':
              addressDetails.city = item.long_name || '';
              break;
            case 'state':
              addressDetails.state = item.long_name || '';
              break;
            case 'postal_code':
              addressDetails.postal_code = item.long_name || '';
              break;
            case 'country':
              addressDetails.country = item.long_name || '';
              addressDetails.countryCode = item.short_name || '';
              addressDetails.countryCodePhone = item.short_name || '';
              break;
            default:
              break;
          }
        });

        filtered_address_components.forEach(item => {
          address += ` ${item.long_name}`;
        });

        if (
          shortAddress
            .replace(spaceRegex, '')
            .replace(commaRegex, '')
            .indexOf(
              name
                .replace(spaceRegex, '')
                .replace(commaRegex, '')
                .toLowerCase()
            ) !== -1 ||
          longAddress
            .replace(spaceRegex, '')
            .replace(commaRegex, '')
            .indexOf(
              name
                .replace(spaceRegex, '')
                .replace(commaRegex, '')
                .toLowerCase()
            ) !== -1
        ) {
          prependName = false;
        }

        addressDetails.street1 = prependName
          ? `${name} ${address.trim()}`
          : address.trim();

        if (this.props.setAddress && this.props.setAddress) {
          this.props.setAddress(addressDetails, this.props.addressType);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      name,
      type,
      placeHolder,
      value,
      onChange,
      autocomplete,
      onBlur,
      required
    } = this.props;
    const url = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    return (
      <React.Fragment>
        <Script url={url} onLoad={this.handleScriptLoad} />
        <TextInput
          id={this.props.addressType}
          name={name}
          type={type}
          placeHolder={placeHolder}
          value={value}
          onChange={onChange}
          autocomplete={autocomplete}
          onBlur={onBlur}
          required={required}
        />
      </React.Fragment>
    );
  }
}

GooglePlacesAutocomplete.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  autocomplete: PropTypes.string,
  onBlur: PropTypes.func,
  setAddress: PropTypes.func,
  updateAddressFields: PropTypes.func,
  addressType: PropTypes.string
};

export default GooglePlacesAutocomplete;
