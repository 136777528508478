import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import React from 'react';
import DefaultImage from '../../../../assets/images/no-image.svg';
import shoptypeThemes from '../../../../theme';
import CardSpinner from '../../../common/CardSpinner';
import WeeklySalesTrend from '../../../common/Graph/weeklySalesTrend';
import {
  DataLabel,
  LocationIcon,
  MoneyIcon,
  NetworkInfo,
  StyledAvatar,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledLink
} from '../styles';

const useStyles = makeStyles({
  networkData: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridRowGap: '12px',
    gridColumnGap: '2px',
    width: '100%'
  },
  networkItem: {
    alignSelf: 'start',
    justifySelf: 'center',
    textAlign: 'center'
  },
  button: {
    flex: '1 1 auto',
    textTransform: 'none'
  },
  buttonSuccess: {
    backgroundColor: shoptypeThemes.default.SUCCESS,
    color: shoptypeThemes.default.WHITE,
    '&:hover': {
      backgroundColor: shoptypeThemes.default.SUCCESS_HOVER
    }
  },
  buttonFailure: {
    backgroundColor: shoptypeThemes.default.FAILURE,
    color: shoptypeThemes.default.WHITE,
    '&:hover': {
      backgroundColor: shoptypeThemes.default.FAILURE_HOVER
    }
  },
  title: {
    lineHeight: '1',
    marginBottom: '5px',
    wordBreak: 'break-word'
  }
});

const ConnectionRequestCard = props => {
  const classes = useStyles();
  const { network, platform, analytics, onAccept, onDecline } = props;

  // GRAPH
  let weeklySalesTrend = analytics?.weeklySalesTrend;
  const salesTrend = weeklySalesTrend?.map(sale => {
    return sale.total_sales;
  });
  // END GRAPH

  // CARD HEADER
  const avatar = network?.preview_image_url || DefaultImage;
  const networkName = network?.name ? network.name : '--No name--';
  const networkURL = platform?.url ? platform.url : '--No url--';
  const geoLocation = platform?.geoLocation ? platform.geoLocation : '--';
  const city = geoLocation?.split(',')[0];
  const currency = network?.currency ? network.currency.toUpperCase() : '--';

  // CARD CONTENT
  const networkFee = analytics?.network_fee ? `${analytics.network_fee}%` : 0;
  const vendorsConnected = analytics?.vendors_count;
  const totalProducts = analytics?.products_count;
  const ordersLastMonth = analytics?.total_orders_last_month;

  // FOR MAPPING ANALYTICS
  const NETWORK_DATA = !isEmpty(analytics)
    ? [
        {
          label: 'Network Fee',
          value: networkFee
        },
        {
          label: 'Vendors Connected',
          value: vendorsConnected
        },
        {
          label: 'Total Products',
          value: totalProducts
        },
        {
          label: 'Orders Last Month',
          value: ordersLastMonth
        }
      ]
    : [];

  if (!isEmpty(weeklySalesTrend) && !isEmpty(salesTrend)) {
    NETWORK_DATA.push({
      label: 'Sales Trends Last Month',
      value: <WeeklySalesTrend salesTrend={salesTrend} />
    });
  }

  return (
    <StyledCard>
      <StyledCardHeader
        avatar={
          <StyledAvatar
            objectfit={network?.preview_image_url}
            aria-label="network-image"
            src={avatar}
          />
        }
        title={networkName}
        classes={{ title: classes.title }}
        subheader={
          <>
            <Box display="flex" flexDirection="column" mb={1.5}>
              <StyledLink
                className={classes.link}
                target="_blank"
                to={{
                  pathname: `http://${networkURL}`
                }}
              >
                {networkURL}
              </StyledLink>
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <NetworkInfo>
                <LocationIcon />
                <Box fontSize={10} fontWeight={500}>
                  {city}
                </Box>
              </NetworkInfo>
              <NetworkInfo>
                <MoneyIcon />
                <Box fontSize={10} fontWeight={500}>
                  {currency}
                </Box>
              </NetworkInfo>
            </Box>
          </>
        }
        titleTypographyProps={{
          variant: 'h6'
        }}
        subheaderTypographyProps={{
          variant: 'subtitle2'
        }}
      />
      <Box mt="auto">
        <StyledCardContent>
          {!isEmpty(analytics) ? (
            <div className={classes.networkData}>
              {NETWORK_DATA.map((data, index) => (
                <div className={classes.networkItem} key={index}>
                  <Box fontSize={24} fontWeight={500}>
                    {data.value}
                  </Box>
                  <DataLabel>{data.label}</DataLabel>
                </div>
              ))}
            </div>
          ) : (
            <CardSpinner />
          )}
        </StyledCardContent>
        <StyledCardActions>
          <Button
            variant="contained"
            className={`${classes.button} ${classes.buttonSuccess}`}
            onClick={onAccept}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            className={`${classes.button} ${classes.buttonFailure}`}
            onClick={onDecline}
          >
            Decline
          </Button>
        </StyledCardActions>
      </Box>
    </StyledCard>
  );
};

export default ConnectionRequestCard;
