import React from 'react';
import Icons from '../../atoms/icons';
import payoutsLogo from '../../../assets/images/payouts-logo.png';
import { InactiveIcon, ActiveIcon } from './styles';

// All the Coseller, Vendor and NetworkOperator Categories are defined below:
const CONSTANT_CATEGORIES = [
  {
    name: 'dashboard',
    icon: (
      <InactiveIcon>
        <Icons
          height={26}
          key="sidebar-dashboard"
          width={26}
          name="sidebar-dashboard"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={26}
          key="sidebar-dashboard"
          width={26}
          name="sidebar-dashboard"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'products',
    icon: (
      <InactiveIcon>
        <Icons
          height={27}
          key="sidebar-products"
          width={20}
          name="sidebar-products"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={27}
          key="sidebar-products"
          width={20}
          name="sidebar-products"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'wallet',
    icon: (
      <InactiveIcon>
        <Icons
          height={30}
          key="sidebar-wallet"
          width={30}
          name="sidebar-wallet"
          viewBox="0 0 480 480"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={30}
          key="sidebar-wallet"
          width={30}
          name="sidebar-wallet"
          viewBox="0 0 480 480"
        />
      </ActiveIcon>
    )
  }
];

const NETWORK_OPERATOR_CATEGORIES = [
  {
    name: 'network',
    icon: (
      <InactiveIcon>
        <Icons
          height={30}
          key="sidebar-networks"
          width={30}
          name="sidebar-networks"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={30}
          key="sidebar-networks"
          width={30}
          name="sidebar-networks"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'vendors',
    icon: (
      <InactiveIcon>
        <Icons
          height={30}
          key="sidebar-vendors"
          width={30}
          name="sidebar-vendors"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={30}
          key="sidebar-vendors"
          width={30}
          name="sidebar-vendors"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'collections',
    icon: (
      <InactiveIcon>
        <Icons
          height={27}
          key="sidebar-collections"
          width={25}
          name="sidebar-collections"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={27}
          key="sidebar-collections"
          width={25}
          name="sidebar-collections"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'attribution',
    icon: (
      <InactiveIcon>
        <Icons
          height={27}
          key="attribution"
          width={27}
          name="sidebar-social-platforms"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={27}
          key="sidebar-social-platforms"
          width={27}
          name="sidebar-social-platforms"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'orders',
    icon: (
      <InactiveIcon>
        <Icons
          height={34}
          key="sidebar-orders"
          width={32}
          name="sidebar-orders"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={34}
          key="sidebar-orders"
          width={32}
          name="sidebar-orders"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'payments',
    icon: (
      <InactiveIcon>
        <Icons
          height={34}
          key="sidebar-earnings"
          width={32}
          name="sidebar-earnings"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={34}
          key="sidebar-earnings"
          width={32}
          name="sidebar-earnings"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'payouts',
    icon: (
      <InactiveIcon>
        <img src={payoutsLogo} width={34} height={32} />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <img src={payoutsLogo} width={34} height={32} />
      </ActiveIcon>
    )
  }
];

const VENDOR_CATEGORIES = [
  {
    name: 'attribution',
    icon: (
      <InactiveIcon>
        <Icons
          height={27}
          key="sidebar-social-platforms"
          width={27}
          name="sidebar-social-platforms"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={27}
          key="sidebar-social-platforms"
          width={27}
          name="sidebar-social-platforms"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'orders',
    icon: (
      <InactiveIcon>
        <Icons
          height={34}
          key="sidebar-orders"
          width={32}
          name="sidebar-orders"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={34}
          key="sidebar-orders"
          width={32}
          name="sidebar-orders"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'networks',
    icon: (
      <InactiveIcon>
        <Icons
          height={30}
          key="sidebar-networks"
          width={30}
          name="sidebar-networks"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={30}
          key="sidebar-networks"
          width={30}
          name="sidebar-networks"
        />
      </ActiveIcon>
    )
  }
];

const COSELLER_CATEGORIES = [
  {
    name: 'earnings',
    icon: (
      <InactiveIcon>
        <Icons
          height={34}
          key="sidebar-earnings"
          width={32}
          name="sidebar-earnings"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={34}
          key="sidebar-earnings"
          width={32}
          name="sidebar-earnings"
        />
      </ActiveIcon>
    )
  },
  {
    name: 'purchases',
    icon: (
      <InactiveIcon>
        <Icons
          height={34}
          key="sidebar-orders"
          width={32}
          name="sidebar-orders"
        />
      </InactiveIcon>
    ),
    iconSelected: (
      <ActiveIcon>
        <Icons
          height={34}
          key="sidebar-orders"
          width={32}
          name="sidebar-orders"
        />
      </ActiveIcon>
    )
  }
];

export {
  CONSTANT_CATEGORIES,
  VENDOR_CATEGORIES,
  COSELLER_CATEGORIES,
  NETWORK_OPERATOR_CATEGORIES
};
