import React, { useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../constants';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Theme from '../../../theme';

const prepareAddress = addressObject => {
  try {
    if (addressObject) {
      let addressDetails = {
        street1: '',
        city: '',
        state: '',
        state_code: '',
        postal_code: '',
        country: '',
        country_code: ''
      };

      const commaRegex = /,/g;
      const spaceRegex = /\s/g;
      let name = addressObject.name || '';
      let prependName = true;
      let longAddress = '';
      let shortAddress = '';
      let address = '';

      let filtered_address_components = addressObject.address_components.filter(
        item => {
          return (
            item.types.includes('street_number') ||
            item.types.includes('route') ||
            item.types.includes('sublocality_level_2') ||
            item.types.includes('sublocality_level_1')
          );
        }
      );

      addressObject.address_components.forEach(item => {
        longAddress += item.long_name.toLowerCase();
        shortAddress += item.short_name.toLowerCase();

        switch (addressField(item.types)) {
          case 'city':
            addressDetails.city = item.long_name || '';
            break;
          case 'state':
            addressDetails.state = item.long_name || '';
            addressDetails.state_code = item.short_name || '';
            break;
          case 'postal_code':
            addressDetails.postal_code = item.long_name || '';
            break;
          case 'country':
            addressDetails.country = item.long_name || '';
            addressDetails.country_code = item.short_name || '';
            addressDetails.country_code_phone = item.short_name || '';
            break;
          default:
            break;
        }
      });

      filtered_address_components.forEach(item => {
        address += ` ${item.long_name}`;
      });

      if (
        shortAddress
          .replace(spaceRegex, '')
          .replace(commaRegex, '')
          .indexOf(
            name
              .replace(spaceRegex, '')
              .replace(commaRegex, '')
              .toLowerCase()
          ) !== -1 ||
        longAddress
          .replace(spaceRegex, '')
          .replace(commaRegex, '')
          .indexOf(
            name
              .replace(spaceRegex, '')
              .replace(commaRegex, '')
              .toLowerCase()
          ) !== -1
      ) {
        prependName = false;
      }

      addressDetails.street1 = prependName
        ? `${name} ${address.trim()}`
        : address.trim();

      return addressDetails;
    }
  } catch (err) {
    console.log(err);
  }
};

const addressField = type => {
  if (type.includes('locality') && type.includes('political')) {
    return 'city';
  }
  if (
    type.includes('administrative_area_level_1') &&
    type.includes('political')
  ) {
    return 'state';
  }
  if (type.includes('postal_code')) {
    return 'postal_code';
  }
  if (type.includes('country')) {
    return 'country';
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 280,
    // eslint-disable-next-line no-unexpected-multiline
    ['@media (max-width:544px)']: {
      maxWidth: '100%'
    }
  },
  addressRoot: {
    width: '100%',
    maxWidth: 568
  },
  inputRoot: {
    fontSize: 14,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    }
  },
  labelRoot: {
    fontSize: 14,
    color: Theme.default.CORDUROY,
    '&$labelFocused': {
      color: Theme.default.BUDDHA_GOLD
    }
  },
  labelFocused: {}
}));

const GooglePlacesAutocomplete = props => {
  const [selectedAddress, setSelectedAddress] = useState({});
  const classes = useStyles();

  useEffect(() => {
    props.callback(selectedAddress, 'all');
  }, [selectedAddress]);

  const widgetSettings = {
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (response, inputRef) => {
      const selectedAddress = prepareAddress(response);
      setSelectedAddress(selectedAddress);
      if (props.allowCustomValues) {
        inputRef.value = selectedAddress[props.customValueKey];
      }
    },
    options: {
      types: props.types ?? [],
      fields: ['address_components']
    }
  };

  const { ref } = usePlacesWidget(widgetSettings);

  useEffect(() => {
    if (props.allowCustomValues) {
      ref.current.value = props.customValue || '';
    }
  }, [props.customValue]);

  return (
    <TextField
      size="small"
      label={props.label ?? 'Search Location'}
      variant="outlined"
      id={`${props.type}-google-autocomplete`}
      inputRef={ref}
      className={`${classes.root} ${classes.addressRoot}`}
      InputProps={{
        classes: { root: classes.inputRoot },
        defaultValue: props.customValue ?? ''
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused
        }
      }}
      onKeyUp={e => {
        if (props.allowCustomValues) {
          const addressObj = {};
          addressObj[props.customValueKey] = e.target.value;
          props.callback(addressObj, 'custom');
        }
      }}
    />
  );
};

export default GooglePlacesAutocomplete;
