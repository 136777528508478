import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { number, func } from 'prop-types';

class MaterialTablePagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { count, page, colSpan, handleChangePage, rowsPerPage } = this.props;

    return (
      <>
        <TablePagination
          rowsPerPageOptions={[]}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true
          }}
          onPageChange={handleChangePage}
        />
      </>
    );
  }
}

MaterialTablePagination.propTypes = {
  count: number,
  page: number,
  colSpan: number,
  onPageChange: func
};

export default MaterialTablePagination;
