import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import history from '../../../history';
import ShoptypeLogoImage from '../../../assets/images/logo.png';
import DefaultProductImage from '../../../assets/images/photo.svg';
import Loader from '../../atoms/loader';
import CheckBox from '../../atoms/checkBox';
import { toaster } from '../../atoms/toaster';
import AddressInput from '../../organisms/AddressInput';
import Theme from '../../../theme';
import {
  API_BASE_URL,
  SHOPTYPE_API_KEY,
  APP_ENV,
  APP_ENV_PRODUCTION
} from '../../../config/env';
import {
  getCheckoutDetails,
  updateCheckoutAddresses,
  updateShippingOption
} from '../../../apis/cart';
import {
  successStatusCodes,
  removeCartId,
  isEmailValid,
  isPhoneValid,
  postWithResponseObject
} from '../../../utils';
import {
  fetchCountriesList,
  fetchStatesList
} from '../../organisms/AddressInput/prebuiltData/locationUtilities';
import * as actions from '../../../redux/actions/uiActions';
import Currency from '../../molecules/Currency';
import {
  Container,
  RowWrap,
  ColWrap,
  BuyerInfo,
  BuyerContent,
  ShoptypeLogo,
  BuyerShippingBillingInput,
  ShippingInfo,
  BillingInfo,
  Heading,
  Continue,
  ContinueButton,
  BuyerChooseShippingOptionInput,
  BuyerSummary,
  SummaryBlock,
  SummaryBlockHeading,
  EditBuyerInfo,
  EditBuyerInfoButton,
  OrderInfoWrapper,
  ProductsData,
  ProductDetails,
  CostCalculation,
  CostType,
  ProductImageContainer,
  ProductInformationContainer,
  ProductAmountContainer,
  ProductHeading,
  ProductVariant,
  ProductImageWrapper,
  ProductQuantityChip,
  CostTypeHeading,
  CostTypeAmount,
  CostTypeMessage,
  TotalCostContainer,
  TotalCostHeading,
  TotalCostAmount,
  OrderInfoContent,
  MobileView,
  ShoptypeLogoMobile,
  MobileContent,
  ExpansionButton,
  ExpansionButtonContent,
  ButtonMainLabel,
  ButtonSecondaryLabel,
  LineItemVendorDetails,
  ShippingOptionsCard,
  VendorShippingOptions
} from './styles';
import { VARIANT_COMBO_DELIMITER } from '../../../constants';
import initSTPayment from 'st-payment-handlers';
import orderConfirmationTemplate from '../../email-templates/orderConfirmation';

const styles = {
  root: {
    width: '100%',
    maxWidth: 280
  },
  inputRoot: {
    fontSize: 14,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    }
  },
  labelRoot: {
    fontSize: 14,
    color: Theme.default.CORDUROY,
    '&$labelFocused': {
      color: Theme.default.BUDDHA_GOLD
    }
  },
  labelFocused: {}
};

const addressFields = {
  name: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  country: '',
  countryCode: '',
  state: '',
  stateCode: '',
  postalCode: ''
};

const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: '#E6C100'
    }
  },
  checked: {}
})(Radio);

const CheckoutV2 = ({ showSpinnerBackdrop, hideSpinnerBackdrop }) => {
  const { checkoutId } = useParams();
  const [countries, setCountries] = useState([]);
  const [countriesCode, setCountriesCode] = useState({});
  const [shippingAdddressInfo, setShippingAdddressInfo] = useState({
    countryCode: '',
    statesList: [],
    statesCode: {}
  });
  const [billingAddressInfo, setBillingAddressInfo] = useState({
    countryCode: '',
    statesList: [],
    statesCode: {}
  });
  const [cartCheckoutId, setCartCheckoutId] = useState(null);
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [currency, setCurrency] = useState('USD');
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [updateFormFields, setUpdateFormFields] = useState(false);
  const [disableContinue, setDisableContinue] = useState(false);
  const [expanded, toggleExpansionPanel] = useState(false);
  const [isLoading, updateIsLoading] = useState(false);
  const [formEditMode, setFormEditMode] = useState(true);
  const [choosenShippingOptions, setChoosenShippingOptions] = useState({});
  const [disablePayment, setDisablePayment] = useState(false);
  const [requiresShippping, setRequiresShippping] = useState(true);

  const [shippingDataStatus, setShippingDataStatus] = useState({
    available: false,
    fetching: false,
    error: false
  });
  const [disableContinueToPayment, setDisableContinueToPayment] = useState(
    false
  );
  const [taxesDataStatus, setTaxesDataStatus] = useState({
    available: false,
    fetching: false,
    error: false
  });
  const [costs, updateCosts] = useState({
    subTotal: 0.0,
    taxes: 0.0,
    shipping: 0.0,
    total: 0.0
  });
  const [payment, updatePaymentStatus] = useState({
    readyForPayment: false,
    paymentProcess: false,
    paymentStatus: false
  });
  const [shippingAddress, setShippingAddress] = useState({
    ...addressFields
  });
  const [billingAddress, setBillingAddress] = useState({
    ...addressFields
  });

  const [razorpayHandler, setrazorpayHandler] = useState(() => {});

  useEffect(() => {
    setCartCheckoutId(checkoutId);
  }, []);

  useEffect(() => {
    const fetchCheckoutDetails = async () => {
      showSpinnerBackdrop();
      const res = await getCheckoutDetails(cartCheckoutId);

      if (successStatusCodes.includes(res.status)) {
        const data = res.data;

        if (!isEmpty(data?.order_details_per_vendor)) {
          if (data.requires_shipping && !isEmpty(data.shipping_address)) {
            const structuredAddress = await restructureAddress(
              data.shipping_address,
              'receive'
            );

            setShippingAddress(structuredAddress);
          }

          if (!data.is_shipping_billing && !isEmpty(data.billing_address)) {
            const structuredAddress = await restructureAddress(
              data.billing_address,
              'receive'
            );

            setBillingAddress(structuredAddress);
            setBillingSameAsShipping(false);
          }

          if (!data.requires_shipping) {
            setRequiresShippping(false);
            setBillingSameAsShipping(false);
          }

          updateCosts({
            subTotal: data?.sub_total?.amount || 0.0,
            taxes: 0.0,
            shipping: 0.0,
            total: data?.total?.amount || 0.0
          });

          setCurrency(data?.total?.currency || 'USD');
          setCheckoutDetails(data);
        }
      } else {
        hideSpinnerBackdrop();
        toaster('Cannot fetch checkout details');
      }
    };

    if (cartCheckoutId) {
      fetchCheckoutDetails();
      fetchAndUpdateCountriesData();
    }
  }, [cartCheckoutId]);

  useEffect(() => {
    setUpdateFormFields(true);
    hideSpinnerBackdrop();
  }, [checkoutDetails]);

  useEffect(() => {
    if (updateFormFields) {
      setUpdateFormFields(false);
    }
  }, [updateFormFields]);

  useEffect(() => {
    if (billingSameAsShipping) {
      setBillingAddress({ ...addressFields });
    }
  }, [billingSameAsShipping]);

  const fetchAndUpdateCountriesData = async () => {
    const countriesListRes = await fetchCountriesList();
    let countriesCode = {};

    setCountries(
      countriesListRes.map(country => {
        countriesCode[country.name] = country.iso2;
        return country.name;
      })
    );

    setCountriesCode(countriesCode);
  };

  const fetchAndUpdateStatesData = async (countryCode, addressType) => {
    if (
      addressType === 'billing' &&
      shippingAdddressInfo.countryCode === countryCode &&
      !isEmpty(shippingAdddressInfo.statesList) &&
      !isEmpty(shippingAdddressInfo.statesCode)
    ) {
      updateAddressDataInfo(
        countryCode,
        addressType,
        shippingAdddressInfo.statesList,
        shippingAdddressInfo.statesCode
      );
    } else if (
      addressType === 'shipping' &&
      billingAddressInfo.countryCode === countryCode &&
      !isEmpty(billingAddressInfo.statesList) &&
      !isEmpty(billingAddressInfo.statesCode)
    ) {
      updateAddressDataInfo(
        countryCode,
        addressType,
        billingAddressInfo.statesList,
        billingAddressInfo.statesCode
      );
    } else {
      const statesListRes = await fetchStatesList(countryCode);
      let statesCode = {};

      const statesList = statesListRes.map(state => {
        statesCode[state.name] = state.iso2;
        return state.name;
      });

      updateAddressDataInfo(countryCode, addressType, statesList, statesCode);
    }
  };

  const updateAddressDataInfo = (
    countryCode,
    addressType,
    statesList,
    statesCode
  ) => {
    addressType === 'shipping'
      ? setShippingAdddressInfo({
          countryCode: countryCode,
          statesList,
          statesCode
        })
      : setBillingAddressInfo({
          countryCode: countryCode,
          statesList,
          statesCode
        });
  };

  const formIsValid = () => {
    let valid = false;

    if (billingSameAsShipping && requiresShippping) {
      valid = fieldsAreComplete(shippingAddress);
    } else {
      if (requiresShippping) {
        valid =
          fieldsAreComplete(shippingAddress) &&
          fieldsAreComplete(billingAddress);
      } else {
        valid = fieldsAreComplete(billingAddress);
      }
    }

    return valid;
  };

  const fieldsAreComplete = addressObj => {
    if (isEmpty(addressObj.name)) {
      toaster('Please enter your name');
      return false;
    } else if (isEmpty(addressObj.phone)) {
      toaster('Please enter your phone number');
      return false;
    } else if (!isPhoneValid(addressObj.phone)) {
      toaster('Please enter a valid phone number');
      return false;
    } else if (isEmpty(addressObj.email)) {
      toaster('Please enter a your email adress');
      return false;
    } else if (!isEmailValid(addressObj.email)) {
      toaster('Please enter a valid email adress');
    } else if (isEmpty(addressObj.city)) {
      toaster('Please enter your city');
      return false;
    } else if (isEmpty(addressObj.country)) {
      toaster('Please select your country');
      return false;
    } else if (addressObj.country !== '' && isEmpty(addressObj.state)) {
      toaster('Please select your state');
      return false;
    } else if (isEmpty(addressObj.countryCode)) {
      toaster('Please enter your country code');
      return false;
    } else if (isEmpty(addressObj.postalCode)) {
      toaster('Please enter your postal code');
      return false;
    } else {
      return true;
    }
  };

  const updateAddresses = async () => {
    if (formIsValid()) {
      const reqbody = {
        shipping_address: await restructureAddress(shippingAddress, 'send'),
        billing_address: billingSameAsShipping
          ? await restructureAddress(shippingAddress, 'send')
          : await restructureAddress(billingAddress, 'send'),
        is_shipping_billing: billingSameAsShipping
      };

      setDisableContinue(true);

      setTaxesDataStatus({
        available: false,
        fetching: true,
        error: false
      });

      setShippingDataStatus({
        available: false,
        fetching: true,
        error: false
      });

      const res = await updateCheckoutAddresses(cartCheckoutId, reqbody);

      setDisableContinue(false);

      if (successStatusCodes.includes(res.status)) {
        setFormEditMode(false);

        updateCosts({
          subTotal: res?.data?.sub_total?.amount || 0.0,
          taxes: res?.data?.taxes?.amount || 0.0,
          shipping: res?.data?.shipping?.amount
            ? parseFloat(res.data.shipping.amount)
            : 0.0,
          total: res?.data?.total?.amount || 0.0
        });

        setTaxesDataStatus({
          available: true,
          fetching: false,
          error: false
        });

        setShippingDataStatus({
          available: true,
          fetching: false,
          error: false
        });

        setCheckoutDetails(res.data);
        generateShippingOptions(res.data);

        updatePaymentStatus({
          ...payment,
          readyForPayment: true
        });
      } else {
        setTaxesDataStatus({
          available: false,
          fetching: false,
          error: true
        });

        setShippingDataStatus({
          available: false,
          fetching: false,
          error: true
        });

        toaster(res.data.message || 'Cannot update address');
      }
    }
  };

  const generateShippingOptions = async data => {
    const vendorIdList = Object.keys(data.order_details_per_vendor);

    const choosenOptions = { method_key_per_vendor: {} };

    vendorIdList.forEach(vendorId => {
      if (!isEmpty(data.order_details_per_vendor[vendorId].shipping_selected)) {
        choosenOptions.method_key_per_vendor[vendorId] = {
          method_key:
            data.order_details_per_vendor[vendorId].shipping_selected.method_key
        };
      }
    });

    setChoosenShippingOptions(choosenOptions);
  };

  const restructureAddress = async (adddressObj, type) => {
    if (type === 'send') {
      return {
        name: adddressObj.name,
        phone: adddressObj.phone,
        email: adddressObj.email,
        street1: adddressObj.address,
        city: adddressObj.city,
        country: adddressObj.country,
        countryCode: adddressObj.countryCode,
        state: adddressObj.state,
        stateCode: adddressObj.stateCode,
        postalCode: adddressObj.postalCode
      };
    } else {
      return {
        name: adddressObj.name,
        phone: adddressObj.phone || '',
        email: adddressObj.email || '',
        address: adddressObj.street1 || '',
        city: adddressObj.city || '',
        country: adddressObj.country,
        countryCode: adddressObj.countryCode || '',
        state: adddressObj.state,
        stateCode: adddressObj.stateCode || '',
        postalCode: adddressObj.postalCode || ''
      };
    }
  };

  const editBuyerInfo = () => {
    setUpdateFormFields(true);

    setChoosenShippingOptions({});

    setTaxesDataStatus({
      available: false,
      fetching: false,
      error: false
    });

    setShippingDataStatus({
      available: false,
      fetching: false,
      error: false
    });

    updateCosts({
      subTotal: checkoutDetails?.cart?.sub_total?.amount || 0.0,
      taxes: 0.0,
      shipping: 0.0,
      total: checkoutDetails?.cart?.total?.amount || 0.0
    });

    setDisableContinue(false);

    setDisableContinueToPayment(false);

    setFormEditMode(true);

    updatePaymentStatus({
      readyForPayment: false,
      paymentProcess: false,
      paymentStatus: false
    });
  };

  const addressDataFetch = (fetchedAddressType, fetchedAddressData) => {
    if (fetchedAddressType === 'shipping')
      setShippingAddress(fetchedAddressData);
    else if (fetchedAddressType === 'billing')
      setBillingAddress(fetchedAddressData);
  };

  const confirmPaymentStatus = payload => {
    // payload: {status: string, message: string, transactionId: string}
    // transactionId will only be returned if payment was attempted, hence, redirection can happen

    if (payload.status == 'closed') {
      // ignoring payment closed/dismissed callback
      return;
    }

    if (payload.transactionId) {
      removeCartId();

      let toList = [];

      if (!isEmpty(shippingAddress.email)) {
        toList.push({
          name: shippingAddress.name,
          email: shippingAddress.email
        });
      }
      if (
        !isEmpty(billingAddress.email) &&
        billingAddress.email !== shippingAddress.email
      ) {
        toList.push({ name: billingAddress.name, email: billingAddress.email });
      }

      setTimeout(() => {
        sendOrderConfirmationMail({ to: toList, from: 'admin@shoptype.com' });
      }, 0);

      history.replace(`/order-confirmation?checkoutId=${checkoutId}`);
    } else {
      toaster(payload.message || 'Something went wrong!');
    }
  };

  const sendOrderConfirmationMail = async dataObj => {
    try {
      const checkoutDetailsRes = await getCheckoutDetails(checkoutId);

      if (successStatusCodes.includes(checkoutDetailsRes.status)) {
        if (!isEmpty(dataObj.to) && dataObj.from) {
          const requestBody = {
            type: 'email',
            details: {
              to: dataObj.to,
              from: dataObj.from,
              subject: 'A new order has been placed!',
              body: orderConfirmationTemplate(checkoutDetailsRes.data)
            }
          };

          const res = await postWithResponseObject(
            `${API_BASE_URL}/command?type=sendNotification`,
            requestBody,
            {
              'Content-Type': 'application/json'
            }
          );

          if (!successStatusCodes.includes(res.status)) {
            console.error(
              'Failed to send order confirmation mail to vendor as call to /command?type=sendNotification failed'
            );
          }
        } else {
          console.error(
            'Failed to send order confirmation mail to vendor as "to" and "from" is unavailable'
          );
        }
      } else {
        console.error(
          'Failed to send order confirmation mail to vendor as failed to get checkout details'
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const continueToPayment = async () => {
    setDisableContinueToPayment(true);
    setDisablePayment(true);

    await initSTPayment(
      checkoutId,
      API_BASE_URL,
      SHOPTYPE_API_KEY,
      confirmPaymentStatus,
      APP_ENV != APP_ENV_PRODUCTION //useAuthNetSandbox = true, if not production, use AuthNet Sandbox payment
    );

    setTimeout(setDisablePayment(false), 2000);
    updatePaymentStatus({ ...payment, paymentProcess: true });
  };

  const handleExpansionButton = () => {
    toggleExpansionPanel(!expanded);
  };

  const handleShippingOptionChange = async (e, vendorId) => {
    setDisableContinueToPayment(true);

    showSpinnerBackdrop();

    const reqBody = {
      method_key_per_vendor: {
        [vendorId]: {
          method_key: e.target.value
        }
      }
    };

    const res = await updateShippingOption(cartCheckoutId, reqBody);

    if (successStatusCodes.includes(res.status)) {
      updateCosts({
        subTotal: res?.data?.sub_total?.amount || 0.0,
        taxes: res?.data?.taxes?.amount || 0.0,
        shipping: res?.data?.shipping?.amount
          ? parseFloat(res.data.shipping.amount)
          : 0.0,
        total: res?.data?.total?.amount || 0.0
      });

      setCheckoutDetails(res.data);
      generateShippingOptions(res.data);
    }

    setDisableContinueToPayment(false);
  };

  const editShippingInfo = () => {
    setDisableContinueToPayment(false);
    updatePaymentStatus({ ...payment, paymentProcess: false });
  };

  return (
    <Container>
      {!isLoading ? (
        <RowWrap>
          <MobileView>
            <MobileContent>
              <ShoptypeLogoMobile src={ShoptypeLogoImage} />
            </MobileContent>
            <ExpansionButton onClick={handleExpansionButton}>
              <MobileContent>
                <ExpansionButtonContent>
                  <ButtonMainLabel>
                    <i className="fas fa-shopping-checkoutDetails" />
                    &emsp;
                    {expanded ? 'Hide order summary' : 'Show order summary'}
                    &nbsp;
                    <i
                      className={
                        expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
                      }
                    />
                  </ButtonMainLabel>
                  <ButtonSecondaryLabel>
                    <Currency currencyName={currency} />
                    {costs.total?.toFixed(2)}
                  </ButtonSecondaryLabel>
                </ExpansionButtonContent>
              </MobileContent>
            </ExpansionButton>
          </MobileView>
          <ColWrap>
            <BuyerInfo>
              <BuyerContent>
                <ShoptypeLogo src={ShoptypeLogoImage} />
                {formEditMode ? (
                  <BuyerShippingBillingInput>
                    {requiresShippping && (
                      <ShippingInfo>
                        <Heading>Shipping Address</Heading>
                        <AddressInput
                          addressType="shipping"
                          addressDataFeed={shippingAddress}
                          addressDataFetch={addressDataFetch}
                          updateFormFields={updateFormFields}
                          countries={countries}
                          countriesCode={countriesCode}
                          states={shippingAdddressInfo.statesList}
                          statesCode={shippingAdddressInfo.statesCode}
                          fetchAndUpdateStatesData={fetchAndUpdateStatesData}
                        />
                      </ShippingInfo>
                    )}
                    <BillingInfo>
                      <Heading>Billing Address</Heading>
                      {requiresShippping && (
                        <div>
                          <CheckBox
                            label="Same as Shipping Address"
                            value={billingSameAsShipping}
                            onChange={e =>
                              setBillingSameAsShipping(e.target.checked)
                            }
                          />
                        </div>
                      )}
                      {!billingSameAsShipping ? (
                        <AddressInput
                          addressType="billing"
                          addressDataFeed={billingAddress}
                          addressDataFetch={addressDataFetch}
                          updateFormFields={updateFormFields}
                          countries={countries}
                          countriesCode={countriesCode}
                          states={billingAddressInfo.statesList}
                          statesCode={billingAddressInfo.statesCode}
                          fetchAndUpdateStatesData={fetchAndUpdateStatesData}
                        />
                      ) : null}
                    </BillingInfo>
                    <Continue>
                      <ContinueButton
                        text="Continue"
                        disabled={disableContinue}
                        onClick={() => updateAddresses()}
                      />
                    </Continue>
                  </BuyerShippingBillingInput>
                ) : (
                  <BuyerChooseShippingOptionInput>
                    <>
                      <BuyerSummary>
                        <EditBuyerInfo>
                          <EditBuyerInfoButton
                            text="Edit"
                            onClick={editBuyerInfo}
                          />
                        </EditBuyerInfo>
                        {requiresShippping && (
                          <SummaryBlock>
                            <SummaryBlockHeading>Shipping:</SummaryBlockHeading>
                            <div>
                              <div>{shippingAddress?.name || ''}</div>
                              <div>{shippingAddress?.email || ''}&#44;</div>
                              <div>{shippingAddress?.phone || ''}&#44;</div>
                              <div>
                                {shippingAddress?.address || ''}&#44;&nbsp;
                                <br />
                                {shippingAddress?.city || ''}&#44;&nbsp;
                                {shippingAddress?.state || ''}&#44;
                                <br />
                                {shippingAddress?.country || ''}&#44;&nbsp;
                                {shippingAddress?.postalCode || ''}
                              </div>
                            </div>
                            <div />
                          </SummaryBlock>
                        )}
                        <SummaryBlock>
                          <SummaryBlockHeading>Billing:</SummaryBlockHeading>
                          <div>
                            {billingSameAsShipping ? (
                              <div>Same as Shipping</div>
                            ) : (
                              <>
                                <div>{billingAddress?.name || ''}</div>
                                <div>{billingAddress?.email || ''}&#44;</div>
                                <div>{billingAddress?.phone || ''}&#44;</div>
                                <div>
                                  {billingAddress?.address || ''}&#44;&nbsp;
                                  <br />
                                  {billingAddress?.city || ''}&#44;&nbsp;
                                  {billingAddress?.state || ''}&#44;
                                  <br />
                                  {billingAddress?.country || ''}&#44;&nbsp;
                                  {billingAddress?.postalCode || ''}
                                </div>
                              </>
                            )}
                          </div>
                          <div />
                        </SummaryBlock>
                      </BuyerSummary>
                      {requiresShippping && (
                        <ShippingOptionsCard>
                          {payment.paymentProcess && (
                            <EditBuyerInfo>
                              <EditBuyerInfoButton
                                text="Edit"
                                onClick={editShippingInfo}
                              />
                            </EditBuyerInfo>
                          )}
                          <SummaryBlockHeading>
                            {!payment.paymentProcess
                              ? 'Select Shipping Option'
                              : 'Selected Shipping Options'}
                            :
                          </SummaryBlockHeading>
                          {!isEmpty(choosenShippingOptions) &&
                          !isEmpty(checkoutDetails?.order_details_per_vendor)
                            ? Object.keys(
                                choosenShippingOptions.method_key_per_vendor
                              ).map((vendorId, index) => (
                                <VendorShippingOptions key={index}>
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                      By&nbsp;
                                      {checkoutDetails
                                        ?.order_details_per_vendor?.[vendorId]
                                        ?.cart_lines[0]?.vendor_name || '—'}
                                      :
                                    </FormLabel>
                                    <RadioGroup
                                      value={
                                        choosenShippingOptions
                                          ?.method_key_per_vendor?.[vendorId]
                                          ?.method_key || '—'
                                      }
                                      onChange={e =>
                                        handleShippingOptionChange(e, vendorId)
                                      }
                                    >
                                      {checkoutDetails?.order_details_per_vendor?.[
                                        vendorId
                                      ]?.shipping_options.map(
                                        (shippingOption, index) => (
                                          <FormControlLabel
                                            key={index}
                                            value={shippingOption.method_key}
                                            disabled={disableContinueToPayment}
                                            control={<CustomRadio />}
                                            label={shippingOption.shipping_html}
                                          />
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </VendorShippingOptions>
                              ))
                            : 'NA'}
                        </ShippingOptionsCard>
                      )}
                    </>
                    {!payment.paymentProcess ? (
                      <>
                        {payment.readyForPayment ? (
                          <Continue>
                            <ContinueButton
                              text="Continue to Payment"
                              disabled={disableContinueToPayment}
                              onClick={() => continueToPayment()}
                            />
                          </Continue>
                        ) : null}
                      </>
                    ) : (
                      <Continue>
                        <ContinueButton
                          text="Pay Now"
                          disabled={disablePayment}
                          onClick={() => continueToPayment()}
                        />
                      </Continue>
                    )}
                  </BuyerChooseShippingOptionInput>
                )}
              </BuyerContent>
            </BuyerInfo>
            <OrderInfoWrapper
              panelState={expanded ? 'expanded' : 'not-expanded'}
            >
              <OrderInfoContent>
                <ProductsData
                  hideBorderBottom={isEmpty(
                    checkoutDetails?.order_details_per_vendor
                  )}
                >
                  {!isEmpty(checkoutDetails?.order_details_per_vendor)
                    ? Object.keys(checkoutDetails.order_details_per_vendor).map(
                        vendorId => (
                          <>
                            {checkoutDetails.order_details_per_vendor?.[
                              vendorId
                            ].cart_lines.map((productData, i) => (
                              <ProductDetails key={i}>
                                <ProductImageWrapper>
                                  <ProductImageContainer>
                                    <img
                                      src={
                                        productData.image_src ||
                                        DefaultProductImage
                                      }
                                    />
                                  </ProductImageContainer>
                                  <ProductQuantityChip>
                                    {productData.quantity}
                                  </ProductQuantityChip>
                                </ProductImageWrapper>
                                <ProductInformationContainer>
                                  <ProductHeading>
                                    {productData.name}
                                  </ProductHeading>
                                  <ProductVariant>
                                    {productData.variant_name_value
                                      ? Object.values(
                                          productData.variant_name_value
                                        ).join(VARIANT_COMBO_DELIMITER)
                                      : ''}
                                  </ProductVariant>
                                </ProductInformationContainer>
                                <ProductAmountContainer>
                                  <Currency
                                    currencyName={productData.price.currency}
                                  />
                                  {(
                                    parseFloat(
                                      productData.price.amount
                                    ).toFixed(2) * productData.quantity
                                  ).toFixed(2)}
                                </ProductAmountContainer>
                              </ProductDetails>
                            ))}
                            <>
                              <LineItemVendorDetails>
                                By&nbsp;
                                {checkoutDetails?.order_details_per_vendor?.[
                                  vendorId
                                ]?.cart_lines[0]?.vendor_name || '—'}
                              </LineItemVendorDetails>
                              <CostCalculation>
                                <CostType>
                                  <CostTypeHeading>Taxes</CostTypeHeading>
                                  {taxesDataStatus.fetching ? (
                                    <CostTypeMessage>
                                      Calculating...
                                    </CostTypeMessage>
                                  ) : taxesDataStatus.available ? (
                                    <CostTypeAmount>
                                      <Currency
                                        currencyName={
                                          checkoutDetails
                                            ?.order_details_per_vendor?.[
                                            vendorId
                                          ]?.taxes?.currency
                                        }
                                      />
                                      {checkoutDetails?.order_details_per_vendor?.[
                                        vendorId
                                      ]?.taxes?.amount?.toFixed(2)}
                                    </CostTypeAmount>
                                  ) : taxesDataStatus.error ? (
                                    <CostTypeMessage>
                                      Failed to load
                                    </CostTypeMessage>
                                  ) : (
                                    <CostTypeMessage>
                                      Calculated at next step
                                    </CostTypeMessage>
                                  )}
                                </CostType>
                                <CostType>
                                  <CostTypeHeading>Shipping</CostTypeHeading>
                                  <CostTypeMessage>
                                    {shippingDataStatus.fetching ? (
                                      <CostTypeMessage>
                                        Calculating...
                                      </CostTypeMessage>
                                    ) : shippingDataStatus.available ? (
                                      <CostTypeAmount>
                                        {isEmpty(
                                          checkoutDetails
                                            ?.order_details_per_vendor?.[
                                            vendorId
                                          ]?.shipping_selected
                                        ) ? (
                                          'Not Available'
                                        ) : (
                                          <>
                                            <Currency
                                              currencyName={
                                                checkoutDetails
                                                  ?.order_details_per_vendor?.[
                                                  vendorId
                                                ]?.shipping_selected?.total
                                                  ?.currency
                                              }
                                            />
                                            {checkoutDetails?.order_details_per_vendor?.[
                                              vendorId
                                            ]?.shipping_selected?.total?.amount?.toFixed(
                                              2
                                            )}
                                          </>
                                        )}
                                      </CostTypeAmount>
                                    ) : shippingDataStatus.error ? (
                                      <CostTypeMessage>
                                        Failed to load
                                      </CostTypeMessage>
                                    ) : (
                                      <CostTypeMessage>
                                        Calculated at next step
                                      </CostTypeMessage>
                                    )}
                                  </CostTypeMessage>
                                </CostType>
                              </CostCalculation>
                            </>
                          </>
                        )
                      )
                    : 'No products found'}
                </ProductsData>
                <CostCalculation>
                  <CostType calcStatus={costs.subTotal > 0}>
                    <CostTypeHeading>Subtotal</CostTypeHeading>
                    <CostTypeAmount>
                      <Currency currencyName={currency} />
                      {costs.subTotal.toFixed(2)}
                    </CostTypeAmount>
                  </CostType>
                  <CostType>
                    <CostTypeHeading>Taxes</CostTypeHeading>
                    {taxesDataStatus.fetching ? (
                      <CostTypeMessage>Calculating...</CostTypeMessage>
                    ) : taxesDataStatus.available ? (
                      <CostTypeAmount>
                        <Currency currencyName={currency} />
                        {costs.taxes?.toFixed(2)}
                      </CostTypeAmount>
                    ) : taxesDataStatus.error ? (
                      <CostTypeMessage>Failed to load</CostTypeMessage>
                    ) : (
                      <CostTypeMessage>Calculated at next step</CostTypeMessage>
                    )}
                  </CostType>
                  <CostType>
                    <CostTypeHeading>Shipping</CostTypeHeading>
                    <CostTypeMessage>
                      {shippingDataStatus.fetching ? (
                        <CostTypeMessage>Calculating...</CostTypeMessage>
                      ) : shippingDataStatus.available ? (
                        <CostTypeAmount>
                          {requiresShippping ? (
                            <>
                              <Currency currencyName={currency} />
                              {costs.shipping?.toFixed(2)}
                            </>
                          ) : (
                            'Not Available'
                          )}
                        </CostTypeAmount>
                      ) : shippingDataStatus.error ? (
                        <CostTypeMessage>Failed to load</CostTypeMessage>
                      ) : (
                        <CostTypeMessage>
                          Calculated at next step
                        </CostTypeMessage>
                      )}
                    </CostTypeMessage>
                  </CostType>
                </CostCalculation>
                <TotalCostContainer>
                  <CostType type="total" calcStatus={costs.total > 0}>
                    <TotalCostHeading>Total</TotalCostHeading>
                    <TotalCostAmount>
                      <Currency currencyName={currency} />
                      {costs.total?.toFixed(2)}
                    </TotalCostAmount>
                  </CostType>
                </TotalCostContainer>
              </OrderInfoContent>
            </OrderInfoWrapper>
          </ColWrap>
        </RowWrap>
      ) : (
        <Loader
          message="Creating your Order..."
          isFlex={true}
          w={'100%'}
          min_h={'100vh'}
          isCenter={true}
        />
      )}
    </Container>
  );
};

CheckoutV2.propTypes = {
  classes: object,
  showSpinnerBackdrop: func,
  hideSpinnerBackdrop: func
};

const StyledCheckoutV2 = withStyles(styles)(CheckoutV2);

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(StyledCheckoutV2));
