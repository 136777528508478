import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toaster } from '../../atoms/toaster';
import history from '../../../history';
import { API_BASE_URL } from '../../../config/env';
import qs from 'qs';
import {
  postWithResponseObject,
  successStatusCodes,
  isPasswordValid,
  showApiErrorToast
} from '../../../utils';
import {
  Container,
  Password,
  ConfirmPassword,
  SaveButton,
  Save
} from './styles';

class ResetPasswordTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: ''
    };
  }

  componentDidMount() {
    if (
      !qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      }).token
    ) {
      history.push('/');
    }
  }

  handlePasswordChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  keyPressHandler = e => {
    if (e.key === 'Enter') {
      this.submit();
    }
  };

  validPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    if (!newPassword && !confirmPassword) {
      toaster('Please enter new password and confirm password.');
      return false;
    } else if (!newPassword) {
      toaster('Please enter new password.');
      return false;
    } else if (!confirmPassword) {
      toaster('Please enter confirm password.');
      return false;
    } else if (newPassword !== confirmPassword) {
      toaster('New password and confirm password does not match.');
      return false;
    } else if (!isPasswordValid(newPassword)) {
      toaster('Password should contain at least 6 characters.');
      return false;
    } else {
      return true;
    }
  };

  submit = async () => {
    const token = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    }).token;

    if (this.validPassword() && token) {
      const reqBody = { password: this.state.newPassword };
      const headers = {
        'Content-type': 'application/json',
        Authorization: token
      };
      let res = await postWithResponseObject(
        `${API_BASE_URL}/reset-password`,
        reqBody,
        headers
      );

      if (
        successStatusCodes.includes(res.status) &&
        res.data &&
        res.data.status &&
        res.data.status === 'success'
      ) {
        history.push({
          pathname: '/users/message',
          search: '?type=password_reset_success'
        });
      } else {
        showApiErrorToast(res.data);
      }
    }
  };

  render() {
    const { newPassword, confirmPassword } = this.state;
    return (
      <Container>
        <Password
          id="newPassword"
          name="newPassword"
          type="password"
          placeHolder="New Password"
          value={newPassword}
          onChange={this.handlePasswordChange}
          autocomplete="off"
          onKeyPress={event => {
            this.keyPressHandler(event);
          }}
        />
        <ConfirmPassword
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          placeHolder="Confirm Password"
          value={confirmPassword}
          onChange={this.handlePasswordChange}
          autocomplete="off"
          onKeyPress={event => {
            this.keyPressHandler(event);
          }}
        />
        <SaveButton>
          <Save onClick={this.submit} text="Save" />
        </SaveButton>
      </Container>
    );
  }
}

ResetPasswordTab.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(ResetPasswordTab);
