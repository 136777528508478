import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SignInTab from '../../molecules/SignInTab';
import PrivacyInformation from '../../atoms/privacyInformation';

import {
  Container,
  Heading,
  SubHeading,
  VendorContainer,
  Footer
} from './styles';
import { COSELLER } from '../../../constants';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCoseller: false,
      isSignIn: true
    };
  }

  componentDidMount() {
    let checkLoginType = localStorage.getItem('USER_TYPE');
    if (checkLoginType === COSELLER) {
      this.setState({ isCoseller: true });
    }
  }

  render() {
    const { isSignIn } = this.state;
    return (
      <Container>
        <Heading>Welcome Back</Heading>
        <SubHeading>Good to see you again</SubHeading>
        <VendorContainer>
          <SignInTab isSignIn={isSignIn} />
        </VendorContainer>
        <Footer>
          <PrivacyInformation isSignUp={false} />
        </Footer>
      </Container>
    );
  }
}

SignIn.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(SignIn);
