import React from 'react';
import { withRouter } from 'react-router-dom';
import { toaster } from '../../../atoms/toaster';
import update from 'immutability-helper';
import history from '../../../../history';
import DefaultProductImage from '../../../../assets/images/photo.svg';
import { API_BASE_URL } from '../../../../config/env';
import EffectiveNetworkCommission from './effectiveNetworkCommission';
import * as actions from '../../../../redux/actions/uiActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'qs';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  postWithResponseObject,
  putWithResponseObject,
  deleteWithResponseObject,
  resourceNotFoundStatusCode,
  fetchAttributionLevels
} from '../../../../utils';
import {
  Container,
  InnerContainer,
  AttributionContainer,
  AttributionConfigContainer,
  HeadingSection,
  Heading,
  SaveButtonContainer,
  Row,
  Image,
  ProductHeading,
  ClearAttributionButtonContainer,
  UpdateButtonContainer,
  ErrorText
} from './styles';
import { isEmpty } from 'lodash';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Button, Tooltip, Zoom } from '@material-ui/core';
import {
  ATTRIBUTION,
  NETWORK_OPERATOR_INFO
} from '../../../../constants/tooltip';
import {
  ChartContainer,
  FloatingContainer,
  SubHeading
} from '../../../templates/Attribution/styles';
import STProgressBar from '../../../common/STProgressBar';
import EmptyState from '../../../atoms/EmptyState';
class Attribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAttributionSet: false,
      attributionId: '',
      networkId: '',
      disableUpdateButton: false,
      disableClearAttributionButton: false,
      resetAttribution: false,
      productLevelAttribution: false,
      productDetails: {
        id: '',
        title: '',
        imageSrc: ''
      },
      attributionData: {
        attributionConfig: {
          configs: {
            lIntro: {
              percentage: '',
              timeLimit: '',
              timeLimitUnit: 'infinite'
            },
            lX: {
              percentage: '',
              timeLimit: '',
              timeLimitUnit: 'infinite'
            }
          }
        }
      },
      showError: false,
      showExpireTimeError: false,
      attributionSum: 0,
      invalidData: false
    };
  }

  componentDidMount = () => {
    if (this.isProductAttribution()) {
      this.setProductAttribution();
    } else {
      this.fetchNetworkDetails();
    }
  };

  fetchNetworkDetails = async () => {
    const token = getAuthToken();
    const headers = { Authorization: token };
    this.props.showSpinnerBackdrop();
    const response = await getApiResponseObject(
      `${API_BASE_URL}/networks`,
      headers
    );
    this.props.hideSpinnerBackdrop();

    if (successStatusCodes.includes(response.status)) {
      const networkId = response?.data?.network?.id;

      if (!isEmpty(networkId)) {
        let { attributionData } = this.state;
        //creating 10 levels in attribution
        attributionData = fetchAttributionLevels(attributionData, 10);
        const newAttributionData = update(attributionData, {
          networkId: { $set: networkId }
        });
        this.setState(
          { attributionData: newAttributionData, networkId },
          () => {
            this.fetchAttributionData();
          }
        );
      } else {
        return toaster('unable to set network id');
      }
    } else {
      return toaster('unable to fetch network details');
    }
  };

  getQueryParam = item => {
    try {
      return qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      })[item];
    } catch (err) {
      console.log(err);
    }
  };

  isProductAttribution = () => {
    const product = this.getQueryParam('product');
    const id = this.getQueryParam('id');
    return product && product === 'yes' && id && id.length > 0;
  };

  setProductAttribution = async () => {
    let newProductDetails = this.state.productDetails;

    try {
      const product = this.getQueryParam('product');
      const id = this.getQueryParam('id');
      const title = this.getQueryParam('title');
      const imageSrc = this.getQueryParam('imageSrc');

      newProductDetails = update(newProductDetails, {
        id: { $set: id ? id : null },
        title: { $set: title ? title : null },
        imageSrc: { $set: imageSrc ? imageSrc : null }
      });

      const productLevelAttribution =
        product && product === 'yes' && id && id.length > 0;

      this.setState(
        {
          productLevelAttribution,
          productDetails: newProductDetails
        },
        () => {
          let { attributionData } = this.state;
          const newAttributionData = update(attributionData, {
            productId: { $set: id }
          });
          this.setState({ attributionData: newAttributionData });

          this.fetchAttributionData();
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  checkValidAttribution = data => {
    let keys = Object.keys(data.attributionConfig.configs);
    if (keys.includes('l1') && !keys.includes('l10')) {
      return false;
    } else {
      return true;
    }
  };

  fetchAttributionData = async () => {
    const { productLevelAttribution, productDetails, networkId } = this.state;
    let token = getAuthToken();
    const headers = { authorization: token };

    let URL = '';
    if (productLevelAttribution && productDetails.id.length > 0) {
      URL = `${API_BASE_URL}/product-attribution/${productDetails.id}`;
    } else {
      URL = `${API_BASE_URL}/network-attribution/${networkId}`;
    }

    this.props.showSpinnerBackdrop();
    const response = await getApiResponseObject(URL, headers);
    this.props.hideSpinnerBackdrop();

    if (successStatusCodes.includes(response.status)) {
      if (this.checkValidAttribution(response.data.data)) {
        response.data.data.id
          ? this.setState({
              isAttributionSet: true,
              disableClearAttributionButton: false,
              attributionId: response.data.data.id,
              invalidData: false,
              attributionData: this.generateAttributionData(
                response.data.data,
                'set'
              )
            })
          : this.setState({
              isAttributionSet: false,
              disableClearAttributionButton: true
            });
      } else {
        this.setState({ invalidData: true });
      }
    } else if (response.status !== resourceNotFoundStatusCode) {
      this.setState({
        isAttributionSet: false,
        disableClearAttributionButton: true
      });
    }
  };

  clearAttribution = async () => {
    const { productDetails } = this.state;

    const token = getAuthToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token
    };

    this.setState({
      disableUpdateButton: true,
      disableClearAttributionButton: true
    });
    let response = await deleteWithResponseObject(
      `${API_BASE_URL}/product-attribution/${productDetails.id}`,
      headers,
      {}
    );
    this.setState({ disableUpdateButton: false });

    if (successStatusCodes.includes(response.status)) {
      this.resetAttribution();
      this.setState({ isAttributionSet: false, resetAttribution: true });
      this.setState({ resetAttribution: false });
      return toaster('Attribution details cleared');
    } else {
      this.setState({ disableClearAttributionButton: false });
      return toaster(response.data.message);
    }
  };

  resetAttribution = () => {
    let attributionData = {
      attributionConfig: {
        configs: {
          networkCommission: {
            percentage: ''
          },
          lIntro: {
            percentage: '',
            timeLimit: '',
            timeLimitUnit: 'infinite'
          },
          lX: {
            percentage: '',
            timeLimit: '',
            timeLimitUnit: 'infinite'
          }
        }
      }
    };
    attributionData = fetchAttributionLevels(attributionData, 10);

    if (this.state.productLevelAttribution) {
      attributionData.productId = this.state.productDetails.id;
    } else {
      attributionData.networkId = this.state.networkId;
    }

    this.setState({ attributionData });
  };

  saveAttributionConfig = async () => {
    if (!this.attributionDataComplete()) {
      return toaster(
        'At least one of the Effective Network Fee Division level is required'
      );
    }
    if (!this.minAttributionPercentageSet()) {
      return toaster(
        'Summation of all the Effective Network Fee Divisions must be equal to 100%'
      );
    }
    const {
      attributionId,
      isAttributionSet,
      attributionData,
      productLevelAttribution,
      productDetails
    } = this.state;
    let requestBody = this.generateAttributionData(attributionData, 'get');
    const token = getAuthToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token
    };
    let URL = '';
    if (productLevelAttribution && productDetails.id.length > 0) {
      URL = `${API_BASE_URL}/product-attribution`;
    } else {
      URL = `${API_BASE_URL}/network-attribution`;
    }
    let response = '';
    if (isAttributionSet) {
      this.props.showSpinnerBackdrop();
      this.setState({
        disableUpdateButton: true,
        disableClearAttributionButton: true
      });
      response = await putWithResponseObject(
        `${URL}/${attributionId}`,
        requestBody,
        headers
      );
      this.setState({
        disableUpdateButton: false,
        disableClearAttributionButton: false
      });
      this.props.hideSpinnerBackdrop();
    } else {
      this.props.showSpinnerBackdrop();
      this.setState({
        disableUpdateButton: true,
        disableClearAttributionButton: true
      });
      if (productLevelAttribution && productDetails.id.length > 0) {
        requestBody = { ...requestBody, productId: productDetails.id };
      }
      response = await postWithResponseObject(URL, requestBody, headers);
      this.setState({
        disableUpdateButton: false,
        disableClearAttributionButton: false
      });
      this.props.hideSpinnerBackdrop();
    }
    if (successStatusCodes.includes(response.status)) {
      if (!isAttributionSet && response.data.data.id) {
        let { attributionData } = this.state;
        const newAttributionData = update(attributionData, {
          id: { $set: response.data.data.id }
        });
        this.setState({
          isAttributionSet: true,
          attributionId: response.data.data.id,
          attributionData: newAttributionData
        });
      }
      return toaster('Attribution details updated successfully');
    } else {
      return toaster(response.data.message);
    }
  };

  generateAttributionData = (attributionData, type) => {
    let newAttributionData = attributionData;

    Object.keys(attributionData.attributionConfig.configs).forEach(item => {
      if (
        attributionData.attributionConfig.configs[item].percentage === '' ||
        attributionData.attributionConfig.configs[item].percentage === 0
      ) {
        newAttributionData = update(newAttributionData, {
          attributionConfig: {
            configs: {
              [item]: {
                percentage: {
                  $set: type === 'set' ? '' : 0
                }
              }
            }
          }
        });
      }
      if (
        attributionData.attributionConfig.configs[item].timeLimit === '' ||
        attributionData.attributionConfig.configs[item].timeLimit === null
      ) {
        newAttributionData = update(newAttributionData, {
          attributionConfig: {
            configs: {
              [item]: {
                timeLimit: {
                  $set: type === 'set' ? '' : null
                }
              }
            }
          }
        });
      }
    });

    return newAttributionData;
  };

  setAttributionData = data => {
    this.restructureAttributionData(data);
  };

  restructureAttributionData = data => {
    let { attributionData } = this.state;
    let newAttributionData = attributionData;
    let numberOfLevels = data.levels.length;
    newAttributionData = fetchAttributionLevels(
      newAttributionData,
      numberOfLevels
    );
    Object.keys(data).forEach(item => {
      if (item === 'levels') {
        data[item].forEach(
          detail =>
            (newAttributionData = update(newAttributionData, {
              attributionConfig: {
                configs: {
                  [detail.shortName]: {
                    percentage: {
                      $set: detail.active
                        ? detail.splitValue !== ''
                          ? Number(detail.splitValue)
                          : ''
                        : ''
                    },
                    timeLimit: {
                      $set: detail.active ? detail.timeLimit : null
                    },
                    timeLimitUnit: { $set: detail.option.toLowerCase() }
                  }
                }
              }
            }))
        );
      } else {
        data[item].forEach(
          detail =>
            (newAttributionData = update(newAttributionData, {
              attributionConfig: {
                configs: {
                  [detail.shortName]: {
                    percentage: {
                      $set:
                        detail.splitValue !== ''
                          ? Number(detail.splitValue)
                          : ''
                    },
                    timeLimit: { $set: detail.timeLimit },
                    timeLimitUnit: { $set: detail.option.toLowerCase() }
                  }
                }
              }
            }))
        );
      }
    });

    this.setState({ attributionData: newAttributionData });
  };

  attributionDataComplete = () => {
    const { attributionData } = this.state;
    const configs = Object.keys(attributionData.attributionConfig.configs);

    for (let i = 0; i < configs.length; i++) {
      if (
        attributionData.attributionConfig.configs[configs[i]].percentage !== ''
      ) {
        return true;
      }
    }
    return false;
  };

  minAttributionPercentageSet = () => {
    const { attributionData } = this.state;
    const configs = Object.keys(attributionData.attributionConfig.configs);
    let percentagesSum = 0;

    configs.forEach(config => {
      percentagesSum +=
        attributionData.attributionConfig.configs[config].percentage !== ''
          ? attributionData.attributionConfig.configs[config].percentage
          : 0;
    });

    return percentagesSum === 100;
  };
  updateErrorFromEffectiveNetwork = value => {
    this.setState({
      showError: value
    });
  };
  updateExpireTimeErrorFromEffectiveNetwork = value => {
    this.setState({
      showExpireTimeError: value
    });
  };
  updateAttributionSum = sum => {
    this.setState({ attributionSum: sum });
  };
  render() {
    const {
      attributionData,
      isAttributionSet,
      disableUpdateButton,
      disableClearAttributionButton,
      productLevelAttribution,
      productDetails,
      resetAttribution,
      showError,
      showExpireTimeError,
      attributionSum,
      invalidData
    } = this.state;
    return (
      <Container>
        <InnerContainer>
          <AttributionContainer>
            <Row>
              <div
                style={{
                  width: productLevelAttribution ? '60%' : '100%',
                  position: 'relative'
                }}
              >
                <HeadingSection>
                  <Heading>Configure Attributions</Heading>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={NETWORK_OPERATOR_INFO.pageDescription.Attribution}
                    arrow
                  >
                    <InfoRoundedIcon />
                  </Tooltip>
                </HeadingSection>
              </div>
              {productLevelAttribution && (
                <div style={{ width: '40%', paddingLeft: '16px' }}>
                  <Row justifyContent="flex-start">
                    <Image
                      alt={productDetails.title}
                      src={productDetails.imageSrc || DefaultProductImage}
                    />
                    <ProductHeading>{productDetails.title}</ProductHeading>
                  </Row>
                </div>
              )}
            </Row>
            {!invalidData ? (
              <AttributionConfigContainer>
                <FloatingContainer>
                  <HeadingSection>
                    <SubHeading>Attribution Fee Breakup</SubHeading>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={ATTRIBUTION.Network_Fee_Division}
                      arrow
                    >
                      <InfoRoundedIcon />
                    </Tooltip>
                    {showError && (
                      <ErrorText>
                        (Effective network fee division cannot exceed 100%)
                      </ErrorText>
                    )}
                    {showExpireTimeError && (
                      <ErrorText>(Expire time cannot be blank)</ErrorText>
                    )}
                    <ChartContainer>
                      <STProgressBar
                        bgcolor={'#F0D64E'}
                        completed={attributionSum}
                      />
                    </ChartContainer>
                  </HeadingSection>
                </FloatingContainer>

                <EffectiveNetworkCommission
                  attributionData={attributionData}
                  isAttributionSet={isAttributionSet}
                  sendData={this.setAttributionData}
                  resetAttribution={resetAttribution}
                  updateErrorFromEffectiveNetwork={
                    this.updateErrorFromEffectiveNetwork
                  }
                  updateAttributionSum={this.updateAttributionSum}
                  updateExpireTimeErrorFromEffectiveNetwork={
                    this.updateExpireTimeErrorFromEffectiveNetwork
                  }
                />
                <SaveButtonContainer>
                  {productLevelAttribution && (
                    <ClearAttributionButtonContainer>
                      <Button
                        onClick={this.clearAttribution}
                        disabled={disableClearAttributionButton}
                      >
                        RESET
                      </Button>
                    </ClearAttributionButtonContainer>
                  )}
                  <UpdateButtonContainer>
                    <Button
                      onClick={this.saveAttributionConfig}
                      disabled={
                        disableUpdateButton || showError || showExpireTimeError
                      }
                    >
                      UPDATE
                    </Button>
                  </UpdateButtonContainer>
                </SaveButtonContainer>
              </AttributionConfigContainer>
            ) : (
              <EmptyState errorMessage="Incorrect Attribution Data" />
            )}
          </AttributionContainer>
        </InnerContainer>
      </Container>
    );
  }
}

Attribution.propTypes = {
  history: PropTypes.object.isRequired,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Attribution));
