import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const TabContent = styled.div``;

const Message = styled.div`
  margin: 8px 0;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
`;

const Section = styled.div`
  margin: 12px 0;
`;

const ConnectionCardWrapper = styled.div`
  width: 100%;
  margin: 24px 0;
  display: flex;
  flex-wrap: wrap;
`;

const NotAvailableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 140px;
  margin: 12px 0;
`;

const SectionHeading = styled.div`
  margin: 8px 0;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  color: ${props => props.theme.SONIC_SILVER};
`;

const ErrorMessageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export {
  Container,
  HeadingSection,
  Heading,
  TabContent,
  Message,
  Section,
  ConnectionCardWrapper,
  NotAvailableWrapper,
  SectionHeading,
  ErrorMessageContainer
};
