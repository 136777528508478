import React from 'react';
import LoginType from '../LoginType';
import { Container, VendorContainer, ExitIcon } from './styles';
import Icons from '../../atoms/icons';
import { logout } from '../../../utils';

const LoginSelection = () => {
  return (
    <Container>
      <ExitIcon onClick={logout}>
        <Icons name="exit" width={20} height={20} />
      </ExitIcon>
      <VendorContainer>
        <LoginType />
      </VendorContainer>
    </Container>
  );
};

export default LoginSelection;
