import React from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../atoms/loader';
import { isEmpty } from 'lodash';
import { VENDOR, COSELLER, NETWORK_OPERATOR } from '../../../constants';
import { object, string } from 'prop-types';
import { API_BASE_URL } from '../../../config/env';
import UserNode from './userNode';

import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  getUserType
} from '../../../utils';

import {
  Container,
  ContentSection,
  Content,
  Heading,
  ErrorMessageContainer,
  ChartContainer,
  UserNodesContainer,
  NotAvailableMessage,
  UserNodeLabel,
  Row
} from './styles';

function NotAvailable({ message }) {
  return <NotAvailableMessage>{message}</NotAvailableMessage>;
}

class Referrals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routeUserType: '',
      isLoading: true,
      dataAvailable: false,
      referralsData: {}
    };
  }

  componentDidMount() {
    this.setRouteUserType();
    this.fetchRelationships();
  }

  setRouteUserType = () => {
    this.setState({ routeUserType: getUserType() });
  };

  fetchRelationships = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const res = await getApiResponseObject(
      `${API_BASE_URL}/referrals`,
      headers
    );

    if (successStatusCodes.includes(res.status)) {
      this.setState({
        referralsData: res.data.data,
        isLoading: false,
        dataAvailable: true
      });
    } else {
      this.setState({
        isLoading: false,
        dataAvailable: false
      });
    }
  };

  formatTime = time => {
    return time;
  };

  render() {
    const {
      routeUserType,
      isLoading,
      dataAvailable,
      referralsData
    } = this.state;

    return (
      <Container>
        <ContentSection>
          {!isLoading && dataAvailable && !isEmpty(referralsData) ? (
            <Content>
              <Heading>Referral Tree</Heading>
              <ChartContainer>
                <UserNodesContainer>
                  <UserNodeLabel>Inviter</UserNodeLabel>
                  {referralsData?.inviter?.email?.length > 0 ? (
                    <Row>
                      <UserNode
                        data={referralsData.inviter}
                        nodeType="inviter"
                      />
                    </Row>
                  ) : (
                    <NotAvailable message="Inviter not available" />
                  )}
                </UserNodesContainer>
                <UserNodesContainer>
                  <UserNodeLabel>You</UserNodeLabel>
                  {referralsData?.me?.email?.length > 0 ? (
                    <Row>
                      <UserNode data={referralsData.me} nodeType="me" />
                    </Row>
                  ) : (
                    <NotAvailable message="Your Information is not available" />
                  )}
                </UserNodesContainer>
                <UserNodesContainer>
                  <UserNodeLabel>Invitees</UserNodeLabel>
                  {referralsData?.invitees?.length > 0 ? (
                    <Row>
                      {referralsData.invitees.map((invitee, index) => (
                        <UserNode
                          key={index}
                          data={invitee}
                          nodeType="invitee"
                        />
                      ))}
                    </Row>
                  ) : (
                    <NotAvailable message="Invitees not available" />
                  )}
                </UserNodesContainer>
              </ChartContainer>
            </Content>
          ) : (
            !isLoading &&
            !dataAvailable && (
              <ErrorMessageContainer>
                Relationship information unavailable.
              </ErrorMessageContainer>
            )
          )}
          {isLoading && (
            <Loader
              message="Loading..."
              isFlex={true}
              w={'100%'}
              isCenter={true}
              min_h={'100vh'}
            />
          )}
        </ContentSection>
      </Container>
    );
  }
}

Referrals.propTypes = {
  location: object
};

NotAvailable.propTypes = {
  message: string
};

export default withRouter(Referrals);
