import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, get } from 'lodash';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import {
  DOMAIN,
  PLAYTABLE,
  VENDOR,
  IMAGE,
  IMAGES,
  VIDEO,
  VIDEOS,
  PRODUCT_VIEW,
  VARIANT_COMBO_DELIMITER,
  GLOBAL_EVENT_KEYS
} from '../../../constants';
import {
  getApiResponseObject,
  getAuthToken,
  postWithResponseObject,
  successStatusCodes,
  clearId,
  getCartId,
  showApiErrorToast,
  currencySymbol
} from '../../../utils';

import { createNewCart, addToCart, getCart } from '../../../apis/cart';
import Loader from '../../atoms/loader';
import DefaultProductImage from '../../../assets/images/photo.svg';
import { toaster } from '../../atoms/toaster';
import history from '../../../history';
import Icons from '../../atoms/icons';
import CheckoutCart from '../../organisms/CheckoutCart';
import ReactPlayer from 'react-player';
import Logo from '../../../assets/images/logo.png';
import Carousel from '../../organisms/Carousel';
import Path from '../../atoms/path';
import './app.css';
import {
  API_BASE_URL,
  APP_BASE_URL,
  APP_ENV,
  APP_ENV_DEV,
  APP_ENV_BETA
} from '../../../config/env';
import getDeviceId from './generateBuyerSignature';
import Currency from '../../molecules/Currency';
import * as actions from '../../../redux/actions/productDetailActions';
import qs from 'qs';
import { openSTLoginModal } from 'st-login-handler';
import theme from '../../../theme/index';

import {
  Container,
  InnerContainer,
  ProductContainer,
  PrimaryDetails,
  ImagesContainer,
  MainMediaContainer,
  NoDisplayImage,
  ProductMainVideoContainer,
  MainImage,
  SubImagesContainer,
  BasicDetails,
  HeadingAndEditContainer,
  Heading,
  Description,
  Price,
  CurrentPrice,
  PreviousPrice,
  Discount,
  NoStockMessage,
  BuyShareOptions,
  SecondaryDetails,
  VariantStock,
  TertiaryDetails,
  QuotedReview,
  FeaturesContainer,
  TechnicalSpecifications,
  UsageDetailsContainer,
  UsageContent,
  UsageTextContainer,
  UsageTextPoints,
  UsageMediaContainer,
  UsageImage,
  AdditionalDetails,
  FAQContainer,
  FAQElement,
  FAQQuestion,
  FAQAnswer,
  ShareButton,
  BuyNowButton,
  TestimonyIcon,
  Testimony,
  MainLogo,
  SubContainer,
  MobileView,
  MobileViewContainer,
  PriceData,
  BuyShareData,
  AdminBuyNowButton,
  ShoptypeLogo,
  Row,
  PriceInformation,
  VendorName,
  AvailableVariants,
  VariantOptionTypeContainer,
  VariantOptionValueContainer,
  PrimaryVariant,
  VariantOptionTypeHeading
} from './styles';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { withStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';

const styles = () => ({
  clampText: {
    WebkitLineClamp: 6,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word'
  },

  readMoreBtn: {
    color: 'rgba(69, 0, 217, 0.9)',
    border: 'none',
    background: 'transparent',
    width: 'fit-content',
    padding: '10px',
    paddingLeft: 0
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '20px 0',
    flexWrap: 'wrap',

    '@media (max-width: 768px)': {
      justifyContent: 'center'
    }
  },

  cardBtn: {
    textTransform: 'none',
    minHeight: '28px',
    maxHeight: '55px',
    borderRadius: '2.5px',
    padding: '12px 7px',
    color: theme.default.BLACK,
    fontWeight: 'normal',
    flex: '1 1 auto',

    '@media (max-width:640px)': {
      fontSize: '12px',
      height: '24px'
    }
  },

  buyBtn: {
    background: theme.default.WHITE,
    border: `1px solid ${theme.default.RONCHI}`,
    minWidth: '120px',
    maxWidth: '100%',

    '@media (min-width:1300px)': {
      marginRight: '8px'
    },
    '@media (min-width:768px) and (max-width: 1336px)': {
      marginBottom: '10px'
    }
  },

  cosellBtn: {
    background: theme.default.RONCHI
  },

  adminBuyNowButton: {
    curson: 'unset'
  }
});

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: this.props.match.params.productId,
      userType: '',
      shoptypeUserView: false,
      productVendorDetails: {},
      vendorLogo: '',
      productSource: DOMAIN,
      fetchedProductDetails: {},
      hasVariants: false,
      selectedVariantArray: [],
      isLoading: true,
      shareModal: false,
      productShareLink: '',
      productDisplayMedia: DefaultProductImage,
      productDisplayMediaType: '',
      primaryImage: {},
      mediaStorage: {
        images: {},
        videos: {}
      },
      variantStorage: {},
      variantOptionTypeOptionValuesMapping: {},
      variantDistribution: {},
      additionalImages: [],
      inventory: {},
      totalVariantsAndColors: [],
      availableVariants: [],
      availableSecondOptions: [],
      collatedProductImages: [],
      collatedProductVideos: [],
      selectedProductAvailability: false,
      howitworks: {},
      isCartOpen: false,
      products: [],
      shop: {},
      howItWorks: {
        content: {
          mediaUrl: '',
          text: [],
          type: ''
        }
      },
      sourceName: '',
      variantHeading: 'SIZE',
      slicedDescription: '',
      techspecs: [''],
      details: [''],
      productReferrer: '',
      productUrl: '',
      trackerId: '',
      hideLogo: false,
      cart: {},
      loadingCart: false,
      productOptions: [],
      clampedText: true
    };
  }

  componentDidMount() {
    this.hideShoptypeUrl();
    this.setPageUrl();
    this.fetchProductData().then(() => {
      if (
        !getAuthToken() ||
        (this.props.viewProduct && this.props.viewProduct === 'no')
      ) {
        const productReferrer =
          document?.referrer?.length > 0
            ? document.referrer
            : this.getReferrerUrl();

        this.setState({ productReferrer }, () => {
          return getDeviceId(this.sendUserProductViewEvent);
        });
      }

      const trackerId = qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      }).tid;

      this.setState({
        userType: localStorage.getItem('USER_TYPE') || null,
        trackerId: trackerId
      });

      if (this.props.viewProduct && this.props.viewProduct === 'yes') {
        this.setState({
          shoptypeUserView: true
        });
      }
    });

    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
  }

  hideShoptypeUrl = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.hideShoptypeLogo === 'true') {
      this.setState({ hideLogo: true });
    }
  };

  setPageUrl = () => {
    this.setState({ productUrl: window.location.href });
  };

  fetchProductData = async () => {
    const { productId } = this.state;

    let trackerId = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    }).tid;

    if (!trackerId && this.props.viewProduct == 'no')
      return toaster('Something went wrong!');

    let token = getAuthToken();
    const headers = { authorization: token };
    const response = await getApiResponseObject(
      `${API_BASE_URL}/products/${productId}`,
      headers
    );

    if (response.status !== 200) {
      showApiErrorToast(response.data);
    } else {
      const fetchedProductDetailsNew = response.data;
      if (isEmpty(fetchedProductDetailsNew)) {
        return toaster(
          'Failed to fetch product details, please reload the page'
        );
      }
      this.setState(
        {
          fetchedProductDetails: fetchedProductDetailsNew,
          isLoading: false
        },
        () => {
          let { fetchedProductDetails } = this.state;
          let productSource, vendorLogo, hasVariants;
          const isUserLoggedIn = getAuthToken();
          if (
            isUserLoggedIn &&
            this.props.viewProduct &&
            this.props.viewProduct === 'yes'
          ) {
            this.fetchProductLinkData();
          }
          const sourceName = fetchedProductDetails.sourceName || '';

          productSource = sourceName === 'BLOK-PARTY' ? PLAYTABLE : sourceName;

          hasVariants =
            get(fetchedProductDetails, 'options[0].values[0]') ===
            'Default Title'
              ? false
              : true;

          this.userSessionCartCheckAndUpdate(fetchedProductDetails.sourceName);

          vendorLogo = fetchedProductDetails.vendor
            ? fetchedProductDetails.vendor.logo
            : '';

          let productImages, productVideos;

          if (!isEmpty(fetchedProductDetails.primaryImageSrc.imageSrc)) {
            productImages = [fetchedProductDetails.primaryImageSrc];

            productImages = !isEmpty(fetchedProductDetails.secondaryImageSrc)
              ? productImages.concat(fetchedProductDetails.secondaryImageSrc)
              : productImages;
          } else {
            productImages = fetchedProductDetails.secondaryImageSrc;
          }

          productVideos = !isEmpty(fetchedProductDetails.primaryVideoSrc)
            ? [fetchedProductDetails.primaryVideoSrc]
            : [];

          this.setState(
            {
              productSource: productSource,
              vendorLogo: vendorLogo,
              productVendorDetails: fetchedProductDetails.vendor
                ? fetchedProductDetails.vendor
                : {},
              primaryImage: fetchedProductDetails.primaryImageSrc,
              productOptions: fetchedProductDetails?.options,
              hasVariants
            },
            () => {
              this.pushFetchedMediaDataToMediaStorage(productImages, IMAGES);
              this.pushFetchedMediaDataToMediaStorage(productVideos, VIDEOS);
              this.pushFetchedVariantDataToVariantStorage(
                fetchedProductDetails.options,
                fetchedProductDetails.variants
              );

              if (!isEmpty(fetchedProductDetails.cards)) {
                this.setCardDetails(fetchedProductDetails.cards);
              }
            }
          );

          let description = fetchedProductDetails.description;
          const slicedDescription = description
            ? description.slice(0, 300)
            : '';

          this.setState({
            slicedDescription
          });
        }
      );
    }
    return;
  };

  getCookieStorage = cname => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (const element of ca) {
      let c = element;
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  getReferrerUrl = () => {
    const utmMedium = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    }).utm_medium;

    switch (utmMedium) {
      case 'facebook':
        return 'facebook.com';
      case 'twitter':
        return 'twitter.com';
      case 'pinterest':
        return 'pinterest.com';
      case 'whatsapp':
        return 'whatsapp.com';
      default:
        return '';
    }
  };

  sendUserProductViewEvent = async () => {
    const { productUrl, trackerId, productReferrer } = this.state;
    const deviceId = localStorage.getItem('deviceId') || '';

    const response = await postWithResponseObject(
      `${API_BASE_URL}/track/user-event`,
      {
        device_id: deviceId,
        url: productUrl,
        tracker_id: trackerId,
        referrer: productReferrer
      }
    );

    if (successStatusCodes.includes(response.status)) {
      sessionStorage.setItem('USER_EVENT_STATUS', true);
    } else {
      sessionStorage.setItem('USER_EVENT_STATUS', false);
    }
  };

  clickEdit = () => {
    const { productId } = this.state;
    const editUrl = `${APP_BASE_URL}/edit-product/${productId}`;
    history.push(editUrl.replace(APP_BASE_URL, ''));
  };

  userSessionCartCheckAndUpdate = productSourceName => {
    if (!this.state.shoptypeUserView) {
      if (productSourceName !== this.props.buyProductData.productSourceName) {
        this.props.setProductSourceName(productSourceName);
      }
    }
  };

  pushFetchedMediaDataToMediaStorage = (fetchedMediaArray, mediaType) => {
    let { mediaStorage } = this.state;

    if (mediaType === IMAGES) {
      if (fetchedMediaArray) {
        fetchedMediaArray.forEach(imageElement => {
          mediaStorage.images[imageElement.id] = {
            imageSrc: imageElement.imageSrc,
            variantIds: imageElement.variantIds
          };
        });
      }
    } else if (mediaType === VIDEOS) {
      if (fetchedMediaArray) {
        fetchedMediaArray.forEach(videoElement => {
          mediaStorage.videos[videoElement.id] = {
            videoSrc: videoElement.videoSrc,
            variantIds: videoElement.variantIds
          };
        });
      }
    }
    this.setState({
      mediaStorage,
      collatedProductImages: this.fetchAllImages(),
      collatedProductVideos: this.fetchAllVideos()
    });
  };

  setProductDisplayMedia = (mediaUrl, mediaType) => {
    mediaType = mediaType || IMAGE;

    this.setState({
      productDisplayMedia: mediaUrl,
      productDisplayMediaType: mediaType
    });
  };

  fetchAllImages = () => {
    const { mediaStorage } = this.state;

    if (!isEmpty(mediaStorage.images)) {
      let collatedImagesArray = [];

      Object.keys(mediaStorage.images).forEach(imageId => {
        collatedImagesArray.push(mediaStorage.images[imageId].imageSrc);
      });
      return collatedImagesArray;
    } else {
      return [];
    }
  };

  fetchAllVideos = () => {
    const { mediaStorage } = this.state;

    if (!isEmpty(mediaStorage.videos)) {
      let collatedVideosArray = [];

      Object.keys(mediaStorage.videos).forEach(videoId => {
        if (mediaStorage.videos[videoId])
          collatedVideosArray.push(mediaStorage.videos[videoId].videoSrc);
      });

      return collatedVideosArray;
    } else {
      return [];
    }
  };

  pushFetchedVariantDataToVariantStorage = (options, variants = []) => {
    let {
      variantStorage,
      variantOptionTypeOptionValuesMapping,
      selectedVariantArray
    } = this.state;

    if (options) {
      const variantArrays = [];

      options.forEach((option, index) => {
        variantOptionTypeOptionValuesMapping[option.name] = {
          values: option.values,
          index: index
        };
        variantArrays.push(option.values);
      });

      // all combos available
      let variantNameArrays =
        variantArrays.length > 0 ? this.cartesian(variantArrays) : [];
      let variantNames = variantNameArrays.map(v =>
        v.join(VARIANT_COMBO_DELIMITER)
      );

      // if variants from backend are lesser than all combos available
      if (variants.length != variantNameArrays.length) {
        const comboLength =
          variantArrays.length > 0 ? variantNameArrays[0].length : 0;

        // sort by descending specificity (no of speicifc attributes(variantNameValue))
        variants = variants.sort(
          (a, b) =>
            (b.variantNameValue ? Object.keys(b.variantNameValue).length : 0) -
            (a.variantNameValue ? Object.keys(a.variantNameValue).length : 0)
        );

        for (let variant of variants) {
          const variantNameValues = variant.variantNameValue
            ? Object.keys(variant.variantNameValue)
            : [];

          // if all attributes are specified
          if (variantNameValues.length == comboLength) {
            let variantArray = [];
            let variantName = '';
            Object.keys(variantOptionTypeOptionValuesMapping).forEach(
              optionType => {
                const index =
                  variantOptionTypeOptionValuesMapping[optionType].index;
                variantArray[index] = variant.variantNameValue[optionType];
              }
            );

            variantName = variantArray.join(VARIANT_COMBO_DELIMITER);
            const variantNameIndex = variantNames.indexOf(variantName);
            variantStorage[variantName] = variant;
            variantNameArrays.splice(variantNameIndex, 1);
            variantNames.splice(variantNameIndex, 1);
            continue;
          }

          // if only some attributes are specified
          if (
            variantNameValues.length < comboLength &&
            variantNameValues.length > 0
          ) {
            Object.keys(variantOptionTypeOptionValuesMapping).forEach(
              optionType => {
                let matchingVariantArrays = [];
                const index =
                  variantOptionTypeOptionValuesMapping[optionType].index;
                if (variant.variantNameValue[optionType]) {
                  matchingVariantArrays = matchingVariantArrays.filter(
                    v => v[index] == variant.variantNameValue[optionType]
                  );
                  matchingVariantArrays = matchingVariantArrays.concat(
                    variantNameArrays.filter(
                      v => v[index] == variant.variantNameValue[optionType]
                    )
                  );
                }

                matchingVariantArrays.forEach(variantArray => {
                  const variantName = variantArray.join(
                    VARIANT_COMBO_DELIMITER
                  );
                  const variantNameIndex = variantNames.indexOf(variantName);
                  variantStorage[variantName] = variant;
                  variantNameArrays.splice(variantNameIndex, 1);
                  variantNames.splice(variantNameIndex, 1);
                });
              }
            );

            continue;
          }

          // if no attributes are specified
          if (variantNameValues.length == 0) {
            variantNameArrays.forEach(variantNameArray => {
              const variantName = variantNameArray.join(
                VARIANT_COMBO_DELIMITER
              );
              variantStorage[variantName] = variant;
            });
            variantNameArrays = [];
            variantNames = [];
          }
        }

        // Add remaining variants as unavailable (quantity = -1)
        if (variantNameArrays.length > 0) {
          variantNames.forEach(v => {
            variantStorage[v] = { quantity: -1 };
          });
        }
      } else {
        // if variants from backend are equal to all combos available
        variants.forEach(variant => {
          let variantNameArray = [];
          let variantName = '';
          Object.keys(variantOptionTypeOptionValuesMapping).forEach(
            optionType => {
              if (variant.variantNameValue[optionType]) {
                variantNameArray.push(variant.variantNameValue[optionType]);
              }
            }
          );

          variantName = variantNameArray.join(VARIANT_COMBO_DELIMITER);
          variantStorage[variantName] = variant;
        });
      }
    }

    // select a default variant
    let baseVariant =
      Object.keys(variantStorage).find(
        variantKey => variantStorage[variantKey].quantity > 0
      ) ?? Object.keys(variantStorage)[0];

    selectedVariantArray = baseVariant
      ? baseVariant.split(VARIANT_COMBO_DELIMITER)
      : [];

    this.onVariantSelect(selectedVariantArray);

    this.setState({
      variantStorage,
      variantOptionTypeOptionValuesMapping
    });
  };

  cartesian = args => {
    let r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (let j = 0, l = args[i].length; j < l; j++) {
        let a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i == max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  };

  onVariantSelect = selectedVariantInfo => {
    const { variantStorage, mediaStorage, primaryImage } = this.state;
    let selectedVariantName = selectedVariantInfo.join(VARIANT_COMBO_DELIMITER);
    let associatedImageIds = [];
    if (variantStorage[selectedVariantName]) {
      associatedImageIds = variantStorage[selectedVariantName].imageIds || [];
    } else {
      Object.keys(variantStorage).forEach(variantName => {
        let variantArray = variantName.split(VARIANT_COMBO_DELIMITER);
        if (selectedVariantName.includes(variantArray[0])) {
          selectedVariantInfo = variantArray;
          selectedVariantName = selectedVariantInfo.join(
            VARIANT_COMBO_DELIMITER
          );
          associatedImageIds =
            variantStorage[variantArray.join(VARIANT_COMBO_DELIMITER)]
              .imageIds || [];
        }
      });
    }

    let associatedImage = !isEmpty(associatedImageIds)
      ? mediaStorage.images[associatedImageIds[0]].imageSrc
      : '';

    this.setProductDisplayMedia(
      associatedImage ? associatedImage : primaryImage.imageSrc,
      IMAGE
    );

    this.setState({
      selectedVariantArray: selectedVariantInfo,
      selectedProductAvailability: this.checkProductAvailability(
        selectedVariantName
      )
    });
  };

  checkProductAvailability = selectedVariantName => {
    const { variantStorage } = this.state;

    let stockLeft = 0;
    if (variantStorage[selectedVariantName]) {
      stockLeft = variantStorage[selectedVariantName].quantity || 0;
    }

    const availabilityStatus = stockLeft > 0;
    return availabilityStatus;
  };

  structureProductForCheckout = (rawProductData, variantName) => {
    const { mediaStorage, primaryImage, fetchedProductDetails } = this.state;

    return {
      variantId: rawProductData.id || '',
      heading: fetchedProductDetails.title || '',
      variant: variantName,
      imageUrl: !isEmpty(rawProductData.imageIds)
        ? mediaStorage.images[rawProductData.imageIds[0]].imageSrc
        : primaryImage.imageSrc,
      price: rawProductData.discountedPrice || '',
      quantity: 1,
      weight: {
        unit: rawProductData.weight_unit,
        value: parseFloat(rawProductData.weight)
      },
      dimensions: {
        unit: rawProductData.dimension_unit,
        length: parseFloat(rawProductData.dimensions?.length),
        width: parseFloat(rawProductData.dimensions?.width),
        height: parseFloat(rawProductData.dimensions?.height)
      },
      vendorId: fetchedProductDetails.vendor
        ? fetchedProductDetails.vendor.id
        : ''
    };
  };

  toggleCartLoader = status => {
    this.setState({ loadingCart: status === 'show' ? true : false });
  };

  updateCartDetails = async () => {
    this.toggleCartLoader('show');
    const cartRes = await getCart();
    this.toggleCartLoader('hide');
    if (cartRes?.cart_lines?.length === 0) {
      this.setState({ isCartOpen: false });
    }
    this.setState({ cart: cartRes });
  };

  addProductToCheckoutCart = async variantName => {
    const {
      variantStorage,
      productId,
      variantOptionTypeOptionValuesMapping
    } = this.state;
    const rawProductData = variantStorage[variantName];
    let cartId = getCartId();

    if (!cartId) {
      cartId = await createNewCart();
    }

    if (cartId) {
      this.toggleCartLoader('show');
      const cartRes = await getCart();
      this.toggleCartLoader('hide');

      const variantArray = variantName.split(VARIANT_COMBO_DELIMITER);
      let productVariantValues = {};
      Object.keys(variantOptionTypeOptionValuesMapping).forEach(key => {
        productVariantValues[key] =
          variantArray[variantOptionTypeOptionValuesMapping[key].index];
      });

      const productAlreadyExists =
        cartRes?.cart_lines?.length > 0 &&
        cartRes.cart_lines.some(
          item =>
            item.product_id === productId &&
            item.product_variant_id === rawProductData.id &&
            isEqual(item.variant_name_value, productVariantValues)
        );

      if (productAlreadyExists) {
        this.setState({ isCartOpen: true, cart: cartRes });
      } else {
        const reqBody = {
          product_id: productId,
          product_variant_id: rawProductData.id,
          variant_name_value: productVariantValues,
          quantity: 1
        };

        this.toggleCartLoader('show');
        const res = await addToCart(reqBody);

        if (!successStatusCodes.includes(res.status)) {
          toaster(
            res?.message || res?.data?.message || 'Cannot add product to cart'
          );
        }

        const cartResNew = await getCart();
        this.toggleCartLoader('hide');
        this.setState({
          loadingCart: false,
          isCartOpen: true,
          cart: cartResNew
        });
      }
    } else {
      toaster('Cannot generate cart id');
    }
  };

  handleCloseCart = () => {
    this.setState({
      isCartOpen: false
    });
  };

  closeModal = () => {
    if (JSON.parse(sessionStorage.getItem('isQuickSignUp'))) {
      clearId();
    }
    this.setState({
      shareModal: false,
      modalContent: null
    });
  };

  fetchProductLinkData = async () => {
    const { productId } = this.state;
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/track/publish-slug?productId=${productId}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    }
    this.setState({ productShareLink: `${APP_BASE_URL}${response.data.slug}` });
  };

  handleShare = () => {
    const { fetchedProductDetails, trackerId } = this.state;
    let env = '';

    if (APP_ENV === APP_ENV_DEV) {
      env = 'dev';
    } else if (APP_ENV === APP_ENV_BETA) {
      env = 'beta';
    } else {
      env = 'prod';
    }

    openSTLoginModal(
      {
        name: 'Shoptype.com',
        url: 'https://www.shoptype.com/',
        env,
        tid: trackerId,
        isCosell: true,
        cosell: {
          origin: APP_BASE_URL,
          productId: fetchedProductDetails.id,
          productVendorId: fetchedProductDetails.vendor.id,
          imageUrl: fetchedProductDetails.primaryImageSrc.imageSrc,
          token: getAuthToken() ? getAuthToken() : ''
        }
      },
      () => {
        //callback function is mandatory
        console.log('Open ST Model');
      }
    );
  };

  handleReadMore = () => {
    this.setState(prevState => ({
      clampedText: !prevState.clampedText
    }));
  };

  calculateDiscountPercentage = (discountedPrice, priceParam) => {
    if (priceParam) {
      if (discountedPrice === priceParam) {
        return null;
      } else {
        return `${Math.round(
          ((priceParam - discountedPrice) / priceParam) * 100
        )}% off`;
      }
    } else {
      return null;
    }
  };

  returnStocks = stock => {
    if (this.state.shoptypeUserView)
      return (
        <VariantStock>{isNaN(stock) ? 'NA' : `${stock} left`}</VariantStock>
      );

    return null;
  };

  setCardDetails = cardDetails => {
    const fetchedCardDetails = {};

    cardDetails.forEach(cardElement => {
      let convertedStateCardName = cardElement.heading
        .toLowerCase()
        .split(' ')
        .join('');
      fetchedCardDetails[convertedStateCardName] = cardElement.content;
    });

    this.setState({
      testimony: fetchedCardDetails.testimony
        ? fetchedCardDetails.testimony.text
        : '',
      features: fetchedCardDetails.features
        ? fetchedCardDetails.features.points
        : '',
      techspecs: fetchedCardDetails.technicalspecifications
        ? fetchedCardDetails.technicalspecifications.points
        : '',
      details: fetchedCardDetails.details
        ? fetchedCardDetails.details.points
        : [''],
      howitworks: fetchedCardDetails.howitworks
        ? fetchedCardDetails.howitworks
        : {},
      faq: fetchedCardDetails.faq || []
    });
  };

  currrentProductAvailability = optionValue => {
    const { selectedVariantArray, productOptions } = this.state;
    const variantIndex = productOptions.findIndex(po =>
      po.values.some(o => o == optionValue)
    );
    if (variantIndex != -1) {
      const selectedVariants = Object.assign(selectedVariantArray, {});
      selectedVariants.splice(variantIndex, 1, optionValue);
      return this.checkProductAvailability(
        selectedVariants.join(VARIANT_COMBO_DELIMITER)
      );
    }

    return false;
  };

  render() {
    const {
      isLoading,
      isCartOpen,
      productSource,
      productId,
      fetchedProductDetails,
      hasVariants,
      selectedVariantArray,
      variantStorage,
      productDisplayMedia,
      productDisplayMediaType,
      collatedProductImages,
      collatedProductVideos,
      selectedProductAvailability,
      shareModal,
      modalContent,
      shoptypeUserView,
      testimony,
      techspecs,
      features,
      details,
      howitworks,
      faq,
      productReferrer,
      hideLogo,
      cart,
      loadingCart,
      clampedText,
      variantOptionTypeOptionValuesMapping
    } = this.state;
    const { classes } = this.props;

    const selectedVariantName = selectedVariantArray.join(
      VARIANT_COMBO_DELIMITER
    );

    return (
      <>
        <Container>
          <MainLogo>
            {!isLoading && shoptypeUserView ? null : hideLogo ? (
              <ShoptypeLogo hideLogo={hideLogo} src={Logo} alt="logo" />
            ) : (
              !isLoading && <ShoptypeLogo src={Logo} alt="logo" />
            )}
          </MainLogo>
          <SubContainer>
            <InnerContainer>
              {fetchedProductDetails && !isLoading ? (
                <ProductContainer>
                  <CheckoutCart
                    cart={cart}
                    loadingCart={loadingCart}
                    toggleCartLoader={this.toggleCartLoader}
                    updateCartDetails={this.updateCartDetails}
                    isCartOpen={isCartOpen}
                    client={productSource}
                    sourceId={fetchedProductDetails.sourceId || ''}
                    productId={productId}
                    fetchedProductDetails={fetchedProductDetails}
                    hasVariants={hasVariants}
                    productReferrer={productReferrer}
                    handleCloseCart={this.handleCloseCart}
                  />

                  <PrimaryDetails>
                    <ImagesContainer>
                      <SubImagesContainer>
                        {(!isEmpty(collatedProductImages) &&
                          collatedProductImages.length > 1) ||
                        (!isEmpty(collatedProductVideos) &&
                          collatedProductVideos.length > 1) ? (
                          <Carousel
                            orientation="desktop"
                            elementClick={this.setProductDisplayMedia}
                            imagesArray={collatedProductImages}
                            videosArray={collatedProductVideos}
                            slidesToShow={4}
                          />
                        ) : null}
                      </SubImagesContainer>
                      <MainMediaContainer>
                        {productDisplayMedia ? (
                          productDisplayMediaType === VIDEO ? (
                            <ProductMainVideoContainer>
                              <ReactPlayer
                                url={
                                  fetchedProductDetails.primaryVideoSrc.videoSrc
                                }
                                controls={true}
                                width="400px"
                                playing
                              />
                            </ProductMainVideoContainer>
                          ) : (
                            <MainImage src={productDisplayMedia} />
                          )
                        ) : (
                          <NoDisplayImage>
                            No Images available for this product
                          </NoDisplayImage>
                        )}
                      </MainMediaContainer>
                    </ImagesContainer>
                    <BasicDetails>
                      <HeadingAndEditContainer>
                        <Heading>{fetchedProductDetails.title}</Heading>
                        <VendorName>
                          By {fetchedProductDetails.vendorName}
                        </VendorName>
                      </HeadingAndEditContainer>
                      <Description className={clampedText && classes.clampText}>
                        {fetchedProductDetails.description}
                      </Description>
                      {fetchedProductDetails?.description?.length > 550 && (
                        <button
                          className={classes.readMoreBtn}
                          onClick={this.handleReadMore}
                        >
                          Read {clampedText ? 'More' : 'Less'}
                        </button>
                      )}
                      <AvailableVariants>
                        {!isEmpty(variantOptionTypeOptionValuesMapping)
                          ? Object.keys(
                              variantOptionTypeOptionValuesMapping
                            ).map(optionType => {
                              const optionTypeIndex =
                                variantOptionTypeOptionValuesMapping[optionType]
                                  .index;
                              if (optionType === 'title') {
                                return null;
                              }
                              return (
                                <VariantOptionTypeContainer
                                  key={optionTypeIndex}
                                >
                                  <VariantOptionTypeHeading>
                                    {optionType.toUpperCase()}
                                  </VariantOptionTypeHeading>
                                  <div>
                                    {variantOptionTypeOptionValuesMapping[
                                      optionType
                                    ].values.map(
                                      (optionValue, optionValueIndex) => {
                                        if (optionValue === 'Default Title') {
                                          return null;
                                        } else {
                                          if (optionTypeIndex === 0) {
                                            return (
                                              <VariantOptionValueContainer
                                                key={optionValueIndex}
                                              >
                                                <PrimaryVariant
                                                  isSelected={
                                                    optionValue ===
                                                    selectedVariantArray[
                                                      optionTypeIndex
                                                    ]
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() => {
                                                    const arr = [
                                                      ...selectedVariantArray
                                                    ];
                                                    arr[
                                                      optionTypeIndex
                                                    ] = optionValue;
                                                    this.onVariantSelect(arr);
                                                  }}
                                                >
                                                  {optionValue}
                                                </PrimaryVariant>
                                                {selectedVariantArray[0] ===
                                                optionValue
                                                  ? this.returnStocks(
                                                      variantStorage[
                                                        selectedVariantArray.join(
                                                          VARIANT_COMBO_DELIMITER
                                                        )
                                                      ].quantity
                                                    )
                                                  : null}
                                              </VariantOptionValueContainer>
                                            );
                                          } else {
                                            return (
                                              <PrimaryVariant
                                                key={optionValueIndex}
                                                isSelected={
                                                  optionValue ===
                                                  selectedVariantArray[
                                                    optionTypeIndex
                                                  ]
                                                    ? true
                                                    : false
                                                }
                                                onClick={() => {
                                                  const arr = [
                                                    ...selectedVariantArray
                                                  ];
                                                  arr[
                                                    optionTypeIndex
                                                  ] = optionValue;
                                                  this.onVariantSelect(arr);
                                                }}
                                              >
                                                {optionValue}
                                              </PrimaryVariant>
                                            );
                                          }
                                        }
                                      }
                                    )}
                                  </div>
                                </VariantOptionTypeContainer>
                              );
                            })
                          : null}
                      </AvailableVariants>
                      <div>
                        {variantStorage[selectedVariantName] ? (
                          <PriceInformation>
                            {variantStorage[selectedVariantName].quantity <=
                            0 ? (
                              <NoStockMessage displayMode="Mobile">
                                {variantStorage[selectedVariantName].quantity <
                                0
                                  ? 'This product is not available'
                                  : 'This product is out of stock'}
                              </NoStockMessage>
                            ) : (
                              ''
                            )}
                            {variantStorage[selectedVariantName].quantity !=
                            -1 ? (
                              <React.Fragment>
                                <Price>
                                  <CurrentPrice />
                                  <PreviousPrice>
                                    {this.calculateDiscountPercentage(
                                      variantStorage[selectedVariantName]
                                        .discountedPrice,
                                      variantStorage[selectedVariantName].price
                                    ) && fetchedProductDetails.currency ? (
                                      <Currency
                                        currencyName={
                                          fetchedProductDetails.currency
                                        }
                                      />
                                    ) : (
                                      ''
                                    )}
                                    {variantStorage[selectedVariantName] &&
                                    this.calculateDiscountPercentage(
                                      variantStorage[selectedVariantName]
                                        .discountedPrice,
                                      variantStorage[selectedVariantName].price
                                    )
                                      ? variantStorage[selectedVariantName]
                                          .price
                                        ? `${variantStorage[selectedVariantName].price}`
                                        : null
                                      : null}
                                  </PreviousPrice>
                                  <Discount>
                                    {variantStorage[selectedVariantName]
                                      ? this.calculateDiscountPercentage(
                                          variantStorage[selectedVariantName]
                                            .discountedPrice,
                                          variantStorage[selectedVariantName]
                                            .price
                                        )
                                      : null}
                                  </Discount>
                                </Price>
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </PriceInformation>
                        ) : (
                          <NoStockMessage displayMode="Mobile">
                            {
                              'This product is currently out of stock and unavailable.'
                            }
                          </NoStockMessage>
                        )}
                      </div>
                      <BuyShareOptions>
                        <div className={classes.buttonContainer}>
                          {shoptypeUserView ? (
                            <Button
                              className={`${classes.cardBtn} ${classes.adminBuyNowButton} ${classes.buyBtn}`}
                              disabled
                              color="secondary"
                            >
                              {`Buy for ${
                                fetchedProductDetails?.currency
                                  ? currencySymbol(
                                      fetchedProductDetails?.currency
                                    )
                                  : ''
                              } ${`${
                                variantStorage[selectedVariantName]
                                  ? variantStorage[selectedVariantName]
                                      ?.discountedPrice ?? ''
                                  : ''
                              }`}`}
                            </Button>
                          ) : (
                            <Button
                              className={`${classes.cardBtn} ${classes.buyBtn}`}
                              disabled={
                                !selectedProductAvailability ||
                                fetchedProductDetails?.status === 'disabled'
                              }
                              onClick={() => {
                                this.addProductToCheckoutCart(
                                  selectedVariantName
                                );
                              }}
                            >
                              {`Buy for ${
                                fetchedProductDetails.currency
                                  ? currencySymbol(
                                      fetchedProductDetails.currency
                                    )
                                  : ''
                              } ${`${
                                variantStorage[selectedVariantName]
                                  ? variantStorage[selectedVariantName]
                                      .discountedPrice
                                  : ''
                              }`}`}
                            </Button>
                          )}
                          <Button
                            className={`${classes.cardBtn} ${classes.cosellBtn}`}
                            color="primary"
                            onClick={this.handleShare}
                            disabled={
                              fetchedProductDetails?.status === 'disabled'
                            }
                          >
                            {`Cosell and earn upto ${
                              fetchedProductDetails.currency
                                ? currencySymbol(fetchedProductDetails.currency)
                                : ''
                            } ${
                              fetchedProductDetails &&
                              fetchedProductDetails.productCommission &&
                              fetchedProductDetails.productCommission
                                .percentage &&
                              fetchedProductDetails.productCommission
                                .percentage > 0
                                ? variantStorage[selectedVariantName] &&
                                  variantStorage[selectedVariantName]
                                    .discountedPrice &&
                                  variantStorage[selectedVariantName]
                                    .discountedPrice > 0
                                  ? (
                                      (fetchedProductDetails.productCommission
                                        .percentage /
                                        100) *
                                      variantStorage[selectedVariantName]
                                        .discountedPrice
                                    ).toFixed(2)
                                  : '0'
                                : '0'
                            }`}
                          </Button>
                        </div>
                      </BuyShareOptions>
                    </BasicDetails>
                    {shareModal ? <>{modalContent}</> : null}
                  </PrimaryDetails>
                  <SecondaryDetails />
                  <TertiaryDetails>
                    <div style={{ marginBottom: '20px' }}>
                      {features && features[0] !== '' ? (
                        <FeaturesContainer title="features">
                          <ul>
                            {features.map((featureElement, index) => {
                              if (featureElement) {
                                return <li key={index}>{featureElement}</li>;
                              }
                              return null;
                            })}
                          </ul>
                        </FeaturesContainer>
                      ) : null}
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                      {!isEmpty(techspecs) && techspecs[0] !== '' ? (
                        <TechnicalSpecifications title="specifications">
                          <ul>
                            {techspecs.map((specsElement, index) => {
                              if (!isEmpty(specsElement)) {
                                return <li key={index}>{specsElement}</li>;
                              }
                              return null;
                            })}
                          </ul>
                        </TechnicalSpecifications>
                      ) : null}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      {testimony ? (
                        <Testimony>
                          <TestimonyIcon>
                            <Icons
                              name="testimony-icon"
                              height={178}
                              width={176}
                            />
                          </TestimonyIcon>
                          &nbsp;
                          <QuotedReview>{testimony}</QuotedReview>&nbsp;
                        </Testimony>
                      ) : null}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      {!isEmpty(details) && !isEmpty(details[0]) ? (
                        <div style={{ marginBottom: '20px' }}>
                          <AdditionalDetails title="details">
                            <ul>
                              {details.map((textElement, index) => {
                                if (textElement) {
                                  return <li key={index}>{textElement}</li>;
                                }
                                return null;
                              })}
                            </ul>
                          </AdditionalDetails>
                        </div>
                      ) : null}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      {!isEmpty(howitworks.mediaUrl) ||
                      !isEmpty(howitworks.text) ? (
                        <div style={{ marginBottom: '20px' }}>
                          <UsageDetailsContainer title="how to use">
                            <UsageContent>
                              {!isEmpty(howitworks.text) ? (
                                <UsageTextContainer>
                                  <UsageTextPoints>
                                    <Path content={howitworks.text || []} />
                                  </UsageTextPoints>
                                </UsageTextContainer>
                              ) : null}

                              <UsageMediaContainer>
                                {howitworks.mediaUrl ? (
                                  howitworks.type === IMAGE ? (
                                    <UsageImage src={howitworks.mediaUrl} />
                                  ) : howitworks.type === VIDEO ? (
                                    <ReactPlayer
                                      url={howitworks.mediaUrl}
                                      controls={true}
                                      width="400px"
                                    />
                                  ) : null
                                ) : null}
                              </UsageMediaContainer>
                            </UsageContent>
                          </UsageDetailsContainer>
                        </div>
                      ) : null}
                    </div>

                    {!isEmpty(faq) ? (
                      <div style={{ marginBottom: '20px' }}>
                        <FAQContainer title="faq">
                          <FAQElement>
                            {faq
                              ? faq.map(item => {
                                  return (
                                    <>
                                      <FAQQuestion title={`${item.question}`}>
                                        <FAQAnswer>{item.answer}</FAQAnswer>
                                      </FAQQuestion>
                                    </>
                                  );
                                })
                              : null}
                          </FAQElement>
                        </FAQContainer>
                      </div>
                    ) : null}
                  </TertiaryDetails>
                </ProductContainer>
              ) : (
                <Loader
                  message="Loading your product..."
                  isFlex={true}
                  w={'100%'}
                  isCenter={true}
                  min_h={'100vh'}
                />
              )}
            </InnerContainer>
          </SubContainer>
        </Container>
        <MobileView>
          <MobileViewContainer>
            <Row>
              <>
                {variantStorage[selectedVariantName] ? (
                  <PriceData>
                    {variantStorage[selectedVariantName].quantity <= 0 ? (
                      <NoStockMessage displayMode="Mobile">
                        {variantStorage[selectedVariantName].quantity < 0
                          ? 'Not Available'
                          : 'Out of stock'}
                      </NoStockMessage>
                    ) : (
                      ''
                    )}
                  </PriceData>
                ) : (
                  <NoStockMessage displayMode="Mobile">
                    {'Not Available'}
                  </NoStockMessage>
                )}
              </>
              <Grid container spacing={1} className={classes.buttonContainer}>
                <Grid item>
                  <Button
                    className={`${classes.cardBtn} ${classes.buyBtn}`}
                    color="secondary"
                    disabled={
                      !selectedProductAvailability ||
                      fetchedProductDetails?.status === 'disabled'
                    }
                    onClick={() => {
                      this.addProductToCheckoutCart(selectedVariantName);
                    }}
                  >
                    {`Buy for ${
                      fetchedProductDetails.currency
                        ? currencySymbol(fetchedProductDetails.currency)
                        : ''
                    } ${`${
                      variantStorage[selectedVariantName]
                        ? variantStorage[selectedVariantName].discountedPrice
                        : ''
                    }`}`}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={`${classes.cardBtn} ${classes.cosellBtn}`}
                    color="primary"
                    disabled={fetchedProductDetails?.status === 'disabled'}
                    onClick={this.handleShare}
                  >
                    {`Cosell and earn upto ${
                      fetchedProductDetails &&
                      fetchedProductDetails.productCommission &&
                      fetchedProductDetails.productCommission.percentage &&
                      fetchedProductDetails.productCommission.percentage > 0
                        ? variantStorage[selectedVariantName] &&
                          variantStorage[selectedVariantName].discountedPrice &&
                          variantStorage[selectedVariantName].discountedPrice >
                            0
                          ? (
                              (fetchedProductDetails.productCommission
                                .percentage /
                                100) *
                              variantStorage[selectedVariantName]
                                .discountedPrice
                            ).toFixed(2)
                          : '0'
                        : '0'
                    }`}
                  </Button>
                </Grid>
              </Grid>
            </Row>
          </MobileViewContainer>
        </MobileView>
      </>
    );
  }
}

ProductDetail.propTypes = {
  match: PropTypes.object.isRequired,
  client: PropTypes.object,
  setProductSourceName: PropTypes.func,
  buyProductData: PropTypes.object,
  viewProduct: PropTypes.string
};
const mapStateToProps = state => {
  const { buyProduct } = state;
  return {
    buyProductData: buyProduct
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setProductSourceName: sourceName =>
      dispatch(actions.setProductSourceNameAction(sourceName))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ProductDetail))
);
