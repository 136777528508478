import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { toaster } from '../../atoms/toaster';
import Icons from '../../atoms/icons';
import history from '../../../history';
import { connect } from 'react-redux';
import {
  PASSWORD_PATTERN,
  isEmailValid,
  isPasswordValid,
  isPhoneValid
} from '../../../utils';
import {
  postWithResponseObject,
  getApiResponseObject,
  successStatusCodes,
  isUserNameValid
} from '../../../utils';
import * as actions from '../../../redux/actions/uiActions';
import { API_BASE_URL } from '../../../config/env';
import {
  GOOGLEPROVIDER,
  FACEBOOKPROVIDER,
  DOMAIN,
  AWAKE_REDIRECT,
  FACEBOOKAPPID,
  GOOGLECLIENTID,
  VENDOR,
  COSELLER
} from '../../../constants';
import {
  SignInForm,
  ForgotPassword,
  SignIn,
  AuthButton,
  Container,
  Separator,
  AuthContainer,
  SignInButton,
  MainContainer,
  LoginContainer,
  Email,
  Password,
  SeperatorContainer,
  OR,
  Row,
  VerifyAgain
} from './styles';

class SignInTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      signInStatus: true,
      response: '',
      isLoading: false,
      isCoseller: false,
      socialMediaAccounts: [],
      disableButton: false
    };
  }

  componentDidMount() {
    let checkLoginType = localStorage.getItem('USER_TYPE');
    if (checkLoginType === COSELLER) {
      this.setState({ isCoseller: true });
    }
  }

  awakeMeValidationAndRedirect = () => {
    let receivedToken = this.getAwakeMeRedirectToken();

    if (receivedToken) {
      this.setState(
        {
          isLoading: true
        },
        async () => {
          const profileRes = await this.updateUserProfileToLocalStorage(
            '',
            receivedToken
          );
          if (profileRes.vendors && profileRes.vendors.length) {
            history.push('/dashboard');
          } else {
            history.push('/users/platform/');
          }
        }
      );
    }
  };

  clickAwakeMe = () => {
    window.location.href = AWAKE_REDIRECT;
  };

  getAwakeMeRedirectToken = () => {
    let redirectUrlParams = {};

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
      m,
      key,
      value
    ) {
      redirectUrlParams[key] = value;
    });
    return redirectUrlParams.token;
  };

  responseGoogle = response => {
    let gmailToken = response.tokenId;
    if (response && response.tokenId) {
      if (gmailToken) {
        this.signIn(gmailToken, GOOGLEPROVIDER);
      } else if (response.error) {
        return toaster('Error Connecting to Gmail');
      }
    } else if (response && response.error === 'popup_closed_by_user') {
      return toaster('Error Connecting to Gmail');
    }
  };

  responseFacebook = response => {
    let facebookToken = response.accessToken;
    if (facebookToken) {
      this.signIn(facebookToken, FACEBOOKPROVIDER);
    } else {
      return toaster('Error Connecting to Facebook');
    }
  };

  signIn = async (token, provider) => {
    //const isCoseller = this.state.isCoseller;
    const { email, password } = this.state;
    let response = '';
    if (token) {
      const requestBody = {
        token: token,
        provider: provider,
        domain: DOMAIN
        //...(isCoseller ? { userType: USER_TYPE } : { userType: VENDOR })
      };

      this.props.showSpinnerBackdrop();
      response = await postWithResponseObject(
        `${API_BASE_URL}/social-login`,
        requestBody
      );
      this.props.hideSpinnerBackdrop();
    } else {
      const requestBody = {
        email: email,
        password: password
        //...(isCoseller ? { userType: USER_TYPE } : { userType: VENDOR })
      };

      this.props.showSpinnerBackdrop();
      this.setState({ disableButton: true });
      response = await postWithResponseObject(
        `${API_BASE_URL}/login`,
        requestBody
      );
      this.setState({ disableButton: false });
      this.props.hideSpinnerBackdrop();
    }

    if (successStatusCodes.includes(response.status)) {
      const token =
        (response.data && response.data.data && response.data.data.token) ||
        null;
      const id =
        (response.data && response.data.data && response.data.data._id) || null;
      if (!token || !id) {
        toaster('Something went wrong!');
        return;
      }
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('_id', JSON.stringify(id));
      history.push('/users/platform');
    } else {
      toaster(response.data.message || response.data.error);
    }
  };

  inputChangeHandler = e => {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  inputsAreInvalid = signInStatus => {
    if (signInStatus && !isUserNameValid(this.state.name)) {
      return 'Please enter a valid name.';
    }

    if (signInStatus && !isPhoneValid(this.state.phone)) {
      return 'Please enter a valid phone number.';
    }

    if (!isEmailValid(this.state.email)) {
      return 'Please enter a valid email id.';
    }

    if (signInStatus && !isPasswordValid(this.state.password)) {
      return 'Password should contain at least 6 characters.';
    } else if (!this.state.password) {
      return 'Please enter password.';
    }

    return null;
  };

  keyPressHandler = e => {
    let { signInStatus } = this.state;
    let error = this.inputsAreInvalid(!signInStatus);
    if (!error && e.key === 'Enter') {
      this.signIn();
    } else if (e.key === 'Enter') {
      toaster(error);
    }
  };

  updateUserProfileToLocalStorage = async (id, token) => {
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      localStorage.setItem(
        'userProfile',
        JSON.stringify(profileRes.data || {})
      );
      localStorage.setItem(
        '_id',
        JSON.stringify(id || (profileRes.data && profileRes.data._id) || null)
      );
    } else {
      localStorage.setItem('userProfile', JSON.stringify({}));
    }

    return profileRes.data || {};
  };

  signInSubmitHandler = () => {
    let { signInStatus } = this.state;
    if (signInStatus) {
      let inputValidationError = this.inputsAreInvalid(!signInStatus);

      return !inputValidationError
        ? this.signIn()
        : toaster(inputValidationError);
    } else {
      this.setState({
        email: '',
        password: '',
        signInStatus: true
      });
    }
  };

  handleSignIn = () => {
    let checkLoginType = localStorage.getItem('USER_TYPE');
    const location = {
      pathname: '/users/signin',
      state: { isCoseller: true }
    };
    if (checkLoginType === VENDOR) {
      history.push('/users/signin');
    } else {
      history.push(location);
    }
  };

  handleSignUp = () => {
    history.push('/users/signup');
  };

  handleForgotPassword = () => {
    history.push('/users/forgot-password');
  };

  handleVerifyAgain = () => {
    history.push('/users/resend-verification-email');
  };

  render() {
    const { email, password, disableButton } = this.state;
    const { isSignIn } = this.props;
    return (
      <MainContainer>
        <Container>
          <div>
            <SignInForm onSubmit={this.submitHandler}>
              <Email
                name="email"
                type="email"
                placeHolder="Email"
                id="email"
                value={email}
                onChange={this.inputChangeHandler}
                pattern={PASSWORD_PATTERN}
                autocomplete="off"
                onKeyPress={event => {
                  this.keyPressHandler(event);
                }}
              />

              <Password
                id="password"
                name="password"
                type="password"
                placeHolder="Password"
                value={password}
                onChange={this.inputChangeHandler}
                autocomplete="off"
                onKeyPress={event => {
                  this.keyPressHandler(event);
                }}
              />
              <Row>
                <ForgotPassword onClick={this.handleForgotPassword}>
                  Forgot Password?
                </ForgotPassword>
                <VerifyAgain onClick={this.handleVerifyAgain}>
                  Verify again
                </VerifyAgain>
              </Row>
            </SignInForm>
          </div>
          <SeperatorContainer>
            <Separator />
            <OR>OR</OR>
          </SeperatorContainer>
          <AuthContainer>
            <FacebookLogin
              appId={FACEBOOKAPPID}
              callback={this.responseFacebook}
              render={renderProps => (
                <AuthButton onClick={renderProps.onClick}>
                  <Icons name="facebook-oauth" width={40} height={40} />
                </AuthButton>
              )}
            />
            <GoogleLogin
              clientId={GOOGLECLIENTID}
              render={renderProps => (
                <AuthButton
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <Icons name="google-oauth" width={40} height={40} />
                </AuthButton>
              )}
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </AuthContainer>
        </Container>
        <LoginContainer>
          <SignInButton>
            <SignIn
              onClick={this.signInSubmitHandler}
              text="Sign In"
              disabled={disableButton}
            />
          </SignInButton>
          <SignInButton>
            <SignIn
              onClick={() => this.handleSignUp()}
              text="Sign Up"
              isSignIn={isSignIn}
              disabled={disableButton}
            />
          </SignInButton>
        </LoginContainer>
      </MainContainer>
    );
  }
}

SignInTab.propTypes = {
  history: PropTypes.object.isRequired,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(SignInTab));
