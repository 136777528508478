import styled from 'styled-components';
import { Dashed } from '../../atoms/button';

const DottedOutlineButton = styled(Dashed)`
  min-width: ${props => props.width + 'px' || '540px'};
  min-height: ${props => props.height + 'px' || '150px'};
  width: 100%;
  height: 100%;
  margin-top: 30px;
  border-radius: 5px;
  border: dashed 1px ${props => props.theme.CORDUROY};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  color: ${props => props.theme.CORDUROY};
`;

export { DottedOutlineButton };
