import React, { useEffect, useState } from 'react';
import PrimaryView from '../PrimaryView/';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';
import NavBar from '../../pages/NavBar';
import { getAuthToken } from '../../../utils';
import { makeStyles } from '@material-ui/core/styles';
import SideBar from '../../pages/SideBar';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';
import { isEmpty } from 'lodash';
import history from '../../../history';

const useStyles = makeStyles({
  mainView: {
    width: '100%'
  },
  backdrop: {
    zIndex: 99999,
    color: '#fff'
  },
  STcontainer: {
    display: 'block',
    overflow: 'hidden'
  },
  primaryViewContainer: {
    marginLeft: '100px',
    height: 'calc(100vh - 130px)',
    overflowY: 'scroll',
    width: 'calc(100vw - 100px)'
  },
  sideMarginZero: {
    marginLeft: 0,
    width: '100%'
  },
  navMarginZero: {
    marginTop: 0,
    width: '100%',
    height: '100vh'
  },
  navHeight: {
    height: '85px',
    marginLeft: '100px'
  },
  mL0: {
    marginLeft: 0
  }
});

const Main = props => {
  const classes = useStyles();
  const { showSpinner } = props;

  const [showSidebar, setShowSidebar] = useState(true);
  const [showNavbar, setShowNavbar] = useState(true);
  const [navBarLogo, setNavBarLogo] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const [showBackToDashboard, setShowBackToDashboard] = useState(false);

  useEffect(() => {
    EventsBaseClient.on(
      GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR,
      validateNavbarAndSideBarRoutes
    );
    validateNavbarAndSideBarRoutes();

    return () => {
      EventsBaseClient.removeEventListener(
        GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR,
        validateNavbarAndSideBarRoutes
      );
    };
  }, []);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        } else {
          setLocationKeys(keys => [location.key, ...keys]);
          EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        }
      }
    });
  }, [locationKeys]);

  const validateNavbarAndSideBarRoutes = () => {
    const token = getAuthToken();
    const pathName = window.location.pathname;
    const splittedPathName = window.location.pathname.split('/')[1];

    switch (true) {
      case pathName === '/' && isEmpty(token):
      case splittedPathName === 'users':
      case splittedPathName === 'product':
      case splittedPathName === 'checkout_v2':
        setShowNavbar(false);
        setShowSidebar(false);
        break;

      case pathName === '/edit-profile':
      case pathName === '/profile':
      case pathName === '/network-profile':
        setShowSidebar(false);
        setShowNavbar(true);
        setNavBarLogo(true);
        setShowBackToDashboard(true);
        break;

      case splittedPathName === 'order-confirmation':
        setShowSidebar(false);
        setShowNavbar(true);
        setNavBarLogo(true);
        break;

      default:
        setShowSidebar(true);
        setShowNavbar(true);
        setNavBarLogo(false);
    }
  };

  const token = getAuthToken();

  return (
    <div className={classes.mainView}>
      <Backdrop className={classes.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.STcontainer}>
        {token && showSidebar ? <SideBar /> : null}
        {token && showNavbar ? (
          <div className={`${classes.navHeight} ${navBarLogo && classes.mL0}`}>
            <NavBar
              navBarLogo={navBarLogo}
              allowClick={true}
              showBackToDashboard={showBackToDashboard}
            />
          </div>
        ) : null}
        <div
          className={`${classes.primaryViewContainer} ${!showSidebar &&
            classes.sideMarginZero} ${!showNavbar && classes.navMarginZero}`}
        >
          <PrimaryView />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { showSpinnerBackdrop } = state.uiReducer;
  return { showSpinner: showSpinnerBackdrop };
};

export default compose(connect(mapStateToProps, null))(Main);
