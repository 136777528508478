import styled from 'styled-components';

const Container = styled.div`
  display: block;
`;

const VendorContainer = styled.div`
  margin-top: 20px;
`;

const ExitIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  left: 20px;
  top: -47px;
  cursor: pointer;
`;

export { Container, VendorContainer, ExitIcon };
