import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import { getApiErrorMessage } from '../../utils';

export const MESSAGE_SEVERITY = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
};

const DEFAULT_VALUES = {
  message: 'Snackbar message',
  severity: MESSAGE_SEVERITY.success,
  duration: 3000
};

export const withSnackbar = WrappedComponent => {
  const WithSnackbar = props => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(DEFAULT_VALUES.message);
    const [severity, setSeverity] = useState(
      DEFAULT_VALUES.severity
    ); /** error | warning | info */
    const [duration, setDuration] = useState(DEFAULT_VALUES.duration);

    const showMessage = messageObj => {
      let message = messageObj.error && getApiErrorMessage(messageObj.error);

      if (!message) {
        message = messageObj.message || DEFAULT_VALUES.message;
      }

      setMessage(message);
      setSeverity(messageObj.severity || DEFAULT_VALUES.severity);
      setDuration(messageObj.duration || DEFAULT_VALUES.duration);
      setOpen(true);
    };

    const handleClose = (_, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} showSnackbar={showMessage} />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          autoHideDuration={duration}
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide}
        >
          <Alert
            variant="filled"
            elevation={6}
            severity={severity}
            onClose={handleClose}
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };

  return WithSnackbar;
};
