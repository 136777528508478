import React from 'react';
import {
  DIAL_CODES,
  getCountriesList,
  getCountryLabelsAndDialCodes
} from '../../../constants/dial_codes';
import ReactFlagsSelect from 'react-flags-select';
import TextInputV1 from '../textInputV1';
import TextInput from '../textInput';
import { TextField } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import './styles.css';

class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: 'US',
      phone: ''
    };
  }

  componentDidUpdate(props) {
    //parse and autofill when details are fetched
    if (
      props.phoneValue &&
      this.state.phone == '' &&
      props.phoneValue !=
        this.getFormattedPhoneNo(this.state.selectedCountry, this.state.phone)
    ) {
      try {
        const parsedPhone = parsePhoneNumber(props.phoneValue);
        if (parsedPhone.isValid()) {
          this.setState({
            selectedCountry: parsedPhone.country,
            phone: parsedPhone.nationalNumber
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  getFormattedPhoneNo(countryCode, phone) {
    return `${DIAL_CODES[countryCode].dial_code}${phone}`;
  }

  render() {
    const { phone, selectedCountry } = this.state;
    return (
      <div
        style={
          this.props.material
            ? { display: 'flex', width: '100%', marginRight: '8px' }
            : { width: '320px', display: 'flex' }
        }
      >
        <ReactFlagsSelect
          selected={selectedCountry}
          onSelect={code => {
            this.setState({ selectedCountry: code });
            this.props.callback(this.getFormattedPhoneNo(code, phone));
          }}
          countries={getCountriesList()}
          customLabels={getCountryLabelsAndDialCodes()}
          showSelectedLabel={false}
          fullWidth={false}
          searchable
          className="phone-number-input-container"
          selectButtonClassName={
            this.props.material
              ? 'phone-number-input-select-mat'
              : this.props.border
              ? 'phone-number-input-select-bordered'
              : 'phone-number-input-select'
          }
        />
        {this.props.material ? (
          <TextField
            name="phone"
            type="phone"
            label="Phone Number"
            placeHolder="Phone Number"
            id="phone"
            value={phone}
            style={{ width: '100%' }}
            onChange={e => {
              this.setState({ phone: e.target.value });
              this.props.callback(
                this.getFormattedPhoneNo(selectedCountry, e.target.value)
              );
            }}
            autocomplete="off"
            variant="outlined"
            InputProps={this.props.inputProps}
            InputLabelProps={this.props.inputLabelProps}
          />
        ) : this.props.border ? (
          <TextInputV1
            inputStyle={{ borderBottom: 0, width: '210px' }}
            name="phone"
            type="phone"
            placeHolder="Phone Number"
            id="phone"
            value={phone}
            onChange={e => {
              this.setState({ phone: e.target.value });
              this.props.callback(
                this.getFormattedPhoneNo(selectedCountry, e.target.value)
              );
            }}
            autocomplete="off"
          />
        ) : (
          <TextInput
            inputStyle={{ width: '210px' }}
            inputStyleClass="input-field-style"
            inputClass
            name="phone"
            type="phone"
            placeHolder="Phone Number"
            id="phone"
            value={phone}
            onChange={e => {
              this.setState({ phone: e.target.value });
              this.props.callback(
                this.getFormattedPhoneNo(selectedCountry, e.target.value)
              );
            }}
            autocomplete="off"
          />
        )}
      </div>
    );
  }
}

export default PhoneNumberInput;
