import { Tooltip, Zoom } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/env';
import { GLOBAL_EVENT_KEYS } from '../../../constants';
import { VENDOR_INFO } from '../../../constants/tooltip';
import * as actions from '../../../redux/actions/uiActions';
import {
  getApiResponseObject,
  getAuthToken,
  logout,
  successStatusCodes
} from '../../../utils';
import EmptyState from '../../atoms/EmptyState';
import { toaster } from '../../atoms/toaster';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import ProductLevelAnalytics from './ProductLevelAnalytics';
import { Container, Heading, HeadingSection } from './styles';
import VendorLevelAnalytics from './VendorLevelAnalytics';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchProducts: [],
      isLoading: true,
      isLoadingVendorAnalytics: true,
      isLoadingProductAnalytics: true,
      vendorLevelAnalyticsDataPoints: {},
      productLevelAnalyticsData: [],
      totalCount: 0,
      totalResultsCount: 0,
      fetchParams: {
        count: 10, // set this for pagesize
        offset: 0
      }
    };
  }

  componentDidMount() {
    this.updateUserProfileToLocalStorage();
  }

  updateFetchParams = fetchParams => {
    this.setState({ fetchParams }, () => {
      this.fetchDashboardData(false, false);
    });
  };

  updateUserProfileToLocalStorage = async () => {
    const token = getAuthToken();

    this.props.showSpinnerBackdrop();
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      localStorage.setItem('userProfile', JSON.stringify(profileRes.data));
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.UPDATE_SIDEBAR_CATEGORIES);
      this.fetchDashboardData(true);
    } else {
      logout();
    }
    this.props.hideSpinnerBackdrop();
    return profileRes.data || {};
  };

  fetchDashboardData = (
    updateTotalCount = false,
    fetchVendorAnalytics = true,
    fetchProductAnalytics = true
  ) => {
    const userDetails = JSON.parse(localStorage.getItem('userProfile')) || null;
    const token = getAuthToken();
    const vendorId = userDetails
      ? !isEmpty(userDetails.vendors)
        ? userDetails.vendors[0].id || null
        : null
      : null;
    if (!vendorId)
      return toaster('Unable to fetch data. Vendor details not available');

    if (fetchVendorAnalytics) this.fetchVendorLevelAnalytics(vendorId, token);

    if (fetchProductAnalytics)
      this.fetchProductLevelAnalytics(vendorId, token, updateTotalCount);
  };

  fetchVendorLevelAnalytics = async (vendorId, token) => {
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/vendor-dashboard?viewType=vendorView`,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      const fetchedAnalyticsData = response.data || {};

      this.setState({
        isLoadingVendorAnalytics: false,
        vendorLevelAnalyticsDataPoints: {
          'Total Revenue': fetchedAnalyticsData.total_revenue || 0,
          Orders: fetchedAnalyticsData.total_orders || 0,
          'Items Sold': fetchedAnalyticsData.total_items_sold || 0,
          Clicks: fetchedAnalyticsData.total_clicks || 0,
          Publishes: fetchedAnalyticsData.total_publishes || 0
        }
      });
      this.props.hideSpinnerBackdrop();
    } else {
      this.props.hideSpinnerBackdrop();
      toaster('Failed to load Vendor level Analytics ! Please try again later');
    }
  };

  fetchProductLevelAnalytics = async (
    vendorId,
    token,
    updateTotalCount = false
  ) => {
    const { fetchParams } = this.state;
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/vendor-dashboard?viewType=vendorProductView`,
      headers,
      fetchParams
    );

    if (successStatusCodes.includes(response.status)) {
      this.setState({
        isLoadingProductAnalytics: false,
        productLevelAnalyticsData: response.data || [],
        totalCount: updateTotalCount
          ? response?.headers['total-count'] || 0
          : this.state.totalCount,
        totalResultsCount: response?.headers['total-count'] || 0
      });
    } else {
      this.props.hideSpinnerBackdrop();
      toaster(
        'Failed to load Product level Analytics ! Please try again later'
      );
    }
  };

  render() {
    const {
      isLoadingVendorAnalytics,
      isLoadingProductAnalytics,
      vendorLevelAnalyticsDataPoints,
      productLevelAnalyticsData,
      fetchParams,
      totalCount,
      totalResultsCount
    } = this.state;

    return (
      <Container>
        <HeadingSection>
          <Heading>Dashboard</Heading>
          <Tooltip
            TransitionComponent={Zoom}
            title={VENDOR_INFO.pageDescription.Dashboard}
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </HeadingSection>
        <VendorLevelAnalytics
          isLoading={isLoadingVendorAnalytics}
          dataPoints={vendorLevelAnalyticsDataPoints}
        />
        {!isLoadingProductAnalytics && totalCount > 0 ? (
          <ProductLevelAnalytics
            analyticsData={productLevelAnalyticsData}
            fetchParams={fetchParams}
            totalResultsCount={totalResultsCount}
            updateFetchParams={this.updateFetchParams}
          />
        ) : (
          !isLoadingProductAnalytics && (
            <EmptyState errorMessage="No products to show" />
          )
        )}
      </Container>
    );
  }
}

Dashboard.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Dashboard));
