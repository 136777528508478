import styled, { css } from 'styled-components';
import { Secondary } from '../../atoms/button';
import TextInput from '../../atoms/textInputV1';

const MainContainer = styled.div``;
const SignInForm = styled.form`
  > div {
    border-bottom: unset;
    input {
      width: 320px;
      height: 60px;
      border: solid 1px ${props => props.theme.PINK_SWAN};
      text-align: left;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const ForgotPassword = styled.div`
  margin-top: 20px;
  width: 120px;
  height: 25px;
  font-size: ${props => props.theme.SMALL};
  color: ${props => props.theme.HAVELOCK_BLUE};
  cursor: pointer;
`;

const SignIn = styled(Secondary)`
  margin-right: 20px;
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
  ${props =>
    props.isSignIn &&
    css`
      background-color: ${props => props.theme.WHITE};
      border: 1px solid ${props => props.theme.BLACK};
    `}
`;

const AuthButton = styled.button`
  border: 0;
  max-width: 40px;
  max-height: 40px;
  cursor: pointer;
  outline: none;
  background-color: ${props => props.theme.WHITE};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 500px;
`;

const Separator = styled.hr`
  width: 1px solid ${props => props.theme.PINK_SWAN};
  height: 100%;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: -50px;
`;

const SignInButton = styled.div`
  margin: 20px 4px 0 4px;
`;

const LoginContainer = styled.div`
  display: flex;
`;

const Email = styled(TextInput)`
  input {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    :first-child {
      border-bottom: 0;
    }
  }
`;

const Password = styled(TextInput)`
  input {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const SeperatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OR = styled.div`
  position: relative;
  left: -16px;
  width: 30px;
  height: 30px;
  background-color: ${props => props.theme.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VerifyAgain = styled.div`
  margin-top: 20px;
  width: 120px;
  height: 25px;
  font-size: ${props => props.theme.SMALL};
  color: ${props => props.theme.HAVELOCK_BLUE};
  cursor: pointer;
  text-align: right;
`;

export {
  MainContainer,
  SignInForm,
  ForgotPassword,
  SignIn,
  AuthButton,
  Container,
  Separator,
  AuthContainer,
  SignInButton,
  LoginContainer,
  Email,
  Password,
  SeperatorContainer,
  OR,
  Row,
  VerifyAgain
};
