import * as actions from './index';

export const showSpinnerBackdropAction = () => {
  return dispatch => {
    dispatch(actions.showSpinnerBackdrop());
  };
};

export const hideSpinnerBackdropAction = () => {
  return dispatch => {
    dispatch(actions.hideSpinnerBackdrop());
  };
};
