import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  getAuthToken,
  getApiResponseObject,
  getAuthId,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import { API_BASE_URL } from '../../../../config/env';
import OrdersList from './OrdersList';
import './styles.css';
import { Heading, Container, HeadingSection } from './styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom, Typography } from '@material-ui/core';
import { COSELLER_INFO } from '../../../../constants/tooltip';
import { isEmpty } from 'lodash';
import * as actions from '../../../../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import EmptyState from '../../../atoms/EmptyState';
import { COSELLER, GLOBAL_EVENT_KEYS } from '../../../../constants';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';

const Purchases = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    // Tokenized Login to get the Coseller's Orders directly from Redirection
    let params = new URLSearchParams(document.location.search);
    const token = params.get('access_token');

    if (token) {
      const profileResponse = await getProfileDetails();
      setProfileDetailsToLocalStorage(profileResponse);
    }
    fetchOrders();
  };

  const getProfileDetails = async () => {
    let params = new URLSearchParams(document.location.search);
    let token = params.get('access_token');
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      return profileRes.data;
    } else {
      showApiErrorToast(profileRes.data);
    }
  };

  const setProfileDetailsToLocalStorage = response => {
    localStorage.setItem('USER_TYPE', COSELLER);
    localStorage.setItem('userProfile', JSON.stringify(response));
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
  };

  const fetchOrders = async () => {
    const token = getAuthToken();
    const cosellerId = getAuthId();
    const headers = { authorization: token };

    dispatch(actions.showSpinnerBackdropAction());

    const response = await getApiResponseObject(
      `${API_BASE_URL}/cosellers/${cosellerId}/purchase-orders`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      setIsLoading(false);
      dispatch(actions.hideSpinnerBackdropAction());
      showApiErrorToast(response.data);
    } else {
      setIsLoading(false);
      dispatch(actions.hideSpinnerBackdropAction());
      setOrdersData(response.data?.data);
    }
  };

  return (
    <Container>
      <HeadingSection>
        <Heading>Purchases</Heading>
        <Tooltip
          TransitionComponent={Zoom}
          title={COSELLER_INFO.pageDescription.Purchases}
          arrow
        >
          <InfoRoundedIcon />
        </Tooltip>
      </HeadingSection>
      {!isLoading && !isEmpty(ordersData) ? (
        <>
          <Typography className="textStyle" gutterBottom variant="h6">
            Your orders ({ordersData.length})
          </Typography>
          {ordersData.map((order, index) => (
            <OrdersList
              key={index}
              orderIndex={index + 1}
              orderDate={order.created_at}
              orderNumber={order.external_order_id}
              OrderTotal={order.total.amount}
              returnsCount={order.return_requests_count}
              status={order.status}
              deliveryDate={
                order.history
                  ? order.history[order.history.length - 1].updated_at
                  : order.created_at
              }
              ordersCount={order.cart_lines.length}
              purchasedItems={order.cart_lines}
              currency={order.total.currency}
              shoptypeOrderId={order.shoptypeOrderId}
              source={order.source}
            />
          ))}
        </>
      ) : (
        !isLoading &&
        isEmpty(ordersData) && <EmptyState errorMessage="No orders available" />
      )}
    </Container>
  );
};

export default withRouter(Purchases);
