import React from 'react';
import {
  SubSectionWrapper,
  Card,
  Wrapper,
  ProductInfo,
  PriceInfo,
  ProductImage,
  PriceLabel,
  PriceValue,
  TotalPrice,
  ImageNameWrapper,
  ButtonWrapper
} from '../styles';
import PropTypes from 'prop-types';
import Currency from '../../../molecules/Currency';
import { Filled as CheckoutButton } from '../../../atoms/button';

export default function FinalAmount({ makePayment, finalAmount }) {
  const data = finalAmount.salesOrderResponse;
  const onMakePayment = () => {
    const sendData = {
      order_number: data.order_number,
      payment_method: data.payment_method,
      billingAddress: finalAmount.checkoutFormData.billing.address,
      shippingAddress: finalAmount.checkoutFormData.shipping.address,
      checkout_id: sessionStorage.getItem('checkout_id')
    };
    makePayment(sendData);
  };

  const getPriceInTwoDecimal = price => {
    return price.toFixed(2);
  };

  return (
    <SubSectionWrapper>
      <Card height={'100%'}>
        {data.items &&
          data.items.map((product, i) => (
            <ProductInfo key={i}>
              <ImageNameWrapper>
                <Wrapper>
                  <ProductImage src={product.image} />
                </Wrapper>
                <Wrapper size="14px">
                  {product.name} <b>X</b> {product.quantity}
                </Wrapper>
              </ImageNameWrapper>
              <Wrapper size="18px">
                <Currency />
                {getPriceInTwoDecimal(product.unit_price * product.quantity)}
              </Wrapper>
            </ProductInfo>
          ))}
        <PriceInfo>
          <Wrapper>
            <PriceLabel>SubTotal</PriceLabel>
            <PriceLabel>Shipping</PriceLabel>
            <PriceLabel>Tax</PriceLabel>
          </Wrapper>
          <Wrapper>
            <PriceValue>
              {' '}
              <Currency />
              {getPriceInTwoDecimal(data.subtotal)}
            </PriceValue>
            <PriceValue>
              <Currency />
              {getPriceInTwoDecimal(data.shipping_amount)}
            </PriceValue>
            <PriceValue>
              {' '}
              <Currency />
              {getPriceInTwoDecimal(data.tax_amount)}
            </PriceValue>
          </Wrapper>
        </PriceInfo>
        <TotalPrice>
          <PriceLabel bold size={'18px'}>
            Total
          </PriceLabel>

          <PriceValue bold size={'18px'}>
            <Currency />
            {getPriceInTwoDecimal(data.order_total)}
          </PriceValue>
        </TotalPrice>
      </Card>
      <ButtonWrapper>
        <CheckoutButton
          text={
            finalAmount.isLoading ? '...Processing' : `Pay $${data.order_total}`
          }
          textColor="black"
          backgroundColor="#f0d64e"
          borderColor="#f0d64e"
          size={'large'}
          onClick={onMakePayment}
        />
      </ButtonWrapper>
    </SubSectionWrapper>
  );
}

FinalAmount.propTypes = {
  orderData: PropTypes.object,
  makePayment: PropTypes.func,
  finalAmount: PropTypes.object
};
