import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from './styles';
import icons from './icons.svg';

const Icon = ({ name, ...rest }) => {
  return (
    <Svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      <use xlinkHref={`${icons}#${name}`} />
    </Svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
