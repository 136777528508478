import styled from 'styled-components';

const Container = styled.div`
  display: block;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const SubHeading = styled.span`
  font-size: ${props => props.theme.SMALL};
  margin-bottom: 20px;
`;

const VendorContainer = styled.div`
  margin-top: 20px;
`;

const Footer = styled.div`
  max-width: 360px;
  margin: 18px 0;
`;

export { Container, Heading, SubHeading, VendorContainer, Footer };
