import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const SubHeading = styled.div`
  padding: 8px 0 20px 0;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.LIGHT_FONT};
  max-width: 525px;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;
  text-align: center;
`;

export { Container, HeadingSection, Heading, SubHeading, MessageContainer };
