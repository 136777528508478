import React from 'react';
import TextInput from '../../../atoms/textInputV1';
import styled from 'styled-components';
import GooglePlacesAutocomplete from '../../../molecules/GooglePlacesAutocomplete';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import Select from 'react-select';
import { countryList } from './country';
import { allCountries } from './allCountriesList';
import ReactTelephoneInput from 'react-telephone-input';
import './react-telephone-input.css';

const regex = /^[a-zA-Z ]*$/;
const NameContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  input {
    width: ${props => props.width || '322px'};
  }
  ${props => props.theme.TABLET`
    display: unset;
    input {
      width: 320px;
    }
  `}
`;

const MarginInputContainer = styled.div`
  margin-right: 15px;
  ${props => props.theme.TABLET`
    margin-right: unset;
    margin-top: 10px;
  `}
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const MiscContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  position: relative;
  input {
    width: 660px;
  }
  ${props => props.theme.TABLET`
    display: unset;
    input {
      width: 320px;
    }
    .react-tel-input .country-list {
      max-width: 320px
    }
  `}
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const CountryContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  input {
    width: 210px;
  }

  > div {
    @media screen and (min-width: 768px) {
      position: relative;
      z-index: 2;
    }
    > div {
      width: 210px;
      ${props => props.theme.TABLET`
        width: 320px;
      `}
    }
  }
  ${props => props.theme.TABLET`
    display: unset;
    input {
      width: 320px;
    }
  `}
`;

const FakePlaceHolderContainer = styled.label`
  color: #999;
  position: absolute;
  pointer-events: none;
  display: block;
  top: 20px;
  left: 65px;
  z-index: 1;
  ${props => props.theme.TABLET`
      left: -95px;
  `}
`;

const customStyles = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 20
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 'none',
    height: 60,
    marginRight: window.innerWidth < 769 ? 0 : 15
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition, fontWeight: 'bold' };
  },
  menu: styles => ({ ...styles, zIndex: 999 })
};

class BillingDetailsFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      street1: '',
      state: '',
      postal_code: '',
      country: '',
      phone:
        this.props.type === 'shipping'
          ? this.props.checkoutFormData.shipping.address.phone
          : this.props.checkoutFormData.billing.address.phone,
      countryCode:
        this.props.type === 'shipping'
          ? this.props.checkoutFormData.shipping.address.countryCode
          : this.props.checkoutFormData.billing.address.countryCode,
      countryCodePhone:
        this.props.type === 'shipping'
          ? this.props.checkoutFormData.shipping.address.countryCodePhone
          : this.props.checkoutFormData.billing.address.countryCodePhone,
      stateList: []
    };
  }

  setComponentStates = () => {
    const { checkoutFormData, states } = this.props;
    const data =
      this.props.type === 'shipping'
        ? checkoutFormData.shipping
        : checkoutFormData.billing;
    this.setState({
      firstname: data.firstname || '',
      lastname: data.lastname || '',
      email: data.email || '',
      street1: data.address.street1 || '',
      city: data.address.city || '',
      state: data.address.state || '',
      postal_code: data.address.postal_code || '',
      country: data.address.country || '',
      countryCode: data.address.countryCode || '',
      phone: data.address.phone || '',
      countryCodePhone: data.address.countryCodePhone || '',
      stateList:
        this.props.type === 'shipping' ? states.shipping : states.billing
    });
  };

  setStateList = () => {
    this.setState({
      stateList:
        this.props.type === 'shipping'
          ? this.props.states.shipping
          : this.props.states.billing
    });
  };

  componentDidMount() {
    this.setComponentStates();
    if (
      this.props.type === 'billing' &&
      !isNil(this.props.checkoutFormData.billing.address.country) &&
      this.props.checkoutFormData.billing.address.country !== ''
    ) {
      this.props.fetchStatesFromCountryCode(
        this.props.checkoutFormData.billing.address.countryCode,
        this.props.type
      );
    }

    if (
      this.props.type === 'shipping' &&
      !isNil(this.props.checkoutFormData.shipping.address.country) &&
      this.props.checkoutFormData.billing.address.country !== ''
    ) {
      this.props.fetchStatesFromCountryCode(
        this.props.checkoutFormData.shipping.address.countryCode,
        this.props.type
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.checkoutFormData, this.props.checkoutFormData)) {
      this.setComponentStates();
    }
    if (!isEqual(prevProps.states, this.props.states)) {
      this.setStateList();
    }
  }

  inputChangeHandler = e => {
    if (e.target.name === 'phone' && e.target.value.length > 10) {
      this.setState({
        [e.target.name]: e.target.value.substring(0, 10)
      });
    } else if (e.target.name === 'postal_code' && e.target.value.length > 6) {
      this.setState({
        [e.target.name]: e.target.value.substring(0, 6)
      });
    } else if (
      e.target.name === 'state' ||
      e.target.name === 'city' ||
      e.target.name === 'country'
    ) {
      if (regex.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  handleBlur = e => {
    this.props.handleBlur(e, this.props.type);
  };

  handleCountryChange = selectedOption => {
    this.props.handleSelectChange(
      'country',
      selectedOption.value,
      this.props.type
    );
    this.props.handleSelectChange(
      'countryCode',
      selectedOption.iso2,
      this.props.type
    );
    this.props.fetchStatesFromCountryCode(selectedOption.iso2, this.props.type);
  };

  handleStateChange = selectedOption => {
    this.props.handleSelectChange(
      'state',
      selectedOption.value,
      this.props.type
    );
  };

  handlePhoneCodeBlur = (value, country) => {
    this.props.handleSelectChange(
      'countryCodePhone',
      country.iso2,
      this.props.type
    );

    this.props.handleSelectChange('phone', value, this.props.type);
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      street1,
      city,
      state,
      postal_code,
      country,
      phone,
      stateList,
      countryCodePhone
    } = this.state;
    const { setAddress } = this.props;

    let lowerCountryCode = countryCodePhone
      ? countryCodePhone
          .toString()
          .toLowerCase()
          .trim()
      : 'us';
    let phonePlaceHolderText =
      !phone || phone === '+' || phone === '' ? 'Phone' : '';
    return (
      <>
        <NameContainer>
          <MarginInputContainer>
            <TextInput
              name="firstname"
              type="text"
              placeHolder="First Name"
              id="firstname"
              value={firstname}
              onChange={this.inputChangeHandler}
              autocomplete="disabled"
              onBlur={this.handleBlur}
              required
            />
          </MarginInputContainer>
          <TextInput
            name="lastname"
            type="text"
            placeHolder="Last Name"
            id="lastname"
            value={lastname}
            onChange={this.inputChangeHandler}
            autocomplete="disabled"
            onBlur={this.handleBlur}
            required
          />
        </NameContainer>
        <MiscContainer>
          <ReactTelephoneInput
            preferredCountries={['us', 'ca', 'gb', 'in']}
            flagsImagePath="/flags.png"
            initialValue={phone}
            defaultCountry={lowerCountryCode}
            onChange={this.handlePhoneCodeBlur}
            onlyCountries={allCountries}
          />
          <FakePlaceHolderContainer>
            {phonePlaceHolderText}
          </FakePlaceHolderContainer>
        </MiscContainer>
        <MiscContainer>
          <TextInput
            name="email"
            type="email"
            placeHolder="Email"
            id="email"
            value={email}
            onChange={this.inputChangeHandler}
            autocomplete="disabled"
            onBlur={this.handleBlur}
            required
          />
        </MiscContainer>
        <MiscContainer>
          <GooglePlacesAutocomplete
            name="street1"
            type="text"
            placeHolder="Address"
            value={street1}
            onChange={this.inputChangeHandler}
            autocomplete="disabled"
            required
            setAddress={setAddress}
            updateAddressFields={this.updateAddressFields}
            addressType={this.props.type}
            onBlur={this.handleBlur}
          />
        </MiscContainer>
        <MiscContainer>
          <TextInput
            name="city"
            type="text"
            placeHolder="City"
            id="city"
            value={city}
            onChange={this.inputChangeHandler}
            autocomplete="disabled"
            onBlur={this.handleBlur}
            required
          />
        </MiscContainer>
        <CountryContainer>
          <Select
            value={countryList.filter(option => option.label === country)}
            styles={customStyles}
            onChange={this.handleCountryChange}
            options={countryList}
            placeholder="Country"
          />

          <Select
            value={stateList.filter(option => option.label === state)}
            styles={customStyles}
            onChange={this.handleStateChange}
            options={stateList}
            placeholder="State"
          />

          <TextInput
            name="postal_code"
            type="text"
            placeHolder="Zipcode"
            id="postal_code"
            value={postal_code}
            onChange={this.inputChangeHandler}
            autocomplete="disabled"
            onBlur={this.handleBlur}
            required
          />
        </CountryContainer>
      </>
    );
  }
}

export default BillingDetailsFields;
