/* eslint-disable react/prop-types */
import {
  Avatar,
  Container,
  makeStyles,
  Paper,
  Tab,
  Tabs
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/env';
import { COSELLER, NETWORK_OPERATOR, VENDOR } from '../../../constants';
import * as actions from '../../../redux/actions/uiActions';
import shoptypeThemes from '../../../theme';
import {
  getApiResponseObject,
  getAuthToken,
  getUserType,
  putWithResponseObject,
  showApiErrorToast,
  successStatusCodes,
  uploadImageVideoHandler
} from '../../../utils';
import EmptyState from '../../atoms/EmptyState';
import { toaster } from '../../atoms/toaster';
import CosellerInfo from './CosellerInfo/cosellerInfo';
import ManageTemplates from './ManageTemplates';
import NetworkInfo from './NetworkInfo/networkInfo';
import SMTPConfig from './NetworkInfo/smtpConfig';
import PersonalInfo from './PersonalInfo/personalInfo';
import StoreInfo from './StoreInfo/storeInfo';
import { FileUpload, PanelContent } from './styles';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',

    '@media (max-width:576px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  tabPanel: {
    height: '100%',
    maxWidth: conditionalStyles => conditionalStyles.maxWidth
  },
  tabs: {
    backgroundColor: shoptypeThemes.default.FLORAL_WHITE,
    position: 'sticky',
    top: 0,
    zIndex: 999
  },
  tab: {
    color: shoptypeThemes.default.MINE_SHAFT,
    fontSize: shoptypeThemes.default.X_LARGE,

    '@media (max-width:576px)': {
      fontSize: shoptypeThemes.default.MEDIUM
    }
  },
  selectedTab: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    height: '3px'
  },
  editIcon: {
    cursor: 'pointer',
    color: 'gray',
    marginTop: '140px'
  },
  avatar: {
    height: '150px',
    width: '150px',
    borderRadius: '50%',
    marginTop: '20px'
  },
  profileImage: {
    display: 'flex',
    padding: '20px',
    marginRight: '20px',
    width: '200px',

    '@media (max-width:576px)': {
      marginRight: 0
    }
  },
  tabsWrapper: {
    flex: 1
  },
  paperWidth: {
    maxWidth: conditionalStyles => conditionalStyles.maxWidth,
    boxShadow: 'none'
  }
});

function TabPanel(props) {
  const userType = getUserType();
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
      className={classes.tabPanel}
    >
      {value === index && (
        <PanelContent userType={userType}>{children}</PanelContent>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const ProfileDetails = () => {
  const [user, setUser] = useState('');

  useEffect(() => {
    fetchUserProfileInfo();
  }, []);

  // Conditional stylings
  const userType = getUserType();
  const maxWidth = userType === NETWORK_OPERATOR ? '1000px' : '550px';
  const conditionalStyles = {
    maxWidth: maxWidth
  };
  const classes = useStyles(conditionalStyles);
  // End Conditional stylings

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  // UNIFORM PERSONAL INFO
  const [personalInfo, setPersonalInfo] = useState({});
  // COSELLER
  const [cosellerInfo, setCosellerInfo] = useState({});
  // VENDOR
  const [storeInfo, setStoreInfo] = useState({
    vendor: {
      logo: ''
    }
  });
  // NETWORK OPERATOR
  const [networkInfo, setNetworkInfo] = useState({});
  const [smtpConfig, setSmtpConfig] = useState({});

  // Personal Profile Image
  const [profilePic, setProfilePic] = useState();
  // Vendor store logo
  const [vendorStoreLogo, setVendorStoreLogo] = useState();
  // Network operator logo
  const [networkOperatorBrandLogo, setNetworkOperatorBrandLogo] = useState();
  const [feature, setFeature] = useState('');
  const inputRef = useRef();

  const getUserResponse = async userType => {
    const token = getAuthToken();
    const headers = { authorization: token };
    let response;
    switch (userType) {
      case 'COSELLER':
        response = await getApiResponseObject(
          `${API_BASE_URL}/cosellers`,
          headers
        );
        break;
      case 'VENDOR':
        response = await getApiResponseObject(`${API_BASE_URL}/store`, headers);
        break;
      case 'NETWORK_OPERATOR': {
        response = await getApiResponseObject(
          `${API_BASE_URL}/networks`,
          headers
        );
        break;
      }
      default:
        break;
    }
    return response;
  };

  const setUserResponse = async userType => {
    const userResponse = await getUserResponse(userType);
    if (successStatusCodes.includes(userResponse.status)) {
      switch (userType) {
        case 'COSELLER':
          setCosellerInfo(userResponse.data);
          break;
        case 'VENDOR':
          setStoreInfo(userResponse.data);
          break;
        case 'NETWORK_OPERATOR':
          setNetworkInfo(userResponse.data);
          setSmtpConfig(userResponse.data?.smtp);
          break;
        default:
          break;
      }
    } else {
      switch (userType) {
        case 'COSELLER':
          setCosellerInfo({});
          break;
        case 'VENDOR':
          setStoreInfo({});
          break;
        case 'NETWORK_OPERATOR':
          setNetworkInfo({});
          setSmtpConfig({});
          break;
        default:
          break;
      }
      showApiErrorToast(userResponse?.data);
    }
  };

  useEffect(() => {
    // update the vendor brand logo as soon as the store info gets updated
    setVendorStoreLogo(storeInfo.vendor?.logo);
  }, [storeInfo]);

  useEffect(() => {
    // update the network operator brand logo as soon as the network info gets updated
    setNetworkOperatorBrandLogo(networkInfo?.network?.preview_image_url);
  }, [networkInfo]);

  const fetchUserProfileInfo = async () => {
    const userType = getUserType();
    setUser(userType);
    const token = getAuthToken();
    const headers = { authorization: token };

    if (selectedTabIndex === 0) {
      setFeature('profilePic');
    } else if (selectedTabIndex === 1) {
      userType === COSELLER ? setFeature('profilePic') : setFeature('logo');
    }

    dispatch(actions.showSpinnerBackdropAction());

    // Uniform API for fetching User Profile Info (/me)
    const response = await getApiResponseObject(`${API_BASE_URL}/me`, headers);

    if (!successStatusCodes.includes(response.status)) {
      dispatch(actions.hideSpinnerBackdropAction());
      setLoading(false);
      showApiErrorToast(response.data);
    } else {
      setLoading(false);
      const profilePicture = response.data?.profilePicture;
      setProfilePic(profilePicture);
      setPersonalInfo(response.data);

      // fetch and set Additional Info for Vendor, Coseller and Network-operator
      setUserResponse(userType);

      dispatch(actions.hideSpinnerBackdropAction());
    }
  };

  const postProfilePic = async url => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const userType = getUserType();
    let response;

    if (selectedTabIndex === 0) {
      // Coseller
      const requestBody = url;
      dispatch(actions.showSpinnerBackdropAction());
      response = await putWithResponseObject(
        `${API_BASE_URL}/me`,
        requestBody,
        headers
      );
    } else if (selectedTabIndex === 1) {
      const { vendor } = storeInfo;
      let requestBody;
      switch (userType) {
        case 'COSELLER':
          requestBody = url;
          dispatch(actions.showSpinnerBackdropAction());
          response = await putWithResponseObject(
            `${API_BASE_URL}/me`,
            requestBody,
            headers
          );
          break;

        case 'VENDOR':
          vendor.logo = url.profilePicture;
          requestBody = { vendor };
          dispatch(actions.showSpinnerBackdropAction());
          response = await putWithResponseObject(
            `${API_BASE_URL}/vendors?updateStore=false`,
            requestBody,
            headers
          );
          break;

        case 'NETWORK_OPERATOR':
          requestBody = {
            preview_image_url: url.profilePicture,
            platformId: networkInfo?.platforms[0].id
          };
          dispatch(actions.showSpinnerBackdropAction());
          response = await putWithResponseObject(
            `${API_BASE_URL}/networks`,
            requestBody,
            headers
          );
      }
    }

    if (successStatusCodes.includes(response.status)) {
      setLoading(false);
      dispatch(actions.hideSpinnerBackdropAction());
      toaster('Profile picture updated successfully');
      window.location.reload();
    } else {
      dispatch(actions.hideSpinnerBackdropAction());
      showApiErrorToast(response.data);
    }
  };

  const handleTabChange = (_event, selectedTab) => {
    setSelectedTabIndex(selectedTab);
  };

  const updateProfilePicture = () => {
    if (user === COSELLER) {
      if (selectedTabIndex === 0 || selectedTabIndex === 1) {
        return profilePic;
      }
    } else if (user === VENDOR) {
      if (selectedTabIndex === 0) {
        return profilePic;
      } else if (selectedTabIndex === 1) {
        return vendorStoreLogo;
      }
    } else if (user === NETWORK_OPERATOR) {
      if (selectedTabIndex === 0) {
        return profilePic;
      } else if (selectedTabIndex === 1) {
        return networkOperatorBrandLogo;
      }
    }
  };

  const tabLabels = {
    personal: 'Personal',
    coseller: 'Coseller Info',
    vendor: 'Store Info',
    network: 'Network Info',
    smtp: 'SMTP Details',
    templates: 'Manage Templates'
  };

  const renderTabs = () => {
    let tabs = [
      <Tab
        key={tabLabels.personal}
        className={classes.tab}
        label={tabLabels.personal}
      />
    ];

    if (user === 'COSELLER') {
      tabs.push(
        <Tab
          key={tabLabels.coseller}
          className={classes.tab}
          label={tabLabels.coseller}
        />
      );
    } else if (user === 'VENDOR') {
      tabs.push(
        <Tab
          key={tabLabels.vendor}
          className={classes.tab}
          label={tabLabels.vendor}
        />
      );
    } else {
      tabs.push(
        <Tab
          key={tabLabels.network}
          className={classes.tab}
          label={tabLabels.network}
        />
      );
    }

    if (user === 'NETWORK_OPERATOR') {
      tabs.push(
        <Tab
          key={tabLabels.templates}
          className={classes.tab}
          label={tabLabels.templates}
        />,
        <Tab
          key={tabLabels.smtp}
          className={classes.tab}
          label={tabLabels.smtp}
        />
      );
    }

    return tabs;
  };

  return (
    <Container component="main" className={classes.mainContainer}>
      {!loading && !isEmpty(personalInfo) ? (
        <>
          <div className={classes.profileImage}>
            {selectedTabIndex === 2 || selectedTabIndex === 3 ? null : (
              <>
                <Avatar
                  alt="profile-image"
                  src={updateProfilePicture()}
                  className={classes.avatar}
                />
                <FileUpload
                  type="file"
                  ref={inputRef}
                  accept=".jpg, .jpeg, .png, .bmp"
                  onChange={e =>
                    uploadImageVideoHandler(
                      e,
                      postProfilePic,
                      dispatch,
                      feature
                    )
                  }
                />
                <EditIcon
                  className={classes.editIcon}
                  onClick={() => inputRef.current.click()}
                />
              </>
            )}
          </div>

          <div className={classes.tabsWrapper}>
            <Paper square className={classes.paperWidth}>
              <Tabs
                className={classes.tabs}
                value={selectedTabIndex}
                variant="fullWidth"
                TabIndicatorProps={{ className: classes.selectedTab }}
                onChange={handleTabChange}
              >
                {renderTabs()}
              </Tabs>
            </Paper>
            <TabPanel value={selectedTabIndex} index={0}>
              <PersonalInfo personalInfo={personalInfo} />
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1}>
              {user === COSELLER ? (
                !loading && !isEmpty(cosellerInfo) ? (
                  <CosellerInfo
                    personalInfo={personalInfo}
                    cosellerInfo={cosellerInfo}
                  />
                ) : (
                  <EmptyState
                    errorMessage="No Coseller Details Available"
                    height="30vh"
                  />
                )
              ) : user === VENDOR ? (
                !loading && !isEmpty(storeInfo) ? (
                  <StoreInfo storeInfo={storeInfo} />
                ) : (
                  <EmptyState
                    errorMessage="No Store Details Available"
                    height="30vh"
                  />
                )
              ) : !loading && !isEmpty(networkInfo) ? (
                <NetworkInfo networkInfo={networkInfo} />
              ) : (
                <EmptyState
                  errorMessage="No Network Details Available"
                  height="30vh"
                />
              )}
            </TabPanel>
            {user === NETWORK_OPERATOR && (
              <>
                <TabPanel value={selectedTabIndex} index={2}>
                  <ManageTemplates />
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={3}>
                  <SMTPConfig smtpConfig={smtpConfig} />
                </TabPanel>
              </>
            )}
          </div>
        </>
      ) : (
        !loading && (
          <EmptyState
            errorMessage="Something went wrong."
            message="Please check your internet connection and try again."
            height="70vh"
          />
        )
      )}
    </Container>
  );
};

export default withRouter(ProfileDetails);
