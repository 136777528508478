import * as actions from './index';

export const setProductSourceNameAction = productSourceName => {
  return dispatch => {
    dispatch(actions.setProductSourceName(productSourceName));
  };
};

export const setProductIdAction = productId => {
  return dispatch => {
    dispatch(actions.setProductId(productId));
  };
};

export const setVendorIdAction = vendorId => {
  return dispatch => {
    dispatch(actions.setVendorId(vendorId));
  };
};

export const buyProductAction = cartData => {
  return dispatch => {
    dispatch(actions.buyProductRequest(cartData));
  };
};

export const addToCartAction = cartData => {
  return dispatch => {
    dispatch(actions.addToCart(cartData));
  };
};

export const removeFromCart = data => {
  return dispatch => {
    dispatch(actions.removeFromCart(data));
  };
};

export const changeProductDataInCart = itemIndexObj => {
  return dispatch => {
    dispatch(actions.changeProductDataInCart(itemIndexObj));
  };
};

export const clearCartData = () => {
  return dispatch => {
    dispatch(actions.clearCartData());
  };
};
