import React from 'react';
import moment from 'moment';
import ConfirmationDialog from '../../../atoms/confirmationDialog';
import ProductDetails from '../../../atoms/Orders/ReturnRequests/ProductDetails';
import MaterialTablePagination from '../../../atoms/materialTablePagination';
import CustomShipping from '../../../atoms/customShippingComponent';
import { withStyles } from '@material-ui/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  TableFooter,
  Link,
  Popover,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  NativeSelect
} from '@material-ui/core';
import {
  ORDER_STATUS,
  ORDER_STATUS_LABEL,
  ORDER_ACTION,
  ORDER_ACTION_VALUE,
  REQUEST_APPROVED_SUB_STATUSES,
  ORDER_ACTION_DIALOG_INFORMATION
} from './constants';
import { FlexRowEnd } from './styles';
import { string } from 'prop-types';

// material components styles
const styles = () => ({
  tableBody: {
    opacity: '1',
    transition: 'all .1s linear'
  },
  tableBodyDisabled: {
    opacity: '0.4',
    pointerEvents: 'none'
  },
  imageCell: {
    display: 'flex',
    alignItems: 'center'
  },
  adjustmentsRow: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  adjustmentsData: {
    margin: '0 8px'
  },
  nativeSelect: {
    fontSize: 14
  },
  textField: {
    width: '100%'
  }
});

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:focus': {
      backgroundColor: '#f1d64d',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#ffffff'
      }
    }
  }
}))(MenuItem);

class OrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      popoverAnchorEls: [],
      menuAnchorEls: [],
      confirmationDialog: { open: false, title: '', description: '' },
      currentAction: { action: '', order: {}, allVirtualProducts: false }
    };

    this.reasonInputRef = React.createRef(null);
    this.customShippingAmount = React.createRef(0);
  }

  componentDidMount() {
    if (this.props.orderDetails.length > 0) {
      this.restructureOrderDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.orderDetails.length <= 0 &&
        this.props.orderDetails.length > 0) ||
      (prevProps.fetchingOrders && !this.props.fetchingOrders)
    ) {
      this.restructureOrderDetails();
    }
  }

  restructureOrderDetails = () => {
    const { orderDetails } = this.props;
    this.setState({ rows: orderDetails });
  };

  openPopover = (index, event) => {
    let { popoverAnchorEls } = this.state;
    popoverAnchorEls[index] = event.target;
    this.setState({ popoverAnchorEls });
  };

  closePopover = index => {
    let { popoverAnchorEls } = this.state;
    popoverAnchorEls[index] = null;
    this.setState({ popoverAnchorEls });
  };

  openMenu = (index, event) => {
    let { menuAnchorEls } = this.state;
    menuAnchorEls[index] = event.target;
    this.setState({ menuAnchorEls });
  };

  closeMenu = index => {
    let { menuAnchorEls } = this.state;
    menuAnchorEls[index] = null;
    this.setState({ menuAnchorEls });
  };

  getProductsCount = lineItems => {
    const productIdList = Object.keys(lineItems);
    return productIdList.length;
  };

  getProducts = lineItems => {
    const productIdList = Object.keys(lineItems);
    const productsList = [];

    productIdList.forEach(productId => {
      productsList.push(lineItems[productId]);
    });

    return productsList;
  };

  changeOrderStatus = (e, order) => {
    const value = e.target.value;
    this.setState(
      {
        currentAction: { ...this.state.currentAction, action: value, order }
      },
      () => {
        this.openConfirmationDialog(
          ORDER_ACTION_DIALOG_INFORMATION[value].title,
          ORDER_ACTION_DIALOG_INFORMATION[value].description
        );
      }
    );
  };

  handleOrderAction = (order, actionName) => {
    const productIds = Object.keys(order.line_items);
    let allVirtualProducts = false;

    if (
      productIds.every(id => {
        return order.line_items[id].is_virtual;
      })
    ) {
      allVirtualProducts = true;
    }

    this.setState(
      {
        currentAction: {
          ...this.state.currentAction,
          action: actionName,
          order,
          allVirtualProducts
        }
      },
      () => {
        this.openConfirmationDialog(
          ORDER_ACTION_DIALOG_INFORMATION[actionName].title,
          ORDER_ACTION_DIALOG_INFORMATION[actionName].description
        );
      }
    );
  };

  openConfirmationDialog = (title, description) => {
    this.setState({
      confirmationDialog: { open: true, title, description }
    });
  };

  // handler for confirm dialog action
  handleConfirmAction = () => {
    if (this.state.currentAction.action === ORDER_ACTION.approve) {
      this.approveRequest();
    } else if (this.state.currentAction.action === ORDER_ACTION.decline) {
      this.declineRequest();
    } else if (
      REQUEST_APPROVED_SUB_STATUSES.includes(this.state.currentAction.action)
    ) {
      this.changeRequestStatus();
    }
  };

  approveRequest = () => {
    if (this.props.updateOrderRequest) {
      this.closeConfirmationDialog();
      this.props.updateOrderRequest({
        id: this.state.currentAction.order.id,
        status: this.state.currentAction.allVirtualProducts
          ? ORDER_ACTION_VALUE.refund_initiated
          : ORDER_ACTION_VALUE.approve
      });
    }
  };

  declineRequest = () => {
    if (this.props.updateOrderRequest) {
      this.closeConfirmationDialog();
      this.props.updateOrderRequest({
        id: this.state.currentAction.order.id,
        reason: this.reasonInputRef.current.value || '',
        status: ORDER_ACTION_VALUE.decline
      });
    }
  };

  changeRequestStatus = () => {
    if (this.props.updateOrderRequest) {
      this.closeConfirmationDialog();
      this.props.updateOrderRequest({
        id: this.state.currentAction.order.id,
        customShippingAmount:
          this.state.currentAction.order.status ===
          ORDER_ACTION_VALUE.items_received
            ? {
                amount: parseFloat(this.customShippingAmount.current.value),
                currency:
                  Object.values(this.state.currentAction.order.line_items)
                    .length > 0
                    ? Object.values(
                        this.state.currentAction.order.line_items
                      )[0].price.currency
                    : ''
              }
            : null,
        status: this.state.currentAction.action
      });
    }
  };

  // handler for cancel dialog action
  handleCancelAction = () => {
    this.resetCurrentAction();
    this.closeConfirmationDialog();
  };

  resetCurrentAction = () => {
    this.setState({
      currentAction: { action: '', order: {}, allVirtualProducts: false }
    });
  };

  closeConfirmationDialog = () => {
    this.setState(
      {
        confirmationDialog: { ...this.state.confirmationDialog, open: false }
      },
      () => {
        setTimeout(() => {
          this.setState({
            confirmationDialog: {
              ...this.state.confirmationDialog,
              title: '',
              description: ''
            }
          });
        }, 500);
      }
    );
  };

  render() {
    const {
      confirmationDialog,
      currentAction,
      rows,
      popoverAnchorEls,
      menuAnchorEls
    } = this.state;
    const {
      fetchingOrders,
      classes,
      totalOrdersCount,
      currentPage,
      handleChangePage
    } = this.props;

    return rows.length > 0 ? (
      <>
        {!fetchingOrders && (
          <ConfirmationDialog
            open={confirmationDialog.open}
            title={confirmationDialog.title}
            description={confirmationDialog.description}
            onConfirm={this.handleConfirmAction}
            onClose={this.closeConfirmationDialog}
            onCancel={this.handleCancelAction}
          >
            {currentAction.action === ORDER_ACTION.decline && (
              <TextField
                inputRef={this.reasonInputRef}
                className={classes.textField}
                label="Reason"
                multiline
                rows={2}
                helperText="Decline reason (optional)"
                variant="outlined"
              />
            )}
            {currentAction.order.status ===
              ORDER_ACTION_VALUE.items_received && (
              <CustomShipping
                customShippingAmount={this.customShippingAmount}
              />
            )}
          </ConfirmationDialog>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Request No.</TableCell>
                <TableCell>Order No.</TableCell>
                <TableCell align="left">Date&nbsp;&amp;&nbsp;Time</TableCell>
                <TableCell>No. of Products</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody
              className={`${classes.tableBody} ${
                fetchingOrders ? classes.tableBodyDisabled : null
              }`}
            >
              {rows.map((row, rowIndex) => (
                <TableRow key={row.id}>
                  <TableCell align="left" component="th" scope="row">
                    {row.id || '—'}
                  </TableCell>
                  <TableCell align="left">
                    {row.external_order_id || '—'}
                  </TableCell>
                  <TableCell align="left">
                    {row.created_at
                      ? moment(row.created_at).format('DD[/]MMMM[/]YYYY h:mm A')
                      : '—'}
                  </TableCell>
                  <TableCell align="left">
                    {this.getProductsCount(row.line_items)}&nbsp;
                    <Link
                      component="button"
                      variant="body2"
                      onClick={e => this.openPopover(rowIndex, e)}
                    >
                      (Details)
                    </Link>
                    <Popover
                      id={rowIndex}
                      anchorEl={popoverAnchorEls[rowIndex]}
                      open={Boolean(popoverAnchorEls[rowIndex])}
                      onClose={() => this.closePopover(rowIndex)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      PaperProps={{
                        style: { minWidth: '400px', maxWidth: '480px' }
                      }}
                    >
                      <ProductDetails
                        lineItems={this.getProducts(row.line_items)}
                        status={row.status}
                        returnReason={row.return_reason}
                      />
                    </Popover>
                  </TableCell>
                  <TableCell align="left">
                    {row.status === ORDER_ACTION_VALUE.refund_initiated ||
                    !REQUEST_APPROVED_SUB_STATUSES.includes(row.status) ? (
                      ORDER_STATUS_LABEL[row.status] || row.status || '—'
                    ) : (
                      <NativeSelect
                        className={classes.nativeSelect}
                        value={row.status}
                        onChange={e => this.changeOrderStatus(e, row)}
                      >
                        <option
                          disabled={
                            row.status === ORDER_STATUS.items_received ||
                            row.status === ORDER_STATUS.refund_initiated
                          }
                          value={ORDER_STATUS.pickup_initiated}
                        >
                          Pickup Initiated
                        </option>
                        <option
                          disabled={
                            row.status === ORDER_STATUS.refund_initiated
                          }
                          value={ORDER_STATUS.items_received}
                        >
                          Items Received
                        </option>
                        <option
                          disabled={
                            row.status === ORDER_STATUS.pickup_initiated
                          }
                          value={ORDER_STATUS.refund_initiated}
                        >
                          Refund Initiated
                        </option>
                      </NativeSelect>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row.status === ORDER_STATUS.pending && (
                      <FlexRowEnd>
                        <IconButton
                          size="small"
                          onClick={e => this.openMenu(rowIndex, e)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <StyledMenu
                          id={rowIndex}
                          anchorEl={menuAnchorEls[rowIndex]}
                          keepMounted
                          open={Boolean(menuAnchorEls[rowIndex])}
                          onClose={() => this.closeMenu(rowIndex)}
                        >
                          <StyledMenuItem
                            onClick={() => {
                              this.closeMenu(rowIndex);
                              this.handleOrderAction(row, ORDER_ACTION.approve);
                            }}
                          >
                            <ListItemIcon>
                              <ThumbUpAltIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Approve Request" />
                          </StyledMenuItem>
                          <Divider />
                          <StyledMenuItem
                            onClick={() => {
                              this.closeMenu(rowIndex);
                              this.handleOrderAction(row, ORDER_ACTION.decline);
                            }}
                          >
                            <ListItemIcon>
                              <ThumbDownAltIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Decline Request" />
                          </StyledMenuItem>
                        </StyledMenu>
                      </FlexRowEnd>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <MaterialTablePagination
                  colSpan={6}
                  count={totalOrdersCount}
                  page={currentPage}
                  handleChangePage={handleChangePage}
                  rowsPerPage={6}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    ) : (
      <div>No order details available.</div>
    );
  }
}

export default withStyles(styles)(OrdersTable);
