import {
  Avatar,
  Typography,
  CardContent,
  CardHeader,
  Card,
  CardActions
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ConnectionCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 12px;
`;

const PanelContent = styled.div`
  width: 100%;
  margin: 24px 0px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 10px;
`;

const StyledAvatar = styled(Avatar)`
  &&& {
    height: 100px;
    width: 100px;
    padding: 5px;
    background-color: ${props => props.theme.LIGHT_SILVER};
    border-radius: 50%;

    & .MuiAvatar-img {
      object-fit: ${props => (props.objectfit ? 'cover' : 'none')};
    }
  }

  @media only screen and (max-width: 1420px) {
    &&& {
      height: 95px;
      width: 95px;
    }
  }

  @media only screen and (max-width: 768px) {
    &&& {
      height: 80px;
      width: 80px;
    }
  }
`;

const StyledCardHeader = styled(CardHeader)`
  &&& {
    padding: 16px 16px 0px 16px;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledCardContent = styled(CardContent)`
  &&& {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 161px;
    margin-top: auto;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  word-break: break-word;
`;

const NetworkInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.BLACK};
  height: 20px;
  padding: 10px;
  border-radius: 15px;
  background-color: rgba(255, 216, 0, 0.35);
  margin-right: 5px;
`;

const LocationIcon = styled(LocationOnIcon)`
  &&& {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`;

const MoneyIcon = styled(MoneyOutlinedIcon)`
  &&& {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`;

const DataLabel = styled(Typography)`
  &&& {
    font-size: ${props => props.theme.X_SMALL};
    color: ${props => props.theme.BLUE_GEM};
    font-weight: 500;
  }
`;

const StyledCardActions = styled(CardActions)`
  &&& {
    display: flex;
    padding: 0px 20px 25px 20px;
  }
`;

export {
  ConnectionCardWrapper,
  PanelContent,
  StyledCard,
  StyledAvatar,
  StyledCardHeader,
  StyledCardContent,
  StyledLink,
  NetworkInfo,
  LocationIcon,
  MoneyIcon,
  DataLabel,
  StyledCardActions
};
