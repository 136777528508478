import styled, { css } from 'styled-components';
import { Primary, Secondary } from '../../atoms/button';
import CollapsibleWithIcon from '../../organisms/collapsibleWithIcon';
import Collapsible from '../../atoms/collapsible';

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.SPRING_WOOD};
  ${p => p.theme.TABLET`
    padding: 0px 30px 150px 30px;
    positon: relative;
    z-index: 0;
    overflow: auto;
  `};
`;

const SubContainer = styled.div`
  display: flex;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: block;
`;

const ProductContainer = styled.div`
  padding: 24px 48px;
  ${p => p.theme.TABLET`
    padding: unset;
    margin-left: unset;
    height: fit-content;
  `};
`;

const PrimaryDetails = styled.div`
  display: flex;
  width: 85%;
  margin: 0 auto;
  height: 100%;
  padding-top: 50px;

  ${p => p.theme.TABLET`
    display: unset;
  `};
`;
const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 35px;

  ${p => p.theme.TABLET`
    flex-direction: column-reverse;

  `};
`;

const MainMediaContainer = styled.div`
  width: 419px;
  height: 296px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  background-color: white;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  > img {
    object-fit: contain;
  }
  ${p => p.theme.TABLET`
    display: flex;
    width: initial;
    max-width: 690px;
    align-items: center;
    height: auto;
  `};
`;

const NoDisplayImage = styled.div`
  min-width: 400px;
  width: 100%;
  text-align: center;
`;

const MainImage = styled.img`
  width: 100%;
  overflow: hidden;
  object-fit: contain;
  background-color: ${props => props.theme.WHITE};
`;

const SubImagesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 120px;
  height: 430px;
  > img {
    border-radius: 10px;
    object-fit: contain;
    box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  }
  ${p => p.theme.TABLET`
    width: 100%;
    max-height: 270px;
  `};
`;

const SubImageElementContainer = styled.div`
  > img {
    border-radius: 10px;
    object-fit: contain;
    box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  }
`;

const ProductMainVideoContainer = styled.div`
  width: 100%;
  position: relative;
  & > div > video {
    outline: none;
  }
`;

const BasicDetails = styled.div`
  width: 100%;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;

  ${p => p.theme.TABLET`
    padding: unset;
  `};
`;

const HeadingAndEditContainer = styled.div`
  display: block;
  text-align: right;
  width: 100%;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  text-align: left;
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.LARGE};
    font-weight: ${props => props.theme.MEDIUM_BOLD};
    text-transform: uppercase;
  `}
`;

const Description = styled.div`
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.LIGHT_FONT};
  padding-top: 10px;
  text-align: justify;
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.MEDIUM};
    font-weight: ${props => props.theme.LIGHT_FONT};;
  `}
`;

const SlicedDescription = styled.div`
  max-width: 60px;
  max-height: 25px;
  color: #4a90e2;
  cursor: pointer;
  ${props => props.theme.TABLET`
    max-width: 68px;
  `}
`;

const Category = styled.div`
  margin-top: 40px;
  ${p => p.theme.TABLET`
    margin-top: 60px;
  `};
`;

const ProductNotAvailable = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  color: red;
`;

const PriceInformation = styled.div`
  ${p => p.theme.TABLET`
  display: none;
`}
`;

const Price = styled.div`
  display: flex;
  width: 100%;
  max-width: 350px;
  justify-content: flex-start;
`;

const PreviousPrice = styled.div`
  margin-right: 10px;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  color: ${props => props.theme.SILVER_CHALICE};
  text-decoration: line-through;
`;

const CurrentPrice = styled.div`
  margin-right: 10px;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  ${props => props.theme.TABLET`
    font-size: ${props => props.theme.MEDIUM};
    font-weight: ${props => props.theme.BOLD_FONT};
  `}
`;

const MobileHeading = styled.div`
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
  text-transform: uppercase;
`;

const NoStockMessage = styled.div`
  display: flex;
  width: 100%;
  max-width: 350px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: red;
  font-size: ${props => props.theme.X_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  ${props => props.theme.TABLET`
    display: ${props => (props.displayMode === 'Desktop' ? 'none' : 'flex')};
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 100px;
    margin: 0;
    font-size: ${props => props.theme.SMALL};
    font-weight: ${props => props.theme.BOLD_FONT};
  `}
`;

const Discount = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  color: ${props => props.theme.SILVER_CHALICE};
`;

const BuyShareOptions = styled.div`
  & > button {
    margin-right: 20px;
  }
  ${p => p.theme.TABLET`
    display: none;
  `};
`;

const SecondaryDetails = styled.div`
  margin-top: 50px;
`;

const VariantTypes = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
`;

const VariantOptionTypeContainer = styled.div`
  margin-top: 15px;
  & > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const VariantOptionTypeHeading = styled.div`
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const VariantOptionValueContainer = styled.div`
  min-height: 70px;
`;

const PrimaryVariant = styled.div`
  overflow: hidden;
  border-radius: 3px;
  width: 100px;
  height: 40px;
  text-align: center;
  margin-top: 10px;
  margin-right: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.SMALL};
  border: 1px solid black;
  background-color: ${props => (props.isSelected ? 'black' : 'white')};
  color: ${props => (props.isSelected ? 'white' : 'black')};
  cursor: pointer;
`;

const SecondaryVariant = styled.div`
  display: flex;
  max-width: 150px;
  max-height: 30px;
  min-width: 100px;
  min-height: 30px;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 10px 10px;
`;

const VariantStock = styled.div`
  width: 100%;
  height: 20px;
  width: 50px;
  margin: auto;
  text-align: center;
  width: 76px;
  height: 20px;
  border-radius: 3px;
  background-color: #756200;
  color: white;
  font-size: ${props => props.theme.SMALL};
`;

const SecondOption = styled(CollapsibleWithIcon)`
  width: 100%;
  margin-left: 30px;
`;

const SecondOptionValue = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
`;

const ModalContainer = styled.div`
  background-color: ${props => props.theme.WHITE};
`;

const ModalTopLevelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TertiaryDetails = styled.div`
  width: 100%;
`;

const Cards = styled.div``;

const Testimony = styled.div`
  margin: 20px 0;
`;

const QuotedReview = styled.div`
  width: 100%;
  padding: 50px;
  font-family: Testimony;
  font-size: 30px;
  text-align: center;
  background-color: ${props => props.theme.BLACK};
  color: ${props => props.theme.WHITE};
  border-radius: 10px;
  box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.1);
  ${p => p.theme.TABLET`
    font-size: 20px;
  `};
`;

const FeaturesContainer = styled(CollapsibleWithIcon)`
  padding: 30px;
  & > ul > li {
    font-size: ${props => props.theme.LARGE};
  }
`;

const TechnicalSpecifications = styled(CollapsibleWithIcon)`
  padding: 30px;
  & > ul > li {
    font-size: ${props => props.theme.LARGE};
  }
`;

const UsageDetailsContainer = styled(CollapsibleWithIcon)`
  border-bottom: 1px solid black;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UsageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${p => p.theme.TABLET`
    display: unset;
  `};
`;

const UsageTextContainer = styled.ul`
  width: 100%;
`;

const UsageTextPoints = styled.div``;

const UsageMediaContainer = styled.div`
  width: 100%;
  max-width: 485px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div > video {
    outline: none;
  }
  ${p => p.theme.TABLET`
    display: flex;
    justify-content: center;
  `};
`;

const UsageImage = styled.img`
  width: 80%;
  height: 370px;
  margin: auto;
  box-shadow: 0 0 30px 0 rgba(41, 49, 73, 0.1);
  ${p => p.theme.TABLET`
    width: 100%;
  `};
`;

const TagsContainer = styled(CollapsibleWithIcon)`
  border-bottom: 1px solid black;
  margin-top: 20px;
`;

const TagElementsContainer = styled.div`
  display: flex;
  padding: 10px 0px 10px 0px;
  ${p => p.theme.TABLET`
    flex-direction: column;
  `};
`;

const TagElement = styled.div`
  background-color: ${props => props.theme.BLACK};
  color: ${props => props.theme.WHITE};
  margin-right: 20px;
  padding: 5px 10px 5px 10px;
  border-radius: 7px;
  ${p => p.theme.TABLET`
    padding: 5px;
    margin-right: unset;
    margin: 5px 0;
    text-align: center;
  `};
`;

const AdditionalDetails = styled(CollapsibleWithIcon)`
  margin-top: 60px;
  border-bottom: 1px solid black;
`;

const AdditionalDetailsContent = styled.div`
  font-size: ${props => props.theme.LARGE};
  padding: 10px 0px 20px 0px;
`;

const SocialMediaContentContainer = styled.div`
  margin-top: 60px;
`;

const SocialMediaPostContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SocialMediaPost = styled.img`
  width: 30%;
  height: 400px;
  padding: 10px;
  box-shadow: 0 0 30px 0 rgba(41, 49, 73, 0.1);
  :hover {
    width: 40%;

    transition: 1s width cubic-bezier(0, 0, 0.3, 1);
  }
`;

const FAQContainer = styled(CollapsibleWithIcon)`
  margin-top: 40px;
  border-bottom: 1px solid black;
`;

const FAQElement = styled.div``;

const FAQQuestion = styled(Collapsible)`
  font-size: ${props => props.theme.LARGE};
  font-weight: bold;
  padding: 10px 0px 10px 0px;
`;

const FAQAnswer = styled.div`
  font-size: ${props => props.theme.LARGE};
  font-weight: normal;
  padding: 10px 0px 10px 0px;
`;

const RelatedProductsContainer = styled.div`
  margin-top: 60px;
`;

const ProductElement = styled.div`
  display: flex;
  width: 60%;
  padding: 18px 0px 18px 0px;
  border-bottom: 1px solid grey;
`;

const ProductImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 20px;
`;

const ProductTitle = styled.div`
  font-size: ${props => props.theme.LARGE};
  margin-right: 40px;
  height: 60px;
  padding: 20px 0px 20px 0px;
`;

const ProductPrice = styled.div`
  font-size: ${props => props.theme.LARGE};
  margin-right: 30px;
  height: 60px;
  padding: 20px 0px 20px 0px;
`;

const ProductQuantity = styled.div`
  font-size: ${props => props.theme.LARGE};
  margin-right: 50px;
  height: 60px;
  padding: 20px 0px 20px 0px;
`;

const LogoContainer = styled.div`
  margin: 20px 0 30px 0;
`;

const AvailableVariants = styled.div`
  display: block;
  max-width: 400px;
  margin: 15px 0px 20px 0px;
`;

const VendorContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const VendorLogo = styled.img`
  width: 120px;
  height: 90px;
  object-fit: contain;
`;

const EditIcon = styled.div`
  cursor: pointer;
`;

const ShareButton = styled.button`
  color: ${props => props.theme.BLACK};
  background-color: ${props => props.theme.RONCHI};
  height: 40px;
  padding: 11px 7px;
  font-weight: normal;
  margin-right: 10px;
  border-radius: 2.5px;
  text-transform: none;
  border: none;
  width: auto;
  cursor: pointer;
`;

const BuyNowButton = styled(Secondary)`
  border-radius: 3px;
  border-color: ${props => props.theme.RONCHI};
  background-color: ${props => props.theme.WHITE};
  color: ${props => props.theme.BLACK};
  height: 40px;
  border: ${props => `1px solid ${props.theme.RONCHI}`};
  font-weight: normal;
  cursor: pointer;


  ${props => props.theme.TABLET`
    min-width: 151px;
    max-height: 50px;
    margin-left: 10px;
  `}
  ${props => props.theme.PHONE`
    min-width: 100px;
    min-height: 50px;
    margin-left: 10px;
  `}

  ${props =>
    props.disabled &&
    css`
      cursor: auto;
    `}
`;

const AdminBuyNowButton = styled(BuyNowButton)`
  cursor: unset;
`;

const TestimonyIcon = styled.div`
  position: relative;
  top: -50px;
  max-height: 40px;
`;

const ModalData = styled.div`
  padding: 20px;
  max-width: 900px;
  ${p => p.theme.TABLET`
     padding: 50px;
     width: 100%;
  `};
  ${p => p.theme.PHONE`
     width: 100%;
     padding: 20px;
  `};
`;

const MainLogo = styled.div`
  width: 100%;
  max-width: 180px;
  ${p => p.theme.TABLET`
    padding: unset;
    margin: 0 auto;
    max-width: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
  `};
`;

const MobileView = styled.div`
  display: none;
  ${props => props.theme.TABLET`
      display: unset;
      width: 100%;
      position: fixed;
      z-index: 1;
      background-color: ${props => props.theme.WHITE};
      box-shadow: 0 -10px 24px 0 rgba(0, 0, 0, 0.1);
      bottom: 0;
    `}
`;

const MobileViewContainer = styled.div``;

const PriceData = styled.div``;

const BuyShareData = styled.div`
  display: flex;
`;

const ShoptypeLogo = styled.img`
  padding: 30px;

  ${props => props.theme.TABLET`
    padding: unset;
  `}

  ${props =>
    props.hideLogo &&
    css`
      display: none;
    `}
`;

const Commission = styled.div`
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  margin: 12px 0;

  ${props => props.theme.TABLET`
  font-size: ${props => props.theme.SMALL};
  `}
`;

const HideOnMobile = styled.div`
  ${props => props.theme.TABLET`
  display: none;
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const VendorName = styled.div`
  align-items: flex-start;
  display: flex;
  color: white;
  width: fit-content;
  background: #ea5c5c;
  border-radius: 2.5px;
  padding: 3px 12px;
  font-weight: 500;
  margin: 20px 0px;
`;

export {
  MainLogo,
  Container,
  SubContainer,
  InnerContainer,
  ProductContainer,
  PrimaryDetails,
  ImagesContainer,
  MainMediaContainer,
  NoDisplayImage,
  ProductMainVideoContainer,
  MainImage,
  SubImageElementContainer,
  SubImagesContainer,
  BasicDetails,
  HeadingAndEditContainer,
  VendorLogo,
  Heading,
  Description,
  SlicedDescription,
  Category,
  ProductNotAvailable,
  PriceInformation,
  Price,
  CurrentPrice,
  PreviousPrice,
  Discount,
  NoStockMessage,
  BuyShareOptions,
  SecondaryDetails,
  VariantTypes,
  VariantOptionTypeContainer,
  VariantOptionTypeHeading,
  VariantOptionValueContainer,
  PrimaryVariant,
  SecondaryVariant,
  VariantStock,
  SecondOption,
  SecondOptionValue,
  ModalContainer,
  ModalTopLevelContainer,
  TertiaryDetails,
  Cards,
  QuotedReview,
  TechnicalSpecifications,
  FeaturesContainer,
  UsageDetailsContainer,
  UsageContent,
  UsageTextContainer,
  UsageTextPoints,
  UsageMediaContainer,
  UsageImage,
  TagsContainer,
  TagElementsContainer,
  TagElement,
  AdditionalDetails,
  AdditionalDetailsContent,
  SocialMediaContentContainer,
  SocialMediaPostContainer,
  SocialMediaPost,
  FAQContainer,
  FAQElement,
  FAQQuestion,
  FAQAnswer,
  RelatedProductsContainer,
  ProductElement,
  ProductImage,
  ProductTitle,
  ProductPrice,
  ProductQuantity,
  LogoContainer,
  AvailableVariants,
  VendorContainer,
  EditIcon,
  ShareButton,
  BuyNowButton,
  TestimonyIcon,
  Testimony,
  ModalData,
  MobileView,
  MobileViewContainer,
  PriceData,
  BuyShareData,
  AdminBuyNowButton,
  ShoptypeLogo,
  Commission,
  HideOnMobile,
  Row,
  MobileHeading,
  VendorName
};
