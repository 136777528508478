import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ForgotPasswordTab from '../../molecules/ForgotPasswordTab';

import { Container, Heading, SubHeading, FormContainer } from './styles';

const ForgotPassword = () => {
  return (
    <Container>
      <Heading>Forgot Password</Heading>
      <SubHeading>
        Please enter your email address. You will receive a link to create a new
        password via email.
      </SubHeading>
      <FormContainer>
        <ForgotPasswordTab />
      </FormContainer>
    </Container>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(ForgotPassword);
