import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getAuthToken,
  getApiResponseObject,
  getAuthId,
  getUserType,
  successStatusCodes,
  showApiErrorToast,
  getSelectedCurrency
} from '../../../../utils';
import Modal from '../../../templates/Modal';
import { toaster } from '../../../atoms/toaster';
import PublishModal from '../../ShareOnSocial';
import { API_BASE_URL, APP_BASE_URL } from '../../../../config/env';
import PaginationControls from '../../../atoms/paginationControls';
import SearchBar from '../../../atoms/searchBar';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS, COSELLER } from '../../../../constants';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import * as actions from '../../../../redux/actions/uiActions';

import {
  MainContainer,
  ProductSection,
  CatalogBar,
  InfoBar,
  SearchCount,
  ProductListItems,
  TopLevelContainer
} from './styles';
import MultiSelectAutoComplete from '../../../atoms/MultiSelectAutoComplete';
import { withStyles } from '@material-ui/core';
import PriceRangeFilter from '../../../pages/Coseller/ProductList/PriceRangeFilter';
import STProductCards from '../../../common/STProductCards';
import EmptyState from '../../../atoms/EmptyState';

const styles = theme => ({
  searchBar: {
    backgroundColor: '#fff'
  },
  tc: {
    textAlign: 'center'
  },
  paginationBtn: {
    border: 'none',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none'
    }
  },
  mL10: {
    marginLeft: '10px'
  },
  PRwidth: {
    width: '210px'
  }
});

class Catalog extends React.Component {
  state = {
    isLoadingCatalog: true,
    isOpen: false,
    modalContent: null,
    vendorProducts: [],
    // Below required for search and pagination implementation
    totalCount: null,
    totalResultsCount: 0,
    selectedCurrency: null,
    restrictions: {},
    priceRangeSelected: [0, 1000],
    priceRangeAvailable: [0, 1000],
    fetchParams: {
      count: 12, // set this for pagesize
      offset: 0,
      text: ''
    }
  };

  componentDidMount() {
    const selectedCurrency = getSelectedCurrency();
    if (selectedCurrency) {
      this.setState({ selectedCurrency }, () => this.fetchVendorProducts());
    }
    EventsBaseClient.on(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedVendorId, selectedVendors, restrictions } = this.props;
    const { selectedCurrency } = this.state;
    let resetParams = false;

    if (
      prevState.selectedCurrency != null &&
      prevState.selectedCurrency != selectedCurrency
    ) {
      this.fetchVendorProducts();
    }

    if (selectedVendorId) {
      if (prevProps.selectedVendorId !== selectedVendorId) resetParams = true;
    } else {
      if (prevProps.selectedVendors.length !== selectedVendors.length) {
        if (!isEqual(prevProps.selectedVendors, selectedVendors))
          resetParams = true;
      }
    }

    if (resetParams) this.resetFetchParams();
    if (prevState.restrictions != restrictions)
      this.setState({ restrictions: restrictions });
  }

  componentWillUnmount() {
    EventsBaseClient.removeEventListener(
      GLOBAL_EVENT_KEYS.CURRENCY_CHANGE_EVENT,
      this.handleCurrencyChange
    );
  }

  handleCurrencyChange = currency => {
    this.setState({ selectedCurrency: currency, isLoadingCatalog: true });
  };

  resetFetchParams = () => {
    this.setState(
      {
        fetchParams: {
          count: 12, // set this for pagesize
          offset: 0,
          text: ''
        },
        totalcount: 0,
        isLoadingCatalog: true
      },
      () => {
        this.fetchVendorProducts();
      }
    );
  };

  // required for search and pagination implementation
  updateFetchParams = fetchParams => {
    this.setState({ fetchParams, isLoadingCatalog: true }, () => {
      this.fetchVendorProducts();
    });
  };

  fetchVendorProducts = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    this.props.showSpinnerBackdrop();

    const { fetchParams, selectedCurrency, priceRangeSelected } = this.state;
    const { selectedVendorId, selectedVendors, restrictions } = this.props;
    const userType = getUserType() || '';

    const vendorId = selectedVendorId
      ? selectedVendorId
      : selectedVendors.join(',');
    const priceRangeParams = `&minRange=${priceRangeSelected[0]}&maxRange=${priceRangeSelected[1]}`;
    let adult = restrictions ? restrictions.isAdult : false;
    let ageRestricted = restrictions ? restrictions.isAgeRestricted : false;

    // Uniform elastic search API for COSELLER and NETWORK OPERATOR
    let URL = '';
    if (userType === COSELLER) {
      URL = `${API_BASE_URL}/query-products?vendorId=${vendorId}&currency=${selectedCurrency}${priceRangeParams}&isAdult=${adult}&isAgeRestricted=${ageRestricted}`;
    } else {
      // Network Operator
      if (adult === false && ageRestricted == false)
        URL = `${API_BASE_URL}/query-products?vendorId=${vendorId}`;
      else
        URL = `${API_BASE_URL}/query-products?vendorId=${vendorId}&isAdult=${adult}&isAgeRestricted=${ageRestricted}`;
    }

    const response = await getApiResponseObject(URL, headers, fetchParams);

    if (!successStatusCodes.includes(response.status)) {
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
    }

    const productResults = response.data?.product;

    if (userType === COSELLER) {
      const productPriceLimits = response.data?.productPriceLimits;
      if (productPriceLimits) {
        const availabePriceRange = [
          productPriceLimits.minPrice.amount,
          productPriceLimits.maxPrice.amount
        ];
        this.setState({
          priceRangeAvailable: availabePriceRange,
          priceRangeSelected: availabePriceRange
        });
      } else {
        this.setState({
          priceRangeAvailable: [0, 0],
          priceRangeSelected: [0, 0]
        });
      }
    }

    this.setState({
      vendorProducts: productResults,
      isLoadingCatalog: false,
      totalCount: this.state.totalCount ?? response.data.count,
      totalResultsCount: response.data.count
    });
    this.props.hideSpinnerBackdrop();
  };

  handleViewButtonClick = id => {
    window.open(`/view-product/${id}`, '_blank');
  };

  clickPublish = (id, title, description, imageSrc, catalogId, currency) => {
    this.fetchProductLinkData(
      id,
      title,
      description,
      imageSrc,
      catalogId,
      currency
    );
  };

  fetchProductLinkData = async (
    id,
    title,
    description,
    imageSrc,
    catalogId,
    currency
  ) => {
    const token = getAuthToken();
    const headers = { authorization: token };
    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/track/publish-slug?productId=${id}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
    }

    const productShareLink = `${APP_BASE_URL}${response.data.slug}`;

    this.setState({ productShareLink: productShareLink, isOpen: true }, () => {
      this.publish(id, title, description, imageSrc, catalogId, currency);
    });

    this.props.hideSpinnerBackdrop();
  };

  closeModal = () => {
    this.setState({ modalContent: null, productShareLink: '' });
  };

  publish = (id, title, description, imageSrc, catalogId, currency) => {
    const token = getAuthToken();
    const authId = getAuthId();
    const { productShareLink } = this.state;

    const modalContent = (
      <Modal close={this.closeModal}>
        <TopLevelContainer>
          <PublishModal
            authId={authId}
            productId={id}
            vendorId={catalogId} // Vendor Id is considered as Catalog id
            title={title}
            token={token}
            description={description}
            imageSrc={imageSrc}
            productShareLink={productShareLink}
            embedOption={true}
            currency={currency}
          />
        </TopLevelContainer>
      </Modal>
    );

    this.setState({
      shopifyProducts: true,
      modalContent: modalContent
    });
  };

  productsCount = (totalCount, totalResultsCount) => {
    if (totalCount !== totalResultsCount) {
      if (totalResultsCount === 1)
        return `About ${totalResultsCount} product found`;
      else return `About ${totalResultsCount} products found`;
    } else {
      return 'Showing all the products';
    }
  };

  handlePriceRangeChange = priceRangeToSelect => {
    this.setState({ priceRangeSelected: priceRangeToSelect }, () => {
      this.fetchVendorProducts();
    });
  };
  loadMoreResults = () => {
    this.props.updateVendorFetchParams();
  };

  changeOptionBaseOnValue = value => {
    this.props.updateVendorFetchParamsBasedOnInput(value);
  };

  render() {
    const { classes } = this.props;
    const {
      isLoadingCatalog,
      isOpen,
      modalContent,
      vendorProducts,
      totalCount,
      totalResultsCount,
      priceRangeAvailable,
      selectedCurrency,
      priceRangeSelected
    } = this.state;

    const { vendorsList, updateSelectedVendors } = this.props;
    let userType = getUserType() || '';

    return (
      <MainContainer>
        <CatalogBar>
          <SearchBar
            className={classes.searchBar}
            placeholder="Search.."
            fetchParams={this.state.fetchParams}
            updateFetchParams={this.updateFetchParams}
          />
          <div className={classes.mL10}>
            <MultiSelectAutoComplete
              vendorsList={vendorsList}
              updateSelectedVendors={updateSelectedVendors}
              width="300px"
              size="small"
              label="Select Vendors"
              loadMoreResults={this.loadMoreResults}
              changeOptionBaseOnValue={this.changeOptionBaseOnValue}
            />
          </div>
          {/* {userType === COSELLER && (
            <div className={`${classes.mL10} ${classes.PRwidth}`}>
              <PriceRangeFilter
                selectedCurrency={selectedCurrency}
                priceRangeUpdateCallback={this.handlePriceRangeChange}
                priceRangeSelected={priceRangeSelected}
                priceRangeAvailable={priceRangeAvailable}
              />
            </div>
          )} */}
        </CatalogBar>
        <InfoBar>
          <SearchCount>
            {this.productsCount(totalCount, totalResultsCount)}
          </SearchCount>
        </InfoBar>
        <ProductSection>
          <ProductListItems>
            {!isLoadingCatalog ? (
              <div>
                {isOpen ? modalContent : null}
                <STProductCards
                  productsData={vendorProducts}
                  clickPublish={this.clickPublish}
                  handleViewButtonClick={this.handleViewButtonClick}
                />
              </div>
            ) : (
              !isLoadingCatalog && (
                <EmptyState errorMessage={'No products found'} />
              )
            )}
          </ProductListItems>
          <PaginationControls
            fetchParams={this.state.fetchParams}
            totalItemCount={this.state.totalResultsCount}
            updateFetchParams={this.updateFetchParams}
          />
        </ProductSection>
      </MainContainer>
    );
  }
}

Catalog.propTypes = {
  selectedVendorId: PropTypes.string,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withStyles(styles)(
  withRouter(connect(null, mapDispatchToProps)(Catalog))
);
