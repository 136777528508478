import React, { useState } from 'react';
import PurchaseCards from './PurchaseCards';
import { Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './styles.css';
import { CURRENCY_SYMBOL } from '../../../../constants';

const OrdersList = ({
  orderIndex,
  orderDate,
  orderNumber,
  returnsCount,
  deliveryDate,
  status,
  OrderTotal,
  ordersCount,
  shoptypeOrderId,
  purchasedItems,
  source,
  currency
}) => {
  const [active, setActive] = useState('');

  const currencySymbol = CURRENCY_SYMBOL[currency];

  const orderDateString = new Date(orderDate).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  const toggleChange = orderNo => {
    if (active == '') {
      setActive(orderNo);
    } else {
      setActive('');
    }
  };
  return (
    <div>
      <Typography classes={{ root: 'typoOne' }} gutterBottom component="h2">
        Order {orderIndex}: {orderDateString}
      </Typography>
      <div className="accordion">
        <Box
          className="accordionHeading"
          onClick={() => toggleChange(orderNumber)}
        >
          <div className="columnSpace">
            <div className="rowPadding">
              <Typography classes={{ root: 'fixedFont' }}>
                Order No :
              </Typography>
              <Typography classes={{ root: 'typoTwo' }}>
                {orderNumber}
              </Typography>
            </div>

            <div className="rowPadding">
              <Typography classes={{ root: 'fixedFont' }}>Items :</Typography>
              <Typography classes={{ root: 'typoTwo' }}>
                {' '}
                {ordersCount}{' '}
              </Typography>
            </div>
          </div>

          <div className="flexRow">
            <div className="center">
              <Typography classes={{ root: 'fixedFont' }}>
                Order Total
              </Typography>
              <Typography classes={{ root: 'typoThree' }}>
                {currencySymbol} {OrderTotal}
              </Typography>
            </div>
            {active != '' ? (
              <ExpandLessIcon
                style={{ height: '60px', width: '25px', color: '#808080' }}
              />
            ) : (
              <ExpandMoreIcon
                style={{ height: '60px', width: '25px', color: '#808080' }}
              />
            )}
          </div>
        </Box>

        {purchasedItems.map((item, index) => {
          return active != '' ? (
            <PurchaseCards
              key={index}
              Seller={item.vendor_name}
              ItemName={item.name}
              VendorId={item.vendor_id}
              Quantity={item.quantity}
              ItemTotal={item.price.amount}
              ReturnsCount={returnsCount}
              deliveryDate={deliveryDate}
              OrderId={orderNumber}
              shoptypeOrderId={shoptypeOrderId}
              source={source}
              status={status}
              currency={item.price.currency}
              productId={item.product_id}
              purchasedItems={purchasedItems}
              index={index}
            />
          ) : null;
        })}
      </div>
    </div>
  );
};

export default OrdersList;
