import { makeStyles } from '@material-ui/core';
import React from 'react';
import PaginationControls from '../../../atoms/paginationControls';
import Currency from '../../../molecules/Currency';
import DefaultProductImage from '../../../../assets/images/photo.svg';
import PropTypes from 'prop-types';
import {
  ProductDataPoints,
  ProductDataPointTitle,
  ProductLevelAnalyticsContainer,
  ProductListItems
} from '../styles';

const useStyles = makeStyles({
  paginationBtn: {
    border: 'none',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none'
    }
  }
});

const ProductLevelAnalytics = props => {
  const PRODUCT_LEVEL_ANALYTICS_TITLE = [
    '',
    'PRODUCT',
    'PRICE',
    'ITEMS SOLD',
    'CLICKS',
    'PUBLISHES'
  ];

  const classes = useStyles();
  const {
    analyticsData,
    fetchParams,
    totalResultsCount,
    updateFetchParams
  } = props;
  return (
    <div>
      <ProductLevelAnalyticsContainer>
        <ProductDataPointTitle>
          {PRODUCT_LEVEL_ANALYTICS_TITLE.map((title, i) => (
            <div key={i}>{title}</div>
          ))}
        </ProductDataPointTitle>
        <ProductListItems>
          {analyticsData.map((dataPoints, i) => {
            return (
              <ProductDataPoints key={i}>
                <img
                  src={dataPoints.image_url || DefaultProductImage}
                  alt="product"
                />
                <div>{dataPoints.title || 'NA'}</div>
                <div>
                  <Currency currencyName={dataPoints.currency} />
                  {dataPoints.price >= 0 ? dataPoints.price : 'NA'}
                </div>
                <div>
                  {dataPoints.total_quantity_sold >= 0
                    ? dataPoints.total_quantity_sold
                    : 'NA'}
                </div>
                <div>
                  {dataPoints.total_clicks >= 0
                    ? dataPoints.total_clicks
                    : 'NA'}
                </div>
                <div>
                  {dataPoints.total_publishes >= 0
                    ? dataPoints.total_publishes
                    : 'NA'}
                </div>
              </ProductDataPoints>
            );
          })}
        </ProductListItems>
        <PaginationControls
          paginationBtn={classes.paginationBtn}
          fetchParams={fetchParams}
          totalItemCount={totalResultsCount}
          updateFetchParams={updateFetchParams}
        />
      </ProductLevelAnalyticsContainer>
    </div>
  );
};

ProductLevelAnalytics.propTypes = {
  analyticsData: PropTypes.array,
  isLoading: PropTypes.bool
};

export default ProductLevelAnalytics;
