import React from 'react';
import { Container } from './styles';
import OrderConfirmation from '../../molecules/OrderConfirmation_v1.0';

const ThankYou = () => {
  return (
    <>
      <Container>
        <OrderConfirmation />
      </Container>
    </>
  );
};

export default ThankYou;
