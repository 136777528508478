import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import React from 'react';
import DefaultImage from '../../../../assets/images/no-image.svg';
import shoptypeThemes from '../../../../theme';
import { currencySymbol } from '../../../../utils';
import CardSpinner from '../../../common/CardSpinner';
import {
  DataLabel,
  LocationIcon,
  MoneyIcon,
  NetworkInfo,
  StyledAvatar,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledLink
} from '../styles';

const useStyles = makeStyles({
  networkData: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(40px, 1fr))',
    gridRowGap: '12px',
    gridColumnGap: '2px',
    width: '100%'
  },
  networkItem: {
    alignSelf: 'start',
    justifySelf: 'center',
    textAlign: 'center'
  },
  button: {
    flex: '1',
    textTransform: 'none',
    backgroundColor: shoptypeThemes.default.RONCHI
  },
  title: {
    lineHeight: '1',
    marginBottom: '5px',
    wordBreak: 'break-word'
  }
});

const ConnectedNetworkCard = props => {
  const classes = useStyles();
  const { network, platform, analytics, onRemove } = props;

  // CARD HEADER
  const avatar = network?.preview_image_url || DefaultImage;
  const networkName = network?.name ? network?.name : '--No name--';
  const networkURL = platform?.url ? platform?.url : '--No url--';
  const geoLocation = platform?.geoLocation ? platform?.geoLocation : '--';
  const city = geoLocation?.split(',')[0];
  const currency = network?.currency ? network?.currency.toUpperCase() : '--';

  // CARD CONTENT
  const networkFee = analytics?.network_fee ? `${analytics.network_fee}%` : 0;
  const activeCosellers = analytics?.active_cosellers;
  const ordersLastMonth = analytics?.vendor_orders_last_month;
  const returnsLastMonth = analytics?.returns_last_month;
  const revenueLastMonth = `${currencySymbol(
    analytics?.revenue_last_month?.currency
  )}${analytics?.revenue_last_month?.amount}`;
  const paymentSettled = `${currencySymbol(
    analytics?.payment_settled?.currency
  )}${analytics?.payment_settled?.amount}`;
  const paymentPending = `${currencySymbol(
    analytics?.payment_pending?.currency
  )}${analytics?.payment_pending?.amount}`;

  // FOR MAPPING ANALYTICS
  const NETWORK_DATA = !isEmpty(analytics)
    ? [
        {
          label: 'Network Fee',
          value: networkFee
        },
        {
          label: 'Active Cosellers',
          value: activeCosellers
        },
        {
          label: 'Orders Last Month',
          value: ordersLastMonth
        },
        {
          label: 'Returns Last Month',
          value: returnsLastMonth
        },
        {
          label: 'Revenue Last Month',
          value: revenueLastMonth
        },
        {
          label: 'Payment Settled',
          value: paymentSettled
        },
        {
          label: 'Payment Pending',
          value: paymentPending
        }
      ]
    : [];

  return (
    <StyledCard>
      <StyledCardHeader
        avatar={
          <StyledAvatar
            objectfit={network?.preview_image_url}
            aria-label="network-image"
            src={avatar}
          />
        }
        title={networkName}
        classes={{ title: classes.title }}
        subheader={
          <>
            <Box display="flex" flexDirection="column" mb={1.5}>
              <StyledLink
                target="_blank"
                to={{
                  pathname: `http://${networkURL}`
                }}
              >
                {networkURL}
              </StyledLink>
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <NetworkInfo>
                <LocationIcon />
                <Box fontSize={10} fontWeight={500}>
                  {city}
                </Box>
              </NetworkInfo>
              <NetworkInfo>
                <MoneyIcon />
                <Box fontSize={10} fontWeight={500}>
                  {currency}
                </Box>
              </NetworkInfo>
            </Box>
          </>
        }
        titleTypographyProps={{
          variant: 'h6'
        }}
        subheaderTypographyProps={{
          variant: 'subtitle2'
        }}
      />
      <Box mt="auto">
        <StyledCardContent>
          {!isEmpty(analytics) ? (
            <div className={classes.networkData}>
              {NETWORK_DATA.map((data, index) => (
                <div className={classes.networkItem} key={index}>
                  <Box fontSize={18} fontWeight={500}>
                    {data.value}
                  </Box>
                  <DataLabel>{data.label}</DataLabel>
                </div>
              ))}
            </div>
          ) : (
            <CardSpinner />
          )}
        </StyledCardContent>
        <StyledCardActions>
          <Button
            variant="contained"
            className={`${classes.button}`}
            onClick={onRemove}
          >
            Remove Connection
          </Button>
        </StyledCardActions>
      </Box>
    </StyledCard>
  );
};

export default ConnectedNetworkCard;
