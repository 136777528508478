import React from 'react';
import styled from 'styled-components';
import { func, array, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Currency from '../../../../molecules/Currency';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  heightInherit: {
    height: 'inherit'
  }
}));

const HelperText = styled.div`
  width: 100%;
`;

const SimpleSelect = ({ value, options, handleChange, helperText }) => {
  const classes = useStyles();
  return (
    <div className={classes.heightInherit}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Currency</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
          label="Currency"
          disabled={
            !options || (Array.isArray(options) && options?.length <= 0)
          }
        >
          {options &&
            options?.map((key, index) => (
              <MenuItem key={index} value={key}>
                {key.toUpperCase()}
              </MenuItem>
            ))}
        </Select>
        <HelperText>
          {helperText ? (
            <FormHelperText>
              Maximum Amount avaliable for withdrawal{' '}
              <Currency currencyName={'USD'} />
              <span style={{ fontWeight: 'bold' }}>{helperText}</span>
            </FormHelperText>
          ) : null}
        </HelperText>
      </FormControl>
    </div>
  );
};

SimpleSelect.propTypes = {
  value: string,
  options: array,
  handleChange: func,
  helperText: string
};

export default SimpleSelect;
