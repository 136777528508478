import React from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../../atoms/loader';
import CosellerDashboard from './index';
import {
  getAuthToken,
  getUserType,
  getUserProfile,
  getApiResponseObject,
  successStatusCodes,
  clearBrowserStoredData
} from '../../../../utils';
import history from '../../../../history';
import { API_BASE_URL } from '../../../../config/env';
import { GLOBAL_EVENT_KEYS, COSELLER } from '../../../../constants';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';

class CosellerDashboardWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDashboard: false };
  }

  componentDidMount = async () => {
    let params = new URLSearchParams(document.location.search);

    const token = params.get('access_token');
    const user_Type = params.get('user_type');

    if (token) {
      localStorage.setItem('USER_TYPE', COSELLER);
      await this.updateUserProfileToLocalStorage();
    }

    this.fetchProfileData();
  };

  updateUserProfileToLocalStorage = async () => {
    let params = new URLSearchParams(document.location.search);
    let token = params.get('access_token');
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      localStorage.setItem('userProfile', JSON.stringify(profileRes.data));
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
    }
    return profileRes.data || {};
  };

  fetchProfileData = async () => {
    const token = getAuthToken();
    const userType = getUserType();
    const userProfile = getUserProfile();

    if (!userProfile) {
      if (token) {
        if (token && userType) {
          const res = await getApiResponseObject(`${API_BASE_URL}/me`, {
            authorization: token
          });

          if (successStatusCodes.includes(res.status)) {
            localStorage.setItem('userProfile', JSON.stringify(res.data));
            EventsBaseClient.emit(
              GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT
            );

            this.setState({ showDashboard: true });
          }
        } else {
          history.push('/users/platform');
        }
      } else {
        history.push('/');
      }
    } else {
      this.setState({ showDashboard: true });
    }
  };

  render() {
    const { showDashboard } = this.state;

    return (
      <>
        {showDashboard ? (
          <CosellerDashboard />
        ) : (
          <div style={{ width: '100%', height: '100vh' }}>
            <Loader
              message="Loading..."
              isFlex={true}
              w={'100%'}
              h={'100%'}
              isCenter={true}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(CosellerDashboardWrapper);
