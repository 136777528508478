import { toast } from 'react-toastify';
import './styles.css';

toast.configure();

export const toaster = message => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000
  });
};
