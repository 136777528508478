import styled from 'styled-components';
import Card from '../../atoms/card';

const PublishButtonModal = styled(Card)`
  color: ${props => props.theme.BLACK};
  display: flex;
  align-items: left;
  flex-direction: column;
`;

const SubHeading = styled.div`
  text-align: left;
  padding: 8px 0;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

const Container = styled.div``;

const ShareIcon = styled.i`
  font-size: 20px;
  margin: 8px;
`;

export { PublishButtonModal, SubHeading, IconContainer, Container, ShareIcon };
