import React from 'react';
import isNil from 'lodash/isNil';
import {
  SubSectionWrapper,
  Card,
  ShippingInfoWrapper,
  CardHeading,
  ShippingInfoLabel,
  ShippingInfoValue,
  Wrapper,
  BillingInfoContainer
} from '../styles';
import PropTypes from 'prop-types';
const getShippingAddressString = data => {
  const { street1, city, state, postal_code, country } = data.address;
  return (
    street1 + ', ' + city + ', ' + state + ', ' + postal_code + ', ' + country
  );
};

export default function ShippingDetail({ finalAmount }) {
  const { checkoutFormData } = finalAmount;
  const { billing, shipping } = checkoutFormData;

  const shippingName = isNil(shipping.firstname)
    ? isNil(shipping.lastname)
      ? ''
      : shipping.lastname
    : isNil(shipping.lastname)
    ? shipping.firstname
    : shipping.firstname + ' ' + shipping.lastname;

  const billingName =
    isNil(billing.firstname) || billing.firstname === ''
      ? isNil(billing.lastname) || billing.lastname === ''
        ? shippingName
        : billing.lastname
      : isNil(billing.lastname) || billing.lastname === ''
      ? billing.firstname
      : billing.firstname + ' ' + billing.lastname;

  const billingEmail =
    isNil(billing.email) || billing.email === ''
      ? shipping.email
      : billing.email;
  const billingPhoneNumber =
    isNil(billing.address.phone) || billing.address.phone === ''
      ? shipping.address.phone
      : billing.address.phone;
  const shippingAddressString = getShippingAddressString(shipping);
  const billingAddressString = isNil(billing.address.street1)
    ? shippingAddressString
    : getShippingAddressString(billing);
  return (
    <SubSectionWrapper>
      <Card>
        <CardHeading>Shipping Details</CardHeading>
        <ShippingInfoWrapper>
          <Wrapper shrink={0}>
            <ShippingInfoLabel>Name:</ShippingInfoLabel>
            <ShippingInfoLabel>Email:</ShippingInfoLabel>
            <ShippingInfoLabel>Mobile No:</ShippingInfoLabel>
            <ShippingInfoLabel>Shipping Address:</ShippingInfoLabel>
          </Wrapper>
          <Wrapper>
            <ShippingInfoValue>{shippingName}</ShippingInfoValue>
            <ShippingInfoValue>{shipping.email}</ShippingInfoValue>
            <ShippingInfoValue>{shipping.address.phone}</ShippingInfoValue>
            <ShippingInfoValue>{shippingAddressString}</ShippingInfoValue>
          </Wrapper>
        </ShippingInfoWrapper>
      </Card>
      <BillingInfoContainer>
        <Card>
          <CardHeading>Billing Details</CardHeading>
          <ShippingInfoWrapper>
            <Wrapper shrink={0}>
              <ShippingInfoLabel>Name:</ShippingInfoLabel>
              <ShippingInfoLabel>Email:</ShippingInfoLabel>
              <ShippingInfoLabel>Mobile No:</ShippingInfoLabel>
              <ShippingInfoLabel>Billing Address:</ShippingInfoLabel>
            </Wrapper>
            <Wrapper>
              <ShippingInfoValue>{billingName}</ShippingInfoValue>
              <ShippingInfoValue>{billingEmail}</ShippingInfoValue>
              <ShippingInfoValue>{billingPhoneNumber}</ShippingInfoValue>
              <ShippingInfoValue>{billingAddressString}</ShippingInfoValue>
            </Wrapper>
          </ShippingInfoWrapper>
        </Card>
      </BillingInfoContainer>
    </SubSectionWrapper>
  );
}

ShippingDetail.propTypes = {
  data: PropTypes.object
};
