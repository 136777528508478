import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import history from '../../../history';
import { getCheckoutDetails } from '../../../apis/cart';
import { toaster } from '../../atoms/toaster';
import { successStatusCodes } from '../../../utils';
import { isEmpty } from 'lodash';
import Loader from '../../atoms/loader';
import {
  Container,
  Heading,
  SubHeading,
  OrdersContainer,
  OrderDetails,
  ProductsContainer,
  ProductDetails,
  ImageContainer,
  OtherDetails
} from './styles';

const OrderConfirmation = () => {
  const [checkoutDetails, setCheckoutDetails] = useState({});

  useEffect(() => {
    sessionStorage.clear();

    fetchCheckoutDetails();
  }, []);

  const fetchCheckoutDetails = async () => {
    const checkoutId = getCheckoutId();

    if (checkoutId?.length > 0) {
      const res = await getCheckoutDetails(checkoutId);

      if (successStatusCodes.includes(res.status)) {
        setCheckoutDetails(res.data);
      } else {
        toaster('Cannot fetch checkout details');
      }
    }
  };

  const getCheckoutId = () => {
    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    }).checkoutId;
  };

  return !isEmpty(checkoutDetails) ? (
    <Container>
      <Heading>Thank You!</Heading>
      <SubHeading>
        Order Confirmed&nbsp;
        <i className="fas fa-check-circle success" />
      </SubHeading>

      <OrdersContainer>
        {!isEmpty(checkoutDetails?.order_details_per_vendor) &&
          Object.keys(checkoutDetails.order_details_per_vendor).map(
            (vendorId, index) => (
              <OrderDetails key={index}>
                <div>
                  <div>
                    Order&nbsp;&#35;
                    {
                      checkoutDetails.order_details_per_vendor[vendorId]
                        .external_order_id
                    }
                  </div>
                  <div>
                    By&nbsp;
                    {
                      checkoutDetails.order_details_per_vendor[vendorId]
                        .cart_lines[0].vendor_name
                    }
                  </div>
                </div>
                <ProductsContainer>
                  {checkoutDetails.order_details_per_vendor[
                    vendorId
                  ].cart_lines.map((product, index) => (
                    <ProductDetails key={index}>
                      <ImageContainer>
                        <img src={product.image_src} alt={product.name} />
                      </ImageContainer>
                      <div>{product.name}</div>
                    </ProductDetails>
                  ))}
                </ProductsContainer>
              </OrderDetails>
            )
          )}
      </OrdersContainer>
      <OtherDetails>
        <div>
          Customer Information:
          <br />
          {checkoutDetails?.shipping_address?.name}&#44;
          <br />
          {checkoutDetails?.shipping_address?.street1}&#44;
          <br />
          {checkoutDetails?.shipping_address?.city}&nbsp;
          {checkoutDetails?.shipping_address?.postalCode}&#44;
          <br />
          {checkoutDetails?.shipping_address?.state}&#44;&nbsp;
          {checkoutDetails?.shipping_address?.country}
        </div>
        <div>
          Payment Information:
          <br />
          {checkoutDetails?.payment?.name?.length > 0
            ? `Paid online via ${checkoutDetails.payment.name}`
            : 'NA'}
        </div>
      </OtherDetails>
      <SubHeading>
        Confirmation sent to:&nbsp;
        <strong>{checkoutDetails?.shipping_address?.email}</strong>
      </SubHeading>
    </Container>
  ) : (
    <Loader isFlex={true} w={'100%'} min_h={'100vh'} isCenter={true} />
  );
};

OrderConfirmation.propTypes = {
  data: PropTypes.object
};

export default withRouter(OrderConfirmation);
