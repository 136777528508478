import styled from 'styled-components';
const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 350px;
  height: 100vh;
  border-left: 1px solid #e5e5e5;
  background-color: ${props => props.theme.WHITE};
  ${props => (props.display === 'show' ? '' : ' transform: translateX(100%);')}
  transition: transform 0.15s ease-in-out;
  & > button {
    top: 10px;
    right: 10px;
  }
`;

const CartHeading = styled.div`
  flex: 0 0 auto;
  padding: 20px;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  border-bottom: 1px solid #e5e5e5;
`;

const CartLineItems = styled.div`
  flex: 1 1 auto;
  padding: 20px;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const LineItemDetails = styled.div`
  margin-bottom: 20px;
`;

const LineItemPrimaryDetails = styled.div`
  display: flex;
  margin-bottom: 10px;
  & > img {
    width: 65px;
    height: 65px;
    object-fit: contain;
    background-color: white;
  }
  & > div {
    width: 100%;
    max-width: 235px;
    margin-left: 10px;
  }
  & > div > div:first-child {
    color: #4e5665;
    font-size: ${props => props.theme.LARGE};
  }
  & > div > div:last-child {
    color: #767676;
    font-size: ${props => props.theme.SMALL};
    font-weight: ${props => props.theme.MEDIUM_FONT};
  }
`;

const LineItemSecondaryDetails = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  & > button {
    top: 0px;
  }
`;

const LineItemQuantity = styled.div`
  display: flex;
  width: 90px;
  border: 1px solid #767676;
  border-radius: 3px;
  & > button {
    flex: 1 0 auto;
    width: 25px;
    height: 25px;
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
  }
  & > span {
    width: 100%;
    font-size: ${props => props.theme.LARGE};
    border-left: 1px solid #767676;
    border-right: 1px solid #767676;
    text-align: center;
  }
`;

const LineItemPrice = styled.div`
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 40px;
`;

const CartFooter = styled.div`
  flex: 0 0 auto;
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  & > button {
    min-height: 40px;
    margin-top: 20px;
    background-color: ${props =>
      props.disable ? props.theme.CHINESE_SILVER : props.theme.RONCHI};
    border-color: ${props =>
      props.disable ? props.theme.CHINESE_SILVER : props.theme.RONCHI};
    color: ${props => props.theme.BLACK};
  }
`;

const CartFooterItem = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 25px;
  font-size: ${props => props.theme.SMALL};
  & > div:first-child {
    font-size: ${props => props.theme.SMALL};
  }
  & > div:last-child {
    font-size: ${props => props.theme.LARGE};
  }
`;

const LineItemVendorDetails = styled.div`
  padding: 10px 0;
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  color: ${props => props.theme.SONIC_SILVER};
`;

export {
  CartContainer,
  CartHeading,
  CartLineItems,
  LineItemDetails,
  LineItemPrimaryDetails,
  LineItemSecondaryDetails,
  LineItemQuantity,
  LineItemPrice,
  CartFooter,
  CartFooterItem,
  LineItemVendorDetails
};
