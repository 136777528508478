import React from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';

function withStripeHook(Component) {
  return function WrappedComponent(props) {
    const stripe = useStripe();
    const elements = useElements();
    return <Component {...props} stripe={stripe} elements={elements} />;
  };
}

export default withStripeHook;
