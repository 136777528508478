import React from 'react';
import SignUpTab from '../../molecules/SignUpTab';
import {
  Container,
  Heading,
  SubHeading,
  VendorContainer,
  Footer
} from './styles';
import history from '../../../history';
import qs from 'qs';
import PrivacyInformation from '../../atoms/privacyInformation';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignUp: true
    };
  }

  componentDidMount() {
    this.fetchinviteLinkSlug();
  }

  fetchinviteLinkSlug = () => {
    const inviteId = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    }).rid;
    if (inviteId) {
      sessionStorage.setItem('inviteId', inviteId);
    }
  };

  render() {
    const { isSignUp } = this.state;
    return (
      <Container>
        <Heading>Create account</Heading>
        <SubHeading>Add your details</SubHeading>
        <VendorContainer>
          <SignUpTab isSignUp={isSignUp} />
        </VendorContainer>
        <Footer>
          <PrivacyInformation isSignUp={true} />
        </Footer>
      </Container>
    );
  }
}

export default SignUp;
