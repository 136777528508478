import styled from 'styled-components';

const SubmitButton = styled.button`
  display: block;
  height: 40px;
  width: 100%;
  font-size: inherit;
  background-color: ${props => (props.disabled ? '#f0d64e' : '#f0d64e')};
  box-shadow: ${props =>
    props.disabled
      ? 'none'
      : `0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 ${props =>
          props.theme.BLACK}`};
  border-radius: 4px;
  opacity: ${props => (props.disabled ? 1 : 1)};
  color: ${props => props.theme.BLACK};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  cursor: pointer;
`;

export default SubmitButton;
