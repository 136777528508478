import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { isEmpty } from 'lodash';
import DefaultImage from '../../../../../assets/images/photo.svg';
import {
  ModalContainer,
  ModalHeading,
  ModalSubHeading,
  SmallLabel,
  Actions,
  InviteButton,
  ModalBody,
  VendorImage,
  ProductCategories
} from '../styles';

const InviteVendorModal = ({ vendor, handleInvite, disableButton }) => {
  return (
    <ModalContainer>
      <ModalHeading>Connect Vendor</ModalHeading>
      <ModalBody>
        <VendorImage src={vendor.logo || DefaultImage} />
        <ModalSubHeading>{vendor.name || '-'}</ModalSubHeading>
        <SmallLabel>{vendor.url}</SmallLabel>
        {!isEmpty(vendor.productCategories) && (
          <ProductCategories>
            {vendor.productCategories.map((category, i) => (
              <Chip
                key={i}
                label={category}
                style={{ minWidth: 100, margin: 4 }}
              />
            ))}
          </ProductCategories>
        )}
      </ModalBody>
      <Actions>
        <InviteButton
          onClick={() => handleInvite(vendor.vendorId)}
          text="Connect"
          disabled={disableButton}
        />
      </Actions>
    </ModalContainer>
  );
};

InviteVendorModal.propTypes = {
  vendor: PropTypes.object,
  handleInvite: PropTypes.func,
  disableButton: PropTypes.bool
};

export default InviteVendorModal;
