import React from 'react';
import PropTypes from 'prop-types';
import { LoadingAnimation, LoadingContainer } from './styles';

const Loader = ({ w, h, min_h, isCenter, fSize, color, bgColor, isFlex }) => {
  return (
    <LoadingContainer
      w={w}
      h={h}
      min_h={min_h}
      isCenter={isCenter}
      fSize={fSize}
      isFlex={isFlex}
    >
      <LoadingAnimation color={color} bgColor={bgColor} />
    </LoadingContainer>
  );
};

Loader.defaultProps = {
  w: '100%',
  h: '100%',
  min_h: '32px',
  isCenter: false,
  message: '',
  fSize: '14px',
  color: '#333',
  bgColor: 'white',
  isFlex: true
};

Loader.propTypes = {
  w: PropTypes.string,
  h: PropTypes.string,
  min_h: PropTypes.string,
  isCenter: PropTypes.bool,
  message: PropTypes.string,
  fSize: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  isFlex: PropTypes.bool
};

export default Loader;
