import styled from 'styled-components';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const SelectVendor = styled.div`
  width: 100%;
  max-width: 300px;
`;

const CatalogContainer = styled.div`
  margin-top: 25px;
`;

const AcceptInvite = styled.div`
  min-height: 100vh;
  text-align: center;
`;

const NoInvites = styled.div`
  min-height: 100vh;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.LIGHT_FONT};
  text-align: center;
`;

const SelectVendorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
`;

const Message = styled.div`
  margin: 8px 0;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
`;

export {
  Container,
  HeadingSection,
  Heading,
  SelectVendor,
  CatalogContainer,
  AcceptInvite,
  NoInvites,
  SelectVendorContainer,
  Row,
  Message
};
