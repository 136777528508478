import styled, { css } from 'styled-components';

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
`;

const DropdownHero = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: 0;
  outline: 0;
  border-bottom: 1px solid ${props => props.theme.BLACK};
  padding: 8px 14px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  flex-wrap: wrap;

  ${p =>
    p.hasSelectedValue &&
    css`
      border-color: ${p => p.theme.SOLID_BLACK};
      font-weight: ${p => p.theme.SEMI_BOLD_FONT};
    `}

  ${p =>
    p.isDisabled &&
    css`
      border-color: ${p => p.theme.GALLERY};
      background-color: ${p => p.theme.GALLERY} !important;
      cursor: default;

      > span {
        color: ${p => p.theme.SILVER_CHALICE};
        background-color: ${p => p.theme.GALLERY} !important;
      }
    `}
`;

const DropdownPlaceholder = styled.span`
  color: ${p => p.theme.MINE_SHAFT};
  position: absolute;
  transition: 0.2s all cubic-bezier(0, 0, 0.3, 1);
  z-index: 1;
  border-radius: 2px;
  font-weight: ${p => p.theme.MEDIUM_FONT};

  ${p =>
    p.isOpenOrHasSelectedValue &&
    css`
      background: ${p => p.theme.WHITE};
      transform: translate(-5%, -180%);
      padding: 0 4px;
      font-size: ${p => p.theme.X_SMALL};
      transition: 0.2s all cubic-bezier(0, 0, 0.3, 1);
    `}
`;

const DropdownIndicator = styled.span`
  position: absolute;
  right: 15px;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1);
  transform: ${p => (p.isOpen ? 'rotate(180deg)' : '')};
`;

const DropdownOptions = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: auto;
  background: ${p => p.theme.WHITE};
  box-shadow: 0 0 30px 0 rgba(41, 49, 73, 0.1);
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #ccc;
  max-height: ${p => (p.isFilteredOptionsLengthExceeds7 ? '250px' : null)};
`;

const DropdownPlaceholderOption = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  color: ${p => p.theme.GREY};
`;

const DropdownOption = styled.div`
  padding: 11px 15px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;

  :hover {
    background-color: rgba(41, 49, 73, 0.1);
    font-weight: ${p => p.theme.SEMI_BOLD_FONT};
  }
`;

const SelectedOption = styled.span`
  text-align: left;
  font-weight: ${p => (p.isSelectedBold ? p.theme.SEMI_BOLD_FONT : 'normal')};
`;

const SelectedMulipleOption = styled.div`
  background: ${p => p.theme.ONAHAU};
  padding: 2px 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const SelectedMulipleOptionText = styled.span`
  color: ${p => p.theme.SOLID_BLACK};
  font-size: 14px;
  position: relative;
  border-right: 1px solid ${p => p.theme.SILVER_SAND};
  padding-right: 4px;
  margin-right: 4px;
`;

const SelectedMulipleOptionRemove = styled.span`
  color: ${p => p.theme.PERSIMMON};
`;

const DropdownSearchInput = styled.input`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  background: transparent;
`;
const DropDownIcon = styled.div`
position: absolute;
color: #a5a1a1;
    right: 10px;
    top: 7px;
}
`;

export {
  DropdownContainer,
  DropdownHero,
  DropdownPlaceholder,
  DropdownIndicator,
  DropdownOptions,
  DropdownPlaceholderOption,
  DropdownOption,
  SelectedOption,
  SelectedMulipleOption,
  SelectedMulipleOptionText,
  SelectedMulipleOptionRemove,
  DropdownSearchInput,
  DropDownIcon
};
