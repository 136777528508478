import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: '10px',
    cursor: 'pointer'
  },
  text: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

const BackToDashboard = props => {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <ArrowBackIcon
        className={classes.icon}
        fontSize="small"
        onClick={onClick}
      />
      <Typography variant="body2" className={classes.text} onClick={onClick}>
        Back to Dashboard
      </Typography>
    </Box>
  );
};

export default BackToDashboard;
