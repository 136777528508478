import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import {
  InputContainer,
  InputSuggestions,
  InputSuggestionsItem,
  Input,
  InputLabel
} from './styles';

class TextInput extends React.Component {
  state = {
    touched: false,
    isFocused: false
  };

  setFocus = () => {
    if (this.props.autoFocus) {
      setTimeout(() => {
        if (this.inputSearch) {
          this.inputSearch.focus();
        }
        return true;
      }, 0);
    }
    return false;
  };

  onBlur = e => {
    this.setState({
      touched: true,
      isFocused: false
    });

    if (this.props.onBlur) this.props.onBlur(e);
  };

  onInputFocus = () => this.setState({ isFocused: true });

  onKeyDown = event => {
    const {
      submitOnEnter,
      isAutoSuggest,
      suggestions = [],
      onSuggestionSelect
    } = this.props;
    if (
      !submitOnEnter &&
      isAutoSuggest &&
      suggestions.length &&
      event.key === 'Enter'
    ) {
      event.preventDefault();
      if (onSuggestionSelect) onSuggestionSelect(event, suggestions[0]);
    }
  };

  onSuggestionSelect = selectedOption => event => {
    const { onSuggestionSelect } = this.props;

    if (onSuggestionSelect) onSuggestionSelect(event, selectedOption);
  };

  renderSuggestions = () => {
    const { suggestions = [], suggestionEl } = this.props;

    if (!suggestions.length) return null;

    if (suggestionEl) return suggestionEl(suggestions);

    return (
      <InputSuggestions>
        {suggestions.map(item => (
          <InputSuggestionsItem
            key={item}
            onMouseDown={this.onSuggestionSelect(item)}
          >
            {item}
          </InputSuggestionsItem>
        ))}
      </InputSuggestions>
    );
  };

  render() {
    const {
      name,
      placeHolder,
      value,
      onChange,
      type,
      inputType,
      required,
      size,
      id,
      disabled,
      autocomplete,
      isAutoSuggest,
      containerStyleClass,
      inputStyleClass,
      inputStyle,
      title,
      step,
      min,
      max,
      pattern,
      customPlaceholder,
      isDisabled,
      maxLength,
      ...rest
    } = this.props;
    const { isFocused } = this.state;

    return isDisabled === true ? (
      <InputContainer className={containerStyleClass} {...rest}>
        <Input
          readOnly
          placeholder={customPlaceholder ? '' : placeHolder}
          style={inputStyle}
          disabled={disabled}
          data-testid="textInput"
          id={id}
          value={value || ''}
          name={name}
          onFocus={this.onInputFocus}
          onChange={onChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          inputType={inputType}
          size={size}
          touched={this.state.touched || false}
          filled={value || false}
          className={inputStyleClass}
          type={type}
          required={required}
          autoComplete={autocomplete}
          ref={input => {
            this.inputSearch = input;
          }}
          title={title}
          step={step}
          min={min}
          max={max}
          pattern={pattern}
          autoFocus={this.setFocus()}
          maxLength={maxLength}
        />
        {customPlaceholder && placeHolder && (
          <InputLabel
            htmlFor={id}
            filled={value || false}
            onClick={() => {
              this.inputSearch.focus();
            }}
          >
            {placeHolder}
          </InputLabel>
        )}

        {isAutoSuggest && isFocused && this.renderSuggestions()}
      </InputContainer>
    ) : (
      <InputContainer className={containerStyleClass} {...rest}>
        <Input
          placeholder={customPlaceholder ? '' : placeHolder}
          style={inputStyle}
          disabled={disabled}
          data-testid="textInput"
          id={id}
          value={value || ''}
          name={name}
          onFocus={this.onInputFocus}
          onChange={onChange}
          onKeyDown={this.onKeyDown}
          inputType={inputType}
          size={size}
          touched={this.state.touched || false}
          filled={value || false}
          className={inputStyleClass}
          type={type}
          required={required}
          autoComplete={autocomplete}
          ref={input => {
            this.inputSearch = input;
          }}
          title={title}
          step={step}
          min={min}
          max={max}
          pattern={pattern}
          autoFocus={this.setFocus()}
          maxLength={maxLength}
        />
        {customPlaceholder && placeHolder && (
          <InputLabel
            htmlFor={id}
            filled={value || false}
            onClick={() => {
              this.inputSearch.focus();
            }}
          >
            {placeHolder}
          </InputLabel>
        )}

        {isAutoSuggest && isFocused && this.renderSuggestions()}
      </InputContainer>
    );
  }
}

TextInput.defaultProps = {
  type: 'text',
  inputType: 'primary',
  required: false,
  size: 'default-size',
  id: '',
  isAutoSuggest: false,
  onSuggestionSelect: null,
  suggestions: [],
  suggestionEl: null,
  submitOnEnter: false,
  placeHolder: null,
  containerStyleClass: [],
  inputStyleClass: [],
  inputStyle: {},
  title: '',
  customPlaceholder: true,
  autoFocus: false,
  value: ''
};

TextInput.propTypes = {
  placeHolder: PropTypes.string,
  value: oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  inputType: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  isAutoSuggest: PropTypes.bool,
  suggestions: PropTypes.array,
  onSuggestionSelect: PropTypes.func,
  suggestionEl: PropTypes.func,
  submitOnEnter: PropTypes.bool,
  containerStyleClass: PropTypes.array,
  inputStyleClass: PropTypes.array,
  inputStyle: PropTypes.object,
  title: PropTypes.string,
  pattern: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  customPlaceholder: PropTypes.bool,
  autoFocus: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default TextInput;
