import styled from 'styled-components';
import { Secondary } from '../../atoms/button';

const VendorOnBoardForm = styled.div`
  > div {
    border-bottom: unset;
    input {
      width: 500px;
      height: 60px;
      border-radius: 5px;
      border: solid 1px ${props => props.theme.PINK_SWAN};
      text-align: left;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const Next = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const Container = styled.div`
  display: block;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const SubHeading = styled.span`
  font-size: ${props => props.theme.SMALL};
  margin-bottom: 20px;
`;

const VendorContainer = styled.div`
  margin-top: 20px;
  border-bottom: unset;
`;

const NextButton = styled.div`
  margin-top: 70px;
`;

const GenderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;
  height: 60px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px #b2b2b2;
  padding: 20px;
`;

const Gender = styled.input`
  max-width: 20px;
  margin-right: 10px;
`;

const UserSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
`;

const List = styled.div`
  width: 40px;
  height: 14px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.BLACK};
  position: relative;
  top: -2px;
`;

const Seperator = styled.hr`
  border: 1px solid #ccc;
  height: 300%;
  position: relative;
  top: -25px;
  :last-child {
    border: unset;
    height: 0;
  }
`;

export {
  VendorOnBoardForm,
  Next,
  Container,
  Heading,
  SubHeading,
  VendorContainer,
  NextButton,
  GenderContainer,
  Gender,
  List,
  UserSelectionContainer,
  Seperator
};
