import React from 'react';
import { HeadingSection, Heading } from './styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom } from '@material-ui/core';

const PageTitle = props => {
  const { title, tooltip } = props;
  return (
    <HeadingSection>
      <Heading>{title}</Heading>
      <Tooltip TransitionComponent={Zoom} title={tooltip} arrow>
        <InfoRoundedIcon />
      </Tooltip>
    </HeadingSection>
  );
};

export default PageTitle;
