import React from 'react';
import Modal from '../../../../templates/Modal';
import { RemoveVendorModal, ConfirmOption } from '../styles';

const UnlinkVendorConfirmationModal = ({ id, unlinkVendor, closeModal }) => {
  return (
    <Modal model="custom" width={600} height={200} close={closeModal}>
      <RemoveVendorModal>
        <div>Are you sure you want to remove this Vendor from your list ?</div>
        <>
          <ConfirmOption text="Yes" onClick={() => unlinkVendor(id)} />
          <ConfirmOption text="No" onClick={closeModal} />
        </>
      </RemoveVendorModal>
    </Modal>
  );
};

export default UnlinkVendorConfirmationModal;
