import React from 'react';
import GoogleLocationSearchPopover from '../../molecules/GoogleLocationSearchPopover';
import styled from 'styled-components';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes
} from '../../../utils';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import location_ico from './location_ico.png';

const LocationWidgetContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LocationWidgetIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const LocationAddress = styled.div`
  padding: 8px;
  max-width: 350px;
`;

class LocationWidget extends React.Component {
  state = {
    formattedAddress: null
  };

  componentDidMount() {
    const locationInfo = localStorage.getItem('locationInfo');
    if (locationInfo) {
      this.setState({ formattedAddress: locationInfo });
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          pos => {
            this.getLocationDetails(
              null,
              pos.coords.latitude,
              pos.coords.longitude
            );
          },
          error => {},
          { timeout: 5000 }
        );
      }
    }
  }

  getLocationDetails = async (locationData, lat, long) => {
    let params = '';
    const token = getAuthToken();
    const headers = { authorization: token };
    let response;

    if (locationData) {
      params = `${locationData.country_code}`;
      response = await getApiResponseObject(
        `${API_BASE_URL}/currency/${params}`,
        headers
      );
    } else {
      params = `geoLat=${lat}&geoLng=${long}`;
      response = await getApiResponseObject(
        `${API_BASE_URL}/address?${params}`,
        headers
      );

      locationData = response.data;
    }

    if (locationData) {
      if (this.props.setCurrencyCallback) {
        this.props.setCurrencyCallback(response.data.currency);
      }

      let formattedAddress = '';
      formattedAddress += locationData.city ? locationData.city + ', ' : '';
      formattedAddress += locationData.state ? locationData.state + ', ' : '';
      formattedAddress += locationData.country
        ? locationData.country + ' '
        : '';
      formattedAddress += locationData.postal_code
        ? '(' + locationData.postal_code + ')'
        : '';

      this.setState({ formattedAddress: formattedAddress });
      localStorage.setItem('locationInfo', formattedAddress);
    } else {
      toaster('Invalid input');
    }
  };

  render() {
    const { formattedAddress } = this.state;
    return (
      <LocationWidgetContainer>
        <LocationWidgetIcon src={location_ico} />
        {this.state.formattedAddress ? (
          <LocationAddress>{this.state.formattedAddress}</LocationAddress>
        ) : (
          <LocationAddress>Location Unavailable</LocationAddress>
        )}
        <GoogleLocationSearchPopover
          formattedAddress={formattedAddress}
          getLocationDetails={this.getLocationDetails}
        />
      </LocationWidgetContainer>
    );
  }
}

export default LocationWidget;
