import { API_BASE_URL } from '../../../../config/env';
import { countriesData, US_State_Codes } from './locationData';
import {
  getApiResponseObject,
  successStatusCodes,
  setCountriesList,
  getShippingInfo,
  getBilllingInfo
} from '../../../../utils';

export const fetchCountriesList = async () => {
  let countriesList = [];
  const res = await getApiResponseObject(`${API_BASE_URL}/countries`);

  if (successStatusCodes.includes(res?.status) && res?.data?.data?.length > 0) {
    const countriesData = res.data.data;

    countriesData.forEach(country => {
      countriesList.push({
        name: country.name,
        iso2: country.iso2
      });
    });
  }

  return countriesList;
};

export const fetchStatesList = async countryCode => {
  let statesList = [];

  const res = await getApiResponseObject(
    `${API_BASE_URL}/states/${countryCode}`
  );

  if (successStatusCodes.includes(res?.status) && res?.data?.data?.length > 0) {
    const statesData = res.data.data;

    statesData.forEach(state => {
      statesList.push({
        name: state.name,
        iso2: state.state_code
      });
    });
  }

  return statesList;
};

export const fetchUSStateCodeByName = stateName => {
  let stateCode = '';
  if (!stateName) return null;
  const US_State_Names = Object.values(US_State_Codes);
  for (let i = 0; i < US_State_Names.length; i++) {
    if (US_State_Names[i] === stateName) {
      stateCode = Object.keys(US_State_Codes)[i];
      break;
    }
  }
  return stateCode;
};

export const fetchCountryCodeByName = countryName => {
  let countryCode = '';
  if (!countryName) return null;
  for (let countryData of countriesData) {
    if (countryData.name === countryName) {
      countryCode = countryData.iso2;
      break;
    }
  }
  return countryCode;
};
