import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API_BASE_URL } from '../../../../../config/env';
import * as actions from '../../../../../redux/actions/uiActions';
import {
  deleteWithResponseObject,
  getApiResponseObject,
  getAuthToken,
  successStatusCodes,
  postWithResponseObject,
  showApiErrorToast
} from '../../../../../utils';
import EmptyState from '../../../../atoms/EmptyState';
import PaginationControls from '../../../../atoms/paginationControls';
import SearchBar from '../../../../atoms/searchBar';
import { toaster } from '../../../../atoms/toaster';
import { MESSAGE_SEVERITY, withSnackbar } from '../../../../hoc/withSnackbar';
import { PaginationWrapper, VendorsContainer, Wrapper } from '../styles';
import ConnectedVendorCard from './connectedVendorCard';
import UnlinkVendorConfirmationModal from './unlinkVendorModal';

class ConnectedVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      vendors: [],
      vendorIds: [],
      vendorAnalytics: {},
      isModalOpen: false,
      modalContent: null,
      // Below required for search and pagination implementation
      totalCount: null,
      totalResultsCount: 0,
      fetchParams: {
        count: 20, // set this for pagesize
        offset: 0,
        text: ''
      }
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.fetchLinkedVendors(true);
  }

  fetchLinkedVendors = async (setTotalCount = false) => {
    const { fetchParams } = this.state;

    this.props.showSpinnerBackdrop();

    const response = await getApiResponseObject(
      `${API_BASE_URL}/networks/manage-vendors`,
      { authorization: getAuthToken() },
      fetchParams
    );

    if (!successStatusCodes.includes(response.status)) {
      this.props.hideSpinnerBackdrop();
      return toaster(
        'Failed to fetch linked vendors. Please try reloading the page.'
      );
    } else {
      this.setState(
        {
          vendors: Array.isArray(response.data.vendors)
            ? response.data.vendors
            : [],
          vendorIds: Array.isArray(response.data.vendorIds)
            ? response.data.vendorIds
            : [],
          isLoading: false,
          totalCount: setTotalCount
            ? response.data.count
            : this.state.totalCount,
          totalResultsCount: response.data.count
        },
        () => {
          if (!isEmpty(this.state.vendorIds)) this.fetchVendorAnalytics();
          else return;
        }
      );
    }
    this.props.hideSpinnerBackdrop();
  };

  fetchVendorAnalytics = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const requestBody = {
      vendorIds: this.state.vendorIds
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/networks/manage-vendors/analytics`,
      requestBody,
      headers
    );
    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      this.setState({
        vendorAnalytics: response.data.vendorNetworkAnalytics
      });
    }
  };

  // required for search and pagination implementation
  updateFetchParams = fetchParams => {
    this.setState({ fetchParams });
    this.fetchLinkedVendors();
  };

  unlinkVendorConfirmation = vendorId => {
    this.setState({
      modalContent: (
        <UnlinkVendorConfirmationModal
          vendorId={vendorId}
          unlinkVendor={() => this.unlinkVendor(vendorId)}
          closeModal={this.closeModal}
        />
      ),
      isModalOpen: true
    });
  };

  unlinkVendor = async vendorId => {
    const token = getAuthToken();
    const response = await deleteWithResponseObject(
      `${API_BASE_URL}/network-vendor-connections/${vendorId}`,
      { authorization: token }
    );

    if (successStatusCodes.includes(response.status)) {
      this.closeModal();
      this.props.showSnackbar({
        message: 'Connection removed successfully',
        severity: MESSAGE_SEVERITY.success
      });
      this.setState(
        {
          fetchParams: {
            count: 20, // set this for pagesize
            offset: 0,
            text: ''
          },
          isLoading: true
        },
        () => this.fetchLinkedVendors(true)
      );
    } else {
      this.closeModal();
      this.setState({ isLoading: false });
      this.props.showSnackbar({
        error: response.data,
        message: 'Failed to remove connection',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  closeModal = () => {
    this.setState({ modalContent: null, isOpen: false });
  };

  render() {
    const {
      isLoading,
      vendors,
      vendorIds,
      totalCount,
      isModalOpen,
      modalContent
    } = this.state;
    return (
      <Wrapper>
        {!isLoading && totalCount > 0 ? (
          <React.Fragment>
            <SearchBar
              placeholder="Search.."
              fetchParams={this.state.fetchParams}
              updateFetchParams={this.updateFetchParams}
            />
            {vendors.length > 0 ? (
              <VendorsContainer>
                {vendors.map((vendor, index) => (
                  <ConnectedVendorCard
                    key={index}
                    vendor={vendor.vendor}
                    store={vendor.store}
                    analytics={this.state.vendorAnalytics[vendor.vendor.id]}
                    vendorCardActionMethods={{
                      unlinkVendor: () =>
                        this.unlinkVendorConfirmation(vendor.vendor.id)
                    }}
                  />
                ))}
              </VendorsContainer>
            ) : (
              <EmptyState errorMessage="No vendors found" />
            )}
            <PaginationWrapper>
              <PaginationControls
                fetchParams={this.state.fetchParams}
                totalItemCount={this.state.totalResultsCount}
                updateFetchParams={this.updateFetchParams}
              />
            </PaginationWrapper>
          </React.Fragment>
        ) : (
          !isLoading && <EmptyState errorMessage="No vendors linked yet" />
        )}
        {isModalOpen ? modalContent : null}
      </Wrapper>
    );
  }
}

ConnectedVendors.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withSnackbar(
  withRouter(connect(null, mapDispatchToProps)(ConnectedVendors))
);
