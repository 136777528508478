import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import DiscoverVendors from './DiscoverVendors/discoverVendors';
import ConnectedVendors from './ConnectedVendors/connectedVendors';
import { PanelContent } from './styles';
import shoptypeThemes from '../../../../theme';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import PageTitle from '../../../common/PageTitle';

const styles = makeStyles({
  mainContainer: {
    padding: '24px 48px',
    width: '100%',

    '@media (max-width: 768px)': {
      padding: '24px 24px'
    }
  },
  tabs: {
    backgroundColor: shoptypeThemes.default.FLORAL_WHITE,
    position: 'sticky',
    top: -15,
    zIndex: 999,
    boxShadow: '0 3px 2px -2px rgba(0,0,0,.1)'
  },
  tab: {
    color: shoptypeThemes.default.MINE_SHAFT,
    fontSize: shoptypeThemes.default.X_LARGE,
    textTransform: 'none',
    textAlign: 'start',
    width: '200px',

    '@media (max-width: 768px)': {
      width: '180px',
      fontSize: '1rem'
    },

    '@media (max-width: 576px)': {
      width: '160px',
      fontSize: '0.9rem'
    }
  },
  selectedTab: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    height: '3px'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      {value === index && <PanelContent>{children}</PanelContent>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const NetworkOperatorVendors = () => {
  const classes = styles();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleTabChange = (_, newSelectedTabIndex) => {
    setSelectedTabIndex(newSelectedTabIndex);
  };

  return (
    <div className={classes.mainContainer}>
      <PageTitle
        title={'Vendor Management'}
        tooltip={NETWORK_OPERATOR_INFO.pageDescription.Vendors}
      />
      <Tabs
        className={classes.tabs}
        value={selectedTabIndex}
        TabIndicatorProps={{ className: classes.selectedTab }}
        onChange={handleTabChange}
      >
        <Tab className={classes.tab} label="Discover Vendors" />
        <Tab className={classes.tab} label="Connected Vendors" />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <DiscoverVendors />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <ConnectedVendors />
      </TabPanel>
    </div>
  );
};

NetworkOperatorVendors.propTypes = {
  classes: PropTypes.object
};

export default withRouter(NetworkOperatorVendors);
