import styled from 'styled-components';

const TagsInputMainContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
  min-height: 150px;
  border: solid 1px #b2b2b2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  input {
    height: 150px;
    width: 100%;
    border: none;
    outline: none;
  }
`;

const TagsInputContainer = styled.div`
  top: 0;
  position: relative;
  margin: 0 auto;
  padding: 5px;
  width: 500px;
  font-size: 14px;
  max-height: 150px;
  overflow: auto;
  border: 0;
`;

const TagSpan = styled.span`
  position: relative;
  top: 60px;
  display: inline-flex;
  padding: 2px 5px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 7px;
  background-color: #ede5c0;
`;

const DeleteButton = styled.button`
  display: inline-block;
  margin-left: 5px;
  margin-top: 3px;
  padding: 0;
  width: 15px;
  height: 15px;
  background-color: #ede5c0;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  outline: none;
  user-select: none;

  ::after {
    content: 'X';
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
  }
`;

const HelpSpan = styled.span`
  position: relative;
  top: 30px;
  font-size: 12px;
  color: #555;
  padding: 5px;
  max-width: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  TagsInputMainContainer,
  TagsInputContainer,
  TagSpan,
  DeleteButton,
  HelpSpan
};
