import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import history from '../../../history';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import {
  postWithResponseObject,
  successStatusCodes,
  getApiResponseObject,
  showApiErrorToast
} from '../../../utils';
import TagsInput from '../../molecules/interests';
import { getAuthToken } from '../../../utils';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/uiActions';
import {
  Next,
  Container,
  Heading,
  SubHeading,
  VendorContainer,
  NextButton,
  GenderContainer,
  Gender,
  List,
  UserSelectionContainer,
  Seperator
} from './styles';
import { isEmpty } from 'lodash';

class CosellerOnBoardInterests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socialMediaAccounts: [],
      interests: [],
      genderSelection: ['MALE', 'FEMALE', 'OTHERS'],
      tags: [],
      userSelection: '',
      disableButton: false
    };
  }

  componentDidMount() {
    const signUpData = this.props.history.location.data;
    const personalInfoData = { ...signUpData };
    this.setState({
      socialMediaAccounts: personalInfoData.socialMediaAccounts || ''
    });
  }

  inputChangeHandler = e => {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleRadio = e => {
    this.setState({ userSelection: e.target.value });
  };

  handleTagsChange = tags => {
    this.setState({ tags: tags });
  };

  handleSubmit = async () => {
    const token = getAuthToken();
    const { socialMediaAccounts, tags, userSelection } = this.state;

    const data = socialMediaAccounts
      .map(item => {
        return {
          socialMediaName: item.socialMediaName,
          socialMediaLink: item.socialMediaLink
        };
      })
      .filter(
        account =>
          !isEmpty(account) &&
          account.socialMediaLink &&
          account.socialMediaLink !== '' &&
          account.socialMediaName &&
          account.socialMediaName !== ''
      );

    let response = '';
    const requestBody = {
      socialMediaAccounts: data,
      gender: userSelection,
      interests: tags
    };
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();
    this.setState({ disableButton: true });
    response = await postWithResponseObject(
      `${API_BASE_URL}/cosellers`,
      requestBody,
      headers
    );
    this.setState({ disableButton: false });
    this.props.hideSpinnerBackdrop();

    if (successStatusCodes.includes(response.status)) {
      localStorage.setItem('token', JSON.stringify(response.data.token) || {});
      const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
        authorization: token
      });

      localStorage.setItem(
        'userProfile',
        JSON.stringify(profileRes.data || {})
      );

      history.push('/cosellerdashboard');
    } else {
      showApiErrorToast(response.data);
    }
  };

  render() {
    const { genderSelection, tags, disableButton } = this.state;
    return (
      <Container>
        <Heading>Create an account</Heading>
        <SubHeading>Add your details</SubHeading>
        <VendorContainer>
          <GenderContainer>
            {genderSelection.map((type, index) => {
              return (
                <>
                  <UserSelectionContainer key={index}>
                    <label>
                      <Gender
                        type="radio"
                        name="loginSelection"
                        id={type}
                        value={type}
                        onChange={e => this.handleRadio(e)}
                      />
                    </label>
                    <List>{type}</List>
                  </UserSelectionContainer>
                  <Seperator />
                </>
              );
            })}
          </GenderContainer>
          <TagsInput
            value={tags}
            onChange={tags => this.handleTagsChange(tags)}
          />
          <NextButton>
            <Next
              text="Sign Up"
              onClick={this.handleSubmit}
              disabled={disableButton}
            />
          </NextButton>
        </VendorContainer>
      </Container>
    );
  }
}

CosellerOnBoardInterests.propTypes = {
  history: PropTypes.object.isRequired,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(CosellerOnBoardInterests)
);
