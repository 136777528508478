import styled, { css } from 'styled-components';
import { Secondary } from '../../atoms/button';
import Select from 'react-select';

const VendorOnBoardForm = styled.div`
  max-height: 200px;
  > div {
    border-bottom: unset;
    input {
      padding: 20px;
      outline: none;
      width: 500px;
      height: 60px;
      border: solid 1px ${props => props.theme.PINK_SWAN};
      text-align: left;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const Next = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const Container = styled.div`
  display: block;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.HEADING};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

const SubHeading = styled.span`
  font-size: ${props => props.theme.SMALL};
  margin-bottom: 20px;
`;

const VendorContainer = styled.div`
  margin-top: 20px;
  border-bottom: unset;
`;

const NextButton = styled.div`
  margin-top: 70px;
`;

const SocialAccount = styled.div`
  display: flex;
  max-width: 603px;
  :last-child {
    border-bottom: 1px solid ${props => props.theme.PINK_SWAN};
  }
  > div {
    border-bottom: unset;
    > div {
      max-height: 60px;
      border-radius: 0;
      border-bottom: 0;
      width: 90px;
      align-content: center;
      > div {
        padding: unset;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 0 20px 0;
        > span {
          width: 0;
          background-color: unset;
        }
      }
    }
    input {
      width: 400px;
      height: 60px;
      border: solid 1px ${props => props.theme.PINK_SWAN};
      border-left: 0;
      border-bottom: 0;
      text-align: left;
      ::placeholder {
        text-align: left;
      }
    }
  }
`;

const Label = styled.div`
  display: flex;
`;

const SelectDropDown = styled(Select)``;

const AddMoreLinks = styled.button`
  width: 490px;
  outline: none;
  height: 60px;
  border-radius: 5px;
  border: dashed 1px ${props => props.theme.CORDUROY};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: ${props => props.theme.SMALL};

  ${props =>
    props.isDisabled &&
    css`
      background-color: ${props => props.theme.PINK_SWAN};
    `}
`;

const AddIcon = styled.div`
  margin-right: 15px;
`;

export {
  VendorOnBoardForm,
  Next,
  Container,
  Heading,
  SubHeading,
  VendorContainer,
  NextButton,
  SocialAccount,
  Label,
  SelectDropDown,
  AddMoreLinks,
  AddIcon
};
