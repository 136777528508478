import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Grid
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import DefaultProductImage from '../../assets/images/photo.svg';
import Currency from '../molecules/Currency';
import theme from '../../theme';
import Carousel from '../organisms/Carousel';
import EmptyState from '../atoms/EmptyState';
import { VendorName } from '../templates/ProductDetail/styles';
import { currencySymbol } from '../../utils';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (min-width:1400px)': {
      marginTop: '25px'
    },

    '@media (min-width:960px) and (max-width:1399px)': {
      display: 'grid !important',
      gridTemplateColumns: 'auto',
      gridRowGap: '8px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center'
    },

    '@media (min-width:800px) and (max-width:960px)': {
      marginTop: '25px'
    },

    '@media (max-width:800px)': {
      display: 'grid !important',
      gridTemplateColumns: 'auto',
      gridRowGap: '8px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  media: {
    height: 220
  },
  secondButton: {
    textTransform: 'none',
    marginTop: 8
  },
  cardContent: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: 0
    }
  },
  cardTypography: {
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  descTypography: {
    fontSize: '10px',
    maxWidth: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '10px',
    minHeight: '45px'
  },
  cardContainer: {
    flexGrow: 1
  },
  cardBtn: {
    textTransform: 'none',
    height: '28px',
    borderRadius: '2.5px',
    padding: '20px 7px',
    color: theme.default.BLACK,
    fontWeight: 'normal'
  },
  cardHeight: {
    minHeight: '420px',
    border: '0.25px solid rgba(0, 0, 0, 0.4)',
    borderRadius: '2.5px',
    background: 'transparent',
    padding: '7px',
    position: 'relative',
    cursor: 'pointer'
  },
  minHeight: {
    minHeight: '70px'
  },
  viewBtn: {
    background: theme.default.WHITE,
    border: `1px solid ${theme.default.RONCHI}`,
    minWidth: '70px',
    maxWidth: '240px',

    '&:hover': {
      backgroundColor: '#fffdf4'
    },

    '@media (min-width:1400px)': {
      marginRight: '8px'
    },

    '@media (min-width:800px) and (max-width:960px)': {
      marginRight: '8px'
    }
  },
  cosellBtn: {
    background: theme.default.RONCHI,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@media (min-width:960px) and (max-width:1399px)': {
      fontSize: '10px'
    }
  },
  mL2: {
    marginLeft: '2px'
  },
  carouselClass: {
    width: '100%',
    marginTop: 0,
    '& .slick-slider': {
      width: '100%',
      height: '100%',
      margin: 0
    },
    '& .slick-slide img': {
      width: '100%',
      minHeight: '200px'
    }
  },
  vendorName: {
    position: 'absolute',
    top: '187px',
    height: '15px',
    fontSize: '8px',
    right: '6px',
    padding: '2px 4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '80px'
  }
});

function STProductCards(props) {
  const classes = useStyles();

  const openShareModel = (event, product) => {
    event.stopPropagation();
    props.clickPublish(
      product.id,
      product.title,
      product.description,
      product.primaryImageSrc.imageSrc || DefaultProductImage,
      product.catalogId,
      product.currency
    );
  };
  return (
    <>
      <div className={classes.cardContainer}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent={isEmpty(props.productsData) ? 'center' : 'flex-start'}
          alignItems="flex-start"
        >
          {!isEmpty(props.productsData) ? (
            props.productsData.map(product => {
              let productImagesArray = [
                product.primaryImageSrc.imageSrc
                  ? product.primaryImageSrc.imageSrc
                  : DefaultProductImage
              ];
              if (!isEmpty(product.secondaryImageSrc)) {
                let secondaryImages = product.secondaryImageSrc.map(
                  ele => ele.imageSrc
                );
                productImagesArray = [
                  ...productImagesArray,
                  ...secondaryImages
                ];
                productImagesArray = productImagesArray.slice(0, 6);
              }
              return (
                <Grid item xs={12} sm={6} md={3} key={product.id}>
                  <Card
                    className={classes.cardHeight}
                    onClick={() => props.handleViewButtonClick(product.id)}
                  >
                    <div className={classes.media}>
                      <div
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      >
                        <Carousel
                          orientation="noOrientation"
                          showDots={true}
                          slidesToShow={1}
                          elementClick={() => {}}
                          imagesArray={productImagesArray}
                          width={200}
                          height={250}
                          className={classes.carouselClass}
                        />
                      </div>
                    </div>
                    <VendorName className={classes.vendorName}>
                      By {product.vendorName}
                    </VendorName>

                    <CardContent className={classes.cardContent}>
                      <Typography
                        className={classes.cardTypography}
                        gutterBottom
                        variant="h6"
                        component="h2"
                        title={product.title}
                      >
                        {product.title || '-'}
                      </Typography>
                      <Typography
                        title={product.description}
                        className={classes.descTypography}
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        {product.description || '-'}
                      </Typography>
                    </CardContent>
                    <div className={classes.buttonContainer}>
                      <Button
                        className={`${classes.cardBtn} ${classes.viewBtn}`}
                        color="secondary"
                        onClick={() => props.handleViewButtonClick(product.id)}
                      >
                        Price
                        <b className={classes.mL2}>
                          {` ${
                            product.currency
                              ? currencySymbol(product.currency)
                              : ''
                          }${`${
                            product?.variants?.length > 0
                              ? product.variants[0].discountedPrice
                              : ''
                          }`}`}
                        </b>
                      </Button>
                      <Button
                        className={`${classes.cardBtn} ${classes.cosellBtn}`}
                        color="primary"
                        onClick={event => openShareModel(event, product)}
                      >
                        Cosell and earn upto
                        <b className={classes.mL2}>
                          {product.variants && product.variants.length > 0 ? (
                            <Fragment>
                              <Currency currencyName={product.currency} />
                              {product.productCommission &&
                              product.productCommission.percentage > 0
                                ? product.variants[0].discountedPrice > 0
                                  ? (
                                      (product.productCommission.percentage /
                                        100) *
                                      product.variants[0].discountedPrice
                                    ).toFixed(2)
                                  : '0'
                                : '0'}
                            </Fragment>
                          ) : (
                            '—'
                          )}
                        </b>
                      </Button>
                    </div>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <EmptyState errorMessage="No products found" />
          )}
        </Grid>
      </div>
    </>
  );
}

export default withRouter(STProductCards);
