import React from 'react';
import PropTypes from 'prop-types';
import { Separator } from './styles';

const HorizontalSeparator = ({ label }) => {
  return <Separator>{label}</Separator>;
};

HorizontalSeparator.propTypes = {
  label: PropTypes.string
};

export default HorizontalSeparator;
