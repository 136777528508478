import React from 'react';
import PropTypes from 'prop-types';
import Logout from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PeopleIcon from '@material-ui/icons/People';
import GroupAdd from '@material-ui/icons/GroupAdd';
import LockIcon from '@material-ui/icons/Lock';
import history from '../../../history';
import {
  Button,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Typography,
  Chip,
  makeStyles
} from '@material-ui/core/';
import {
  VENDOR,
  COSELLER,
  NETWORK_OPERATOR,
  GLOBAL_EVENT_KEYS
} from '../../../constants/index.js';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    lineHeight: 'initial',
    padding: '5.34px 8px'
  },
  switchUserButton: {
    width: '100%',
    textTransform: 'none',
    lineHeight: 'initial',
    color: '#303030',
    backgroundColor: '#F0D64E',
    '&:hover': {
      backgroundColor: '#ebc918'
    },
    padding: 1
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: 8
  },
  menuAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: 12
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    backgroundColor: '#F0D64E',
    height: 'fit-content',
    fontSize: '0.7rem',
    cursor: 'pointer'
  },
  mainMenuItem: {
    padding: '12px 16px',
    alignItems: 'flex-start'
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#F0D64E'
    }
  }
}));

export default function UserProfileMenu({
  name,
  userType,
  userName,
  email,
  handleLogout,
  handleInvite
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let user = '';

  switch (userType) {
    case VENDOR:
      user = 'Vendor';
      break;
    case COSELLER:
      user = 'Coseller';
      break;
    case NETWORK_OPERATOR:
      user = 'Network';
  }

  const handleMenuItemClick = name => {
    switch (name) {
      case 'view profile':
        history.push(
          userType === NETWORK_OPERATOR ? '/network-profile' : '/profile'
        );
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;

      case 'edit profile':
        history.push(
          userType === NETWORK_OPERATOR ? '/network-profile' : '/edit-profile'
        );
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;

      case 'switch user':
        history.push('/users/platform');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;

      case 'change password':
        history.push(
          userType === VENDOR
            ? '/change-password'
            : userType === COSELLER
            ? '/coseller/change-password'
            : '/networkoperator/change-password'
        );
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;
      case 'refer':
        handleClose();
        handleInvite();
        break;
      case 'referral tree':
        history.push('/referrals');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;
      case 'api keys':
        history.push('/api-keys');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;
      case 'logout':
        handleLogout();
    }
  };

  const profileDetails = (
    <div className={`${classes.row} ${classes.mainMenuItem}`}>
      <Avatar className={classes.menuAvatar} />
      <div>
        <Typography variant="body1" gutterBottom>
          {name}
        </Typography>
        {userName && (
          <Typography variant="body2" gutterBottom>
            {`${
              userType === VENDOR ? 'vendor:' : 'Network Operator:'
            } ${userName}`}
          </Typography>
        )}
        <Typography variant="body2" gutterBottom>
          {email}
        </Typography>
        <Button
          className={classes.switchUserButton}
          size="small"
          startIcon={<PeopleIcon />}
          onClick={() => handleMenuItemClick('switch user')}
        >
          Switch Profile
        </Button>
      </div>
    </div>
  );
  return (
    <div>
      <Button className={classes.button} onClick={handleClick}>
        <div className={classes.row}>
          <Avatar className={classes.avatar} />
          <div>
            <div>{name}</div>
            <Chip className={classes.chip} label={user} />
          </div>
        </div>
      </Button>
      {userType === VENDOR && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {profileDetails}
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('edit profile')}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('change password')}
          >
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('refer')}
          >
            <ListItemIcon>
              <GroupAdd fontSize="small" />
            </ListItemIcon>
            Refer
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('referral tree')}
          >
            <ListItemIcon>
              <AccountTreeIcon fontSize="small" />
            </ListItemIcon>
            Referral Tree
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('api keys')}
          >
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" />
            </ListItemIcon>
            API Keys
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('logout')}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
      {userType === COSELLER && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {profileDetails}
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('view profile')}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('change password')}
          >
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('refer')}
          >
            <ListItemIcon>
              <GroupAdd fontSize="small" />
            </ListItemIcon>
            Refer
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('referral tree')}
          >
            <ListItemIcon>
              <AccountTreeIcon fontSize="small" />
            </ListItemIcon>
            Referral Tree
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('logout')}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
      {userType === NETWORK_OPERATOR && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {profileDetails}
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('edit profile')}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('change password')}
          >
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('refer')}
          >
            <ListItemIcon>
              <GroupAdd fontSize="small" />
            </ListItemIcon>
            Refer
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('referral tree')}
          >
            <ListItemIcon>
              <AccountTreeIcon fontSize="small" />
            </ListItemIcon>
            Referral Tree
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('api keys')}
          >
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" />
            </ListItemIcon>
            API Keys
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleMenuItemClick('logout')}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}

UserProfileMenu.propTypes = {
  name: PropTypes.string,
  userType: PropTypes.string,
  userName: PropTypes.string,
  email: PropTypes.string,
  handleLogout: PropTypes.func,
  handleInvite: PropTypes.func
};
