import React from 'react';
import shoptypeThemes from '../../../theme';
import AllOrdersContent from './AllOrders';
import RequestsContent from './Requests';
import { Tabs, Tab, withStyles } from '@material-ui/core';
import { Container, HeadingSection, Heading, TabContent } from './styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom } from '@material-ui/core';
import { VENDOR_INFO } from '../../../constants/tooltip';

const useStyles = () => ({
  tabs: {
    backgroundColor: shoptypeThemes.default.FLORAL_WHITE,
    position: 'sticky',
    top: -15,
    zIndex: 999,
    boxShadow: '0 3px 2px -2px rgba(0,0,0,.1)'
  },
  tab: {
    color: shoptypeThemes.default.MINE_SHAFT,
    fontSize: shoptypeThemes.default.X_LARGE,
    textTransform: 'none'
  },
  selectedTab: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    height: '3px'
  }
});

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedTabIndex: 0 };
  }

  handleTabChange = (_, newSelectedTabIndex) => {
    this.setState({ selectedTabIndex: newSelectedTabIndex });
  };

  render() {
    const { selectedTabIndex } = this.state;
    const { classes } = this.props;

    return (
      <Container>
        <HeadingSection>
          <Heading>Orders & Return Requests</Heading>
          <Tooltip
            TransitionComponent={Zoom}
            title={VENDOR_INFO.pageDescription.Orders}
            arrow
          >
            <InfoRoundedIcon />
          </Tooltip>
        </HeadingSection>
        <Tabs
          className={classes.tabs}
          value={selectedTabIndex}
          TabIndicatorProps={{ className: classes.selectedTab }}
          onChange={this.handleTabChange}
        >
          <Tab className={classes.tab} label="All Orders" />
          <Tab className={classes.tab} label="Requests" />
        </Tabs>
        <TabContent>
          {selectedTabIndex === 0 && <AllOrdersContent tab="all-orders" />}
          {selectedTabIndex === 1 && <RequestsContent tab="requests" />}
        </TabContent>
      </Container>
    );
  }
}

export default withStyles(useStyles)(OrderDetails);
