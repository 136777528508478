import React from 'react';
import styled from 'styled-components';
import Currency from '../../../../molecules/Currency';
import MaterialButton from '../../../../atoms/materialButton';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 'fit-content',
    maxWidth: 440,
    width: '100%',
    margin: '0 12px 12px 0'
  },
  pos: {
    marginBottom: 12
  },
  secondButton: {
    textTransform: 'none',
    marginTop: 8
  }
});

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ConnectionDetails = styled.div``;

const SecondaryText = styled.span`
  color: rgba(0, 0, 0, 0.54);
`;
const ActionButtons = styled.div`
  margin-left: 24px;

  button {
    width: 80px;
  }
`;

export default function NetworkMyConnectionCard({
  network,
  platform,
  currency,
  earnings,
  onWithdraw,
  onRemove
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Row>
          <ConnectionDetails>
            <Typography className={classes.pos} color="textPrimary">
              <SecondaryText>Network:&nbsp;</SecondaryText>
              {network}
            </Typography>
            <Typography className={classes.pos} color="textPrimary">
              <SecondaryText>Platform:&nbsp;</SecondaryText>
              {platform}
            </Typography>
            <Typography className={classes.pos} color="textPrimary">
              <SecondaryText>Earnings:&nbsp;</SecondaryText>
              <Currency currencyName={currency} />
              {earnings}
            </Typography>
          </ConnectionDetails>
        </Row>
      </CardContent>
    </Card>
  );
}
