import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Modal from '../../../../templates/Modal';
import {
  getApiResponseObject,
  postWithResponseObject,
  getAuthToken,
  successStatusCodes,
  showApiErrorToast
} from '../../../../../utils';
import { API_BASE_URL } from '../../../../../config/env';
import PaginationControls from '../../../../atoms/paginationControls';
import SearchBar from '../../../../atoms/searchBar';
import * as actions from '../../../../../redux/actions/uiActions';
import { connect } from 'react-redux';
import InviteVendorModal from './inviteVendorModal';
import { Wrapper, VendorsContainer, PaginationWrapper } from '../styles';
import EmptyState from '../../../../atoms/EmptyState';
import DiscoverVendorCard from './discoverVendorCard';
import { withSnackbar, MESSAGE_SEVERITY } from '../../../../hoc/withSnackbar';

class DiscoverVendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
      isOpen: false,
      showVendors: false,
      isError: false,
      isLoading: true,
      selectedVendor: null,
      disableButton: false,
      // Below required for search and pagination implementation
      totalCount: null,
      totalResultsCount: 0,
      fetchParams: {
        count: 20, // set this for pagesize
        offset: 0,
        text: ''
      }
    };
  }

  componentDidMount() {
    this.fetchVendorDetails(true);
  }

  fetchVendorDetails = async (setTotalcount = false) => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const { fetchParams } = this.state;

    this.props.showSpinnerBackdrop();

    let res = await getApiResponseObject(
      `${API_BASE_URL}/networks/discover-vendors`,
      headers,
      fetchParams
    );

    // Revert to previous page if invitations on a page run out
    if (res.data?.vendors?.length == 0 && res.data.count > 0) {
      fetchParams.offset = fetchParams.offset > 0 ? fetchParams.offset - 20 : 0;
      this.setState({ fetchParams });
      res = await getApiResponseObject(
        `${API_BASE_URL}/networks/discover-vendors`,
        headers,
        fetchParams
      );
    }

    if (successStatusCodes.includes(res.status)) {
      if (res.data && !isEmpty(res.data)) {
        this.setState({
          vendors: res.data.vendors,
          isError: false,
          showVendors: true,
          isLoading: false,
          totalCount: setTotalcount ? res.data.count : this.state.totalCount,
          totalResultsCount: res.data.count
        });
      } else {
        this.setState({
          isError: false,
          showVendors: false,
          isLoading: false
        });
      }
    } else {
      this.setState({
        isError: true,
        isLoading: false
      });
      showApiErrorToast(res.data);
    }
    this.props.hideSpinnerBackdrop();
  };

  // required for search and pagination implementation
  updateFetchParams = fetchParams => {
    this.setState({ fetchParams });
    this.fetchVendorDetails();
  };

  handleInviteVendor = vendor => {
    this.setState({
      selectedVendor: vendor,
      isOpen: true
    });
  };

  inviteVendor = async vendorId => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const { fetchParams } = this.state;
    const reqBody = { vendor_id: vendorId };

    this.setState({ disableButton: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/network-vendor-connections`,
      reqBody,
      headers
    );
    this.setState({
      fetchParams: {
        count: 20, // set this for pagesize
        offset: fetchParams.offset,
        text: ''
      },
      disableButton: false
    });

    if (successStatusCodes.includes(response.status)) {
      this.closeModal();
      this.props.showSnackbar({
        message: 'Connection request sent successfully',
        severity: MESSAGE_SEVERITY.success
      });
      this.fetchVendorDetails(true);
    } else {
      this.closeModal();
      this.setState({ isLoading: false });
      this.props.showSnackbar({
        error: response.data,
        message: 'Something went wrong',
        severity: MESSAGE_SEVERITY.error
      });
    }
  };

  closeModal = () => {
    this.setState({ selectedVendor: null, isOpen: false });
  };

  resetComponent = () => {
    this.setState({
      vendors: [],
      isError: false,
      showVendors: false,
      isLoading: true
    });
  };

  render() {
    const {
      vendors,
      isOpen,
      showVendors,
      isLoading,
      isError,
      selectedVendor,
      disableButton
    } = this.state;
    return (
      <Wrapper>
        {!isLoading && !isError && showVendors ? (
          <React.Fragment>
            <SearchBar
              placeholder="Search.."
              size="medium"
              fetchParams={this.state.fetchParams}
              updateFetchParams={this.updateFetchParams}
            />
            {vendors.length > 0 ? (
              <VendorsContainer>
                {vendors.map((vendor, index) => (
                  <DiscoverVendorCard
                    key={index}
                    vendor={vendor.vendor}
                    store={vendor.store}
                    vendorCardActionMethods={{
                      handleInvite: this.handleInviteVendor
                    }}
                  />
                ))}
                {isOpen === true && !isEmpty(selectedVendor) ? (
                  <Modal close={this.closeModal}>
                    <InviteVendorModal
                      vendor={selectedVendor}
                      handleInvite={() => this.inviteVendor(selectedVendor.id)}
                      disableButton={disableButton}
                    />
                  </Modal>
                ) : null}
              </VendorsContainer>
            ) : (
              <EmptyState errorMessage="No vendors found" />
            )}
            <PaginationWrapper>
              <PaginationControls
                fetchParams={this.state.fetchParams}
                totalItemCount={this.state.totalResultsCount}
                updateFetchParams={this.updateFetchParams}
              />
            </PaginationWrapper>
          </React.Fragment>
        ) : (
          !isLoading &&
          !showVendors && <EmptyState errorMessage="No vendors available" />
        )}
      </Wrapper>
    );
  }
}

DiscoverVendors.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withSnackbar(
  withRouter(connect(null, mapDispatchToProps)(DiscoverVendors))
);
