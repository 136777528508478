import styled from 'styled-components';
import Card from '../../../atoms/card';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const CosellerLevelAnalyticsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CosellerDataPoint = styled(Card)`
  width: 100%;
  max-width: 200px;
  height: auto;
  padding: 10px 20px;
  margin: 10px;
  color: black;
  background-color: ${props => props.theme.GALLERY};

  & > h4 {
    font-size: ${props => props.theme.XX_LARGE};
    font-weight: ${props => props.theme.MEDIUM_FONT};
    margin: 0;
  }
  & > div {
    font-size: ${props => props.theme.XXX_LARGE};
    font-weight: ${props => props.theme.BOLD_FONT};
    padding: 10px 0px;
  }
`;

const ProductLevelAnalyticsContainer = styled.div`
  margin-top: 50px;
  border-radius: 10px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.WHITE};
`;

const ProductListItems = styled.div`
  display: block;
  height: 525px;
  overflow-y: scroll;
`;

const ProductLevelAnalyticsContent = styled.div``;

const ProductDataPointTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  min-height: 60px;
  padding-top: 35px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const ProductDataPoints = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.SEMI_BOLD_FONT};
  min-height: 105px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #ccc;
  & > img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: contain;
    background-color: ${props => props.theme.WHITE};
    border: 1px solid ${props => props.theme.GAINSBORO};
  }
  & > div:first-of-type {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${props => props.theme.GALLERY};
    transition: all 300ms ease;
  }
`;

const Message = styled.div`
  text-align: center;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
  height: 525px;
  line-height: 525px;
`;

export {
  Container,
  HeadingSection,
  Heading,
  CosellerLevelAnalyticsContainer,
  CosellerDataPoint,
  ProductLevelAnalyticsContainer,
  ProductListItems,
  ProductLevelAnalyticsContent,
  ProductDataPointTitle,
  ProductDataPoints,
  Message
};
