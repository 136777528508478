import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React from 'react';
import { Calendar } from './styles';

const useStyles = makeStyles({
  keyboardPicker: {
    margin: 0
  }
});

function CalendarComponent(props) {
  const classes = useStyles();
  return (
    <Calendar style={{ width: '170px' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={props.className ? props.className : classes.keyboardPicker}
          autoOk={true}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={props.label}
          size={props.size}
          value={props.date}
          minDate={props.minDate}
          onError={props.onError}
          onChange={props.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          InputProps={{
            style: {
              height: props.height,
              padding: 0,
              backgroundColor: '#fff'
            }
          }}
        />
      </MuiPickersUtilsProvider>
    </Calendar>
  );
}

export default CalendarComponent;
