import styled from 'styled-components';

const SubSectionWrapper = styled.div`
  height: 100%;
`;
const Card = styled.div`
  background-color: ${props => props.theme.WHITE};
  padding: 3rem;
  max-height: 540px;
  overflow: auto;
  height: ${props => props.height || 'inital'};
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const ContactWrapper = styled.div``;
const ShippingInfoWrapper = styled.div`
  display: flex;
`;
const CardHeading = styled.div`
  font-size: 20px;
`;
const ShippingInfoLabel = styled.div`
  margin: 2rem 2rem 0 0;
`;
const ShippingInfoValue = styled.div`
  font-weight: bold;
  margin-top: 2rem;
`;
const Wrapper = styled.div`
  font-size: ${props => props.size || 'inherit'};
  flex-shrink: ${props => props.shrink};
`;
const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;
const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 1.5rem;
`;
const PriceInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PriceLabel = styled.div`
  margin-bottom: 1.5rem;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => props.size || 'inherit'};
`;
const PriceValue = styled.div`
  margin-bottom: 1.5rem;
  text-align: right;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => props.size || 'inherit'};
`;
const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid black;
  padding-top: 2rem;
`;

const ImageNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  position: relative;
  bottom: -20px;
  text-align: center;

  ${props => props.theme.TABLET`
    button {
      height: 30px;
    }
  `}
`;

const BillingInfoContainer = styled.div`
  margin-top: 20px;
`;

export {
  SubSectionWrapper,
  ContactWrapper,
  ShippingInfoWrapper,
  CardHeading,
  Card,
  ShippingInfoLabel,
  ShippingInfoValue,
  Wrapper,
  ProductInfo,
  ProductImage,
  PriceInfo,
  PriceLabel,
  PriceValue,
  TotalPrice,
  ImageNameWrapper,
  ButtonWrapper,
  BillingInfoContainer
};
