import styled from 'styled-components';

const PanelContent = styled.div`
  padding: 24px 0;
  max-width: auto;
`;

const Imgholder = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 20px;
`;

const FileUpload = styled.input`
  cursor: pointer;
  display: none;
`;

export { FileUpload, PanelContent, Imgholder };
