import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DefaultProductImage from '../../../assets/images/photo.svg';
import { SubHeading, IconContainer, Container, ShareIcon } from './styles';
import { setQueryParamsToUrl, formHashTag, isMobile } from '../../../utils.js';
import { ShareRounded } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton
} from 'react-share';

function Share({
  shareLabel,
  productShareLink,
  title,
  description,
  imageSrc,
  tags,
  disableButton,
  publishShareEvent
}) {
  const handleShareBtnClick = medium => {
    if (publishShareEvent) {
      publishShareEvent(medium);
    }
  };
  const facebookHashtag = formHashTag(tags && tags[0]);
  const twitterHashtags =
    tags && tags[0] ? [formHashTag(tags[0]), formHashTag(tags && tags[1])] : [];

  const nativeShare = () => {
    if (navigator) {
      const shareData = {
        title: title,
        text: 'Hey! check this out!',
        url: productShareLink
      };
      navigator.share(shareData);
    }
  };

  return (
    <Container>
      <SubHeading>{shareLabel}</SubHeading>
      <IconContainer>
        {!isMobile() ? (
          <React.Fragment>
            <FacebookShareButton
              url={setQueryParamsToUrl(productShareLink, {
                utm_medium: 'facebook'
              })}
              hashtag={facebookHashtag}
              onClick={() => handleShareBtnClick('facebook')}
              disabled={disableButton}
            >
              <ShareIcon className="fab fa-facebook-square" />
            </FacebookShareButton>
            <TwitterShareButton
              url={setQueryParamsToUrl(productShareLink, {
                utm_medium: 'twitter'
              })}
              quote={`${title}${'   '}${description}`}
              title={'Check out this product:'}
              hashtags={twitterHashtags}
              onClick={() => handleShareBtnClick('twitter')}
              disabled={disableButton}
            >
              <ShareIcon className="fab fa-twitter-square" />
            </TwitterShareButton>
            <PinterestShareButton
              url={setQueryParamsToUrl(productShareLink, {
                utm_medium: 'pinterest'
              })}
              media={imageSrc || DefaultProductImage}
              description={`${title}`}
              onClick={() => handleShareBtnClick('pinterest')}
              disabled={disableButton}
            >
              <ShareIcon className="fab fa-pinterest-square" />
            </PinterestShareButton>
            <WhatsappShareButton
              url={setQueryParamsToUrl(productShareLink, {
                utm_medium: 'whatsapp'
              })}
              hashtag={facebookHashtag}
              onClick={() => handleShareBtnClick('whatsapp')}
              disabled={disableButton}
            >
              <ShareIcon className="fab fa-whatsapp-square" />
            </WhatsappShareButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button variant="contained" onClick={nativeShare}>
              <ShareRounded />
            </Button>
          </React.Fragment>
        )}
      </IconContainer>
    </Container>
  );
}

Share.propTypes = {
  shareLabel: PropTypes.string,
  productShareLink: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
  tags: PropTypes.array,
  disableButton: PropTypes.bool,
  publishShareEvent: PropTypes.func
};

export default withRouter(Share);
