import React, { useState, useEffect } from 'react';
import { object, string, func, bool, array } from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  fetchCountriesList,
  fetchStatesList
} from './prebuiltData/locationUtilities';
import { setShippingInfo, setBillingInfo } from '../../../utils';
import Theme from '../../../theme';
import GooglePlacesAutocomplete from '../../molecules/GooglePlacesAutocompleteV2';
import PhoneNumberInput from '../../atoms/phoneNumberInput';

const styles = {
  root: {
    width: '100%',
    maxWidth: 280,
    // eslint-disable-next-line no-unexpected-multiline
    ['@media (max-width:544px)']: {
      maxWidth: '100%'
    }
  },
  firstField: {
    marginRight: 8
  },
  nameField: {
    width: '100%'
  },
  emailRoot: {
    width: '100%',
    maxWidth: 568
  },
  addressRoot: {
    width: '100%',
    maxWidth: 568
  },
  inputRoot: {
    fontSize: 14,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.default.BUDDHA_GOLD
    }
  },
  labelRoot: {
    fontSize: 14,
    color: Theme.default.CORDUROY,
    '&$labelFocused': {
      color: Theme.default.BUDDHA_GOLD
    }
  },
  labelFocused: {}
};

const AddressInput = ({
  classes,
  addressType,
  addressDataFeed,
  addressDataFetch,
  updateFormFields,
  countries,
  countriesCode,
  states,
  statesCode,
  fetchAndUpdateStatesData
}) => {
  const [addressData, setAddressData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    setAddressData(addressDataFeed);
  }, []);

  useEffect(() => {
    const updateSelectedCountry = async () => {
      setSelectedCountry(addressDataFeed.country);
    };

    if (updateFormFields) {
      setAddressData(addressDataFeed);
      updateSelectedCountry();
    }
  }, [updateFormFields]);

  useEffect(() => {
    if (!isEmpty(selectedCountry)) {
      fetchAndUpdateStatesData(countriesCode[selectedCountry], addressType);
    }
  }, [selectedCountry]);

  useEffect(() => {
    let newAddressData = JSON.parse(JSON.stringify(addressData));
    addressDataFetch(addressType, newAddressData);
  }, [addressData]);

  const fillAddressFetchedFromGooglePlaces = (
    fetchedAddressDataFromGoogle,
    type = 'all'
  ) => {
    type === 'custom'
      ? setAddressData({
          ...addressData,
          address: fetchedAddressDataFromGoogle?.street1 || ''
        })
      : setAddressData({
          ...addressData,
          address: fetchedAddressDataFromGoogle.street1 || '',
          city: fetchedAddressDataFromGoogle.city || '',
          state: fetchedAddressDataFromGoogle.state || '',
          stateCode: fetchedAddressDataFromGoogle.state_code || '',
          country: fetchedAddressDataFromGoogle.country || '',
          countryCode: fetchedAddressDataFromGoogle.country_code || '',
          postalCode: fetchedAddressDataFromGoogle.postal_code || ''
        });

    setSelectedCountry(fetchedAddressDataFromGoogle.country);
  };

  return (
    <Container>
      <Row>
        <Name
          name="name"
          label="Name"
          variant="outlined"
          className={classes.nameField}
          InputProps={{ classes: { root: classes.inputRoot } }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          value={addressData.name || ''}
          onChange={e =>
            setAddressData({ ...addressData, name: e.target.value })
          }
        />
      </Row>
      <Row>
        <PhoneNumberInput
          material
          inputProps={{ classes: { root: classes.inputRoot } }}
          phoneValue={addressData.phone}
          inputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          callback={value => setAddressData({ ...addressData, phone: value })}
        />
        <Email
          label="Email"
          variant="outlined"
          className={classes.root}
          InputProps={{ classes: { root: classes.inputRoot } }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          value={addressData.email || ''}
          onChange={e =>
            setAddressData({ ...addressData, email: e.target.value })
          }
        />
      </Row>
      <Row>
        <GooglePlacesAutocomplete
          type={addressType}
          label="Address"
          callback={fillAddressFetchedFromGooglePlaces}
          allowCustomValues
          customValue={addressData.address}
          customValueKey={'street1'}
        />
      </Row>
      <Row>
        <City
          label="City"
          variant="outlined"
          className={`${classes.root} ${classes.firstField}`}
          InputProps={{ classes: { root: classes.inputRoot } }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          value={addressData.city || ''}
          onChange={e =>
            setAddressData({ ...addressData, city: e.target.value })
          }
        />
        <Autocomplete
          className={classes.root}
          id={`${addressType}-country-autocomplete`}
          options={countries}
          getOptionLabel={option => option}
          value={addressData.country || ''}
          classes={classes}
          disabled={countries.length === 0}
          onChange={(e, selectedCountry) => {
            setSelectedCountry(selectedCountry);
            setAddressData({
              ...addressData,
              country: selectedCountry,
              countryCode: countriesCode[selectedCountry],
              state: '',
              stateCode: ''
            });
          }}
          renderInput={params => {
            params.inputProps.autoComplete = 'new-password';
            return (
              <Country
                {...params}
                label="Country"
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
              />
            );
          }}
        />
      </Row>
      <Row>
        <Autocomplete
          className={`${classes.root} ${classes.firstField}`}
          id={`${addressType}-state-autocomplete`}
          noOptionsText={'Please choose your country'}
          options={states}
          getOptionLabel={option => option}
          value={addressData.state || ''}
          classes={classes}
          disabled={states.length === 0}
          onChange={(e, selectedState) =>
            setAddressData({
              ...addressData,
              state: selectedState,
              stateCode: statesCode[selectedState]
            })
          }
          renderInput={params => {
            params.inputProps.autoComplete = 'new-password';
            return (
              <State
                {...params}
                label="State"
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
              />
            );
          }}
        />

        <ZipCode
          label="ZipCode"
          variant="outlined"
          className={classes.root}
          InputProps={{ classes: { root: classes.inputRoot } }}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          value={addressData.postalCode || ''}
          onChange={e =>
            setAddressData({ ...addressData, postalCode: e.target.value })
          }
        />
      </Row>
    </Container>
  );
};

const Container = styled.div``;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 15px 0px;

  ${props => props.theme.PHONE`
    flex-direction: column;

    & > div {
      margin: 7.5px 0;
    }
  `}
`;

const Name = styled(TextField)``;
const FirstName = styled(TextField)``;
const Email = styled(FirstName)``;
const City = styled(FirstName)``;
const State = styled(FirstName)``;
const Country = styled(FirstName)``;
const ZipCode = styled(FirstName)``;

AddressInput.propTypes = {
  classes: object,
  addressType: string,
  addressDataFeed: object,
  addressDataFetch: func,
  updateFormFields: bool,
  countries: array,
  countriesCode: object,
  states: array,
  statesCode: object,
  fetchAndUpdateStatesData: func
};

export default withStyles(styles)(AddressInput);
