import React from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { ModalContainer, ModalContent, ModalBackdrop } from './styles';

let modalRoot = document.getElementById('modal');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    if (!modalRoot) {
      modalRoot = document.getElementsByTagName('body')[0];
    }
    modalRoot.appendChild(this.el);
    document.body.classList.add('modal--open');
    document.addEventListener('keydown', this.escFunction, true);
  }

  componentWillUnmount() {
    document.body.classList.remove('modal--open');
    modalRoot && modalRoot.removeChild(this.el);
    document.removeEventListener('keydown', this.escFunction, true);
  }

  escFunction = event => {
    if (event.defaultPrevented) {
      return;
    }

    let key = event.key || event.keyIdentifier || event.keyCode;
    let handled = false;
    if (key === 'Escape' || key === 'Esc' || key === 27) {
      this.props.close();
    }

    if (handled) {
      event.preventDefault();
    }
  };

  render() {
    const { close, fullCanvas, model, width, height } = this.props;
    const modal = (
      <ModalContainer>
        <ModalBackdrop onClick={close} />
        <ModalContent
          fullCanvas={fullCanvas}
          model={model}
          width={width}
          height={height}
        >
          {this.props.children}
        </ModalContent>
      </ModalContainer>
    );

    return ReactDOM.createPortal(modal, this.el);
  }
}

Modal.defaultProps = {
  model: 'default',
  fullCanvas: true
};

Modal.propTypes = {
  children: PropTypes.element,
  model: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  close: PropTypes.func,
  fullCanvas: PropTypes.bool,
  size: PropTypes.string
};

export default Modal;
