import styled, { css } from 'styled-components';
import { Secondary } from '../../atoms/button';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.WHITE};
`;

const RowWrap = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

const ColWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  color: ${p => p.theme.MINE_SHAFT};
  margin: 0 auto;

  ${props => props.theme.DESKTOP`
    flex-direction: column-reverse;
  `}
`;

const BuyerInfo = styled.div`
  width: 100%;
  height: 100vh;
  padding: 30px 50px;
  background-color: ${p => p.theme.WHITE};
  overflow: auto;

  ${props => props.theme.DESKTOP`
  height: initial;
  overflow: initial;
  `}

  ${props => props.theme.PHONE`
  padding: 30px 14px;
  `}
`;

const BuyerContent = styled.div`
  max-width: 568px;
  width: 100%;
  float: right;

  ${props => props.theme.DESKTOP`
    max-width: 480px;
    width: 100%;
    width: initial;
    float: initial;
    margin: 0 auto;
  `}
`;

const ShoptypeLogo = styled.img`
  width: 120px;

  ${props => props.theme.DESKTOP`
    display: none;
  `}
`;

const ShoptypeLogoMobile = styled(ShoptypeLogo)`
  display: none;

  ${props => props.theme.DESKTOP`
    display: initial;
    width: 120px;
  `}
`;

const MobileContent = styled.div`
  max-width: 480px;
  width: 100%;
  padding: 14px 0;
  margin: 0 auto;

  ${props => props.theme.PHONE`
    padding: 14px;
  `}
`;

const CheckoutProgress = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0px 10px 0px;
  font-size: ${p => p.theme.X_LARGE};
`;

const CheckoutProgressHeading = styled.div`
  width: auto;
  text-align: center;
  border-bottom: 2px solid ${p => p.theme.RONCHI};
`;

const BuyerShippingBillingInput = styled.div``;

const ShippingInfo = styled.div`
  width: 100%;
  padding: 20px 0px 10px 0px;
`;

const BillingInfo = styled.div`
  width: 100%;
  max-width: 580px;
  padding: 20px 0px 10px 0px;
  & > div:nth-child(2) {
    min-height: 30px;
  }
`;

const Heading = styled.div`
  font-size: ${p => p.theme.LARGE};
  font-weight: ${p => p.theme.MEDIUM_FONT};
  margin: 10px 0px;
`;

const Continue = styled.div`
  width: 100%;
  max-width: 580px;
  text-align: right;
  padding: 10px 0px;
  button {
    width: 200px;
    height: 50px;
    border-radius: 5px;
    margin-left: auto;
    background-color: ${props => props.theme.RONCHI};
    color: ${props => props.theme.BLACK};
    font-size: ${props => props.theme.MEDIUM};
    font-weight: ${props => props.theme.BOLD_FONT};
  }
`;

const ContinueButton = styled(Secondary)``;

const BuyerChooseShippingOptionInput = styled.div``;

const BuyerSummary = styled.div`
  margin-top: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

const ShippingOptionsCard = styled.div`
  margin-top: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 10px 20px;
`;

const SummaryBlock = styled.div`
  display: grid;
  grid-template-columns: 75px auto 24px;
  align-items: baseline;
  padding: 10px 20px;
  color: ${p => p.theme.MATTERHORN};
  font-size: ${p => p.theme.MEDIUM};
`;

const SummaryBlockHeading = styled.div`
  color: ${p => p.theme.MATTERHORN};
  font-weight: ${p => p.theme.MEDIUM_FONT};
  font-size: ${p => p.theme.LARGE};
`;

const EditBuyerInfo = styled.div`
  width: 100%;
  text-align: right;
  padding: 10px 10px 0px 10px;
  button {
    width: 60px;
    height: 30px;
    border-radius: 5px;
    margin-left: auto;
    background-color: ${props => props.theme.WHITE};
    color: ${props => props.theme.MATTERHORN};
    font-size: ${props => props.theme.LARGE};
    font-weight: ${props => props.theme.BOLD_FONT};
  }
`;

const EditBuyerInfoButton = styled(Secondary)``;

const ShippingOptions = styled.div`
  width: 100%;
  max-width: 450px;
  min-height: 140px;
  margin-top: 20px;
  padding: 5px 15px 10px 15px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
`;

const ShippingOption = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 0.25fr;
  border-bottom: 1px solid lightgray;
  padding: 10px 0px 5px 0px;
  div {
    color: ${p => p.theme.MATTERHORN};
    font-weight: ${p => p.theme.MEDIUM_FONT};
    font-size: ${p => p.theme.MEDIUM};
  }
  div:nth-child(2) {
    font-size: ${p => p.theme.LARGE};
  }
`;

const OrderInfoWrapper = styled.div`
  width: 80%;
  height: 100vh;
  background-color: ${p => p.theme.SPRING_WOOD};
  border-left: 1px solid #e5e5e5;
  padding: 50px 50px;
  overflow: auto;

  ${props => props.theme.DESKTOP`
    width: 100%;
    max-height: 0px;
    height: auto;
    border: none;
    padding: 0px 14px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.3, 0, 0, 1), width 0ms;

  ${props =>
    props.panelState &&
    props.panelState === 'expanded' &&
    css`
      max-height: fit-content;
      padding: 50px 14px;
      opacity: 1;
      border-bottom: 1px solid ${p => p.theme.TANA};
    `}
  `}

  ${props => props.theme.PHONE`
      padding: 0px 14px;

    ${props =>
      props.panelState &&
      props.panelState === 'expanded' &&
      css`
        padding: 50px 14px;
      `}
  `}
`;

const ProductsData = styled.div`
  width: 100%;
  max-width: 480px;
  height: fit-content;

  ${props =>
    props.hideBorderBottom &&
    css`
      border-bottom: 1px solid ${p => p.theme.TANA};
    `}
`;

const OrderInfoContent = styled.div`
  ${props => props.theme.DESKTOP`
  max-width: 480px;
  margin: 0 auto;
  `}
`;

const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: 90px auto auto;
  justify-items: flex-start;
  align-items: center;
  margin 20px 0px;
`;

const ProductImageWrapper = styled.div`
  position: relative;
`;

const ProductImageContainer = styled.div`
  width: 80px;
  height: 80px;
  margin: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0.5px solid ${p => p.theme.CHINESE_SILVER};
    border-radius: 5px;
  }
`;

const ProductQuantityChip = styled.div`
  font-weight: ${p => p.theme.MEDIUM_FONT};
  font-size: ${p => p.theme.X_SMALL};
  color: ${p => p.theme.WHITE};
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: #727272;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5833333333em;
  position: absolute;
  right: -0.25em;
  top: -0.25em;

  & ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    z-index: 2;
  }
`;

const ProductInformationContainer = styled.div`
  max-width: 300px;
  width: 100%;
  padding: 0 8px;
`;

const ProductHeading = styled.div`
  font-weight: ${p => p.theme.BOLD_FONT};
  font-size: ${p => p.theme.MEDIUM};
  color: ${p => p.theme.MATTERHORN};
`;

const ProductVariant = styled.div`
  font-weight: ${p => p.theme.LIGHT_FONT};
  font-size: ${p => p.theme.SMALL};
  color: ${p => p.theme.DIM_GRAY};
`;

const ProductAmountContainer = styled.div`
  max-width: 90px;
  width: fit-content;
  text-align: right;
  justify-self: flex-end;
  font-weight: ${p => p.theme.BOLD_FONT};
  font-size: ${p => p.theme.MEDIUM};
  color: ${p => p.theme.MATTERHORN};
`;

const CostCalculation = styled.div`
  width: 100%;
  max-width: 480px;
  height: fit-content;
  border-bottom: 1px solid ${p => p.theme.TANA};
`;

const TotalCostContainer = styled(CostCalculation)`
  margin: 12px 0;
  border-bottom: none;
`;

const CostType = styled.div`
  display: grid;
  grid-template-columns: 140px auto;
  align-items: center;
  margin: 6px 0;
`;

const CostTypeHeading = styled.div`
  width: 140px;
  padding: 0 5px;
  color: ${p => p.theme.CORDUROY};
  font-weight: ${p => p.theme.MEDIUM_FONT};
  font-size: ${p => p.theme.MEDIUM};
`;

const CostTypeAmount = styled.div`
  text-align: right;
  color: ${p => p.theme.MATTERHORN};
  font-weight: ${p => p.theme.BOLD_FONT};
  font-size: ${p => p.theme.MEDIUM};
`;

const CostTypeMessage = styled.div`
  text-align: right;
  font-weight: ${p => p.theme.LIGHT_FONT};
  font-size: ${p => p.theme.SMALL};
  color: ${p => p.theme.DIM_GRAY};
`;

const TotalCostHeading = styled.div`
  width: 140px;
  padding: 0 5px;
  color: ${p => p.theme.MATTERHORN};
  font-weight: ${p => p.theme.BOLD_FONT};
  font-size: ${p => p.theme.LARGE};
`;

const TotalCostAmount = styled.div`
  text-align: right;
  color: ${p => p.theme.MATTERHORN};
  font-weight: ${p => p.theme.BOLD_FONT};
  font-size: ${p => p.theme.XX_LARGE};
`;

const Pay = styled.div`
  width: 100%;
  text-align: right;
  padding: 30px 0px;
  button {
    width: 200px;
    height: 60px;
    border-radius: 5px;
    margin-left: auto;
    background-color: ${props => props.theme.RONCHI};
    color: ${props => props.theme.BLACK};
    font-size: ${props => props.theme.LARGE};
    font-weight: ${props => props.theme.BOLD_FONT};
  }
`;

const PaymentIframe = styled.iframe`
  width: 100%;
  max-width: 480px;
  min-height: 400px;
  margin-top: 20px;
  padding: 5px 0px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
`;

const PayButton = styled(Secondary)``;

const MobileView = styled.div`
  display: none;

  ${props => props.theme.DESKTOP`
    display: initial;
    width: 100%;
    height: 100%;
  `}
`;

const ButtonMainLabel = styled.div`
  color: #197bbd;
  font-weight: 500;
  text-align: left;
  margin-right: 24px;
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
`;

const ButtonSecondaryLabel = styled.div`
  text-align: right;
  color: ${p => p.theme.MATTERHORN};
  font-weight: ${p => p.theme.BOLD_FONT};
  font-size: ${p => p.theme.LARGE};
`;

const ExpansionButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExpansionButton = styled.button`
  border: none;
  background-color: ${p => p.theme.SPRING_WOOD};
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
  cursor: pointer;
  margin-bottom: 1px;

  &:hover ${ButtonMainLabel} {
    color: #166eaa;
  }
`;

const LineItemVendorDetails = styled.div`
  padding: 5px;
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  color: ${props => props.theme.SONIC_SILVER};
`;

const VendorShippingOptions = styled.div`
  margin: 12px;
`;

const FlexJustifyEnd = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export {
  Container,
  RowWrap,
  ColWrap,
  BuyerInfo,
  BuyerContent,
  ShoptypeLogo,
  ShoptypeLogoMobile,
  CheckoutProgress,
  CheckoutProgressHeading,
  BuyerShippingBillingInput,
  ShippingInfo,
  BillingInfo,
  Heading,
  Continue,
  ContinueButton,
  BuyerChooseShippingOptionInput,
  BuyerSummary,
  SummaryBlock,
  SummaryBlockHeading,
  EditBuyerInfo,
  EditBuyerInfoButton,
  ShippingOptions,
  ShippingOption,
  OrderInfoWrapper,
  ProductsData,
  ProductDetails,
  CostCalculation,
  CostType,
  Pay,
  PayButton,
  PaymentIframe,
  ProductImageContainer,
  ProductInformationContainer,
  ProductAmountContainer,
  ProductHeading,
  ProductVariant,
  ProductQuantityChip,
  ProductImageWrapper,
  CostTypeHeading,
  CostTypeAmount,
  CostTypeMessage,
  TotalCostContainer,
  TotalCostHeading,
  TotalCostAmount,
  OrderInfoContent,
  MobileView,
  MobileContent,
  ExpansionButton,
  ExpansionButtonContent,
  ButtonMainLabel,
  ButtonSecondaryLabel,
  LineItemVendorDetails,
  ShippingOptionsCard,
  VendorShippingOptions,
  FlexJustifyEnd
};
