import styled, { css } from 'styled-components';
import Card from '../../atoms/card';
import { Secondary } from '../../atoms/button';

const Container = styled.div`
  ${props =>
    props.isMobilePreview &&
    css`
      display: flex;
      justify-content: left;
      flex-direction: column;
      text-align: left;
    `}
`;

const PreviewContainer = styled.div`
  display: flex;
`;

const Preview = styled(Card)`
  min-width: 670px;
  max-height: 560px;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px ${props => props.theme.WHITE};
  background-color: ${props => props.theme.WHITE};
  ${props =>
    props.isMobilePreview &&
    css`
      min-width: 270px;
      padding: 30px;
    `}
  ${props =>
    props.isCurrent &&
    css`
      border: solid 1px ${props => props.theme.RONCHI};
    `}
`;

const Image = styled.img`
  margin: 0 auto;
  max-width: 250px;
  max-height: 300px;
  ${props =>
    props.isMobilePreview &&
    css`
      max-width: 250px;
    `}
`;

const Title = styled.div`
  width: 100%;
  margin-top: 20px;
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  color: ${props => props.theme.BLACK};

  ${props =>
    props.isMobilePreview &&
    css`
      font-size: ${props => props.theme.MEDIUM};
      font-weight: ${props => props.theme.MEDIUM_FONT};
      margin-top: 30px;
    `}
`;

const Description = styled.div`
  margin-top: 10px;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.LIGHT_FONT};
  color: ${props => props.theme.BLACK};

  ${props =>
    props.isMobilePreview &&
    css`
      font-size: ${props => props.theme.X_SMALL};
      font-weight: ${props => props.theme.LIGHT_FONT};
    `}
`;

const BuyNow = styled(Secondary)`
  margin-top: 10px;
  max-width: 130px;
  min-height: 40px;
  border-radius: 3px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  cursor: unset;
`;

const BuyNowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  ${props =>
    props.isMobilePreview &&
    css`
      justify-content: center;
      margin-top: 10px;
      > button {
        min-width: 100%;
      }
    `}
`;

const Logo = styled.img`
  max-width: 30px;
  max-height: 30px;
`;

export {
  Container,
  PreviewContainer,
  Preview,
  Image,
  Title,
  Description,
  BuyNow,
  BuyNowContainer,
  Logo
};
