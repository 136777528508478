import React from 'react';
import Currency from '../../../molecules/Currency';
import PropTypes from 'prop-types';
import { VendorDataPoint, VendorLevelAnalyticsContainer } from '../styles';

const VendorLevelAnalytics = props => {
  const { isLoading, dataPoints } = props;

  return (
    <VendorLevelAnalyticsContainer>
      {!isLoading &&
        Object.keys(dataPoints).map((dataPoint, i) => (
          <VendorDataPoint key={i} isDark={true}>
            <h4>{dataPoint}</h4>
            <div>
              {dataPoint === 'Total Revenue' ? (
                <>
                  <Currency currencyName={dataPoints[dataPoint].currency} />
                  {dataPoints[dataPoint].amount}
                </>
              ) : (
                dataPoints[dataPoint]
              )}
            </div>
          </VendorDataPoint>
        ))}
    </VendorLevelAnalyticsContainer>
  );
};

VendorLevelAnalytics.propTypes = {
  dataPoints: PropTypes.object,
  isLoading: PropTypes.bool
};

export default VendorLevelAnalytics;
