import { handleActions } from 'redux-actions';
import * as constants from '../constants';
import update from 'immutability-helper';

const initalState = {
  showSpinnerBackdrop: false
};

const showSpinnerBackdrop = state => {
  return update(state, { showSpinnerBackdrop: { $set: true } });
};

const hideSpinnerBackdrop = state => {
  return update(state, { showSpinnerBackdrop: { $set: false } });
};

export default handleActions(
  {
    [constants.SHOW_SPINNER_BACKDROP]: showSpinnerBackdrop,
    [constants.HIDE_SPINNER_BACKDROP]: hideSpinnerBackdrop
  },
  initalState
);
