import styled from 'styled-components';
import CollapsibleWithIcon from '../../../organisms/collapsibleWithIcon';

const Container = styled.div`
  padding: 24px 48px;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

const OrderDetailsContainer = styled(CollapsibleWithIcon)`
  margin-top: 40px;
  border-bottom: 1px solid black;
`;

const PlatformImage = styled.img`
  width: 16px;
  height: 16px;
`;

const Calendar = styled.div`
  width: 200px;
`;

const FiltersContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  & > div {
    margin-right: 20px;
  }
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;

  & > div:first-child {
    margin-right: 10px;
  }
`;

export {
  Container,
  HeadingSection,
  Heading,
  DetailsContainer,
  OrderDetailsContainer,
  ErrorMessageContainer,
  PlatformImage,
  Calendar,
  FiltersContainer,
  CalendarContainer
};
