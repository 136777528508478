import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import shoptype from '../../../../assets/images/simple_logo.png';
import { toaster } from '../../../atoms/toaster';
import { API_BASE_URL } from '../../../../config/env';

import { LineItemQuantity } from '../../../organisms/CheckoutCart/styles';

import { SHOPIFY_SOURCE, WOOCOMMERCE_SOURCE } from '../../../../constants';

import {
  getAuthToken,
  successStatusCodes,
  postWithResponseObject,
  showApiErrorToast
} from '../../../../utils';
import './styles.css';
import { Next } from '../../../templates/CosellerProfile/styles';

function RefundModal({
  Quantity,
  ItemName,
  VendorId,
  productId,
  shoptypeOrderId,
  source,
  purchasedItems,
  closeModal,
  fetchRefundstatus,
  quantityDifference
}) {
  const [wooCommerceProducts, setWooCommerceProducts] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  useEffect(() => {
    filtered();
  }, []);

  const options = [
    'Accidental Order',
    'Better Price Available',
    'Performance or Quality Not Adequate',
    'Incompatible or Not Useful for Intended Purpose',
    'Damaged due to Poor Packaging',
    'Missed Estimated Delivery Date',
    'Missing Parts or Accessories',
    'Damaged during Delivery',
    'Other'
  ];

  const [shopifyOtherReason, setShopifyOtherReason] = useState('');

  const [wooCommerceOtherReason, setWooCommerceOtherReason] = useState('');

  const [shopifyBody, setShopifyBody] = useState({
    vendor_id: VendorId,
    products: [{ id: productId, quantity: quantityDifference }],
    source: source,
    shoptype_order_id: shoptypeOrderId,
    reason: ''
  });

  const [wooCommerceBody, setWooCommerceBody] = useState({
    vendor_id: VendorId,
    products: [],
    source: source,
    shoptype_order_id: shoptypeOrderId,
    reason: ''
  });

  const filtered = () => {
    const wooCommerceItems = purchasedItems.map(item => {
      const container = {};
      container.id = item.product_id;
      container.quantity = item.quantity;
      return container;
    });
    wooCommerceBody.products = wooCommerceItems;
    setWooCommerceBody({ ...wooCommerceBody });
  };

  const changeQuantity = action => {
    if (action === 'decrease') {
      if (!(shopifyBody.products[0].quantity < 2)) {
        let qua = shopifyBody.products[0].quantity;
        qua = qua - 1;
        shopifyBody.products[0].quantity = qua;
        setShopifyBody({ ...shopifyBody });
      }
    } else {
      if (!(shopifyBody.products[0].quantity > quantityDifference - 1)) {
        let qua = shopifyBody.products[0].quantity;
        qua = qua + 1;
        shopifyBody.products[0].quantity = qua;
        setShopifyBody({ ...shopifyBody });
      }
    }
  };

  const setShopifyReason = value => {
    shopifyBody.reason = value;
    setShopifyBody({ ...shopifyBody });
    setDisableSaveButton(false);
  };

  const setWooCommerceReason = value => {
    wooCommerceBody.reason = value;
    setWooCommerceBody({ ...wooCommerceBody });
    setDisableSaveButton(false);
  };

  const shopifyRefund = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    if (shopifyBody.reason == 'Other') {
      shopifyBody.reason = shopifyOtherReason;
      setShopifyBody({ ...shopifyBody });
    }

    const response = await postWithResponseObject(
      `${API_BASE_URL}/coseller-returns`,
      shopifyBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      return;
    } else {
      toaster('Return request submitted successfully');
      fetchRefundstatus(response.data.status);
      closeModal();
      window.location.reload(false);
    }
  };

  const WooCommerceRefund = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/coseller-returns`,
      wooCommerceBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      return;
    } else {
      toaster('Return request submitted successfully');
      closeModal();
      window.location.reload(false);
    }
  };

  if (source == SHOPIFY_SOURCE) {
    return (
      <div style={{ padding: '25px' }}>
        <Typography
          style={{
            color: '#484848',
            fontSize: '20px',
            textAlign: 'start',
            paddingBottom: '20px'
          }}
        >
          {' '}
          Item to be returned :{' '}
        </Typography>
        <div className="rowTopPadding">
          <img src={shoptype} alt="none" style={{ height: '100px' }} />
          <div className="flexColumn">
            <Typography className="typoClass" component="h4">
              {' '}
              {ItemName}{' '}
            </Typography>
            <div className="flexRow">
              <Typography
                style={{
                  paddingLeft: '15px',
                  color: '#484848',
                  textAlign: 'left',
                  marginRight: '10px',
                  marginTop: '10px'
                }}
                component="h4"
              >
                {' '}
                Quantity :{' '}
              </Typography>
              <div style={{ marginTop: '5px' }}>
                <LineItemQuantity>
                  <button
                    onClick={() => {
                      changeQuantity('decrease');
                    }}
                  >
                    -
                  </button>
                  <span>{shopifyBody.products[0].quantity}</span>
                  <button
                    onClick={() => {
                      changeQuantity('increase');
                    }}
                  >
                    +
                  </button>
                </LineItemQuantity>
              </div>
            </div>

            <div className="shopifyReason">
              <Autocomplete
                options={options}
                getOptionLabel={option => option}
                style={{ width: 300, paddingLeft: '5px' }}
                onChange={(event, value) => setShopifyReason(value)}
                renderInput={params => (
                  <TextField {...params} label="Select Reason" />
                )}
              />
            </div>
            {shopifyBody.reason == 'Other' ? (
              <div className="textArea">
                <textarea
                  style={{
                    width: '300px',
                    outline: 'none',
                    borderRadius: '5px'
                  }}
                  value={shopifyOtherReason}
                  onChange={e => setShopifyOtherReason(e.target.value)}
                  rows="8"
                  cols="50"
                />
              </div>
            ) : (
              <div className="gap" />
            )}

            <div style={{ height: '20px' }}>
              <Typography
                style={{
                  paddingLeft: '15px',
                  color: '#484848',
                  textAlign: 'left',
                  fontStyle: 'italic'
                }}
                component="h4"
              >
                {' '}
                Refund To Original Mode of Payment{' '}
              </Typography>
            </div>
          </div>
        </div>
        <div className="topPadding">
          <Next
            text="Request"
            disabled={disableSaveButton}
            onClick={shopifyRefund}
          />
        </div>
      </div>
    );
  }

  if (source == WOOCOMMERCE_SOURCE) {
    return (
      <div className="wooComContainer">
        <Typography
          style={{
            color: '#484848',
            fontSize: '20px',
            textAlign: 'start',
            paddingBottom: '20px'
          }}
        >
          {' '}
          The following items will also be cancelled along with the requested
          item. Continue ?{' '}
        </Typography>

        <div style={{ height: '150px', overflowY: 'auto' }}>
          {purchasedItems.map(item => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="rowTopPadding">
                <img src={shoptype} alt="none" style={{ height: '100px' }} />
                <div className="flexColumn">
                  <Typography className="typoClass"> {item.name} </Typography>
                  <Typography
                    style={{
                      paddingLeft: '15px',
                      color: '#484848',
                      textAlign: 'left',
                      marginTop: '10px'
                    }}
                    component="h4"
                  >
                    {' '}
                    Quantity : {item.quantity}{' '}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>

        <div className="wooCommReason">
          <Autocomplete
            options={options}
            getOptionLabel={option => option}
            style={{ width: 300, paddingLeft: '5px' }}
            onChange={(event, value) => setWooCommerceReason(value)}
            renderInput={params => (
              <TextField {...params} label="Select Reason" />
            )}
          />
        </div>

        <div className="refundText">
          <Typography
            style={{
              paddingLeft: '15px',
              color: '#484848',
              textAlign: 'left',
              fontStyle: 'italic'
            }}
            component="h4"
          >
            {' '}
            Refund To Original Mode of Payment{' '}
          </Typography>
        </div>

        <div className="topPadding">
          <Next
            text="Confirm"
            disabled={disableSaveButton}
            onClick={WooCommerceRefund}
          />
        </div>
      </div>
    );
  }
}

export default RefundModal;
