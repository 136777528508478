import React from 'react';
import { isEmpty } from 'lodash';
import { API_BASE_URL } from '../../../../config/env';
import OrdersTable from './ordersTable';
import { toaster } from '../../../atoms/toaster';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  postWithResponseObject
} from '../../../../utils';
import { Container, ProductSection } from './styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import { withRouter } from 'react-router-dom';
import EmptyState from '../../../atoms/EmptyState';

const PAGE_ORDERS_COUNT = 6;

class AllOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orders: [],
      ordersAvailable: false,
      totalOrdersCount: 0,
      currentPage: 0,
      currentOffset: 0,
      fetchingOrders: false
    };
  }

  componentDidMount() {
    this.fetchPageData();
  }

  fetchOrders = async offset => {
    this.props.showSpinnerBackdrop();
    const userProfileData = JSON.parse(localStorage.getItem('userProfile'));
    const vendorId = userProfileData.vendors[0].id;
    const token = getAuthToken();
    const headers = { authorization: token };
    const res = await getApiResponseObject(
      `${API_BASE_URL}/vendors/${vendorId}/orders?offset=${offset}&count=${PAGE_ORDERS_COUNT}`,
      headers
    );
    this.props.hideSpinnerBackdrop();
    return res;
  };

  fetchPageData = async () => {
    const res = await this.fetchOrders(0);

    if (successStatusCodes.includes(res.status)) {
      const orders = res?.data?.data;
      if (!Array.isArray(orders)) {
        return toaster('Unable to fetch orders');
      }
      this.setState({
        orders,
        totalOrdersCount: res?.data?.count || 0,
        ordersAvailable: true,
        isLoading: false
      });
    } else {
      this.setState({
        ordersAvailable: false,
        isLoading: false
      });
    }
  };

  settlePayment = async orderId => {
    const { currentOffset } = this.state;
    const token = getAuthToken();
    const headers = {
      authorization: token,
      'Content-Type': 'application/json'
    };

    this.setState({ fetchingOrders: true });

    const res = await postWithResponseObject(
      `${API_BASE_URL}/order/transaction/${orderId}/complete`,
      {},
      headers
    );

    if (successStatusCodes.includes(res.status)) {
      toaster('payment status updated');
      const res = await this.fetchOrders(currentOffset);

      if (successStatusCodes.includes(res.status)) {
        const orders = res?.data?.data;
        if (!Array.isArray(orders)) {
          return toaster('Unable to fetch orders');
        }
        this.setState({
          orders,
          fetchingOrders: false
        });
      } else {
        this.setState({ fetchingOrders: false });
        return toaster('Unable to fetch orders');
      }
    } else {
      this.setState({ fetchingOrders: false });
      return toaster('Unable to update payment status');
    }
  };

  handleChangePage = async (event, newPage) => {
    if (this.state.fetchingOrders) return;
    const { currentPage, currentOffset } = this.state;
    const fetchOffset =
      newPage > currentPage ? currentOffset + 6 : currentOffset - 6;

    this.setState({ fetchingOrders: true });

    const res = await this.fetchOrders(fetchOffset);

    if (successStatusCodes.includes(res.status)) {
      const orders = res?.data?.data;
      if (!Array.isArray(orders)) {
        return toaster('Unable to fetch orders');
      }
      this.setState({
        orders,
        currentPage: newPage,
        currentOffset: fetchOffset,
        fetchingOrders: false
      });
    } else {
      this.setState({ fetchingOrders: false });
      return toaster('Unable to fetch orders');
    }
  };

  render() {
    const {
      isLoading,
      orders,
      totalOrdersCount,
      currentPage,
      fetchingOrders,
      ordersAvailable
    } = this.state;

    return (
      <Container>
        <ProductSection>
          {!isLoading && ordersAvailable && !isEmpty(orders) ? (
            <OrdersTable
              orderDetails={orders}
              totalOrdersCount={totalOrdersCount}
              currentPage={currentPage}
              fetchingOrders={fetchingOrders}
              settlePayment={this.settlePayment}
              handleChangePage={this.handleChangePage}
            />
          ) : (
            !isLoading &&
            !ordersAvailable && (
              <EmptyState errorMessage="No order details available" />
            )
          )}
        </ProductSection>
      </Container>
    );
  }
}

AllOrders.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AllOrders));
