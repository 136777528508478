import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
  width: 30px;
  height: 30px;
  margin: 0px auto;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left: 3px solid #333;
  border-right: 3px solid #333;
  animation: spinner 1s linear infinite forwards;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const CardSpinner = () => {
  return <Spinner />;
};

export default CardSpinner;
