import React from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Loader from '../../atoms/loader';
import { toaster } from '../../atoms/toaster';
import { Primary, CloseButton } from '../../atoms/button';
import TextInput from '../../atoms/textInput';
import PropTypes from 'prop-types';
import { post, getAuthToken } from '../../../utils';
import { API_BASE_URL } from '../../../config/env';
import UploadMedia from '../../atoms/UploadMediaInput';
import ReactPlayer from 'react-player';

import {
  SubHeading,
  CardDetails,
  EditBestTestimonyContainer,
  EditAddIngredientsContainer,
  EditTechnicalSpecifications,
  HowItWorksContainer,
  EditHowItWorksContent,
  HowItWorksTextContent,
  HowItWorksMediaContent,
  HowItWorksMediaContainer,
  HowItWorksImage,
  HowItWorksVideoContainer,
  EditMoreInformationContainer,
  EditFAQContainer,
  FAQElement,
  FAQQuestion,
  FAQAnswer,
  SavedFAQElement,
  SavedFAQQuestion,
  SavedFAQAnswer
} from './styles';

class ProductCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingProductImage: false,
      productId: this.props.match.params.productId,
      authId: this.props.match.params.authId,
      productSource: 'shop-type',
      fetchedProductDetails: {},
      fetchedCardDetails: {},
      mediaStorage: {
        images: {},
        videos: {}
      },
      variantStorage: {},
      collatedProductImages: [],
      collatedProductVideos: [],
      productDisplayMedia: '',
      productDisplayMediaType: '',
      selectedVariantIndex: 0,
      cardDetails: {},
      uploadImageUrls: [],
      taxable: false,
      faqQuestion: '',
      faqAnswer: '',
      faq: [],
      videoSrc: '',
      variantImages: {},
      variantImageUploadStatus: false,
      techspecs: [''],
      details: [''],

      howItWorks: {
        content: {
          mediaUrl: '',
          text: [],
          type: ''
        },
        heading: 'How It Works',
        id: '14',
        type: 'text'
      }
    };
  }

  fetchUploadedData = e => {
    const selectedFile = e.target.files ? e.target.files[0] : '';
    const selectedFileName = e.target.files ? e.target.files[0].name : '';
    const fileType = e.target.files[0].type || '';
    const fileId = e.target.id || '';

    this.setState({ isLoadingHowItWorks: true }, () => {
      toaster('Media Uploading...');
      var uploadMediaRequestBody = new FormData();
      uploadMediaRequestBody.append(selectedFileName, selectedFile);
      uploadMediaRequestBody.append('fileNames', `["${selectedFileName}"]`);
      const token = getAuthToken();
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token
      };

      post(
        `${API_BASE_URL}/command?type=addMedia`,
        uploadMediaRequestBody,
        headers
      ).then(response => {
        const result = response[Object.keys(response)[0]];

        this.setState({ fetchedUploadedMediaUrl: result }, () => {
          let { fetchedUploadedMediaUrl } = this.state;

          if (fileId === 'how-it-works') {
            let mediaType = fileType.includes('image') ? 'image' : 'video';
            this.props.storeHowItWorksMedia(fetchedUploadedMediaUrl, mediaType);
          }

          this.setState({ isLoadingHowItWorks: false });
        });
      });
    });
  };

  render() {
    const {
      testimony,
      features,
      technicalspecifications,
      details,
      howitworks,
      faqQuestion,
      faqAnswer,
      faq
    } = this.props;

    const { isLoadingHowItWorks } = this.state;

    return (
      <CardDetails>
        <EditBestTestimonyContainer>
          <SubHeading>Add your best testimony</SubHeading>
          <TextInput
            name="testimony"
            id="testimony"
            size="edit-detail-size"
            value={testimony.text}
            onChange={this.props.updateContentHandler}
            placeHolder="Testimony"
          />
        </EditBestTestimonyContainer>
        <EditAddIngredientsContainer>
          <SubHeading>Add Features</SubHeading>
          {features
            ? features.points.map((textElement, index) => {
                return (
                  <TextInput
                    name="features"
                    id={`features-${index}`}
                    size="edit-detail-size"
                    value={textElement}
                    onChange={this.props.updateContentHandler}
                    onKeyUp={this.props.handleKeyUp}
                    placeHolder="Features"
                    key={index}
                    autoFocus={
                      index > 0 && index === features.points.length - 1
                    }
                  />
                );
              })
            : null}
        </EditAddIngredientsContainer>
        <EditTechnicalSpecifications>
          <SubHeading>Add Specifications</SubHeading>
          {technicalspecifications
            ? technicalspecifications.points.map((textElement, index) => {
                return (
                  <TextInput
                    name="technicalspecifications"
                    id={`technicalspecifications-${index}`}
                    size="edit-detail-size"
                    value={textElement}
                    onChange={this.props.updateContentHandler}
                    onKeyUp={this.props.handleKeyUp}
                    placeHolder="Specifications"
                    key={index}
                    autoFocus={
                      index > 0 &&
                      index === technicalspecifications.points.length - 1
                    }
                  />
                );
              })
            : null}
        </EditTechnicalSpecifications>

        <EditMoreInformationContainer>
          <SubHeading>Add Details</SubHeading>
          {details
            ? details.points.map((textElement, index) => {
                return (
                  <TextInput
                    name="details"
                    id={`details-${index}`}
                    size="edit-detail-size"
                    value={textElement}
                    onChange={this.props.updateContentHandler}
                    onKeyUp={this.props.handleKeyUp}
                    placeHolder="Details"
                    key={index}
                    autoFocus={index > 0 && index === details.points.length - 1}
                  />
                );
              })
            : null}
        </EditMoreInformationContainer>

        <HowItWorksContainer>
          <SubHeading>How it works</SubHeading>

          <EditHowItWorksContent>
            <HowItWorksTextContent>
              {this.props.descriptionData.map((textDescription, index) => {
                return (
                  <>
                    <div style={{ display: 'flex' }} key={index}>
                      <TextInput
                        placeHolder="How it works"
                        onChange={e =>
                          this.props.handleInputDescriptionChange(e, index)
                        }
                        value={textDescription}
                      />
                      <button onClick={index => this.props.handleRemove(index)}>
                        REMOVE
                      </button>
                    </div>
                  </>
                );
              })}
              <div style={{ display: 'flex' }}>
                <button onClick={e => this.props.addDescription(e)}>
                  Add Description
                </button>
              </div>
            </HowItWorksTextContent>
            <HowItWorksMediaContent>
              {!isLoadingHowItWorks ? (
                !isEmpty(howitworks.mediaUrl) ? (
                  howitworks.type === 'image' ? (
                    <HowItWorksMediaContainer>
                      <CloseButton
                        width="20px"
                        height="20px"
                        size="custom"
                        textColor="white"
                        backgroundColor="black"
                        onClick={this.props.deleteHowItWorksMedia}
                      />

                      <HowItWorksImage src={howitworks.mediaUrl} />
                    </HowItWorksMediaContainer>
                  ) : howitworks.type === 'video' ? (
                    <HowItWorksMediaContainer>
                      <CloseButton
                        width="20px"
                        height="20px"
                        size="custom"
                        textColor="white"
                        backgroundColor="black"
                        onClick={this.props.deleteHowItWorksMedia}
                      />

                      <HowItWorksVideoContainer>
                        <ReactPlayer
                          url={howitworks.mediaUrl}
                          controls={true}
                          width="240px"
                          height="240px"
                        />
                      </HowItWorksVideoContainer>
                    </HowItWorksMediaContainer>
                  ) : null
                ) : (
                  <UploadMedia
                    size="how-it-works"
                    id="how-it-works"
                    onChange={this.fetchUploadedData}
                  />
                )
              ) : (
                <Loader
                  message="Loading..."
                  isFlex={true}
                  w={'100%'}
                  isCenter={true}
                  h={'240px'}
                />
              )}
            </HowItWorksMediaContent>
          </EditHowItWorksContent>
        </HowItWorksContainer>

        <EditFAQContainer>
          <SubHeading>Add FAQ</SubHeading>
          {faq
            ? faq.map((faqItem, index) => {
                return (
                  <SavedFAQElement key={index}>
                    <CloseButton
                      width="20px"
                      height="20px"
                      size="custom"
                      textColor="white"
                      backgroundColor="black"
                      onClick={() => {
                        this.props.deleteFAQElement(index);
                      }}
                    />
                    <SavedFAQQuestion>{faqItem.question}</SavedFAQQuestion>
                    <SavedFAQAnswer>{faqItem.answer}</SavedFAQAnswer>
                  </SavedFAQElement>
                );
              })
            : null}
          <FAQElement>
            <FAQQuestion>
              <TextInput
                name="faqQuestion"
                id="faqQuestion"
                size="edit-detail-size"
                value={faqQuestion}
                placeHolder="Add Question"
                onChange={this.props.updateContentHandler}
              />
            </FAQQuestion>
            <FAQAnswer>
              <TextInput
                name="faqAnswer"
                id="faqAnswer"
                size="edit-detail-size"
                value={faqAnswer}
                placeHolder="Add Answer"
                onChange={this.props.updateContentHandler}
              />
            </FAQAnswer>
          </FAQElement>
          <Primary
            text="+ Add another question"
            onClick={() => {
              this.props.saveFAQElement();
            }}
          />
        </EditFAQContainer>
      </CardDetails>
    );
  }
}

ProductCards.propTypes = {
  match: PropTypes.object,
  updateContentHandler: PropTypes.func,
  handleKeyUp: PropTypes.func,
  features: PropTypes.object,
  technicalspecifications: PropTypes.object,
  details: PropTypes.object,
  testimony: PropTypes.object,
  descriptionData: PropTypes.array,
  addDescription: PropTypes.func,
  handleRemove: PropTypes.func,
  handleInputDescriptionChange: PropTypes.func,
  storeHowItWorksMedia: PropTypes.func,
  howitworks: PropTypes.object,
  deleteHowItWorksMedia: PropTypes.func,
  faq: PropTypes.array,
  faqQuestion: PropTypes.string,
  faqAnswer: PropTypes.string,
  saveFAQElement: PropTypes.func,
  deleteFAQElement: PropTypes.func
};

export default withRouter(ProductCards);
