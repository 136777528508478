import { handleActions } from 'redux-actions';
import * as constants from '../constants';
import update from 'immutability-helper';

const initalState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: {},
  checkoutFormData: { shipping: { address: {} }, billing: { address: {} } },
  salesOrderResponse: {},
  makePaymentResponse: {},
  states: { billing: [], shipping: [] }
};

const makePaymentRequest = (state, action) =>
  update(state, {
    isLoading: { $set: true },
    isError: { $set: false },
    isSuccess: { $set: false }
  });
const makePaymentSuccess = (state, action) =>
  update(state, {
    isLoading: { $set: false },
    isError: { $set: false },
    isSuccess: { $set: true },
    makePaymentResponse: { $set: action.payload }
  });
const makePaymentError = (state, action) =>
  update(state, {
    isLoading: { $set: false },
    isError: { $set: true },
    isSuccess: { $set: false }
  });

const salesOrderRequest = (state, action) =>
  update(state, {
    salesOrderProcessing: { $set: true },
    salesOrderError: { $set: false },
    salesOrderResponse: { $set: {} }
  });

const salesOrderSuccess = (state, action) =>
  update(state, {
    salesOrderProcessing: { $set: false },
    salesOrderError: { $set: false },
    salesOrderResponse: { $set: action.payload }
  });

const salesOrderError = (state, action) =>
  update(state, {
    salesOrderProcessing: { $set: false },
    salesOrderError: { $set: action.payload },
    salesOrderResponse: { $set: {} }
  });

const setCheckoutFormFields = (state, action) => {
  const { shipping, billing } = action.payload;
  const shippingAddres = shipping.address;
  const billingAddress = billing.address;
  return update(state, {
    checkoutFormData: {
      shipping: { $merge: shipping, address: { $merge: shippingAddres } },
      billing: { $merge: billing, address: { $merge: billingAddress } }
    }
  });
};

const setInitalStatusRequest = (state, action) =>
  update(state, {
    isLoading: { $set: false },
    isError: { $set: false },
    isSuccess: { $set: false }
  });

const clearPlacedOrderDataRequest = (state, action) => {
  return update(state, {
    salesOrderResponse: {
      $set: {
        order_number: state.salesOrderResponse.order_number,
        customer_email: state.salesOrderResponse.customer_email
      }
    },
    makePaymentResponse: { $set: {} }
  });
};

const clearBillingFromCheckoutData = (state, action) => {
  return update(state, {
    checkoutFormData: {
      billing: { $set: { address: {} } }
    }
  });
};

const setStateCountryResponse = (state, action) => {
  const { states, type } = action.payload;
  if (type === 'billing') {
    return update(state, {
      states: { billing: { $set: states } }
    });
  } else if (type === 'shipping') {
    return update(state, {
      states: { shipping: { $set: states } }
    });
  }
};

export default handleActions(
  {
    [constants.MAKE_PAYMENT_REQUEST]: makePaymentRequest,
    [constants.MAKE_PAYMENT_SUCCESS]: makePaymentSuccess,
    [constants.MAKE_PAYMENT_ERROR]: makePaymentError,
    [constants.MAKE_SALES_ORDER_REQUEST]: salesOrderRequest,
    [constants.MAKE_SALES_ORDER_SUCCESS]: salesOrderSuccess,
    [constants.MAKE_SALES_ORDER_ERROR]: salesOrderError,
    [constants.SET_CHECKOUT_FORM_FIELDS]: setCheckoutFormFields,
    [constants.SET_INITIAL_STATUS_REQUEST]: setInitalStatusRequest,
    [constants.CLEAR_PLACED_ORDER_DATA]: clearPlacedOrderDataRequest,
    [constants.CLEAR_BILLING_FROM_FORM]: clearBillingFromCheckoutData,
    [constants.STATE_COUNTRY_RESPONSE]: setStateCountryResponse
  },
  initalState
);
