import styled from 'styled-components';

const SidebarContainer = styled.aside`
  position: fixed;
  width: 100px;
  height: 100vh !important;
  overflow-y: auto;
  border-right: solid 1px #ccc;
  background-color: ${props => props.theme.WHITE};
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1005;
`;

const Logo = styled.img`
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
`;

const Category = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const CategoryItem = styled.li`
  padding: 3px 10px;
  width: 100%;
  color: ${props => props.theme.MINE_SHAFT};
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    font-weight: ${props => props.theme.SEMI_BOLD_FONT};
    background-color: ${props => props.theme.WHITE}!important;
  }
  font-weight: ${props =>
    props.isActive ? props.theme.SEMI_BOLD_FONT : props.theme.LIGHT_FONT};
  background-color: ${props => (props.isActive ? props.theme.WHITE : 'unset')};

  a {
    text-decoration: none;
  }
`;

const InactiveIcon = styled.div`
  background-color: ${props => props.theme.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
`;

const ActiveIcon = styled.div`
  background-color: ${props => props.theme.RONCHI};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 15px;
`;

const LogoTitle = styled.div`
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const CategoryItemContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CategoryItemName = styled.span`
  font-size: ${props => props.theme.X_SMALL};
`;

export {
  SidebarContainer,
  Logo,
  Category,
  CategoryItem,
  InactiveIcon,
  ActiveIcon,
  LogoContainer,
  LogoTitle,
  CategoryItemContent,
  CategoryItemName
};
