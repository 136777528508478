import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Loader from '../../atoms/loader';
import { toaster } from '../../atoms/toaster';
import { CloseButton } from '../../atoms/button';
import UploadMedia from '../../atoms/UploadMediaInput';
import { API_BASE_URL } from '../../../config/env';
import CheckBox from '../../atoms/checkBox';
import { post, getAuthToken } from '../../../utils';

import ReactPlayer from 'react-player';

import {
  SubHeading,
  EditImageContainer,
  EditImageInnerContainer,
  ProductMediaContainer,
  ProductVideoContainer,
  ImagesContainer,
  ProductImageWrapper,
  MediaOperations,
  VideosContainer
} from './styles';

class MediaContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingMediaStorageMedia: this.props.isLoadingMediaStorageMedia,
      mediaStorage: {
        images: {},
        videos: {}
      },
      primaryImage: {
        mediaId: '',
        mediaSrc: '',
        variantIds: []
      },
      structureDataStatus: this.props.structureDataStatus
    };
  }

  fetchUploadedData = e => {
    const selectedFile = e.target.files ? e.target.files[0] : '';
    const selectedFileName = e.target.files ? e.target.files[0].name : '';
    const fileType = e.target.files[0].type || '';
    const fileId = e.target.id || '';

    this.setState({ isLoadingMediaStorageMedia: true }, () => {
      toaster('Media Uploading...');
      var uploadMediaRequestBody = new FormData();
      uploadMediaRequestBody.append(selectedFileName, selectedFile);
      uploadMediaRequestBody.append('fileNames', `["${selectedFileName}"]`);
      const token = getAuthToken();
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token
      };

      post(
        `${API_BASE_URL}/command?type=addMedia`,
        uploadMediaRequestBody,
        headers
      ).then(response => {
        const result = response[Object.keys(response)[0]];

        this.setState({ fetchedUploadedMediaUrl: result }, () => {
          let { fetchedUploadedMediaUrl } = this.state;
          this.props.pushNewMediaElementToMediaStorage(
            fetchedUploadedMediaUrl,
            selectedFileName,
            fileType,
            fileId
          );

          this.setState({ isLoadingMediaStorageMedia: false });
        });
      });
    });
  };

  fetchAllMediaType = mediaType => {
    const { mediaStorage } = this.props;

    let collatedImagesArray = [];

    if (!isEmpty(mediaStorage[mediaType])) {
      Object.keys(mediaStorage[mediaType]).forEach(mediaId => {
        collatedImagesArray.push([
          mediaId,
          mediaStorage[mediaType][mediaId].mediaSrc,
          mediaStorage[mediaType][mediaId].primary
        ]);
      });
    }

    return collatedImagesArray;
  };

  render() {
    const { mediaStorage } = this.props;
    const { isLoadingMediaStorageMedia } = this.state;

    return (
      <EditImageContainer>
        <EditImageInnerContainer>
          <SubHeading>Add Images/Video</SubHeading>
          <ProductMediaContainer>
            <ImagesContainer>
              {this.fetchAllMediaType('images').map((imageElement, index) => {
                return (
                  <ProductImageWrapper key={index}>
                    <MediaOperations>
                      <CheckBox
                        onChange={() => {
                          this.props.changePrimaryImage(
                            imageElement[0],
                            'media-storage'
                          );
                        }}
                        value={imageElement[2] || false}
                        label=""
                      />
                      <CloseButton
                        width="20px"
                        height="20px"
                        size="custom"
                        textColor="white"
                        backgroundColor="black"
                        onClick={() => {
                          this.props.deleteMedia(
                            imageElement[0],
                            'images',
                            'media-storage'
                          );
                        }}
                      />
                    </MediaOperations>
                    <img
                      key={index}
                      src={imageElement[1]}
                      alt="media-storage-media"
                    />
                  </ProductImageWrapper>
                );
              })}
            </ImagesContainer>
            <VideosContainer>
              {this.fetchAllMediaType('videos').map((videoElement, index) => {
                return (
                  <ProductVideoContainer key={index}>
                    <MediaOperations>
                      <CheckBox value={videoElement[2]} />
                      <CloseButton
                        width="20px"
                        height="20px"
                        size="custom"
                        textColor="white"
                        backgroundColor="black"
                        onClick={() => {
                          this.props.deleteMedia(
                            videoElement[0],
                            'videos',
                            'media-storage'
                          );
                        }}
                      />
                    </MediaOperations>
                    <ReactPlayer
                      url={videoElement[1]}
                      controls={true}
                      width="160px"
                      height="160px"
                    />
                  </ProductVideoContainer>
                );
              })}
            </VideosContainer>
            {!isLoadingMediaStorageMedia ? (
              <UploadMedia
                size={
                  isEmpty(mediaStorage.images) && isEmpty(mediaStorage.videos)
                    ? 'empty-media-storage'
                    : 'filled-media-storage'
                }
                id="media-storage"
                onChange={this.fetchUploadedData}
              />
            ) : (
              <Loader
                message="Loading Media.."
                isFlex={true}
                w={'100%'}
                isCenter={true}
                h={'240px'}
              />
            )}
          </ProductMediaContainer>
        </EditImageInnerContainer>
      </EditImageContainer>
    );
  }
}

MediaContainer.propTypes = {
  match: PropTypes.object,
  isLoadingMediaStorageMedia: PropTypes.bool,
  mediaStorage: PropTypes.object,
  pushNewMediaElementToMediaStorage: PropTypes.func,
  changePrimaryImage: PropTypes.func,
  deleteMedia: PropTypes.func,
  structureDataStatus: PropTypes.bool
};

export default withRouter(MediaContainer);
