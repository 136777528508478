import styled from 'styled-components';

const InputFieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CheckboxContainer = styled.div`
  min-width: 240px;
  max-width: 380px;
  width: 100%;
`;

const ApiKey = styled.div`
  width: 100%;
  padding: 6px;
  border-radius: 4px;
  border: 1.8px solid #b8b8b8;
  overflow-wrap: break-word;
  font-size: ${props => props.theme.MEDIUM};
  font-weight: ${props => props.theme.MEDIUM_FONT};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
`;

const CopyButtonContainer = styled.div`
  margin-left: 8px;
`;

const MainContentWrapper = styled.div`
  min-height: 240px;
  max-height: 280px;
  padding: 6px 0;
  overflow-y: auto;
`;

const CurrenciesImage = styled.img`
  width: 120px;
`;

const MainContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CurrencySelectContainer = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export {
  InputFieldContainer,
  CheckboxContainer,
  ApiKey,
  Row,
  CopyButtonContainer,
  MainContentWrapper,
  CurrenciesImage,
  MainContentFlex,
  CurrencySelectContainer
};
