import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import noBannerImage from '../../../../assets/images/no-preview-available.png';
import { API_BASE_URL } from '../../../../config/env';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import history from '../../../../history';
import * as actions from '../../../../redux/actions/uiActions';
import shoptypeThemes from '../../../../theme';
import {
  getApiResponseObject,
  getAuthToken,
  putWithResponseObject,
  showApiErrorToast,
  successStatusCodes,
  dateWithoutTime
} from '../../../../utils';
import EmptyState from '../../../atoms/EmptyState';
import PaginationControls from '../../../atoms/paginationControls';
import SearchBar from '../../../atoms/searchBar';
import { toaster } from '../../../atoms/toaster';
import {
  Container,
  Heading,
  HeadingSection,
  PaginationWrapper
} from '../../Dashboard/styles';
import GetCollectionsFilters from './GetCollectionsFilters';
import { StyledChip, StyledDivider } from './styles';

const useStyles = makeStyles({
  card: {
    border: '0.25px solid rgba(0, 0, 0, 0.4)',
    borderRadius: '2.5px'
  },
  media: {
    height: 195,
    backgroundSize: 'contain'
  },
  chip: {
    margin: '4px'
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '20px',
    gap: '10px'
  },
  searchbar: {
    minWidth: '200px'
  },
  cardContent: {
    paddingBottom: '15px !important',
    height: 'calc(100% - 200px)',
    display: 'flex',
    flexDirection: 'column'
  },
  cardTypography: {
    width: '100%',
    fontSize: '16px'
  },
  descTypography: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px'
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(300px, 1fr))',
    gridGap: '12px'
  },
  draftStatus: {
    backgroundColor: shoptypeThemes.default.RONCHI
  },
  publishedStatus: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    color: shoptypeThemes.default.BLACK
  },
  draftText: {
    color: shoptypeThemes.default.DARK_RED
  },
  publishedText: {
    color: shoptypeThemes.default.VERDUN_GREEN
  },
  button: {
    backgroundColor: shoptypeThemes.default.RONCHI,
    fontSize: '12px',
    color: shoptypeThemes.default.BLACK,
    padding: '10px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: shoptypeThemes.default.RONCHI
    }
  },
  cardBtn: {
    textTransform: 'none'
  },
  attributes: {
    minHeight: '70px'
  },
  filterButton: {
    padding: 0,
    marginLeft: '10px'
  },
  buttons: {
    marginTop: 'auto'
  }
});

function CollectionsWrapper() {
  const classes = useStyles();

  const [fetchParams, updateFetchParams] = useState({
    count: 8, // set this for pagesize
    offset: 0,
    text: '',
    status: '',
    type: '',
    sortBy: '',
    orderBy: '',
    isMenu: null,
    isCampaign: null,
    campaignStartDate: dateWithoutTime(
      new Date(new Date().setDate(new Date().getDate() - 30))
    ),
    campaignEndDate: dateWithoutTime(new Date())
  });
  const [collectionsData, updateCollections] = useState([]);
  const [totalResultsCount, updateTotalResults] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const updateFetchParamsFromPagination = fetchParamsData => {
    updateFetchParams(fetchParamsData);
  };

  useEffect(() => {
    fetchCollectionsData();
  }, []);

  useEffect(() => {
    fetchCollectionsData();
  }, [fetchParams]);

  const fetchCollectionsData = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    dispatch(actions.showSpinnerBackdropAction());

    const response = await getApiResponseObject(
      `${API_BASE_URL}/networks/collections?showDynamic=true`,
      headers,
      fetchParams
    );
    updateCollections(response.data.collections);
    updateTotalResults(response.data.count);
    setIsLoading(false);
    if (!successStatusCodes.includes(response.status)) {
      dispatch(actions.hideSpinnerBackdropAction());
      showApiErrorToast(response.data);
    }

    dispatch(actions.hideSpinnerBackdropAction());
  };

  const updateCollectionStatus = async (status, collection_id) => {
    const token = getAuthToken();
    const headers = { authorization: token };
    let response = '';
    let requestBody = {
      status: status === 'draft' ? 'published' : 'draft'
    };

    response = await putWithResponseObject(
      `${API_BASE_URL}/networks/collections/${collection_id}`,
      requestBody,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      toaster('Collection updated');
      fetchCollectionsData();
    } else {
      showApiErrorToast(response.data);
    }
  };

  const redirectToCreateCollection = (isUpdate, id) => {
    if (!isUpdate) {
      history.push('/networkoperator/create-collection');
    } else {
      history.push(`/networkoperator/create-collection/${id}`);
    }
  };

  const toggleFilters = () => {
    setShowFilters(showFiltersValue => !showFiltersValue);
  };

  const getProductsCount = ele => {
    if (ele.type === 'product') {
      return `${ele?.product_metas?.length ?? 0} Product${
        ele?.product_metas?.length !== 1 ? 's' : ''
      }`;
    } else {
      return `${ele?.collection_ids?.length ?? 0} Collection${
        ele?.collection_ids?.length !== 1 ? 's' : ''
      }`;
    }
  };

  return (
    <Container>
      <HeadingSection>
        <Heading>Collections</Heading>
        <Tooltip
          TransitionComponent={Zoom}
          title={NETWORK_OPERATOR_INFO.pageDescription.Collections}
          arrow
        >
          <InfoRoundedIcon />
        </Tooltip>
      </HeadingSection>
      <Box className={classes.filterBox}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="550px"
        >
          <SearchBar
            placeholder={'Search'}
            fetchParams={fetchParams}
            updateFetchParams={updateFetchParamsFromPagination}
            className={classes.searchbar}
          />
          <IconButton
            aria-label="add"
            className={classes.filterButton}
            onClick={() => toggleFilters()}
          >
            <FilterListIcon />
          </IconButton>
        </Box>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => redirectToCreateCollection(false)}
        >
          Create Collection
        </Button>
      </Box>
      {showFilters && (
        <>
          <StyledDivider />
          <GetCollectionsFilters
            fetchParams={fetchParams}
            updateFetchParams={updateFetchParamsFromPagination}
          />
          <StyledDivider />
        </>
      )}
      {!isLoading && !isEmpty(collectionsData) ? (
        <div className={classes.cardContainer}>
          {collectionsData.map(collectionEle => {
            return (
              <Card
                variant="outlined"
                key={collectionEle.id}
                className={classes.card}
              >
                <CardMedia
                  className={classes.media}
                  image={collectionEle.preview_image_src || noBannerImage}
                  title={collectionEle.name || '-'}
                />
                <CardContent className={classes.cardContent}>
                  <Typography
                    className={classes.cardTypography}
                    gutterBottom
                    variant="h6"
                    component="h2"
                  >
                    {collectionEle.name || '-'}
                  </Typography>
                  <Typography
                    title={collectionEle.description}
                    className={classes.descTypography}
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    {collectionEle.description || '-'}
                  </Typography>
                  <StyledDivider />
                  <div className={classes.attributes}>
                    <Typography
                      className={classes.cardTypography}
                      gutterBottom
                      variant="h6"
                      component="h2"
                    >
                      Attributes
                    </Typography>
                    <StyledChip
                      className={
                        collectionEle.status === 'draft'
                          ? classes.draftStatus
                          : classes.publishedStatus
                      }
                      label={collectionEle.status}
                    />
                    <StyledChip label={getProductsCount(collectionEle)} />
                    {collectionEle.product_query?.tags?.length > 0 && (
                      <StyledChip
                        label={`${
                          collectionEle.product_query.tags.length > 1
                            ? collectionEle.product_query.tags.length + ' Tags'
                            : collectionEle.product_query.tags.length + ' Tag'
                        }`}
                      />
                    )}
                    {collectionEle?.attributes?.is_campaign && (
                      <StyledChip label="Campaign" />
                    )}

                    {collectionEle?.attributes?.is_campaign && (
                      <>
                        <StyledChip
                          label={`Starts on ${moment(
                            collectionEle?.attributes?.campaign_start_date
                          ).format('DD/MM/YYYY')}`}
                        />
                        <StyledChip
                          label={`Ends on ${moment(
                            collectionEle?.attributes?.campaign_end_date
                          ).format('DD/MM/YYYY')}`}
                        />
                      </>
                    )}
                  </div>

                  <div className={classes.buttons}>
                    <StyledDivider />
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Button
                        className={`${classes.cardBtn} ${
                          collectionEle.status === 'draft'
                            ? classes.publishedText
                            : classes.draftText
                        }`}
                        color="secondary"
                        onClick={() =>
                          updateCollectionStatus(
                            collectionEle.status,
                            collectionEle.id
                          )
                        }
                      >
                        {collectionEle.status === 'draft'
                          ? 'Publish'
                          : 'Unpublish'}
                      </Button>
                      <Button
                        className={classes.cardBtn}
                        color="primary"
                        onClick={() =>
                          redirectToCreateCollection(true, collectionEle.id)
                        }
                      >
                        Update
                      </Button>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            );
          })}
        </div>
      ) : (
        !isLoading && <EmptyState errorMessage="No collections found" />
      )}
      <PaginationWrapper>
        <PaginationControls
          fetchParams={fetchParams}
          totalItemCount={totalResultsCount}
          updateFetchParams={updateFetchParamsFromPagination}
        />
      </PaginationWrapper>
    </Container>
  );
}

export default withRouter(CollectionsWrapper);
