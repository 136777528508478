import React from 'react';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { withStyles } from '@material-ui/styles';
import {
  Checkbox,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import _ from 'lodash';

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#E6C100'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '220px',
    marginRight: 0
  },
  textField: {
    backgroundColor: '#fff'
  }
}));

const MultiSelectAutoComplete = props => {
  const {
    vendorsList,
    updateSelectedVendors,
    width,
    size,
    label,
    loadMoreResults,
    changeOptionBaseOnValue
  } = props;

  const classes = useStyles();
  return (
    <Autocomplete
      className={classes.root}
      style={{ width: width }}
      multiple
      loading={vendorsList.length > 0 ? true : false}
      id="vendors-autocomplete"
      limitTags={2}
      size={size}
      disableCloseOnSelect
      options={vendorsList}
      getOptionLabel={option => option || ''}
      renderOption={(option, { selected }) => (
        <>
          <CustomCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            style={{ marginRight: 8 }}
          />
          {option}
        </>
      )}
      onChange={(event, value) => updateSelectedVendors(value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Typography key={index} noWrap label={option} {...getTagProps({ index })}>
            {option},
          </Typography>
        ))
      }
      ListboxProps={{
        onScroll: event => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollHeight -
              listboxNode.scrollTop -
              listboxNode.clientHeight <=
            1
          ) {
            if (loadMoreResults !== undefined) {
              loadMoreResults();
            }
          }
        }
      }}
      onInputChange={_.debounce((event, value, reason) => {
        if (reason === 'input') {
          if (changeOptionBaseOnValue !== undefined) {
            changeOptionBaseOnValue(value.trim());
          }
        }
      }, 500)}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default MultiSelectAutoComplete;
