import React from 'react';
import Icons from '../../atoms/icons';
import { toaster } from '../../atoms/toaster';
import {
  TagsInputMainContainer,
  TagsInputContainer,
  TagSpan,
  DeleteButton,
  HelpSpan
} from './styles';

const Tag = props => <TagSpan {...props} />;
const Delete = props => <DeleteButton {...props} />;
const Help = props => <HelpSpan {...props} />;

class TagsInput extends React.Component {
  constructor() {
    super();
    this.state = {
      newTag: ''
    };
  }

  handleChange = e => {
    this.setState({ newTag: e.target.value });
  };

  handleKeyDown = e => {
    if (e.keyCode === 13 && e.target.value !== '') {
      let newTag = this.state.newTag.trim();
      if (this.props.value.indexOf(newTag) === -1) {
        if (this.props.value.length < 20) {
          this.props.value.push(newTag);
          this.setState({ newTag: '' });
        } else {
          return toaster('You can input a maximum of 20 tags');
        }
      }
      e.target.value = '';
    }
  };

  handleRemoveTag = e => {
    let tag = e.target.parentNode.textContent.trim();
    let index = this.props.value.indexOf(tag);
    this.props.value.splice(index, 1);
    this.setState({ newTag: '' });
  };

  render() {
    return (
      <TagsInputMainContainer>
        <TagsInputContainer>
          {this.props.value.map((tag, index) => (
            <Tag key={index}>
              {tag}
              <Delete onClick={e => this.handleRemoveTag(e)} />
            </Tag>
          ))}
          <label>
            <input
              type="text"
              placeholder="Interests"
              onChange={e => this.handleChange(e)}
              onKeyDown={e => this.handleKeyDown(e)}
            />
          </label>
        </TagsInputContainer>
        <Help>
          <Icons name="info-icon" width={20} height={20} />
          hit 'Enter' to add new Interests
        </Help>
      </TagsInputMainContainer>
    );
  }
}

export default TagsInput;
