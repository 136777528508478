import Fingerprint2 from 'fingerprintjs2';

const getDeviceId = async (deviceIdCreatedCallback = null) => {
  const fingerprintExcludeOptions = {
    hardwareConcurrency: true,
    availableScreenResolution: true,
    sessionStorage: true,
    localStorage: true,
    indexedDb: true,
    addBehavior: true,
    openDatabase: true,
    plugins: true,
    canvas: true,
    webgl: true,
    adBlock: true,
    fontsFlash: true,
    enumerateDevices: true,
    doNotTrack: true,
    hasLiedLanguages: true,
    hasLiedResolution: true,
    hasLiedOs: true,
    hasLiedBrowser: true,
    touchSupport: true,
    fonts: true,
    audio: true,
    pixelRatio: true,
    timezone: true,
    extendedJsFonts: true
  };

  setTimeout(async () => {
    try {
      const components = await Fingerprint2.getPromise({
        excludes: fingerprintExcludeOptions
      });
      const murmurFingerprint = Fingerprint2.x64hash128(
        components.map(c => c.value).join(''),
        31
      );
      var currentDate = new Date();
      currentDate.setTime(currentDate.getTime() + 365 * 24 * 60 * 60 * 1000);

      localStorage.setItem('deviceId', murmurFingerprint);

      if (deviceIdCreatedCallback) {
        deviceIdCreatedCallback();
      }
    } catch (e) {
      console.info('There was a problem getting fingerprint data', e);
      return {};
    }
  }, 500);
};

export default getDeviceId;
