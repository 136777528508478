import styled from 'styled-components';

const Row = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 30px auto;
  background-color: ${props => props.theme.WHITE};
  color: ${props => props.theme.BLACK};
  position: relative;
  border: 1px solid ${props => props.theme.PINK_SWAN};
  ${props => props.theme.PHONE`
    max-width: 330px;
    width: 100%;
  `}
`;

export default Row;
