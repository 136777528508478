import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/uiActions';
import {
  VendorOnBoardForm,
  Next,
  Container,
  Heading,
  SubHeading,
  VendorOnBoardContainer,
  VendorName,
  WebsiteUrl,
  SampleURL
} from './styles';
import history from '../../../history';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  postWithResponseObject,
  isInvalidNetoworkDetails,
  showApiErrorToast
} from '../../../utils';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';

class NetworkOperatorOnBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorName: '',
      productCategories: '',
      websiteUrl: '',
      disableButton: false
    };
  }

  queryOnboardData = async () => {
    const { vendorName, websiteUrl } = this.state;
    const token = getAuthToken();

    let error = isInvalidNetoworkDetails(vendorName);
    if (error) {
      toaster(error);
      return;
    }

    const headers = { authorization: token };
    const requestBody = {
      name: vendorName.trim(),
      urls: [websiteUrl.trim()]
    };
    this.setState({ loading: true }, async () => {
      this.props.showSpinnerBackdrop();
      this.setState({ disableButton: true });
      let res = await postWithResponseObject(
        `${API_BASE_URL}/networks`,
        requestBody,
        headers
      );
      this.setState({ disableButton: false });
      this.props.hideSpinnerBackdrop();

      if (successStatusCodes.includes(res.status)) {
        this.setState({
          loading: false
        });
        localStorage.setItem('USER_TYPE', 'NETWORK_OPERATOR');
        localStorage.setItem('token', JSON.stringify(res.data.token));
        history.push('/networkoperator/dashboard');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
          authorization: token
        });
        if (successStatusCodes.includes(profileRes.status)) {
          localStorage.setItem(
            'userProfile',
            JSON.stringify(profileRes.data || {})
          );
        } else {
          localStorage.setItem('userProfile', JSON.stringify({}));
        }
      } else {
        showApiErrorToast(res.data);
      }
    });
  };

  inputChangeHandler = e => {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  handleSubmit = () => {
    this.queryOnboardData();
  };

  render() {
    const { vendorName, websiteUrl, disableButton } = this.state;
    return (
      <Container>
        <Heading>Create a profile</Heading>
        <SubHeading>Add your details</SubHeading>
        <VendorOnBoardContainer>
          <VendorOnBoardForm>
            <VendorName
              name="vendorName"
              type="text"
              placeHolder="What is your Network name ?"
              required={true}
              id="vendorName"
              value={vendorName}
              onChange={this.inputChangeHandler}
            />
            <WebsiteUrl
              name="websiteUrl"
              type="text"
              placeHolder="Platform URL"
              id="website"
              value={websiteUrl}
              onChange={this.inputChangeHandler}
              autocomplete="off"
            />
            <SampleURL>Example: https://www.mywebsite.com</SampleURL>
          </VendorOnBoardForm>
        </VendorOnBoardContainer>
        <div style={{ marginTop: '70px' }}>
          <Next
            text="Next"
            disabled={disableButton}
            onClick={this.handleSubmit}
          />
        </div>
      </Container>
    );
  }
}

NetworkOperatorOnBoard.propTypes = {
  history: PropTypes.object.isRequired,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(NetworkOperatorOnBoard)
);
