import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { CheckboxContainer, SubHeading } from '../pages/Dashboard/styles';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  pb0: {
    paddingBottom: '0px'
  }
});
const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#E6C100'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

function STFilters(props) {
  const classes = useStyles();

  const [activeFilter, setActiveFilters] = useState([]);

  useEffect(() => {
    if (props.clearFilter) {
      if (!props.isMulti) {
        setActiveFilters([]);
      } else {
        setActiveFilters('');
      }
    }
    const { fetchParams, updateFetchParams } = props;
    let newFetchparams = { ...fetchParams };
    newFetchparams.vendorId = '';
    updateFetchParams(newFetchparams);
  }, [props.clearFilter]);

  const onFilterChange = filter => {
    let vendorId = '';

    if (activeFilter.includes(filter)) {
      const filterIndex = activeFilter.indexOf(filter);
      const newFilter = [...activeFilter];
      newFilter.splice(filterIndex, 1);
      vendorId = newFilter.length > 0 ? newFilter.join(',') : '';
      setActiveFilters(newFilter);
    } else {
      if (!props.isMulti) {
        vendorId = filter;
        setActiveFilters([filter]);
      } else {
        let filteredData = [...activeFilter, filter];
        vendorId = filteredData.join(',');
        setActiveFilters(filteredData);
      }
    }
    const { fetchParams, updateFetchParams } = props;
    let newFetchparams = { ...fetchParams };
    newFetchparams.vendorId = vendorId;
    updateFetchParams(newFetchparams);
  };

  return (
    <div>
      <SubHeading className={classes.pb0}>{props.filterName}</SubHeading>
      <div
        style={{
          maxHeight: props.height,
          overflow: 'auto',
          overflowX: 'hidden'
        }}
      >
        {!isEmpty(props.filterList) ? (
          props.filterList.map(filter => (
              <CheckboxContainer key={filter.id} style={{ display: 'block' }}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={activeFilter.includes(filter.value)}
                      onChange={() => onFilterChange(filter.value)}
                    />
                  }
                  label={filter.name}
                />
              </CheckboxContainer>
          ))
        ) : (
          <p style={{ textAlign: 'center' }}>No linked vendors</p>
        )}
      </div>
    </div>
  );
}

export default STFilters;
