import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.WILD_SAND};
  padding: 50px;
  align-items: center;
`;

export { Container };
