import React from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../../atoms/loader';
import NetworkOperatorDashboard from './index';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes,
  getUserType,
  getUserProfile,
  getUserProfileNetworks
} from '../../../../utils';
import history from '../../../../history';
import { API_BASE_URL } from '../../../../config/env';
import { EventsBaseClient } from '../../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../../constants';
class NetworkOperatorDashboardWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showDashboard: false };
  }

  componentDidMount() {
    this.proceedToDashboard();
  }

  proceedToDashboard = async () => {
    const token = getAuthToken();
    const userType = getUserType();
    const userProfileSet = await this.userProfileSet();
    const userProfileNetworksSet = await this.userProfileNetworksSet();

    if (token) {
      if (!userType) {
        history.push('/users/platform');
        return;
      }
      if (userProfileSet && userProfileNetworksSet) {
        this.setState({ showDashboard: true });
      }
    } else {
      history.push('/');
    }
  };

  userProfileSet = async () => {
    const userProfile = getUserProfile();

    if (!userProfile) {
      const token = getAuthToken();
      const headers = { authorization: token };

      const res = await getApiResponseObject(`${API_BASE_URL}/me`, headers);

      if (successStatusCodes.includes(res.status)) {
        localStorage.setItem('userProfile', JSON.stringify(res.data));
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);

        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(true);
    }
  };

  userProfileNetworksSet = async () => {
    const userProfileNetworks = getUserProfileNetworks();

    if (!userProfileNetworks?.network?.name) {
      const token = getAuthToken();
      const headers = { authorization: token };

      const res = await getApiResponseObject(
        `${API_BASE_URL}/networks`,
        headers
      );

      if (successStatusCodes.includes(res.status)) {
        localStorage.setItem('userProfileNetworks', JSON.stringify(res.data));
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(true);
    }
  };

  render() {
    const { showDashboard } = this.state;
    return (
      <>
        {showDashboard ? (
          <NetworkOperatorDashboard />
        ) : (
          <div style={{ width: '100%', height: '100vh' }}>
            <Loader
              message="Loading..."
              isFlex={true}
              w={'100%'}
              h={'100%'}
              isCenter={true}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(NetworkOperatorDashboardWrapper);
