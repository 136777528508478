import React from 'react';
import { withTheme } from 'styled-components';

const Logo = () => {
  return (
    <img
      src={require('../../../assets/images/logo.png')}
      alt="logo"
      width="120px"
    />
  );
};

export default withTheme(Logo);
