import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { withRouter } from 'react-router-dom';
import DefaultProductImage from '../../../assets/images/photo.svg';
import { Filled as FilledButton } from '../../atoms/button';
import { toaster } from '../../atoms/toaster';
import Logo from '../../atoms/logo';
import Modal from '../../templates/Modal';
import Loader from '../../atoms/loader';
import { API_BASE_URL, APP_BASE_URL } from '../../../config/env';
import HorizontalSeparator from '../../atoms/horizontalSeparator';
import {
  GOOGLEPROVIDER,
  FACEBOOKPROVIDER,
  DOMAIN,
  USER_TYPE,
  FACEBOOKAPPID,
  GOOGLECLIENTID
} from '../../../constants';

import {
  postWithResponseObject,
  getApiResponseObject,
  successStatusCodes,
  isUserNameValid,
  getAuthId,
  showApiErrorToast
} from '../../../utils';
import history from '../../../history';
import TextInput from '../../atoms/textInput';
import PublishModal from '../../organisms/ShareOnSocial';
import * as actions from '../../../redux/actions/uiActions';
import { connect } from 'react-redux';
import CountdownTimer from '../../atoms/countdownTimer';
import PrivacyInformation from '../../atoms/privacyInformation';

import {
  Container,
  InnerContainer,
  AuthContainer,
  VendorOrCosellerLogin,
  ModalContainer,
  ModalTopLevelContainer,
  ShoptypeLogo,
  SignInForm,
  SignUpForm,
  GoogleButton,
  FacebookButton,
  Vendor,
  Coseller,
  AwakeMeLogin,
  Name,
  UserButtonContainer,
  Row,
  SubHeading,
  Hyperlink,
  Footer,
  Message
} from './styles';
import PhoneNumberInput from '../../atoms/phoneNumberInput';

import {
  PASSWORD_PATTERN,
  isEmailValid,
  isPasswordValid,
  isPhoneValid
} from '../../../utils';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      phone: '',
      email: '',
      password: '',
      signInStatus: true,
      response: '',
      shareModal: false,
      modalContent: null,
      productShareLink: '',
      fetchedProductDetails: {},
      userProfileDetails: [],
      isLoading: false,
      signUpStep: 1,
      buttonDisabled: false,
      otp: '',
      cosellerToken: '',
      timerDuration: 30,
      timerRunning: false,
      startTimer: false,
      isResendOtpStep: false,
      isVerifyAgain: false
    };
  }

  componentDidMount() {
    this.awakeMeValidationAndRedirect();
  }

  awakeMeValidationAndRedirect = () => {
    let receivedToken = this.getAwakeMeRedirectToken();

    if (receivedToken) {
      this.setState(
        {
          isLoading: true
        },
        async () => {
          const profileRes = await this.updateLocalStorage('', receivedToken);
          if (profileRes.vendors && profileRes.vendors.length) {
            history.push('/dashboard');
          } else {
            history.push('/onboard');
          }
        }
      );
    }
  };

  inputChangeHandler = e => {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  signInSubmitHandler = () => {
    let { signInStatus } = this.state;
    if (signInStatus) {
      let inputValidationError = this.inputsAreInvalid(!signInStatus);

      return !inputValidationError
        ? this.signIn()
        : toaster(inputValidationError);
    } else {
      this.setState({
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: '',
        signInStatus: true,
        signUpStep: 1
      });
    }
  };

  signUpSubmitHandler = () => {
    let { signInStatus, signUpStep } = this.state;
    if (!signInStatus) {
      let inputValidationError = this.inputsAreInvalid(!signInStatus);

      return !inputValidationError
        ? signUpStep === 1
          ? this.signUp()
          : this.verifyOtp(this.redirectToOnboard)
        : toaster(inputValidationError);
    } else {
      this.setState({
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: '',
        signInStatus: false,
        signUpStep: 1
      });
    }
  };

  cosellerSubmitHandler = () => {
    let { signInStatus, signUpStep } = this.state;

    if (!signInStatus) {
      let inputValidationError = this.inputsAreInvalid(!signInStatus);

      return !inputValidationError
        ? signUpStep === 1
          ? this.cosellerSignUp()
          : this.verifyOtp()
        : toaster(inputValidationError);
    } else {
      this.setState({
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: '',
        signInStatus: false,
        signUpStep: 1
      });
    }
  };

  keyPressHandler = e => {
    let { signInStatus, signUpStep } = this.state;
    let error = this.inputsAreInvalid(!signInStatus);
    if (!error && e.key === 'Enter') {
      if (signUpStep === 4) {
        this.handleSendVerificationOtp();
        return;
      }
      if (signInStatus) {
        this.signIn();
      } else {
        this.signUp();
      }
    } else if (e.key === 'Enter') {
      toaster(error);
    }
  };

  userOnboard = async token => {
    const headers = { authorization: token };
    const requestBody = {
      userType: 'coseller'
    };
    const response = await postWithResponseObject(
      `${API_BASE_URL}/authenticate`,
      requestBody,
      headers
    );

    if (response.data.is_onboarded === false) {
      const reqBody = {};
      const response = await postWithResponseObject(
        `${API_BASE_URL}/cosellers`,
        reqBody,
        headers
      );
      if (successStatusCodes.includes(response.status)) {
        this.setState({ cosellerToken: response.data.token });
        this.cosellerPublishModal(response.data.token);
        localStorage.setItem(
          'cosellerToken',
          JSON.stringify(response.data.token || null)
        );
      }
    } else {
      this.cosellerPublishModal(response.data.token);
    }
  };

  signIn = async (token, provider) => {
    const { isCoseller } = this.props;
    const { email, password } = this.state;
    const inviteId = sessionStorage.getItem('inviteId');

    let response = '';
    if (token) {
      let url = `${API_BASE_URL}/social-login`;
      const requestBody = {
        token: token,
        provider: provider,
        domain: DOMAIN,
        ...(isCoseller ? { userType: USER_TYPE } : {})
      };

      if (inviteId) {
        url = `${API_BASE_URL}/social-login?rid=${inviteId}`;
      }

      if (this.props.trackerId && this.props.trackerId.length > 0) {
        url = `${API_BASE_URL}/social-login?tid=${this.props.trackerId}`;
      }

      this.setState({ buttonDisabled: true });
      response = await postWithResponseObject(url, requestBody);
      this.setState({ buttonDisabled: false });
    } else {
      const requestBody = {
        email: email,
        password: password,
        ...(isCoseller ? { userType: USER_TYPE } : {})
      };

      this.props.showSpinnerBackdrop();
      this.setState({ buttonDisabled: true });
      response = await postWithResponseObject(
        `${API_BASE_URL}/login`,
        requestBody
      );
    }

    if (successStatusCodes.includes(response.status)) {
      sessionStorage.removeItem('inviteId');

      this.userOnboard(response.data.data.token);
      if (response.data && response.data.status === 'success') {
        if (!isCoseller) {
          const profileRes = await this.updateLocalStorage(
            response.data.data._id,
            response.data.data.token
          );
          this.setState({ buttonDisabled: false });
          this.props.hideSpinnerBackdrop();
          if (profileRes.vendors && profileRes.vendors.length > 0) {
            history.push('/dashboard');
          } else {
            history.push('/onboard');
          }
        }
      } else if (response.data && response.data.status === 'fail') {
        this.setState({ buttonDisabled: false });
        this.props.hideSpinnerBackdrop();
        showApiErrorToast(response.data);
      }
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
    }
  };

  signUp = async () => {
    let url = `${API_BASE_URL}/register?verificationMethod=OTP`;
    const inviteId = sessionStorage.getItem('inviteId');
    const requestBody = {
      name: this.state.fname.trim() + ' ' + this.state.lname.trim(),
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password
    };

    if (inviteId) {
      url = `${API_BASE_URL}/register?verificationMethod=OTP&rid=${inviteId}`;
    }

    if (this.props.trackerId && this.props.trackerId.length > 0) {
      url = `${API_BASE_URL}/register?verificationMethod=OTP&tid=${this.props.trackerId}`;
    }

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(url, requestBody);
    this.setState({ buttonDisabled: false });
    this.props.hideSpinnerBackdrop();

    if (successStatusCodes.includes(response.status)) {
      sessionStorage.removeItem('inviteId');
      this.setState({ buttonDisabled: false });
      sessionStorage.setItem('id', JSON.stringify(response.data.id || null));
      this.setState({ signUpStep: 2 });
    } else {
      this.setState({ buttonDisabled: false });
      showApiErrorToast(response.data);
    }
  };

  cosellerSignUp = async () => {
    let url = `${API_BASE_URL}/register?verificationMethod=OTP`;
    const inviteId = sessionStorage.getItem('inviteId');
    const requestBody = {
      name: this.state.fname.trim() + ' ' + this.state.lname.trim(),
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      userType: USER_TYPE
    };

    if (inviteId) {
      url = `${API_BASE_URL}/register?verificationMethod=OTP&rid=${inviteId}`;
    }

    if (this.props.trackerId && this.props.trackerId.length > 0) {
      url = `${API_BASE_URL}/register?verificationMethod=OTP&tid=${this.props.trackerId}`;
    }

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(url, requestBody);
    this.setState({ buttonDisabled: false });
    this.props.hideSpinnerBackdrop();

    if (successStatusCodes.includes(response.status)) {
      sessionStorage.removeItem('inviteId');
      sessionStorage.setItem(
        'id',
        JSON.stringify(
          (response.data && response.data.data && response.data.data.id) || null
        )
      );
      if (this.props.isQuickSignUp) {
        sessionStorage.setItem('isQuickSignUp', true);
      }
      this.setState({ signUpStep: 2 });
    } else {
      showApiErrorToast(response.data);
    }
  };

  redirectToOnboard = () => {
    history.push('/onboard');
  };

  cosellerQuickSignup = async token => {
    sessionStorage.removeItem('inviteId');

    const headers = { authorization: token };
    const reqBody = {};
    const response = await postWithResponseObject(
      `${API_BASE_URL}/cosellers`,
      reqBody,
      headers
    );
    if (successStatusCodes.includes(response.status)) {
      this.setState({ cosellerToken: response.data.token });
      this.cosellerPublishModal(response.data.token);
      localStorage.setItem(
        'cosellerToken',
        JSON.stringify(response.data.token || null)
      );
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
    }
  };

  verifyOtp = async cbFun => {
    const id = JSON.parse(sessionStorage.getItem('id'));
    const requestBody = {
      id,
      verificationCode: this.state.otp
    };

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/validate-email-otp`,
      requestBody
    );

    if (successStatusCodes.includes(response.status)) {
      this.cosellerQuickSignup(response.data.token);
      if (this.props.isQuickSignUp) {
        sessionStorage.removeItem('isQuickSignUp');
      }
    } else {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
    }
  };

  closeModal = () => {
    this.setState({
      shareModal: false,
      modalContent: null
    });
    this.props.closePreviousModal();
  };

  fetchProductData = async () => {
    const productId = window.location.pathname.split(/[/?]/)[3];
    const authId = this.state.cosellerId;
    const token = this.state.cosellerToken;
    const headers = { authorization: token };
    const response = await getApiResponseObject(
      `${API_BASE_URL}/user/${authId}/products/${productId}`,
      headers
    );

    if (response.status !== 200) {
      showApiErrorToast(response.data);
    }

    this.setState(
      {
        fetchedProductDetails: response.data || {},
        isLoading: false
      },
      () => {}
    );
  };

  updateLocalStorage = async (id, token) => {
    const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
      authorization: token
    });

    if (successStatusCodes.includes(profileRes.status)) {
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('_id', JSON.stringify(id));
      localStorage.setItem(
        'userProfile',
        JSON.stringify(profileRes.data || {})
      );
    } else {
      localStorage.setItem('userProfile', JSON.stringify({}));
    }
    return profileRes.data || {};
  };

  cosellerPublishModal = async token => {
    const {
      vendorId,
      productId,
      title,
      description,
      imageSrc,
      tags
    } = this.props;

    const authId = getAuthId();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/track/publish-slug?productId=${productId}&shareLink=${encodeURIComponent(
        window.location.href
      )}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      this.setState({ buttonDisabled: false });
      this.props.hideSpinnerBackdrop();
      showApiErrorToast(response.data);
      return;
    }

    sessionStorage.removeItem('id');

    const productShareLink = `${APP_BASE_URL}${response.data.slug}`;

    const modalContent = (
      <Modal close={this.closeModal}>
        <ModalTopLevelContainer>
          <ModalContainer>
            <PublishModal
              authId={authId}
              productId={productId}
              vendorId={vendorId}
              title={title}
              description={description}
              imageSrc={imageSrc || DefaultProductImage}
              tags={tags}
              productShareLink={productShareLink}
              embedOption={true}
              token={token}
            />
          </ModalContainer>
        </ModalTopLevelContainer>
      </Modal>
    );

    this.setState(
      {
        shareModal: true,
        modalContent: modalContent
      },
      () => {
        this.setState({ buttonDisabled: false });
        this.props.hideSpinnerBackdrop();
      }
    );
  };

  inputsAreInvalid = signUpStatus => {
    const { signUpStep, otp, isResendOtpStep } = this.state;

    if (signUpStep === 4) {
      if (!isEmailValid(this.state.email))
        return 'Please enter a valid email id.';
      return null;
    }
    if (signUpStep === 2) {
      if (otp.length <= 0) return 'Please enter the OTP.';
      if (isResendOtpStep) return null;
    }

    if (signUpStatus && !isUserNameValid(this.state.fname)) {
      return 'Please enter a valid first name.';
    }

    if (signUpStatus && !isUserNameValid(this.state.lname)) {
      return 'Please enter a valid last name.';
    }

    if (signUpStatus && !isPhoneValid(this.state.phone)) {
      return 'Please enter a valid phone number.';
    }

    if (!isEmailValid(this.state.email)) {
      return 'Please enter a valid email id.';
    }

    if (signUpStatus && !isPasswordValid(this.state.password)) {
      return 'Password should contain at least 6 characters.';
    } else if (!this.state.password) {
      return 'Please enter password.';
    }

    return null;
  };

  responseGoogle = response => {
    let gmailToken = response.tokenId;
    if (response && response.tokenId) {
      if (gmailToken) {
        this.signIn(gmailToken, GOOGLEPROVIDER);
      } else if (response.error) {
        return toaster('Error Connecting to Gmail');
      }
    } else if (response && response.error === 'popup_closed_by_user') {
      return toaster('Error Connecting to Gmail');
    }
  };

  responseFacebook = response => {
    let facebookToken = response.accessToken;
    if (facebookToken) {
      this.signIn(facebookToken, FACEBOOKPROVIDER);
    } else {
      return toaster('Error Connecting to Facebook');
    }
  };

  clickAwakeMe = () => {
    window.location.href =
      'http://beta-awake.me.s3-website-us-east-1.amazonaws.com/?SSO=true&APP_ID=123456789';
  };

  getAwakeMeRedirectToken = () => {
    let redirectUrlParams = {};

    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
      m,
      key,
      value
    ) {
      redirectUrlParams[key] = value;
    });
    return redirectUrlParams.token;
  };

  handleResendOtpBtn = () => {
    this.setState({ timerRunning: true, startTimer: true }, () => {
      this.resendVerificationOtp();
    });
  };

  resendVerificationOtp = async (callbackFun = null) => {
    const reqBody = { email: this.state.email };

    this.props.showSpinnerBackdrop();
    this.setState({ buttonDisabled: true });

    const res = await postWithResponseObject(
      `${API_BASE_URL}/resend-verification-email?verificationMethod=OTP`,
      reqBody
    );

    this.setState({ buttonDisabled: false });
    this.props.hideSpinnerBackdrop();

    if (successStatusCodes.includes(res.status)) {
      toaster(
        res?.data?.message || 'Verification email has been sent to your mail id'
      );

      if (this.state.isVerifyAgain) {
        const data = res.data;
        sessionStorage.setItem('id', JSON.stringify(data?.userId || null));
      }

      if (callbackFun) {
        callbackFun();
      }
    } else {
      showApiErrorToast(res.data);
    }
  };

  handleTimerComplete = () => {
    this.setState({ timerRunning: false, startTimer: false });
  };

  changeSignUpStep = step => {
    this.setState({ signUpStep: step, isVerifyAgain: step === 4 });
  };

  goToOtpScreen = () => {
    this.setState({
      signUpStep: 2,
      signInStatus: false,
      isResendOtpStep: true
    });
  };

  handleSendVerificationOtp = () => {
    let error = this.inputsAreInvalid(!this.state.signInStatus);

    if (!error) {
      this.setState({
        timerRunning: true,
        startTimer: true
      });

      this.resendVerificationOtp(this.goToOtpScreen);
    } else {
      toaster(error);
    }
  };

  render() {
    const {
      isLoading,
      phone,
      email,
      password,
      shareModal,
      modalContent,
      signUpStep,
      otp,
      buttonDisabled,
      fname,
      lname,
      timerDuration,
      timerRunning,
      startTimer
    } = this.state;
    const { isCoseller } = this.props;

    return (
      <Container>
        {!isLoading ? (
          <InnerContainer isCoseller={isCoseller}>
            <VendorOrCosellerLogin isCoseller={isCoseller}>
              {isCoseller ? (
                ''
              ) : (
                <>
                  <ShoptypeLogo>
                    <Logo color="black" name="shoptype-logo" />
                  </ShoptypeLogo>
                  <Vendor text="Vendor" size="medium" />
                  <Coseller text="Coseller" size="medium" />{' '}
                </>
              )}
            </VendorOrCosellerLogin>
            <SubHeading>
              Become a Coseller and make money when someone buys this product.
            </SubHeading>
            <AuthContainer isCoseller={isCoseller}>
              <GoogleLogin
                clientId={GOOGLECLIENTID}
                render={renderProps => (
                  <GoogleButton
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <img
                      width="56px"
                      src={require('../../../assets/images/google.png')}
                      alt="google"
                    />
                  </GoogleButton>
                )}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
              <FacebookLogin
                appId={FACEBOOKAPPID}
                callback={this.responseFacebook}
                render={renderProps => (
                  <FacebookButton onClick={renderProps.onClick}>
                    <img
                      width="56px"
                      src={require('../../../assets/images/facebook.png')}
                      alt="google"
                    />
                  </FacebookButton>
                )}
              />
              <AwakeMeLogin>
                <FilledButton
                  text="Awake.me"
                  backgroundColor="white"
                  textColor="black"
                  borderColor="black"
                  onClick={this.clickAwakeMe}
                />
              </AwakeMeLogin>
            </AuthContainer>
            <HorizontalSeparator label="OR" />
            {signUpStep === 4 ? (
              <>
                <p style={{ maxWidth: 400 }}>
                  Please enter your email address. An email with an OTP will be
                  sent to your email address.
                </p>
                <SignInForm display="block">
                  <TextInput
                    name="email"
                    type="email"
                    placeHolder="Email"
                    required={true}
                    id="email"
                    value={email}
                    onChange={this.inputChangeHandler}
                    pattern={PASSWORD_PATTERN}
                    autocomplete="off"
                    onKeyPress={event => {
                      this.keyPressHandler(event);
                    }}
                  />
                </SignInForm>
                <UserButtonContainer>
                  <FilledButton
                    type="button"
                    text="Send"
                    borderColor="black"
                    backgroundColor="white"
                    textColor="black"
                    onClick={this.handleSendVerificationOtp}
                    disabled={buttonDisabled}
                  />
                </UserButtonContainer>
              </>
            ) : (
              <>
                <SignInForm
                  display={this.state.signInStatus}
                  onSubmit={this.submitHandler}
                >
                  <TextInput
                    name="email"
                    type="email"
                    placeHolder="Email"
                    required={true}
                    id="email"
                    value={email}
                    onChange={this.inputChangeHandler}
                    pattern={PASSWORD_PATTERN}
                    autocomplete="off"
                    onKeyPress={event => {
                      this.keyPressHandler(event);
                    }}
                  />
                  <TextInput
                    id="password"
                    name="password"
                    type="password"
                    placeHolder="Password"
                    required={true}
                    value={password}
                    onChange={this.inputChangeHandler}
                    autocomplete="off"
                    onKeyPress={event => {
                      this.keyPressHandler(event);
                    }}
                  />
                </SignInForm>
                {!this.state.signInStatus && (
                  <>
                    {signUpStep === 1 ? (
                      <SignUpForm display={!this.state.signInStatus}>
                        <Row>
                          <Name
                            width={148}
                            marginRight={4}
                            name="fname"
                            type="text"
                            placeHolder="First Name"
                            id="fname"
                            required={true}
                            value={fname}
                            onChange={this.inputChangeHandler}
                            autocomplete="off"
                            onKeyPress={event => {
                              this.keyPressHandler(event);
                            }}
                          />
                          <Name
                            width={148}
                            name="lname"
                            type="text"
                            placeHolder="Last Name"
                            id="lname"
                            required={true}
                            value={lname}
                            onChange={this.inputChangeHandler}
                            autocomplete="off"
                            onKeyPress={event => {
                              this.keyPressHandler(event);
                            }}
                          />
                        </Row>
                        <PhoneNumberInput
                          border={false}
                          callback={phone => {
                            this.setState({ phone: phone });
                          }}
                        />
                        <TextInput
                          name="email"
                          type="email"
                          placeHolder="Email"
                          required={true}
                          id="email"
                          value={email}
                          onChange={this.inputChangeHandler}
                          autocomplete="off"
                          onKeyPress={event => {
                            this.keyPressHandler(event);
                          }}
                        />

                        <TextInput
                          id="password"
                          name="password"
                          type="password"
                          placeHolder="Password"
                          required={true}
                          value={password}
                          onChange={this.inputChangeHandler}
                          pattern={PASSWORD_PATTERN}
                          autocomplete="off"
                          onKeyPress={event => {
                            this.keyPressHandler(event);
                          }}
                        />
                      </SignUpForm>
                    ) : (
                      <SignUpForm display={!this.state.signInStatus}>
                        <TextInput
                          id="otp"
                          name="otp"
                          type="password"
                          placeHolder="OTP"
                          required={true}
                          value={otp}
                          onChange={this.inputChangeHandler}
                          autocomplete="off"
                          onKeyPress={event => {
                            this.keyPressHandler(event);
                          }}
                        />
                      </SignUpForm>
                    )}
                  </>
                )}
                {signUpStep === 2 && (
                  <Message>
                    <i className="fas fa-info-circle info" />
                    &nbsp;One Time Password (OTP) has been sent to your
                    registered email id&nbsp;<b>{email}</b>
                  </Message>
                )}
                <UserButtonContainer>
                  <FilledButton
                    type={'submit'}
                    text={signUpStep === 1 ? 'Sign Up' : 'Submit OTP'}
                    borderColor="black"
                    backgroundColor={
                      this.state.signInStatus ? 'white' : 'black'
                    }
                    textColor={this.state.signInStatus ? 'black' : 'white'}
                    onClick={
                      this.props.isCoseller
                        ? this.cosellerSubmitHandler
                        : this.signUpSubmitHandler
                    }
                    disabled={buttonDisabled}
                  />
                  {signUpStep === 2 && (
                    <FilledButton
                      type="button"
                      text={
                        timerRunning ? (
                          <>
                            Wait&nbsp;
                            <CountdownTimer
                              timerDuration={timerDuration}
                              start={startTimer}
                              onComplete={this.handleTimerComplete}
                            />
                          </>
                        ) : (
                          'Resend OTP'
                        )
                      }
                      borderColor="black"
                      backgroundColor={
                        this.state.signInStatus ? 'white' : 'black'
                      }
                      textColor={this.state.signInStatus ? 'black' : 'white'}
                      onClick={this.handleResendOtpBtn}
                      disabled={buttonDisabled || timerRunning}
                    />
                  )}
                  {shareModal ? <>{modalContent}</> : null}
                  <span
                    style={{ display: signUpStep === 1 ? 'initial' : 'none' }}
                  >
                    <FilledButton
                      type={'submit'}
                      text="Sign In"
                      borderColor="black"
                      backgroundColor={
                        this.state.signInStatus ? 'black' : 'white'
                      }
                      textColor={this.state.signInStatus ? 'white' : 'black'}
                      onClick={this.signInSubmitHandler}
                      disabled={buttonDisabled}
                    />
                  </span>
                </UserButtonContainer>
              </>
            )}
            {this.state.signInStatus && signUpStep === 4 && (
              <Hyperlink onClick={() => this.changeSignUpStep(1)}>
                Go back
              </Hyperlink>
            )}
            {this.state.signInStatus && signUpStep !== 4 && (
              <Hyperlink onClick={() => this.changeSignUpStep(4)}>
                Verify again
              </Hyperlink>
            )}
            <Footer>
              <PrivacyInformation isSignUp={!this.state.signInStatus} />
            </Footer>
          </InnerContainer>
        ) : (
          <Loader
            message="Loading..."
            isFlex={true}
            w={'100%'}
            isCenter={true}
            min_h={'100vh'}
          />
        )}
      </Container>
    );
  }
}

SignIn.propTypes = {
  isCoseller: PropTypes.bool,
  closePreviousModal: PropTypes.func,
  vendorId: PropTypes.string,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func,
  trackerId: PropTypes.string
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(SignIn));
