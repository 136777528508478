import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import {
  Wrapper,
  IconContainer,
  Icon,
  MessageContainer,
  Message
} from './styles';

const PageMessage = ({ type, message }) => {
  // Supported values for "type" prop:
  // success, warning, danger, info
  const [iconClass, setIconClass] = useState('fas fa-info-circle');

  useEffect(() => {
    setIcon();
  }, []);

  const setIcon = () => {
    switch (type) {
      case 'success':
        setIconClass('fas fa-tick-circle success');
        break;
      case 'warning':
        setIconClass('fas fa-exclamation-circle warning');
        break;
      case 'danger':
        setIconClass('fas fa-times-circle danger');
        break;
      case 'info':
        setIconClass('fas fa-info-circle info');
        break;
    }
  };

  return (
    <Wrapper>
      <IconContainer>
        <Icon className={iconClass} />
      </IconContainer>
      <MessageContainer>
        <Message>{message}</Message>
      </MessageContainer>
    </Wrapper>
  );
};

PageMessage.propTypes = {
  type: string,
  message: string
};

export default PageMessage;
