import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  postWithResponseObject,
  PASSWORD_PATTERN,
  isPasswordValid,
  successStatusCodes,
  getAuthId,
  getAuthToken,
  getUserType,
  showApiErrorToast
} from '../../../utils';
import { toaster } from '../../atoms/toaster';
import { API_BASE_URL } from '../../../config/env';
import {
  Wrapper,
  Container,
  Content,
  HeadingSection,
  Heading,
  Row,
  Password,
  SaveButton,
  Save
} from './styles';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      userType: ''
    };
  }

  componentDidMount() {
    this.setUserType();
  }

  setUserType = () => {
    const userType = getUserType();
    this.setState({ userType });
  };

  handlePasswordChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.submit();
    }
  };

  submit = async () => {
    const id = getAuthId();
    const token = getAuthToken();
    const { oldPassword, newPassword } = this.state;

    if (this.validPassword() && id && token) {
      const reqBody = {
        id,
        password: oldPassword,
        newPassword: newPassword
      };
      const headers = {
        'Content-type': 'application/json',
        Authorization: token
      };
      let res = await postWithResponseObject(
        `${API_BASE_URL}/change-password`,
        reqBody,
        headers
      );

      if (
        successStatusCodes.includes(res.status) &&
        res.data &&
        res.data.status &&
        res.data.status === 'success'
      ) {
        toaster('Password changed successfully');
        this.resetState();
      } else {
        showApiErrorToast(res.data);
      }
    }
  };

  validPassword = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    if (!oldPassword || !newPassword || !confirmPassword) {
      toaster('Please fill all the fields.');
      return false;
    } else if (newPassword !== confirmPassword) {
      toaster('New password and confirm password does not match.');
      return false;
    } else if (!isPasswordValid(newPassword)) {
      toaster('Password should contain at least 6 characters.');
      return false;
    } else {
      return true;
    }
  };

  resetState = () => {
    this.setState({ oldPassword: '', newPassword: '', confirmPassword: '' });
  };

  render() {
    const { oldPassword, newPassword, confirmPassword, userType } = this.state;
    return (
      <Wrapper>
        <Container>
          <Content>
            <HeadingSection>
              <Heading>Change Password</Heading>
            </HeadingSection>
            <Row>
              <Password
                id="oldPassword"
                name="oldPassword"
                type="password"
                placeHolder="Old Password"
                value={oldPassword}
                onChange={this.handlePasswordChange}
                pattern={PASSWORD_PATTERN}
                autocomplete="off"
                onKeyPress={event => {
                  this.handleKeyPress(event);
                }}
              />
              <Password
                id="newPassword"
                name="newPassword"
                type="password"
                placeHolder="New Password"
                value={newPassword}
                onChange={this.handlePasswordChange}
                pattern={PASSWORD_PATTERN}
                autocomplete="off"
                onKeyPress={event => {
                  this.handleKeyPress(event);
                }}
              />
              <Password
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeHolder="Confirm Password"
                value={confirmPassword}
                onChange={this.handlePasswordChange}
                pattern={PASSWORD_PATTERN}
                autocomplete="off"
                onKeyPress={event => {
                  this.handleKeyPress(event);
                }}
              />
              <SaveButton>
                <Save onClick={this.submit} text="Save" />
              </SaveButton>
            </Row>
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

export default withRouter(ChangePassword);
