import React from 'react';
import Loader from '../../../atoms/loader';
import MaterialButton from '../../../atoms/materialButton';
import ConfigurePayoutsDialog from '../../Payouts/dialogs/configurePayoutsDialog';
import AccountCard from './accountCard';
import { isEmpty } from 'lodash';
import { API_BASE_URL } from '../../../../config/env';
import { withSnackbar } from '../../../hoc/withSnackbar';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Tooltip, Zoom } from '@material-ui/core';
import {
  getAuthToken,
  getApiResponseObject,
  successStatusCodes
} from '../../../../utils';
import { NETWORK_OPERATOR_INFO } from '../../../../constants/tooltip';
import {
  AccountCardWrapper,
  Container,
  Heading,
  HeadingSection,
  Message,
  PaymentsContainer,
  Section
} from '../Payments/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../../../redux/actions/uiActions';
import { withRouter } from 'react-router-dom';

class Payouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchedDetails: [],
      payoutMethodConfigured: false,
      openDialog: false
    };
  }

  componentDidMount() {
    this.fetchPayoutConfig();
  }

  fetchPayoutConfig = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    this.props.showSpinnerBackdrop();
    this.setState({ isLoading: true });

    const response = await getApiResponseObject(
      `${API_BASE_URL}/payout-config`,
      headers
    );

    if (
      successStatusCodes.includes(response.status) &&
      response?.data?.data &&
      response.data.data.length > 0
    ) {
      const fetchedDetails = response.data.data;
      this.setState({
        fetchedDetails,
        payoutMethodConfigured: true,
        isLoading: false
      });
    } else {
      this.setState({
        payoutMethodConfigured: false,
        isLoading: false
      });
    }
    this.props.hideSpinnerBackdrop();
  };

  handleDialog = status => {
    this.setState({ openDialog: status === 'open' ? true : false });
  };

  handleOpenSnackbar = (severity, message) => {
    this.setState({ openSnackbar: true, severity, snackbarMessage: message });
  };

  handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openSnackbar: false, snackbarMessage: '' });
  };

  render() {
    const {
      isLoading,
      fetchedDetails,
      payoutMethodConfigured,
      disableButton,
      openDialog
    } = this.state;
    const { showSnackbar } = this.props;
    return (
      <>
        {!isLoading && (
          <ConfigurePayoutsDialog
            open={openDialog}
            handleDialog={this.handleDialog}
            showSnackbar={showSnackbar}
            fetchPayoutConfig={this.fetchPayoutConfig}
          />
        )}
        <Container>
          <HeadingSection>
            <Heading>Payouts</Heading>
            <Tooltip
              TransitionComponent={Zoom}
              title={NETWORK_OPERATOR_INFO.pageDescription.Payouts}
              arrow
            >
              <InfoRoundedIcon />
            </Tooltip>
          </HeadingSection>
          <PaymentsContainer>
            {!isLoading &&
            payoutMethodConfigured &&
            !isEmpty(fetchedDetails) ? (
              <>
                <Message>
                  <i className="fas fa-check-circle success" />
                  &nbsp;Payouts configured
                </Message>
                <Section>
                  <MaterialButton
                    label="Configure Payouts"
                    disabled={disableButton}
                    onClick={() => {
                      this.handleDialog('open');
                    }}
                  />
                </Section>
                <AccountCardWrapper>
                  {fetchedDetails &&
                    fetchedDetails.map((account, index) => (
                      <AccountCard
                        key={index}
                        method={account?.payout_method}
                        status={account?.status}
                        keyLastFour={account?.last_4}
                        currency={account?.asset_type_unit}
                      />
                    ))}
                </AccountCardWrapper>
              </>
            ) : (
              !isLoading &&
              !payoutMethodConfigured && (
                <>
                  <Message>
                    <i className="fas fa-info-circle info" />
                    &nbsp;Payouts are not configured yet
                  </Message>
                  <Section>
                    <MaterialButton
                      label="Configure Payouts"
                      disabled={disableButton}
                      onClick={() => {
                        this.handleDialog('open');
                      }}
                    />
                  </Section>
                </>
              )
            )}
          </PaymentsContainer>
        </Container>
      </>
    );
  }
}

Payouts.propTypes = {
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};

export default withSnackbar(
  withRouter(connect(null, mapDispatchToProps)(Payouts))
);
