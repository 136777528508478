import React, { useEffect } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { useRef, useState } from 'react';

const useStyles = makeStyles(theme => ({
  inputField: {
    padding: '5px'
  },
  cancelIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '4px'
  },
  textField: {
    margin: '1rem 0',
    display: 'flex',
    width: '100%'
  }
}));

const Tags = ({ data, handleDelete }) => {
  const classes = useStyles();
  return (
    <Box
      style={{
        background: '#283240',
        borderRadius: '0.5rem',
        height: '100%',
        padding: '0.3rem',
        margin: '0.2rem',
        color: '#ffffff'
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="caption">{data}</Typography>
        <CancelIcon
          className={classes.cancelIcon}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Box>
    </Box>
  );
};

const InputTags = props => {
  const classes = useStyles();
  const { interests, handleSelectedTag } = props;
  const [tags, setTags] = useState([]);
  const tagRef = useRef();

  useEffect(() => {
    setTags(interests);
  }, []);

  const handleDelete = value => {
    const newtags = tags.filter(val => val !== value);
    setTags(newtags);
    handleSelectedTag(newtags);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const newtags = [...tags, tagRef.current.value];
    setTags(newtags);
    handleSelectedTag(tags);
    tagRef.current.value = '';
  };

  useEffect(() => {
    handleSelectedTag(tags);
  }, [tags]);

  return (
    <Box style={{ flexGrow: '1' }}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          className={classes.textField}
          inputRef={tagRef}
          variant="outlined"
          label="Interests"
          fullWidth
          margin="none"
          placeholder={tags.length == 0 ? 'Enter tags' : ''}
          InputProps={{
            startAdornment: (
              <Box
                style={{
                  margin: '0 0.2rem 0 0',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {tags.map((data, index) => {
                  return (
                    <Tags data={data} handleDelete={handleDelete} key={index} />
                  );
                })}
              </Box>
            ),
            className: classes.inputField
          }}
          helperText={'Hit Enter ⏎ to add tags'}
        />
      </form>
    </Box>
  );
};

export default InputTags;
