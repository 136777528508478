import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toaster } from '../../atoms/toaster';
import history from '../../../history';
import { isEmailValid, showApiErrorToast } from '../../../utils';
import { API_BASE_URL } from '../../../config/env';
import { Container, Email, SendButton, Send } from './styles';
import {
  postWithResponseObject,
  successStatusCodes,
  PASSWORD_PATTERN
} from '../../../utils';

class ForgotPasswordTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      disableButton: false
    };
  }

  emailChange = e => {
    this.setState({ email: e.target.value });
  };

  keyPressHandler = e => {
    if (e.key === 'Enter') {
      if (isEmailValid(this.state.email)) {
        this.submit();
      } else {
        return toaster('Please enter a valid email id');
      }
    }
  };

  submit = async () => {
    if (isEmailValid(this.state.email)) {
      let reqBody = { email: this.state.email.trim() };

      this.setState({ disableButton: true });
      let res = await postWithResponseObject(
        `${API_BASE_URL}/forgot-password`,
        reqBody
      );
      this.setState({ disableButton: false });

      if (
        successStatusCodes.includes(res.status) &&
        res.data &&
        res.data.status &&
        res.data.status === 'success'
      ) {
        history.push({
          pathname: '/users/message',
          search: '?type=forgot_password&email=' + this.state.email
        });
      } else {
        showApiErrorToast(res.data);
      }
    } else {
      return toaster('Please enter a valid email id');
    }
  };

  render() {
    const { email, disableButton } = this.state;
    return (
      <Container>
        <Email
          name="email"
          type="email"
          placeHolder="Email"
          id="email"
          value={email}
          onChange={this.emailChange}
          pattern={PASSWORD_PATTERN}
          autocomplete="off"
          onKeyPress={event => {
            this.keyPressHandler(event);
          }}
        />
        <SendButton>
          <Send onClick={this.submit} text="Send" disabled={disableButton} />
        </SendButton>
      </Container>
    );
  }
}

ForgotPasswordTab.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(ForgotPasswordTab);
