import React from 'react';
import { NavbarContainer, Logo } from './styles';
import LogoImage from '../../../assets/images/logo.png';
import history from '../../../history';
import { getAuthToken, getUserType } from '../../../utils';
import ProfileMenu from '../../templates/ProfileMenu';
import { EventsBaseClient } from '../../helpers/eventsBaseClient';
import { GLOBAL_EVENT_KEYS } from '../../../constants';
import BackToDashboard from './BackToDashboard';

const NavBar = props => {
  const { navBarLogo, allowClick, showBackToDashboard } = props;

  const token = getAuthToken();
  const userType = getUserType();

  const goHome = () => {
    if (token) {
      // Check userType and redirect to respective dashboard
      getRoutes(userType);
    } else {
      history.push('/sign-in');
      EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
    }
  };

  const getRoutes = userType => {
    switch (userType) {
      case 'COSELLER':
        history.push('/cosellerdashboard');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;

      case 'VENDOR':
        history.push('/dashboard');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;

      case 'NETWORK_OPERATOR':
        history.push('/networkoperator/dashboard');
        EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
        break;

      default:
        history.push('/');
    }
  };

  return (
    <NavbarContainer navBarLogo={navBarLogo}>
      {navBarLogo && showBackToDashboard ? (
        <>
          <Logo src={LogoImage} onClick={goHome} allowClick={allowClick} />
          <BackToDashboard onClick={goHome} />
        </>
      ) : navBarLogo ? (
        <Logo src={LogoImage} onClick={goHome} allowClick={allowClick} />
      ) : (
        <ProfileMenu />
      )}
    </NavbarContainer>
  );
};

export default NavBar;
