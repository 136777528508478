import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: block;
`;

const ProductContainer = styled.div`
  width: 100%;
  padding: 0px 50px 50px 140px;
  background-color: #d8d8d8;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  font-family: Montserrat;
  text-align: left;
`;

const EditImageContainer = styled.div`
  width: 100%;
  padding: 40px;
  background-color: ${props => props.theme.WHITE};
  margin-top: 30px;
  max-height: 1000px;
`;

const EditImageInnerContainer = styled.div`
  width: 100%;
`;

const SubHeading = styled.div`
  font-size: ${props => props.theme.XX_LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  font-family: Montserrat;
  margin-bottom: 10px;
`;

const ProductMediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
  & > img {
  }
`;

const ProductImageWrapper = styled.div`
  width: 160px;
  height: 160px;
  margin-right: 20px;
  margin-bottom: 10px;
  position: relative;
  background-color: #f4f6f8;

  & > img {
    width: 160px;
    height: 160px;
    object-fit: contain;
    border: 1px solid rgb(186, 186, 186);
  }
`;

const MediaOperations = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VideosContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  & > div > video {
    width: 160px;
    height: 160px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

const UploadImageContainer = styled.div`
  width: ${props => {
    return props.size === 'large' ? '100%' : '240px';
  }};
  height: ${props => {
    return props.size === 'large' ? '240px' : '240px';
  }};
  border: 1px dotted black;
  text-align: center;
`;

const UploadImageLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => {
    return props.size === 'large' ? '100%' : '240px';
  }};
  height: ${props => {
    return props.size === 'large' ? '240px' : '240px';
  }};
  cursor: pointer;
`;

const UploadImage = styled.div`
  font-size: ${props => props.theme.LARGE};
`;

const UploadImageFile = styled.input`
  display: none;
`;

const ProductVideoContainer = styled.div`
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > div > video {
    outline: none;
  }
`;

const MainMediaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 470px;
  height: 500px;
  position: relative;
  border: 1px solid black;
`;

const ProductMainImageContainer = styled.div``;

const ProductMainVideoContainer = styled.div`
  & > div > video {
    outline: none;
  }
`;

const MainImage = styled.img`
  max-width: 470px;
  width: 100%;
  :hover {
    box-shadow: 0 0 30px 0 rgba(41, 49, 73, 0.1);
  }
`;

const SecondaryImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 20px;
`;

const SecondaryImageWrapper = styled.div`
  position: relative;
  border: 1px solid black;
`;

const SecondaryImage = styled.img`
  width: 160px;
  height: 160px;
  :hover {
    box-shadow: 0 0 30px 0 rgba(41, 49, 73, 0.1);
  }
`;

const VideoElement = styled.video`
  width: 160px;
  height: 160px;
`;

const MoreImages = styled.img`
  width: 160px;
  height: 160px;
  opacity: 0.5;
`;

const UploadVariantImageContainer = styled(UploadImageContainer)`
  width: 80px;
  height: 60px;
`;

const UploadVariantImageLabel = styled(UploadImageLabel)`
  padding: 0px;
  width: 80px;
  height: 60px;
`;

const EditPrimaryDetails = styled.div`
  width: 100%;
  padding: 40px;
  background-color: ${props => props.theme.WHITE};
  margin-top: 30px;
`;

const AddTitleContainer = styled.div`
  margin-top: 18px;
`;

const MiniHeading = styled.div`
  font-family: Montserrat;
  font-size: ${props => props.theme.SMALL};
`;

const CheckBoxContainer = styled.div`
  width: auto;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CheckBoxText = styled.div`
  font-family: Montserrat;
  font-size: ${props => props.theme.X_LARGE};
  padding-left: 30px;
`;

const AddDescriptionContainer = styled(AddTitleContainer)``;

const AddCategoryContainer = styled(AddTitleContainer)``;

const EditPricingContainer = styled(EditPrimaryDetails)``;

const EditInventoryContainer = styled(EditPrimaryDetails)``;

const EditVariantsContainer = styled(EditPrimaryDetails)``;

const VariantOptionsContainer = styled.div``;

const VariantOptionsHeading = styled.div`
  font-family: Montserrat;
  font-size: ${props => props.theme.X_LARGE};
  font-weight: bold;
`;

const VariantOptionsCheckBox = styled.div`
  width: auto;
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const AddedVariantOptionValue = styled.div`
  width: auto;
  padding: 10px;
  margin-right: 10px;
  border-radius: 7px;
  background-color: #dfdfdf;
`;

const VariantOption = styled.div`
  width: 100%;
  max-width: 340px;
`;

const VariantOptionBox = styled.div`
  width: 100%;
  display: flex;
`;

const VariantOptionElement = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const VariantOptionValue = styled.div`
  width: 100%;
  max-width: 530px;
`;

const VariantMedia = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const VariantImageWrapper = styled.div`
  width: 90px;
  height: 90px;
  padding: 5px 0px;
  position: relative;
  background-color: ${props => props.theme.WHITE};
`;

const VariantPrimaryImage = styled.img`
  width: 80px;
  height: 80px;
  display: inline-block;
  object-fit: contain;
`;

const VariantName = styled.div`
  width: 100%;
  font-family: Montserrat;
  font-size: ${props => props.theme.XX_LARGE};
  text-align: left;
`;

const PreviewContainer = styled.div`
  width: 100%;
  margin-top: 18px;

  & > table {
    width: 100%;
  }
  & > table > thead {
    width: 100%;
  }
  & > table > thead > tr {
    background-color: ${props => props.theme.WHITE};
  }
  & > table > tbody > tr {
    background-color: #f2f2f2;
  }
  & > table > thead > tr > th > div {
    text-align: center;
  }
  & > table > tbody > tr > td > div {
    text-align: center;
  }
  & > table > tbody > tr > td > img {
    text-align: center;
    margin-top: 10px;
  }
`;

const PreviewHeading = styled.div`
  font-family: Montserrat;
  font-size: ${props => props.theme.X_LARGE};
  font-weight: bold;
  margin: 20px 0px 10px 0px;
`;

const VariantTable = styled.div`
  width: 100%;
`;

const VariantTableHeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 40px;
`;

const VariantTableHeading = styled.div`
  width: 100%;
  font-size: ${props => props.theme.X_LARGE};
  font-family: Montserrat;
  text-align: center;
`;

const VariantDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 18px 40px;
  margin-top: 10px;
`;

const VariantPrice = styled(VariantName)`
  font-weight: 600;
`;

const VariantSKU = styled(VariantName)``;

const VariantQuantity = styled(VariantName)``;

const CardDetails = styled.div``;

const EditBestTestimonyContainer = styled(EditPrimaryDetails)``;
const EditAddIngredientsContainer = styled(EditPrimaryDetails)``;
const EditTechnicalSpecifications = styled(EditPrimaryDetails)``;
const HowItWorksContainer = styled(EditPrimaryDetails)``;

const EditHowItWorksContent = styled.div`
  display: flex;
  width: 100%;
`;

const HowItWorksTextContent = styled.div`
  width: 100%;
`;
const HowItWorksMediaContent = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
`;

const HowItWorksMediaContainer = styled.div`
  width: 240px;
  height: 240px;
  position: relative;
`;

const HowItWorksImage = styled.img`
  display: flex;
  justify-content: center;
  width: 240px;
  height: 240px;
  text-align: center;
`;

const HowItWorksVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 240px;
  height: 240px;
  text-align: center;
  & > div > video {
    outline: none;
  }
`;

const EditAddTagsContainer = styled(EditPrimaryDetails)``;

const TagsHeadingInfoContainer = styled.div`
  display: flex;
`;

const AddTagsInfo = styled.div`
  font-family: Montserrat;
  font-size: ${props => props.theme.LARGE};
  padding: 2px 18px;
`;

const EditMoreInformationContainer = styled(EditPrimaryDetails)``;

const EditSocialMediaPostContainer = styled(EditPrimaryDetails)``;

const AddSocialMediaInfo = styled(AddTagsInfo)`
  padding: 5px;
`;

const TrendingSocialMediaPostContainer = styled.div`
  margin-top: 50px;
`;

const TrendingSocialMediaPostHeading = styled(PreviewHeading)``;

const SocialMediaPostContainer = styled.div`
  display: flex;
`;

const SocialMediaPost = styled.img`
  width: 100%;
  max-width: 410px;
`;

const EditFAQContainer = styled(EditPrimaryDetails)`
  > button {
    border: 1px solid black;
    width: 260px;
    height: 50px;
    margin-top: 40px;
  }
`;

const FAQElement = styled.div`
  margin-top: 30px;
`;

const FAQQuestion = styled.div``;

const FAQAnswer = styled.div`
  margin-top: 30px;
`;

const SavedFAQElement = styled.div`
  position: relative;
  margin-top: 30px;
`;

const SavedFAQQuestion = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
`;

const SavedFAQAnswer = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 0px 10px 0px;
`;

const EditShippingContainer = styled(EditPrimaryDetails)``;

const ShippingInfoElement = styled.div`
  margin-top: 30px;
`;

const ShippingInfoHeading = styled(PreviewHeading)``;

const ShippingInfoText = styled.div`
  font-family: Montserrat;
  font-size: ${props => props.theme.X_LARGE};
`;

const SaveDetails = styled.div`
  width: 100%;
  margin-top: 50px;
  text-align: center;
`;

export {
  Container,
  InnerContainer,
  ProductContainer,
  Heading,
  SubHeading,
  EditImageContainer,
  EditImageInnerContainer,
  ProductMediaContainer,
  ProductVideoContainer,
  MainMediaContainer,
  ProductMainImageContainer,
  ProductMainVideoContainer,
  SecondaryImageContainer,
  MainImage,
  ImagesContainer,
  ProductImageWrapper,
  MediaOperations,
  VideosContainer,
  UploadImageContainer,
  UploadImageLabel,
  UploadImage,
  UploadImageFile,
  UploadVariantImageContainer,
  UploadVariantImageLabel,
  SecondaryImageWrapper,
  SecondaryImage,
  VideoElement,
  MoreImages,
  EditPrimaryDetails,
  AddTitleContainer,
  MiniHeading,
  AddDescriptionContainer,
  AddCategoryContainer,
  EditPricingContainer,
  EditInventoryContainer,
  EditVariantsContainer,
  VariantOptionsCheckBox,
  AddedVariantOptionValue,
  VariantOptionsContainer,
  VariantOptionsHeading,
  VariantOption,
  VariantOptionBox,
  VariantOptionElement,
  VariantOptionValue,
  PreviewContainer,
  PreviewHeading,
  VariantTable,
  VariantTableHeadingContainer,
  VariantTableHeading,
  VariantDetailsContainer,
  VariantName,
  VariantPrice,
  VariantSKU,
  VariantQuantity,
  VariantPrimaryImage,
  VariantImageWrapper,
  VariantMedia,
  CardDetails,
  EditBestTestimonyContainer,
  EditAddIngredientsContainer,
  EditTechnicalSpecifications,
  HowItWorksContainer,
  EditHowItWorksContent,
  HowItWorksTextContent,
  HowItWorksMediaContent,
  HowItWorksImage,
  HowItWorksVideoContainer,
  HowItWorksMediaContainer,
  EditAddTagsContainer,
  TagsHeadingInfoContainer,
  AddTagsInfo,
  EditMoreInformationContainer,
  EditSocialMediaPostContainer,
  AddSocialMediaInfo,
  TrendingSocialMediaPostContainer,
  TrendingSocialMediaPostHeading,
  SocialMediaPostContainer,
  SocialMediaPost,
  EditFAQContainer,
  FAQElement,
  FAQQuestion,
  FAQAnswer,
  SavedFAQElement,
  SavedFAQQuestion,
  SavedFAQAnswer,
  EditShippingContainer,
  ShippingInfoElement,
  ShippingInfoHeading,
  ShippingInfoText,
  SaveDetails,
  CheckBoxContainer,
  CheckBoxText
};
