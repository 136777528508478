import React, { useEffect } from 'react';
import {
  SubContainer,
  NavigationWrapper,
  PaymentDetailWrapper,
  Wrapper
} from './styles';
import PropTypes from 'prop-types';
import { toaster } from '../../atoms/toaster';

import FinalAmount from '../../organisms/TotalAmount/FinalAmount';
import ShippingDetail from '../../organisms/TotalAmount/ShippingDetail';
export default function TotalAmount({
  makePayment,
  finalAmount,
  history,
  setInitalStatus
}) {
  useEffect(() => {
    if (finalAmount.isSuccess) {
      history.replace('/thank-you-v1');
    } else if (finalAmount.isError) {
      toaster('Error occurs while processing payment');
    }
  }, [finalAmount, history]);

  useEffect(() => {
    return () => {
      setInitalStatus();
    };
  });
  return (
    <SubContainer>
      <PaymentDetailWrapper>
        <Wrapper>
          <ShippingDetail finalAmount={finalAmount} />
        </Wrapper>
        <Wrapper>
          <FinalAmount makePayment={makePayment} finalAmount={finalAmount} />
        </Wrapper>
      </PaymentDetailWrapper>
    </SubContainer>
  );
}

TotalAmount.propTypes = {
  makePayment: PropTypes.func,
  finalAmount: PropTypes.object,
  history: PropTypes.object
};
