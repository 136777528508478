import { handleActions } from 'redux-actions';
import * as constants from '../constants';
import update from 'immutability-helper';

const initalState = {
  productShareLink: ''
};

const updateProductShareLink = (state, action) =>
  update(state, {
    productShareLink: { $set: action.payload }
  });

export default handleActions(
  {
    [constants.UPDATE_PRODUCT_SHARE_LINK]: updateProductShareLink
  },
  initalState
);
