import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Avatar,
  CardActions,
  Box,
  Tooltip
} from '@material-ui/core';
import DefaultImage from '../../../../../assets/images/no-image.svg';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmpty } from 'lodash';
import shoptypeThemes from '../../../../../theme';
import { currencySymbol } from '../../../../../utils';
import { Location } from '../styles';
import CardSpinner from '../../.../../../../common/CardSpinner';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    '@media only screen and (max-width: 768px)': {
      padding: '10px'
    }
  },
  avatar: {
    height: '100px',
    width: '100px',
    padding: '5px',
    backgroundColor: shoptypeThemes.default.LIGHT_SILVER,
    borderRadius: '50%',

    '& .MuiAvatar-img': {
      borderRadius: '50%',
      objectFit: styles => styles.objectFit
    },

    '@media only screen and (max-width: 1420px)': {
      height: '95px',
      width: '95px'
    },

    '@media only screen and (max-width: 768px)': {
      height: '80px',
      width: '80px'
    }
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    marginTop: 'auto',
    minHeight: '176px'
  },
  idInfo: {
    color: shoptypeThemes.default.BLUE_GEM,
    fontSize: '10px',
    lineHeight: '8px'
  },
  vendorData: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '5px'
  },
  vendorItem: {
    alignSelf: 'start',
    justifySelf: 'center',
    textAlign: 'center'
  },
  dataLabel: {
    fontSize: '10px',
    color: shoptypeThemes.default.BLUE_GEM,
    fontWeight: 500
  },
  buttonsContainer: {
    display: 'flex',
    padding: '10px 20px 25px 20px'
  },
  button: {
    flex: '1',
    textTransform: 'none',
    backgroundColor: shoptypeThemes.default.RONCHI
  },
  vendorInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: shoptypeThemes.default.BLACK,
    height: '20px',
    padding: '10px',
    borderRadius: '15px',
    backgroundColor: 'rgba(255, 216, 0, 0.35)',
    marginRight: '5px'
  },
  icons: {
    width: '15px',
    height: '15px',
    marginRight: '3px'
  },
  title: {
    lineHeight: '1',
    marginBottom: '5px',
    wordBreak: 'break-word'
  }
});

const ConnectedVendorCard = props => {
  const { vendor, analytics, vendorCardActionMethods } = props;

  // CARD HEADER
  const avatar = vendor?.logo || DefaultImage;
  const vendorName = vendor?.name ? vendor?.name : '--No name--';
  const vendorId = vendor?.id ? `XXXXXX${vendor?.id.slice(-4)}` : 'XXXXXXXXXX';
  const location = vendor?.store?.city ? vendor?.store.city : '--';
  const currency = vendor?.store?.currency ? vendor?.store.currency : '--';

  // CARD CONTENT
  const totalProducts = analytics?.total_products;
  const ordersLastMonth = analytics?.orders_last_month;
  const revenueLastMonth = `${currencySymbol(
    analytics?.revenue_last_month.currency
  )}${analytics?.revenue_last_month.amount}`;
  const cosellerRevenueLastMonth = `${currencySymbol(
    analytics?.coseller_revenue_last_month.currency
  )}${analytics?.coseller_revenue_last_month.amount}`;
  const returns = analytics?.returns;
  const paymentSettled = `${currencySymbol(
    analytics?.payment_settled.currency
  )}${analytics?.payment_settled.amount}`;
  const paymentPending = `${currencySymbol(
    analytics?.payment_pending.currency
  )}${analytics?.payment_pending.amount}`;

  const CONNECTED_VENDOR_ANALYTICS = !isEmpty(analytics)
    ? [
        {
          label: 'Total Products',
          value: totalProducts
        },
        {
          label: 'Orders Last Month',
          value: ordersLastMonth
        },
        {
          label: 'Revenue Last Month',
          value: revenueLastMonth
        },
        {
          label: 'Coseller Revenue Last Month',
          value: cosellerRevenueLastMonth
        },
        {
          label: 'Returns',
          value: returns
        },
        {
          label: 'Payment Settled',
          value: paymentSettled
        },
        {
          label: 'Payment Pending',
          value: paymentPending
        }
      ]
    : [];

  // Conditional stylings
  const imageObjectFit = avatar === vendor?.logo ? 'cover' : 'none';

  const styles = {
    objectFit: imageObjectFit
  };

  const classes = useStyles(styles);

  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Avatar
            className={classes.avatar}
            aria-label="network-image"
            src={avatar}
          />
        }
        title={vendorName}
        classes={{ title: classes.title }}
        subheader={
          <>
            <Box display="flex" flexDirection="column" mb={1.5}>
              <Typography variant="body2">{vendorId}</Typography>
              <Typography className={classes.idInfo}>
                Shoptype vendor Id
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: '3px' }}
            >
              <div className={classes.vendorInfo}>
                <LocationOnIcon className={classes.icons} />
                <Tooltip title={location} arrow>
                  <Location>{location}</Location>
                </Tooltip>
              </div>
              <div className={classes.vendorInfo}>
                <MoneyOutlinedIcon className={classes.icons} />
                <Box fontSize={10} fontWeight={500}>
                  {currency}
                </Box>
              </div>
            </Box>
          </>
        }
        titleTypographyProps={{
          variant: 'h6'
        }}
        subheaderTypographyProps={{
          variant: 'subtitle2'
        }}
      />

      <CardContent className={classes.cardContent}>
        {!isEmpty(analytics) ? (
          <div className={classes.vendorData}>
            {CONNECTED_VENDOR_ANALYTICS.map((data, index) => (
              <div key={index} className={classes.vendorItem}>
                <Box fontSize={24} fontWeight={500}>
                  {data.value}
                </Box>
                <Typography className={classes.dataLabel}>
                  {data.label}
                </Typography>
              </div>
            ))}
          </div>
        ) : (
          <CardSpinner />
        )}
      </CardContent>

      <CardActions className={classes.buttonsContainer}>
        <Button
          variant="contained"
          className={`${classes.button}`}
          onClick={() => vendorCardActionMethods.unlinkVendor(vendor?.vendorId)}
          startIcon={<DeleteIcon />}
        >
          Remove from Network
        </Button>
      </CardActions>
    </Card>
  );
};

ConnectedVendorCard.propTypes = {
  mode: PropTypes.string,
  vendor: PropTypes.object,
  vendorCardActionMethods: PropTypes.object,
  store: PropTypes.object
};

export default ConnectedVendorCard;
