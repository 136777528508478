import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import shoptype from '../../../../assets/images/simple_logo.png';
import { API_BASE_URL, APP_BASE_URL } from '../../../../config/env';
import Modal from '../../../templates/Modal';
import DefaultProductImage from '../../../../assets/images/photo.svg';
import PublishModal from '../../../organisms/ShareOnSocial';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import RefundModal from './RefundModal';
import RequestDetailsDialog from '../../../atoms/Orders/ReturnRequests/RequestDetailsDialog';
import { CURRENCY_SYMBOL } from '../../../../constants';
import shoptypeThemes from '../../../../theme';

import {
  ORDER_STATUS_COMPLETED_WOOCOMMERCE,
  ORDER_STATUS_COMPLETED_SHOPIFY
} from '../../../../constants';
import moment from 'moment';
import {
  getAuthToken,
  getApiResponseObject,
  getAuthId,
  showApiErrorToast,
  successStatusCodes,
  DetectOutsideClick
} from '../../../../utils';
import './styles.css';

const PurchaseCards = ({
  Seller,
  ItemName,
  Quantity,
  ItemTotal,
  productId,
  source,
  status,
  ReturnsCount,
  deliveryDate,
  currency,
  shoptypeOrderId,
  VendorId,
  _OrderId,
  purchasedItems,
  index
}) => {
  const [refundStatus, setRefundStatus] = useState('');

  const [open, setOpen] = useState(false);

  const [returnsQuantity, setReturnsQuantity] = useState(0);

  const [quantityDifference, setQuantityDifference] = useState(Quantity);

  const [productShareLink, setProductShareLink] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [newModal, setNewModal] = useState(null);
  const [anotherModal, setAnotherModal] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [drop, setDrop] = useState(false);
  const [isNewModal, setIsNewModal] = useState(false);

  const [returnsReqdata, setReturnsReqdata] = useState([]);

  const [loading, setLoading] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [reqDetails, setReqDetails] = useState([]);

  const fetchRequestDetails = async shoptypeOrderId => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/coseller-returns/order/${shoptypeOrderId}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    }

    const returnsData = response?.data?.data;

    if (successStatusCodes.includes(response.status)) {
      setLoading(false);
      setDataAvailable(true);
      setReqDetails(returnsData);
    } else {
      setLoading(false);
      setDataAvailable(false);
    }
  };

  useEffect(() => {
    fetchProductData(productId);
    fetchRequestDetails(shoptypeOrderId);
    calculateReturnsdata();
  }, []);

  const toggleChange = () => {
    setDrop(!drop);
  };

  const calculateReturnsdata = () => {
    let returnsData = reqDetails;
    let tempArr = [];
    let i;
    let tempquantity = 0;

    for (i = 0; i < returnsData.length; i++) {
      tempArr.push(returnsData[i].line_items[productId]);

      if (
        returnsData[i].line_items[productId] &&
        returnsData[i].line_items[productId].product_id
      ) {
        if (productId == returnsData[i].line_items[productId].product_id) {
          tempquantity =
            tempquantity + returnsData[i].line_items[productId].quantity;
        } else {
          tempquantity = tempquantity + 0;
        }
      }
    }

    let quantitydiff = Quantity - tempquantity;

    setReturnsQuantity(tempquantity);

    setQuantityDifference(quantitydiff);
  };

  const fetchRefundstatus = childData => {
    setRefundStatus(childData);
  };

  let new_currency;
  if (CURRENCY_SYMBOL[currency] !== undefined) {
    new_currency = CURRENCY_SYMBOL[currency];
  }

  const fetchProductData = async productId => {
    const response = await getApiResponseObject(
      `${API_BASE_URL}/products/${productId}`
    );
    if (response.status !== 200) {
      showApiErrorToast(response.data);
    }
    const fetchedProductDetails = response.data || [];
    setProductDetails(fetchedProductDetails);
  };

  const clickPublish = (
    productId,
    title,
    description,
    imageSrc,
    catalogId,
    currency
  ) => {
    fetchProductLinkData(
      productId,
      title,
      description,
      imageSrc,
      catalogId,
      currency
    );
  };

  const fetchProductLinkData = async (
    productId,
    title,
    description,
    imageSrc,
    catalogId,
    currency
  ) => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/track/publish-slug?productId=${productId}`,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    }
    const psl = `${APP_BASE_URL}${response.data.slug}`;
    setIsOpen(true);
    publish(productId, title, description, imageSrc, catalogId, psl, currency);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  const publish = (
    productId,
    title,
    description,
    imageSrc,
    catalogId,
    psl,
    currency
  ) => {
    const token = getAuthToken();
    const authId = getAuthId();

    const modalContent = (
      <Modal close={closeModal}>
        <div className="topLevelContainer">
          <PublishModal
            authId={authId}
            productId={productId}
            vendorId={catalogId} // Vendor Id is considered as Catalog id
            title={title}
            token={token}
            description={description}
            imageSrc={imageSrc}
            productShareLink={psl}
            embedOption={true}
            currency={currency}
          />
        </div>
      </Modal>
    );

    setModalContent(modalContent);
  };

  const closeNewModal = () => {
    setNewModal(null);
  };

  const selectReturn = () => {
    const newModalContent = (
      <Modal close={closeNewModal} model={'custom'} width={620} height={550}>
        <RefundModal
          Quantity={Quantity}
          ItemName={ItemName}
          VendorId={VendorId}
          productId={productId}
          shoptypeOrderId={shoptypeOrderId}
          purchasedItems={purchasedItems}
          source={source}
          closeModal={closeNewModal}
          fetchRefundstatus={fetchRefundstatus}
          quantityDifference={quantityDifference}
        />
      </Modal>
    );

    setNewModal(newModalContent);
    setIsNewModal(true);
  };

  const openRequestDetailsDialog = () => {
    setOpen(true);
  };

  const closeRequestDetailsDialog = () => {
    setOpen(false);
  };

  return (
    <div>
      {isOpen === true ? <>{modalContent}</> : null}
      {isNewModal === true ? <>{newModal} </> : null}
      {open === true ? (
        <RequestDetailsDialog
          open={open}
          onClose={closeRequestDetailsDialog}
          loading={loading}
          requests={reqDetails}
          dataAvailable={dataAvailable}
          hasDelete={true}
        />
      ) : null}
      <div className="newCard">
        <div className="flexRow">
          <div className="rectangle1">
            <Typography classes={{ root: 'fixedFont' }}>Seller :</Typography>
            <Typography classes={{ root: 'typoTwo' }}>
              {' '}
              {Seller[0].toUpperCase() + Seller.slice(1).toLowerCase()}{' '}
            </Typography>
          </div>
          {status == ORDER_STATUS_COMPLETED_SHOPIFY ||
          status == ORDER_STATUS_COMPLETED_WOOCOMMERCE ? (
            <div className="rectangle">
              <Typography classes={{ root: 'typofour' }}>
                {status[0].toUpperCase() + status.slice(1).toLowerCase()},{' '}
                {moment(deliveryDate).format('MMM Do YYYY')}
              </Typography>
              {ReturnsCount > 0 && index == 0 ? (
                <div className="flexRow">
                  <Typography
                    classes={{ root: 'typoFive' }}
                    color="textSecondary"
                  >
                    | Requested for Return
                  </Typography>
                  <Typography
                    classes={{ root: 'typoPointer' }}
                    color="primary"
                    onClick={() => openRequestDetailsDialog()}
                  >
                    [Items:{ReturnsCount}]
                  </Typography>
                </div>
              ) : null}
            </div>
          ) : status == 'out for delivery' ? (
            <div className="rectangle8">
              <Typography classes={{ root: 'typo6' }} color="textSecondary">
                Out For Delivery
              </Typography>
            </div>
          ) : null}
        </div>

        <div className="flexRow">
          <div className="rectangle2">
            <div className="rowTopPadding">
              <img src={shoptype} alt="none" style={{ height: '60px' }} />
              <div className="flexColumn1">
                <Typography classes={{ root: 'typo7' }}>
                  {' '}
                  {ItemName}{' '}
                </Typography>
                <Typography classes={{ root: 'typo8' }}>
                  {' '}
                  Quantity: {Quantity}{' '}
                </Typography>
              </div>
            </div>
          </div>
          <div className="rectangle3">
            <Typography classes={{ root: 'typo9' }}>Item Total</Typography>
            <Typography classes={{ root: 'fixedFont' }} component="h2">
              {new_currency} {ItemTotal}
            </Typography>
          </div>
        </div>

        <div className="flexRow">
          <div className="rectangle4">
            <Typography
              classes={{ root: 'typo10' }}
              gutterBottom
              color="primary"
              component="h2"
              onClick={() =>
                clickPublish(
                  productId,
                  productDetails.title,
                  productDetails.description,
                  productDetails.primaryImageSrc.imageSrc ||
                    DefaultProductImage,
                  productDetails.catalogId,
                  productDetails.currency
                )
              }
            >
              Cosell
            </Typography>
          </div>

          {quantityDifference != 0 ? (
            <div className="rectangle5" onClick={() => toggleChange()}>
              <Typography
                classes={{ root: 'typo10' }}
                gutterBottom
                component="h2"
                color="primary"
                style={{
                  color:
                    status === 'fulfilled' || status === 'completed'
                      ? 'primary'
                      : shoptypeThemes.default.DARK_GRAY,
                  cursor:
                    status === 'fulfilled' || status === 'completed'
                      ? 'pointer'
                      : 'default'
                }}
              >
                More Actions
              </Typography>
              {drop === true ? (
                <ExpandLessIcon
                  style={{
                    paddingTop: '3px',
                    height: '35px',
                    width: '25px',
                    color: shoptypeThemes.default.DARK_GRAY
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  style={{
                    paddingTop: '3px',
                    height: '35px',
                    width: '25px',
                    color: shoptypeThemes.default.DARK_GRAY
                  }}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flexRow">
        <div className="rectangle6" />
        {((drop === true && status == 'fulfilled') ||
          (drop === true && status == 'completed')) &&
        quantityDifference != 0 ? (
          <div className="rectangle51">
            <DetectOutsideClick
              show={drop}
              onClickOutside={() => toggleChange()}
              component={
                <Typography
                  classes={{ root: 'typo11' }}
                  gutterBottom
                  color="primary"
                  component="h2"
                  onClick={() => selectReturn()}
                >
                  Request For Return
                </Typography>
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PurchaseCards;
