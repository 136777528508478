import styled from 'styled-components';

const Container = styled.div`
  background-color: ${props => props.theme.FLORAL_WHITE};
  display: flex;
`;
const SubContainer = styled.div`
  flex: 1;
`;
const NavigationWrapper = styled.div`
  background-color: ${props => props.theme.WHITE};
`;
const PaymentDetailWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 5rem;
  display: flex;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 3rem;
  }
`;
const Wrapper = styled.div`
  width: 48%;
  margin: 2rem 0 0;
  @media (max-width: 768px) {
    width: 100%;
    margin: 2rem 0 0;
  }
`;
export {
  Container,
  SubContainer,
  NavigationWrapper,
  PaymentDetailWrapper,
  Wrapper
};
