import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Secondary as SecondaryButton,
  Ternary as TernaryButton
} from '../../atoms/button';
import {
  getAuthToken,
  postWithResponseObject,
  getApiResponseObject,
  successStatusCodes,
  showApiErrorToast
} from '../../../utils';
import Modal from '../../templates/Modal';
import history from '../../../history';
import CheckBox from '../../atoms/checkBox';
import {
  ProductContainer,
  SubHeading,
  Paragraph,
  LinkAccountContainer,
  AddProductView,
  ImportProductModal,
  ModalHeading,
  ShippingServiceTitle,
  ShippingService,
  ModalForm,
  FormField,
  LinkButton
} from './styles.js';
import { NewClose } from '../../templates/InviteCoseller/styles';
import {
  API_BASE_URL,
  SHOPIFY_SHOPTYPE_CHECKOUT_ENFORCE,
  WOOCOMMERCE_SHOPTYPE_CHECKOUT_ENFORCE
} from '../../../config/env';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/uiActions';

const LINK_PRODUCT_TYPES = {
  linkShopifyAccount: {
    description:
      'Import your catalog from Shopify using your API credentials and start publishing products from shoptype.',
    text: 'Import Shopify Products',
    key: 'linkShopifyAccount'
  },
  importWooCommerceProducts: {
    description:
      'Import a list of products from WooCommerce to build your catalog.',
    text: 'Import WooCommerce Products',
    key: 'importWooCommerceProducts'
  }
};
class AddProduct extends React.Component {
  syncStatusUpdateInterval;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      modalContent: null,
      domain: '',
      apiKey: '',
      password: '',
      accessToken: '',
      dokanVendorName: '',
      dokanVendorId: '',
      isLoading: true,
      disableSubmit: false,
      restrictions: {
        isAdult: false,
        isAgeRestricted: false
      },
      syncStatus: null,
      syncedProductsCount: 0
    };
  }

  submitHandler = e => {
    e.preventDefault();
    this.updateShopifyData();
  };

  inputChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentDidMount() {
    this.updateSyncStatus();
  }

  componentWillUnmount() {
    clearInterval(this.syncStatusUpdateInterval);
  }

  setSyncStatusUpdateTimer = () => {
    if (this.syncStatusUpdateInterval) {
      clearInterval(this.syncStatusUpdateInterval);
    }

    this.syncStatusUpdateInterval = setInterval(this.updateSyncStatus, 5000);
  };

  updateSyncStatus = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const userDetails = JSON.parse(localStorage.getItem('userProfile')) || {};
    const vendorId = !isEmpty(userDetails.vendors)
      ? userDetails.vendors[0].vendorId
      : '';
    const url = vendorId
      ? `${API_BASE_URL}/sync-status?vendorId=${vendorId}`
      : `${API_BASE_URL}/sync-status`;
    let response = await getApiResponseObject(url, headers);
    const { status, productCount } = response.data;
    this.setState(
      { syncStatus: status, syncedProductsCount: productCount },
      () => {
        if (
          this.syncStatusUpdateInterval &&
          this.state.syncStatus === 'completed'
        ) {
          clearInterval(this.syncStatusUpdateInterval);
          this.viewProducts();
        }
      }
    );
    this.props.hideSpinnerBackdrop();
  };

  updateShopifyData = async () => {
    const token = getAuthToken();
    const timestamp = new Date().getTime();
    const headers = { authorization: token };
    const { domain, accessToken, restrictions } = this.state;

    const extrasAge = restrictions.isAgeRestricted.toString();
    const extrasAdult = restrictions.isAdult.toString();
    const newRestrictions = {
      isAdult: extrasAdult,
      isAgeRestricted: extrasAge
    };

    const requestBody = {
      storeName: domain,
      accessToken: accessToken,
      timestamp: timestamp,
      enableCheckoutShoptype: SHOPIFY_SHOPTYPE_CHECKOUT_ENFORCE,
      restrictions: newRestrictions
    };
    this.props.showSpinnerBackdrop();

    this.setState({ disableSubmit: true });
    const response = await postWithResponseObject(
      `${API_BASE_URL}/store/shopify`,
      requestBody,
      headers
    );
    this.setState({ disableSubmit: false });
    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      this.props.hideSpinnerBackdrop();
      return;
    }

    this.updateSyncStatus();
    this.setSyncStatusUpdateTimer();
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      modalContent: null,
      isOpen: false
    });

    this.resetForm();
  };

  resetForm = () => {
    let { restrictions } = this.state;
    restrictions.isAdult = false;
    restrictions.isAgeRestricted = false;
    this.setState({
      domain: '',
      apiKey: '',
      password: '',
      accessToken: '',
      dokanVendorId: '',
      dokanVendorName: '',
      //enableCheckoutOnShoptype: false,
      restrictions: restrictions
    });
  };

  importBlokPartyProducts = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const requestBody = {};
    this.props.showSpinnerBackdrop();

    const response = await postWithResponseObject(
      `${API_BASE_URL}/command?type=addProducts&source=blok-party`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      this.props.hideSpinnerBackdrop();

      return;
    }

    this.updateSyncStatus();
    this.closeModal();
  };

  linkWooCommerce = async e => {
    e.preventDefault();
    const {
      domain,
      apiKey,
      password,
      dokanVendorId,
      dokanVendorName,
      shippingApiKey,
      shippingApiPassword,
      restrictions
    } = this.state;

    const extrasAge = restrictions.isAgeRestricted.toString();
    const extrasAdult = restrictions.isAdult.toString();
    const newRestrictions = {
      isAdult: extrasAdult,
      isAgeRestricted: extrasAge
    };
    const token = getAuthToken();
    const headers = {
      authorization: token,
      'Content-Type': 'application/json'
    };
    const requestBody = {
      storeName: domain,
      storeHostUrl: domain,
      consumerKey: apiKey,
      consumerSecret: password,
      dokan_vendor_id: dokanVendorId || '',
      dokan_vendor_name: dokanVendorName || '',
      enableCheckoutShoptype: WOOCOMMERCE_SHOPTYPE_CHECKOUT_ENFORCE,
      shippingServiceId: '001',
      shippingConsumerKey: shippingApiKey,
      shippingConsumerSecret: shippingApiPassword,
      restrictions: newRestrictions
    };

    this.setState({ disableSubmit: true });
    this.props.showSpinnerBackdrop();

    const response = await postWithResponseObject(
      `${API_BASE_URL}/store/woo-commerce`,
      requestBody,
      headers
    );
    this.setState({ disableSubmit: false });

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
      this.props.hideSpinnerBackdrop();
      return;
    }

    this.updateSyncStatus();
    this.setSyncStatusUpdateTimer();
    this.closeModal();
  };

  handleClick = async val => {
    if (val === 'createProduct') {
      history.push('/create-product');
    } else if (val === 'importBlokPartyProducts') {
      this.importBlokPartyProducts();
    } else if (val === 'importWooCommerceProducts') {
      this.setState({
        modalContent: val,
        isOpen: true
      });
    } else {
      this.setState({ modalContent: val, isOpen: true });
    }
  };

  componentWillUnmount() {
    clearInterval(this.syncStatusUpdateInterval);
  }

  handleCheck = event => {
    let { restrictions } = this.state;
    restrictions[event.target.name] = !restrictions[event.target.name];
    this.setState({
      restrictions: restrictions
    });
  };

  viewProducts = () => {
    const fullPath = window.location.pathname.split('/');
    const path = fullPath[fullPath.length - 1];
    if (path == 'add-products') {
      history.push('/products');
    } else {
      const { fetchDashboardData } = this.props;
      fetchDashboardData();
    }
  };

  render() {
    const {
      isOpen,
      modalContent,
      domain,
      dokanVendorName,
      dokanVendorId,
      apiKey,
      password,
      accessToken,
      disableSubmit,
      syncStatus,
      syncedProductsCount,
      restrictions
    } = this.state;
    return (
      <ProductContainer>
        {syncStatus == '' ? (
          <React.Fragment>
            <SubHeading>How would you like to build your catalog?</SubHeading>
            <Paragraph>
              Add products to your Shoptype catalog easily with the following
              methods.
            </Paragraph>
            <LinkAccountContainer>
              {isOpen === true ? (
                <>
                  {modalContent === 'linkShopifyAccount' && (
                    <Modal>
                      <NewClose onClick={() => this.closeModal()}> X </NewClose>
                      <ImportProductModal>
                        <ModalHeading>Import from Shopify</ModalHeading>
                        <ModalForm onSubmit={this.submitHandler}>
                          <FormField
                            name="domain"
                            type="text"
                            size="import-products-modal"
                            placeHolder="Enter Store Name <storename>.myshopify.com"
                            required={true}
                            defaultValue={domain}
                            onChange={this.inputChangeHandler}
                          />
                          <FormField
                            id="accessToken"
                            name="accessToken"
                            type="accessToken"
                            placeHolder="Admin Acess Token"
                            required={true}
                            defaultValue={accessToken}
                            onChange={this.inputChangeHandler}
                          />
                          <ShippingServiceTitle>
                            <CheckBox
                              value={restrictions.isAdult}
                              name="isAdult"
                              label="These products belong to the “Adult” category"
                              onChange={event => this.handleCheck(event)}
                            />
                          </ShippingServiceTitle>
                          <ShippingService>
                            <CheckBox
                              value={restrictions.isAgeRestricted}
                              name="isAgeRestricted"
                              label="These products belong to the “Age Restricted” Category"
                              onChange={event => this.handleCheck(event)}
                            />
                          </ShippingService>
                          <LinkButton>
                            <SecondaryButton
                              text="Link"
                              disabled={disableSubmit}
                            />
                          </LinkButton>
                        </ModalForm>
                      </ImportProductModal>
                    </Modal>
                  )}
                  {modalContent === 'importWooCommerceProducts' && (
                    <Modal size="woocommerce-import">
                      <NewClose onClick={() => this.closeModal()}> X </NewClose>
                      <ImportProductModal>
                        <ModalHeading>Import from WooCommerce</ModalHeading>
                        <ModalForm onSubmit={this.linkWooCommerce}>
                          <FormField
                            id="domain"
                            name="domain"
                            type="text"
                            size="import-products-modal"
                            placeHolder="Enter domain name as https://yourdomain.com"
                            required={true}
                            defaultValue={domain}
                            onChange={this.inputChangeHandler}
                          />

                          <FormField
                            name="apiKey"
                            type="text"
                            placeHolder="API KEY"
                            size="import-products-modal"
                            required={true}
                            autocomplete="apiKey"
                            id="apiKey"
                            defaultValue={apiKey}
                            onChange={this.inputChangeHandler}
                          />

                          <FormField
                            id="password"
                            name="password"
                            type="password"
                            placeHolder="API Password"
                            size="import-products-modal"
                            required={true}
                            defaultValue={password}
                            onChange={this.inputChangeHandler}
                          />
                          <FormField
                            id="dokan-vendor-id"
                            name="dokanVendorId"
                            type="text"
                            placeHolder="Dokan Vendor ID"
                            defaultValue={dokanVendorId}
                            onChange={this.inputChangeHandler}
                          />
                          <FormField
                            id="dokan-vendor-name"
                            name="dokanVendorName"
                            type="text"
                            placeHolder="Dokan Vendor Name"
                            defaultValue={dokanVendorName}
                            onChange={this.inputChangeHandler}
                          />
                          <ShippingServiceTitle>
                            <CheckBox
                              value={restrictions.isAdult}
                              name="isAdult"
                              label="These products belong to the “Adult” category"
                              onChange={event => this.handleCheck(event)}
                            />
                          </ShippingServiceTitle>
                          <ShippingService>
                            <CheckBox
                              value={restrictions.isAgeRestricted}
                              name="isAgeRestricted"
                              label="These products belong to the “Age Restricted” Category"
                              onChange={event => this.handleCheck(event)}
                            />
                          </ShippingService>
                          <LinkButton>
                            <SecondaryButton
                              text="Link"
                              disabled={disableSubmit}
                            />
                          </LinkButton>
                        </ModalForm>
                      </ImportProductModal>
                    </Modal>
                  )}
                  {modalContent === 'importProduct' && (
                    <Modal close={this.closeModal}>
                      <ImportProductModal>
                        This is importProductModal
                      </ImportProductModal>
                    </Modal>
                  )}
                </>
              ) : null}
              {Object.values(LINK_PRODUCT_TYPES).map(
                (linkProductTypes, index) => (
                  <AddProductView key={index} isDark={true}>
                    <p>{linkProductTypes.description}</p>
                    <TernaryButton
                      onClick={() =>
                        this.handleClick(`${linkProductTypes.key}`)
                      }
                      text={linkProductTypes.text}
                    />
                  </AddProductView>
                )
              )}
            </LinkAccountContainer>
          </React.Fragment>
        ) : syncStatus == 'in progress' && syncedProductsCount == 0 ? (
          <h1>Product Sync is in progress, Please wait...</h1>
        ) : syncStatus == 'initiated' ? (
          <h1>Product Sync is initiated, Please wait...</h1>
        ) : syncStatus == 'completed' ? (
          <h1>Product Sync is completed</h1>
        ) : (
          <React.Fragment>
            {syncStatus == 'in progress' && syncedProductsCount > 0 ? (
              <div>
                <h1>Product Sync is in progress, Please wait...</h1>
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        )}
      </ProductContainer>
    );
  }
}

AddProduct.propTypes = {
  hasProducts: PropTypes.bool,
  showSpinnerBackdrop: PropTypes.func,
  hideSpinnerBackdrop: PropTypes.func
};
const mapDispatchToProps = dispatch => {
  return {
    showSpinnerBackdrop: () => dispatch(actions.showSpinnerBackdropAction()),
    hideSpinnerBackdrop: () => dispatch(actions.hideSpinnerBackdropAction())
  };
};
export default connect(null, mapDispatchToProps)(AddProduct);
