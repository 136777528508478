import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Ternary as TernaryButton } from '../../atoms/button';
import TextInput from '../../atoms/textInput';

const Container = styled.div`
  background-color: ${props => props.theme.FLORAL_WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 40px 0;
`;

const InnerContainer = styled.div`
  margin-top: ${props => (props.isCoseller ? '0' : '72px')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Vendor = styled(TernaryButton)`
  border-right: 0;
  background-color: ${p => p.theme.GALLERY};
  &:hover {
    background-color: ${p => p.theme.GALLERY};
    transition: all 200ms ease;
  }
`;

const Coseller = styled(TernaryButton)`
  &:hover {
    background-color: ${p => p.theme.GALLERY};
    transition: all 200ms ease;
  }
`;

const SignTextHeader = styled.span`
  font-weight: ${p => p.theme.MEDIUM_FONT};
  font-size: ${p => p.theme.XX_LARGE};
`;

const AuthContainer = styled.div`
  width: ${props => (props.isCoseller ? '100%' : '50%')};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px;
  margin: 20px 0 60px 0;

  > button,
  div {
    width: 140px;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const ForgotPassword = styled(Link)`
  display: block;
  cursor: pointer;
  color: ${p => p.theme.BLACK};
  text-decoration: none;
  margin: 14px 0;
`;

const VendorOrCosellerLogin = styled.div`
  margin: ${props => (props.isCoseller ? '0' : '0 0 20px 0')};
`;

const ShoptypeLogo = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const OtherLoginTypes = styled.span`
  margin: 0 0 30px 0;
`;

const SignInForm = styled.form`
  ${p =>
    p.display
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
`;

const SignUpForm = styled(SignInForm)``;

const GoogleButton = styled.button`
  width: 150px;
  border: 1px solid ${p => p.theme.BLACK};
  height: 40px;
  cursor: pointer;
  background-color: white;
  & > img {
    margin: auto;
  }
`;

const FacebookButton = styled.button`
  border: 1px solid ${p => p.theme.BLACK};
  height: 40px;
  width: 150px;
  cursor: pointer;
  background-color: white;
  & > img {
    margin: auto;
  }
`;

const LinkedInButton = styled.button`
  border: 1px solid ${p => p.theme.BLACK};
  height: 40px;
  width: 150px;
  cursor: pointer;
  background-color: white;
  & > img {
    margin: auto;
  }
`;

const AwakeMeLogin = styled.div`
  > button {
    border: 1px solid ${p => p.theme.BLACK};
    height: 40px;
    width: 140px;
    cursor: pointer;
    background-color: white;
  }
`;

const Name = styled(TextInput)`
  width: ${props => props.width + 'px' || '300px'};
  margin-right: ${props => props.marginRight + 'px' || 'initial'};
`;

const PhoneNumber = styled(Name)``;

const ModalContainer = styled.div`
  background-color: ${props => props.theme.WHITE};
`;

const ModalTopLevelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UserButtonContainer = styled.div`
  margin: 12px;

  button {
    margin: 4px;
  }

  ${props => props.theme.TABLET`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    span {
      width: 100%;
    }

    span button {
      margin: 0;
    }
    
      button, span button {
        margin: 4px 0;
        width: 100%;
        height : 40px;
      }
  `}
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SubHeading = styled.div`
  text-align: left;
  padding: 8px 0 20px 0;
  font-size: ${props => props.theme.LARGE};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  ${props => props.theme.TABLET`
    text-align: center;
  `}
`;

const Hyperlink = styled.div`
  width: 120px;
  height: 25px;
  font-size: ${props => props.theme.SMALL};
  color: ${props => props.theme.HAVELOCK_BLUE};
  cursor: pointer;
  text-align: center;
`;

const Footer = styled.div`
  max-width: 360px;
  margin: 18px 0;
`;

const Message = styled.div`
  max-width: 400px;
  text-align: left;
  margin: 12px 0;
  font-weight: ${props => props.theme.SEMI_MEDIUM_FONT};
  color: #66788a;
`;

export {
  Container,
  InnerContainer,
  SignTextHeader,
  AuthContainer,
  ForgotPassword,
  VendorOrCosellerLogin,
  ModalContainer,
  ModalTopLevelContainer,
  ShoptypeLogo,
  SignInForm,
  SignUpForm,
  OtherLoginTypes,
  GoogleButton,
  FacebookButton,
  AwakeMeLogin,
  Vendor,
  Coseller,
  LinkedInButton,
  Name,
  PhoneNumber,
  UserButtonContainer,
  Row,
  SubHeading,
  Hyperlink,
  Footer,
  Message
};
