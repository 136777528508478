/* eslint-disable react/prop-types */
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Instagram from '../../../../assets/images/instagram.svg';
import Facebook from '../../../../assets/images/facebook.svg';
import Twitter from '../../../../assets/images/twitter.svg';
import { Imgholder } from '../styles';
import InputTags from '../../../common/InputTags';
import { isEmpty } from 'lodash';
import { toaster } from '../../../atoms/toaster';
import {
  getAuthToken,
  validateSocialMediaURL,
  putWithResponseObject,
  showApiErrorToast,
  successStatusCodes,
  toBoolean
} from '../../../../utils';
import { API_BASE_URL } from '../../../../config/env';
import shoptypeThemes from '../../../../theme';

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: shoptypeThemes.default.RONCHI
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: 0,
    padding: 0
  },
  paper: {
    maxWidth: 550,
    marginTop: theme.spacing(4)
  },
  phone: {
    width: '165px',
    height: '55px'
  },
  submit: {
    margin: theme.spacing(4, 0, 0, 0),
    height: '45px',
    width: '100px'
  },
  heading: {
    fontSize: '18px',
    marginBottom: '10px'
  },
  socialMedia: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

const CosellerInfo = props => {
  const classes = useStyles();
  const { personalInfo, cosellerInfo } = props;

  // COSELLER DATA
  const [cosellerDetails, setCosellerDetails] = useState({
    gender: personalInfo?.gender || '',
    occupation: personalInfo?.occupation || '',
    portfolio: personalInfo?.portfolio || ''
  });
  const { gender, occupation, portfolio } = cosellerDetails;

  const [facebookURL, setFacebookURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const [instagramURL, setInstagramURL] = useState('');
  const [interests, setInterests] = useState(personalInfo?.interests || []);
  const [restrictions, setRestrictions] = useState({
    isAdult: false,
    isAgeRestricted: false
  });

  useEffect(() => {
    // Converting the JSON string values into boolean ('false' => false)
    if (!isEmpty(cosellerInfo?.restrictions)) {
      const ageRestricted = toBoolean(
        cosellerInfo.restrictions?.isAgeRestricted
      );
      const adult = toBoolean(cosellerInfo.restrictions?.isAdult);
      setRestrictions({
        isAdult: adult,
        isAgeRestricted: ageRestricted
      });
    }

    // Extracting the socialmediaURLs and setting seperate states
    if (!isEmpty(cosellerInfo?.socialMediaAccounts)) {
      const facebook = cosellerInfo.socialMediaAccounts.find(
        item => item.socialMediaName === 'FACEBOOK'
      );
      const twitter = cosellerInfo.socialMediaAccounts.find(
        item => item.socialMediaName === 'TWITTER'
      );
      const instagram = cosellerInfo.socialMediaAccounts.find(
        item => item.socialMediaName === 'INSTAGRAM'
      );
      setFacebookURL(facebook?.socialMediaLink || '');
      setTwitterURL(twitter?.socialMediaLink || '');
      setInstagramURL(instagram?.socialMediaLink || '');
    }
  }, []);

  const handleDataChange = event => {
    const { name, value } = event.target;
    setCosellerDetails({
      ...cosellerDetails,
      [name]: value
    });
  };

  const handleSelectedTag = tagData => {
    setInterests(tagData);
  };

  const handleCheckbox = event => {
    setRestrictions({
      ...restrictions,
      [event.target.name]: event.target.checked
    });
  };

  const handleSocialMediaURLs = event => {
    const { name, value } = event.target;
    switch (name) {
      case 'facebook':
        setFacebookURL(value);
        break;
      case 'twitter':
        setTwitterURL(value);
        break;
      case 'instagram':
        setInstagramURL(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };

    // Validating the social media URLs
    if (instagramURL.length > 0 && !validateSocialMediaURL(instagramURL)) {
      toaster('Invalid Instagram URL, Please test the URL & try again');
      return;
    }
    if (twitterURL.length > 0 && !validateSocialMediaURL(twitterURL)) {
      toaster('Invalid Twitter URL, Please test the URL & try again');
      return;
    }
    if (facebookURL.length > 0 && !validateSocialMediaURL(facebookURL)) {
      toaster('Invalid Facebook URL, Please test the URL & try again');
      return;
    }

    const newRestrictions = {
      isAdult: restrictions.isAdult.toString(),
      isAgeRestricted: restrictions.isAgeRestricted.toString()
    };
    const socialMediaAccounts = [
      {
        socialMediaName: 'FACEBOOK',
        socialMediaLink: facebookURL
      },
      {
        socialMediaName: 'TWITTER',
        socialMediaLink: twitterURL
      },
      {
        socialMediaName: 'INSTAGRAM',
        socialMediaLink: instagramURL
      }
    ];

    // Creating the payload for the API call
    const requestBody = {
      gender,
      occupation,
      portfolio,
      interests,
      restrictions: newRestrictions,
      socialMediaAccounts
    };

    const response = await putWithResponseObject(
      `${API_BASE_URL}/me`,
      requestBody,
      headers
    );
    const cosellerResponse = await putWithResponseObject(
      `${API_BASE_URL}/cosellers`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else if (!successStatusCodes.includes(cosellerResponse.status)) {
      showApiErrorToast(cosellerResponse.data);
    } else {
      toaster('Profile updated successfully');
    }
  };

  return (
    <Container component="main" maxWidth="sm" className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl component="fieldset">
              <Typography>Gender</Typography>
              <RadioGroup
                row
                aria-label="position"
                name="gender"
                value={gender}
                onChange={handleDataChange}
              >
                <FormControlLabel
                  value="MALE"
                  control={<Radio color="primary" />}
                  label="Male"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="FEMALE"
                  control={<Radio color="primary" />}
                  label="Female"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="OTHERS"
                  control={<Radio color="primary" />}
                  label="Others"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className={classes.heading}>Job Title</Typography>
            <TextField
              name="occupation"
              label="Professional Job Title"
              fullWidth
              variant="outlined"
              value={occupation}
              onChange={handleDataChange}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className={classes.heading}>
              Portfolio/Introduction
            </Typography>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              name="portfolio"
              value={portfolio}
              onChange={handleDataChange}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className={classes.heading}>
              Social Media Links
            </Typography>
            <div className={classes.socialMedia}>
              <Imgholder src={Instagram} alt="instagram-logo" />
              <TextField
                variant="outlined"
                fullWidth
                name="instagram"
                value={instagramURL}
                placeholder="www.instagram.com/username"
                onChange={handleSocialMediaURLs}
              />
            </div>
            <div className={classes.socialMedia}>
              <Imgholder src={Twitter} alt="twitter-logo" />
              <TextField
                variant="outlined"
                name="twitter"
                fullWidth
                value={twitterURL}
                placeholder="www.twitter.com/username"
                onChange={handleSocialMediaURLs}
              />
            </div>
            <div className={classes.socialMedia}>
              <Imgholder src={Facebook} alt="facebook-logo" />
              <TextField
                variant="outlined"
                name="facebook"
                fullWidth
                value={facebookURL}
                placeholder="www.facebook.com/username"
                onChange={handleSocialMediaURLs}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className={classes.heading}>Interests</Typography>
            <InputTags
              interests={interests}
              handleSelectedTag={tag => handleSelectedTag(tag)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className={classes.heading}>Preferences</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    name="isAdult"
                    checked={restrictions.isAdult}
                    onChange={handleCheckbox}
                  />
                }
                label="Show Adult Content"
              />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    name="isAgeRestricted"
                    checked={restrictions.isAgeRestricted}
                    onChange={handleCheckbox}
                  />
                }
                label="Show Age-restricted Content"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Button
          className={classes.submit}
          type="submit"
          variant="outlined"
          size="large"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </Container>
  );
};

export default CosellerInfo;
