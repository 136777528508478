import styled from 'styled-components';
import { Secondary } from '../../atoms/button';
import TextInput from '../../atoms/textInputV1';

const Container = styled.div`
  margin-top: 30px;
`;

const Email = styled(TextInput)`
  input {
    border-radius: 5px;
  }
`;

const SendButton = styled.div`
  margin-top: 20px;
`;

const Send = styled(Secondary)`
  max-width: 150px;
  min-height: 40px;
  border-radius: 5px;
  background-color: ${props => props.theme.RONCHI};
  color: ${props => props.theme.BLACK};
  font-size: ${props => props.theme.SMALL};
  font-weight: ${props => props.theme.BOLD_FONT};
`;

export { Container, Email, SendButton, Send };
