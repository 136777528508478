import React from 'react';
import styled from 'styled-components';
import Main from './components/templates/MainView';

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.FLORAL_WHITE};
`;

const ShoptypeApp = () => {
  return (
    <Container>
      <Main />
    </Container>
  );
};

export default ShoptypeApp;
