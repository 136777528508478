import React from 'react';
import styled, { css } from 'styled-components';
import GlobalStyles from './prebuilt/GlobalStyles';

const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  ${props => props.theme.TABLET`
      text-align: center;
    `}
`;

const Container = styled.div`
  background-color: ${props =>
    props.fullScreen ? 'transparent' : props.theme.WHITE};
  border-radius: 10px;
  padding: 20px;
  max-width: 700px;
  width: 100%;

  ${props =>
    props.fullScreen &&
    css`
      height: calc(100vh - 70px);
    `}

  ${props => props.theme.PHONE`
        max-width: 360px;
    `}
`;

const Layout = ({ children, title, fullScreen }) => {
  return (
    <>
      <LayoutContainer>
        <GlobalStyles />
        <Container fullScreen={fullScreen}>{children}</Container>
      </LayoutContainer>
    </>
  );
};

export default Layout;
