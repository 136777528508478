/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { API_BASE_URL } from '../../../../config/env';
import {
  containsAnyLetter,
  getAuthToken,
  isEmailValid,
  isUrlValid,
  postWithResponseObject,
  putWithResponseObject,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import { toaster } from '../../../atoms/toaster';
import shoptypeThemes from '../../../../theme';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: 0,
    padding: 0
  },
  paper: {
    maxWidth: 550
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4)
  },
  submit: {
    margin: theme.spacing(4, 0, 0, 0),
    height: '45px',
    width: '100px'
  },
  button: {
    width: '110px',
    marginTop: '10px',
    height: '50px'
  },
  testButton: {
    marginLeft: '-74px',
    marginTop: '12px',
    backgroundColor: shoptypeThemes.default.WILD_SAND
  },
  textButton: {
    color: 'blue',
    cursor: 'pointer'
  },
  confirmTextMargin: {
    marginTop: '10px'
  }
}));

const SMTPConfig = props => {
  const classes = useStyles();
  const { smtpConfig } = props;

  const [SMTPdetails, setSMTPdetails] = useState({
    port: smtpConfig?.port || '',
    host: smtpConfig?.host || '',
    key: smtpConfig?.key || '',
    secret: smtpConfig?.secret || '',
    from: smtpConfig?.from || '',
    email: smtpConfig?.email || ''
  });

  const { port, host, key, secret, from, email } = SMTPdetails;

  const [click, setClick] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [disableTestButton, setDisableTestButton] = useState(true);

  const handleSMTPdetails = event => {
    const { name, value } = event.target;
    setSMTPdetails({ ...SMTPdetails, [name]: value });
  };

  const handleTestMail = async () => {
    const token = getAuthToken();
    const headers = { authorization: token };
    const requestBody = {
      ...SMTPdetails
    };

    const response = await postWithResponseObject(
      `${API_BASE_URL}/networks/smtp/test-email`,
      requestBody,
      headers
    );

    setDisableTestButton(true);
    setClick(true);

    if (!successStatusCodes.includes(response.status)) {
      showApiErrorToast(response.data);
    } else {
      toaster('Test mail sent successfully');
    }
  };

  const handleConfirmAction = action => {
    switch (action) {
      case 'yes':
        setDisableTestButton(true);
        setDisableSaveButton(false);
        setClick(false);
        break;
      case 'no':
        setDisableTestButton(false);
        setDisableSaveButton(true);
        setClick(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    testButtonDisabler();
  }, [SMTPdetails]);

  const testButtonDisabler = () => {
    if (
      port !== '' &&
      host !== '' &&
      key !== '' &&
      secret !== '' &&
      from !== '' &&
      email !== ''
    ) {
      setDisableTestButton(false);
    } else {
      setDisableTestButton(true);
      setDisableSaveButton(true);
    }
  };

  const handleSave = async event => {
    event.preventDefault();
    const token = getAuthToken();
    const headers = { authorization: token };

    // validating the fields
    if (
      port === '' ||
      host === '' ||
      key === '' ||
      secret === '' ||
      from === '' ||
      email === ''
    ) {
      toaster('Please fill all the fields');
      return;
    } else if (containsAnyLetter(port)) {
      toaster('Port should contain only numbers');
      return;
    } else if (parseInt(port) > 65535 || parseInt(port) < 1) {
      toaster('Please enter valid port number');
      return;
    } else if (isNaN(parseInt(port))) {
      toaster('Port should be a number');
      return;
    } else if (!isUrlValid(host)) {
      toaster('Please enter a valid Host URL');
      return;
    } else if (!isEmailValid(from)) {
      toaster('Please enter a valid email address (from email)');
      return;
    } else if (!isEmailValid(email)) {
      toaster('Please enter a valid email address (test email)');
      return;
    }

    const requestBody = {
      ...SMTPdetails
    };

    const response = await putWithResponseObject(
      `${API_BASE_URL}/networks/smtp`,
      requestBody,
      headers
    );

    if (!successStatusCodes.includes(response.status)) {
      toaster('Failed to update SMTP details');
    } else {
      toaster('SMTP details updated successfully');
    }
  };

  return (
    <Container component="main" maxWidth="md" className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Port (port number)"
                name="port"
                variant="outlined"
                fullWidth
                value={port}
                onChange={handleSMTPdetails}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Host (host URL)"
                name="host"
                variant="outlined"
                fullWidth
                value={host}
                onChange={handleSMTPdetails}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Key (key)"
                name="key"
                variant="outlined"
                fullWidth
                value={key}
                onChange={handleSMTPdetails}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Secret (secret)"
                name="secret"
                variant="outlined"
                fullWidth
                value={secret}
                onChange={handleSMTPdetails}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Source (from email)"
                name="from"
                variant="outlined"
                placeholder="yourname@yourdomain.com"
                fullWidth
                value={from}
                onChange={handleSMTPdetails}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Test Email (test email)"
                name="email"
                variant="outlined"
                fullWidth
                placeholder="yourname@domain.com"
                value={email}
                onChange={handleSMTPdetails}
              />
              <Button
                className={classes.testButton}
                variant="outlined"
                size="small"
                onClick={handleTestMail}
                disabled={disableTestButton}
              >
                Test
              </Button>
              {click ? (
                <>
                  <Typography
                    className={classes.confirmTextMargin}
                    variant="subtitle1"
                    component="p"
                  >
                    Did you receive the email?{' '}
                    <span
                      className={classes.textButton}
                      onClick={() => handleConfirmAction('yes')}
                    >
                      Yes/
                    </span>
                    <span
                      className={classes.textButton}
                      onClick={() => handleConfirmAction('no')}
                    >
                      No
                    </span>
                  </Typography>
                </>
              ) : null}
            </Grid>
          </Grid>
          <Button
            className={classes.submit}
            variant="outlined"
            size="large"
            onClick={handleSave}
            disabled={disableSaveButton}
          >
            Save
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default SMTPConfig;
