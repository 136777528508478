import styled from 'styled-components';

const HeadingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: ${props => props.theme.PAGE_HEADING};
  font-weight: ${props => props.theme.MEDIUM_FONT};
  margin-right: 10px;
`;

export { HeadingSection, Heading };
