import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Textarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  resize: none;

  ${p =>
    p.inputType === 'primary'
      ? css`
          border: 1px solid ${p => p.theme.GREY};
        `
      : ''};

  @media (min-width: 768px) {
    ${p =>
      ({
        'default-size': css`
          width: 300px;
          height: 40px;
        `,
        'small-size': css`
          width: 240px;
          height: 100px;
        `
      }[p.size] ||
      css`
        width: 100%;
        height: 80px;
        border: 0;
        outline: none;
        border-bottom: 1px solid ${props => props.theme.BLACK};
        text-align: left;
        font-weight: ${props => props.theme.MEDIUM_FONT};
        margin-bottom: 30px;
      `)};
  }
`;

export { Container, Textarea };
